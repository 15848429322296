import {createSlice} from "@reduxjs/toolkit";
import {identify} from '@amplitude/analytics-browser';
import * as amplitude from '@amplitude/analytics-browser';
import { WorkingTimeType } from "@src/model/WorkingTimeType";

const EducationDegreeMap: { [key: string]: number } = {
  "phd": 4,
  "master": 3,
  "bachelor": 2,
  "high_school": 1,
  "middle_under": 0
}


interface EducationState {
  uuid: string,
  major: string,
  name: string,
  degree: string,
  startDate: string,
  endDate: string,
  status: string,
}

interface CareerState {
  uuid: string,
  name: string,
  position: string,
  status: string,
  startDate: string,
  endDate: string,
  description: string,
  no_career : boolean
}

interface CertificateState {
  uuid: string,
  name: string,
  publisher: string,
  acquired_at: string,
  no_certificate : boolean
}

interface ResumeFormState {
  uuid: string,
  title: string,
  photo: string,
  photo_res: string,
  device_id: string,
  name: string,
  birthday: string,
  gender: any,
  phone_number: string,
  email: string,
  address: string,
  education: EducationState[],
  career: CareerState[],
  certificate: CertificateState[],
  introduction: string,
  updated_at: string,
  finished: boolean,
  lastSelectedCareerOption: string,
  lastSelectedCertificateOption: string,
  lastSelectedEducationOption: string,
}

interface ResumeState {
  uuid: string,
  title: string,
  photo: string,
  photo_res: string,
  device_id: string,
  name: string,
  birthday: string,
  gender: any,
  phone_number: string,
  email: string,
  address: string,
  education: EducationState[],
  career: CareerState[],
  certificate: CertificateState[],
  introduction: string,
  updated_at: string,
}

interface ResumeCardState {
  id: string,
  title: string,
  updated_at: string,
}

interface NoticeState {
  id: string,
  title: string,
  nextShowAt: number
  leftCount: number
}


interface UserState {
  phoneNumber: string | undefined,
  email: string | undefined,
  lastActionTimestamp: number,
  regions: string[],
  hasShownZoomInGuide: boolean,
  createdAt: string,
  signUpAt: string | undefined,
  hasShownAnnouncement: boolean,
  announcementVersion: number,
  installReferrer: string | undefined,
  deviceId: string | null,
  uuid: string | null,
  uuidHash: number | null,
  referrer: string | null,
  resume: ResumeFormState,
  resumeTemp: ResumeState,
  resumes: ResumeCardState[],
  lastUsedResumeId: string,
  categoryIds: number[] | undefined,
  workingTimeType: WorkingTimeType | undefined,
  simpleApplyFilterOn: boolean,
  nickname: string | null
  notice: NoticeState | null
  pushToken: string | null
  onboarded: boolean
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    phoneNumber: undefined,
    email: undefined,
    lastActionTimestamp: 0,
    regions: [],
    hasShownZoomInGuide: false,
    createdAt: new Date().toISOString(),
    signUpAt: undefined,
    hasShownAnnouncement: false,
    announcementVersion: 0,
    installReferrer: undefined,
    deviceId: null,
    uuid: null,
    uuidHash: null,
    referrer: null,
    resumes: [],
    workingTimeType: undefined,
    categoryIds: undefined,
    simpleApplyFilterOn: false,
    nickname: null,
    notice: null,
    pushToken: null,
    onboarded : false,
    resumeTemp: {
      uuid: "",
      device_id: "",
      title: "",
      photo: "",
      photo_res: "",
      name: "",
      birthday: "",
      gender: null,
      phone_number: "",
      email: "",
      address: "",
      education: [],
      career: [],
      certificate: [],
      introduction: "",
      updated_at: "",
    },
    resume: {
      uuid: "",
      device_id: "",
      title: "",
      photo_res: "",
      photo: "",
      name: "",
      birthday: "",
      gender: null,
      phone_number: "",
      email: "",
      address: "",
      education: [],
      career: [],
      certificate: [],
      introduction: "",
      updated_at: "",
      finished: false,
      lastSelectedCareerOption: "",
      lastSelectedCertificateOption: "",
      lastSelectedEducationOption: "",
    },
    lastUsedResumeId: ""
  } as UserState,
  reducers: {
    changePhoneNumber: (state, action) => {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('number', action.payload);
      identify(identifyEvent);
      state.phoneNumber = action.payload
    },
    changeEmail: (state, action) => {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('email', action.payload);
      identify(identifyEvent);
      state.email = action.payload
    },
    changeSignUpAt: (state, action) => {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('signUpAt', action.payload);
      identify(identifyEvent);
      state.signUpAt = action.payload
    },
    updateLastActionTimestamp: (state) => {
      state.lastActionTimestamp = Date.now();
    },
    changeRegions: (state, action) => {
      state.regions = action.payload
    },
    updatePushToken: (state, action) => {
      state.pushToken = action.payload
    },
    updateHasShownZoomInGuide: (state) => {
      state.hasShownZoomInGuide = true
    },
    updateHasShownAnnouncement: (state) => {
      state.hasShownAnnouncement = true
    },
    updateInstallReferrer: (state, action) => {
      state.installReferrer = action.payload
    },
    cleanUser: (state) => {
      state.phoneNumber = undefined
      state.email = undefined
      state.lastActionTimestamp = 0
      state.regions = []
      state.hasShownZoomInGuide = false
      state.installReferrer = undefined
    },
    setDeviceId: (state, action) => {
      state.deviceId = action.payload
    },
    setUuid: (state, action) => {
      state.uuid = action.payload
    },
    updateResumeField: (state, action) => {
      state.resume = {...state.resume, ...action.payload}
    },
    updateResumeTempField: (state, action: {payload: object, type: string}) => {
      state.resumeTemp = {...state.resumeTemp, ...action.payload}
    },
    setResumeTemp: (state, action: {payload: ResumeState, type: string}) => {
      state.resumeTemp = action.payload
    },
    setEducation: (state, action: {payload: EducationState, type: string}) => {
      state.resume.education = [action.payload]
    },
    setEducationTemp: (state, action: {payload: EducationState, type: string}) => {
      state.resumeTemp.education = [action.payload]
    },
    clearEducation: (state, action: {payload: undefined, type: string}) => {
      state.resume.education = []
    },
    saveEducation: (state, action) => {
      state.resume.education = state.resume.education.filter(edu => edu.degree !== "middle_under")
      const oldEducation = state.resume.education.find(edu => edu.uuid === action.payload.uuid)
      if (oldEducation !== undefined) {
        state.resume.education = state.resume.education.filter(edu => edu.uuid !== action.payload.uuid)
      }
      state.resume.education.push(action.payload)
      state.resume.education.sort((a, b) => {
        const b_start_date = b.startDate ? parseInt(b.startDate) : 0
        const a_start_date = a.startDate ? parseInt(a.startDate) : 0
        return (EducationDegreeMap[b.degree] - EducationDegreeMap[a.degree]) * 1e8 + b_start_date - a_start_date
      })
    },
    saveEducationTemp: (state, action: {payload: EducationState, type:string }) => {
      state.resumeTemp.education = state.resumeTemp.education.filter(edu => edu.degree !== "middle_under")
      const oldEducation = state.resumeTemp.education.find(edu => edu.uuid === action.payload.uuid)
      if (oldEducation !== undefined) {
        state.resumeTemp.education = state.resumeTemp.education.filter(edu => edu.uuid !== action.payload.uuid)
      }
      state.resumeTemp.education.push(action.payload)
      state.resumeTemp.education.sort((a, b) => {
          const b_start_date = b.startDate ? parseInt(b.startDate) : 0
          const a_start_date = a.startDate ? parseInt(a.startDate) : 0
          return (EducationDegreeMap[b.degree] - EducationDegreeMap[a.degree]) * 1e8 + b_start_date - a_start_date
      })
    },
    setOnboarded: (state, action) => {
      state.onboarded = action.payload
    },
    setLastSelectedCareerOption: (state, action: {payload: string, type: string}) => {
      state.resume.lastSelectedCareerOption = action.payload
    },
    setLastSelectedCertificateOption: (state, action: {payload: string, type: string}) => {
      state.resume.lastSelectedCertificateOption = action.payload
    },
    setLastSelectedEducationOption: (state, action: {payload: string, type: string}) => {
      state.resume.lastSelectedEducationOption = action.payload
    },
    setNoCareer: (state, action: {payload: CareerState, type: string}) => {
      state.resume.career = [action.payload]
    },
    clearCareer: (state, action: {payload: undefined, type: string}) => {
      state.resume.career = state.resume.career.filter(career => career.no_career !== undefined && career.no_career !== null)
    },
    saveCareer: (state, action: {payload: CareerState, type: string}) => {
      state.resume.career = state.resume.career.filter(career => !career.no_career)
      const oldCareer = state.resume.career.find(career => career.uuid === action.payload.uuid)
      if (oldCareer !== undefined) {
        state.resume.career  = state.resume.career.filter(career => career.uuid !== action.payload.uuid)
      }
      state.resume.career.push(action.payload)
      state.resume.career.sort((a, b) => parseInt(b.startDate) - parseInt(a.startDate))
    },
    saveCareerTemp: (state, action: {payload: CareerState, type:string }) => {
      state.resumeTemp.career = state.resumeTemp.career.filter(career => !career.no_career)
      const oldCareer = state.resumeTemp.career.find(career => career.uuid === action.payload.uuid)
      if (oldCareer !== undefined) {
        state.resumeTemp.career = state.resumeTemp.career.filter(career => career.uuid !== action.payload.uuid)
      }
      state.resumeTemp.career.push(action.payload)
      state.resumeTemp.career.sort((a, b) => parseInt(b.startDate) - parseInt(a.startDate))
    },
    setNoCertificate: (state, action: {payload: CertificateState, type: string}) => {
      state.resume.certificate = [action.payload]
    },
    clearCertificate: (state, action: {payload: undefined, type: string}) => {
      state.resume.certificate = state.resume.certificate.filter(cert => cert.no_certificate !== undefined && cert.no_certificate !== null)
    },
    saveCertificate: (state, action: {payload: CertificateState, type: string}) => {
      state.resume.certificate = state.resume.certificate.filter(certificate => !certificate.no_certificate)
      const oldCertificate = state.resume.certificate.find(certificate => certificate.uuid === action.payload.uuid)
      if (oldCertificate !== undefined) {
        state.resume.certificate = state.resume.certificate.filter(certificate => certificate.uuid !== action.payload.uuid)
      }
      state.resume.certificate.push(action.payload)
      state.resume.certificate.sort((a, b) => parseInt(b.acquired_at) - parseInt(a.acquired_at))
    },
    saveCertificateTemp: (state, action: {payload: CertificateState, type:string }) => {
      state.resumeTemp.certificate = state.resumeTemp.certificate.filter(certificate => !certificate.no_certificate)
      const oldCertificate = state.resumeTemp.certificate.find(certificate => certificate.uuid === action.payload.uuid)
      if (oldCertificate !== undefined) {
        state.resumeTemp.certificate = state.resumeTemp.certificate.filter(certificate => certificate.uuid !== action.payload.uuid)
      }
      state.resumeTemp.certificate.push(action.payload)
      state.resumeTemp.certificate.sort((a, b) => parseInt(b.acquired_at) - parseInt(a.acquired_at))
    },
    removeEducationTemp: (state, action: {payload: string, type: string}) => {
      state.resumeTemp.education = state.resumeTemp.education.filter(edu => edu.uuid !== action.payload)
    },
    removeCareer: (state, action: {payload: string, type: string}) => {
      state.resume.career = state.resume.career.filter(career => career.uuid !== action.payload)
    },
    removeCareerTemp: (state, action: {payload: string, type: string}) => {
      state.resumeTemp.career = state.resumeTemp.career.filter(career => career.uuid !== action.payload)
    },
    removeCertificate: (state, action: {payload: string, type: string}) => {
      state.resume.certificate = state.resume.certificate.filter(certificate => certificate.uuid !== action.payload)
    },
    removeCertificateTemp: (state, action: {payload: string, type: string}) => {
      state.resumeTemp.certificate = state.resumeTemp.certificate.filter(certificate => certificate.uuid !== action.payload)
    },
    updateReferrer: (state, action) => {
      state.referrer = action.payload
    },
    updateUuidHash: (state, action) => {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('user_group', action.payload);
      identify(identifyEvent);
      state.uuidHash = action.payload
    },
    removeResume: (state, action) => {
      state.resumes = state.resumes.filter((resume) => resume.id !== action.payload)
    },
    cleanResume: (state, action : {payload: undefined, type: string}) => {
      state.resume = {
        ...state.resume,
        uuid: "",
        device_id: "",
        career: [],
        certificate: [],
        introduction: "",
        finished: true,
        updated_at: "",
        lastSelectedCareerOption: "",
        lastSelectedCertificateOption: "",
      }
    },
    cleanResumeTemp: (state, action) => {
      state.resumeTemp = {
        title: "",
        photo: "",
        photo_res: "",
        uuid: "",
        device_id: "",
        name: "",
        birthday: "",
        gender: null,
        phone_number: "",
        email: "",
        address: "",
        education: [],
        career: [],
        certificate: [],
        introduction: "",
        updated_at: "",
      }
    },
    setResumes: (state, action: {payload: ResumeCardState[], type:string}) => {
      state.resumes = action.payload
    },
    setLastUsedResumeId: (state, action) => {
      state.lastUsedResumeId = action.payload
    },
    clearFilter: (state) => {
      state.categoryIds = []
      state.workingTimeType = undefined
      state.simpleApplyFilterOn = false
    },
    changeCategoryIds: (state, action) => {
        state.categoryIds = action.payload
    },
    changeWorkingTimeType: (state, action) => {
        state.workingTimeType = action.payload
    },
    changeSimpleApplyFilterOn: (state, action) => {
        state.simpleApplyFilterOn = action.payload
    },
    changeNickname: (state, action: {payload: string, type: string}) => {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('nickname', action.payload);
      identify(identifyEvent);
      state.nickname = action.payload
    },
    updateNotice: (state, action: {payload: any, type: string}) => {
      state.notice = action.payload
    },
    // for test
    clearNickname: (state) => {
      state.nickname = null
    }
  }
})



export {ResumeCardState, ResumeState, EducationState, CareerState, CertificateState};
export const {
  changePhoneNumber,
  changeEmail,
  changeNickname,
  changeRegions,
  updateLastActionTimestamp,
  updateHasShownZoomInGuide,
  cleanUser,
  changeSignUpAt,
  updateHasShownAnnouncement,
  updateInstallReferrer,
  setDeviceId,
  setUuid,
  updateReferrer,
  updateUuidHash,
  updateResumeField,
  saveCareer,
  setNoCareer,
  clearCareer,
  setEducation,
  saveEducation,
  clearEducation,
  saveCertificate,
  setNoCertificate,
  clearCertificate,
  cleanResume,
  clearNickname,
  removeResume,
  setLastUsedResumeId,
  setResumes,
  setResumeTemp,
  removeEducationTemp,
  saveEducationTemp,
  removeCareerTemp,
  removeCertificateTemp,
  saveCareerTemp,
  saveCertificateTemp,
  setEducationTemp,
  removeCareer,
  removeCertificate,
  cleanResumeTemp,
  updateResumeTempField,
  clearFilter,
  changeCategoryIds,
  changeWorkingTimeType,
  changeSimpleApplyFilterOn,
  setLastSelectedCareerOption,
  setLastSelectedCertificateOption,
  setLastSelectedEducationOption,
  updatePushToken,
  updateNotice,
  setOnboarded
} = userSlice.actions
export default userSlice.reducer