export const calculateManAge = (date: string, baseDate: string): string => {
  const year = parseInt(date.substring(0, 4));
  const baseYear = parseInt(baseDate.substring(0, 4));

  if (date.substring(4, 8) > baseDate.substring(4, 8)) {
    return (baseYear - year - 1).toString();
  } else {
    return (baseYear - year).toString();
  }
}

export const calculateKoreaAge = (date: string, baseDate: string): string => {
  const year = parseInt(date.substring(0, 4));
  const baseYear = parseInt(baseDate.substring(0, 4));
  return (baseYear - year + 1).toString();
}

export const calculateManAgeDate = (date: string, age: number) => {
  return new Date((parseInt(date.substring(0,4)) + age) + "-" + date.substring(4, 6) + "-01")
}