import {calculateManAge, calculateManAgeDate} from "@src/features/age_calculator/util/CalculateAge";
import {toYM, toYMD} from "@util/DateHelper";

const getMinSalary = (date: Date) => {
  if (new Date("1988-11-01") <= date && date <= new Date("1995-03-01")) {
    return 70000
  } else if (new Date("1995-04-01") <= date && date <= new Date("2010-06-01")) {
    return 220000
  } else if (new Date("2010-07-01") <= date && date <= new Date("2011-06-01")) {
    return 230000
  } else if (new Date("2011-07-01") <= date && date <= new Date("2012-06-01")) {
    return 230000
  } else if (new Date("2012-07-01") <= date && date <= new Date("2013-06-01")) {
    return 240000
  } else if (new Date("2013-07-01") <= date && date <= new Date("2014-06-01")) {
    return 250000
  } else if (new Date("2014-07-01") <= date && date <= new Date("2015-06-01")) {
    return 260000
  } else if (new Date("2015-07-01") <= date && date <= new Date("2016-06-01")) {
    return 270000
  } else if (new Date("2016-07-01") <= date && date <= new Date("2017-06-01")) {
    return 280000
  } else if (new Date("2017-07-01") <= date && date <= new Date("2018-06-01")) {
    return 290000
  } else if (new Date("2018-07-01") <= date && date <= new Date("2019-06-01")) {
    return 300000
  } else if (new Date("2019-07-01") <= date && date <= new Date("2020-06-01")) {
    return 310000
  } else if (new Date("2020-07-01") <= date && date <= new Date("2021-06-01")) {
    return 320000
  } else if (new Date("2021-07-01") <= date && date <= new Date("2022-06-01")) {
    return 330000
  } else if (new Date("2022-07-01") <= date && date <= new Date("2023-06-01")) {
    return 350000
  } else if (new Date("2023-07-01") <= date && date <= new Date("2024-06-01")) {
    return 370000
  } else if (new Date("2024-07-01") <= date && date <= new Date("2025-06-01")) {
    return 390000
  } else if (new Date("2025-07-01") <= date && date <= new Date("2026-06-01")) {
    return 400000
  } else if (new Date("2026-07-01") <= date && date <= new Date("2027-06-01")) {
    return 410000
  } else if (new Date("2027-07-01") <= date && date <= new Date("2028-06-01")) {
    return 420000
  } else if (new Date("2028-07-01") <= date && date <= new Date("2029-06-01")) {
    return 430000
  } else if (new Date("2029-07-01") <= date && date <= new Date("2030-06-01")) {
    return 440000
  } else if (new Date("2030-07-01") <= date && date <= new Date("2031-06-01")) {
    return 450000
  } else {
    return 460000
  }
}

const getMaxSalary = (date: Date) => {
  if (new Date("1988-11-01") <= date && date <= new Date("1995-03-01")) {
    return 2000000
  } else if (new Date("1995-04-01") <= date && date <= new Date("2010-06-01")) {
    return 3600000
  } else if (new Date("2010-07-01") <= date && date <= new Date("2011-06-01")) {
    return 3680000
  } else if (new Date("2011-07-01") <= date && date <= new Date("2012-06-01")) {
    return 3750000
  } else if (new Date("2012-07-01") <= date && date <= new Date("2013-06-01")) {
    return 3890000
  } else if (new Date("2013-07-01") <= date && date <= new Date("2014-06-01")) {
    return 3980000
  } else if (new Date("2014-07-01") <= date && date <= new Date("2015-06-01")) {
    return 4080000
  } else if (new Date("2015-07-01") <= date && date <= new Date("2016-06-01")) {
    return 4210000
  } else if (new Date("2016-07-01") <= date && date <= new Date("2017-06-01")) {
    return 4340000
  } else if (new Date("2017-07-01") <= date && date <= new Date("2018-06-01")) {
    return 4490000
  } else if (new Date("2018-07-01") <= date && date <= new Date("2019-06-01")) {
    return 4680000
  } else if (new Date("2019-07-01") <= date && date <= new Date("2020-06-01")) {
    return 4860000
  } else if (new Date("2020-07-01") <= date && date <= new Date("2021-06-01")) {
    return 5030000
  } else if (new Date("2021-07-01") <= date && date <= new Date("2022-06-01")) {
    return 5240000
  } else if (new Date("2022-07-01") <= date && date <= new Date("2023-06-01")) {
    return 5530000
  } else if (new Date("2023-07-01") <= date && date <= new Date("2024-06-01")) {
    return 5900000
  } else if (new Date("2024-07-01") <= date && date <= new Date("2025-06-01")) {
    return 6170000
  } else if (new Date("2025-07-01") <= date && date <= new Date("2026-06-01")) {
    return 6390000
  } else if (new Date("2026-07-01") <= date && date <= new Date("2027-06-01")) {
    return 6620000
  } else if (new Date("2027-07-01") <= date && date <= new Date("2028-06-01")) {
    return 6860000
  } else if (new Date("2028-07-01") <= date && date <= new Date("2029-06-01")) {
    return 7110000
  } else if (new Date("2029-07-01") <= date && date <= new Date("2030-06-01")) {
    return 7370000
  } else if (new Date("2030-07-01") <= date && date <= new Date("2031-06-01")) {
    return 7640000
  } else {
    return 7920000
  }
}

const AverageIncomeForLast3Years: {[key: string]: number} = {
  '1988': 0,
  '1989': 374485,
  '1990': 423569,
  '1991': 486449,
  '1992': 581837,
  '1993': 670540,
  '1994': 757338,
  '1995': 859838,
  '1996': 931293,
  '1997': 1015544,
  '1998': 1123185,
  '1999': 1260611,
  '2000': 1290803,
  '2001': 1271595,
  '2002': 1294723,
  '2003': 1320105,
  '2004': 1412428,
  '2005': 1497798,
  '2006': 1566567,
  '2007': 1618914,
  '2008': 1676837,
  '2009': 1750959,
  '2010': 1791955,
  '2011': 1824109,
  '2012': 1891771,
  '2013': 1935977,
  '2014': 1981975,
  '2015': 2044756,
  '2016': 2105482,
  '2017': 2176483,
  '2018': 2270516,
  '2019': 2356670,
  '2020': 2438679,
  '2021': 2539734,
  '2022': 2681724,
  '2023': 2861091,
  '2024': 2989237,
  '2025':	3100517,
  '2026':	3215940,
  '2027':	3335660,
  '2028':	3459836,
  '2029':	3588636,
  '2030':	3722230,
  '2031':	3860797,
  '2032':	4004523,
  '2033':	4153599,
  '2034':	4308225,
  '2035':	4468607,
  '2036':	4634960,
  '2037':	4807506,
  '2038':	4986474,
  '2039':	5172106,
  '2040':	5364648,
  '2041':	5564357,
  '2042':	5771502,
  '2043':	5986357,
  '2044':	6209211,
}

const convertPartition = (year: number) => {
  if (1988<= year && year <= 1998) {
    return "P1"
  } else if (1999 <= year && year <= 2007) {
    return "P2"
  } else if (2028 <= year) {
    return "P23"
  } else {
    return "P" + (year - 2005)
  }
}

const ConstantOfProportionality : {[key: string]: number} = {
  "P1": 2.4,
  "P2": 1.8,
  "P3": 1.5,
  "P4": 1.485,
  "P5": 1.47,
  "P6": 1.455,
  "P7": 1.44,
  "P8": 1.425,
  "P9": 1.41,
  "P10": 1.395,
  "P11": 1.38,
  "P12": 1.365,
  "P13": 1.35,
  "P14": 1.335,
  "P15": 1.32,
  "P16": 1.305,
  "P17": 1.29,
  "P18": 1.275,
  "P19": 1.26,
  "P20": 1.245,
  "P21": 1.23,
  "P22": 1.215,
  "P23": 1.2,
}

const SalaryConstant = (partition: string) => {
  if (partition === "P1") {
    return 0.75
  }
  return 1
}

const calculatePensionStartAge= (year: number) => {
  if (year < 1952) {
    return 60
  } else if (year < 1956) {
    return 61
  } else if (year < 1960) {
    return 62
  } else if (year < 1964) {
    return 63
  } else if (year < 1968) {
    return 64
  } else {
    return 65
  }
}

const calculateAmount = (months: number, startDate: Date, pensionStartDate: Date, salary: number) => {
  let temp = new Date(startDate)
  const salaryByPartition: {[key: string]: number[]} = {}
  for (const _ of [...Array(months).keys()].map(key => key)) {
    const year = temp.getFullYear()
    const partition = convertPartition(year)
    if (partition in salaryByPartition) {
      salaryByPartition[partition].push(salary)
    } else {
      salaryByPartition[partition] = [salary]
    }
    temp = new Date(temp.setMonth(temp.getMonth()+1));
  }
  console.log(salaryByPartition)

  let totalSalary = 0
  let totalSalaryCount = 0
  for (const [partition, salary] of Object.entries(salaryByPartition)) {
    totalSalaryCount += salary.length
    if (salary.length) {
      totalSalary += salary.reduce((a, b) => a + b)
    }
  }

  const averageSalary = totalSalary / totalSalaryCount


  // console.log("pensionStartDate.getFullYear(): ", pensionStartDate.getFullYear())
  const averageIncome = AverageIncomeForLast3Years[pensionStartDate.getFullYear().toString()]
  // console.log("averageIncome: ", averageIncome)

  let amount = 0
  for (const [partition, salary] of Object.entries(salaryByPartition)) {
    const count = salary.length
    if (count) {
      amount += ConstantOfProportionality[partition] * (averageIncome + SalaryConstant(partition) * averageSalary) * count / totalSalaryCount
    }
  }
  const n = totalSalaryCount > 240 ? totalSalaryCount - 240 : 0
  amount *= (1 + 0.05 * n /12)
  const overCount = months - 120
  const payoutRatio = 0.5 + (5 / 12 / 100 * overCount)
  amount *= payoutRatio
  return amount
}

const calculatePension = (data: Pension) => {
  // console.log(data)

  // const today = toYMD(new Date())
  // const age = calculateManAge(data.birth, today)
  // console.log("age: ", age)

  const pensionStartAge = calculatePensionStartAge(parseInt(data.birth.substring(0,4)))
  // console.log("pensionStartAge: ", pensionStartAge)

  const lastPaidDate = calculateManAgeDate(data.birth, 60)
  // console.log("lastPaidDate: ", lastPaidDate.toISOString())

  const tempP  = new Date(parseInt(data.birth.substring(0,4)) + pensionStartAge + "-" + data.birth.substring(4, 6) + "-01")
  const pensionStartDate = new Date(tempP.setMonth(tempP.getMonth() + 1))
  // console.log("pensionStartDate: ", tempP.toISOString())

  const userStartDate = new Date(data.start.substring(0,4) + "-" + data.start.substring(4, 6) + "-01")
  const minStartDate = new Date("1988-01-01")
  // console.log("userStartDate: ", userStartDate)

  const startDate = userStartDate < minStartDate ? minStartDate
    : userStartDate < lastPaidDate ? userStartDate
      : lastPaidDate
  // console.log("startDate: ", startDate)

  const userEndDate = new Date(data.end.substring(0,4) + "-" + data.end.substring(4, 6) + "-01")
  // console.log("userEndDate: ", userEndDate)

  const endDate = userEndDate < minStartDate ? minStartDate
    :  userEndDate < lastPaidDate ? userEndDate
      : lastPaidDate
  // console.log("endDate: ", endDate.toISOString())

  let months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months -= startDate.getMonth();
  months += endDate.getMonth();
  months += 1;
  // console.log(months)

  if (months < 120) {
    return {
      'months': months,
      "pensionStartDate": "",
      'annualAmount': 0,
      "annualAmountPlus30": 0,
      "annualAmountMinus30": 0,
    }
  }

  const minSalary = getMinSalary(pensionStartDate)
  // console.log("minSalary: ", minSalary)

  const maxSalary = getMaxSalary(pensionStartDate)
  // console.log("maxSalary: ", maxSalary)

  const userSalary = parseInt(data.salary)  * 10000
  // console.log("userSalary: ", userSalary)

  const salary = userSalary < minSalary ? minSalary : userSalary > maxSalary ? maxSalary : userSalary
  // console.log("salary: ", salary)

  const amount = calculateAmount(months, startDate, pensionStartDate, salary)
  // console.log("amount: ", amount)

  const amountPlus30 = calculateAmount(months, startDate,  pensionStartDate, salary + 300000)
  // console.log("amountPlus30: ", amountPlus30)

  const amountMinus30 = calculateAmount(months, startDate,  pensionStartDate, salary - 300000)
  // console.log("amountMinus30: ", amountMinus30)

  return {
    'months': months,
    "pensionStartDate": pensionStartDate.toISOString().slice(0, 7).replace(/-/g, ""),
    'annualAmount': amount,
    'annualAmountPlus30': amountPlus30,
    'annualAmountMinus30': amountMinus30,
  }
}

export default calculatePension;