import {useBlocker, useLocation, useNavigate} from "react-router-dom";
import {BorderButton, FillButton} from "@components/Buttons";
import {DefaultPageRoot} from "@components/PageTemplate";
import React, {useEffect, useMemo, useState} from "react";
import {BackNavToolbar} from "@components/Toolbars";
import {Description, Indicator} from "@components/form/ResumeComponent";
import {useDispatch, useSelector} from "react-redux";
import {cleanResume, setResumes, updateResumeField} from "@reducer/userSlice";
import {RootState} from "@reducer/store";
import {fetchWithRetry} from "@util/networks";
import server_domain from "@components/RemoteServer";
import IcDoneResume from "@assets/ic_done_resume.webp";
import {DialogDrawerTemplate, FullscreenDrawerTemplate} from "@components/DrawerTemplate";
import useOnLog from "@components/useOnLog";
import {EVENT_CREATE_RESUME_REGISTER_PHOTO_PAGE_SHOW, EVENT_CREATE_RESUME_SAVED} from "@components/AppConstant";
import trackEvent from "@util/trackEvent";
import ImageSourceSelector from "@components/ImageSourceSelector";

let escape = false

const SimpleApplyRegisterPhotoView = ({id}: {id: string}) => {
  const dispatch = useDispatch();
  const deviceId = useSelector((state: RootState) => state.user.deviceId)
  const resume = useSelector((state: RootState) => state.user.resume)
  const navigate = useNavigate();
  const available = () => true // for dev
  const state = useLocation().state
  const step = useMemo(() => state.step, [null])
  const [showImageSelectorModal, setShowImageSelectorModal] = useState(false);
  const [showConfirmDeletePhotoModal, setShowConfirmDeletePhotoModal] = useState(false);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const _ = useBlocker(({ currentLocation, nextLocation, historyAction }) => {
    if (showImageSelectorModal) {
      setShowImageSelectorModal(false)
      return true
    }

    if (showConfirmDeletePhotoModal) {
      setShowConfirmDeletePhotoModal(false)
      return true
    }

    if (escape) {
      escape = false
      return false
    }

    if (showDoneModal) {
      navigate(-step)
      setShowDoneModal(false)
      return false
    }
    return false
  });


  function done() {
    fetchWithRetry(server_domain() + "/resume", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        device_id: deviceId,
        title: "기본 이력서",
        name: resume.name,
        birthday: resume.birthday,
        gender: resume.gender,
        phone_number: resume.phone_number,
        address: resume.address,
        education: resume.education,
        career: resume.career,
        email: "",
        certificate: resume.certificate,
        introduction: resume.introduction,
        photo_res: resume.photo_res || "",
      })
    })
      .then(res => {
        if (res.ok) {
          return res.json()
        }
      })
      .then(data => {
        if (data) {
          setShowDoneModal(true)
          trackEvent(EVENT_CREATE_RESUME_SAVED, {photo: !!resume.photo_res, resume_id: data.new_resume_id, location: "SimpleApplyPage"} )
          dispatch(setResumes(data.resumes))
          dispatch(cleanResume())
        }
      })
      .catch(err => {
        fetchWithRetry(server_domain() + "/collect_something", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({
            device_id: deviceId,
            title: "기본 이력서",
            name: resume.name,
            birthday: resume.birthday,
            gender: resume.gender,
            phone_number: resume.phone_number,
            address: resume.address,
            education: resume.education,
            email: "",
            career: resume.career,
            certificate: resume.certificate,
            introduction: resume.introduction,
          })
        })
      })
  }

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const newImg = document.createElement('img')
  newImg.onload = () => {
    const imgWidth = 500
    const imgHeight = imgWidth * newImg.height / newImg.width
    canvas.width = imgWidth
    canvas.height = imgHeight
    if (ctx) {
      ctx.filter = "blur(0.5px) brightness(105%)";
      ctx.drawImage(newImg, 0, 0, imgWidth, imgHeight)
    }
    const img = canvas.toDataURL('image/webp')
    dispatch(updateResumeField({
      photo_res: img.split(',')[1]
    }))
  }

  function uploadImage(e: any) {
    if (e.detail.id === 'id2') {
      newImg.setAttribute('src', "data:image/webp;base64, " + e.detail.images[0])
    }
  }

  useEffect(() => {
    window.addEventListener("uploadImage", uploadImage);
    return () => {
      window.removeEventListener("uploadImage", uploadImage);
    }
  }, []);

  useOnLog({
    pageNameKey: EVENT_CREATE_RESUME_REGISTER_PHOTO_PAGE_SHOW,
    regStr: "\/create_resume_photo$"
  })

  return (<>
    <DefaultPageRoot
      top={<BackNavToolbar title=""/>}
      middle={<div style={{padding: '20px 16px 0px'}}>
        <Indicator label={"사진등록"} max={6} index={6} />
        <Description text={"끝으로, 응시원서에 사용할 단정한 사진을 등록해주세요!"}/>
        <p style={{lineHeight: 1.5, margin: 0}}>사진은 필수가 아니지만, 단정한 사진은 면접 성사율을 2배 높입니다.</p>
        <div style={{margin: '15px auto', borderRadius: 5, width: 150}} onClick={() => setShowImageSelectorModal(true)}>
          {
            resume.photo_res ?
              <>
                <img src={"data:image/webp;base64," + resume.photo_res} alt="" style={{width: '100%', border: '1px solid black', borderRadius: 5}}/>
              </>
              :
              <div style={{background: '#F4F4F4', padding: '30px 0'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', borderRadius: 30, width: 60, height: 60, background: '#999'}}>
                  <p style={{margin: '0 0 5px', color: 'white', fontWeight: 700, fontSize: '2.5rem',}}>&#43;</p>
                </div>
                <p style={{textAlign: 'center', fontWeight: 700, fontSize: '1.125rem', margin: '30px 0 0', lineHeight: 1.5}}>사진등록</p>
              </div>
          }
        </div>
        {
          resume.photo_res &&
            <div style={{display: 'flex', gap: 10, justifyContent: 'center', marginBottom: 15}}>
                <BorderButton text={"수정"} onClick={() => setShowImageSelectorModal(true)} style={{width: 'fit-content'}}/>
                <BorderButton text={"삭제"} onClick={() => setShowConfirmDeletePhotoModal(true)} style={{width: 'fit-content'}}/>
            </div>
        }
        <BorderButton text={"사진 가이드 보기"} onClick={() => navigate("guide")} style={{width: 'fit-content', margin: '0px auto 0px'}}/>
      </div>}
      bottom={<div style={{padding: 16}}>
        <FillButton
          active={available()}
          text={resume.photo_res ? "작성완료" : "나중에 사진 추가하기"}
          onClick={done}
        />
      </div>}
    />
    <FullscreenDrawerTemplate show={showDoneModal} onClose={() => {escape=true; navigate(-(step+1))}}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
        <img src={IcDoneResume} alt={""} style={{width: 100}}/>
        <p style={{fontWeight: 500, fontSize: '1.125rem'}}>이력서가 완성되었습니다</p>
        <p style={{whiteSpace: 'pre-wrap', textAlign: 'center', lineHeight: 1.5, color: '#474D5B'}}>{"작성한 이력서로\n지원을 완료해보세요"}</p>
        <FillButton text={"확인"} onClick={(e) => {e.stopPropagation(); e.preventDefault(); escape=true; navigate(-(step+1))}} style={{padding: '10px', width: 250}}/>
      </div>
    </FullscreenDrawerTemplate>
    <ImageSourceSelector id={'id2'} showModal={showImageSelectorModal} setShowModal={setShowImageSelectorModal} />
    <DialogDrawerTemplate show={showConfirmDeletePhotoModal} onClose={() => setShowConfirmDeletePhotoModal(false)} title={"사진 삭제"}>
      <p>등록한 사진을 삭제합니다.</p>
      <div style={{display: 'flex', gap: 10}}>
        <FillButton style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}} text={"취소"} onClick={() => setShowConfirmDeletePhotoModal(false)} />
        <FillButton style={{background: '#F15D47'}} text={"삭제"} onClick={() => {
          dispatch(updateResumeField({photo: "", photo_res: ""}))
          setShowConfirmDeletePhotoModal(false);
        }} />
      </div>
    </DialogDrawerTemplate>
  </>);
}

export default SimpleApplyRegisterPhotoView;