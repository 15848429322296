import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {FillButton, IconFillButton, SecondaryFillButton} from "@components/Buttons";
import {useBlocker, useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import IcReset from "@assets/ic_reset.svg";
import trackEvent from "@util/trackEvent";
import {EVENT_TAX_CALC_NEXT_CLICK, EVENT_TAX_CALC_RESET_CLICK} from "@components/AppConstant";
import {AppInstallGuideBanner} from "@components/AppInstallGuideBanner";
import {DialogDrawerTemplate} from "@components/DrawerTemplate";
import AppInstallGuideModal2 from "@components/AppInstallGuideModal2";
import NoticeBanner from "@src/features/job/component/NoticeBanner";
import {HSelector, VSelector} from "@components/form/ResumeComponent";
import {StepTitleStyle} from "@src/features/pension_calculator/style/style";
import {Caution, Notice, Question} from "@components/Notice";
import LoadingModal from "@src/features/job/component/LoadingModal";
import CarTax from "@src/features/tax_saving/features/car_tax/model/CarTax";
import ExpectedCarTax from "@src/features/tax_saving/features/car_tax/model/ExpectedCarTax";
import {NumInput} from "@components/input/Inputs";
import {OdoMeter} from "@components/odometer/OdoMeter";
import {DividerThin} from "@components/DivideBar";
import Table from "@components/Table";
import {calculateCarTax} from "@src/features/tax_saving/features/car_tax/util/CarTaxCalculator";

let escape = false

const SelectorCarType = [
  {
    key: "내연기관 자동차",
    label: "내연기관 자동차"
  },
  {
    key: "전기/수소 자동차",
    label: "전기/수소 자동차"
  }
]

const Intro = () => {
  return <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%'}}>
    <p style={{whiteSpace: 'pre-wrap', fontSize: '1.25rem', lineHeight: 1.5, margin: 16, fontWeight: 700}}>{"자동차세(보유)는?"}</p>
    <NoticeBanner text={"보유한 자동차에\n부과하는 지방세입니다."}/>
    <p style={{whiteSpace: 'pre-wrap', fontSize: '1.25rem', lineHeight: 1.5, margin: 16, fontWeight: 700}}>{"위즈덤이 도와드릴게요!(무료)"}</p>
    <p style={{whiteSpace: 'pre-wrap', padding: '0 16px 16px', lineHeight: 1.5}}>
      {"하나. 자동차세 납부액을\n지방자치단체 계산 방식 그대로\n가장 정확하게 계산합니다.\n(비영업용 승용자동차)\n\n둘. 절세 방법을 알려드립니다."}
    </p>
    <div style={{flex: 1}} />
    <p style={{lineHeight: 1.5, margin: 16, fontSize: '0.75rem'}}>고객님이 입력하신 정보는 위즈덤이 수집하지 않으며, 다른 목적으로 사용되지 않음을 알려드립니다.</p>
  </div>
}


const Step1 = ({data, onChange}: { data: CarTax, onChange: (data: object) => void }) => {
  return <div style={{padding: 16}}>
    <NoticeBanner text={"고객님의 소중한 개인정보는 수집하지 않습니다."} title={"안심하세요!"} style={{marginBottom: 16}} />
    <p style={StepTitleStyle}>보유하고 계신 자동차의 종류를 선택해주세요</p>
    <VSelector onChange={(option) => onChange({type:option})} selected={data.type} options={SelectorCarType}/>
    <div style={{background: '#F4F4F4', borderRadius: 5, padding: '8px 8px 8px', marginTop: 40}}>
      <Caution title={"자동차 종류 구분이 왜 필요한가요?"}>
        <p style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px'}}>{"전기/수소 자동차는 연식과 성능에 관계없이 매년 동일한 금액을 납부합니다."}</p>
      </Caution>
    </div>
  </div>
}


const Step2 = ({data, onChange}: { data: CarTax, onChange: (data: object) => void }) => {
  return <div style={{padding: 16}}>
    <NoticeBanner text={"고객님의 소중한 개인정보는 수집하지 않습니다."} title={"안심하세요!"} style={{marginBottom: 16}} />
    <p style={StepTitleStyle}>자동차 배기량과 차량 최초등록일을 알려주세요</p>
    <NumInput label={"배기량"} placeholder={"1600"} value={data.displacement} onChange={(num) => onChange({"displacement": num})} unit={"cc"}/>
    <NumInput label={"최초등록"} placeholder={"2021"} value={data.registeredAt} onChange={(num) => onChange({"registeredAt": num})} unit={"년"} maxValue={new Date().getFullYear()}/>
    <HSelector label={""} options={[{key: "0", label: '상반기'}, {key: "1", label: '하반기'}]} value={data.registeredAtHalf || ""} onChange={(key) => onChange({"registeredAtHalf": key})}/>
    <div style={{background: '#F4F4F4', borderRadius: 5, padding: '8px 8px 8px', marginTop: 40}}>
      <Caution title={"두 가지 정보가 왜 필요한가요?"}>
        <ul style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px 0'}}>
          <li><span>자동차세는 배기량에 비례하여 계산됩니다.</span></li>
          <li><span>차량등록 3년차부터 세액을 매년 5%씩 감면 받을 수 있습니다.(최대50%)</span></li>
        </ul>
      </Caution>
      <Question title={"두 가지 정보를 정확히 모르신다면?"}>
        <ul style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px 0'}}>
          <li><span>차량등록증에 두 가지 정보가 기재 되어있으니 참고해주세요.</span></li>
          <li><span>배기량은 1,000cc 이하, 1,600cc 이하, 1,600cc 초과 3가지 기준에 따라 세금의 대부분이 결정되니 대략적인 값을 입력하셔도 됩니다.</span></li>
        </ul>
      </Question>
    </div>
  </div>
}

const formatMoney = (value: number) => value.toLocaleString("ko-KR")

const StepResult = ({data}: { data: CarTax }) => {
  const [result, setResult] = useState<ExpectedCarTax>()

  useEffect(() => {
    const calculated = calculateCarTax(data)
    setResult(calculated)
  }, []);

  return <>
    <div style={{padding: 16}}>
      <p style={{fontSize: '1.75rem', fontWeight: 700, margin: 0}}>자동차세<span style={{fontSize: '1.25rem'}}>(비영업승용)</span></p>
      <div style={{display: 'flex', alignItems: 'center', fontSize: '1.25rem', fontWeight: 700, margin: '20px 0', textAlign: 'center', justifyContent: 'center'}}>
        <span>{result?.currentYear}년&nbsp;</span>
        <OdoMeter value={Math.floor((result?.total || 0))} duration={1000} size="1.5rem" color="black"/>
        <span>원</span>
      </div>
      <div style={{display: 'flex'}}>
        <p style={{fontWeight: 700, margin: 0, textAlign: 'center', flex: 1}}>상반기 {formatMoney(result?.firstHalfTotal || 0)}원,</p>
        <p style={{fontWeight: 700, margin: 0, textAlign: 'center', flex: 1}}>하반기 {formatMoney(result?.secondHalfTotal || 0)}원</p>
      </div>
    </div>
    <DividerThin/>
    <div style={{padding: 16}}>
      <Table
        title={`자동차세 계산 내역(${result?.currentYear}년)`}
        tableStyle={{padding: '0 20px'}}
        valueStyle={{textAlign: 'right',}}
        data={[
          {
            indent: 0,
            key: "기준 세액",
            value: `${formatMoney(result?.standardTax || 0)}원`
          },
          {
            indent: 1,
            key: "과세표준(cc)",
            value: data.displacement + ' cc'
          },
          {
            indent: 1,
            key: "세율",
            value: result?.taxPerDisplacement + `원\n(${result?.displacementType})`
          },

          {
            indent: 0,
            key: "1. 상반기",
            value: `${formatMoney(result?.firstHalfTotal || 0)}원`
          },
          {
            indent: 1,
            key: "당초세액",
            value: `${formatMoney(result?.halfStandardTax || 0)}원\n(1년치의 절반)`
          },
          {
            indent: 1,
            key: "차령적용률",
            value: `${result?.firstHalfCarAgeRatio}%`
          },
          {
            indent: 1,
            key: "자동차세",
            value: `${formatMoney(result?.firstHalfCarTax || 0)}원`
          },
          {
            indent: 1,
            key: "지방교육세",
            value: `${formatMoney(result?.firstHalfLocalEducationTax || 0)}원\n(자동차세의 30%)`
          },

          {
            indent: 0,
            key: "2. 하반기",
            value: `${formatMoney(result?.secondHalfTotal || 0)}원`
          },
          {
            indent: 1,
            key: "당초세액",
            value: `${formatMoney(result?.halfStandardTax || 0)}원\n(1년치의 절반)`
          },
          {
            indent: 1,
            key: "차령적용률",
            value: `${result?.secondHalfCarAgeRatio}%`
          },
          {
            indent: 1,
            key: "자동차세",
            value: `${formatMoney(result?.secondHalfCarTax || 0)}원`
          },
          {
            indent: 1,
            key: "지방교육세",
            value: `${formatMoney(result?.secondHalfLocalEducationTax || 0)}원\n(자동차세의 30%)`
          },
          {
            indent: 0,
            key: result?.currentYear + "년 자동차세총합",
            value: `${formatMoney(result?.total || 0)}원`
          },
        ]}
      />
      <Table
        title={`자동차세 예상(${(result?.currentYear || 0) + 1}년, ${(result?.currentYear || 0) + 2}년)`}
        tableStyle={{padding: '0 20px'}}
        valueStyle={{textAlign: 'right',}}
        data={[
          {
            indent: 0,
            key: `${(result?.currentYear || 0) + 1}년 자동차세총합`,
            value: `${formatMoney(result?.nextYearTotal || 0)}원`
          },
          {
            indent: 0,
            key: `${(result?.currentYear || 0) + 2}년 자동차세총합`,
            value: `${formatMoney(result?.nextNextYearTotal || 0)}원`
          },
        ]}
      />
    </div>
    <div style={{background: '#EEF9F2', borderRadius: 5, padding: 8, margin: '0 16px'}}>
      <Caution title={"이렇게 해석하시면 돼요!"}>
        <ol style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px 0px'}}>
          <li><span>위 결과는 비영업용 내연기관 승용자동차에 해당하는 결과입니다.</span></li>
          <li><span>위 결과는 자동차를 1년동안 보유하였을 때의 금액으로, 연중에 신규/이전 등록 또는 폐차한 경우 소유한 기간만큼의 세금만 납부합니다.</span></li>
          <li><span>자동차세는 상반기 1회(6월), 하반기 1회(12월) 총 2번 납부합니다.</span></li>
          <li><span>최초차량등록연월에 따라 상반기, 하반기 세액이 차이가 있을 수 있습니다.</span></li>
          <li><span>경차(1,000cc 이하) 또는 연간세액이 10만원 미만의 경우 연 1회, 6월에 자동차세를 전액 납부합니다.</span></li>
        </ol>
      </Caution>
      <Notice title={"기능 개선 중입니다! 기다려주세요!"}>
        <ul style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 0 0px'}}>
          <li><span>승합차, 화물차, 특수차 등도 계산할 수 있어요.</span></li>
          <li><span>영업용 차량도 계산할 수 있어요.</span></li>
          <li><span>연 중 실제 소유기간을 반영하여 세금을 계산할 수 있어요.</span></li>
          <li><span>정부에서 논의중인 자동차세 세재개편 내용도 적용할 수 있어요.</span></li>
        </ul>
      </Notice>
    </div>
  </>
}

const StepResultElect = ({data}: { data: CarTax }) => {
  const [result, setResult] = useState<number>()
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    setResult(130000)
  }, []);

  return <>
    <div style={{padding: 16}}>
      <p style={{fontSize: '1.75rem', fontWeight: 700, margin: 0}}>자동차세<span style={{fontSize: '1.25rem'}}>(비영업승용)</span></p>
      <div style={{display: 'flex', alignItems: 'center', fontSize: '1.25rem', fontWeight: 700, margin: '20px 0', textAlign: 'center', justifyContent: 'center'}}>
        <span>{currentYear}년&nbsp;</span>
        <OdoMeter value={Math.floor((result || 0))} duration={1000} size="1.5rem" color="black"/>
        <span>원</span>
      </div>
      <div style={{display: 'flex'}}>
        <p style={{fontWeight: 700, margin: 0, textAlign: 'center', flex: 1}}>상반기 {formatMoney(65000)}원,</p>
        <p style={{fontWeight: 700, margin: 0, textAlign: 'center', flex: 1}}>하반기 {formatMoney(65000)}원</p>
      </div>
    </div>
    <DividerThin/>
    <div style={{padding: 16}}>
      <Table
        title={`자동차세 계산 내역(${currentYear}년)`}
        tableStyle={{padding: '0 20px'}}
        valueStyle={{textAlign: 'right',}}
        data={[
          {
            indent: 0,
            key: "기준 세액",
            value: `${formatMoney(100000)}원\n(전기차, 수소차)`
          },
          {
            indent: 0,
            key: "1. 상반기",
            value: `${formatMoney(65000)}원`
          },
          {
            indent: 1,
            key: "자동차세",
            value: `${formatMoney(50000)}원`
          },
          {
            indent: 1,
            key: "지방교육세",
            value: `${formatMoney(15000)}원\n(자동차세의 30%)`
          },
          {
            indent: 0,
            key: "2. 하반기",
            value: `${formatMoney(65000)}원`
          },
          {
            indent: 1,
            key: "자동차세",
            value: `${formatMoney(50000)}원`
          },
          {
            indent: 1,
            key: "지방교육세",
            value: `${formatMoney(15000)}원\n(자동차세의 30%)`
          },
          {
            indent: 0,
            key: currentYear + "년 자동차세총합",
            value: `${formatMoney(130000)}원`
          },
        ]}
      />
    </div>
    <div style={{background: '#EEF9F2', borderRadius: 5, padding: 8, margin: '0 16px'}}>
      <Caution title={"이렇게 해석하시면 돼요!"}>
        <ol style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px 0px'}}>
          <li><span>위 결과는 전기/수소 승용자동차에 해당하는 결과입니다.</span></li>
          <li><span>전기/수소자동차는 매년 13만원 동일한 자동차세를 납부합니다.</span></li>
          <li><span>위 결과는 자동차를 1년동안 보유하였을 때의 금액으로, 연중에 신규/이전 등록 또는 폐차한 경우 소유한 기간만큼의 세금만 납부합니다.</span></li>
          <li><span>자동차세는 상반기 1회(6월), 하반기 1회(12월) 총 2번 납부합니다.</span></li>
        </ol>
      </Caution>
      <Notice title={"기능 개선 중입니다! 기다려주세요!"}>
        <ul style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 0 0px'}}>
          <li><span>승합차, 화물차, 특수차 등도 계산할 수 있어요.</span></li>
          <li><span>영업용 차량도 계산할 수 있어요.</span></li>
          <li><span>연 중 실제 소유기간을 반영하여 세금을 계산할 수 있어요.</span></li>
          <li><span>정부에서 논의중인 자동차세 세재개편 내용도 적용할 수 있어요.</span></li>
        </ul>
      </Notice>
    </div>
  </>
}

let timer: ReturnType<typeof setTimeout> | null = null;

const CarTaxPage = () => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null)
  const [path, setPath] = useState(["step1"])
  const [showLoading, setShowLoading] = useState(false)
  const [data, setData] = useState<CarTax>({
    type: "",
    displacement: "",
    registeredAt: "",
    registeredAtHalf: ""
  })

  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (escape) {
      escape = false
      return false
    }


    const last = path[path.length - 1]
    if (last === "step1") {
      if (showLoading) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
          setShowLoading(false)
        }
        return true
      }
      return false
    } else if (last === "step2") {
      if (showLoading) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
          setShowLoading(false)
        }
        return true
      }
      path.pop()
      setPath([...path])
      return true
    }
    else if (["result", "result_elect"].includes(last)) {
      return false
    } else {
      path.pop()
      setPath([...path])
      return true
    }
  });

  const onChange = (newData: object) => {
    setData({...data, ...newData})
  }

  const moveToTip = () => {
    trackEvent(EVENT_TAX_CALC_NEXT_CLICK, {type: "car_tax", current_step: 'result'})
    navigate(-1)
  }

  const renderMiddle = () => {
    const current = path[path.length - 1]
    switch (current) {
      // case "intro":
      //   return <Intro key={'intro'}/>
      case "step1":
        return <Step1 key={'step1'} data={data} onChange={onChange}/>
      case "step2":
        return <Step2 key={'step2'} data={data} onChange={onChange}/>
      case "result":
        return <StepResult key={'result'} data={data}/>
      case "result_elect":
        return <StepResultElect key={'result_elect'} data={data}/>
    }
  }

  const renderBottom = () => {
    switch (path[path.length - 1]) {
      // case "intro":
      //   return <FillButton text={"시작하기"} onClick={onNext}/>
      case "step1":
        return <FillButton text={"다음"} onClick={onNext} active={data.type !== ""}/>
      case "step2":
        return <FillButton text={"자동차세 계산"} onClick={onNext} active={data.registeredAt !== "" && parseInt(data.registeredAt) >= 1900 && data.registeredAtHalf !== "" && data.displacement !== ""}/>
      case "result":
        return <div style={{display: 'flex', gap: 16}}>
          <IconFillButton icon={<IcReset style={{height: 30}}/>} text={"다시하기"} onClick={onReset} style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}}/>
          <FillButton text={"절세 팁 보기"} onClick={moveToTip}/>
        </div>
      case "result_elect":
        return <div style={{display: 'flex', gap: 16}}>
          <IconFillButton icon={<IcReset style={{height: 30}}/>} text={"다시하기"} onClick={onReset} style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}}/>
          <FillButton text={"절세 팁 보기"} onClick={moveToTip}/>
        </div>
    }
    return <FillButton text={"시작하기"} onClick={onNext}/>
  }

  const onReset = () => {
    trackEvent(EVENT_TAX_CALC_RESET_CLICK, {type: "car_tax"})
    setData({
      type: "",
      displacement: "",
      registeredAt: "",
      registeredAtHalf: ""
    })
    setPath(["step1"])
  }

  const onNext = () => {
    const current = path[path.length - 1]
    trackEvent(EVENT_TAX_CALC_NEXT_CLICK, {type: "car_tax", current_step: current})

    if (current === "intro") {
      const newPath: string[] = [...path, "step1"]
      setPath(newPath)
    } else if (current === "step1") {
      if (data.type === "전기/수소 자동차") {
        const newPath: string[] = [...path, "result_elect"]
        setShowLoading(true)
        timer = setTimeout(() => {
          setShowLoading(false)
          setPath(newPath)
        }, 2000)
      } else {
        const newPath: string[] = [...path, "step2"]
        setPath(newPath)
      }
    } else if (current === "step2") {
      const newPath: string[] = [...path, "result"]
      setShowLoading(true)
      timer = setTimeout(() => {
        setShowLoading(false)
        setPath(newPath)
      }, 2000)
    }
  }

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTo(0, 0)
    }
  }, [path]);

  return <>
    <DefaultPageRoot
      top={<>
        <AppInstallGuideBanner />
        <BackNavToolbar title={"자동차세(보유) 계산기"}/>
      </>}
      middleRef={ref}
      middle={renderMiddle()}
      bottom={<div style={{margin: 16}}>
        {renderBottom()}
      </div>}
    />

    {
      showLoading && <LoadingModal>
          <p style={{textAlign: 'center', fontWeight: 500, margin: 0, lineHeight: 1.5, whiteSpace: 'pre-wrap'}}>{"자동차세 예상납부액을\n계산 중입니다.\n잠시만 기다려주세요."}</p>
      </LoadingModal>
    }
  </>
}

export default CarTaxPage;