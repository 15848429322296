import { Activity } from "@model/Activity";
import convertActivity from "./convertActivity";

// TODO: repository 만들어서 리팩토링

const convertActivities = (jsonActivities: any): Activity[]  => {
    return jsonActivities.map((i: any) => convertActivity(i))
}

export default convertActivities;
