import React, {ReactNode} from "react";

const SectionHeaderStyle: React.CSSProperties = {
  position: 'sticky',
  margin: 0,
  top: 0,
  fontSize: '1.5rem',
  fontWeight: 700,
  background: 'white',
  padding: 16
}

const ExpandableListHeader = ({title}: {title: string}) => {
  return <p style={SectionHeaderStyle}>{title}</p>
}

export default ExpandableListHeader;