import {useParams} from "react-router-dom";
import {FillButton} from "../components/Buttons";
import {useState} from "react";
import {DialogDrawerTemplate} from "../components/DrawerTemplate";
import {fetchWithRetry} from "../util/networks";
import server_domain from "../components/RemoteServer";
import styles from "@src/features/job/features/apply/style/input.module.css";

interface Form {
  title: string;
  description: string;
  question: string
  options: Option[]
}

interface Option {
  key: string
  label: string
}

const form: { [key: string]: Form } = {
  "reject": {
    "title": "위즈덤 이력서 거부 요청",
    "description": "안녕하세요 채용담당자님, 혹시라도 불편을 드렸다면 먼저 사과의 말씀을 드립니다. 아래의 질문에 답변해주시면 더 이상 이력서를 보내지 않겠습니다.",
    "question": "위즈덤 이력서를 더 이상 안 받으시려는 이유는 무엇인가요? (중복 선택 가능)",
    "options": [
      {
        "key": "Age",
        "label": "위즈덤 지원자들의 나이가 너무 많아요."
      },
      {
        "key": "Email",
        "label": "우리 회사는 이메일로 지원 안 받아요."
      },
      {
        "key": "DUP",
        "label": "동일한 지원자들의 중복지원이 너무 많아요."
      },
      {
        "key": "WizdomAttitude",
        "label": "위즈덤을 통해 채용해봤지만 구직자의 성과와 태도가 좋지 않았어요."
      }
    ]
  },
  "agree": {
    "title": "위즈덤 이력서 수신 요청",
    "description": "안녕하세요 채용담당자님, 위즈덤 이력서를 잘 검토해주셔서 감사합니다. 아래의 질문에 답변해주시면 서비스 발전에 활용하겠습니다.",
    "question": "위즈덤 이력서를 계속 받으시려는 이유는 무엇인가요? (중복 선택 가능)",
    "options": [
      {
        "key": "Age",
        "label": "위즈덤 지원자들의 나이가 우리 회사가 뽑으려는 연령대에요."
      },
      {
        "key": "More",
        "label": "더 많은 지원자가 필요해서 위즈덤 이력서도 계속 받고 싶어요."
      },
      {
        "key": "WizdomAttitude",
        "label": "위즈덤을 통해 채용을 해보았고 구직자의 성과와 태도가 좋았어요."
      }
    ]
  }
}

const translation_mode: { [key: string]: string } = {
  "reject": '거부',
  "agree": '수락'
}

const SurveySimpleApply = () => {
  const {mode, activityId} = useParams()
  const [selected, setSelected] = useState<Set<string>>(new Set())
  const [text, setText] = useState<string>("")
  const [showModal, setShowModal] = useState<boolean>(false)

  function sendEvent() {
    if (mode != undefined) {
      fetchWithRetry(server_domain() + "/alert_to_survey", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({"activity_id": activityId, "options": Array.from(selected).join(','), "survey_mode": translation_mode[mode], "text": text})
      }).then(res => {
        if (res.ok) {
          setShowModal(true)
          setSelected(new Set())
          setText("")
        } else {
          // TODO toast
        }
      })
    } else {
      // TODO toast
    }
  }


  function onChange(key: string) {
    if (selected.has(key)) {
      const n = new Set(selected)
      n.delete(key)
      setSelected(n)
    } else {
      setSelected(new Set(selected).add(key))
    }
  }

  if (mode == undefined || !(mode in form)) {
    return <div> NOT AVAILABLE </div>
  }

  return (<div>
    <DialogDrawerTemplate show={showModal} onClose={() => {}}>
      <p>응답을 제출하였습니다.</p>
      <FillButton
        text={"창 닫기"}
        onClick={() => close()}
      />
    </DialogDrawerTemplate>

    <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
      <div style={{flex: 1, overflow: 'scroll', padding: 10}}>
        <p style={{color: '#0ABF53', fontWeight: 900, fontSize: '1.5rem'}}>WIZDOM</p>
        <p style={{textAlign: 'center', fontSize: '2rem', fontWeight: 900, lineHeight: 1.5, color: '#0ABF53'}}>{form[mode].title}</p>
        <p style={{fontSize: '0.9rem', fontWeight: 900, lineHeight: 1.5}}>{form[mode].description}</p>
        <p style={{fontSize: '0.9rem', fontWeight: 900, lineHeight: 1.5}}>{form[mode].question}</p>

        {
          Object.entries(form[mode].options).map(([idx, ele]) => (
            <div style={{display: 'flex', alignItems: 'center', margin: '10px 0'}}>
              <input checked={selected?.has(ele.key)} onChange={() => onChange(ele.key)} style={{width: 20, height: 20}} type={"checkbox"}/><span style={{marginLeft: 10}}>{ele.label}</span>
            </div>
          ))
        }

        <div style={{width: '100%', margin: '20px 0'}}>
          <p style={{margin: '10px 0'}}>기타의견</p>
          <textarea className={styles.textarea} value={text} onChange={(e) => setText(e.target.value)} rows={3} style={{width: '100%', resize: 'none', padding: 10, fontSize: '1rem', boxSizing: 'border-box'}}/>
        </div>
      </div>
      <div style={{padding: 10}}>
        <FillButton
          active={selected.size > 0 || text != ""}
          text={"제출하기"}
          onClick={sendEvent}
        />
      </div>
    </div>
  </div>);
}

export default SurveySimpleApply;