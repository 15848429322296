export interface CommunityProfile {
    post_count: number
    comment_count: number
    give_like_count: number
    wizdom_value: number
}

export const initialProfile: CommunityProfile = {
    post_count: 0,
    comment_count: 0,
    give_like_count: 0,
    wizdom_value: 0,
}