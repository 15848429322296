export type MemberLevelValue = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface MemberLevel {
    value: MemberLevelValue
    name: string,
    range: number[]
}

export const MemberLevels: MemberLevel[] = [
    { value: 0, name: '신규회원', range: [0, 3]},
    { value: 1, name: '정회원', range: [4, 199]},
    { value: 2, name: '성실회원', range: [200, 999]},
    { value: 3, name: '열심회원', range: [1000, 3999]},
    { value: 4, name: '우수회원', range: [4000, 8999]},
    { value: 5, name: '고마운회원', range: [9000, 10000]},
    { value: 6, name: '운영자', range: [40000, 100000]},
]

export const getMemberTitleFromLevel = (level: MemberLevelValue) => {
    return MemberLevels[level].name
}

export const getNextLevelTitle: (point: number) => string = (point: number) => {
    return MemberLevels.find(e => getLevelFromPoint(point).value + 1 === e.value)?.name ?? ''
}

export const getLevelFromPoint: (point: number) => MemberLevel = (point: number) => {
    return MemberLevels.find(e => e.range[0] <= point && point <= e.range[1])!
}

export const getLevelValueFromPoint: (point?: number | null) => MemberLevelValue = (point?: number | null) => {
    if (point === undefined || point === null) return 0
    return MemberLevels.find(e => e.range[0] <= point && point <= e.range[1])?.value ?? 0
}

export const getMemberTitleFromPoint = (point: number) => {
    const level = MemberLevels.find(e => e.range[0] <= point && point <= e.range[1])
    return level?.name
}
