import { createSlice } from "@reduxjs/toolkit"
import { queryClient } from ".."
import { PopularActivityPage } from "@model/PopularActivityPage"
import { MainPageLoaderResponse } from "@src/data-layer/mainPageLoader"

interface ActivityCacheState {
    mainPage: MainPageLoaderResponse | null,
    popularActivityPage: PopularActivityPage | null,
    simpleApplyActivity: any
}

const activityCacheSlice = createSlice({
    name: 'activityCache',
    initialState: {
        mainPage: null,
        popularActivityPage: null,
        simpleApplyActivity: null
    } as ActivityCacheState,
    reducers: {
        cleanCacheActivities: (state) => {
            state.mainPage = null
            state.popularActivityPage = null
            queryClient.removeQueries('activities')
        },
        cleanMainPage: (state) => {
            state.mainPage = null
        },
        updateMainPage: (state, action) => {
            state.mainPage = action.payload
        },
        updatePopularActivityPage: (state, action) => {
            state.popularActivityPage = action.payload
        },
        updateSimpleApplyActivity: (state, action) => {
            state.simpleApplyActivity = action.payload.value
        },
    }
})

export const { 
    cleanCacheActivities, 
    cleanMainPage,
    updateMainPage,
    updatePopularActivityPage, 
    updateSimpleApplyActivity
} = activityCacheSlice.actions
export default activityCacheSlice.reducer

