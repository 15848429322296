import {useNavigate} from "react-router-dom";
import React from "react";
import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {FillButton} from "@components/Buttons";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {ResumeCardState} from "@reducer/userSlice";
import {zeroPad} from "@components/form/ResumeComponent";
import {updateLastSelectResumeId} from "@reducer/configSlice";
import trackEvent from "@util/trackEvent";
import {EVENT_RESUME_EDIT, EVENT_RESUME_PREVIEW} from "@components/AppConstant";

const formatDateISO = (date: string) => {
  const theDay = new Date(date)
  const year = theDay.getFullYear()
  const month = zeroPad(theDay.getMonth() + 1, 2)
  const day = zeroPad(theDay.getDate(), 2)
  return `${year}${month ? "." : ""}${month}${day ? "." : ""}${day}`
}

const ResumeItem = ({resume, selected, onClick}: { resume: ResumeCardState, selected: boolean, onClick: () => void }) => {
  const navigate = useNavigate();

  const editResume = () => {
    trackEvent(EVENT_RESUME_EDIT, {location: "SimpleApplyPage", resume_id: resume.id})
    navigate("edit_resume/" + resume.id)
  }

  const previewResume = () => {
    trackEvent(EVENT_RESUME_PREVIEW, {location: "SimpleApplyPage", resume_id: resume.id})
    navigate("resume_preview/" + resume.id)
  }

  return <div style={{padding: '10px', border: selected ? '1px solid #0ABF53' : '1px solid #999', borderRadius: 5, marginBottom: 10}} onClick={onClick}>
    <div style={{display: 'flex'}}>
      <div style={{position: 'relative', width: 16, height: 16, borderRadius: 10, background: selected ? '#0ABF53' : 'white', margin: '7px 10px 0 0', border: selected ? '1px solid transparent' : '1px solid #999' }}>
        <div style={{position: 'absolute', top: 4, left: 4, width: 8, height: 8, borderRadius: 15, background: 'white',}} />
      </div>
      <div>
        <p style={{margin: 0, lineHeight: 1.5}}>{resume.title || ""}</p>
        <p style={{margin: 0, lineHeight: 1.5, fontSize: '0.75rem', color: '#666'}}>최종수정일: {formatDateISO(resume.updated_at)}</p>
      </div>
    </div>


    <div style={{display: 'flex', marginTop: 10, gap: 10}}>
      <span style={{flex: 1, textAlign: 'center', border: '1px solid #999', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={previewResume}>보기</span>
      <span style={{flex: 1, textAlign: 'center', border: '1px solid #999', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={editResume}>수정</span>
    </div>
  </div>
}


const SelectResume = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resumes = useSelector((state: RootState) => state.user.resumes)
  const lastSelectResumeId = useSelector((state: RootState) => state.config.lastSelectResumeId)
  const lastUsedResumeId = useSelector((state: RootState) => state.user.lastUsedResumeId)
  const selected = lastSelectResumeId || lastUsedResumeId || resumes[0].id || ""

  const available = () => !!selected && resumes.map(resume => resume.id).includes(selected)

  const onClick = (resume: ResumeCardState) => dispatch(updateLastSelectResumeId(resume.id))
  const next = () => {
    if (selected !== null) {
      navigate(-1)
    }
  }

  return <DefaultPageRoot
    top={<BackNavToolbar title={"이력서 선택"}/>}
    middle={<div style={{padding: '0 16px'}}>
      {
        resumes.map(resume => <ResumeItem resume={resume} selected={resume.id === selected} onClick={() => onClick(resume)}/>)
      }
    </div>}
    bottom={<div style={{padding: 16}}>
      <FillButton text={"선택한 이력서 사용하기"} onClick={next} active={available()}/>
    </div>}
  />
}

export default SelectResume;