import ImgSentEmail from "@assets/img_sent_email.webp";
import {BorderButton} from "@components/Buttons";
import {FullscreenDrawerTemplate} from "@components/DrawerTemplate";
import React, {ReactElement} from "react";
import {useNavigate} from "react-router-dom";
import trackEvent from "@util/trackEvent";
import {EVENT_FAQ_ENTRY_CLICK} from "@components/AppConstant";

const GuideComplete = ({children, activityId, show, onClose}: {children: React.ReactNode, activityId: string, show: boolean, onClose: () => void}) => {
  const navigate = useNavigate();

  return <FullscreenDrawerTemplate show={show} onClose={onClose}>
    <div style={{height: '100%', position: 'relative'}}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <div style={{margin: '12px 0', textAlign: 'center'}}>
          {children}
          <div style={{borderTop: '1px solid #D9D9D9', margin: '40px 0'}} />
          <p style={{margin: '0 0 20px', lineHeight: 1.5, whiteSpace: 'pre-wrap', fontWeight: 500}}>{"지원에 어려움이 있나요?\n도와드릴게요!"}</p>
          <BorderButton style={{width: 200, display: 'inline-block'}} text={"지원 도움 받기"} onClick={() => {trackEvent(EVENT_FAQ_ENTRY_CLICK, {location: "DoneApplyMethodCheck"}); navigate('faq/' + activityId)}}/>
        </div>
      </div>
    </div>
  </FullscreenDrawerTemplate>
}

export default GuideComplete;