import { createSlice } from "@reduxjs/toolkit";
import { Activity } from "@src/model/Activity";

interface ApplicationState {
    value: { [activityId: string]: Activity },
    questionCount: number
}

const applicationSlice = createSlice({
    name: 'application',
    initialState: {
        value: {},
        questionCount: 0,
    } as ApplicationState,
    reducers: {
        appendApplication: (state, action) => {
            let activity = action.payload
            state.value[activity.activityId] = activity
        },
        removeApplication: (state, action) => {
            let activity = action.payload
            delete state.value[activity.activityId]
        },
        cleanApplication: (state) => {
            state.value = {}
        },
        setQuestionCount: (state, action) => {
            state.questionCount = action.payload
        }
    }
})

export const { appendApplication, removeApplication, cleanApplication, setQuestionCount } = applicationSlice.actions
export default applicationSlice.reducer
