import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Button, Description} from "@components/form/ResumeComponent";
import React from "react";
import {setEducationTemp} from "@reducer/userSlice";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import uuidv4 from "@util/uuidv4";
import {RootState} from "@reducer/store";


const ExistedEducation = ({label}: {label: string}) => {
  return <div style={{ padding: '10px 0'}}>
    <div style={{background: '#F4F4F4', borderRadius: 5, textAlign: 'center', padding: '5px 0'}}>
      <p style={{lineHeight: 1.5, margin: 0, fontSize: '1.125rem'}}>{label}</p>
      <p style={{lineHeight: 1.5, margin: 0, fontSize: '0.875rem'}}>이미 입력했어요</p>
    </div>
  </div>
}

const EditResumeCheckEducation = () => {
  const dispatch = useDispatch();
  const education = useSelector((state: RootState) => state.user.resumeTemp.education)
  const navigate = useNavigate();
  return <DefaultPageRoot
    top={<BackNavToolbar title={""}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <Description text={"추가할 학력을 선택해주세요"}/>
      {education.find(edu => edu.degree === "phd") ? <ExistedEducation label={"대학원 박사"}/>: <Button checked={false} label={"대학원 박사"} onClick={() => navigate('../education_phd', {replace: true})}/>}
      {education.find(edu => edu.degree === "master") ? <ExistedEducation label={"대학원 석사"}/>: <Button checked={false} label={"대학원 석사"} onClick={() => navigate('../education_master', {replace: true})}/>}
      {education.find(edu => edu.degree === "bachelor") ? <ExistedEducation label={"대학교"}/>: <Button checked={false} label={"대학교"} onClick={() => navigate('../education_bachelor', {replace: true})}/>}
      {education.find(edu => edu.degree === "high_school") ? <ExistedEducation label={"고등학교"}/>: <Button checked={false} label={"고등학교"} onClick={() => navigate('../education_high_school', {replace: true})}/>}
      {education.find(edu => edu.degree === "middle_under") ? <ExistedEducation label={"중학교 이하"}/>: <Button checked={false} label={"중학교 이하"} onClick={() => {
        dispatch(setEducationTemp({
            uuid: uuidv4(),
            name: "중학교 이하",
            major: "",
            status: "",
            startDate: "",
            endDate: "",
            degree: "middle_under"
          }
        ))
        navigate(-1)
      }}/>}



    </div>}
  />
}

export default EditResumeCheckEducation;