import FinanceListPage from "@src/features/finance/page/FinanceListPage";
import SavingInstallmentAccountPage from "@src/features/finance/features/saving_installment_account/page/SavingInstallmentAccountPage";
import SavingAccountProductsPage from "@src/features/finance/features/saving_installment_account/page/SavingAccountProductsPage";
import InstallmentSavingAccountProductsPage from "@src/features/finance/features/saving_installment_account/page/InstallmentSavingAccountProductsPage";
import SavingInstallmentAccountProductDetailPage from "@src/features/finance/features/saving_installment_account/page/SavingInstallmentAccountProductDetailPage";
import SavingInstallmentAccountProductSimulationPage from "@src/features/finance/features/saving_installment_account/page/SavingInstallmentAccountProductSimulationPage";

const SavingAccountSimulationPage = {path: 'simulation', element: <SavingInstallmentAccountProductSimulationPage />}
const SavingAccountDetailPage = {path: ':productId', element: <SavingInstallmentAccountProductDetailPage/>, children: [SavingAccountSimulationPage]}

export const FinanceRouter = {
  path: "finance", element: <FinanceListPage/>, children: [
    {
      path: "saving_installment_account", element: <SavingInstallmentAccountPage/>, children: [
        {path: "saving_account_products", element: <SavingAccountProductsPage/>, children: [SavingAccountDetailPage]},
        {path: "installment_saving_account_products", element: <InstallmentSavingAccountProductsPage/>, children: [SavingAccountDetailPage]},
      ]
    }
  ]
}