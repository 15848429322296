import PropertyTaxPage from "@src/features/tax_saving/features/property_tax/page/PropertyTaxPage";
import PropertyTaxTips from "@src/features/tax_saving/features/property_tax/page/PropertyTaxTips";
import CarTaxPage from "@src/features/tax_saving/features/car_tax/page/CarTaxPage";
import CarTaxTipsPage from "@src/features/tax_saving/features/car_tax/page/CarTaxTips";
import TaxListPage from "@src/features/tax_saving/page/TaxListPage";
import TaxMorePage from "@src/features/tax_saving/page/TaxMorePage";

export const TaxSavingRouter = {
  path: 'save_tax',
  element: <TaxListPage />,
  children: [
    {path: 'car', element: <CarTaxTipsPage />},
    {path: 'car/calc', element: <CarTaxPage />},
    {path: 'property', element: <PropertyTaxTips />},
    {path: 'property/calc', element: <PropertyTaxPage />},
    {path: 'more', element: <TaxMorePage />},
  ]
}