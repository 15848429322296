import { FillButton } from "@src/components/Buttons";
import { fontSize } from "@src/components/font";
import { DefaultPageRoot } from "@src/components/PageTemplate";
import { MediumText_22 } from "@src/components/text";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import trackEvent from "@src/util/trackEvent";
import { EVENT_EDIT_NICKNAME_COMPLETE, EVENT_EDIT_NICKNAME_PAGE_SHOW } from "@src/components/AppConstant";
import { RootState } from "@src/reducer/store";
import { BackNavToolbar } from "@src/components/Toolbars";
import { useLocation, useNavigate } from "react-router-dom";
import useOnLog from "@src/components/useOnLog";
import { changeNickname } from "@src/reducer/userSlice";
import { show } from "@src/reducer/snackbarSlice";
import IcClose from "@src/assets/close.svg";
import { grey666 } from "@src/components/Color";
import CommunityService from "../infra/CommunityService";
import styles from "@src/features/job/features/apply/style/input.module.css";

const EditNicknamePage = () => {
    const ref = useRef<HTMLInputElement>(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const uuid = useSelector((state: RootState) => state.user.uuid)
    const duplicateNicknameList: string[] = []
    const deviceId = useSelector((state: RootState) => state.user.deviceId)
    const currentNickname = useSelector((state: RootState) => state.user.nickname!)
    const [active, setActive] = useState(currentNickname ? true : false)
    const state = useLocation().state as any
    const autoGenerated: boolean = state.autoGenerated as boolean
    const source = state.source
    const [nickname, setNickname] = useState(autoGenerated ? '' : currentNickname)
        
    useOnLog({
        pageNameKey: EVENT_EDIT_NICKNAME_PAGE_SHOW,
        regStr: "\/edit-nickname$",
        amplitudeProp: {
            source: source
        }
    })

    const onChange = (value: string) => {
        setNickname(value)

        if (value.length > 10 || value.length < 2) {
            setActive(false)
            return
        }
        setActive(true)
    }

    const onRegister = async () => {
        if (!uuid) { return }
        const result = await CommunityService.setNickname({ userId: uuid, nickname: nickname, deviceId: deviceId })

        if (result === "true") {
            trackEvent(EVENT_EDIT_NICKNAME_COMPLETE, {
                source: source,
            })
            dispatch(changeNickname(nickname))
            navigate(-1)
        } else if (result === "used") {
            dispatch(show({message: '이미 사용중인 별명입니다.'}))
            duplicateNicknameList.push(nickname)
        } else {
            dispatch(show({message: '닉네임 설정에 실패했습니다. 다시 시도해주세요.'}))
        }
    }

    useEffect(() => {
        ref.current?.focus()
    }, [ref.current])

    return (
        <DefaultPageRoot
            top={
                <BackNavToolbar title="별명 변경"/>
            }
            middle={
                <>
                    <div>
                        <div style={{ paddingTop: '20px', paddingLeft: '15px' }}>
                            <MediumText_22 style={{ display: 'block', lineHeight: '1.5' }}>별명</MediumText_22>
                        </div>
                        <div style={{ display: 'flex', paddingTop: '20px'}}>
                            <input
                                className={styles.input}
                                ref={ref}
                                value={nickname}
                                onChange={(e) => onChange(e.target.value)}
                                placeholder="2~10자까지 가능"
                                min={2}
                                max={10}
                                required={true}
                                style={{ 
                                    fontSize: fontSize.m,
                                    borderRadius: '5px',
                                    width: 'calc(100% - 60px)',
                                    margin: '0 15px 5px 15px',
                                    padding: '15px 15px',
                                }}
                            />
                            <div style={{position: 'absolute', right: '15px', marginRight: '5px', height: '53px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => onChange('')}>
                                <div style={{width: '20px', height: '20px', borderRadius: '10px', backgroundColor: grey666, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <IcClose color='white' width='10px' height='10px' strokeWidth={'3px'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            bottom={
                <div style={{ padding: '15px' }}>
                    <FillButton
                        text="완료"
                        active={active}
                        onClick={() => {
                            onRegister()
                        }}
                    />
                </div>
            }
        />
    )
}
export default EditNicknamePage;