import { useNavigate, useSearchParams } from "react-router-dom";
import IcArrowBack from '@assets/arrow_back.svg';
import { BoldText_25, MediumText_25 } from "./text";
import icHome from '@assets/ic_home_inactive.webp';
import { primary } from "./Color";
import trackEvent from "@src/util/trackEvent";
import { EVENT_CLICK_HOME } from "./AppConstant";

const BackNavToolbar = ({
    title,
    to = -1,
    titleElement,
    location,
    icon,
    trailing,
    customBackHandler,
    onBeforeBack,
}: {
    title?: string,
    to? : number | string,
    titleElement?: JSX.Element,
    location?: string,
    icon?: JSX.Element,
    trailing?: JSX.Element,
    customBackHandler?: React.MouseEventHandler,
    onBeforeBack?: () => void,
}) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const showInstallBanner = (searchParams.get("show_install_banner") === "true")
    const backToHome = (searchParams.get("back_to_home") === "true")
    const backTo = searchParams.get("back_to")

    const handleBack: React.MouseEventHandler = customBackHandler ? customBackHandler : (e) => {
        if (onBeforeBack) {
            onBeforeBack();
        }
        
        e.preventDefault();
        e.stopPropagation();
        if (backToHome) {
            navigate({
                pathname: "/",
                search: `?show_install_banner=${showInstallBanner}`
            }, {
                replace: true
            })
        } else if (backTo){
            navigate({
                pathname: backTo.startsWith('/') ? backTo : '/' + backTo,
                search: `?show_install_banner=${showInstallBanner}`
            }, {
                replace: true
            })
        } else if (Object.prototype.toString.call(to).slice(8, -1) === 'number') {
          navigate(to as number);
        } else {
          navigate(to as string);
        }
    }
    


    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', padding: '0px 16px', position: 'relative', minHeight: '75px' }}>
                <div style={{display: 'flex', alignItems: 'center', width: '50px', height: '75px'}} onClick={handleBack}>
                    <button
                        style={{
                            border: 'none',
                            background: 'none',
                            justifyContent: 'left',
                            color: '#1D1D1D',
                            padding: '0px',
                            margin: '0px',
                            width: 'auto',
                            minWidth: 'initial',
                        }} >
                        {icon ? icon : <IcArrowBack />}
                    </button>
                </div>
                {titleElement && <div style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        width: '80%',
                        maxWidth: 'initial',
                        transform: 'translate(-50%, -50%)',}}>{titleElement}</div>}
                {(!titleElement && title) && <p
                    style={{
                        margin: 0,
                        lineHeight: 1.5,
                        fontWeight: 500,
                        position: 'absolute',
                        textAlign: 'center',
                        left: '50%',
                        color: '#1D1D1D',
                        fontSize: '1.25rem',
                        top: '50%',
                        width: '80%',
                        maxWidth: 'initial',
                        transform: 'translate(-50%, -50%)',
                    }}
                >{title}</p>}

                {trailing && <div style={{position: 'absolute', right: '16px'}}>{trailing}</div> }
            </div>
        </div>
    )
}

const HomeNavToolbar = (
    { location, title, trailing }: { location: string, title: string, trailing?: JSX.Element,}
) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const showInstallBanner = (searchParams.get("show_install_banner") === "true")
    
    return (
        <BackNavToolbar
            icon={<img src={icHome} width={'24px'} height={'24px'}/>}
            customBackHandler={() => {
                trackEvent(EVENT_CLICK_HOME, { 'source': location })

                if (showInstallBanner) {
                    navigate({pathname: '/', search: `?show_install_banner=${showInstallBanner}`}, { replace: true })
                    return
                }
                navigate('/', { replace: true })
            }}
            titleElement={
                <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', gap: '4px'}}> 
                    <BoldText_25 style={{color: primary}}>위즈덤</BoldText_25>
                    <MediumText_25 style={{color: primary}}>{title}</MediumText_25>
                </div>
            }
            trailing={trailing}
        />
    )
}

export { BackNavToolbar, HomeNavToolbar };