import { Activity } from "@src/model/Activity";

const isSimpleApply = (activity: Activity) => {
    try {
        let applyMethods = activity.applyMethod.split(",");
        if (applyMethods.length === 0) {
            return false;
        }
        return applyMethods.includes("간편지원");
    } catch (e) {
        return false;
    }
}

export default isSimpleApply;