import ActivityCard from '../features/job/component/ActivityCard';
import { useSelector } from 'react-redux';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import { RefObject, useRef } from 'react';
import { RootState } from '@reducer/store';
import { Activity } from '@src/model/Activity';
import FilterResultFooter from './FilterResultFooter';

const FilterResultFooterNumber = 1

const ActivityList = ({ activities, location, filterEnabled, noContentView}: {
    activities: Activity[],
    location: string,
    filterEnabled: boolean,
    noContentView: JSX.Element,
}): JSX.Element => {
    const parentRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const regions = useSelector((state: RootState) => state.user.regions);
    const virtualizer = useVirtualizer({
        count: activities.length + (filterEnabled ? FilterResultFooterNumber : 0),
        getScrollElement: () => parentRef.current,
        estimateSize: () => 200,
        overscan: 10
    })
    const items: VirtualItem[] = virtualizer.getVirtualItems()

    if (activities.length === 0) {
        return noContentView;
    }

    return (
        <>
            <div
                ref={parentRef}
                style={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    contain: 'strict',
                }}
            >
                <div
                    style={{
                        height: virtualizer.getTotalSize(),
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${items[0]?.start ?? 0}px)`,
                        }}
                    >
                        {items.map((virtualRow: VirtualItem, idx) => {
                            if (filterEnabled && idx === items.length - 1) {
                                return (
                                    <div
                                        key={virtualRow.key}
                                        data-index={virtualRow.index}
                                        ref={virtualizer.measureElement}
                                    >
                                        <FilterResultFooter />
                                    </div>
                                )
                            }
                            return (
                                <div
                                    key={virtualRow.key}
                                    data-index={virtualRow.index}
                                    ref={virtualizer.measureElement}
                                >
                                    <ActivityCard key={virtualRow.index} activity={activities[virtualRow.index]} regions={regions} location={location} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivityList;