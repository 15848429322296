import "@components/odometer/style.css";
import {useEffect, useState} from "react";

export const OdoMeter = ({value, duration, size, color} : {value: number, duration: number, size: string, color: string}) => {
  const [dd, setDd] = useState("")

  useEffect(() => {
    if (dd.length !== value.toString().length) {
      setDd("0".repeat(value.toString().length))
    }
    setTimeout(() => {
      setDd(value.toString())
    },100)
  }, [value]);

  return (
    <div className="speedo-wrap" style={{height: size, fontSize: size, lineHeight: size}}>
      {(dd).split("").map((val, idx) => (
        <>
          <div className="speedo-digits" style={{color: color, marginTop: `calc( -${val}em `, transition: `${duration}ms all`, transitionDelay: `${((value + "").split("").length - idx) * 20}ms`}}>
            <div className="speedo-digit" data-val="0">0</div>
            <div className="speedo-digit" data-val="1">1</div>
            <div className="speedo-digit" data-val="2">2</div>
            <div className="speedo-digit" data-val="3">3</div>
            <div className="speedo-digit" data-val="4">4</div>
            <div className="speedo-digit" data-val="5">5</div>
            <div className="speedo-digit" data-val="6">6</div>
            <div className="speedo-digit" data-val="7">7</div>
            <div className="speedo-digit" data-val="8">8</div>
            <div className="speedo-digit" data-val="9">9</div>
          </div>
          {(dd.length - 1 !== idx) && (dd.length - idx) % 3 === 1 && <div>,</div>}
        </>
      ))}
    </div>
  );
}