import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {SectionHeaderStyle} from "@src/features/job/features/faq/style/style";
import {FillButton} from "@components/Buttons";
import IcLightBulb from "@assets/ic_lightbulb.webp";
import React, {useState} from "react";
import {TitleCenterDialogDrawerTemplate} from "@components/DrawerTemplate";
import {TelField} from "@components/form/ResumeComponent";
import {useBlocker, useParams} from "react-router-dom";
import {fetchWithRetry} from "@util/networks";
import server_domain from "@components/RemoteServer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {track} from "@amplitude/analytics-browser";
import trackEvent from "@util/trackEvent";
import {EVENT_FAQ_CLICK, EVENT_FAQ_CTA_CLICK, EVENT_FAQ_SUBMIT} from "@components/AppConstant";
import {show} from "@reducer/snackbarSlice";
import ExpandableItem from "@components/expandable/ExpandableItem";

const FaqView = () => {
  const dispatch = useDispatch()
  const {faqActivityId} = useParams();
  const [showAskModal, setShowAskModal] = React.useState(false)
  const [tel, setTel] = React.useState("")
  const uuid = useSelector((state: RootState) => state.user.uuid)
  const [key, setKey] = useState("")

  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (showAskModal) {
      setShowAskModal(false)
      return true
    }
    return false
  });

  const request = () => {
    trackEvent(EVENT_FAQ_SUBMIT, { user_id: uuid, activity_id: faqActivityId,})
    fetchWithRetry(server_domain() + '/request_faq', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        user_id: uuid,
        activity_id: faqActivityId,
        number: tel,
      })
    }).then(_ => {
      dispatch(show({message: "전화 상담 요청이 완료되었습니다."}))
      setShowAskModal(false)
    }).catch(_ => {
      dispatch(show({message: "오류가 발생했습니다."}))
      throw Error("전화 상담 요청 에러");
    })
  }

  const onClose = () => {
    setTel("")
    setShowAskModal(false)
  }

  return <>
    <DefaultPageRoot
      top={<BackNavToolbar title={"지원 안내"}/>}
      middle={<>
        <div style={{background: '#EEF9F2', display: 'flex', padding: 16, margin: 16, borderRadius: 5, alignItems: 'center'}}>
          <img src={IcLightBulb} alt={""} style={{height: 50, marginRight: 16}}/>
          <p style={{margin: 0, lineHeight: 1.5, whiteSpace: 'pre-wrap'}}>{"지원 관련하여 어려운 점을\n자세히 도와드릴게요!"}</p>
        </div>
        <p style={{margin: 16, fontWeight: 700}}>자주 겪는 문제들</p>
        <p style={SectionHeaderStyle}>컴퓨터 지원</p>
        <ExpandableItem title={"지원방법∙서류 이메일이 안 와요."} expanded={key==="1"} onClick={() => {trackEvent(EVENT_FAQ_CLICK, {title: "지원방법∙서류 이메일이 안 와요."}) ;setKey(key === "1" ? "" : "1")}}>
          <div style={{margin: 20}}>
            <span>1분 이내로 이메일이 도착해야 정상입니다. (1)메일 주소에 오타가 있는 경우, (2)이메일 수신함이 꽉찬 경우, (3)스팸 메일함에 도착한 경우 등 여러 원인이 있습니다. 전화 상담을 요청주시면 문제를 바로 해결해드리겠습니다.</span>
          </div>
        </ExpandableItem>
        <ExpandableItem title={"이메일/카톡 첨부파일이 안 열려요."} expanded={key==="2"} onClick={() => {trackEvent(EVENT_FAQ_CLICK, {title: "이메일/카톡 첨부파일이 안 열려요."}) ;setKey(key === "2" ? "" : "2")}}>
          <div style={{margin: 20}}>
            <span>이메일과 카카오톡 내 첨부파일은 전부 컴퓨터에서 여셔야 합니다. 대부분의 파일은 스마트폰에서 열리지 않습니다. 또한 컴퓨터에 [한글] 소프트웨어가 있어야 원활하게 문서를 열람하실 수 있습니다. 전화 상담을 요청주시면 보다 자세히 설명드리겠습니다.</span>
          </div>
        </ExpandableItem>
        <ExpandableItem title={"컴퓨터/이메일/카카오톡이 없는데 지원하고 싶어요."} expanded={key==="3"} onClick={() => {trackEvent(EVENT_FAQ_CLICK, {title: "컴퓨터/이메일/카카오톡이 없는데 지원하고 싶어요."}) ;setKey(key === "3" ? "" : "3")}}>
          <div style={{margin: 20}}>
            <span>컴퓨터로 지원서를 작성해야하는 공고의 경우 컴퓨터가 없으면 지원이 어렵습니다. 하지만 경우에 따라 접수처에 직접 방문하여 서류를 작성하고 지원할 수도 있습니다. 전화 상담을 요청주시면 다른 방법으로 지원하는 것에 대해 알려드리겠습니다.</span>
          </div>
        </ExpandableItem>
        <ExpandableItem title={"지원 서류 작성법을 모르겠어요."} expanded={key==="4"} onClick={() => {trackEvent(EVENT_FAQ_CLICK, {title: "지원 서류 작성법을 모르겠어요."}) ;setKey(key === "4" ? "" : "4")}}>
          <div style={{margin: 20}}>
            <span>공고문에 지원 서류 작성법이 명시되어 있을 수도 있고 아닐 수도 있습니다. 전화 상담을 요청주시면 서류 작성의 어려운 부분을 도와드리겠습니다.</span>
          </div>
        </ExpandableItem>
        <ExpandableItem title={"지원서 제출방법을 모르겠어요."}  expanded={key==="5"} onClick={() => {trackEvent(EVENT_FAQ_CLICK, {title: "지원서 제출방법을 모르겠어요."}) ;setKey(key === "5" ? "" : "5")}}>
          <div style={{margin: 20}}>
            <span>작성한 지원서를 제출하는 방법에는 보통 이메일, 팩스, 우편, 방문 접수가 있고 공고별로 공고문 내에 제출방법이 명시되어 있습니다. 전화 상담을 요청주시면 제출 시 유의해야할 점들을 자세히 안내해드리겠습니다.</span>
          </div>
        </ExpandableItem>
        <ExpandableItem title={"워크넷지원 외의 방법은 없나요?"} expanded={key==="8"} onClick={() => {trackEvent(EVENT_FAQ_CLICK, {title: "워크넷지원 외의 방법은 없나요?"}) ;setKey(key === "8" ? "" : "8")}}>
          <div style={{margin: 20}}>
            <span>워크넷 지원만 허용하는 공고들이 있습니다. 하지만 일부 공고는 채용담당자에게 다른 지원 방법이 없는지 물어보고 양해를 구할 수 있습니다. 전화 상담을 요청주시면 채용담당자와 직접 소통하는 방법을 안내해드리겠습니다.</span>
          </div>
        </ExpandableItem>
        <p style={SectionHeaderStyle}>그 외 지원방법</p>
        <ExpandableItem title={"채용담당자 연락처가 없어요."} expanded={key==="6"} onClick={() => {trackEvent(EVENT_FAQ_CLICK, {title: "채용담당자 연락처가 없어요."}) ;setKey(key === "6" ? "" : "6")}}>
          <div style={{margin: 20}}>
            <span>채용담당자의 연락처가 누락되었을 수 있습니다. 전화 상담을 요청주시면 채용담당자의 연락처를 찾아서 전달해드리겠습니다.</span>
          </div>
        </ExpandableItem>
        <ExpandableItem title={"채용담당자가 전화를 안 받아요."} expanded={key==="7"} onClick={() => {trackEvent(EVENT_FAQ_CLICK, {title: "채용담당자가 전화를 안 받아요."}) ;setKey(key === "7" ? "" : "7")}}>
          <div style={{margin: 20}}>
            <span>공개되어있는 채용담당자의 연락처로 전화를 하여도 전화를 받지 않는 경우가 드물게 있습니다. 전화 상담을 요청주시면 채용담당자 혹은 회사내 다른 전화번호를 수소문하여 안내해드리겠습니다.</span>
          </div>
        </ExpandableItem>
      </>}
      bottom={<div style={{padding: 16}}>
        <FillButton text={"전화 상담 요청하기"} onClick={() => {trackEvent(EVENT_FAQ_CTA_CLICK, { user_id: uuid, activity_id: faqActivityId,}); setShowAskModal(true)}}/>
      </div>}
    />
    <TitleCenterDialogDrawerTemplate title={"전화 상담 요청"} show={showAskModal} onClose={onClose}>
      <p style={{whiteSpace: 'pre-wrap', margin: '0 0 12px', lineHeight: 1.5}}>{"휴대전화 번호를 남겨주시면\n평일 09~18시 사이에\n빠르게 전화드리겠습니다.(무료)"}</p>
      <TelField init={""} label={""} placeholder={"예) 01012345678"} onChange={(number) => setTel(number)} />
      <FillButton active={tel !== ""} text={"요청하기"} onClick={request} />
    </TitleCenterDialogDrawerTemplate>
  </>
}

export default FaqView;