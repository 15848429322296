import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { updateLastActionTimestamp } from "@reducer/userSlice";
import { CUSTOM_EVENT_APP_PAUSED, CUSTOM_EVENT_APP_RESUMED, FETCH_INTERVAL } from "./AppConstant";
import { cleanMainPage } from "@reducer/activityCacheSlice";
import { RootState } from "@reducer/store";

const useOnUpdateLastUserAction = () => {
    const dispatch = useDispatch();
    const lastActionTimestamp = useSelector((state: RootState) => state.user.lastActionTimestamp)

    function onResumed() {
        dispatch(updateLastActionTimestamp())
        if (isRefreshNeeded()) {
            dispatch(cleanMainPage())
        }
    }

    function onPaused() {
        dispatch(updateLastActionTimestamp())
    }

    function isRefreshNeeded() {
        return Date.now() - lastActionTimestamp > FETCH_INTERVAL
    }

    useEffect(() => {
        window.addEventListener(CUSTOM_EVENT_APP_RESUMED, onResumed);
        window.addEventListener(CUSTOM_EVENT_APP_PAUSED, onPaused);
        return () => {
            window.removeEventListener(CUSTOM_EVENT_APP_RESUMED, onResumed);
            window.removeEventListener(CUSTOM_EVENT_APP_PAUSED, onPaused);
        }
    }, []);
}
export default useOnUpdateLastUserAction;
