import {useEffect, useMemo, useRef, useState} from "react";
import { BackNavToolbar } from "@components/Toolbars";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {calculateRemainDate, getSalaryInfo, isActivityClosed} from "@util/activityHelper";
import { useDispatch, useSelector } from "react-redux";
import { append, remove } from "@reducer/scrapSlice";
import { show } from "@reducer/snackbarSlice";
import IcScrapActiveGreen from '@assets/ic_scrap_active_green.svg';
import IcScrapInactiveBlack from '@assets/ic_scrap_inactive.svg';
import { EVENT_ARTICLE_PAGE_SCROLL, EVENT_ARTICLE_PAGE_SHOW, EVENT_ASK_JOB_CLICK, EVENT_SCRAP_CLICK, EVENT_SHARE_VIEW_CLICK, EVENT_ZOOM_IN_IMAGE_CLICK, KEY_ACTIVITY_CLICK_COUNT} from "@components/AppConstant";
import useOnLog from "@components/useOnLog";
import { AppInstallGuideBanner } from "@components/AppInstallGuideBanner";
import { DefaultPageRoot } from "@components/PageTemplate";
import { RootState } from "@reducer/store";
import { Activity } from "@model/Activity";
import isSimpleApply from "@util/isSimpleApply";
import IcPin from "@assets/ic_pin_bold.svg";
import formatRegions from "@util/formatRegions";
import IcMoneyCircleBold from "@assets/ic_money_circle_bold.webp";
import IcSelectableDatetime from "@assets/ic_selectable_datetime.svg";
import {BorderButton} from "@components/Buttons";
import IcCarbonShareBlack from "@assets/ic_carbon_share_black.svg";
import IcPersonCall from "@assets/ic_person_call.svg";
import IcZoom from "@assets/ic_zoom.svg";
import FirebaseImageLoader from "@components/FirebaseImageLoader";
import {primaryColor} from "@components/styles";
import server_domain from "@components/RemoteServer";
import trackEvent from "@util/trackEvent";
import {GuideInstallAppModal} from "@view/GuideInstallAppModal";
import activityLoader from "@src/data-layer/activityLoader";
import ApplyRemainDate from "@src/features/job/component/ApplyRemainDate";
import {increment} from "@reducer/eventSlice";

const ScrapCta = ({ activity, location }: {
  activity: Activity,
  location?: any
}) => {
  const scrapedActivity = useSelector((state: RootState) => state.scrap.value)
  const regions = useSelector((state: RootState) => state.user.regions);
  const isScraped = () => Object.keys(scrapedActivity).includes(activity.id)
  const [savedActivity, setSavedActivity] = useState(isScraped());
  const dispatch = useDispatch()

  useEffect(() => {
    setSavedActivity(isScraped())
  }, [scrapedActivity])


  function handleScrap() {
    const prop = {
      "activity_id": activity.id,
      "user_regions": regions,
      "location": location,
      "toggle": savedActivity ? "off" : "on"
    }
    if (savedActivity) {
      dispatch(remove(activity))
      dispatch(show({ message: "스크랩에서 제거되었습니다" }))
    } else {
      dispatch(append(activity))
      dispatch(show({ message: "스크랩 되었습니다" }))
    }
    trackEvent(EVENT_SCRAP_CLICK, prop)
  }
  const startIconStyle = {
    width: 'auto',
    maxWidth: 'none'
  }

  return <BorderButton
    icon={savedActivity ? <IcScrapActiveGreen style={{ ...startIconStyle }} /> : <IcScrapInactiveBlack style={{ ...startIconStyle }} />}
    onClick={handleScrap}
    text={"스크랩"}
    style={{width: 'fit-content',}}
    fontStyle={{whiteSpace: 'nowrap'}}
  />
}

const ActivityPageTemplate = (
  {previousPage, Cta, CtaGuide}: { previousPage: string, Cta: React.ElementType, CtaGuide: React.ElementType}
) => {
  const uuid = useSelector((state: RootState) => state.user.uuid);
  const scrollRef = useRef<HTMLDivElement>(null);
  const event = useSelector((state: RootState) => state.event)
  const regions = useSelector((state: RootState) => state.user.regions);
  const [searchParams, _] = useSearchParams();
  const showInstallGuide = (searchParams.get("show_install_guide") === "true")
  const showInstallBanner = (searchParams.get("show_install_banner") === "true")
  const [showGuide, setShowGuide] = useState(showInstallGuide);
  const {activityId} = useParams();
  const [activity, setActivity] = useState<Activity | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const state = useLocation().state;
  const dispatch = useDispatch();
  const currentPathname = useMemo(() => window.location.pathname, []);
  const applyMethod: string[] = activity?.applyMethod.split(",").filter((method) => method !== "공공 간편지원") || [];
  let waiting = false;

  const formatRecruitmentRemainDateBanner = (activity: Activity) => {
    if (activity && isActivityClosed(activity)) {
      return null
    }

    if (activity.activityRecruitmentUntilComplete) {
      return ""
    }
    const remainDate = calculateRemainDate(activity, false)
    if (remainDate == 0) {
      return ""
    } else if (remainDate > 7) {
      return ""
    } else if (remainDate > 0) {
      return ""
    } else if (remainDate < 0) {
      return <p style={{ margin: '16px auto', lineHeight: 1.5, fontSize: '1.125rem', fontWeight: '500', textAlign: 'center' }}>
        <span style={{color: primaryColor}}>{-1 * remainDate}일 후</span><span>부터 지원이 가능합니다.</span>
        <span style={{display: 'inline-block'}}> 지원서류 작성은 미리 할 수 있습니다.</span>
      </p>
    }
    return ""
  }

  const headerStyle = {
    padding: '16px 16px 24px',
    fontWeight: 500,
    fontSize: '1.25rem',
    color: '#1D1D1D'
  }

  const titleStyle = {
    padding: '0px 16px 16px',
    fontWeight: 500,
    fontSize: '1.375rem',
    color: '#1D1D1D'
  }

  function handleZoomInImage() {
    if (!activity) {
      return
    }

    const prop = {
      "activity_id": activity.id,
      "user_regions": regions,
      "title": activity.activityTitle,
      "url": activity.url,
      "previous_page": previousPage,
    }
    trackEvent(EVENT_ZOOM_IN_IMAGE_CLICK, { ...prop });
    navigate("zoom_in", {
      state: {
        'paths': activity.activityImages?.map((image: string) => `activities/${image}`),
        "title": "공고문 크게보기",
      }
    })
  }

  function askWidzom() {
    if (!activity) {
      return
    }

    const prop = {
      "location": "ActivityPage",
      "activity_id": activity.id,
      "user_regions": regions,
      "title": activity.activityTitle,
      "url": activity.url
    }
    trackEvent(EVENT_ASK_JOB_CLICK, { ...prop });
    navigate("inquiry", { state: { ...state, activity_id: activity.id, number: activity.applyMethodTel, managerName: activity.applyMethodManagerName } })
  }

  function share() {
    if (!activity) {
      return
    }

    trackEvent(EVENT_SHARE_VIEW_CLICK, {
      location: 'DetailPageButton'
    })
    navigate({
      pathname: "share",
      search: `?show_install_banner=${showInstallBanner}`
    }, {
      state: {
        amplitude_props: {
          location: 'ActivityDetailPage',
          activity_title: activity.activityTitle,
          activity_id: activity.id,
        },
        title: "채용공고를 공유합니다",
        templateId: 100785,
        kakao_share_title: "카카오톡으로 공유하기",
        link_copy_title: "공고 내용 복사하기",
        toastText: "공고문 주소가 복사되었습니다.\n붙여넣기 하여 공유해주세요.",
        url: `위즈덤 앱에 좋은 채용 공고가 있어 공유합니다.\n${activity.activityOrganization}${activity.activityTitle}\n아래 링크를 클릭하면 공고를 바로 볼 수 있습니다.\n${server_domain()}/redirect_detail_page?activity_id=${activity.id}`,
        templateArgs: {
          "title": activity.activityOrganization,
          "description": activity.activityTitle,
          "cta": "자세히 보기",
          "url": server_domain(),
          "url_path": `redirect_detail_page?activity_id=${activity.id}`
        }
      }
    })
  }

  const amplitudeProp = {
    "activity_id": activity?.id || "activityId" ,
    "user_regions": regions,
    "previous_page": previousPage,
    "previous_location": location?.state?.previous_location,
  }

  const handleScroll = ( _ : Event ) => {
    if (!waiting && scrollRef.current) {
      var srollMax = scrollRef.current.scrollHeight - scrollRef.current.offsetHeight // 아티클 전체 높이 - 표시 높이
      var halfScrollMax = srollMax / 2
      if (scrollRef.current.scrollTop > halfScrollMax) {
        if (activity) {
          trackEvent(EVENT_ARTICLE_PAGE_SCROLL, { ...amplitudeProp });
          scrollRef.current.removeEventListener('scroll', handleScroll);
        }
      }
      waiting = true
      setTimeout(() => {
        waiting = false
      }, 500)
    }
  }

  useEffect(() => {
    if (currentPathname === window.location.pathname) {
      if (activityId) {
        activityLoader(activityId, uuid).then(setActivity)
      }
    }
  }, [window.location.pathname])

  useEffect(() => {
    if (activityId) {
      activityLoader(activityId, uuid).then(setActivity)
    }
  }, [null])

  useEffect(() => {
    if (activity && scrollRef.current) {
      if (event[KEY_ACTIVITY_CLICK_COUNT] === 10) {
        dispatch(increment({key: KEY_ACTIVITY_CLICK_COUNT}))
        navigate("share_guide");
      }
      scrollRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    }
  }, [activity]);

  useOnLog({
    pageNameKey: EVENT_ARTICLE_PAGE_SHOW,
    amplitudeProp: amplitudeProp,
    regStr: "\/activity\/[^/]+$"
  })

  if (!activity) {
    return null
  }

  return (
    <DefaultPageRoot
      top={<>
        <AppInstallGuideBanner />
        <GuideInstallAppModal show={showGuide} close={() => { setShowGuide(false) }} />
        <BackNavToolbar title={'상세 정보'} />
      </>}
      middle={<>
        <div>
          {formatRecruitmentRemainDateBanner(activity) != "" && <div style={{margin: '16px 16px 0px', background: '#F4F4F4', display: 'flex', justifyContent: 'center'}}>{formatRecruitmentRemainDateBanner(activity)}</div>}
          <div style={{height: '24px'}}></div>
          <div>
            {
              (activity.reserved === 1 || isSimpleApply(activity)) &&
                <div style={{padding: '0px 16px 4px',}}>
                  {activity.reserved === 1 && <span style={{display: 'inline-block', fontWeight: '500', fontSize: '0.75rem', lineHeight: '1.2rem', verticalAlign: 'middle', backgroundColor: '#EEF9F2', borderRadius: '5px', padding: '0 16px', color: '#0ABF53', marginRight: '0.3rem', marginBottom: '8px',}}>5060 채용 약속</span>}
                  {isSimpleApply(activity) && <span style={{display: 'inline-block', fontWeight: '500', fontSize: '0.75rem', lineHeight: '1.2rem', verticalAlign: 'middle', backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '0 0.8rem', marginBottom: '8px', marginRight: '0.3rem', color: '#474D5B'}}>간편 지원</span>}
                </div>
            }
          </div>

          <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 16px 4px',}}>
            <p style={{margin: 0, lineHeight: 1.5, color: 'black', fontSize: '1rem', fontWeight: '300',  marginRight: 'auto'}}>{activity.activityOrganization}</p>
            <ApplyRemainDate activity={activity} style={{fontWeight: 300}}/>

          </div>
          <p style={{...titleStyle, margin: 0, lineHeight: 1.5}}>{activity.activityTitle}</p>

          <div style={{margin: '4px 16px 8px 16px', borderBottom: '1px solid #D7D8DA', paddingBottom: '24px',}}>
            <div style={{display: 'flex', alignItems: 'flex-start'}}>
              <IcPin style={{height: '1rem', width: '1rem', margin: '0px', marginLeft: '0.1rem', marginTop: '0.15rem', marginRight: '0.3rem'}}/>
              <p style={{margin: 0, fontSize: '1rem', fontWeight: 400, lineHeight: 1, paddingTop: '0.15rem', textOverflow: "ellipsis", WebkitLineClamp: "1", overflow: "hidden", display: "-webkit-box", WebkitBoxOrient: "vertical", height: '1.2rem',}}> {formatRegions(activity.activityRegionList, regions)} </p>
            </div>
            {
              activity.activityReward && getSalaryInfo(activity) != "" &&
                <div style={{display: 'flex', marginTop: '0.2rem', alignItems: 'flex-start'}}>
                    <img src={IcMoneyCircleBold} width={19} height={19} style={{alignSelf: 'center', margin: '0px', marginTop: '0.1rem', marginLeft: '0.15rem', marginRight: '0.3rem'}} alt=""/>
                    <p style={{margin: 0, fontSize: '1rem', fontWeight: 400, lineHeight: 1, paddingTop: '0.3rem', textOverflow: "ellipsis", WebkitLineClamp: "1", overflow: "hidden", display: "-webkit-box", WebkitBoxOrient: "vertical", height: '1.2rem',}}> {getSalaryInfo(activity)} </p>
                </div>
            }
            {
              activity.activityBasicInsurance ?
                <div style={{border: '1px solid #D9D9D9', width: 'fit-content', padding: "0px 5px", marginTop: '1%', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '1.2rem'}}>
                    <IcSelectableDatetime style={{width: '0.875rem', margin: '0px 5px 0px 0px'}}/>
                    <span style={{fontSize: '0.875rem', fontWeight: 400}}>4대 보험 제공</span>
                </div>
                :
                null
            }
            {
              applyMethod.length == 1 && applyMethod[0] === "컴퓨터지원" &&
                <div style={{lineHeight: 1.5, borderRadius: 5, background: '#F4F4F4', padding: '20px 20px', marginTop: 15}}>
                    <div style={{display: "grid", flexWrap: 'wrap', gap: 0, gridTemplateColumns: '25px 1fr'}}>
                        ✓
                        <span>컴퓨터로 지원서류 작성 필요</span>
                        ✓
                        <span style={{whiteSpace: "pre-wrap"}}>{"지원방법과 서류양식을 받으려면\n[지원방법 확인하기] 버튼 누르기"}</span>
                    </div>
                </div>
            }

            <div style={{marginTop: 24}}>
              <Cta activity={activity} regions={regions} historyId={activity.historyId}/>
            </div>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px 16px 0px', padding: '0px 0px'}}>
            <div style={{textAlign: 'center', justifyContent: 'center', margin: '8px auto'}} onClick={share}>
              <IcCarbonShareBlack/>
              <p style={{margin: 0, fontSize: '1rem', fontWeight: 400, marginTop: 5}}>공유하기</p>
            </div>
            <div style={{height: '60px', width: '1px', background: '#D7D8DA'}}></div>
            <div style={{textAlign: 'center', justifyContent: 'center', margin: '8px auto'}} onClick={askWidzom}>
              <IcPersonCall/>
              <p style={{margin: 0, fontSize: '1rem', fontWeight: 400, marginTop: 5}}>문의하기</p>
            </div>
          </div>
          <div style={{background: "#F4F4F4", marginTop: 8, height: 8}}/>
          <div style={{background: "#F4F4F4", height: 8}}/>

          {
            activity.activityDescription && activity.activityDescription.length < 2000 &&
              <>
                <p style={{...headerStyle, margin: 0}}>내용</p>
                <p style={{margin: 0, lineHeight: 1.5, padding: '0px 16px 16px', color: '#1d1d1d', fontSize: '1rem', wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}>{activity.activityDescription}</p>
                {
                  !isActivityClosed(activity) &&
                    <CtaGuide historyId={activity.historyId}>
                      <Cta activity={activity} regions={regions} historyId={activity.historyId}/>
                    </CtaGuide>
                }
                {activity.activityImages && <div style={{background: "#F4F4F4", height: 8}}/>}
              </>
          }
          {
            activity.activityImages &&
              <>
                  <p style={{...headerStyle, margin: 0}}>공고문</p>
                  <div style={{textAlign: 'center', justifyContent: 'center'}}>
                      <p style={{margin: 0, lineHeight: 1.5, fontSize: '1rem', textDecoration: 'underline', wordBreak: 'break-all', whiteSpace: 'pre-wrap', width: '100%',}}>자격, 서류, 접수 방법 및 기간 필수 확인!</p>
                      <button onClick={handleZoomInImage} style={{background: 'transparent', fontWeight: '500', fontSize: '1.125rem', color: '#1d1d1d', margin: '4% auto 4%', border: '1px solid #000000', padding: '1% 7% 1%', borderRadius: '30px', lineHeight: 1.75, display: 'flex', alignItems: 'center'}}>
                          <IcZoom width={20} height={20} style={{marginRight: '5px'}}/>
                          <span>크게 보기</span>
                      </button>
                  </div>
                {activity.activityImages.map((image: string, idx: number) => <FirebaseImageLoader filename={image} key={idx}/>)}
                {
                  !isActivityClosed(activity) && CtaGuide &&
                    <CtaGuide historyId={activity.historyId}>
                      <Cta activity={activity} regions={regions} historyId={activity.historyId}/>
                    </CtaGuide>
                }
              </>
          }
        </div>
        <div style={{marginTop: "16px"}}/>
      </>}
      middleRef={scrollRef}
      bottom={<div style={{display: 'flex', gap: 16, borderTop: '1px solid #f4f4f4', padding: 16}}>
        <ScrapCta activity={activity}/>
        <Cta activity={activity} regions={regions} historyId={activity.historyId}/>
      </div>}
    />
  )
}

export default ActivityPageTemplate;