import { useLocation, useNavigate } from "react-router-dom";
import { BottomDrawerTemplate } from "@components/DrawerTemplate";
import IcPhone from '@assets/ic_phone.webp';
import IcPhoneBlack from '@assets/ic_phone_black.svg';
import IcKakao from '@assets/ic_kakao.webp';
import { BorderButton, IconFillButton } from "@components/Buttons";
import openCall from "@util/openCall";
import openKakao from "@util/openKakao";
import server_domain from "@components/RemoteServer";
import { EVENT_CALL_MANAGER_CLICK, EVENT_INQURIY_WIZDOM_CLICK } from "@components/AppConstant";
import * as amplitude from "@amplitude/analytics-browser";
import { fetchWithRetry } from '@util/networks';

const ActivityInquiryDrawer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const { number, managerName, activity_id } = state

    return (
        <BottomDrawerTemplate title={'문의하기'} show={true} onClose={() => navigate(-1)} >
            <>
                {
                    number && number != "" ?
                        <div>
                            <>
                                {
                                    managerName &&
                                    <div style={{ width: '100%', marginBottom: 12 }}>
                                        <span style={{ display: 'inline-block', color: '#6A6A6A', width: '110px' }}>채용담당자</span>
                                        <span>{managerName}</span>
                                    </div>
                                }
                                <div style={{ width: '100%' }}>
                                    <span style={{ display: 'inline-flex', color: '#6A6A6A', width: '110px', lineHeight: 1.5, verticalAlign: 'top' }}>연락처</span>
                                    <span style={{ display: 'inline-block', whiteSpace: 'pre-wrap', lineHeight: 1.5 }}>{number.replace(',', ' 또는 \n')}</span>
                                </div>
                            </>
                            <IconFillButton
                                icon={<img src={IcPhone} alt="" height={20} />}
                                text={'채용담당자에게 전화하기'}
                                style={{ margin: '20px 0 0' }}
                                onClick={() => {
                                    amplitude.track(EVENT_CALL_MANAGER_CLICK, {
                                        "activity_id": activity_id,
                                        "location": "ActivityInquiryDrawer",
                                    })
                                    if (number.split(',').length > 1) {
                                        navigate("tel", { state: { ...state, title: "채용담당자 전화번호", number: number } })
                                    } else {
                                        openCall(number)
                                    }
                                }}
                            />
                        </div>
                        :
                        <>
                            <p style={{ margin: '20px 0', lineHeight: 1.5, color: '#666' }}>채용담당자 정보가 공개되어 있지 않습니다. 위즈덤 고객센터에 도움을 요청하세요.</p>
                            <div style={{ background: "#F4F4F4", padding: '20px 16px', borderRadius: 5 }}>
                                <p style={{ fontWeight: 500, color: "#666", margin: '0 0 12px 0' }}>위즈덤 고객센터</p>
                                <div style={{ display: 'flex', gap: 10 }}>
                                    <BorderButton
                                        icon={<IcPhoneBlack />}
                                        text={'전화문의'}
                                        style={{ flex: 1, padding: '16px 0' }}
                                        onClick={() => {
                                            const amp = amplitude.track(EVENT_INQURIY_WIZDOM_CLICK, {
                                                "type": "call",
                                                "activity_id": activity_id
                                            }).promise;
                                            const f = fetchWithRetry(server_domain() + "/request_call", {
                                                method: "POST",
                                                headers: { "Content-Type": "application/json" },
                                                body: JSON.stringify({"activity_id": activity_id})
                                            })
                                            Promise.all([amp, f])
                                                .then((res) => {
                                                    openCall('1660-2958')
                                                })
                                        }}
                                    />
                                    <BorderButton
                                        icon={<img src={IcKakao} alt="" height={20} />}
                                        text={'채팅문의'}
                                        style={{ flex: 1, padding: '16px 0' }}
                                        onClick={() => {
                                            const amp = amplitude.track(EVENT_INQURIY_WIZDOM_CLICK, {
                                                "type": "kakao",
                                                "activity_id": activity_id
                                            })
                                            const f = fetchWithRetry(server_domain() + "/request_kakao", {
                                                method: "POST",
                                                headers: { "Content-Type": "application/json" },
                                                body: JSON.stringify({"activity_id": activity_id})
                                            })
                                            Promise.all([amp, f])
                                                .then((res) => {
                                                    openKakao()
                                                })
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                }
            </>
        </BottomDrawerTemplate>
    )
}

export default ActivityInquiryDrawer;