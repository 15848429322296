import { Activity } from "@model/Activity";
import server_domain from "@components/RemoteServer"
import convertActivity from "@util/convertActivity"
import { fetchWithRetry } from '@util/networks';

const activityLoader = async (id: string, user_id: string | null): Promise<Activity> => {
    let response = await fetchWithRetry(server_domain() + '/activity_detail?id=' + id + "&user_id=" + user_id, {
        headers: {
            "Content-Type": "application/json",
        },
    })
    let json = await response.json()
    let activity = convertActivity(json)
    return activity
}

export default activityLoader;