import { createSlice } from "@reduxjs/toolkit";

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        show: false,
        message: ""
    },
    reducers: {
        show: (state, action) => {
            state.show = true
            state.message = action.payload.message
        },
        hide: (state) => {
            state.show = false
        },
    }
})

export const { show, hide } = snackbarSlice.actions
export default snackbarSlice.reducer
