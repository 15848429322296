//@ts-nocheck

const openGallery = (id, multipleImage=false) => {
    if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
        if (multipleImage) {
            window.flutter_inappwebview.callHandler('openGalleryMultiple', id);
        } else {
            window.flutter_inappwebview.callHandler('openGallery', id);
        }
    }
}
export default openGallery;
