import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {CertificateCard, DeleteCertificateModal, Description, DropDown, Indicator, NavigateButton, TextFieldCheckbox, YMInput} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";
import React, {useMemo, useState} from "react";
import {useBlocker, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {removeCertificate} from "@reducer/userSlice";

const Certificate = () => {
  const navigate = useNavigate();
  const state = useLocation().state
  const step = useMemo(() => state?.step || 0, [null])
  const certificates = useSelector((state: RootState) => state.user.resume.certificate)
  const available = () => certificates.length > 0
  const dispatch = useDispatch();
  const [deleteCertificateId, setDeleteCertificateId] = useState("");
  const _ = useBlocker(({ currentLocation, nextLocation, historyAction }) => {
    if (deleteCertificateId !== "") {
      setDeleteCertificateId("")
      return true
    }
    return false
  });

  const next = () => navigate("../create_resume_introduction", {state: {step: step + 1}});

  return <>
    <DefaultPageRoot
      top={<BackNavToolbar title={""}/>}
      middle={<div style={{padding: '20px 16px 0px'}}>
        <Indicator label={"자격증정보"} max={6} index={4} />
        <Description text={"어떤 자격증이 있나요?"}/>
        <NavigateButton label={"자격증 추가하기"} to={"add"}/>
        {
          certificates.map(certificate => <CertificateCard certificate={certificate} onEdit={() => navigate("add/" + certificate.uuid)} onDelete={setDeleteCertificateId}/>)
        }
      </div>}
      bottom={<div style={{padding: 16}}>
        <FillButton
          active={available()}
          text={"다음"}
          onClick={next}
        />
      </div>}
    />
    <DeleteCertificateModal id={deleteCertificateId} onClose={() => setDeleteCertificateId("")} onDelete={() => dispatch(removeCertificate(deleteCertificateId))} />
  </>
}

export default Certificate;