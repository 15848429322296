import { Activity } from "@model/Activity"
import server_domain from "@components/RemoteServer"
import convertActivity from "@util/convertActivity"
import { fetchWithRetry } from "@util/networks"

export interface ApplicationDescription {
    type: number,
    activity: Activity,
    attachments: any
}

const applicationDescriptionLoader = async (id: string): Promise<ApplicationDescription> => {
    let response = await fetchWithRetry(server_domain() + '/application_description?' + "id=" + id, {
        headers: {
            "Content-Type": "application/json",
        },
    })
    let json = await response.json()
    let activity = convertActivity(json.activity)
    return {
        type: json.type,
        activity: activity,
        attachments: json.attachments
    }
}

export default applicationDescriptionLoader;