import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeCategoryIds } from "@reducer/userSlice";
import { FillButton } from "@components/Buttons";
import { BottomDrawerTemplate } from "@components/DrawerTemplate";
import CategoryContainer from "@src/components/CategoryContainer";

const JobCategoryDrawer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [categoryList, setCategoryList] = useState<number[]>([])
    

    const Cta = () => {
        function handle() {
            dispatch(changeCategoryIds(categoryList))
            navigate('/job/activity_list', { replace: true })
        }

        return (
            <FillButton
                text={categoryList.length !== 0 ? "선택 직종 공고 보기" : '전체 직종 공고 보기'}
                onClick={handle}
            />
        )
    }
    
    const onUpdate = (ids: number[]) => {
        setCategoryList(ids)
    }

    return (
        <BottomDrawerTemplate
            title={'직종'}
            subtitle={'복수 선택 가능'}
            show={true}
            style={{padding: '0px 0 0px'}}
            onClose={() => navigate(-1)}
        >
            <div style={{ borderTop: '1px solid #D7D8DA', padding: '2%' }}>
                <div style={{paddingTop: '20px', paddingBottom: '10px' }}>
                    <CategoryContainer onUpdate={onUpdate} style={{maxHeight: '330px', overflow: 'scroll' }}/>
                </div>
                <Cta />
            </div>

        </BottomDrawerTemplate>
    )
}

export default JobCategoryDrawer;