const SectionHeaderStyle: React.CSSProperties = {
  position: 'sticky',
  margin: 0,
  top: 0,
  padding: '16px 16px 16px',
  fontSize: '1.5rem',
  fontWeight: 700,
  background: 'white'
}

export {SectionHeaderStyle};