import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import React, {useEffect, useMemo, useState} from "react";
import {BackNavToolbar} from "@components/Toolbars";
import {DefaultPageRoot} from "@components/PageTemplate";
import {HSelector, Indicator, TelField, TextArea, TextField, YMDInput} from "@components/form/ResumeComponent";
import {FillButton, SecondaryFillButton} from "@components/Buttons";
import {useLocation, useNavigate} from "react-router-dom";
import {changePhoneNumber, updateResumeField} from "@reducer/userSlice";
import {DialogDrawerTemplate} from "@components/DrawerTemplate";

const initBirthday = (birthday: string) => {
  if (/^\d{8}$/.test(birthday)) {
    return birthday
  } else {
    return ""
  }
}

const BasicInfo = () => {
  const state = useLocation().state
  const step = useMemo(() => state?.step || 0, [null])
  const resume = useSelector((state: RootState) => state.user.resume)
  const number = useSelector((state: RootState) => state.user.phoneNumber)
  const isEdit = !!resume.name

  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [name, setName] = useState(resume.name || "")
  const [birthday, setBirthday] = useState(initBirthday(resume.birthday) || "")
  const [gender, setGender] = useState(resume.gender || "0")
  const [phoneNumber, setPhoneNumber] = useState(resume.phone_number || number || "") // TODO init with redux
  const [address, setAddress] = useState(resume.address || "")

  const next = () => {
    dispatch(updateResumeField({
      "name": name.trim(),
      "birthday": birthday.trim(),
      "gender": gender,
      "phone_number": phoneNumber.trim(),
      "address": address.trim(),
    }))

    if (!number) {
      dispatch(changePhoneNumber(phoneNumber))
      navigate("../create_resume_highest_education", {state: {step: step+1}})
    } else if (number !== phoneNumber) {
      navigate("")
      setShowModal(true)
    } else {
      navigate("../create_resume_highest_education", {state: {step: step+1}})
    }
  }

  useEffect(() => {
    dispatch(updateResumeField({
      finished: false
    }))
  }, []);

  window.onpopstate = () => {
    if (showModal) {
      setShowModal(false)
    }
  }
  const available = () => name !== "" && birthday !== "" && gender !== "" && phoneNumber !== "" && address !== ""

  return <>
    <DefaultPageRoot
      top={<BackNavToolbar title={""}/>}
      middle={<div style={{padding: '20px 16px 0px'}}>
        <Indicator label={"기본정보"} max={6} index={1} />
        <TextField key={"basic_info_name"} label={"이름"} placeholder={"예시) 홍길동"} value={name} onChange={setName} highlight={isEdit && name === ""}/>
        <YMDInput key={"basic_info_birth"} label="생년월일" init={birthday} onChange={setBirthday} highlight={isEdit && birthday === ""}/>
        <HSelector key={"basic_info_gender"} label={"성별"} options={[{key: "0", label: '남성'}, {key: "1", label: '여성'}]} value={gender} onChange={setGender} />
        <TelField init={phoneNumber} key={"basic_info_phone"} placeholder={"예시) 01012345678"} label={"휴대폰"} onChange={setPhoneNumber} highlight={isEdit && phoneNumber === ""} />
        <TextArea key={"basic_info_address"} placeholder={"예시) 서울시 중구 세종대로 110"} value={address} label={"주소"} onChange={setAddress} highlight={isEdit && address === ""} />
      </div>}
      bottom={<div style={{padding: 16}}>
        <FillButton
          active={available()}
          text={"다음"}
          onClick={next}
        />
      </div>}
    />
    <DialogDrawerTemplate show={showModal} onClose={() => setShowModal(false)}>
      <p style={{lineHeight: 1.5, wordBreak: 'break-all'}}>입력하신 휴대전화번호({phoneNumber})와 로그인 한 휴대전화번호({number})가 일치하지 않습니다.</p>
      <FillButton text={"다시 입력하기"} style={{marginBottom: 10}} onClick={() => navigate(-1)}/>
      <SecondaryFillButton text={"입력한 내용대로 진행하기"} onClick={() => navigate("../create_resume_highest_education", {state: {step: step+2}})}/>
    </DialogDrawerTemplate>
  </>
}

export default BasicInfo;