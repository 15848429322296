import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Description, HSelector, Indicator, NavigateButton, TextArea, TextField, YMInput} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";
import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CareerState, saveCareer} from "@reducer/userSlice";
import uuidv4 from "@util/uuidv4";
import {RootState} from "@reducer/store";
import IcClose from "@assets/close.svg";

const AddCareer = () => {
  const {careerId} = useParams()
  const resume = useSelector((state: RootState) => state.user.resume)
  const career = resume.career.find((career: CareerState) => career.uuid === careerId)
  const isEdit = !!career
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState(career?.name || "")
  const [position, setPosition] = useState(career?.position || "")
  const [status, setStatus] = useState(career?.status || "0")
  const [startDate, setStartDate] = useState(career?.startDate || "")
  const [endDate, setEndDate] = useState(career?.endDate || "")
  const [description, setDescription] = useState(career?.description || "")

  const available = () => name !== "" && position !== "" && status !== "" && startDate !== "" && description !== "" && (status !== "1" && endDate !== "" && endDate >= startDate || status === "1")
  const next = () => {
    dispatch(saveCareer({
      uuid: careerId || uuidv4(),
      name: name.trim(),
      position: position.trim(),
      status: status,
      startDate: startDate.trim(),
      endDate: status === "1" ? "" : endDate.trim(),
      description: description.trim(),
      no_career: false
    }))
    navigate(-1)
  }

  return <DefaultPageRoot
    top={<BackNavToolbar title={""} icon={<IcClose />}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <TextField label={"회사명"} placeholder={"예) 위즈덤"} value={name} onChange={setName} highlight={isEdit && name===""}/>
      <HSelector label={"재직상태"} options={[{key: "0", label: '퇴사'}, {key: "1", label: '재직중'}]} value={status} onChange={setStatus}/>
      <YMInput label={"입사일"} placeHolder={"198803"} init={startDate} onChange={setStartDate} highlight={isEdit && startDate===""}/>
      {status === "0" && <YMInput label={"퇴사일"}  placeHolder={"202312"} init={endDate} onChange={setEndDate} highlight={isEdit && endDate===""}/>}
      <TextField label={"직급/직책"} placeholder={"예) 부장"} value={position} onChange={setPosition} highlight={isEdit && position===""}/>
      <TextArea rows={8} label={"주요 직무\n및 업무"} placeholder={"어떤 일을 하셨는지 적어주세요."} value={description} onChange={setDescription} highlight={isEdit && description===""}/>

    </div>}
    bottom={<div style={{padding: 16}}>
      <FillButton
        active={available()}
        text={"추가"}
        onClick={next}
      />
    </div>}
  />
}

export default AddCareer;