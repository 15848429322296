//@ts-nocheck
import { useNavigate } from "react-router-dom";
import { FillButton } from "../../components/Buttons";
import { DefaultPageRoot } from "../../components/PageTemplate";
import { BackNavToolbar } from "../../components/Toolbars";
import ImgPhotoExample from '../../assets/img_photo_example.webp';
import useOnLog from "@components/useOnLog";
import {EVENT_CREATE_RESUME_REGISTER_PHOTO_GUIDE_PAGE_SHOW, EVENT_CREATE_RESUME_REGISTER_PHOTO_PAGE_SHOW} from "@components/AppConstant";

const RegisterPhotoGuideView = () => {
    const navigate = useNavigate();

    useOnLog({
      pageNameKey: EVENT_CREATE_RESUME_REGISTER_PHOTO_GUIDE_PAGE_SHOW,
      regStr: "\/create_resume_photo\/guide$"
    })

    return (
        <DefaultPageRoot
            top={<BackNavToolbar title="사진 가이드" />}
            middle={<div style={{padding: '0 0 30px'}}>
                <p style={{fontSize: '1.125rem', fontWeight: 500, textAlign: 'center', whiteSpace: 'pre-wrap'}}>{"아래 조건에 맞춰\n사진을 올려주세요"}</p>
                <div style={{ display: 'grid', width: '70%', margin: 'auto', padding: 16, flexWrap: 'wrap', gap: 0, gridTemplateColumns: '25px 1fr' }}>
                    ✓
                    <p style={{ margin: 0, lineHeight: 1.5, wordBreak: 'break-all', color: '#666' }}>6개월 이내 촬영한 사진</p>
                    ✓
                    <p style={{ margin: 0, lineHeight: 1.5, wordBreak: 'break-all', color: '#666' }}>실제사진을 카메라로 찍은 것도 ok!</p>
                    ✓
                    <p style={{ margin: 0, lineHeight: 1.5, wordBreak: 'break-all', color: '#666' }}>증명사진이 가장 좋습니다.</p>
                </div>
                <p style={{margin: '0 0 20px', lineHeight: 1.5, color: '#666', textAlign: 'center' }}>&#60;예시&#62;</p>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}> 
                    <img src={ImgPhotoExample} alt="" style={{width: '50%', verticalAlign: 'middle'}}/>
                </div>
            </div>}
            bottom={<div style={{ padding: 16 }}>
                <FillButton
                    text="닫기"
                    onClick={() => navigate(-1)}
                />
            </div>}
        />

    );
}

export default RegisterPhotoGuideView;