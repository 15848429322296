import { FillButton } from "@src/components/Buttons";
import { DefaultPageRoot } from "@src/components/PageTemplate";
import { BoldText_22, RegularText_17 } from "@src/components/text";
import { useLoaderData, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { EVENT_CLICK_EDIT_NICKNAME, EVENT_ONBOARDING_PAGE_V2_SHOW, EVENT_ONBOARDING_START_CTA_CLICK } from "@src/components/AppConstant";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/reducer/store";
import { greyF4, secondaryBlack } from "@src/components/Color";
import useOnLog from "@src/components/useOnLog";
import { useEffect, useState } from "react";
import IcEdit from "@assets/ic_edit.svg";
import CommunityService from "../../infra/CommunityService";
import { show } from "@src/reducer/snackbarSlice";
import { changeNickname } from "@src/reducer/userSlice";
import trackEvent from "@src/util/trackEvent";
import { useOnMountOnce } from "@src/util/useOnMountOnce";

var escapeFromCTA = false;

const OnboardingPage = () => {
    const navigate = useNavigate();
    const [dispatch] = useOutletContext() as any[]
    const userId = useSelector((state: RootState) => state.user.uuid);
    const deviceId = useSelector((state: RootState) => state.user.deviceId);
    const autoGeneratedNickname = useLoaderData() as string;
    const nickname = useSelector((state: RootState) => state.user.nickname);
    const location = useLocation()
    useOnLog({
        pageNameKey: EVENT_ONBOARDING_PAGE_V2_SHOW,
        regStr: "^\/community\/onboarding$",
    })

    useOnMountOnce(() => {
        escapeFromCTA = false;
        if (!userId) { 
            navigate(-1)
            return 
        }
        CommunityService.setNickname({userId: userId, nickname: autoGeneratedNickname, deviceId: deviceId})
            .then(() => {
                dispatch(changeNickname(autoGeneratedNickname))
            })
    }, [])

    const handleOnPop = () => {
        if (!escapeFromCTA) {
            dispatch(show({message: `"${nickname}"로 닉네임이 설정되었어요.\n내 프로필에서 변경할 수 있어요.` }))
            escapeFromCTA = true
            return
        }

        dispatch(show({message: `"${nickname}"님, 환영해요!\n커뮤니티에서 많은 정보 얻어가세요!`}))
    }
    
    useEffect(() => {
        window.onpopstate = () => {
            if (location.pathname !== "/community/onboarding") { return }
            handleOnPop()
            window.onpopstate = null
        }
    }, [handleOnPop])

    

    const navigateToEditNickname = () => {
        trackEvent(EVENT_CLICK_EDIT_NICKNAME, { source: 'onboarding' })
        navigate('edit-nickname', { state: { autoGenerated: nickname == autoGeneratedNickname, source: 'onboarding' } })
    }

    return (
        <DefaultPageRoot
            middle={
                <div style={{ display: 'flex', padding: '0px 15px', paddingTop: '100px', flexDirection: 'column' }}>
                    {
                        autoGeneratedNickname == nickname ?
                        <BoldText_22 style={{ display: 'block', lineHeight: '1.5' }}>안녕하세요 👋<br/>커뮤니티에서 사용할<br/>별명을 제안드려요<br/></BoldText_22>
                        : <BoldText_22 style={{ display: 'block', lineHeight: '1.5' }}>좋은 별명이에요.<br/>커뮤니티를 시작해보세요!</BoldText_22>
                    }
                    
                    <div style={{display: 'inline-flex', padding: '10px 16px', gap: '8px', backgroundColor: greyF4, borderRadius: '5px', alignItems: 'center', justifyContent: 'center', marginTop: '27px', width: 'fit-content'}}
                        onClick={navigateToEditNickname}
                    >
                        <span style={{fontSize: '1.375rem', fontWeight: 700}}>{nickname}</span><IcEdit color={secondaryBlack} width='20px' height='20px' />
                    </div>
                    {   
                        autoGeneratedNickname == nickname &&
                        <RegularText_17 style={{color: secondaryBlack, marginTop: '50px', lineHeight: '1.5'}}>
                            {`${nickname}(은)는 자동 부여된 별명입니다.`}<br/>
                            {`별명을 바꾸려면  `}<IcEdit color={secondaryBlack} width='14px' height='14px' onClick={navigateToEditNickname} style={{marginLeft: '2px'}}/>{` 클릭 후 새로 적어주세요.`}
                        </RegularText_17>
                    }
                </div>
            }
            bottom={
                <div style={{padding: '15px'}}>
                    <FillButton
                        text="시작하기"
                        active={true}
                        onClick={() => {
                            escapeFromCTA = true;
                            trackEvent(EVENT_ONBOARDING_START_CTA_CLICK, undefined)
                            navigate(-1)
                        }} 
                    />
                </div>
            } />
    );
};

export default OnboardingPage;
