import { useLocation, useNavigate } from "react-router-dom";
import { EVENT_ASK_WIZDOM_CLICK, EVENT_INQURIY_SELECT_PAGE_SHOW } from "@components/AppConstant";
import icKakao from "@assets/ic_kakao.webp";
import * as amplitude from "@amplitude/analytics-browser"
import IcPhoneBlack from '@assets/ic_phone_black.svg';
import server_domain from "@components/RemoteServer";
import useOnLog from "@components/useOnLog";
import IcClose from '@assets/close.svg';
import {isForPartners} from "@util/isForPartners";
import { fetchWithRetry } from "@util/networks";
import trackEvent from "@src/util/trackEvent";

const InquriyDrawer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    useOnLog({
        pageNameKey: EVENT_INQURIY_SELECT_PAGE_SHOW,
        amplitudeProp: state.amplitudePageShowProp
    })

    async function call() {

        let body = {
            "activity_id": state.activityId,
        }

        await fetchWithRetry(server_domain() + "/request_call", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        })

        if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            trackEvent(EVENT_ASK_WIZDOM_CLICK, {
                "type": "call",
                ...state.amplitudeButtonEvenetProp,
            })
            
            window.flutter_inappwebview.callHandler('fromJS', '1660-2958');
        }
    }

    async function openKakao() {
        let body = {
            "activity_id": state.activityId,
        }

        await fetchWithRetry(server_domain() + "/request_kakao", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        })

        trackEvent(EVENT_ASK_WIZDOM_CLICK, {
            "type": "kakao",
            ...state.amplitudeButtonEvenetProp,
        });

        if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            window.flutter_inappwebview.callHandler('fromJSKakao');
        }
    }

    const preventEvenPropagation: React.MouseEventHandler = (e) => {
        e.stopPropagation();
    }

    return (
        <div style={{ position: 'fixed', top: 0, zIndex: 2, height: '100vh', width: "100vw", maxWidth: isForPartners ? 800 : 650, background: "#000000B3", }} onClick={() => navigate(-1)}>
            <div
                style={{  paddingBottom: '24px', position: 'fixed', bottom: 0, width: '100%', background: 'white', borderRadius: '10px 10px 0px 0px', }}
                onClick={preventEvenPropagation}
            >
                <div style={{display:'flex', alignItems: 'center', padding: '24px 16px 0px', marginBottom: '16px' }}>
                    <p style={{ margin:0, lineHeight:1.5, flex: 1,  fontWeight: 500, fontSize: '1.25rem', }}>{state.title}</p>
                    <IcClose onClick={() => navigate(-1)}/>
                </div>
                <p style={{padding: '0px 16px', margin: '0px 0px 24px 0px', lineHeight: 1.5, whiteSpace: 'pre-wrap'}}>
                    {state.description} 
                </p>
                <div style={{ margin: '0px 16px' }}>
                    <div style={{ display: 'flex', width: '100%', gap: '16px' }}>
                        <button style={{ flex: 1, borderRadius: '5px', justifyContent: 'center', display: 'inline-flex', padding: '16px 12px', border: '1px solid #999', alignItems: 'center', background: 'transparent' }} onClick={call} >
                            <IcPhoneBlack style={{ marginRight: '8px' }}/>
                            <p style={{ margin: 0, lineHeight: 1.5, color: 'black', fontSize: '1rem', fontWeight: '500' }}>전화하기</p>
                        </button>
                        <button style={{ flex: 1, borderRadius: '5px', justifyContent: 'center', display: 'inline-flex', padding: '16px 12px', border: '1px solid #999', alignItems: 'center', background: 'transparent' }} onClick={openKakao} >
                            <img src={icKakao} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '8px' }} />
                            <p style={{ margin: 0, lineHeight: 1.5, color: 'black', fontSize: '1rem', fontWeight: '500' }}>카카오톡</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InquriyDrawer;