import IcScrapActiveGreenBlack from '@assets/ic_scrap_active_green_black.svg';
import ActivityList from "@components/ActivityList";
import {EVENT_JOB_SCRAP_TAB_SHOW, EVENT_SCRAP_PAGE_SHOW} from "@components/AppConstant";
import {useSelector} from "react-redux";
import useOnLog from "@components/useOnLog";
import {AppInstallGuideBanner} from "@components/AppInstallGuideBanner";
import {RootState} from '@reducer/store';
import {DefaultTabTemplate} from "@components/PageTemplate";

const NoContentView = () => {
  return (
    <div style={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center'}}>
      <p style={{textAlign: 'center', fontWeight: 400, fontSize: '1.25rem', margin: 0, lineHeight: 1.5}}>스크랩한 공고가 없습니다</p>
      <p key={"phrase2"} style={{textAlign: 'center', margin: 0, lineHeight: 1.5}}>관심가는 공고를 쉽게 다시 보려면</p>
      <p key={"phrase3"} style={{textAlign: 'center', margin: 0, lineHeight: 1.5}}>스크랩(<IcScrapActiveGreenBlack style={{width: 'auto', height: '1.1rem', verticalAlign: 'middle', margin: '0'}}/>)하세요</p>
    </div>
  )
}

const JobScrapView = () => {
  const scrapedActivity = useSelector((state: RootState) => state.scrap.value);

  useOnLog({
    pageNameKey: EVENT_JOB_SCRAP_TAB_SHOW,
    regStr: "^\/job\/scrap$"
  })

  return (
    <DefaultTabTemplate
      top={<>
        <AppInstallGuideBanner/>
        <div style={{height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <p style={{textAlign: 'center', fontSize: '1.25rem', fontWeight: 500, margin: 0}}>스크랩</p>
        </div>
      </>}
      middle={<>
        {scrapedActivity && <ActivityList activities={Object.values(scrapedActivity).reverse()} location={"ScrapPage"} noContentView={<NoContentView/>} filterEnabled={false}/>}
      </>}
    />
  );
}

export default JobScrapView;