import { createSlice } from "@reduxjs/toolkit";

const askedActivitySlice = createSlice({
    name: 'askedActivity',
    initialState: {
        value: [] as any[]
    },
    reducers: {
        appendAskedActivity: (state, action) => {
            state.value.push(action.payload)
        },
        cleanAskedActivity: (state) => {
            state.value = []
        },
        setAskedActivity: (state, action) => {
            state.value = action.payload
        }
    }
})

export const {appendAskedActivity, cleanAskedActivity, setAskedActivity} = askedActivitySlice.actions
export default askedActivitySlice.reducer
