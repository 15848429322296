import { DefaultTabTemplate } from "@src/components/PageTemplate";
import { HomeNavToolbar } from "@src/components/Toolbars";
import { VirtualItem, useVirtualizer } from "@tanstack/react-virtual";
import { FunctionComponent, SVGAttributes, useEffect, useRef, useState } from "react";
import Post, { PostCategory } from "../model/Post";
import CommunityService from "../infra/CommunityService";
import { BoldText_22, RegularText_20 } from "@src/components/text";
import { greyF4, primary, primaryFilterForSVG, red, secondary } from "@src/components/Color";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IconFillButton } from "@src/components/Buttons";
import icPencilFill from "@assets/ic_pencil_fill.webp";
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { RootState } from "@src/reducer/store";
import { useDispatch, useSelector } from "react-redux";
import { PostItem } from "./component/PostItem";
import useOnLog from "@src/components/useOnLog";
import { EVENT_CLICK_COMMUNITY_CATEGORY, EVENT_CLICK_WRITE_POST, EVENT_COMMUNITY_PAGE_SHOW } from "@src/components/AppConstant";
import trackEvent from "@src/util/trackEvent";
import IcCategoryAll from '@assets/ic_category_all.svg';
import IcCategoryChild from '@assets/ic_category_child.svg';
import IcCategoryCertification from '@assets/ic_category_certification.svg';
import IcCategoryFinance from '@assets/ic_category_finance.svg';
import IcCategoryFreeTalk from '@assets/ic_category_free_talk.svg';
import IcCategoryHealth from '@assets/ic_category_health.svg';
import IcCategoryHobby from '@assets/ic_category_hobby.svg';
import IcCategoryJob from '@assets/ic_category_job.svg';
import IcCategoryParentCare from '@assets/ic_category_parent_care.svg';
import { ImpressionTracker } from "@src/components/useOnScreenActivity";
import { useOnMountOnce } from "@src/util/useOnMountOnce";
import { AppInstallGuideBanner } from "@src/components/AppInstallGuideBanner";

const CommunityPage = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const parentRef = useRef<HTMLDivElement>(null)
    const nickname = useSelector((state: RootState) => state.user.nickname)
    const dispatch = useDispatch()
    const cached = queryClient.getQueryData(`posts-전체`) as { pages: Post[][]} | undefined
    const [posts, setPosts] = useState<Post[]>(cached?.pages.flatMap(e => e) ?? [])
    const [category, setCategory] = useState<PostCategory>('전체')
    const [lastItemIndex, setLastItemIndex] = useState<number>()
    const [searchParams, setSearchParams] = useSearchParams()
    const showInstallBanner = searchParams.get("show_install_banner") === "true"

    useOnLog({
        pageNameKey: EVENT_COMMUNITY_PAGE_SHOW,
        regStr: "^\/community$"
    })

    const {
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery(
        `posts-${category}`,
        ({ pageParam = { page: 0, category: category } }) => CommunityService.getPosts({ page: pageParam.page, category: pageParam.category}),
        {
            getNextPageParam: (lastPage, allPages) => {
                return lastPage?.length === 0 || lastPage?.length < 30 ? undefined : { page: allPages.length, category: category }
            },
            onSuccess: (data) => {
                setPosts(data.pages.flatMap((d) => d))
            },
            staleTime: 1000 * 60 * 5,
        },
    )

    const virtualizer = useVirtualizer({
        count: posts.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 150,
        overscan: 10
    })
    const items: VirtualItem[] = virtualizer.getVirtualItems()

    useEffect(() => {
        if (!isFetchingNextPage && lastItemIndex === posts.length - 1) {
            fetchNextPage()
        }
    }, [
        hasNextPage,
        fetchNextPage,
        posts.length,
        lastItemIndex,
        isFetchingNextPage,
        category,
    ])

    const handleWritePost = () => {
        trackEvent(EVENT_CLICK_WRITE_POST, {
            'nickname_exists': nickname ? true : false
        })
        if (!nickname) {
            navigate('onboarding')
            return
        }
        navigate('post/write')
    }

    interface CategoryItem {
        name: PostCategory
        Img: FunctionComponent<SVGAttributes<SVGElement>>
    }
    const categoryItems: CategoryItem[] = [
        {name: '전체', Img: IcCategoryAll}, 
        {name: '일자리', Img: IcCategoryJob}, 
        {name: '자격증', Img: IcCategoryCertification},
        {name: '재무', Img: IcCategoryFinance}, 
        {name: '건강', Img: IcCategoryHealth}, 
        {name: '자녀', Img: IcCategoryChild}, 
        {name: '부모 케어', Img: IcCategoryParentCare}, 
        {name: '취미', Img: IcCategoryHobby}, 
        {name: '자유 주제', Img: IcCategoryFreeTalk}
    ]

    useOnMountOnce(() => {
        if (!nickname && window.flutter_inappwebview) {
            navigate('onboarding')
        }
    })

    return (
        <DefaultTabTemplate
            outletContext={[dispatch]}
            top={
                <>
                    { showInstallBanner && <AppInstallGuideBanner /> }
                    <HomeNavToolbar
                        location="community"
                        title="커뮤니티"
                    />
                </>
            }
            middle={
                <div style={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    contain: 'strict',
                    scrollbarWidth: 'none',
                }}>
                    <div style={{lineHeight: 1.5, paddingLeft: '15px', paddingTop: '10px'}}>
                    <BoldText_22>
                    은퇴를 맞이한 사람들의 모임.<br/>고민도 나누고 정보도 교류하세요.
                    </BoldText_22>
                    </div>
                    <div style={{display: 'flex', padding: '20px 0px', paddingRight: '20px', overflow: 'scroll', scrollbarWidth: 'none'}}>
                        {categoryItems.map((item, idx) => {
                            return <div key={idx} 
                                style={{display: 'flex', color: category === item.name ? primary : secondary, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px'}}
                                onClick={() => {
                                    trackEvent(EVENT_CLICK_COMMUNITY_CATEGORY, { 'category': item.name })
                                    setCategory(item.name)
                                    queryClient.invalidateQueries(`posts-${item.name}`)
                                }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        borderRadius: '30px', 
                                        width: '60px', height: '60px',
                                        background: category == item.name ? secondary : greyF4,
                                        alignItems: 'center', justifyContent: 'center',
                                        border: category == item.name ? `solid 1px ${primary}`: undefined
                                    }}>
                                        <item.Img filter={item.name == category ? primaryFilterForSVG : undefined}/>
                                    </div>
                                    <RegularText_20 style={{color: 'black', width: '85px', textAlign: 'center'}}>{item.name}</RegularText_20>
                                </div>
                        })}
                    </div>
                    <div style={{height: '16px', width: '100%', background: greyF4, borderTop: 'solid 1px #D7D8DA', marginBottom: '5px'}}/>
                    <div ref={parentRef}>
                        <div
                            style={{
                                height: virtualizer.getTotalSize(),
                                width: '100%',
                                position: 'relative',
                            }}
                        >
                            {items.map((virtualRow: VirtualItem) => {
                                return (
                                    <div
                                        key={virtualRow.key}
                                        data-index={virtualRow.index}
                                        ref={virtualizer.measureElement}
                                    >
                                        {
                                            (virtualRow.index == posts.length -10)?
                                            <ImpressionTracker onImpressed={() => setLastItemIndex(items.length - 1)}>
                                                <PostItem idx={virtualRow.index} post={posts[virtualRow.index]} totalLength={posts.length} source="list"/>
                                            </ImpressionTracker>
                                            : <PostItem idx={virtualRow.index} post={posts[virtualRow.index]} totalLength={posts.length} source="list"/>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
            bottom={
                <IconFillButton
                    text={'글쓰기'}
                    icon={<img src={icPencilFill} width='19px' height='19px' />}
                    onClick={handleWritePost}
                    style={{ position: 'absolute', right: 0, bottom: '95px', marginRight: '12px', width: '120px', borderRadius: '30px', padding: '15px 10px' }}
                    fontStyle={{ fontSize: '1rem' }}
                />
            }
        />
    );
};

export default CommunityPage;
