import {useNavigate} from "react-router-dom";
import IcJobHomeActive from '@assets/ic_job_home_active.webp';
import IcJobHomeInactive from '@assets/ic_job_home_inactive.webp';
import IcResumeActive from '@assets/ic_resume_active.webp';
import IcResumeInactive from '@assets/ic_resume_inactive.webp';
import IcScrapActive from '@assets/ic_scrap_active.webp';
import IcScrapInactive from '@assets/ic_scrap_inactive.webp';
import IcHistoryActive from '@assets/ic_job_history_active.webp';
import IcHistoryInActive from '@assets/ic_job_history_inactive.webp';
import {DefaultTabPageRoot} from "@src/components/PageTemplate";
import useOnLog from "@components/useOnLog";
import {EVENT_JOB_PAGE_SHOW} from "@components/AppConstant";

const Tab = ({label, path, activeIcon, inactiveIcon, iconSize = 23, showRedDot}: { label: string, path: string, activeIcon: any, inactiveIcon: any, iconSize?: number, showRedDot?: boolean }) => {
  const navigate = useNavigate();
  const NavIconStyle = {padding: "5px",}

  return <div style={{flex: 1, textAlign: 'center'}} onClick={() => navigate(path)}>
    <div style={{position: 'relative', width: 'fit-content', margin: 'auto'}}>
      <img src={location.pathname === path ? activeIcon : inactiveIcon} alt="" width={iconSize} height={iconSize} style={NavIconStyle}/>
      {showRedDot && <div style={{position: 'absolute', top: -3, right: -6, height: 15, width: 15, background: '#F15D47', borderRadius: '100%', border: '2px solid white'}} />}
    </div>
    <p style={{margin: 0, color: '#1D1D1D', fontSize: '0.75rem'}}>{label}</p>
  </div>
}

const JobPageRoot = () => {

  useOnLog({
    pageNameKey:EVENT_JOB_PAGE_SHOW,
    regStr: /\/job$/,
  });

  return <DefaultTabPageRoot>
    <Tab label={"일자리정보"} path={"/job"} activeIcon={IcJobHomeActive} inactiveIcon={IcJobHomeInactive}/>
    <Tab label={"스크랩"} path={"/job/scrap"} activeIcon={IcScrapActive} inactiveIcon={IcScrapInactive}/>
    <Tab label={"지원내역"} path={"/job/history"} activeIcon={IcHistoryActive} inactiveIcon={IcHistoryInActive} showRedDot={true}/>
    <Tab label={"이력서관리"} path={"/job/resume"} activeIcon={IcResumeActive} inactiveIcon={IcResumeInactive}/>
  </DefaultTabPageRoot>

}
export default JobPageRoot;