import {
    persistReducer, persistStore, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import applicationReducer from "./applicationSlice";
import scrapReducer from "./scrapSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import userReducer from "./userSlice";
import snackbarReducer from "./snackbarSlice";
import activityCacheSliceReducer from "./activityCacheSlice";
import askedActivityReducer from "./askedActivitySlice";
import configReducer from "./configSlice";
import scrollReducer from "./scrollSlice";
import surveyReducer from "./surveySlice";
import eventReducer from "./eventSlice";
import postImageReducer from "./postImageSlice";
import communityReducer from "./communitySlice";

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['activityCache'],
};
const activityCachePersistConfig = {
    key: 'activityCache',
    storage: storageSession,
}
const configCachePersistConfig = {
    key: 'configCache',
    storage: storageSession,
}
export type RootState = ReturnType<typeof rootReducer>
const rootReducer = combineReducers({
    activityCache: persistReducer(activityCachePersistConfig, activityCacheSliceReducer),
    scrap: scrapReducer,
    application: applicationReducer,
    user: userReducer,
    snackbarInfo: snackbarReducer,
    askedActivity: askedActivityReducer,
    config: persistReducer(configCachePersistConfig, configReducer),
    scroll: scrollReducer,
    survey: surveyReducer,
    event: eventReducer,
    postImages: postImageReducer,
    community: communityReducer
})
const persistedReducer  =  persistReducer(rootPersistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
        devTools: true
});

export const persistor = persistStore(store);
export default store;