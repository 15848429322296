import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {fetchWithRetry} from "@util/networks";
import server_domain from "@components/RemoteServer";
import LoadingModal from "@src/features/job/component/LoadingModal";

const ActivityHistoryLoader = ({children, eventList}: { children: (history: any, lastEvent: string, saveEvent: (status: string) => void) => React.ReactElement, eventList: string[]}) => {
  const {historyId} = useParams();
  const [history, setHistory] = useState<any>();
  const [lastEvent, setLastEvent] = useState("");
  const uuid = useSelector((state: RootState) => state.user.uuid)

  const saveEvent = (status: string) => {
    if (!history) {
      return
    }
    fetchWithRetry(server_domain() + "/apply_history", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        activity_id: history.activity_id,
        user_uuid: uuid,
        status: status,
        date: null
      })
    })
      .then(() => loadHistory())
  }

  const loadHistory = () => {
    fetchWithRetry(server_domain() + "/apply_history/" + historyId)
      .then(res => res.json())
      .then(history => {
        setLastEvent([...eventList].reverse().find((event) => history.events.find((e: any) => e.status === event)) || "")
        return history
      })
      .then(setHistory)
  }

  useEffect(() => {
    loadHistory()
  }, []);

  if (!history) {
    return <LoadingModal>
      <p style={{textAlign: 'center', fontWeight: 500, margin: 0, lineHeight: 1.5, whiteSpace: 'pre-wrap'}}>{"지원 현황을 불러옵니다.\n잠시만 기다려주세요."}</p>
    </LoadingModal>
  }
  return children(history, lastEvent, saveEvent)
}

export default ActivityHistoryLoader;