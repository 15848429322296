import { EVENT_ACTIVITIES_LOADED, EVENT_POPULAR_ACTIVITIES_LOADED } from "@components/AppConstant"
import server_domain from "@components/RemoteServer"
import convertActivities from "@util/convertActivities"
import * as amplitude from "@amplitude/analytics-browser"
import uuidv4 from "@util/uuidv4"
import { fetchWithRetry } from "@util/networks"
import { Activity } from "@model/Activity"
import { PublicTagContent } from "@src/model/PublicTagContent"

export interface MainPageLoaderResponse {
    popularActivities: Activity[],
    tagContent: PublicTagContent,
    activities: Activity[],
    sessionId: string
}

const mainPageLoader = async (regions: string[], signUpAt: string | undefined, listMode: string, page: number): Promise<MainPageLoaderResponse> => {
    let serialized = JSON.stringify(regions)
    let response = await fetchWithRetry(server_domain() + '/all_activities?regions=' + serialized + "&sign_up_at=" + signUpAt + "&mode=" + listMode + "&page=" + page, {
        headers: { "Content-Type": "application/json" },
    })
    let json = await response.json()
    let popularActivities: Activity[] = convertActivities(json.popular_activities)
    let tagContent: PublicTagContent = json.public_tag_contents
    tagContent.activities = convertActivities(json.public_tag_contents.activities)
    let activities: Activity[] = convertActivities(json.main_page_contents)
    let sessionId = uuidv4()
    amplitude.track(EVENT_POPULAR_ACTIVITIES_LOADED, {
        location: "MainPage",
        ids: popularActivities.map((a: Activity) => a.id),
        regions: regions,
        sessionId: sessionId
    })

    amplitude.track(EVENT_ACTIVITIES_LOADED, {
        location: "MainPage",
        size: activities.length,
        regions: regions,
        sessionId: sessionId
    })

    return { popularActivities: popularActivities, tagContent: tagContent, activities: activities, sessionId: sessionId }
}

export default mainPageLoader;