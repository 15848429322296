import { useEffect, useRef, useState } from "react";
import regionCityData from '../data/region_city_data.json'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { changeRegions } from "@reducer/userSlice";
import { cleanCacheActivities } from "@reducer/activityCacheSlice";
import IcClose from '@assets/close.svg';
import IcRegionChecked from '@assets/ic_region_checked.svg';
import * as amplitude from "@amplitude/analytics-browser"
import { EVENT_REGION_SELECT_DONE } from "@components/AppConstant";
import { FillButton } from "@components/Buttons";
import { BottomDrawerTemplate } from "@components/DrawerTemplate";
import { RootState } from "@reducer/store";

const RegionCityDrawer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const chipRef = useRef<HTMLDivElement>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    let location = searchParams.get('location')
    const { state } = useLocation();
    const directSearch = state?.directSearch;
    const regions = useSelector((state: RootState) => state.user.regions)

    const regionList = Object.keys(regionCityData);
    const [selectedRegion, setSelectedRegion] = useState(initSelectedRegion());
    const [cityList, setCityList] = useState(loadCities());

    const [selectedRegionCity, setSelectedRegionCity] = useState(regions);

    function loadCities() {
        return ['전체'].concat(regionCityData[selectedRegion])
    }

    function initSelectedRegion() {
        if (regions.length == 0) {
            return "서울"
        } else {
            return regions[0].split(' ')[0]
        }
    }

    useEffect(() => {
        setCityList(loadCities())
    }, [selectedRegion])

    function handleChipClick(regionCity: string) {
        let newArr = selectedRegionCity.slice();
        var index = newArr.indexOf(regionCity);
        if (index >= 0) {
            newArr.splice(index, 1);
        }
        setSelectedRegionCity(newArr);
    }

    function handleCityClick(city: string) {
        let newArr = selectedRegionCity.slice();
        if (city == "전체") {
            let regionCity = `${selectedRegion} ${city}`
            if (newArr.includes(regionCity)) {
                var index = newArr.indexOf(regionCity);
                if (index >= 0) {
                    newArr.splice(index, 1);
                }
            } else {
                const result = newArr.filter((word) => !word.includes(selectedRegion));
                result.push(regionCity)
                newArr = result
            }
        } else {
            let regionCity = `${selectedRegion} ${city}`
            let regionAll = `${selectedRegion} 전체`
            if (newArr.includes(regionAll)) {
                var index = newArr.indexOf(regionAll);
                if (index >= 0) {
                    newArr.splice(index, 1);
                }
            }
            if (newArr.includes(regionCity)) {
                var index = newArr.indexOf(regionCity);
                if (index >= 0) {
                    newArr.splice(index, 1);
                }
            } else {
                newArr.push(regionCity)
            }
        }
        setSelectedRegionCity(newArr)
    }

    useEffect(() => {
        setTimeout(() => {
            chipRef.current?.lastElementChild?.scrollIntoView(
              { behavior: "smooth", block: "end", inline: "end" },
            );
        }, 1000)

    }, [selectedRegionCity])

    const Cta = () => {
        function handle() {
            dispatch(changeRegions(selectedRegionCity))
            dispatch(cleanCacheActivities())
            amplitude.track(EVENT_REGION_SELECT_DONE, {
                region_city: selectedRegionCity,
                location: location,
            })
            if (directSearch) {
                navigate('/job/activity_list', { replace: true })
            } else {
                navigate(-1)
            }
        }

        return (
            <FillButton
                text={selectedRegionCity.length === 0 ? "전체 지역 공고 보기" : "선택 지역 공고 보기"}
                onClick={handle}
            />
        )
    }

    return (
        <BottomDrawerTemplate
            title={'지역'}
            subtitle={'복수 선택 가능'}
            show={true}
            style={{padding: '0px 0 0px'}}
            onClose={() => navigate(-1)}
        >
            <div key="cities" style={{ display: 'flex', overflowY: 'auto', borderTop: '1px solid #D7D8DA' }}>
                <div style={{ width: '125px', height: '300px', overflowY: "scroll", borderRight: '1px solid #D7D8DA', position: 'relative', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: 1, width: '100%', position: 'relative' }}>
                        <div style={{ position: 'absolute' }}>
                            {(regionList.length > 0) && regionList.map((e, idx) => {
                                return <button
                                    key={idx}
                                    onClick={() => { setSelectedRegion(e) }}
                                    style={{
                                        border: 'none',
                                        color: selectedRegion == e ? 'white' : '#1d1d1d',
                                        background: selectedRegion == e ? '#0ABF53' : 'white',
                                        width: '100%',
                                        borderBottom: '1px solid #D7D8DA',
                                        borderRadius: '0px',
                                        padding: '12px 16px',
                                    }}>
                                    <p style={{ fontSize: '1rem', margin: 0, lineHeight: 1.5 }}>{e}</p>
                                </button>
                            })}
                        </div>
                    </div>
                </div>
                <div style={{ overflowY: 'scroll', width: '100%', height: '300px', }}>
                    {cityList && (cityList.length > 0) && cityList.map((e, idx) => {
                        let isChecked = selectedRegionCity.includes(`${selectedRegion} ${e}`);
                        const checkedStyle = isChecked ? { color: "#0ABF53" } : {};
                        return <button key={idx} onClick={() => handleCityClick(e)} style={{ display: 'flex', alignItems: 'center', border: 'none', background: 'none', color: '#1d1d1d', ...checkedStyle, width: '100%', marginBottom: '1px', borderRadius: '0px', padding: '12px 16px', justifyContent: 'start' }}>
                            <p style={{ margin: 0, lineHeight: 1.5, fontSize: '1rem', width: '83%', textAlign: 'left', }}>{e}</p>
                            {isChecked && (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><IcRegionChecked /></div>)}
                        </button>
                    })}
                </div>
            </div>

            <div style={{ borderTop: '1px solid #D7D8DA', padding: '2%' }}>
                <div style={{ marginBottom: "2%", display: 'flex', overflowX: 'scroll', }} ref={chipRef}>
                    {
                        selectedRegionCity.map((rc, idx) => {
                            return <div key={idx} style={{
                                border: "1px solid #D7D8DA",
                                borderRadius: "100px",
                                margin: '0px 1%',
                                padding: '1% 2%',
                                display: 'flex',
                                alignItems: 'center',
                            }} onClick={() => { handleChipClick(rc) }}>
                                <p key={idx} style={{ margin: 0, lineHeight: 1.5, marginRight: '5px', fontWeight: 300, fontSize: '0.875rem', whiteSpace: 'nowrap'}} >{rc}</p>
                                <IcClose height={10} />
                            </div>
                        })}
                </div>
                <Cta />
            </div>

        </BottomDrawerTemplate>
    )
}

export default RegionCityDrawer;