import {DefaultTabTemplate} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import React, {useEffect, useState} from "react";
import SimpleApplyActivityHistoryCard from "@src/features/job/features/history/component/SimpleApplyActivityHistoryCard";
import DefaultActivityHistoryCard from "@src/features/job/features/history/component/DefaultActivityHistoryCard";
import {FillButton} from "@components/Buttons";
import {useNavigate} from "react-router-dom";
import {fetchWithRetry} from "@util/networks";
import server_domain from "@components/RemoteServer";
import {useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {ApplyHistory} from "@src/features/job/features/history/model/ApplyHistory";
import convertActivity from "@util/convertActivity";
import useOnLog from "@components/useOnLog";
import {EVENT_JOB_HISTORY_PAGE_SHOW, EVENT_JOB_PAGE_SHOW} from "@components/AppConstant";

const HistoryType = ["간편지원", "간편지원 외"]
const Description: { [key: string]: string } = {
  "간편지원": "위즈덤 이력서를 사용하여 간편지원한 내역들을 최신순으로 보여드립니다",
  "간편지원 외": "지원방법을 확인한 내역들을 최신순으로 보여드립니다"
}
const HistoryQueryType: { [key: string]: string } = {
  "간편지원": "simple_apply",
  "간편지원 외": "apply"
}
const EmptyDescription: { [key: string]: string } = {
  "간편지원": "간편지원한 내역이 없습니다",
  "간편지원 외": "지원방법 확인 내역이 없습니다"
}

const SimpleApplyHistoryView = ({histories} : {histories: ApplyHistory[]}) => {
  return <> {histories.map(history => <SimpleApplyActivityHistoryCard history={history} location={""} regions={[]}/>)} </>
    
}
const ApplyHistoryView = ({histories} : {histories: ApplyHistory[]}) => {
  return <> {histories.map(history => <DefaultActivityHistoryCard history={history} location={""} regions={[]}/>)} </>
}

const JobHistoryView = () => {
  const navigate = useNavigate();
  const uuid = useSelector((state: RootState) => state.user.uuid)
  const lastUsedApplyMethod = useSelector((state: RootState) => state.config.lastUsedApplyMethod)
  const [selectedType, setSelectedType] = useState(lastUsedApplyMethod || HistoryType[0])
  const [histories, setHistories] = useState<ApplyHistory[] | null>(null)

  useOnLog({
    pageNameKey:EVENT_JOB_HISTORY_PAGE_SHOW,
    amplitudeProp: {},
    regStr: "^\/job\/history$"
  })

  useEffect(() => {
    setHistories(null)
    fetchWithRetry(server_domain() + "/apply_histories/" + uuid + "?type=" + HistoryQueryType[selectedType])
      .then(res => res.json())
      .then(data =>
        data.map((rawHistory: any) => ({
          id: rawHistory.id,
          activity: convertActivity(rawHistory.activity),
        }) as ApplyHistory)
      )
      .then(setHistories)
  }, [selectedType]);

  return <>
    <DefaultTabTemplate
      top={<>
        <BackNavToolbar title={"지원 내역"} icon={<></>}/>
        <div style={{display: 'flex'}}>
          {
            HistoryType.map(type => <p style={{margin: 0, flex: 1, fontWeight: 500, textAlign: "center", padding: 10, borderBottom: type === selectedType ? "3px solid #0ABF53" : "1px solid #D7D8DA", color: type === selectedType ? "#0ABF53" : "#666"}} onClick={() => setSelectedType(type)}>{type}</p>)
          }
        </div>
      </>}
      middle={<>
        <p style={{margin: 16, padding: 16, lineHeight: 1.5, borderRadius: 5, background: '#F4F4F4'}}>{Description[selectedType]}</p>
        {
          histories !== null && histories.length === 0 && <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, 0%)', width: '100%'}}>
            <div style={{padding: 16}}>
              <p style={{margin: '0 0 30px', lineHeight: 1.5, fontWeight: 500, textAlign: 'center'}}>{EmptyDescription[selectedType]}</p>
              <FillButton
                text={"공고 찾아보기"}
                onClick={() => navigate("activity_list")}
              />
            </div>
          </div>
        }
        {
          histories !== null && selectedType === "간편지원" && <SimpleApplyHistoryView histories={histories}/>
        }
        {
          histories !== null && selectedType === "간편지원 외" && <ApplyHistoryView histories={histories}/>
        }
      </>}
    />
  </>
}

export default JobHistoryView;