import ListQueryMode from "@util/ListQueryMode";
import {CSSProperties} from "react";
import {BottomDrawerTemplate} from "@components/DrawerTemplate";
import {primary} from "@components/Color";
import IcCheckMark from "@assets/ic_check_mark.svg";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {updateListQueryMode} from "@reducer/configSlice";
import {cleanCacheActivities} from "@reducer/activityCacheSlice";
import useOnLog from "@components/useOnLog";
import {EVENT_JOB_LIST_QUERY_CHANGE, EVENT_JOB_PAGE_SHOW} from "@components/AppConstant";
import trackEvent from "@util/trackEvent";

export const ListQueryModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listQueryMode = useSelector((state: RootState) => state.config.listQueryMode) || "RECOMMEND"
  const itemStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.25rem',
    fontWeight: 300,
    padding: '12px 0',
  }
  const items: { key: ListQueryMode, title: string }[] = [
    {key: 'RECOMMEND', title: '추천순'},
    {key: 'LASTEST', title: '최신순'},
  ]

  return (
    <BottomDrawerTemplate
      title={'정렬'}
      subtitle={''}
      show={true}
      style={{padding: '0 16px 16px'}}
      onClose={() => navigate(-1)}
    >
      {items.map((item) => {
        return (
          <div
            key={item.key}
            onClick={() => {
              dispatch(cleanCacheActivities())
              dispatch(updateListQueryMode(item.key))
              trackEvent(EVENT_JOB_LIST_QUERY_CHANGE, {mode: item.key})
              navigate(-1)
            }}
            style={itemStyle}
          >
            <span style={{fontSize: '1rem', fontWeight: '400', color: item.key === listQueryMode ? primary : 'black'}}>{item.title}</span>
            <IcCheckMark style={{visibility: item.key === listQueryMode ? 'visible' : 'hidden'}} width={30} height={30}/>
          </div>
        )
      })}
    </BottomDrawerTemplate>
  )
}

export default ListQueryModal;