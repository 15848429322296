import IcArrowRight from '@assets/ic_arrow_right.webp';

const Padding5perListItem = (prop: {
    text: string,
    onClick: () => void,
}) => {
    const { text, onClick } = prop
    return ( 
        <div style={{display: 'flex', alignItems: 'center'}} onClick={onClick}>
            <p style={{flex: 1}}>{text}</p>
            <img src={IcArrowRight} alt="" style={{ marginLeft: '0.5rem', height: '100%', width: '0.5rem', marginRight: 10}} />
        </div>
    );
}

export default Padding5perListItem;