const FirebaseImageLoader = (props: {
    filename?: string,
    path?: string
}) => {
    const { filename, path } = props
    let imageUrl = ""
    if (filename) {
        imageUrl = "https://storage.googleapis.com/activity_img/activities/" + filename
    } else {
        imageUrl = "https://storage.googleapis.com/activity_img/" + path
    }
    return (
        <img style={{ width: '100%', margin: "0px" }} src={imageUrl} />
    )
}

export default FirebaseImageLoader;