import * as amplitude from '@amplitude/analytics-browser';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FillButton } from '@components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { changePhoneNumber, changeSignUpAt } from '@reducer/userSlice';
import IcClose from '@assets/close.svg';
import { EVENT_LOGIN_PAGE_SHOW, EVENT_COMPLETE_LOGIN_TO_APPLY } from '@components/AppConstant';
import useOnLog from '@components/useOnLog';
import server_domain from "@components/RemoteServer"
import {isForPartners} from "@util/isForPartners";
import { fetchWithRetry } from '@util/networks';
import { RootState } from 'src/reducer/store';
import styles from "@src/features/job/features/apply/style/input.module.css";

const LoginView = () => {
  const [number, setNumber] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceId = useSelector((state: RootState) => state.user.deviceId)
  const [highlightLabel, setHighlightLabel] = useState(false);

  useOnLog({
    pageNameKey: EVENT_LOGIN_PAGE_SHOW,
    amplitudeProp: {
      "location": "mypage",
    },
    regStr: "^\/mypage/login$"
  })

  const changeNumber: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length > 11) {
      return;
    }
    setNumber(e.target.value);
  };

  async function onClick() {
    let res = await fetchWithRetry(server_domain() + "/user", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "device_id": deviceId,
        "phone_number": number || "",
      })
    });

    let data = await res.json();
    if (data.result == "success") {
      dispatch(changePhoneNumber(number))
      dispatch(changeSignUpAt(data.user.sign_up_at))

      amplitude.track(EVENT_COMPLETE_LOGIN_TO_APPLY, {
        "phone_number": number,
        "location": "loginPage"
      })

      // @ts-ignore
      navigate(-1, { replace: true });
    } else {
      // @ts-ignore
      navigate(-1, { replace: true });
    }
  }

  function handleClose() {
    navigate(-1);
  }

  return (
    <div style={{
      position: 'absolute',
      height: '100vh',
      maxWidth: isForPartners ? 800 : 650,
      width: '100vw',
      background: 'white',
      zIndex: 2
    }}>
      <div style={{
        padding: '0px 5%',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <button style={{ padding: '5% 0px 0px 0px', color: '#1D1D1D', background: 'transparent', border: 'none', width: 'fit-content' }} onClick={handleClose}>
          <IcClose />
        </button>

        <p style={{ color: '#1D1D1D', fontWeight: 500, fontSize: '1.375rem', margin: '1.5rem 0 1rem' }}>휴대전화 번호를 입력해주세요</p>
        <p style={{ color: '#1D1D1D', fontWeight: 400, fontSize: '1.125rem', lineHeight: '1.3', margin: '0 0 1rem' }} >휴대전화 번호로 3초만에 간편히 가입합니다. 번호는 철저히 보관됩니다.</p>

        <div style={{ position: 'relative', width: '100%', marginTop: 30 }}>
          <span style={{ position: 'absolute', fontSize: '0.75rem', top: '-0.375rem', background: 'white', left: '0.675rem', color: highlightLabel ? '#0ABF53' : '#666666', padding: '0 5px' }}>휴대전화 번호</span>
          <input
            className={styles.input}
            onFocus={(e) => setHighlightLabel(true)}
            onBlur={(e) => setHighlightLabel(false)}
            autoFocus={true}
            type='tel'
            value={number}
            placeholder="- 없이 숫자만 입력"
            style={{ margin: 0, width: '100%', marginBottom: '2rem', lineHeight: 1.5, fontSize: '1.25rem', color: '#666666', padding: '16.5px 14px', borderRadius: 4, border: '1px solid #66666687', boxSizing: 'border-box' }}
            onChange={changeNumber}
          />
        </div>

        {
          number.match(/^[0-9]{11}$/g) === null ?
            <FillButton
              style={{ color: "#FFFFFF", background: '#d9d9d9', }}
              text={'시작하기'}
            />
            :
            <FillButton
              onClick={onClick}
              text={'시작하기'}
            />
        }
      </div>
    </div>
  );
}

export default LoginView