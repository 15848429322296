import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Description, HSelector, Indicator, NavigateButton, TextField, YMInput} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";
import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CertificateState, saveCertificate} from "@reducer/userSlice";
import uuidv4 from "@util/uuidv4";
import {RootState} from "@reducer/store";
import IcClose from "@assets/close.svg";

const AddCertificate = () => {

  const dispatch = useDispatch();
  const {certificateId} = useParams()
  const resume = useSelector((state: RootState) => state.user.resume)
  const certificate = resume.certificate.find((cert: CertificateState) => cert.uuid === certificateId)
  const isEdit = !!certificate

  const [name, setName] = useState(certificate?.name || "")
  const [publisher, setPublisher] = useState(certificate?.publisher ||"")
  const [acquiredAt, setAcquiredAt] = useState(certificate?.acquired_at || "")
  const navigate = useNavigate();

  const available = () => name !== "" && publisher !== "" && acquiredAt !== ""
  const next = () => {
    dispatch(saveCertificate({
      uuid: uuidv4(),
      name: name.trim(),
      publisher: publisher.trim(),
      acquired_at: acquiredAt.trim(),
      no_certificate: false
    }))
    navigate(-1)
  }

  return <DefaultPageRoot
    top={<BackNavToolbar title={""} icon={<IcClose />}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <TextField label={"자격증명"} placeholder={"예) 1종대형운전면허"} value={name} onChange={setName} highlight={isEdit && name===""}/>
      <TextField label={"발행처"} placeholder={"예) 경찰청"} value={publisher} onChange={setPublisher} highlight={isEdit && publisher===""}/>
      <YMInput label={"취득월"} placeHolder={"202312"} init={acquiredAt} onChange={setAcquiredAt} highlight={isEdit && acquiredAt===""}/>
    </div>}
    bottom={<div style={{padding: 16}}>
      <FillButton
        active={available()}
        text={"추가"}
        onClick={next}
      />
    </div>}
  />
}

export default AddCertificate;