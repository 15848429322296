import { useNavigate } from "react-router-dom"
import Post from "../../model/Post"
import { LightText_13, MediumText_20, RegularText_17, RegularText_20 } from "@src/components/text"
import { grey999, red, secondaryBlack } from "@src/components/Color"
import { convertToHangul, convertToKSTString } from "@src/util/convertDatetime"
import { EVENT_CLICK_POST_ITEM, EVNET_POST_ITEM_IMPRESSED } from "@src/components/AppConstant"
import trackEvent from "@src/util/trackEvent"
import { PostCateogryTag } from "./PostCateogryTag"
import IcHeart from "@assets/ic_heart.svg";
import icComment from "@assets/ic_comment.webp";
import { CSSProperties } from "react"
import { ImpressionTracker } from "@src/components/useOnScreenActivity"

type PostItemDateFormat = "string" | "korean";

export const PostItem = (
    { idx, post, dateFormat = "korean", nicknameEnabled = true, source, totalLength, style, newMarkEnabeld = true }:
    { idx: number, post: Post, dateFormat?: PostItemDateFormat, nicknameEnabled?: boolean, source: string, totalLength: number, style?: CSSProperties, newMarkEnabeld?: boolean }
) => {
    const navigate = useNavigate()
    const onClick = () => {
        trackEvent(EVENT_CLICK_POST_ITEM, { 
            'post_id': post.id ,
            'source': source,
        })
        navigate(`post/${post.id}`)
    }

    const createdAt = convertToHangul(post.created_at)
    const isNewPost = createdAt.includes('시간') || createdAt.includes('분') || createdAt.includes('지금')
    
    return (
        <ImpressionTracker eventName={EVNET_POST_ITEM_IMPRESSED} props={{post_id: post.id, like_count: post.like_count, comment_count: post.comment_count, source: source}}>
            <article style={{ margin: '0px 15px', padding: '15px 0px', borderBottom: (idx  < totalLength -1) && totalLength != 1  ? 'solid 1px #F4F4F4' : undefined, ...style}} onClick={onClick}>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
                    <div style={{display: 'inline-block', width: '100%'}}>
                        {   (newMarkEnabeld && isNewPost || post.category) &&
                            <div style={{display: 'inline-flex', gap: '8px'}}>
                            {
                                newMarkEnabeld && isNewPost && <LightText_13 style={{ display: 'inline-block', lineHeight: '1.2', color: 'white', backgroundColor: red, borderRadius: '5px', padding: '4px 0px', width: '24px', textAlign: 'center' }}>N</LightText_13>
                            }
                            {
                                post.category ? <PostCateogryTag category={post.category}/> : null
                            }
                            </div>
                        }
                        <div style={{display: '-webkit-box', overflow: 'hidden', overflowWrap: 'break-word', lineHeight: '1.4', textOverflow: "ellipsis", WebkitLineClamp: "2", WebkitBoxOrient: "vertical", marginTop: '5px'}}>
                            <MediumText_20 style={{lineHeight: '1.5'}}>{post.title}</MediumText_20>
                        </div>
                    </div>
                    {
                        post.image_urls && (
                            <div style={{ position: 'relative', alignSelf: 'end' }}>
                                {post.image_urls.length > 1 && <div style={{ position: 'absolute', top: 0, left: 0, borderTopLeftRadius: '5px', width: '18px', height: '18px', backgroundColor: '#00000088', zIndex: 1, color: 'white', fontSize: '0.75rem', justifyContent: 'center', textAlign: 'center' }}>
                                    {post.image_urls.length}
                                </div>
                                }
                                <img src={post.image_urls[0]} alt="thumbnail" style={{ width: '56px', height: '56px', objectFit: 'cover', borderRadius: '5px' }} />
                            </div>
                        )
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', alignItems: 'center' }}>
                    <div style={{ display: 'inline-flex', gap: '4px' }}>
                        {nicknameEnabled &&
                            <>
                                <RegularText_17 style={{ color: grey999 }}>{post.nickname}</RegularText_17>
                                <RegularText_17 style={{ color: grey999 }}>∙</RegularText_17>
                            </>
                        }
                        <RegularText_17 style={{ color: grey999 }}>{dateFormat === 'korean' ? createdAt : convertToKSTString(post.created_at)}</RegularText_17>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: '2px'}}>
                        <IcHeart />
                        <RegularText_17 style={{ color: secondaryBlack, paddingRight: '2px' }}>{post.like_count}</RegularText_17>
                        <img src={icComment} width='24px' height='24px'></img>
                        <RegularText_17 style={{ color: secondaryBlack }}>{post.comment_count}</RegularText_17>
                    </div>
                </div>
            </article>
        </ImpressionTracker>
    )
}

export const RecommendPostItem = ({ idx, post, totalLength, sourcePostId }: { idx: number, post: Post, totalLength: number, sourcePostId: string }) => {
    const navigate = useNavigate()
    const onClick = () => {
        trackEvent(EVENT_CLICK_POST_ITEM, { 
            'post_id': post.id ,
            'source': 'recommend',
            'source_post_id': sourcePostId,
        })
        navigate(`/community/post/${post.id}`)
    }
    
    return (
        <ImpressionTracker eventName={EVNET_POST_ITEM_IMPRESSED} props={{post_id: post.id, like_count: post.like_count, comment_count: post.comment_count, source: 'recommend', source_post_id: sourcePostId}}>
            <article style={{ display: 'flex', padding: '15px 0px', borderBottom: ((idx == totalLength -1) || totalLength == 1)  ? undefined : 'solid 1px #F4F4F4'}} onClick={onClick}>
                <div style={{width: '100%'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
                    <div style={{display: 'inline-block', width: '100%'}}>
                        {
                            post.category ? <PostCateogryTag category={post.category}/> : null
                        }
                        <div style={{display: '-webkit-box', overflow: 'hidden', overflowWrap: 'break-word', lineHeight: '1.4', textOverflow: "ellipsis", WebkitLineClamp: "2", WebkitBoxOrient: "vertical", marginTop: '5px'}}>
                            <MediumText_20 style={{lineHeight: '1.5'}}>{post.title}</MediumText_20>
                        </div>
                    </div>
                    {
                        post.image_urls && (
                            <div style={{ position: 'relative', alignSelf: 'end' }}>
                                {post.image_urls.length > 1 && <div style={{ position: 'absolute', top: 0, left: 0, borderTopLeftRadius: '5px', width: '18px', height: '18px', backgroundColor: '#00000088', zIndex: 1, color: 'white', fontSize: '0.75rem', justifyContent: 'center', textAlign: 'center' }}>
                                    {post.image_urls.length}
                                </div>
                                }
                                <img src={post.image_urls[0]} alt="thumbnail" style={{ width: '56px', height: '56px', objectFit: 'cover', borderRadius: '5px' }} />
                            </div>
                        )
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', alignItems: 'center' }}>
                    <div style={{ display: 'inline-flex', gap: '4px' }}>
                    <RegularText_17 style={{ color: grey999 }}>{post.nickname}</RegularText_17>
                    <RegularText_17 style={{ color: grey999 }}>∙</RegularText_17>
                        <RegularText_17 style={{ color: grey999 }}>{convertToHangul(post.created_at)}</RegularText_17>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: '2px'}}>
                        <IcHeart />
                        <RegularText_17 style={{ color: secondaryBlack, paddingRight: '2px'}}>{post.like_count}</RegularText_17>
                        <img src={icComment} width='24px' height='24px'></img>
                        <RegularText_17 style={{ color: secondaryBlack }}>{post.comment_count}</RegularText_17>
                    </div>
                </div>
                </div>
            </article>
        </ImpressionTracker>
    )
}
