import React from "react";
import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Button, Description, VSelector} from "@components/form/ResumeComponent";
import {useNavigate} from "react-router-dom";
import {saveCertificateTemp} from "@reducer/userSlice";
import uuidv4 from "@util/uuidv4";
import {useDispatch} from "react-redux";

const EditResumeCheckCertificateView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const noCertificate = () => {
    dispatch(saveCertificateTemp({
      uuid: uuidv4(),
      name: "",
      publisher: "",
      acquired_at: "",
      no_certificate: true,
    }))
    navigate(-1)
  }
  const yesCertificate = () => {
    navigate("../certificate", {replace: true})
  }

  return <DefaultPageRoot
    top={<BackNavToolbar title={""}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <Description text={"자격증이 있나요?"}/>
      <Button checked={false}  label={"자격증 있어요"} onClick={yesCertificate}/>
      <Button checked={false}  label={"자격증이 하나도 없어요"} onClick={noCertificate}/>
    </div>}
  />
}

export default EditResumeCheckCertificateView;