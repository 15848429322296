import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Divider} from "@components/DivideBar";
import {useNavigate} from "react-router-dom";
import IcHomeInactive from "@assets/ic_home_inactive.webp";
import {BlackText_25, MediumText_25, RegularText_20} from "@components/text";
import {grey999, primary} from "@components/Color";
import {FillButton} from "@components/Buttons";
import React from "react";
import {useDispatch} from "react-redux";
import {show} from "@reducer/snackbarSlice";
import trackEvent from "@util/trackEvent";
import IcArrowRight from "@assets/ic_arrow_right.webp";
import {EVENT_FINANCE_ITEM_CLICK, EVENT_FINANCE_MORE_CLICK} from "@components/AppConstant";

const FinanceListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickNotification = () => {
    trackEvent(EVENT_FINANCE_MORE_CLICK, {})
    dispatch(show({message: "금융 서비스 오픈 알림을 신청하였습니다."}))
  }

  return <>
    <DefaultPageRoot
      top={
        <BackNavToolbar
          to={"/"}
          icon={<img alt={""} src={IcHomeInactive} width='24px' height='24px'></img>}
          titleElement={
            <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', gap: '4px'}}>
              <BlackText_25 style={{color: primary}}>위즈덤</BlackText_25>
              <MediumText_25 style={{color: primary}}>금융</MediumText_25>
            </div>
          }
        />
      }
      middle={<>
        <div style={{padding: '5% 5% 2rem 5%', textAlign: 'center', lineHeight: '1.5', fontWeight: 500, fontSize: "1rem"}}>
          <p style={{textAlign: 'left', whiteSpace: 'pre-wrap', fontWeight: 700, fontSize: "1.25rem", color: '#1D1D1D', margin: 0}}>{"안정적인 자산 관리\n불필요한 지출은 줄이고, 혜택은 늘리는 금융 생활을 다룹니다."}</p>
        </div>
        <Divider/>

        <p style={{marginLeft: 16, fontSize: '1.25rem', fontWeight: 700}}>콘텐츠</p>
        <div style={{margin: 16, border: '1px solid #999', borderRadius: 5}}>
          <div style={{display: 'flex', padding: '0px 16px', alignItems: 'center'}} onClick={() => {trackEvent(EVENT_FINANCE_ITEM_CLICK, {type: 'saving_installment_account'});  navigate('saving_installment_account')}}>
            <p style={{flex: 1}}>{'예금 적금 상품'}</p>
            <img src={IcArrowRight} alt="" style={{marginLeft: '0.5rem', height: '100%', width: '0.5rem', marginRight: 10}}/>
          </div>
        </div>

        <p style={{marginLeft: 16, fontSize: '1.25rem', fontWeight: 700}}>준비 중 콘텐츠</p>
        <div style={{margin: '15px', marginTop: '20px', padding: '20px', border: `solid 1px ${grey999}`, display: 'flex', flexDirection: 'column', borderRadius: '5px'}}>
          <RegularText_20 style={{marginBottom: 20, textAlign: 'center'}}>카드 혜택, 포인트 혜택, 보험 등</RegularText_20>
          <RegularText_20>금융 콘텐츠 소식을 제일 먼저 받으려면 오픈 알림을 신청하세요.</RegularText_20>
          <FillButton text={"오픈 알림 받기"} style={{marginTop: '20px'}} onClick={onClickNotification} />
        </div>
      </>}
    />
  </>

}

export default FinanceListPage;