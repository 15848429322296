import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {FillButton, SecondaryFillButton} from "@components/Buttons";
import {useBlocker, useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {AppInstallGuideBanner} from "@components/AppInstallGuideBanner";
import {DialogDrawerTemplate} from "@components/DrawerTemplate";
import AppInstallGuideModal2 from "@components/AppInstallGuideModal2";
import NoticeBanner from "@src/features/job/component/NoticeBanner";
import {VSelector} from "@components/form/ResumeComponent";
import {StepTitleStyle} from "@src/features/pension_calculator/style/style";
import {Caution, Question} from "@components/Notice";
import trackEvent from "@util/trackEvent";
import {EVENT_FINANCE_NEXT_CLICK} from "@components/AppConstant";

const SelectorAccountType = [
  {
    key: "saving_account",
    label: "예금 상품"
  },
  {
    key: "installment_saving_account",
    label: "적금 상품(정기)"
  }
]

const Intro = () => {
  return <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%'}}>
    <p style={{whiteSpace: 'pre-wrap', fontSize: '1.25rem', lineHeight: 1.5, margin: 16, fontWeight: 700}}>{"예금 적금 상품?"}</p>
    <NoticeBanner text={"가장 안전한 금융 상품으로\n5060에게 맞는 상품만 있어요"} style={{margin: '0 16px'}}/>
    <p style={{whiteSpace: 'pre-wrap', fontSize: '1.25rem', lineHeight: 1.5, margin: 16, fontWeight: 700}}>{"위즈덤이 도와드릴게요!(무료)"}</p>
    <p style={{whiteSpace: 'pre-wrap', padding: '0 16px 16px', lineHeight: 1.5}}>
      {"하나. 시중 은행의 예금 적금 상품들을 다양하게 알려드릴게요. 이자율이 높은 특별판매(특판)상품도 놓치지 않아요!\n\n둘. 예금 적금에 가입할 금액으로 상품별 수익을 알려드릴게요.\n\n셋. 가입 방법부터 최대 이자율 충족 방법까지 쉽고 자세하게 알려드릴게요."}
    </p>
    <div style={{flex: 1}}/>
    <p style={{lineHeight: 1.5, margin: 16, fontSize: '0.75rem'}}>고객님이 입력하신 정보는 위즈덤이 수집하지 않으며, 다른 목적으로 사용되지 않음을 알려드립니다.</p>
  </div>
}


const Step1 = ({data, onChange}: { data: SavingAccount, onChange: (data: object) => void }) => {
  return <div style={{padding: 16}}>
    <p style={StepTitleStyle}>가입하고 싶은 상품의 종류를 알려주세요</p>
    <VSelector onChange={(option) => onChange({type: option})} selected={data.type} options={SelectorAccountType}/>
    <div style={{background: '#F4F4F4', borderRadius: 5, padding: '8px 8px 8px', marginTop: 40}}>
      <Caution title={"하나의 상품을 선택하신다면"}>
        <ul style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px 0'}}>
          <li><span>가입 가능한 예∙적금 상품만 알려드릴게요.</span></li>
          <li><span>시중 예적금 상품별 기대 수익을 알려드릴 수 있어요.</span></li>
        </ul>
      </Caution>
      <Question title={"자유적립적금 상품은 없나요?"}>
        <p style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px'}}>{"자유적립적금 상품의 경우 초기 목돈을 넣지 않는 경우 수익률이 일반 정기예금보다 높지 않아 추후에 안내해드리겠습니다."}</p>
      </Question>
    </div>
  </div>
}

const SavingInstallmentAccountPage = () => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null)
  const currentPath = useMemo(() => window.location.pathname, [null])
  const [path, setPath] = useState(["intro"])
  const [data, setData] = useState<SavingAccount>({
    type: "",
  })

  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    const last = path[path.length - 1]
    if (last === "intro") return false
    else if (["step1"].includes(last)) {
      if (historyAction === "PUSH") {
        return false
      } else {
        if (currentLocation.pathname === currentPath) {
          path.pop()
          setPath([...path])
          return true
        } else {
          return false
        }
      }
    } else {
      path.pop()
      setPath([...path])
      return true
    }
  });

  const onChange = (newData: object) => {
    setData({...data, ...newData})
  }

  const renderMiddle = () => {
    const current = path[path.length - 1]
    switch (current) {
      case "intro":
        return <Intro key={'intro'}/>
      case "step1":
        return <Step1 key={'step1'} data={data} onChange={onChange}/>
    }
  }

  const renderBottom = () => {
    switch (path[path.length - 1]) {
      case "intro":
        return <FillButton text={"시작하기"} onClick={onNext}/>
      case "step1":
        return <FillButton text={"다음"} onClick={onNext} active={data.type !== ""}/>
    }
    return <FillButton text={"시작하기"} onClick={onNext}/>
  }

  const onNext = () => {
    const current = path[path.length - 1]
    if (current === "intro") {
      trackEvent(EVENT_FINANCE_NEXT_CLICK, {type: "saving_installment_account", current_step: 'intro'})
      const newPath: string[] = [...path, "step1"]
      setPath(newPath)
    } else {
      if (data.type === "saving_account") {
        trackEvent(EVENT_FINANCE_NEXT_CLICK, {type: "saving_installment_account", current_step: current, next: "saving_account_products"})
        navigate("saving_account_products")
      } else {
        trackEvent(EVENT_FINANCE_NEXT_CLICK, {type: "saving_installment_account", current_step: current, next: "installment_saving_account_products"})
        navigate("installment_saving_account_products")
      }
    }
  }

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTo(0, 0)
    }
  }, [path]);

  return <>
    <DefaultPageRoot
      top={<>
        <BackNavToolbar title={"예금 적금 상품"}/>
      </>}
      middleRef={ref}
      middle={renderMiddle()}
      bottom={<div style={{margin: 16}}>
        {renderBottom()}
      </div>}
    />
  </>
}

export default SavingInstallmentAccountPage;