import React, {Children, ReactNode, useState} from "react";
import ExpandableListHeader from "@components/expandable/ExpandableSection";


export const ExpandableListSection = ({title, onClick, selected, sectionIndex=0, children}: { title: string, sectionIndex?: number, selected?: string, onClick?: (index: string) => void, children: any }) => {
  return <>
    <ExpandableListHeader title={title}/>
    <>{Children.map(children, (child, index) => {
      const i = sectionIndex.toString() + "-" + index.toString()
      return <div>
        {React.cloneElement(child, {onClick: () => onClick?.(i), expanded: i === selected})}
      </div>
    })}</>
  </>
}


const ExpandableList = ({children}: { children: any }) => {
  const [key, setKey] = useState<string>("")
  const onClick = (index: string) => {
    setKey(index === key ? "" : index)
  }
  return <>{Children.map(children, (child, index) => {
    return <div>
      {React.cloneElement(child, {onClick: onClick, selected: key, sectionIndex: index})}
    </div>
  })}</>
}

export default ExpandableList;