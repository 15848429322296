import { isForPartners } from "@src/util/isForPartners";
import { FillButton } from "./Buttons";

const IModal = ({ message, onCancel, onConfirm, confirmText = '확인', cancelText = '취소', onBackgroundClick = onCancel}: 
{
    message: string;
    confirmText?: string;
    cancelText?: string;
    onCancel: () => void;
    onConfirm: () => void;
    onBackgroundClick?: () => void;
}) => {
    const messages = message.split('\\n')
    return (
        <div
            style={{ 
                display: 'flex',
                position: 'absolute',
                height: '100vh',
                width: '100vw',
                background: '#000000B3',
                zIndex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: isForPartners ? 800 : 650
            }}
            onClick={onBackgroundClick}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                style={{ display: 'flex', flexDirection: 'column', width: '80%', background: 'white', borderRadius: '10px', padding: '16px 16px', justifyContent: 'center', alignItems: 'center' }}
            >
                <p style={{display: 'block', textAlign: 'center', fontWeight: 500, lineHeight: '1.5'}}>{messages.map((m, idx) => <span key={idx} style={{width: '100%'}}>{m}<br/></span>)}</p>
                <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                    <FillButton 
                        text={cancelText}
                        onClick={onCancel} 
                        style={{
                            background: '#f4f4f4',
                        }}
                        fontStyle={{
                            color: 'black',
                            fontSize: '1rem'
                        }}
                    />
                    <FillButton 
                        text={confirmText}
                        onClick={onConfirm}
                        fontStyle={{
                            fontSize: '1rem'
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default IModal;
