import ActivityPageTemplate from "@src/features/job/component/ActivityPageTemplate";
import {Activity} from "@model/Activity";
import {isActivityClosed} from "@util/activityHelper";
import {useNavigate} from "react-router-dom";
import trackEvent from "@util/trackEvent";
import {EVENT_APPLY_GUIDE_CLICK} from "@components/AppConstant";
import {FillButton} from "@components/Buttons";

const Cta = ({activity, regions}: {
  activity: Activity,
  regions: string[]
}) => {
  const closed = isActivityClosed(activity)
  const navigate = useNavigate();

  function handleApplyViaWizdom() {
    navigate(
      "apply_method", {
        state: {
          activity: activity
        }
      }
    )
    trackEvent(EVENT_APPLY_GUIDE_CLICK, {
      "activity_id": activity.id,
      "user_regions": regions,
      "previousPage": "ActivityDetailPage",
      "location": "bottom"
    });
  }

  return <FillButton
    active={!closed}
    text={closed ? "마감되었습니다" : "지원방법 확인하기"}
    style={{padding: '16px 8px'}}
    onClick={handleApplyViaWizdom}
  />
}

const CtaGuide = ({ historyId, children }: {
  historyId?: string,
  children: React.ReactNode
}) => {
  return <div style={{margin: '8px 16px 60px 16px', padding: 20, background: '#F4F4F4', borderRadius: 5}}>
    <p style={{fontWeight: 700, fontSize: '1.125rem', margin: '0 0 20px 0', lineHeight: 1.5, whiteSpace: 'pre-wrap'}}>{"이 공고에 지원하기 위한\n방법과 서류를 확인해보세요"}</p>
    {children}
  </div>
}

const DefaultActivityPage = ({previousPage}: { previousPage: string }) => (
  <ActivityPageTemplate
    previousPage={previousPage}
    Cta={Cta}
    CtaGuide={CtaGuide}
  />
)

export default DefaultActivityPage;