import { greyF4, secondaryBlack } from "@src/components/Color";
import { DefaultPageRoot } from "@src/components/PageTemplate";
import { BackNavToolbar } from "@src/components/Toolbars";
import { BoldText_20, BoldText_25, MediumText_20, RegularText_17 } from "@src/components/text";
import { MemberLevels } from "../model/MemberLevel";
import MemberLevelTag from "./component/MemberLevelTag";
import useOnLog from "@src/components/useOnLog";
import { EVENT_COMMUNITY_MEMBER_GUIDE_PAGE_SHOW } from "@src/components/AppConstant";

const CommunityMemberLevelGuide = () => {

    useOnLog({
        pageNameKey: EVENT_COMMUNITY_MEMBER_GUIDE_PAGE_SHOW,
        regStr: "^\/community/profile/member-guide$"
    })

    return <DefaultPageRoot
        top={<BackNavToolbar />}
        middle={
            <div>
                <div style={{padding: '20px 15px 30px 15px'}}>
                    <BoldText_25>지혜도 얻는 방법</BoldText_25>
                    <table>
                        <tbody style={{display: 'flex', flexDirection: 'column'}}>
                            <tr style={{marginTop: '20px'}}>
                                <td style={{width: '15px'}}>∙</td>
                                <td><BoldText_20 style={{marginTop: '30px'}}>좋은 글을 많이 써 보세요!</BoldText_20></td>
                            </tr>
                            <tr>
                                <td style={{width: '15px'}}></td>
                                <td><RegularText_17 style={{lineHeight: '1.5', color: secondaryBlack}}>게시글, 댓글을 포함한 모든 글쓰기 활동이 점수에 반영됩니다. 내 글에 좋아요가 많다면 더 많은 점수를 얻습니다.</RegularText_17></td>
                            </tr>
                            <tr style={{marginTop: '25px'}}>
                                <td style={{width: '15px'}}>∙</td>
                                <td><BoldText_20 style={{marginTop: '30px'}}>'좋아요'를 많이 표현하세요!</BoldText_20></td>
                            </tr>
                            <tr>
                                <td style={{width: '15px'}}></td>
                                <td><RegularText_17 style={{lineHeight: '1.5', color: secondaryBlack}}>다른 회원의 게시글과 댓글에 ‘좋아요’를 많이 할수록 많은 점수를 얻습니다.</RegularText_17></td>
                            </tr>
                        </tbody>
                        </table>
                </div>
                <div style={{backgroundColor: greyF4, borderTop: '1px solid #D7D8DA', height: '16px'}}/>
                <div style={{padding: '40px 15px 30px', marginBottom: '30px'}}>
                    <BoldText_25>회원 제도</BoldText_25>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '35px'}}>
                        {MemberLevels.filter(e => e.name !== "운영자").map((level, idx) => {
                            return (
                                <div key={level.name} style={{display: 'flex', gap: '15px', alignItems: 'center'}}>
                                    <MemberLevelTag size="60px" fontSize="30px" level={level.value} borderRadius="5px"/>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: '6px'}}>
                                        <MediumText_20>{level.name}</MediumText_20>
                                        <RegularText_17 style={{color: secondaryBlack}}>{'지혜도 ' + level.range[0] + '-' + level.range[1]}</RegularText_17>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        }
    />
}

export default CommunityMemberLevelGuide;