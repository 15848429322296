import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {FillButton, IconFillButton} from "@components/Buttons";
import React from "react";
import {useNavigate} from "react-router-dom";
import trackEvent from "@util/trackEvent";
import {EVENT_CREATE_RESUME_COPY_SELECT, EVENT_CREATE_RESUME_NEW_CREATE_SELECTS} from "@components/AppConstant";

const RecommendIcon = () => <span style={{color: '#0ABF53', background: 'white', padding: '4px 8px', borderRadius: 5, fontSize: '0.9rem'}}>추천</span>

const SelectAddResumeOption = () => {
  const navigate = useNavigate();

  const copyResume = () => {
    trackEvent(EVENT_CREATE_RESUME_COPY_SELECT, {})
    navigate("copy")
  }

  const createResume = () => {
    trackEvent(EVENT_CREATE_RESUME_NEW_CREATE_SELECTS, {})
    navigate("../create_resume_basic_info", {state: {step: 1}})
  }

  return <DefaultPageRoot
    top={<BackNavToolbar title={"이력서 추가 작성"}/>}
    middle={<div style={{padding: '0 16px'}}>
      <p style={{margin: 0, lineHeight: 1.5}}>TIP</p>
      <p style={{margin: 0, lineHeight: 1.5}}>기존에 작성하신 이력서를 복사하여 수정할 부분만 수정 후 저장하는 것을 추천드립니다.</p>
      <IconFillButton text={"기존 이력서 복사하여 작성"} icon={<RecommendIcon />} style={{margin: '10px 0'}} onClick={copyResume} />
      <FillButton text={"처음부터 새롭게 작성"} style={{background: '#474D5B'}} onClick={createResume} />
    </div>}
  />
}

export default SelectAddResumeOption;