// n분 전 표기 체계
// 0~1분 = 지금
// 1~60분 = n분 전
// 61분~24시간 = n시간 전
// 24시간~ = n일 전
// (0시 date 기준으로 n일전 이 아님. 23일 오후 11시에 글 올리고, 24일 새벽 1시 되면, 2시간 전인 거임)
export const convertToKSTString = (stringDatetime: string) => {
    stringDatetime = stringDatetime.replace('.000000', '');
    const gmtDate = new Date(stringDatetime);
    const date = new Date(gmtDate.getTime() + 9 * 60 * 60 * 1000);
    return date.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });
}
export const convertToHangul = (stringDatetime: string) => {
    stringDatetime = stringDatetime.replace('.000000', '');
    const gmtDate = new Date(stringDatetime);
    const date = new Date(gmtDate.getTime() + 9 * 60 * 60 * 1000);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const diffMin = Math.floor(diff / 60000);
    const diffHour = Math.floor(diffMin / 60);
    const diffDay = Math.floor(diffHour / 24);
    if (diffMin < 1) {
        return "지금";
    } else if (diffMin < 60) {
        return `${diffMin}분 전`;
    } else if (diffHour < 24) {
        return `${diffHour}시간 전`;
    } else {
        return `${diffDay}일 전`;
    }
}
