import server_domain from "@components/RemoteServer"
import convertActivities from "@util/convertActivities"
import { fetchWithRetry } from "@util/networks"
import * as amplitude from '@amplitude/analytics-browser';
import { EVENT_KEYWORD_SEARCH } from "@src/components/AppConstant";
import { WorkingTimeType } from "@src/model/WorkingTimeType";

interface Props {
    keyword: string,
    tag?: string,
    workingTimeType?: WorkingTimeType,
    regions?: string[],
    categoryIds?: number[],
    simpleApply?: boolean,
}

const searchPageLoader = async ({
    keyword, workingTimeType = undefined, regions = undefined, categoryIds = [0], simpleApply = false
}: Props) => {
    categoryIds = categoryIds.length === 0 ? [0] : categoryIds

    let query: Record<string, any> = {}
    query['keyword'] = keyword
    query['category_type'] = JSON.stringify(categoryIds)
    query['simple_apply'] = simpleApply
    if (workingTimeType) {
        query['working_time_type'] = workingTimeType
    }
    if (regions) {
        query['regions'] = JSON.stringify(regions)
    }
    const params = new URLSearchParams(query)
    let response = await fetchWithRetry(server_domain() + '/search?' + params.toString(), {
        headers: {
            "Content-Type": "application/json",
        },
    })

    let data = await response.json();
    amplitude.track(EVENT_KEYWORD_SEARCH, {
        matched: data.matched.length,
        near_regions: data.near_regions.length,
        other_regions: data.other_regions.length,
        keyword: keyword,
        user_regions: regions,
    });
    return {
        matched: convertActivities(data.matched),
        near_regions: convertActivities(data.near_regions),
        other_regions: convertActivities(data.other_regions),
    }
}

export default searchPageLoader;
