import IcCheckboxOn from "@assets/ic_checkbox_on.svg";
import IcCheckboxOff from "@assets/ic_checkbox_off.svg";
import React from "react";

interface CheckboxProps {
  label: string;
  checked: boolean;
  onClick: (label: string) => void;
}

export const Checkbox = ({label, checked, onClick}: CheckboxProps) => {
  return <div onClick={() => onClick(label)} style={{display: 'flex', alignItems: 'center', margin: '20px 0'}}>
    {checked ? <IcCheckboxOn/> : <IcCheckboxOff/>}
    <span style={{marginLeft: 20}}>{label}</span>
  </div>
}