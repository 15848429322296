// import { captureMessage } from "@sentry/react";
const emailChecker = (email: string) => {
    const regex = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (regex.test(email)) {
        const mailName = email.split("@")[1]
        if (mailName) {
            const onlyMailName = mailName.split('.')[0]
            if (onlyMailName) {
                const correctOnlyMailNameExample = ["naver", "hanmail", "gmail", "daum", "nate", "hotmail", "kakao"]
                const correctMailNameExample = ["naver.com", "hanmail.net", "gmail.com", "daum.net", "nate.com", "hotmail.com", "kakao.com"]

                const index = correctOnlyMailNameExample.indexOf(onlyMailName)
                if (index != -1) {
                    if (mailName != correctMailNameExample[index]) {
                        return false
                    }
                }

                const incorrectMailExample = ["naver.co.kr", "nave.com", "naver.net", "naver.con", "hanmail.ney", "nsver.com", "gmaol.com", "never.com", "naver.cim", "naver.cpm", "naver.co"]
                if (incorrectMailExample.includes(mailName)) {
                    return false
                } else {
                    return true
                }    
            } else {
                return false
            }
            
        } else {
            return false
        }
    } else {
        return false
    }
}

export default emailChecker;