import { createSlice } from "@reduxjs/toolkit";

const communitySlice = createSlice({
    name: 'community',
    initialState: {
        searchPostsKeywords: [] as string[],
        writePostMoreThanOnce: false,
        allTimeFavoritePostIds: [],
    },
    reducers: {
        setWritePostMoreThanOnce: (state, action) => {
            state.writePostMoreThanOnce = action.payload
        },
        setAllTimeFavoritePostIds: (state, action) => {
            state.allTimeFavoritePostIds = action.payload
        },
        addSearchPostsKeyword: (state, action) => {
            if (!state.searchPostsKeywords) {
                state.searchPostsKeywords = []
            }
            if (state.searchPostsKeywords.includes(action.payload)) {
                state.searchPostsKeywords = state.searchPostsKeywords.filter(e => e !== action.payload)
            }
            state.searchPostsKeywords.push(action.payload)
        },
        removeSearchPostsKeyword: (state, action) => {
            state.searchPostsKeywords = state.searchPostsKeywords.filter(e => e !== action.payload)
        },
        clearSearchPostsKeywords: (state) => {
            state.searchPostsKeywords = []
        }
    }
})

export const {
    setWritePostMoreThanOnce,
    setAllTimeFavoritePostIds,
    addSearchPostsKeyword,
    removeSearchPostsKeyword,
    clearSearchPostsKeywords
} = communitySlice.actions
export default communitySlice.reducer
