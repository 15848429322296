import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import Table from "@components/Table";
import {useParams} from "react-router-dom";
import {FinanceProductList} from "@src/features/finance/features/saving_installment_account/model/FinanceProductList";
import {NumInput} from "@components/input/Inputs";
import React, {useRef, useState} from "react";
import {FillButton} from "@components/Buttons";
import PrincipalInterest from "@src/features/finance/features/saving_installment_account/model/PrincipalInterest";
import {DividerThin} from "@components/DivideBar";
import {OdoMeter} from "@components/odometer/OdoMeter";
import {Caution, Notice} from "@components/Notice";
import {calculatePrincipalInterest} from "@src/features/finance/features/saving_installment_account/util/PrincipalInterestCalculator";
import {InterestType} from "@src/features/finance/features/saving_installment_account/model/InterestType";
import trackEvent from "@util/trackEvent";
import {EVENT_FINANCE_INTEREST_CALC_CLICK} from "@components/AppConstant";

const Description = {
  "saving_account": "예치금액과 기간을 입력하면 본 상품의 원금과 이자가 계산됩니다",
  "installment_saving_account": "월납입액과 적립기간을 입력하면 본 상품의 원금과 이자가 계산됩니다"
}

const AmountLabel = {
  "saving_account": "예치금액",
  "installment_saving_account": "월납입액"
}

const PeriodLabel = {
  "saving_account": "예치기간",
  "installment_saving_account": "적립기간"
}

const AmountPlaceholder = {
  "saving_account": "500",
  "installment_saving_account": "50"
}

const PeriodPlaceholder = {
  "saving_account": "12",
  "installment_saving_account": "12"
}

const formatMoney = (value: number) => value.toLocaleString("ko-KR")

const SavingInstallmentAccountProductSimulationPage = () => {
  const {productId} = useParams();
  const infoRef = useRef<HTMLDivElement>(null);
  const resultRef = useRef<HTMLDivElement>(null);
  const [amount, setAmount] = useState("");
  const [period, setPeriod] = useState("");
  const [result, setResult] = useState<PrincipalInterest | null>(null);
  const product = FinanceProductList.find((product) => product.id === parseInt(productId ?? ""));

  // TODO
  if (product === undefined) {
    return null
  }

  const calc = () => {
    trackEvent(EVENT_FINANCE_INTEREST_CALC_CLICK, {months: period, amount: amount})
    const calculated = calculatePrincipalInterest(product, parseInt(amount) * 10000, parseInt(period));
    setResult(calculated)
    setTimeout(() => {resultRef.current?.scrollIntoView({behavior: 'smooth'})}, 200)
  }

  const onAmountChange = (value: string) => {
    setResult(null)
    setAmount(value)
  }

  const onPeriodChange = (value: string) => {
    setResult(null)
    setPeriod(value)
  }

  const onFocus = () => {
    setTimeout(() => {infoRef.current?.scrollIntoView({behavior: 'smooth'})}, 400)
  }

  const rate = InterestType[product.interestType] + " " + product.basicRate + "%" + (product.maxRate ? ` (최대 ${product.maxRate}%)` : "");
  return <DefaultPageRoot
    top={<BackNavToolbar title={"상세정보"}/>}
    middle={<>
      <div style={{padding: '0 16px'}}>
        <p style={{background: '#F4F4F4', borderRadius: 5, padding: 16, lineHeight: 1.5}}>{Description[product.type]}</p>
        <div ref={infoRef}/>
        <Table
          title={"상품 정보"}
          data={[
            {
              'key': '상품명',
              'value': product.organization + " " + product.title
            },
            {
              'key': '연이자율',
              'value': rate
            }
          ]}
        />

        <NumInput label={AmountLabel[product.type]} unit={"만원"} value={amount} onChange={onAmountChange} placeholder={AmountPlaceholder[product.type]} onFocus={onFocus}/>
        <NumInput label={PeriodLabel[product.type]} unit={"개월"} value={period} onChange={onPeriodChange} placeholder={PeriodPlaceholder[product.type]} onFocus={onFocus}/>
        <FillButton text={"원리금 계산하기"} onClick={calc}/>

        {
          result &&
            <div style={{margin: '30px 0'}} ref={resultRef}>
                <p style={{fontSize: '1.75rem', fontWeight: 700, margin: 0}}>예상수익</p>
                <div style={{display: 'flex', alignItems: 'center', fontSize: '1.25rem', fontWeight: 700, margin: '20px 0', textAlign: 'center', justifyContent: 'center'}}>
                    <OdoMeter value={Math.floor((result?.basicInterestAfterTax || 0))} duration={1000} size="1.5rem" color="black"/>
                    <span>원(기본)</span>
                </div>
                <p style={{margin: 0, textAlign: 'center'}}>{formatMoney(Math.floor((result?.maxInterestAfterTax || 0)))}원(최대)</p>
            </div>
        }


      </div>

      {
        result &&
          <>
              <DividerThin/>
              <div style={{padding: 16}}>
                  <Table
                      title={"수익 계산 내역"}
                      subTitle={"(괄호 안은 최대 이자율 적용시)"}
                      valueStyle={{textAlign: 'right'}}
                      data={[
                        {
                          key: "원금합계",
                          value: formatMoney(result?.principalTotal || 0) + "원"
                        },
                        {
                          key: "세전이자",
                          value: formatMoney(result?.basicInterest || 0) + "원" + `\n(${formatMoney(result?.maxInterest || 0)}원)`
                        },
                        {
                          key: "이자과세",
                          value: "- " + formatMoney(result?.basicTax || 0) + "원" + `\n(- ${formatMoney(result?.maxTax || 0)}원)`

                        },
                        {
                          key: "세후수령액",
                          value: formatMoney(result?.basicAfterTax || 0) + "원" + `\n(${formatMoney(result?.maxAfterTax || 0)}원)`

                        },
                        {
                          key: "수익률",
                          value: (result?.basicRate || 0) + "%" + `\n(${(result?.maxRate || 0)}%)`
                        }
                      ]}
                  />
              </div>

              <div style={{background: '#EEF9F2', borderRadius: 5, padding: 8, margin: '0 16px 30px'}}>
                  <Caution title={"이렇게 해석하시면 돼요!"}>
                      <ol style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px 0px'}}>
                          <li><span>이자과세는 세전이자의 15.4%(국세 14% + 지방세 1.4%)를 원천징수합니다.</span></li>
                          <li><span>금리는 고정금리로 계산하였습니다.</span></li>
                          <li><span>기간내 수익률을 물가상승률과 비교하여 보세요.</span></li>
                      </ol>
                  </Caution>
                  <Notice title={"기능 개선 중입니다! 기다려주세요!"}>
                      <ul style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 0 0px'}}>
                          <li><span>비과세, 세금우대 상품의 수익 계산을 할 수 있어요.</span></li>
                          <li><span>중도 해지 수수료를 계산할 수 있어요.</span></li>
                          <li><span>변동금리 상품의 수익도 계산할 수 있어요.</span></li>
                          <li><span>상품별 수익 비교를 할 수 있어요.</span></li>
                      </ul>
                  </Notice>
              </div>
          </>
      }


    </>}
  />
}
export default SavingInstallmentAccountProductSimulationPage;