import { Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hide } from "@reducer/snackbarSlice";
import useOnUpdateLastUserAction from "@components/useOnUpdateLastUserAction";
import { CUSTOM_EVENT_SHOWN_UPDATE_DIALOG, EVENT_UPDATE_DIALOG_SHOW, CUSTOM_EVENT_UPDATE_INSTALL_REFERRER } from "@components/AppConstant";
import * as amplitude from "@amplitude/analytics-browser"
import { identify } from '@amplitude/analytics-browser';
import { updateInstallReferrer } from "@reducer/userSlice";
import server_domain from "@components/RemoteServer";
import { fetchWithRetry } from '@util/networks';
import { RootState } from '@reducer/store';
import { fontSize } from '@src/components/font';

const Snackbar = () => {
    const snackbarInfo = useSelector((state: RootState) => state.snackbarInfo);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (snackbarInfo.show) {
            setShow(true)
            dispatch(hide())
            setTimeout(() => {
                setShow(false)
            }, 5000)
        }
    }, [snackbarInfo])

    if (!show) return null;
    return (
        <div style={{position: 'fixed', left: '50%', bottom: 70, zIndex: 100, transform: 'translate(-50%, 0)', borderRadius: 5, textAlign: 'center', background: 'black', boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", maxWidth: '600px', width: '90%'}}>
            <div style={{margin: 10}}>
                {snackbarInfo.message.split('\n').map((line, index) => {
                    return <div key={index}>
                        <span style={{fontSize: fontSize.s, lineHeight: 1.5, color: 'white', width: '100%', boxSizing: 'border-box'}}>{line}</span>
                    </div>
                })}
            </div>
        </div>
    )
}

const MainView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const installReferrer = useSelector((state: RootState) => state.user.installReferrer)
    const email = useSelector((state: RootState) => state.user.email)
    const [showEmailAlert, setShowEmailAlert] = useState(false)

    useOnUpdateLastUserAction()

    function logUpdateDialogShow() {
        amplitude.track(EVENT_UPDATE_DIALOG_SHOW);
    }

    async function setUserProperties(referrer: string) {
        let utms = Object.fromEntries(new URLSearchParams(referrer))
        if (utms.utm_source && utms.utm_source === "apps.facebook.com") {
            let res = await fetchWithRetry(server_domain() + "/decrypt_fb_install_referrer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({"data": referrer})
            });
            if (res.ok) {
                let data = await res.json();
                utms.utm_content = data.decrypted_key;
            }
        } 
        const identifyEvent = new amplitude.Identify();
        Object.entries(utms).forEach(([k, v]) => {
            identifyEvent.set(k, v);
        })
        identify(identifyEvent);
        dispatch(updateInstallReferrer(referrer))
    }

    const onUpdateInstallReferrer = (event: any) => {
        // referrer 포멧 변환후 분기처리 필요
        if (event.detail != null && JSON.stringify(event.detail) != JSON.stringify({})) {
            setUserProperties(event.detail)
        }
    }

    useEffect(() => {
        if (email === "sanggyui968@gmail.com") {
            fetchWithRetry(server_domain() + "/collect_something", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({"msg": "show dialog sanggyui968@gmail.com"})
            }).then(res => {});
            setShowEmailAlert(true);
        }

        if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            window.addEventListener(CUSTOM_EVENT_SHOWN_UPDATE_DIALOG, logUpdateDialogShow);
            if (installReferrer == null || installReferrer == undefined) {
                window.addEventListener(CUSTOM_EVENT_UPDATE_INSTALL_REFERRER, onUpdateInstallReferrer, false);
            }
            window.flutter_inappwebview.callHandler('checkUpdate', 20);
        } else {
        }
        return () => {
            window.removeEventListener(CUSTOM_EVENT_UPDATE_INSTALL_REFERRER, onUpdateInstallReferrer);
        };
    }, [])

    const goBack = (canGoBack: boolean, url: string) => {
        const uri = new URL(url);
        const backTo = uri.searchParams.get('back_to') || '';
        const query = uri.searchParams;
        const filteredQuery = new URLSearchParams();
        query.forEach((value, key) => {
            if (key !== 'back_to') {
                filteredQuery.append(key, value);
            }
        });
      
        if (backTo !== '') {
            if (backTo.startsWith('/')) {
                navigate(url, {replace: true})
                navigate(backTo + '?' + filteredQuery.toString(), { replace: true })
            } else {
                navigate('/' + backTo + '?' + filteredQuery.toString(), { replace: true })
            }
            return false;
        }
      
        if (canGoBack) {
            history.back();
            return false;
        }
      
        const finishAtRoot = uri.searchParams.get('finish_at_root') === "true" ? true : false;
        const query2 = uri.searchParams;
        const filteredQuery2 = new URLSearchParams();
        query2.forEach((value, key) => {
            if (key !== 'finish_at_root') {
                filteredQuery.append(key, value);
            }
        });
        if (finishAtRoot) {
            navigate('/'+ '?' + filteredQuery2.toString(), { replace: true })
            return false;
        }
      
        const lastBackClick = sessionStorage.getItem('lastBackClicked') ?? "0";
        const now = Date.now();
        if (now - parseInt(lastBackClick) < 2000) {
            return true;
        } else {
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler('showToast', '뒤로 버튼을 한번 더 누르시면 종료됩니다.');
            }
            sessionStorage.setItem('lastBackClicked', Date.now().toString());
            return false
        }
    }      

    const changePage = (e: any) => {
        let path = (e as { detail: { path: string } }).detail.path
        if (!path.startsWith('/')) {
          path = '/' + path
        }
        const urlComponents = path.split('?')
        const pathname = urlComponents[0]
        
        const queryParams = urlComponents.length > 1 ? urlComponents[1] : ''
        const searchParams = new URLSearchParams(queryParams)

        if (location.pathname == searchParams.get('back_to')) {
            searchParams.delete('back_to')
        }
        
        navigate(pathname + '?' + searchParams.toString())
    }
    
    useEffect(() => {
        window.addEventListener('changePage', changePage)
        return () => {
            window.removeEventListener('changePage', changePage)
        }
    }, [location.pathname])

    useEffect(() => {
        window.AndroidInterface = {
            goBack: goBack
        }
    }, [navigate])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
        }}>
            {
                showEmailAlert &&
                <div style={{position: 'absolute', zIndex: '100', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', background: "#000000B3"}} onClick={() => {setShowEmailAlert(false)}}>
                    <div style={{background: 'white', margin: "0 5%", height: '70%', display: 'flex', flexDirection: 'column'}} onClick={(e) => {e.stopPropagation()}}>
                        <div style={{overflow: 'auto', padding: '0 8px'}}>
                            <p style={{fontWeight: 500, fontSize: '1.25rem'}}>[이메일 주소 기입 오류 안내]</p>
                            <p>
                                <span style={{display: "block"}}>안녕하세요 고객님</span>
                                <span style={{display: "block"}}>위즈덤 서비스를 사용해주셔서 감사합니다!</span>
                            </p>                        
                            <p>
                                <span style={{display: "block"}}>공고문을 살펴보시고 이메일 보내기를 계속하셨으나 입력하신 이메일 주소 sanggyui968@gmail.com 이 '존재하지 않는 메일주소'로 이메일 발송이 약 10회 실패하였습니다. 이에 고객님을 위해 안내 메시지를 보내드립니다.</span>
                            </p>                        
                            <p>
                                <span style={{display: "block"}}>이메일 주소를 다시 한 번 점검해주시기 바라며, 도움이 필요하실 경우 위즈덤 고객센터 1660-2958 로 연락 주시기 바랍니다.</span>
                            </p>                        
                            <p>
                                <span style={{display: "block"}}>감사합니다.</span>
                                <span style={{display: "block"}}>위즈덤 드림</span>
                            </p>     
                        </div>
                        <div style={{textAlign: 'center', padding: '20px 0', background: '#0ABF53', color: 'white'}} onClick={() => {setShowEmailAlert(false)}}>
                            <span>닫기</span>
                        </div>
                    </div>
                </div>
            }

            <div style={{ flex: 1, overflowY: 'auto' }}>
                <Suspense fallback={<div style={{position: 'absolute', background: "#00000088", width: '100vw', height: '100vh'}}></div>}>
                    <Outlet />
                </Suspense>
            </div>
            <Snackbar />
        </div>
    )
}

export default MainView;