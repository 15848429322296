import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DefaultPageRoot } from "@components/PageTemplate";
import { BackNavToolbar } from "@components/Toolbars";
import "@src/features/job/features/news/style/job_news_detail_view.css";
import { useEffect, useState } from "react";
import { FillButton } from "@components/Buttons";
import server_domain from "@components/RemoteServer";
import * as amplitude from '@amplitude/analytics-browser';
import { EVENT_JOB_NEWS_DETAIL_PAGE_SHOW, EVENT_SHARE_VIEW_CLICK } from "@components/AppConstant";
import { AppInstallGuideBanner } from "@components/AppInstallGuideBanner";
import useOnLog from "@components/useOnLog";
import { fetchWithRetry } from "@util/networks";
import {GuideInstallAppModal} from "@view/GuideInstallAppModal";

const formatDateToKorean = (date: string | number | Date) => {
    const timestamp = new Date(new Date(date).getTime() + 9 * 60 * 60 * 1000);
    const year = timestamp.getFullYear();
    const month = timestamp.getMonth() + 1;
    const day = timestamp.getDate();
    const hour = timestamp.getHours();
    const minute = timestamp.getMinutes();
    const seconds = timestamp.getSeconds();
    const ampm = hour >= 12 ? '오후' : '오전';
    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day} ${ampm} ${hour > 12 ? hour - 12 : hour}:${minute < 10 ? "0" + minute : minute}:${seconds < 10 ? "0" + seconds : seconds}`
}


const JobNewsDetailView = () => {
    const { job_news_id } = useParams();
    const [jobNews, setJobNews] = useState<any>(null);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const showInstallGuide = (searchParams.get("show_install_guide") === "true")
    const [showGuide, setShowGuide] = useState(showInstallGuide);

    useOnLog({
        pageNameKey: EVENT_JOB_NEWS_DETAIL_PAGE_SHOW,
        amplitudeProp: {
            job_news_id: job_news_id
        },
        regStr: "^(.*?)\/job_news\/[^/]+$"
    })

    function share() {
        amplitude.track(EVENT_SHARE_VIEW_CLICK, {
            location: 'JobNewsDetailPage'
        })
        navigate({
            pathname: "share",
        }, {
            state: {
                amplitude_props: {
                    location: 'JobNewsDetailPage',
                },
                title: "공유하기",
                kakao_share_title: "카카오톡으로 공유하기",
                link_copy_title: "링크 복사하기",
                url: `[위즈덤]\n\n${jobNews.title}\n\n${import.meta.env.VITE_URL}/job/job_news/${job_news_id}?back_to_home=true&show_install_banner=true&show_install_guide=true&referrer=utm_source%3Dappshare%26utm_campaign%3Dnewsdetailpage%26utm_content%3D5060news`,
                toastText: "링크가 복사되었습니다.",
                templateId: 104702,
                templateArgs: {
                    'title': jobNews.title,
                    'description': "위즈덤에서 확인해보세요",
                    'thumbnail_url': jobNews.thumbnail_url,
                    "url": import.meta.env.VITE_URL,
                    "url_path": `job/job_news/${job_news_id}?back_to_home=true&show_install_banner=true&show_install_guide=true&referrer=utm_source%3Dappshare%26utm_campaign%3Dnewsdetailpage%26utm_content%3D5060news`
                }
            }
        })
    }

    useEffect(() => {
        fetchWithRetry(server_domain() + '/job_news?id=' + job_news_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                setJobNews(data)
            })
    }, [])

    if (!jobNews) {
        return null
    }

    return (
        <DefaultPageRoot
            top={<>
                <AppInstallGuideBanner />
                <BackNavToolbar title="5060 취업 뉴스" />
                <GuideInstallAppModal show={showGuide} close={() => { setShowGuide(false) }} />
            </>}
            middle={<>
                <div style={{ padding: '16px 16px 0px' }}>
                    <p style={{ margin: 0, lineHeight: 1.5, fontSize: '1.25rem', fontWeight: 500 }}>{jobNews.title}</p>
                    <div style={{ display: 'flex', padding: '5px 0 24px', alignItems: 'center', borderBottom: '1px solid #F4F4F4' }}>
                        <span style={{ margin: 0, flex: 1, color: '#999', fontSize: '0.875rem' }}>{formatDateToKorean(jobNews.created_at)}</span>
                        <FillButton
                            text="공유"
                            style={{ width: 'fit-content', padding: '5px 15px', borderRadius: 25 }}
                            onClick={share}
                            fontStyle={{ fontSize: '0.875rem' }}
                        />
                    </div>
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: jobNews.content }}></div>
                <div style={{ borderTop: '1px solid #F4F4F4', margin: 16 }}>
                    <p style={{ fontWeight: 500, margin: '36px 0 0', fontSize: '1.125rem', textAlign: 'center' }}>이 기사를 유익하게 보셨다면?</p>
                    <FillButton
                        text="기사 공유하기"
                        style={{ width: '100%', margin: '8px 0 70px ' }}
                        onClick={share}
                    />
                </div>
            </>}
        />
    );
}

export default JobNewsDetailView;