import ActivityPageTemplate from "@src/features/job/component/ActivityPageTemplate";
import {Activity} from "@model/Activity";
import {isActivityClosed} from "@util/activityHelper";
import {useNavigate} from "react-router-dom";
import trackEvent from "@util/trackEvent";
import {EVENT_APPLY_GUIDE_CLICK, EVENT_APPLY_HISTORY_CLICK, EVENT_GO_TO_SIMPLE_APPLY_CLICK} from "@components/AppConstant";
import {FillButton} from "@components/Buttons";

const Cta = ({ activity, regions, historyId }: {
  activity: Activity,
  historyId: string,
  regions: string[]
}) => {
  const navigate = useNavigate();

  const goToApplyHistoryDetail = () => {
    trackEvent(EVENT_APPLY_HISTORY_CLICK, {location: 'SimpleApplyActivityPage'})
    navigate("simple_apply_activity_history/" + historyId)
  }

  function handleApplyViaWizdom() {
    trackEvent(EVENT_GO_TO_SIMPLE_APPLY_CLICK, {
      "activity_id": activity.id,
    })
    navigate("simple_apply", {state: {activity: activity}})
  }

  if (historyId) {
    return <FillButton
      active={true}
      text={"지원현황 확인하기"}
      style={{padding: '16px 8px'}}
      onClick={goToApplyHistoryDetail}
    />
  } else {
    const closed = isActivityClosed(activity)
    return <FillButton
      active={!closed}
      text={closed ? "마감되었습니다" : "간편 지원하기"}
      style={{padding: '16px 8px'}}
      onClick={handleApplyViaWizdom}
    />
  }
}

const CtaGuide = ({ historyId, children }: {
  historyId: string,
  children: React.ReactNode
}) => {
  return <div style={{margin: '8px 16px 60px 16px', padding: 20, background: '#F4F4F4', borderRadius: 5}}>
    <p style={{fontWeight: 700, fontSize: '1.125rem', margin: '0 0 20px 0', lineHeight: 1.5, whiteSpace: 'pre-wrap'}}>{ historyId ? "지원현황을 확인하고\n합격 축하금에 응모하세요" : "위즈덤 이력서를 작성하고\n빠르게 지원해보세요" }</p>
    {children}
  </div>
}

const SimpleApplyActivityPage = ({previousPage}: { previousPage: string }) => (
  <ActivityPageTemplate
    previousPage={previousPage}
    Cta={Cta}
    CtaGuide={CtaGuide}
  />
)

export default SimpleApplyActivityPage;