import { RefObject, useEffect, useMemo, useRef, useState } from "react"
import * as amplitude from '@amplitude/analytics-browser';
import { EVENT_ACTIVITY_CARD_IMPRESSED } from "./AppConstant";
import { useSelector } from "react-redux";
import { RootState } from "@reducer/store";
import { Activity } from "@src/model/Activity";
import trackEvent from "@src/util/trackEvent";
import { useOnMountOnce } from "@src/util/useOnMountOnce";

export default function useOnScreen(ref: RefObject<any>, activity: Activity, location: any, sessionId?: string) {

    const regions = useSelector((state: RootState) => state.user.regions);
    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                const prop = {
                    "user_regions": regions,
                    "title": activity.activityTitle,
                    "activity_id": activity.id,
                    "location": location,
                    "sessionId": sessionId
                }
                setIntersecting(entry.isIntersecting)
                amplitude.track(EVENT_ACTIVITY_CARD_IMPRESSED, prop)
                observer.unobserve(entry.target);
            }
        }, {
        threshold: 1
    }), [ref])


    useEffect(() => {
        observer.observe(ref.current)
        return () => observer.disconnect()
    }, [])

    return isIntersecting
}

export const ImpressionTracker = ({children, eventName, props, onImpressed}:{children: any, eventName?: string, props?: any, onImpressed?: () => void}) => {
    const ref = useRef(null)
    const [isIntersecting, setIntersecting] = useState(false)
    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                setIntersecting(entry.isIntersecting)
                if (eventName) {
                    trackEvent(eventName, props)
                }
                observer.unobserve(entry.target);
                onImpressed?.()
            }
        }, {
        threshold: 1
    }), [ref])

    useOnMountOnce(() => {
        if (ref.current) {
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [ref.current])
    
    return <div ref={ref}>{children}</div>
}
