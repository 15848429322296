import {BottomDrawerTemplate, DialogDrawerTemplate} from "@components/DrawerTemplate";
import {useNavigate, useParams} from "react-router-dom";
import {BorderButton, FillButton} from "@components/Buttons";
import {useDispatch} from "react-redux";
import {removeCareer, removeCertificate} from "@reducer/userSlice";

const RemoveCertificate = () => {
  const {certificateId} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _removeCertificate = () => {
    if (certificateId) {
      dispatch(removeCertificate(certificateId))
    }
    navigate(-1)
  }

  return <DialogDrawerTemplate show={true} onClose={() => navigate(-1)} title={"자격증 삭제"} closeButton={false}>
    <p>해당 자격증 정보를 삭제할까요?</p>
    <div style={{display: 'flex', gap: 10}}>
      <FillButton style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}} text={"취소"} onClick={() => navigate(-1)} />
      <FillButton style={{background: '#F15D47'}} text={"삭제"} onClick={_removeCertificate} />
    </div>
  </DialogDrawerTemplate>
}

export default RemoveCertificate;