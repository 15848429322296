import { primary } from "@src/components/Color";
import { MemberLevelValue } from "../../model/MemberLevel";
import icLastLevel from "@assets/ic_last_level.webp";

const MemberLevelTag = (
    { level, fontSize, size, borderRadius, style }: { level: MemberLevelValue, fontSize: string, size: string, borderRadius: string, style?: React.CSSProperties}
) => {
    let backroundColor = undefined;
    switch (level) {
        case 0:
            backroundColor = '#C1C3C8';
            break;
        case 1:
            backroundColor = '#F1A13B';
            break;
        case 2:
            backroundColor = '#93D542';
            break;
        case 3:
            backroundColor = '#469DEB';
            break;
        case 4:
            backroundColor = '#9767E0';
            break;
        case 5:
            break
        case 6:
            backroundColor = primary;
            break;
    }
    return (
        <div style={{display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: size, height: size, borderRadius: borderRadius, backgroundColor: backroundColor, ...style}}>
            {!backroundColor && <img src={icLastLevel} width={size} height={size} style={{position: 'absolute'}}/>}
            <span style={{fontWeight: 700, fontSize: fontSize, color: 'white', zIndex: '1' }}>{level === 6 ? 'W' : level}</span>
        </div>
    )
}

export default MemberLevelTag;
