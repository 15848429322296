import {BackNavToolbar} from "@components/Toolbars";
import {useLocation} from "react-router-dom";
import FirebaseImageLoader from "@components/FirebaseImageLoader";
import {useState} from "react";
import {updateHasShownZoomInGuide} from "@reducer/userSlice";
import {useDispatch, useSelector} from "react-redux";
import IcPinchImage from '@assets/ic_pinch_image.svg';
import PinchZoomWrapper from "@components/PinchZoomWrapper";
import {isForPartners} from "@util/isForPartners";
import {DefaultPageRoot} from "@components/PageTemplate";
import { RootState } from "@reducer/store";

const Modal = ({open, onClose, children}: {
    open: boolean,
    onClose: () => void,
    children: React.ReactNode
}) => {
    if (!open) return null;
    return (
        <div style={{position: 'absolute', height: '100vh', width: '100vw', maxWidth: isForPartners ? 800 : 650, background: '#FFFFFFB3'}} onClick={onClose}>
            <div onClick={(e) => e.stopPropagation()} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', borderRadius: 5, width: "90%"}}>
                {children}
            </div>
        </div>
    )
}

const ZoomInImage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {paths, title} = location.state;
    const hasShownZoomInGuide = useSelector((state: RootState) => state.user.hasShownZoomInGuide)
    const [guideOpen, setGuideOpen] = useState(!hasShownZoomInGuide);

    const modalTextStyle = {
        fontSize: '1.125rem',
        color: '#1d1d1d'
    }

    function handleClose() {
        setGuideOpen(false);
        dispatch(updateHasShownZoomInGuide());
    }

    return (<>
        <DefaultPageRoot
            top={<BackNavToolbar title={title}/>}
            middle={<PinchZoomWrapper>
                {
                    paths && paths.map((image: string, idx: number) => {
                        return (
                            <FirebaseImageLoader path={image} key={idx}/>
                        )
                    })
                }
            </PinchZoomWrapper>}
        />
        <Modal
            open={guideOpen}
            onClose={handleClose}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                alignItems: 'center'
            }}>
                <p style={{...modalTextStyle, margin: 0, lineHeight: 1.5}}>두 손가락으로</p>
                <p style={{...modalTextStyle, margin: 0, lineHeight: 1.5}}>확대, 축소할 수 있습니다</p>
                <IcPinchImage style={{margin: '5% 0 7%'}}/>
                <button style={{lineHeight: 1.75, padding: '1% 15%', ...modalTextStyle, fontWeight: '400', backgroundColor: 'white', border: '1px solid #1d1d1d', borderRadius: '10px'}} onClick={handleClose}>
                    <span>알겠어요</span>
                </button>
            </div>
        </Modal>
    </>
    )
}

export default ZoomInImage;