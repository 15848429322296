import {isForPartners} from "@src/util/isForPartners";
import {useNavigate, useSearchParams} from "react-router-dom";
import icWizdom from "@assets/ic_wizdom.webp"
import {EVENT_APP_INSTLL_GUIDE_MODAL_SHOW, EVENT_INSTALL_BUTTON_CLICK} from "./AppConstant";
import {RootState} from "@src/reducer/store";
import {useDispatch, useSelector} from "react-redux";
import {FillButton} from "./Buttons";
import {grey999} from "./Color";
import {updateReferrer} from "@src/reducer/userSlice";
import {RegularText_17} from "./text";
import trackEvent from "@src/util/trackEvent";
import {useOnMountOnce} from "@src/util/useOnMountOnce";
import {DialogDrawerTemplate} from "@components/DrawerTemplate";

// GuideInstallAppModal.tsx도 참고
// 이건 일단 급하게 1시간 만에 구현된거임. 목적은 back button 대비용
const AppInstallGuideModal2 = ({show, onClose}: { show: boolean, onClose: () => void }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const referrer = searchParams.get("referrer")
  const userReferrer = useSelector((state: RootState) => state.user.referrer)

  if (referrer) {
    dispatch(updateReferrer(referrer))
  }

  useOnMountOnce(() => {
    if (show) {
      trackEvent(EVENT_APP_INSTLL_GUIDE_MODAL_SHOW, {referrer: userReferrer})
    }
  }, [show])

  async function openStore() {
    trackEvent(EVENT_INSTALL_BUTTON_CLICK, {location: 'modal', referrer: userReferrer})
    if (userReferrer) {
      window.location.href = `https://play.google.com/store/apps/details?id=com.modex.wizdom&referrer=${encodeURIComponent(userReferrer)}`;
    } else {
      window.location.href = "https://play.google.com/store/apps/details?id=com.modex.wizdom&referrer=utm_source%3Dappshare%26utm_campaign%3Ddetailpage%26utm_content%3Dactivityshare";
    }
  }

  return (
    <DialogDrawerTemplate show={show} onClose={onClose}>
      <div style={{display: 'flex', flexDirection: 'column', background: 'white', borderRadius: '10px', justifyContent: 'center', alignItems: 'center'}}>
        <img src={icWizdom} alt="" style={{alignSelf: "center", height: '70px', marginTop: '20px'}}/>
        <p style={{display: 'block', marginTop: '20px', textAlign: 'center', fontWeight: 500, lineHeight: '1.5'}}>
          [위즈덤 : 은퇴 후 필수 정보 앱]<br/>
          은퇴 후 일자리, 재무 등 5060<br/>
          필수 정보를 매일 받아보세요.
        </p>
        <div style={{display: 'flex', marginTop: '15px', gap: '15px', width: '100%', flexDirection: 'column', alignItems: 'center'}}>
          <FillButton
            text={'앱 설치해서 보기 (무료)'}
            onClick={openStore}
            style={{
              height: '50px',
              width: '85%'
            }}
            fontStyle={{
              fontSize: '1rem'
            }}
          />
          <RegularText_17 style={{color: grey999, marginBottom: '10px'}} onClick={onClose}>
            설치하지 않고 보기
          </RegularText_17>
        </div>
      </div>
    </DialogDrawerTemplate>
  );
}
export default AppInstallGuideModal2;