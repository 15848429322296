import { RouteObject, RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';
import React, { Children, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isForPartners } from "@util/isForPartners";
import { BorderButton } from "@components/Buttons";
import { fetchWithRetry } from '@util/networks';
import { RootState } from '@reducer/store';
import {setDeviceId, setUuid, updateUuidHash, setResumes, changeNickname, updatePushToken} from '@reducer/userSlice';
import { withProfiler } from '@sentry/react';
import { ShareDrawer } from '@view/ShareDrawer';
import { updateAppVersion } from "@reducer/configSlice";
import server_domain from '@components/RemoteServer';
import * as amplitude from '@amplitude/analytics-browser';
import LoginView from '@view/LoginView';
import MainView from '@view/MainView';
import SettingView from '@view/SettingView';
import RedirectView from '@view/RedirectView';
import DevConfigView from '@view/DevConfigView';
import SurveySimpleApply from "@view/SurveySimpleApply";
import applicationDescriptionLoader from "@src/data-layer/applicationDescriptionLoader";
import {removeUnavailableScrapedActivity} from "@reducer/scrapSlice";
import MyPageView from "@view/mypage/MyPageView";
import { getFCMToken, subscribeToTopic } from './util/firebaseCloudMessaging';
import { updateFCMToken } from './data-layer/UserService';
import HomePage from './view/HomePage';
import DiscountHomePage from './features/discount/page/DiscountHomePage';
import DiscountNotificationConfirmModal from './features/discount/page/DiscountNotificationConfirmModal';
import DiscountPromoteForCEOPage from './features/discount/page/DiscountPromoteForCEOPage';
import LoggoutDialog from "@view/mypage/LoggoutDialog";
import SignOutDialog from "@view/mypage/SignOutDialog";
import {identify} from "@amplitude/analytics-browser";
import DiscountPromotePhoneNumberModal from './features/discount/page/DiscountPromotePhoneNumberModal';
import JobRouter, {ActivityRouter} from "@src/features/job/JobRouter";
import PensionCalculatorRouter from "@src/features/pension_calculator/PensionCalculatorRouter";
import {TaxSavingRouter} from "@src/features/tax_saving/TaxSavingRouter";
import {AgeCalculatorRouter} from "@src/features/age_calculator/AgeCalculatorRouter";
import {FinanceRouter} from "@src/features/finance/FinanceRouter";
import CommunityRouter, { PostDetailRouter } from '@src/features/community/CommunityRouter';

declare global {
  interface String {
    hashCode(): number;
  }

  interface Set<T> {
    eq(other: Set<T>): boolean;
  }
}

String.prototype.hashCode = function () {
  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
    var char = this.charCodeAt(i);
    hash = hash + char;
  }
  return hash;
}

Set.prototype.eq = function (other): boolean {
  return this.size === other.size && [...this].every((x) => other.has(x));
}

const Desc = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <>
    {
      isForPartners && width > 1300 &&
      <div style={{ alignItems: 'center', margin: 'auto', width: 330 }}>
        <p style={{ fontSize: '1.625rem' }}><span style={{ color: '#0ABF53', fontWeight: 900, lineHeight: 1.5 }}>위즈덤 파트너스</span>란?</p>
        <p style={{ lineHeight: 1.5 }}>위즈덤 파트너스는 직업상담사, 전직지원컨설턴트, 은퇴자교육자 등 HR전문가 분들을 위한 5060 취업정보 사이트입니다.</p>
        <p style={{ lineHeight: 1.5 }}>구직자용 위즈덤과 달리 일자리 정보 습득에만 더 집중하실 수 있도록 만들었습니다.</p>
        <p style={{ lineHeight: 1.5 }}>구직자분들에게는 아래의 일반 위즈덤(앱)을 소개해주시기 바랍니다.</p>
        <BorderButton
          text={"구직자용 위즈덤 바로가기"}
          onClick={() => window.location.replace("https://app.wizdom.co.kr")}
          style={{ padding: '10px 20px', width: 'fit-content' }}
          fontStyle={{ fontSize: '1rem', fontWeight: 400 }}
        />
      </div>
    }
  </>
}

const App = () => {
  const dispatch = useDispatch()
  const deviceId = useSelector((state: RootState) => state.user.deviceId)
  const phoneNumber = useSelector((state: RootState) => state.user.phoneNumber)
  const uuid = useSelector((state: RootState) => state.user.uuid)
  const pushToken = useSelector((state: RootState) => state.user.pushToken)
  const uuidHash = useSelector((state: RootState) => state.user.uuidHash)
  const createdAt = useSelector((state: RootState) => state.user.createdAt)
  const scraps = useSelector((state: RootState) => state.scrap.value)

  init()

  const shareDrawer = { path: "share", element: <ShareDrawer /> }
  const ApplicationDescriptionView = lazy(() => import('./view/ApplicationDescriptionView'));

  const router = createBrowserRouter([
    {
      path: "survey_simple_apply/:mode/:activityId",
      element: <SurveySimpleApply />,
    },
    {
      path: "application_description/:id",
      element: <ApplicationDescriptionView />,
      loader: async ({ params }) => {
        return await applicationDescriptionLoader(params.id ?? "")
      }
    },
    {
      path: 'redirect/:redirect_activity_id',
      element: <RedirectView />
    },
    {
      path: "/",
      element:<MainView />,
      children:  [
        {
          path: "/",
          element: <HomePage />,
          children: [
            shareDrawer,
            ActivityRouter("HomePage"),
            {
              path: "mypage",
              element: <MyPageView />,
              children: [
                {
                  path: "setting",
                  element: <SettingView />,
                  children: [
                    { path: "signout", element: <SignOutDialog /> },
                    { path: "logout", element: <LoggoutDialog /> },
                  ]
                },
                { path: "login", element: <LoginView /> },
                { path: "dev", element: <DevConfigView /> },
              ],
            },
            {
              path: 'discount',
              element: <DiscountHomePage />,
              children: [
                {
                  path: 'confirm-notification',
                  element: <DiscountNotificationConfirmModal />,
                },
                {
                  path: 'for-ceo',
                  element: <DiscountPromoteForCEOPage />,
                  children: [
                    {
                      path: 'call',
                      element: <DiscountPromotePhoneNumberModal />,
                    }
                  ]
                }
              ]
            },
            CommunityRouter,
            PostDetailRouter,
            JobRouter,
            AgeCalculatorRouter,
            PensionCalculatorRouter,
            TaxSavingRouter,
            FinanceRouter,
          ]
        },
      ],
    }
  ]);

  async function tokenUpdate({deviceId}: {deviceId: string}) {
    const newToken = await getFCMToken()
      
    if (newToken == null) return;
    
    if(newToken != pushToken) {
      updateFCMToken({deviceId: deviceId, token: newToken})
      dispatch(updatePushToken(newToken))
    }
  }

  async function init() {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('createdAt', createdAt);
    identify(identifyEvent);

    if (uuid && !uuidHash) {
      dispatch(updateUuidHash(uuid.replace(/-/, "").hashCode() % 100))
    }
    await amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY!, undefined, { defaultTracking: { sessions: true, pageViews: false, formInteractions: true, fileDownloads: true }, flushIntervalMillis: 5000, appVersion: APP_VERSION }).promise

    if (deviceId == null) {
      const deviceId = amplitude.getDeviceId()

      if (!deviceId) {
        return
      }

      const response = await fetchWithRetry(server_domain() + "/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "device_id": deviceId,
          "phone_number": phoneNumber || ""
        })
      });

      let data = await response.json();
      if (data.result == "success") {
        dispatch(setDeviceId(data.user.device_id))
        dispatch(setUuid(data.user.uuid))
      }

      if (data.result == "fail") {
        // duplicated case: deviceId를 캐시에만 다시 저장
        dispatch(setDeviceId(deviceId))
      }
    }

    if (deviceId != null) {
      tokenUpdate({deviceId: deviceId})
    }

    if (deviceId != null && uuid == null) {
      const response = await fetchWithRetry(server_domain() + '/user?' + "device_id=" + deviceId, {
        headers: {
          "Content-Type": "application/json",
        },
      })

      const json = await response.json()
      dispatch(setUuid(json.user.uuid))
      dispatch(updateUuidHash(json.user.uuid.replace(/-/, "").hashCode() % 100))
    }

    fetchWithRetry(server_domain() + '/resumes?' + "uid=" + uuid, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response: Response) => {
      let json = await response.json()
      dispatch(setResumes(json))
    });

    if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
      window.flutter_inappwebview.callHandler('appVersion').then((res: any) => {
        dispatch(updateAppVersion(res))
      })
    }

    // 활동을 삭제하는 이벤트가 있다면 복구 필요
    let body = {
      "ids": Object.keys(scraps)
    }

    fetch(
      server_domain() + "/check_activity_available",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      }
    )
      .then(res => res.json())
      .then(data => {
        dispatch(removeUnavailableScrapedActivity(data.ids))
      })
    subscribeToTopic("all_events")
  }

  return (
    <div style={{ background: '#FAFAFA' }}>
      <div style={{ maxWidth: 1300, margin: 'auto', display: 'flex' }}>
        <Desc />
        <div style={{ flex: 1.625, minWidth: 0, maxWidth: isForPartners ? 800 : 650, margin: 'auto', background: 'white' }}>
          <RouterProvider router={router} />
        </div>
      </div>

    </div>
  );
}

export default withProfiler(App);