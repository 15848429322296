import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { cleanUser } from '@reducer/userSlice';
import { show } from '@reducer/snackbarSlice';
import { DialogDrawerTemplate } from "@components/DrawerTemplate";
import { FillButton, SecondaryFillButton } from '@components/Buttons';

const LoggoutDialog = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    function onClose() {
        // @ts-ignore
        navigate(-1, { replace: true })
    }
    function onConfirm() {
        dispatch(cleanUser())
        dispatch(show({message: "로그아웃 되었습니다"}))
        // @ts-ignore
        navigate(-2, { replace: true })
    }
    return (
        <DialogDrawerTemplate 
            show={true} 
            title="정말 로그아웃하시겠습니까?"
            onClose={onClose}
            closeButton={false}
        >
             <div style={{ display: 'flex', width: '100%', gap: 10}} >
                <FillButton text={"로그아웃"} onClick={onConfirm}  />
                <SecondaryFillButton text={"취소"} onClick={onClose} />
            </div>
        </DialogDrawerTemplate>
    );
}

export default LoggoutDialog