import JobNotice from "@src/features/job/features/notice/page/JobNotice";
import ListQueryModal from "@view/ListQueryModal";
import SurveyModal from "@view/survey/SurveyModal";
import SurveyIntroView from "@view/survey/SurveyIntroView";
import SurveyView from "@view/survey/SurveyView";
import SurveyResultView from "@view/survey/SurveyResultView";
import JobResumeView from "@view/JobResumeView";
import SelectAddResumeOption from "@view/mypage/SelectAddResumeOption";
import CopyResume from "@view/mypage/CopyResume";
import React from "react";
import InquriyDrawer from "@view/InquriyDrawer";
import {ShareDrawer} from "@view/ShareDrawer";
import TelDrawer from "@view/TelView";
import ResumePreview from "@view/resume/ResumePreview";
import EditResumeCheckEducation from "@view/edit_resume/EditResumeCheckEducation";
import EditResumeEducationPhd from "@view/edit_resume/EditResumeEducationPhd";
import EditResumeEducationMaster from "@view/edit_resume/EditResumeEducationMaster";
import EditResumeEducationBachelor from "@view/edit_resume/EditResumeEducationBachelor";
import EditResumeEducationHighSchool from "@view/edit_resume/EditResumeEducationHighSchool";
import EditResumeCheckCareerView from "@view/edit_resume/EditResumeCheckCareerView";
import EditResumeAddCareer from "@view/edit_resume/EditResumeAddCareer";
import EditResumeCheckCertificateView from "@view/edit_resume/EditResumeCheckCertificateView";
import EditResumeAddCertificate from "@view/edit_resume/EditResumeAddCertificate";
import EditResumeView from "@view/edit_resume/EditResumeView";
import CopyEditResumeView from "@view/edit_resume/CopyEditResumeView";
import BasicInfo from "@view/resume/BasicInfo";
import HighestEducation from "@view/resume/HighestEducation";
import EducationPhd from "@view/resume/EducationPhd";
import EducationMaster from "@view/resume/EducationMaster";
import EducationBachelor from "@view/resume/EducationBachelor";
import EducationHighSchool from "@view/resume/EducationHighSchool";
import CheckCareer from "@view/resume/CheckCareer";
import Career from "@view/resume/Career";
import AddCareer from "@view/resume/AddCareer";
import RemoveCareer from "@view/resume/RemoveCareer";
import CheckCertificate from "@view/resume/CheckCertificate";
import Certificate from "@view/resume/Certificate";
import AddCertificate from "@view/resume/AddCertificate";
import RemoveCertificate from "@view/resume/RemoveCertificate";
import Introduction from "@view/resume/Introduction";
import RegisterPhotoView from "@view/resume/RegisterPhotoView";
import RegisterPhotoGuideView from "@view/resume/RegisterPhotoGuideView";
import SimpleApplyRegisterPhotoView from "@view/resume/SimpleApplyRegisterPhotoView";
import RegionCityDrawer from "@view/RegionCityDrawer";
import JobCategoryDrawer from "@view/JobCategoryDrawer";
import WorkingTimeTypeDrawer from "@view/WorkingTimeTypeDrawer";
import ActivityFilterPage from "@view/ActivityFilterPage";
import ActivityInquiryDrawer from "@view/ActivityInquiryDrawer";
import {RouteObject} from "react-router-dom";
import ShareGuideView from "@view/ShareGuideView";
import ZoomInImage from "@view/ZoomInImage";
import ActivityListView from "@view/ActivityListView";
import SearchPage from "@view/SearchView";
import PopularActivityView from "@view/PopularActivityView";
import DefaultActivityPage from "@src/features/job/page/DefaultActivityPage";
import SimpleApplyActivityPage from "@src/features/job/page/SimpleApplyActivityPage";
import JobHistoryView from "@src/features/job/features/history/page/JobHistoryView";
import DefaultActivityHistoryView from "@src/features/job/features/history/page/DefaultActivityHistoryView";
import SimpleApplyActivityHistoryView from "@src/features/job/features/history/page/SimpleApplyActivityHistoryView";
import FaqView from "@src/features/job/features/faq/page/FaqView";
import OtherApplyView from "@src/features/job/features/apply/page/OtherApplyView";
import InputEmail from "@src/features/job/features/apply/page/InputEmail";
import RequestKakaoGuideView from "@src/features/job/features/apply/page/RequestKakaoGuideView";
import ApplyMethodDrawer from "@src/features/job/features/apply/page/ApplyMethodDrawer";
import SimpleApplyView from "@src/features/job/features/simple_apply/page/SimpleApplyView";
import SelectResume from "@src/features/job/features/simple_apply/page/SelectResume";
import JobMainList from "@src/features/job/page/JobMainList";
import JobPageRoot from "@src/features/job/page/JobPageRoot";
import JobScrapView from "@src/features/job/features/scrap/page/JobScrapView";
import JobNewsDetailView from "@src/features/job/features/news/page/JobNewsDetailView";
import JobNewsView from "@src/features/job/features/news/page/JobNewsView";
import GovJobView from "@src/features/job/features/gov_job/page/GovJobView";
import GovJobDetailView from "@src/features/job/features/gov_job/page/GovJobDetailPage";

const InquirySelectDrawerRouter = {path: 'inquiry_select', element: <InquriyDrawer/>,} // TODO Modal
const ShareDrawerRouter = {path: "share", element: <ShareDrawer/>} // TODO Modal
const telView = {path: "tel", element: <TelDrawer/>} // TODO Modal
const FaqRouter = {path: 'faq/:faqActivityId', element: <FaqView />}

const resumePreview = {path: "resume_preview/:resumeId", element: <ResumePreview/>}

const editResumeChildren = [
  {path: 'education_status', element: <EditResumeCheckEducation/>,},
  {path: 'education_phd', element: <EditResumeEducationPhd/>},
  {path: 'education_master', element: <EditResumeEducationMaster/>},
  {path: 'education_bachelor', element: <EditResumeEducationBachelor/>},
  {path: 'education_high_school', element: <EditResumeEducationHighSchool/>},
  {path: 'check_career', element: <EditResumeCheckCareerView/>},
  {path: "career", element: <EditResumeAddCareer/>, children: [{path: ":careerId", element: <EditResumeAddCareer/>,}]},
  {path: 'check_certificate', element: <EditResumeCheckCertificateView/>},
  {path: "certificate", element: <EditResumeAddCertificate/>, children: [{path: ":certificateId", element: <EditResumeAddCertificate/>,}]},
]

const editResumeChildrenCopy = [
  {path: 'education_status', element: <EditResumeCheckEducation/>,},
  {path: 'education_phd', element: <EditResumeEducationPhd/>},
  {path: 'education_master', element: <EditResumeEducationMaster/>},
  {path: 'education_bachelor', element: <EditResumeEducationBachelor/>},
  {path: 'education_high_school', element: <EditResumeEducationHighSchool/>},
  {path: 'check_career', element: <EditResumeCheckCareerView/>},
  {path: "career", element: <EditResumeAddCareer/>, children: [{path: ":careerId", element: <EditResumeAddCareer/>,}]},
  {path: 'check_certificate', element: <EditResumeCheckCertificateView/>},
  {path: "certificate", element: <EditResumeAddCertificate/>, children: [{path: ":certificateId", element: <EditResumeAddCertificate/>,}]},
]

const editResume = (previousPage: string) => ({
  path: "edit_resume/:resumeId",
  element: <EditResumeView previousPage={previousPage}/>,
  children: [
    ...editResumeChildren,
  ]
})

const copyEditResume = (previousPage: string) => ({
  path: "copy_edit_resume/:resumeId",
  element: <CopyEditResumeView previousPage={previousPage}/>,
  children: editResumeChildrenCopy
})

const createResumeChildren = (previousPage: string) => [
  {path: 'create_resume_basic_info', element: <BasicInfo/>},
  {path: 'create_resume_highest_education', element: <HighestEducation/>},
  {path: 'create_resume_education_phd', element: <EducationPhd/>},
  {path: 'create_resume_education_master', element: <EducationMaster/>},
  {path: 'create_resume_education_bachelor', element: <EducationBachelor/>},
  {path: 'create_resume_education_high_school', element: <EducationHighSchool/>},
  {path: 'create_resume_check_career', element: <CheckCareer/>},
  {path: 'create_resume_career', element: <Career/>, children: [{path: 'add', element: <AddCareer/>}, {path: 'add/:careerId', element: <AddCareer/>}, {path: 'remove_career/:careerId', element: <RemoveCareer/>}]},
  {path: 'create_resume_check_certificate', element: <CheckCertificate/>},
  {path: 'create_resume_certificate', element: <Certificate/>, children: [{path: 'add', element: <AddCertificate/>}, {path: 'add/:certificateId', element: <AddCertificate/>}, {path: 'remove_certificate/:certificateId', element: <RemoveCertificate/>}]},
  {path: 'create_resume_introduction', element: <Introduction/>},
  {path: 'create_resume_photo', element: <RegisterPhotoView id={"id1"}/>, children: [{path: 'guide', element: <RegisterPhotoGuideView/>}]},
]


const createResumeSimpleApplyChildren = (previousPage: string) => [
  {path: 'create_resume_basic_info', element: <BasicInfo/>},
  {path: 'create_resume_highest_education', element: <HighestEducation/>},
  {path: 'create_resume_education_phd', element: <EducationPhd/>},
  {path: 'create_resume_education_master', element: <EducationMaster/>},
  {path: 'create_resume_education_bachelor', element: <EducationBachelor/>},
  {path: 'create_resume_education_high_school', element: <EducationHighSchool/>},
  {path: 'create_resume_check_career', element: <CheckCareer/>},
  {path: 'create_resume_career', element: <Career/>, children: [{path: 'add', element: <AddCareer/>}, {path: 'add/:careerId', element: <AddCareer/>}, {path: 'remove_career/:careerId', element: <RemoveCareer/>}]},
  {path: 'create_resume_check_certificate', element: <CheckCertificate/>},
  {path: 'create_resume_certificate', element: <Certificate/>, children: [{path: 'add', element: <AddCertificate/>}, {path: 'add/:certificateId', element: <AddCertificate/>}, {path: 'remove_certificate/:certificateId', element: <RemoveCertificate/>}]},
  {path: 'create_resume_introduction', element: <Introduction/>},
  {path: 'create_resume_photo', element: <SimpleApplyRegisterPhotoView id={"id1"}/>, children: [{path: 'guide', element: <RegisterPhotoGuideView/>}]},
]

const OtherApplyViewRouter = {
  path: "other_apply",
  element: <OtherApplyView/>,
  children: [
    {path: "input_email", element: <InputEmail/>, children: [FaqRouter]},
    {path: "request_guide_kakao", element: <RequestKakaoGuideView/>, children: [FaqRouter]},
    InquirySelectDrawerRouter,
    telView,
    FaqRouter
  ]
}
const ApplyMethodDrawerRouter = {
  path: "apply_method", element: <ApplyMethodDrawer/>,
  children: [
    OtherApplyViewRouter,
    telView,
  ]
}
const RegionModalRouter = {path: "region_select", element: <RegionCityDrawer/>} // TODO Modal
const JobCategoryModalRouter = {path: "job_category", element: <JobCategoryDrawer/>} // TODO Modal
const WorkingTimeTypeModalRouter = {path: "job_time_type", element: <WorkingTimeTypeDrawer/>,} // TODO Modal
const activityFilterPage = {path: "filter", element: <ActivityFilterPage/>} // TODO Modal
const InquiryDrawerRouter = {path: "inquiry", element: <ActivityInquiryDrawer/>, children: [telView]}  // TODO Modal

const ActivityRouter = (previousPage: string): RouteObject => {
  return {
    path: "activity/:activityId",
    element: <DefaultActivityPage previousPage={previousPage}/>,
    children: [
      {path: "share_guide", element: <ShareGuideView/>},
      {path: "zoom_in", element: <ZoomInImage/>},
      ShareDrawerRouter,
      ApplyMethodDrawerRouter,
      InquiryDrawerRouter
    ],
  }
}

const ApplyHistoryDetailRouter = {path: 'activity_history/:historyId', element: <DefaultActivityHistoryView/>, children: [InquiryDrawerRouter]}
const SimpleApplyHistoryDetailRouter = {path: 'simple_apply_activity_history/:historyId', element: <SimpleApplyActivityHistoryView/>, children: [InquiryDrawerRouter]}
const SimpleApplyActivityRouter = (previousPage: string): RouteObject => {
  return {
    path: "simple_apply_activity/:activityId",
    element: <SimpleApplyActivityPage previousPage={previousPage}/>,
    children: [
      SimpleApplyHistoryDetailRouter,
      {path: "share_guide", element: <ShareGuideView/>},
      {path: "zoom_in", element: <ZoomInImage/>},
      ShareDrawerRouter,
      SimpleApplyRouter,
      InquiryDrawerRouter
    ],
  }
}
const SimpleApplyRouter = {
  path: "simple_apply", element: <SimpleApplyView/>,
  children: [
    ...createResumeSimpleApplyChildren("SimpleApplyPage"),
    {
      path: 'select_resume',
      element: <SelectResume/>,
      children: [
        editResume("SelectResumePage"),
        resumePreview,
      ]
    },
  ]
}
const ActivityListRouter = {
  path: "activity_list",
  element: <ActivityListView/>,
  children: [
    activityFilterPage,
    RegionModalRouter,
    ActivityRouter("ActivityListPage"),
    SimpleApplyActivityRouter("ActivityListPage"),
  ]
}
const SearchPageRouter = {
  path: "search",
  element: <SearchPage/>,
  children: [
    ActivityRouter("SearchPage"),
    SimpleApplyActivityRouter("SearchPage"),
    RegionModalRouter,
    activityFilterPage,
  ]
}

const PopularActivityPageRouter = {
  path: "popular_activity_more",
  element: <PopularActivityView/>,
  children: [
    ActivityRouter("PopularActivityPage"),
    SimpleApplyActivityRouter("PopularActivityPage"),
    RegionModalRouter,
  ]
}

const GovJovPageRouter = {
  path: "gov_job",
  element: <GovJobView/>,
  children: [
    {
      path: ":govJobId",
      element: <GovJobDetailView/>,
      children: [
        ActivityRouter("GovJobDetailPage"),
        SimpleApplyActivityRouter("GovJobDetailPage"),
        {path: "zoom_in", element: <ZoomInImage/>},
      ]
    },
    ShareDrawerRouter,
    InquirySelectDrawerRouter
  ]
}

const JobTabRouter = {
  path: "",
  element: <JobMainList/>,
  children: [
    {path: "notice", element: <JobNotice/>,},
    RegionModalRouter,
    WorkingTimeTypeModalRouter,
    JobCategoryModalRouter,
    SimpleApplyActivityRouter("JobHomePage"),
    ActivityRouter("JobHomePage"),
    {path: "list_query", element: <ListQueryModal/>},
    ActivityListRouter,
    PopularActivityPageRouter,
    GovJovPageRouter,
    SearchPageRouter,
    {
      path: 'job_news',
      element: <JobNewsView/>,
      children: [
        ShareDrawerRouter,
        {
          path: ':job_news_id',
          element: <JobNewsDetailView/>,
          children: [
            ShareDrawerRouter
          ]
        }
      ]
    },
  ]
}
const ScrapTabRouter = {
  path: "scrap",
  element: <JobScrapView/>,
  children: [
    ActivityRouter("ScrapPage"),
    SimpleApplyActivityRouter("ScrapPage"),
  ]
}
const HistoryTabRouter = {
  path: 'history',
  element: <JobHistoryView />,
  children: [
    ActivityRouter("HistoryPage"),
    SimpleApplyActivityRouter("HistoryPage"),
    ActivityListRouter,
    ApplyHistoryDetailRouter,
    SimpleApplyHistoryDetailRouter,
    FaqRouter
  ]
}
const ResumeTabRouter = {
  path: "resume",
  element: <JobResumeView/>,
  children: [
    {
      path: 'select_add_option',
      element: <SelectAddResumeOption/>,
      children: [
        {
          path: 'copy',
          element: <CopyResume/>,
          children: [
            copyEditResume("CopyResume"),
            resumePreview
          ]
        }
      ],
    },
    editResume("MyPage"),
    resumePreview,
    ...createResumeChildren(""),
  ]
}
const SurveyRouters = [
  {path: "survey_modal", element: <SurveyModal/>,},
  {
    path: "survey",
    element: <SurveyIntroView/>,
    children: [
      {path: ":surveyId/:questionId", element: <SurveyView/>,},
    ]
  },
  {path: 'survey_result', element: <SurveyResultView/>,},
]
const JobRouter = {
  path: "/job",
  element: <JobPageRoot/>,
  children: [
    JobTabRouter,
    ScrapTabRouter,
    HistoryTabRouter,
    ResumeTabRouter,
    ...SurveyRouters
  ],
}

export default JobRouter
export {ActivityRouter}