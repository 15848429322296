import { useNavigate, useSearchParams } from "react-router-dom";
import { DefaultPageRoot } from "@components/PageTemplate";
import { BackNavToolbar } from "@components/Toolbars";
import { FillButton } from "@components/Buttons";
import DivideBar from "@components/DivideBar";
import * as amplitude from '@amplitude/analytics-browser';
import { EVENT_JOB_NEWS_PAGE_SHOW, EVENT_SHARE_VIEW_CLICK } from "@components/AppConstant";
import server_domain from "@components/RemoteServer";
import { useEffect, useRef, useState } from "react";
import { AppInstallGuideBanner } from "@components/AppInstallGuideBanner";
import { useVirtualizer } from '@tanstack/react-virtual';
import useOnLog from "@components/useOnLog";
import { fetchWithRetry } from "@util/networks";
import {GuideInstallAppModal} from "@view/GuideInstallAppModal";

const JobNewsItem = ({ content }: { content: any }) => {
    const navigate = useNavigate();
    return (
        <div style={{ display: 'flex', padding: '20px 16px', gap: 16, alignItems: 'center', borderBottom: '1px solid #D9D9D980' }} onClick={() => navigate(content.id)}>
            <img src={content.thumbnail_url} alt="" style={{ height: '80px', minWidth: '110px', maxWidth: '110px', objectFit: 'cover', objectPosition: 'top' }} />
            <div>
                <p style={{ margin: 0, lineHeight: 1.5, wordBreak: 'break-all' }}>{content.title}</p>
                <p style={{ margin: 0, lineHeight: 1.5, color: '#999', fontSize: '0.875rem' }}>{formatDate(content.created_at)}</p>
            </div>
        </div>
    )
}

const JobNewsList = ({ jobNewsList, parentRef }: {
    jobNewsList: any[],
    parentRef: React.RefObject<HTMLDivElement>
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const virtualizer = useVirtualizer({
        count: jobNewsList.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 200,
        overscan: 5,
        scrollMargin: containerRef.current?.offsetTop ?? 0,
    })
    const items = virtualizer.getVirtualItems()

    if (jobNewsList.length === 0) {
        return null
    }

    return (
        <div
            ref={containerRef}
            style={{
                height: virtualizer.getTotalSize(),
                width: '100%',
                position: 'relative',
            }}
        >
            {items.map((item) => (
                <div
                    key={item.key}
                    ref={virtualizer.measureElement}
                    data-index={item.index}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${item.start - virtualizer.options.scrollMargin}px)`,
                    }}
                >
                    <JobNewsItem content={jobNewsList[item.index]} />
                </div>
            ))}
        </div>
    )
}

const formatDate = (date: string) => {
    const timestamp = new Date(new Date(date).getTime() + 9 * 60 * 60 * 1000);
    return timestamp.toISOString().split('T')[0];
}

const JobNewsView = () => {
    const parentRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate();
    const [contents, setContents] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const showInstallGuide = (searchParams.get("show_install_guide") === "true")
    const [showGuide, setShowGuide] = useState(showInstallGuide);

    useEffect(() => {
        fetchWithRetry(server_domain() + '/job_news_list')
            .then(res => res.json())
            .then(data => {
                setContents(data)
            })
    }, [])

    useOnLog({
        pageNameKey: EVENT_JOB_NEWS_PAGE_SHOW,
        amplitudeProp: {},
        regStr: "^(.*?)\/job_news$"
    })

    function share() {
        amplitude.track(EVENT_SHARE_VIEW_CLICK, {
            location: 'JobNewsPage'
        })
        navigate({
            pathname: "share",
        }, {
            state: {
                amplitude_props: {
                    location: 'JobNewsPage',
                },
                title: "공유하기",
                kakao_share_title: "카카오톡으로 공유하기",
                link_copy_title: "링크 복사하기",
                url: `[위즈덤]\n5060 취업 뉴스\n\n5060 일자리 정책, 중장년 박람회 등\n최신 뉴스 확인 가능!\n\n${import.meta.env.VITE_URL}/job/job_news?back_to_home=true&show_install_banner=true&show_install_guide=true&referrer=utm_source%3Dappshare%26utm_campaign%3Dnewslistpage%26utm_content%3D5060news`,
                toastText: "링크가 복사되었습니다.",
                templateId: 104702,
                templateArgs: {
                    "title": "[위즈덤] 5060 취업 뉴스",
                    "description": "5060 일자리 정책, 중장년 박람회 등 최신 뉴스 확인 가능!",
                    "thumbnail_url": `${import.meta.env.VITE_URL}/img/img_job_news_kakao.png`,
                    "url": import.meta.env.VITE_URL,
                    "url_path": "job/job_news?back_to_home=true&show_install_banner=true&show_install_guide=true&referrer=utm_source%3Dappshare%26utm_campaign%3Dnewslistpage%26utm_content%3D5060news"
                }
            }
        })
    }
    return (
        <DefaultPageRoot
            top={<>
                <AppInstallGuideBanner />
                <BackNavToolbar title="5060 취업 뉴스" />
                <GuideInstallAppModal show={showGuide} close={() => { setShowGuide(false) }} />
            </>}
            middleRef={parentRef}
            middle={<>
                <div style={{ padding: '16px 16px' }}>
                    <p style={{ margin: '0 0 16px 0', lineHeight: 1.5, fontWeight: 500 }}>
                        5060 취업 관련 최신 뉴스입니다. 일자리 정책, 중장년 박람회 등 다양한 소식과 혜택도 놓치지 마세요!
                    </p>
                    <FillButton
                        style={{ width: '100%', padding: 14, margin: '15px 0 0px 0' }}
                        text={'공유하기'}
                        onClick={share}
                    />
                </div>
                <DivideBar margin={'20px 0px'} />
                <JobNewsList jobNewsList={contents} parentRef={parentRef} />
            </>}
        />
    );
}

export default JobNewsView;