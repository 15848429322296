import { createSlice } from "@reduxjs/toolkit"

const surveySlice = createSlice({
    name: 'survey',
    initialState: {
        answered: [] as any[],
    },
    reducers: {
        appendAnswered: (state, action) => {
            state.answered.push(action.payload)
        },
    }
})

export const {appendAnswered} = surveySlice.actions
export default surveySlice.reducer
