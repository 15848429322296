import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Description, Indicator, VSelector} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";
import React, {useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {clearCareer, setLastSelectedCareerOption, setNoCareer} from "@reducer/userSlice";
import uuidv4 from "@util/uuidv4";
import useOnLog from "@components/useOnLog";
import {EVENT_CREATE_RESUME_CHECK_CAREER_PAGE_SHOW} from "@components/AppConstant";

const navigationMap: { [key: string]: string } = {
  "no": 'create_resume_check_certificate',
  "yes": 'create_resume_career',
}

const options = [
  {key: "no", label: "경력이 없어요"},
  {key: "yes", label: "경력자에요"},
]

const CheckCareer = () => {
  const navigate = useNavigate()
  const state = useLocation().state
  const step = useMemo(() => state?.step || 0, [null])
  const dispatch = useDispatch();
  const lastSelectedCareerOption = useSelector((state: RootState) => state.user.resume.lastSelectedCareerOption)

  const next = () => {
    if (lastSelectedCareerOption === "no") {
        dispatch(setNoCareer({
          uuid: uuidv4(),
          name: "",
          position: "",
          status: "",
          startDate: "",
          endDate: "",
          description: "",
          no_career: true
        }))
    } else {
      dispatch(clearCareer())
    }
    navigate('../' + navigationMap[lastSelectedCareerOption], {state: {step: step+1}})
  }

  const available = () => !!lastSelectedCareerOption

  useOnLog({
    pageNameKey: EVENT_CREATE_RESUME_CHECK_CAREER_PAGE_SHOW,
    regStr: "\/create_resume_check_career$"
  })

  return <DefaultPageRoot
    top={<BackNavToolbar title={""}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <Indicator label={"경력정보"} max={6} index={3} />
      <Description text={"일한 경력이 있나요?"}/>
      <VSelector onChange={(option) => dispatch(setLastSelectedCareerOption(option))} selected={lastSelectedCareerOption} options={options}/>
    </div>}
    bottom={<div style={{padding: 16}}>
      <FillButton
        active={available()}
        text={"다음"}
        onClick={next}
      />
    </div>}
  />
}
export default CheckCareer;