import React from "react";
import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Button, Description, VSelector} from "@components/form/ResumeComponent";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {saveCareerTemp} from "@reducer/userSlice";
import uuidv4 from "@util/uuidv4";

const EditResumeCheckCareerView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const noCareer = () => {
    dispatch(saveCareerTemp({
      uuid: uuidv4(),
      name: "",
      position: "",
      status: "",
      startDate: "",
      endDate: "",
      description: "",
      no_career: true
    }))
    navigate(-1)
  }

  const yesCareer = () => {
    navigate('../career', {replace: true})
  }

  return <DefaultPageRoot
    top={<BackNavToolbar title={""}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <Description text={"일한 경력이 있나요?"}/>
      <Button checked={false}  label={"경력이 없어요"} onClick={noCareer}/>
      <Button checked={false}  label={"경력자에요"} onClick={yesCareer}/>
    </div>}
  />
}

export default EditResumeCheckCareerView;