import * as amplitude from '@amplitude/analytics-browser';
import ActivityCardContent from "./ActivityCardContent";
import { useDispatch, useSelector } from "react-redux";
import { append, remove } from "@reducer/scrapSlice";
import IcScrapActiveGreen from '@assets/ic_scrap_active_green.svg';
import IcScrapInactive from '@assets/ic_scrap_inactive.svg';
import {EVENT_SCRAP_CLICK} from "@components/AppConstant";
import { show } from "@reducer/snackbarSlice";
import { RootState } from "@reducer/store";
import { Activity } from '@model/Activity';
import {ReactNode} from "react";

const ActivityCardTemplate = ({ activity, location, last, onClick, Badges, Buttons, useScrap=true, regions = null, sessionId }: {
  onClick?: () => void,
  Badges: ReactNode,
  Buttons: ReactNode,
  activity: Activity,
  location: string,
  useScrap? : boolean,
  last?: boolean,
  regions: string[] | null,
  sessionId?: string
}) => {
  const scrap = useSelector((state: RootState) => state.scrap.value)
  const dispatch = useDispatch()

  const isScraped = () => !!scrap[activity.id];

  const startIconStyle = {
    width: 'auto',
    maxWidth: 'none'
  }

  function buildStartIcon() {
    if (isScraped()) {
      return <IcScrapActiveGreen style={startIconStyle} />
    } else {
      return <IcScrapInactive style={startIconStyle} />
    }
  }

  function handleScrap() {
    const prop = {
      "activity_id": activity.id,
      "user_regions": regions,
      "title": activity.activityTitle,
      "url": activity.url,
      "location": location,
      "toggle": isScraped() ? "off" : "on",
    }
    if (isScraped()) {
      dispatch(show({ message: "스크랩에서 제거되었습니다" }))
      dispatch(remove(activity))
    } else {
      dispatch(show({ message: "스크랩 되었습니다" }))
      dispatch(append(activity))
    }
    amplitude.track(EVENT_SCRAP_CLICK, prop)
  }

  return (
    <div style={{ position: 'relative', margin: '0px' }} >
      <button
        onClick={onClick}
        style={{
          display: 'block',
          border: 'none',
          borderRadius: '0px',
          background: 'white',
          borderBottom: last ? 'none' : '1px solid #D9D9D980',
          lineHeight: '1rem',
          padding: '20px 16px',
          textAlign: 'start',
          width: '100%',
        }}>
        <div>
          {Badges}
          <ActivityCardContent activity={activity} location={location} sessionId={sessionId}/>
        </div>
        {Buttons}
      </button>
      {
        useScrap && <div style={{position: "absolute", top: '5px', right: '5px'}}>
              <button onClick={handleScrap} style={{background: 'transparent', border: 'none', padding: 8}}>
                {buildStartIcon()}
              </button>
          </div>
      }

    </div>
  )
}

export default ActivityCardTemplate;