import {DefaultPageRoot} from "@components/PageTemplate";
import IcHomeInactive from "@assets/ic_home_inactive.webp";
import {BlackText_25, MediumText_25} from "@components/text";
import {primary} from "@components/Color";
import {BackNavToolbar} from "@components/Toolbars";
import React, {useState} from "react";
import {Checkbox} from "@components/selector/checkbox/Checkbox";
import {Button, ButtonText} from "@components/Buttons";
import {useDispatch} from "react-redux";
import {show} from "@reducer/snackbarSlice";
import {useNavigate} from "react-router-dom";
import trackEvent from "@util/trackEvent";
import {EVENT_OTHER_TAX_MORE_CLICK} from "@components/AppConstant";

const TaxMorePage = () => {
  const [checked, setChecked] = useState(new Set());
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = () => {
    trackEvent(EVENT_OTHER_TAX_MORE_CLICK, {taxes: Array.from(checked)})
    dispatch(show({message: "소중한 답변이 제출되었습니다."}))
    navigate(-1)
  }

  const onChange = (label: string) => {
    const newChecked = new Set(checked);
    if (checked.has(label)) {
      newChecked.delete(label);
    } else {
      newChecked.add(label);
    }
    setChecked(newChecked)
  }

  return <DefaultPageRoot
    top={
      <BackNavToolbar
        titleElement={
          <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', gap: '4px'}}>
            <BlackText_25 style={{color: primary}}>위즈덤</BlackText_25>
            <MediumText_25 style={{color: primary}}>절세</MediumText_25>
          </div>
        }
      />
    }
    middle={<>
      <div style={{padding: 16}}>
        <p style={{margin: 0, lineHeight: 1.5, fontWeight: 500}}>다음 중 어떤 세금의 절세 방안에 대해 알고 싶으신가요? 위즈덤이 빠르게 준비하겠습니다.</p>
        <p style={{margin: 0, lineHeight: 1.5}}>(여러 항목 선택이 가능합니다.)</p>
        <Checkbox label={"증여세"} checked={checked.has("증여세")} onClick={onChange}/>
        <Checkbox label={"상속세"} checked={checked.has("상속세")} onClick={onChange}/>
        <Checkbox label={"주택 외 재산세(건물, 토지 등)"} checked={checked.has("주택 외 재산세(건물, 토지 등)")} onClick={onChange}/>
        <Checkbox label={"종합부동산세"} checked={checked.has("종합부동산세")} onClick={onChange}/>
        <Checkbox label={"취등록세(부동산, 자동차 등)"} checked={checked.has("취등록세(부동산, 자동차 등)")} onClick={onChange}/>
        <Checkbox label={"소득세(이자, 배당, 연금 ,종합)"} checked={checked.has("소득세(이자, 배당, 연금 ,종합)")} onClick={onChange}/>
      </div>
    </>}
    bottom={<div style={{padding: 16}}>
      <Button onClick={onSubmit} active={checked.size !== 0}>
        <ButtonText text={"답변 제출하기"} />
      </Button>
    </div>}
  />
}

export default TaxMorePage;