import { DefaultTabTemplate } from "@src/components/PageTemplate";
import { VirtualItem, useVirtualizer } from "@tanstack/react-virtual";
import { useRef, useState } from "react";
import Post from "../model/Post";
import CommunityService from "../infra/CommunityService";
import { BoldText_25, MediumText_25 } from "@src/components/text";
import { greyF4, red } from "@src/components/Color";
import { useDispatch } from "react-redux";
import { PostItem } from "./component/PostItem";
import useOnLog from "@src/components/useOnLog";
import { EVENT_COMMUNITY_HOT_POST_PAGE_SHOW } from "@src/components/AppConstant";
import { useOnMountOnce } from "@src/util/useOnMountOnce";
import icFavorite from "@assets/ic_favorite.webp";
import IcReset from "@assets/ic_reset.svg";

const HotPostListPage = () => {
    const parentRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const [hotPosts, setHotPosts] = useState<Post[]>([])
    const [allTimeFavoritePosts, setAllTimeFavoritePosts] = useState<Post[]>([])

    useOnLog({
        pageNameKey: EVENT_COMMUNITY_HOT_POST_PAGE_SHOW,
        regStr: "^\/community/hot-post$"
    })

    const virtualizer = useVirtualizer({
        count: allTimeFavoritePosts.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 150,
        overscan: 10
    })
    const items: VirtualItem[] = virtualizer.getVirtualItems()

    useOnMountOnce(() => {
        CommunityService.getBestPosts().then((data) => {
            setHotPosts(data.hot)
            setAllTimeFavoritePosts(data.all_time_favorites)
        })
    }, [])

    const reloadHotPosts = async () => {
        const hotPosts = (await CommunityService.getRandomHotPosts()).sort((a, b) => Math.random() - 0.5).slice(0, 2)
        setHotPosts(hotPosts)
    }

    return (
        <DefaultTabTemplate
            outletContext={[dispatch]}
            top={
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '75px'}}>
                    <MediumText_25>인기글</MediumText_25>
                </div>
            }
            middle={
                <div style={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    contain: 'strict',
                    scrollbarWidth: 'none',
                }}>
                    <div style={{display: 'inline-flex', lineHeight: 1.5, paddingLeft: '15px', paddingTop: '10px', alignItems: 'center', gap: '8px'}}>
                        <BoldText_25>
                            <span style={{color: red}}>지금 뜨는</span> 인기글
                        </BoldText_25>
                        <div 
                            style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: greyF4, width: '25px', height: '25px', borderRadius: '12.5px'}}
                            onClick={reloadHotPosts}
                        >
                            <IcReset width='12px' height='12px'/>
                        </div>
                    </div>
                    <div>
                        <div>
                            {
                                hotPosts.map((post, idx) => {
                                    return <PostItem idx={idx} post={post} key={post.id} totalLength={hotPosts.length} newMarkEnabeld={false} source="hot"/>
                                })
                            }
                        </div>
                    </div>
                    <div style={{height: '16px', width: '100%', background: greyF4, borderTop: 'solid 1px #D7D8DA'}}/>
                    <div style={{ display: 'flex', paddingLeft: '15px', marginTop: '20px', marginBottom: '10px', alignItems: 'center', gap: '10px' }}>
                        <img src={icFavorite} width='30px' height='30px'/>
                        <BoldText_25>
                            역대 <span style={{color: '#FAA629'}}>베스트</span> 글
                        </BoldText_25>
                    </div>
                    <div ref={parentRef}>
                        <div
                            style={{
                                height: virtualizer.getTotalSize(),
                                width: '100%',
                                position: 'relative',
                            }}
                        >
                            {items.map((virtualRow: VirtualItem) => {
                                return (
                                    <div
                                        key={virtualRow.key}
                                        data-index={virtualRow.index}
                                        ref={virtualizer.measureElement}
                                    >
                                        <PostItem 
                                            idx={virtualRow.index}
                                            post={allTimeFavoritePosts[virtualRow.index]}
                                            totalLength={allTimeFavoritePosts.length} 
                                            source="allTimeFavorite" 
                                            newMarkEnabeld={false}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
        />
    );
};

export default HotPostListPage;
