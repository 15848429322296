import SavingInstallmentProduct from "@src/features/finance/features/saving_installment_account/model/SavingInstallmentProduct";
import SavingInstallmentProductCardContent from "@src/features/finance/features/saving_installment_account/component/SavingInstallmentProductCardContent";
import Badge from "@components/Badge";
import {isNew} from "@src/features/finance/features/saving_installment_account/util/isNew";
import {useNavigate} from "react-router-dom";
import {InterestType} from "@src/features/finance/features/saving_installment_account/model/InterestType";

const SavingInstallmentProductCard = ({product, last}: { product: SavingInstallmentProduct, last: boolean}) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(product.id.toString())
  }

  return (
    <div style={{position: 'relative', margin: '0px'}}>
      <button
        onClick={onClick}
        style={{
          display: 'block',
          border: 'none',
          borderRadius: '0px',
          background: 'white',
          borderBottom: last ? 'none' : '1px solid #D9D9D980', // TODO
          lineHeight: '1rem',
          padding: '20px 16px',
          textAlign: 'start',
          width: '100%',
        }}>
        <div>
          {
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px', gap: "0.3rem"}}>
              {isNew(product) && <Badge text="N" style={{borderRadius: '5px', height: '1.2rem', width: '1.2rem', lineHeight: 2}}/>}
              {product.specialOffer && <span style={{display: 'inline-block', fontWeight: '500', fontSize: '0.75rem', lineHeight: '1.2rem', verticalAlign: 'middle', backgroundColor: '#EEF9F2', borderRadius: '5px', padding: '0 16px', color: '#0ABF53'}}>특판</span>}
              {product.interestType && <span style={{display: 'inline-block', fontSize: '0.75rem', lineHeight: '1.2rem', verticalAlign: 'middle', backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '0 0.8rem', fontWeight: 500, color: '#474D5B'}}>{InterestType[product.interestType]}</span>}
            </div>
          }
          <SavingInstallmentProductCardContent
            product={product}
          />
        </div>
        <div style={{position: "absolute", right: '1rem', bottom: '0.6rem'}}>
          <div style={{backgroundColor: '#0ABF53', borderRadius: '3px', color: 'white', marginLeft: 'auto', fontSize: '1rem', fontWeight: '400', padding: '8px 10px'}}>
            자세히 보기
          </div>
        </div>
      </button>
    </div>
  )
}

export default SavingInstallmentProductCard;