export const initKakao = (jsKey: string) => {
    return new Promise(
      (resolve: (isLoaded: boolean) => void, reject: (error: any) => void) => {
        if (typeof window !== "undefined") {
          var script = document.createElement("script");
          script.onload = function () {
            // console.log("onload : ", document);
            window.Kakao.init(jsKey);
            resolve(true);
          };
          script.onerror = (e) => {
            reject(e);
          };
          script.src = "https://t1.kakaocdn.net/kakao_js_sdk/2.6.0/kakao.min.js";
          script.integrity =
            "sha384-6MFdIr0zOira1CHQkedUqJVql0YtcZA1P0nbPrQYJXVJZUkTk/oX4U9GhUIs3/z8";
          script.crossOrigin = "anonymous";
  
          document.head.appendChild(script);
        } else {
          console.log("window is not defined. Kakao SDK is not loaded.");
          resolve(false);
        }
      }
    );
};


export const shareViaKakao = async (templateId: number, templateArgs: {[key: string]: string}) => {
  const kakao = window.Kakao;
  if (!kakao || !kakao.isInitialized()) {
      await initKakao(import.meta.env.VITE_SHARE_KAKAO_LINK_KEY);
  }

  window.Kakao.Share.sendCustom({
    templateId: templateId,
    templateArgs: templateArgs,
  });
};
