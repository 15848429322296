import React, {ReactNode} from "react";
import IcLightBulb from "@assets/ic_small_lightbulb.webp";
import ICQuestionMark from "@assets/ic_small_question_mark.webp";
import IcNotice from "@assets/ic_small_notice.webp";

export const Caution = ({title, children}: { title: string, children: ReactNode }) => {
  return <div style={{margin: 8}}>
    <div style={{display: 'flex', alignItems: 'baseline'}}>
      <img src={IcLightBulb} alt={""} style={{height: 20, marginRight: 10}}/>
      <p style={{margin: 0, fontWeight: 700, lineHeight: 1.5}}>{title}</p>
    </div>
    {children}
  </div>
}

export const Question = ({title, children}: { title: string, children: ReactNode }) => {
  return <div style={{margin: 8}}>
    <div style={{display: 'flex', alignItems: 'baseline'}}>
      <img src={ICQuestionMark} alt={""} style={{height: 20, marginRight: 10}}/>
      <p style={{margin: 0, fontWeight: 700, lineHeight: 1.5}}>{title}</p>
    </div>
    {children}
  </div>
}

export const Notice = ({title, children}: { title: string, children: ReactNode }) => {
  return <div style={{margin: 8}}>
    <div style={{display: 'flex', alignItems: 'baseline'}}>
      <img src={IcNotice} alt={""} style={{height: 20, marginRight: 10}}/>
      <p style={{margin: 0, fontWeight: 700, lineHeight: 1.5}}>{title}</p>
    </div>
    {children}
  </div>
}