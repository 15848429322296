import { FillButton } from "@src/components/Buttons";
import { MediumText_20 } from "@src/components/text";
import { useNavigate, useOutletContext } from "react-router-dom";
import animationCongratulation3 from "@assets/animation_congraturation3.webp";
import { isForPartners } from "@src/util/isForPartners";

const OnboardingCompleteCongraturation = () => {
    const navigate = useNavigate();
    const context = useOutletContext() as { onPopState: () => void } | undefined;

    window.onpopstate = (e: PopStateEvent) => {
        if (context?.onPopState) {
            context.onPopState();
        }
        window.onpopstate = null
    }

    return (
        <div
            style={{ 
                display: 'flex',
                position: 'absolute',
                height: '100vh',
                width: '100vw',
                background: '#000000B3',
                zIndex: 10,
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: isForPartners ? 800 : 650
            }}
            onClick={() => navigate(-1)}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                style={{ display: 'flex', flexDirection: 'column', padding: '16px 16px', justifyContent: 'center', alignItems: 'center', gap: '20px', width: '80%', backgroundColor: 'white', height: 'fit-content', borderRadius: '5px' }}
            >
                <img src={animationCongratulation3} style={{width: '45%', marginBottom: '10px'}}/>
                <div style={{display: 'block', textAlign: 'center', fontWeight: 500, lineHeight: '1.5'}}>
                    <MediumText_20 style={{color: 'black'}}>좋은 고민/이야기 공유 감사해요.<br/>앞으로도 커뮤니티 회원들과<br/>자주 소통해보세요!</MediumText_20>
                </div>
                <div style={{display: 'flex', gap: '10px', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <FillButton 
                        text='확인'
                        onClick={() => navigate(-1)}
                        style={{
                            width: '50%'
                        }}
                        fontStyle={{
                            fontSize: '1rem'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default OnboardingCompleteCongraturation;
