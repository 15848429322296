import {useLocation, useNavigate} from "react-router-dom";
import React, {useMemo, useState} from "react";
import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Description, Indicator, TextArea, VSelector} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {updateResumeField} from "@reducer/userSlice";
import useOnLog from "@components/useOnLog";
import {EVENT_CREATE_RESUME_CHECK_CERTIFICATE_PAGE_SHOW, EVENT_CREATE_RESUME_INTRODUCTION_PAGE_SHOW} from "@components/AppConstant";

const Introduction = () => {
  const introduction = useSelector((state: RootState) => state.user.resume.introduction)
  const isEdit = !!introduction
  const state = useLocation().state
  const step = useMemo(() => state?.step || 0, [null])
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [text, setText] = useState(introduction || "")

  const next = () => {
    dispatch(updateResumeField({
      'introduction': text
    }))
    navigate("../create_resume_photo", {state: {step: step+1}})
  }

  const available = () => text !== ""

  useOnLog({
    pageNameKey: EVENT_CREATE_RESUME_INTRODUCTION_PAGE_SHOW,
    regStr: "\/create_resume_introduction"
  })

  return <DefaultPageRoot
    top={<BackNavToolbar title={""}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <Indicator label={"자기소개"} max={6} index={5} />
      <Description text={"나의 강점을 설명해주세요"}/>
      <TextArea rows={8} placeholder={"지원하려는 분야에서 내가 어떤 강점을 가지고 있는지 설명해주세요. 어린 시절의 구구절절한 스토리보다, 내가 이 일을 왜 잘 할 수 있는지 설명하는 게 좋아요"} label={""} value={text} onChange={setText} highlight={isEdit && text===""}/>
    </div>}
    bottom={<div style={{padding: 16}}>
      <FillButton
        active={available()}
        text={"다음"}
        onClick={next}
      />
    </div>}
  />
}
export default Introduction;