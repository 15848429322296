import server_domain from "@components/RemoteServer"
import convertActivities from "@util/convertActivities"
import uuidv4 from "@util/uuidv4"
import { fetchWithRetry } from "@util/networks"
import { WorkingTimeType } from "@src/model/WorkingTimeType"

interface Props {
    tagId?: string | null,
    workingTimeType?: WorkingTimeType | null,
    regions?: string[] | null,
    categoryIds?: number[],
    simpleApply?: boolean,
}

const activityPageLoader = async ({
    tagId = null, workingTimeType = null, regions = null, categoryIds = [0], simpleApply = false
}: Props) => {
    categoryIds = categoryIds.length === 0 ? [0] : categoryIds

    let query: Record<string, any> = {}
    if (tagId) {
        query['id'] = tagId
    }
    if (regions) {
        query['regions'] = JSON.stringify(regions)
    }
    if (workingTimeType) {
        query['working_time_type'] = workingTimeType
    }
    query['category_type'] = JSON.stringify(categoryIds)
    query['simple_apply'] = simpleApply
    const params = new URLSearchParams(query)
    
    let response = await fetchWithRetry(server_domain() + '/tag?' + params.toString(), {
        headers: {
            "Content-Type": "application/json",
        },
    })
    

    let json = await response.json()
    let activities = convertActivities(json.contents).filter((a) => a.activityTagList != "노인일자리")
    let sessionId = uuidv4()
    return { activities: activities, sessionId: sessionId }
}

export default activityPageLoader;
