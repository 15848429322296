import { useLocation, useNavigate } from "react-router-dom";
import { BottomDrawerTemplate } from "@components/DrawerTemplate";
import IcMonitor from '@assets/ic_monitor.png';
import IcEmail from '@assets/ic_email.png';
import IcPhoneGray from '@assets/ic_phone_666.png';
import IcFax from '@assets/ic_fax.png';
import IcDocumentation from '@assets/ic_documentation.png';
import IcPhone from '@assets/ic_phone_border.png';
import IcSend from '@assets/ic_send.png';
import { CSSProperties, useEffect, useState } from "react";
import server_domain from "@components/RemoteServer";
import openCall from "@util/openCall";
import * as amplitude from '@amplitude/analytics-browser';
import { EVENT_CALL_MANAGER_CLICK } from "@components/AppConstant";
import { fetchWithRetry } from '@util/networks';
import ApplicationMethod from "@model/ApplicationMethod";

const ApplyMehthodDrawerButtonStyle: CSSProperties = { border: '1px solid #999', borderRadius: 5, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 16px', position: 'relative' }
const ApplyMethodDrawer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const [methods, setMethods] = useState<ApplicationMethod | null>(null);
    let method = methods?.method.split(",") || [];

    function navigateApplyPage(title: string) {
        navigate("other_apply", {
            state: {
                ...state.activity,
                title: title,
                selectedApplyMethod: methods
            }
        })
    }

    const Icon: {[iconName: string]: string} = {
        "전화지원": IcPhone,
        "이메일지원": IcEmail,
        "팩스지원": IcFax,
        "방문": IcDocumentation,
        "컴퓨터지원": IcMonitor,
        "간편지원": IcSend,
        "공공 간편지원": IcSend,
    }

    const orderMethod: {[order: string]: number} = {
        "간편지원": 1,
        "공공 간편지원": 2,
        "전화지원": 3,
        "이메일지원": 4,
        "방문": 5,
        "팩스지원": 6,
        "컴퓨터지원": 7,
    }

    const methodTitle: {[method: string]: string} = {
        "간편지원": "간편지원",
        "공공 간편지원": "간편지원",
        "전화지원": "전화지원",
        "이메일지원": "이메일지원",
        "방문": "방문",
        "팩스지원": "팩스지원",
        "컴퓨터지원": "컴퓨터지원",
    }
    
    useEffect(() => {
        if (state === undefined) {
            return
        }
        fetchWithRetry(server_domain() + "/application_method?id=" + state.activity.id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw new Error("failed to get application method");
            }
        }).then((res) => {
            if (res && res.method) {
                const sortedMethod = res.method.split(",").sort((a: string, b: string) => {
                    return orderMethod[a] - orderMethod[b];
                })
                res.method = sortedMethod.join(",")
                const filterdMethod = sortedMethod.filter((method: string) => method != "공공 간편지원")
                res.method = filterdMethod.join(",")
            }
            setMethods(res);
        }).catch((err) => {
            console.log(err);
        });
    }, [])
    
    if (method.includes("간편지원")) {
        method = ["간편지원"]
    }

    return (
        <BottomDrawerTemplate show={true} title={'지원방법 확인하기'} onClose={() => navigate(-1)}>

            {
                method.length == 1 && method[0] == "컴퓨터지원" &&
                <p style={{margin: '0 0 20px 0', lineHeight: 1.5}}>컴퓨터로 지원서류를 작성해야하는 공고입니다. 아래의 버튼을 눌러 지원방법과 서류양식을 받으세요.</p>
            }

            <div style={{ display: "grid", flexWrap: 'wrap', gap: 10, gridTemplateColumns: '1fr 1fr' }}>
                {
                    method.map((title: string, idx: number) => (
                    <div style={ApplyMehthodDrawerButtonStyle} onClick={() => { navigateApplyPage(title) }} key={idx}>
                        { (title === "간편지원" || title === "공공 간편지원") && <span style={{ position: 'absolute', top: 5, left: 5, background: "#0ABF53", borderRadius: 5, color: 'white', padding: '5px 10px', fontSize: '0.75rem', fontWeight: 400 }}>추천</span>}
                        <div style={{width: 25, height: 25,  marginBottom: 10 }}>
                            <img src={Icon[title]} alt="" width={25} style={{ marginBottom: 10 }} />
                        </div>
                        <span style={{fontWeight: 500}}>{methodTitle[title]}</span>
                    </div>
                    ))
                }
            </div>
   
            {
                methods && method.length == 0 &&
                <div>
                    <p>채용담당자 한마디를 참고하여 지원해주세요</p>
                </div>
            }
            {
                methods && methods.memo &&
                <div style={{ borderRadius: 5, background: "#F4F4F4", padding: 16, marginTop: 24 }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                        <span style={{ color: '#666', flex: 1, fontWeight: 400 }}>채용담당자 한마디</span>
                        <div style={{ border: '1px solid #999', background: 'white', borderRadius: 5, display: 'flex', alignItems: 'center', padding: '5px 10px' }} onClick={() => {
                            amplitude.track(EVENT_CALL_MANAGER_CLICK, {
                                "activity_id": state.activity.id,
                                "location": "ApplyMethodDrawer"
                            })

                            if (methods.tel.split(',').length > 1) {
                                navigate("tel", { state: { ...state, title: "채용담당자 전화번호", number: methods.tel } })
                            } else {
                                openCall(methods.tel)
                            }
                        }}>
                            <img src={IcPhoneGray} alt="" width={17.5} />
                            <span style={{ color: '#666', fontSize: '0.875rem', marginLeft: 5 }}>연락처</span>
                        </div>
                    </div>
                    <p style={{ color: '#666', margin: 0, whiteSpace: 'pre-wrap', lineHeight: 1.5}}>"{methods.memo}"</p>
                </div>
            }
        </BottomDrawerTemplate>
    )
}

export default ApplyMethodDrawer;