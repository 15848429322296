import {DialogDrawerTemplate} from "@components/DrawerTemplate";
import {useNavigate} from "react-router-dom";
import {FillButton} from "@components/Buttons";
import {BlackText_25, MediumText_25} from "@components/text";
import IcClose from "@assets/close.svg";
import IcLoudspeaker from "@assets/ic_loudspeaker.webp";
import {primary} from "@components/Color";
import {useEffect, useMemo, useRef} from "react";
import trackEvent from "@util/trackEvent";
import {updateNotice} from "@reducer/userSlice";
import {useDispatch} from "react-redux";
import {EVENT_JOB_NOTICE_SCROLL, EVENT_JOB_NOTICE_SHOW, EVENT_JOB_NOTICE_STAY} from "@components/AppConstant";
import useOnLog from "@components/useOnLog";
import {NoticeId} from "@src/features/job/features/notice/model/Notice";

const JobNotice = () => {
  // const ref = useRef<HTMLParagraphElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // let start = 0

  // const observer = useMemo(() => new IntersectionObserver(
  //   ([entry]) => {
  //     if (entry.isIntersecting) {
  //       trackEvent(EVENT_JOB_NOTICE_SCROLL, {})
  //       observer.unobserve(entry.target);
  //     }
  //   }, {
  //     threshold: 1
  //   }), [ref])


  useOnLog({
    pageNameKey: EVENT_JOB_NOTICE_SHOW,
    regStr: "\/job\/notice$"
  })

  // useEffect(() => {
  //   if (ref && ref.current) {
  //     observer.observe(ref.current)
  //   }
  //   return () => observer.disconnect()
  // }, [ref])

  const stopNotice = () => {
    dispatch(updateNotice({
      id: NoticeId,
      leftCount: 0,
      nextShowAt: undefined
    }))
    navigate(-1);
  }

  // useEffect(() => {
  //   if (start === 0) {
  //     start = new Date().getTime();
  //   }
  //   return () => {
  //     const end = new Date().getTime();
  //     const duration = (end - start) / 1000 ;
  //     if (duration > 0.1) {
  //       trackEvent(EVENT_JOB_NOTICE_STAY, {duration: duration.toFixed(2)});
  //     }
  //   }
  // }, []);

  return <DialogDrawerTemplate show={true} onClose={() => navigate(-1)}>
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <img src={IcLoudspeaker} alt={""} style={{height: 30}}/>
      <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', gap: '4px'}}>
        <BlackText_25 style={{color: primary}}>위즈덤</BlackText_25>
        <MediumText_25 style={{color: primary}}>공지사항</MediumText_25>
      </div>
      <IcClose onClick={() => navigate(-1)} />
    </div>

    <div style={{lineHeight: 1.5, fontWeight: 500, overflowY: 'scroll', maxHeight: 400, margin: '15px 0'}}>
      <p>안녕하세요!</p>
      <p>위즈덤 회원님들의 취업 성공을 위해 오늘도 열심히 제품을 개선 중입니다.</p>
      <p><span style={{color: '#0ABF53'}}>지원 내역 확인 기능</span>을 출시했습니다.</p>
      <ul>
        <li><span>[간편지원] <span style={{textDecoration: 'underline'}}>지원하신 내역</span>을 살펴보실 수 있습니다. </span></li>
        <li><span>[간편지원] <span style={{textDecoration: 'underline'}}>채용담당자의 이력서 열람 여부</span>를 알 수 있습니다.</span></li>
        <li><span>[간편지원 외] <span style={{textDecoration: 'underline'}}>지원 방법 확인 내역</span>을 살펴보실 수 있습니다.</span></li>
        <li><span>지원, 면접, 합격 소식을 알리고 <span style={{textDecoration: 'underline'}}>취업축하금 10만원에 응모</span>하실 수 있습니다.</span></li>
      </ul>
      <p>새로운 소식으로 또 인사드리겠습니다. 감사합니다!</p>
      <p>위즈덤팀 드림</p>
    </div>

    <div style={{display: 'flex', gap: 10}}>
      <FillButton text={"닫기"} onClick={() => navigate(-1)} style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}}/>
      <FillButton text={"그만보기"} onClick={stopNotice}/>
    </div>
  </DialogDrawerTemplate>
}

export default JobNotice;