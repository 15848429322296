import { useNavigate, useSearchParams } from "react-router-dom";
import IcSearchBlack from '@assets/ic_search_black.svg';
import IcArrowBack from '@assets/arrow_back.svg';
import { useEffect, useRef, useState } from "react";
import RegionCityButton from "@components/RegionCityButton";
import { useSelector } from "react-redux";
import { DefaultPageRoot } from "@components/PageTemplate";
import ActivityCard from "@src/features/job/component/ActivityCard";
import DivideBar from "@components/DivideBar";
import { AppInstallGuideBanner } from "@components/AppInstallGuideBanner";
import { useVirtualizer } from '@tanstack/react-virtual';
import { RootState } from "@reducer/store";
import NoContentView from "@src/components/NoContentView";
import searchPageLoader from "@src/data-layer/searchPageLoader";
import Filter from "@src/components/Filter";
import styles from "@src/features/job/features/apply/style/input.module.css";

const Navbar = ({ keyword, setKeyword, search }: {
    keyword: string,
    setKeyword: (keyword: string) => void,
    search: () => void
}) => {
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const backToHome = (searchParams.get("back_to_home") === "true")
    const backTo = searchParams.get("back_to")
    const handleBack: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (backTo) {
            navigate({ pathname: backTo }, { replace: true })
            return
        }

        if (backToHome) {
            navigate("/job", { replace: true })
        } else {
            navigate(-1);
        }
    }

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setKeyword(e.target.value);
    }

    function searchKeyward() {
        if (inputRef.current) {
            inputRef.current.blur();
        }
        search()
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', height: '75px' }}>
            <button
                onClick={handleBack}
                style={{
                    background: 'none',
                    border: 'none',
                    justifyContent: 'left',
                    color: '#1D1D1D',
                    margin: '0px 0px 0 16px',
                    width: 'auto',
                    padding: '1px 14px 1px 2px',
                    minWidth: 'initial',
                    display: 'flex',
                    alignItems: 'center',
                }} >
                <IcArrowBack />
            </button>
            <div style={{ flex: 1, padding: '5px 0px 5px 0px', background: '#F4F4F4', borderRadius: '5px' }}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <input
                        className={styles.input}
                        type="search"
                        autoFocus={true}
                        ref={inputRef}
                        style={{ flex: 1, border: 'none', background: 'none', fontSize: '1rem', padding: '5px 0px 5px 5px', lineHeight: 1.5 }}
                        placeholder="일자리 검색"
                        value={keyword}
                        onChange={onChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
                                e.preventDefault();
                                e.stopPropagation();
                                searchKeyward();
                            }
                        }}
                    />
                </div>
            </div>

            <button
                style={{
                    background: 'none',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: '2rem',
                    height: '100%',
                    padding: '6px 0px 6px 8px',
                    margin: '0 6px',
                }}
                onClick={searchKeyward}
            >
                <IcSearchBlack />
            </button>
        </div>
    )
}

const ActivityList = ({ activities, location, keyword, noContentView }: {
    activities: any,
    location: string,
    keyword: string | null,
    noContentView: JSX.Element
}) => {
    const regions = useSelector((state: RootState) => state.user.regions);
    const temp = activities.matched.concat(activities.near_regions, activities.other_regions)
    const [position, setPosition] = useState<{ matched: number, near_regions: number } | null>(null)
    const parentRef = useRef<HTMLDivElement>(null)
    const virtualizer = useVirtualizer({
        count: temp.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 200,
        overscan: 10
    })

    const items = virtualizer.getVirtualItems()

    useEffect(() => {
        setTimeout(() => {
            virtualizer.scrollToOffset(0)
        }, 100)
        setPosition({
            matched: activities.matched.length,
            near_regions: activities.matched.length + activities.near_regions.length,
        });
    }, [activities]);

    if (!position) {
        return null
    }
    if (temp.length === 0) {
        return noContentView
    }
    return (
        <>
            <div
                ref={parentRef}
                style={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    contain: 'strict',
                }}
            >
                <div
                    style={{
                        height: virtualizer.getTotalSize(),
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${items[0]?.start ?? 0}px)`,
                        }}
                    >
                        {items.map((virtualRow) => (
                            <div
                                key={virtualRow.key}
                                data-index={virtualRow.index}
                                ref={virtualizer.measureElement}
                            >

                                {
                                    virtualRow.index == 0 &&
                                    <div style={{ margin: 16, background: '#F4F4F4', textAlign: 'center', padding: 20 }}>
                                        <span style={{ color: '#474D5B', fontSize: '0.875rem' }}>"{keyword}" 검색 결과입니다.</span>
                                    </div>
                                }
                                {
                                    virtualRow.index == 0 && position && position.matched == 0 &&
                                    <div style={{ padding: 80, textAlign: 'center' }}>
                                        <span>검색 결과가 없습니다.</span>
                                    </div>
                                }

                                {
                                    position && virtualRow.index == position.matched && position.matched != position.near_regions &&
                                    <div style={{ marginBottom: 10 }}>
                                        <DivideBar margin={'20px 0px 30px'} />
                                        <p style={{ margin: 0, lineHeight: 1.5, fontSize: '1.125rem', padding: '0 16px' }}>
                                            <span style={{ fontWeight: 700, color: '#0ABF53' }}>주변 지역</span><span style={{ fontWeight: 700 }}>도 함께 확인해보세요</span>
                                        </p>
                                    </div>
                                }

                                {
                                    position && virtualRow.index == position.near_regions &&
                                    <div style={{ marginBottom: 10 }}>
                                        <DivideBar margin={'20px 0px 30px'} />
                                        <p style={{ margin: 0, lineHeight: 1.5, fontSize: '1.125rem', padding: '0 16px' }}>
                                            <span style={{ fontWeight: 700, color: '#0ABF53' }}>다른 지역</span><span style={{ fontWeight: 700 }}>도 참고해보세요</span>
                                        </p>
                                    </div>
                                }
                                <ActivityCard key={virtualRow.index} activity={temp[virtualRow.index]} regions={regions} location={location} last={virtualRow.index == (position.matched - 1) || virtualRow.index == (position.near_regions - 1)} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )

}


const SearchPage = () => {
    const navigate = useNavigate();
    const [activities, setActivities] = useState<{
        matched: any[],
        near_regions: any[],
        other_regions: any[],
    } | null>(null);
    const regions = useSelector((state: RootState) => state.user.regions);
    const categoryIds = useSelector((state: RootState) => state.user.categoryIds)
    const workingTimeType = useSelector((state: RootState) => state.user.workingTimeType)
    const simpleApply = useSelector((state: RootState) => state.user.simpleApplyFilterOn)
    const [searchParams, _] = useSearchParams();
    const [keyword, setKeyword] = useState<string | null>(searchParams.get("today_hot_topic"));
    const [searched, setSearched] = useState<string>("");


    async function search() {
        if (!keyword) {
            return
        }
        const result = await searchPageLoader({ keyword: keyword, regions: regions, simpleApply: simpleApply, categoryIds: categoryIds, workingTimeType: workingTimeType })
        setSearched(keyword);
        setActivities(result);
    }

    useEffect(() => {
        search();
    }, [regions, simpleApply, categoryIds, workingTimeType]);

    return (
        <DefaultPageRoot
            top={<>
                <AppInstallGuideBanner />
                <Navbar keyword={keyword ?? ""} setKeyword={setKeyword} search={search} />
                {searched &&
                    <div>
                        <RegionCityButton />
                        <Filter
                            filterCount={(categoryIds?.length ?? 0) + (!workingTimeType ? 0 : 1) + (simpleApply ? 1 : 0)}
                            margin="16px 16px"
                            onClick={() => navigate('filter')}
                        />
                    </div>
                }
            </>}
            middle={
                <>
                    {activities && <ActivityList activities={activities} location={"SearchPage"} noContentView={<NoContentView />} keyword={searched} />}
                </>
            }
        />
    )
}

export default SearchPage;