import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IcPin from '@assets/ic_pin_green.svg';
import IcArrowDown from '@assets/arrow_down.svg';
import IcArrowRightWhite from '@assets/ic_arrow_right_white.svg';
import * as amplitude from '@amplitude/analytics-browser';
import { EVENT_REGION_SELECT_CLICK, EVENT_SEARCH_DETAIL_SEARCH_BUTTON_CLICK } from "./AppConstant";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { RootState } from "@reducer/store";
import trackEvent from "@src/util/trackEvent";

const RegionCityButton = (props: {
    location?: string,
    style?: CSSProperties
}) => {
    const { location, style } = props
    const ref = useRef<HTMLDivElement>(null);
    const regions = useSelector((state: RootState) => state.user.regions)
    const navigate = useNavigate();
    const [isScrolled, setScrolled] = useState(false);

    function onClick(position = "") {
        amplitude.track(EVENT_REGION_SELECT_CLICK, {
            "location": location,
            "position": position,
        })
        navigate({
            pathname: 'region_select',
            search: `?location=${location}`,
        });
    }

    function getFormattedText() {
        if (regions.length == 0) {
            return "전체 지역"
        } else if (regions.length == 1) {
            return `${regions[0]}`
        } else {
            const hiddenCount = regions.length - 1;
            return `${regions[regions.length - 1]} 외 ${hiddenCount}`
        }
    }

    const handleScroll = () => {
        if (!ref.current) return;
        if (ref.current.getBoundingClientRect().top <= 74) {
            setScrolled(true)
        }

        if (ref.current.getBoundingClientRect().top > 120) {
            setScrolled(false)
        }
    }

    useEffect(() => {
        if (location == "MainPage") {
            window.addEventListener('scroll', handleScroll, true);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);
    
    const DetailSearchButton = () => {
        const onClick = () => {
            trackEvent(EVENT_SEARCH_DETAIL_SEARCH_BUTTON_CLICK, { 
                isStickToTop: isScrolled,
            });
            navigate('activity_list');
        }
        return (
            <div style={{display: 'flex', borderRadius: '5px', backgroundColor: '#474D5B', height: '30px', justifyContent: 'center', alignItems: 'center', padding: '2px 8px', gap: '8px'}}
                onClick={onClick}
            >
                <span style={{color: 'white', fontSize: '0.875rem'}}>상세 검색</span>
                <IcArrowRightWhite />
            </div>
        )
    }

    if (location == "MainPage") {
        const transition = '0.1s';
        return <div style={{ top: -1, zIndex: 1, position: 'sticky', padding: '4px 16px 8px', paddingBottom: isScrolled ? '12px' : '0px', background: 'white', borderBottom: isScrolled ? '1px solid #D9D9D9' : undefined}} ref={ref}>
            {!isScrolled &&
                <div style={{ transition: transition, display: 'flex', justifyContent: 'space-between', height: isScrolled ? '0px': 'auto'}}>
                    <p style={{fontSize: '1.25rem', fontWeight: 500, margin: '0 0px 20px', color: '#1D1D1D'}}>전체 채용 공고</p>    
                    <DetailSearchButton />
                </div>
            }
            <div
                onClick={() => onClick()}  
                style={{ transition: transition, width: isScrolled ? 'fit-content' : 'auto', position: 'relative', zIndex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: isScrolled ? '25px' : '10px', border: isScrolled ? '0px solid #999999' : '1px solid #999999', padding: isScrolled ? '0px' : '10px 12px' }}
            >
                <IcPin height={25} width={25} />
                <p style={{ margin: 0, transition: transition, flex: 1, padding: '6px', whiteSpace: 'nowrap', fontSize: isScrolled ? '1rem': '1.125rem', lineHeight: isScrolled ? 1.5 : '1.25rem' }}>{getFormattedText()}</p>
                <IcArrowDown height={25} width={25}/>
            </div>
            {   isScrolled && 
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center', padding: '0px 0px 0px 3px'} }>
                    <p style={{fontSize: '0.875rem', lineHeight: '0rem', color: '#474D5B'}}>다양한 조건으로 탐색하려면?</p>
                    <DetailSearchButton />
                </div>
            }
        </div>
    }

    return (
        <div style={{ alignItems: 'center', background: 'white', padding: '0px 16px', ...style, }} onClick={() => onClick()}>
             <div style={{ width: 'fit-content', position: 'relative', zIndex: '1', display: 'flex', alignItems: 'center' }}>
                <IcPin height={25} width={25} />
                <p style={{ margin:0, lineHeight: 1.5, display: 'flex', padding: '6px 6px 6px 6px', whiteSpace: 'nowrap', fontSize: '1rem', }}>{getFormattedText()}</p>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IcArrowDown height={25} width={25}/>
                </div>
            </div>
        </div>
    )
}

export default RegionCityButton;