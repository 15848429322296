import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {FillButton, IconFillButtonCenter} from "@components/Buttons";
import SavingInstallmentProductCardContent from "@src/features/finance/features/saving_installment_account/component/SavingInstallmentProductCardContent";
import {FinanceProductList} from "@src/features/finance/features/saving_installment_account/model/FinanceProductList";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Divider} from "@components/DivideBar";
import IcCarbonShareBlack from "@assets/ic_carbon_share_black.svg";
import IcPersonCall from "@assets/ic_person_call.svg";
import {ShareBottomSheet} from "@components/ShareComponent";
import React, {useState} from "react";
import {BottomDrawerTemplate} from "@components/DrawerTemplate";
import IcPhone from "@assets/ic_phone.webp";
import openCall from "@util/openCall";
import Table from "@components/Table";
import {InterestType} from "@src/features/finance/features/saving_installment_account/model/InterestType";
import {SpecialOffer} from "@src/features/finance/features/saving_installment_account/model/SpecialOffer";
import AppInstallGuideModal2 from "@components/AppInstallGuideModal2";
import {AppInstallGuideBanner} from "@components/AppInstallGuideBanner";
import useOnLog from "@components/useOnLog";
import {EVENT_ASK_FINANCE_CLICK, EVENT_FINANCE_INTEREST_CHECK_CLICK, EVENT_FINANCE_PRODUCT_DETAIL_PAGE_SHOW, EVENT_SHARE_VIEW_CLICK} from "@components/AppConstant";
import trackEvent from "@util/trackEvent";
import {track} from "@amplitude/analytics-browser";

const CTA = () => {
  const navigate = useNavigate();
  const onClick = () => {
    trackEvent(EVENT_FINANCE_INTEREST_CHECK_CLICK, {})
    navigate('simulation')
  }

  return <FillButton text={"원리금 확인하기"} onClick={onClick}/>
}

const SavingInstallmentAccountProductDetailPage = () => {
  const {productId} = useParams();
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [showInquiryModal, setShowInquiryModal] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showInstallModal, setShowInstallModal] = useState(searchParams.get("show_install_guide") === 'true')
  const product = FinanceProductList.find((product) => product.id === parseInt(productId ?? ""));

  useOnLog({
    pageNameKey: EVENT_FINANCE_PRODUCT_DETAIL_PAGE_SHOW,
    amplitudeProp: {type: "saving_installment_account", product_id: productId},
    regStr: /\/(saving_account_products|installment_saving_account_products)\/\d+$/
  })

  // TODO
  if (product === undefined) {
    return null
  }

  const share = () => {
    trackEvent(EVENT_SHARE_VIEW_CLICK, {location: "SavingInstallmentAccountDetailPage"})
    setShowShareModal(true)
  }
  const inquiry = () => {
    trackEvent(EVENT_ASK_FINANCE_CLICK, {})
    setShowInquiryModal(true)
  }

  return <>
    <DefaultPageRoot
      top={<>
        <AppInstallGuideBanner/>
        <BackNavToolbar title={"상세 정보"}/>
      </>}
      middle={<>
        <div style={{padding: 16}}>
          <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
            <div style={{flex: 1}}>
              {product.specialOffer && <span style={{display: 'inline-block', fontWeight: '500', fontSize: '0.75rem', lineHeight: '1.2rem', verticalAlign: 'middle', backgroundColor: '#EEF9F2', borderRadius: '5px', padding: '0 16px', color: '#0ABF53', marginRight: 10}}>특판</span>}
              {product.interestType && <span style={{display: 'inline-block', fontSize: '0.75rem', lineHeight: '1.2rem', verticalAlign: 'middle', backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '0 0.8rem', fontWeight: 500, color: '#474D5B'}}>{InterestType[product.interestType]}</span>}
            </div>
            {product.specialOffer && <span style={{display: 'inline-block', color: '#F15D47', fontWeight: '500', fontSize: '0.75rem', lineHeight: '1.2rem', verticalAlign: 'middle'}}>{SpecialOffer[product.specialOffer]}</span>}
            <span></span>
          </div>
          <SavingInstallmentProductCardContent product={product}/>
          <div style={{height: 16}}/>
          <CTA/>

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '30px 0px 0px', padding: '10px 0px 0px', borderTop: '1px solid #D7D8DA'}}>
            <div style={{textAlign: 'center', justifyContent: 'center', margin: '8px auto'}} onClick={share}>
              <IcCarbonShareBlack/>
              <p style={{margin: 0, fontSize: '1rem', fontWeight: 400, marginTop: 5}}>공유하기</p>
            </div>
            <div style={{height: '60px', width: '1px', background: '#D7D8DA'}}></div>
            <div style={{textAlign: 'center', justifyContent: 'center', margin: '8px auto'}} onClick={inquiry}>
              <IcPersonCall/>
              <p style={{margin: 0, fontSize: '1rem', fontWeight: 400, marginTop: 5}}>문의하기</p>
            </div>
          </div>

        </div>
        <Divider/>
        <p style={{padding: '16px 16px 24px', fontWeight: 500, fontSize: '1.25rem', color: '#1D1D1D', margin: 0}}>내용</p>
        <p style={{margin: 0, lineHeight: 1.5, padding: '0px 16px 16px', color: '#1d1d1d', fontSize: '1rem', wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}>{product.description}</p>
      </>}
      bottom={<div style={{padding: 16, borderTop: '1px solid #f4f4f4',}}>
        <CTA/>
      </div>}
    />
    <ShareBottomSheet
      showModal={showShareModal}
      title={"예∙적금상품 공유하기"}
      content={"안전한 예∙적금 상품을 주변 분들에게 많이 전파하세요."}
      onClose={() => setShowShareModal(false)}
      props={{
        kakao_share_title: "카카오톡으로 공유하기",
        link_copy_title: "공유 내용 복사하기",
        amplitude_props: {location: "saving_installment_account"},
        templateId: 109448,
        templateArgs: {
          "title": "(무료)예∙적금 상품을공유합니다.",
          "description": "은퇴 후 필수 앱, 위즈덤",
          "thumbnail_url": "https://app.wizdom.co.kr/img/img_og_image.webp",
          "cta": "예∙적금상품 수익률 보기",
          "url": import.meta.env.VITE_URL,
          "url_path": window.location.pathname + "?show_install_banner=true&show_install_guide=true&back_to_home=true&referrer=utm_source%3Dfinancialcommod%26utm_campaign%3Ddepositinstallment%26utm_content%3Dwizdomutil"
        },
        toastText: "복사되었습니다.\n붙여넣기 하여 공유해주세요.",
        url: "[위즈덤]\n예∙적금 상품 공유\n\n안정적인 예∙적금 상품\n상품별 정확한 수익 금액을 알려드립니다.\n\n" + import.meta.env.VITE_URL + window.location.pathname + "?show_install_banner=true&show_install_guide=true&back_to_home=true&referrer=utm_source%3Dfinancialcommod%26utm_campaign%3Ddepositinstallment%26utm_content%3Dwizdomutil"
      }}
    />

    <BottomDrawerTemplate title={'문의하기'} show={showInquiryModal} onClose={() => setShowInquiryModal(false)}>
      <Table
        keyStyle={{padding: '4px 0'}}
        valueStyle={{padding: '4px 0'}}
        data={[
          {
            'key': "금융회사",
            'value': product.organization
          },
          {
            'key': "전화번호",
            'value': product.tel
          },
        ]}
      />
      <IconFillButtonCenter
        icon={<img src={IcPhone} alt={""} style={{height: 25}}/>}
        text={'전화문의'}
        style={{justifyContent: 'start'}}
        onClick={() => {
          openCall(product.tel)
        }}
      />
    </BottomDrawerTemplate>
    <AppInstallGuideModal2
      show={showInstallModal}
      onClose={() => setShowInstallModal(false)}
    />
  </>

}

export default SavingInstallmentAccountProductDetailPage;