import { useNavigate } from "react-router-dom";
import IcSearch from '@assets/ic_search.svg';
import * as amplitude from '@amplitude/analytics-browser';
import { EVENT_SEARCHBAR_CLICK } from "./AppConstant";
import { grey999 } from "./Color";
import {RegularText_22} from "./text";

const SearchBox = () => {
    const navigate = useNavigate();
    function onClick() {
        amplitude.track(EVENT_SEARCHBAR_CLICK);
        navigate("search")
    }
    return (
        <div
            style={{
                borderRadius: '5px',
                height: '60%',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                padding: '18px 14px',
                border: `1px solid ${grey999}`
            }}
            onClick={onClick}
        >
            <IcSearch width={'24px'} height={'24px'}/>
            <RegularText_22>키워드로 검색</RegularText_22>
        </div>
    )
}

export default SearchBox;