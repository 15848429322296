import { useNavigate, useParams } from "react-router-dom";
import { BackNavToolbar } from "@components/Toolbars";
import IcZoom from '@assets/ic_zoom.svg';
import React, { ReactElement, RefObject, useEffect, useRef, useState } from "react";
import ActivityCard from "@src/features/job/component/ActivityCard";
import server_domain from "@components/RemoteServer";
import FirebaseImageLoader from "@components/FirebaseImageLoader";
import useOnLog from "@components/useOnLog";
import { EVENT_GOV_JOB_DETAIL_PAGE_SHOW } from "@components/AppConstant";
import { DefaultPageRoot } from "@components/PageTemplate";
import { useVirtualizer } from '@tanstack/react-virtual';
import { fetchWithRetry } from "@util/networks";
import { Activity } from "@model/Activity";
import govJobDetailPageLoader from "@src/data-layer/govJobDetailPageLoader";

const TabMode = {
    DETAIL: "DETAIL",
    LIST: "LIST",
}

const ActivityList = ({activities, listRef, noContentView}: { activities: Activity[], listRef: RefObject<HTMLDivElement>, noContentView: ReactElement}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const virtualizer = useVirtualizer({
        count: activities.length,
        getScrollElement: () => listRef.current,
        estimateSize: () => 200,
        overscan: 5,
        scrollMargin: containerRef.current?.offsetTop ?? 0,
    })
    const items = virtualizer.getVirtualItems()
    
    if (activities.length === 0) {
        return noContentView
    }
    return (
        <div
            ref={containerRef}
            style={{
                height: `${virtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
            }}
        >
            {items.map((item) => (
                <div
                    key={item.key}
                    ref={virtualizer.measureElement}
                    data-index={item.index}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${item.start - virtualizer.options.scrollMargin}px)`,
                    }}
                >
                    <ActivityCard activity={activities[item.index]} sessionId={"sessionId"} location={"GovJobDetailPage"} last={item.index === (activities.length - 1)} regions={null} />
                </div>
            ))}
        </div>
    )
}

const GovJobDetailView = () => {
    const listRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate();
    const { govJobId } = useParams();
    const [tab, setTab] = useState(TabMode.DETAIL);
    const [project, setProject] = useState<GovProject | null>(null);
    const [activities, setActivities] = useState<Activity[]>([]);

    function loadProject() {
        fetchWithRetry(server_domain() + "/project?id=" + govJobId, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(json => { setProject(json) })
    }

    function loadActivities() {
        if (!govJobId) return;

        govJobDetailPageLoader(govJobId)
            .then((result) => { setActivities(result) })
    }

    const moveToZoom = () => {
        navigate("zoom_in", {
            state: {
                'paths': project?.images?.split(",").map(image => `project/${image}`),
                "title": "사업개요 크게보기",
            }
        })
    }

    useEffect(() => {
        if (project == null) {
            loadProject()
        }
    }, [project])

    useOnLog({
        pageNameKey: EVENT_GOV_JOB_DETAIL_PAGE_SHOW,
        amplitudeProp: {
            "gov_job_id": govJobId
        },
        regStr: "\/gov_job\/[^/]+$"
    })


    useEffect(() => {
        if (tab === TabMode.LIST) {
            loadActivities()
        }
    }, [tab])

    const noContentView = (
        <p style={{ textAlign: 'center', marginTop: '40px' }}>
            현재 채용 중인 공고가 없습니다.
        </p>
    )

    return (
        <DefaultPageRoot
            top={<BackNavToolbar title={"24년 정부지원 일자리사업"} />}
            middleRef={listRef}
            middle={<>
                {
                    project &&
                    <div style={{ padding: '30px 16px 20px' }}>
                        <p style={{ margin: '10px 0 10px 0', fontSize: '1.25rem', fontWeight: 500 }}>{project.title}</p>
                        <table style={{ borderSpacing: '0px 10px' }}>
                            <colgroup>
                                <col width={85} />
                                <col width={15} />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: 'top', marginBottom: '10px' }}><span style={{ fontSize: '0.875rem', color: '#474D5B' }}>모집지역</span></td>
                                    <td colSpan={2} style={{ verticalAlign: 'top' }}>{project.region}</td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: 'top' }}><span style={{ fontSize: '0.875rem', color: '#474D5B' }}>모집기간</span></td>
                                    <td colSpan={2} style={{ verticalAlign: 'top' }}>{project.period}</td>
                                </tr>
                                {
                                    project.qualification.split("\n").map((line, index) => (
                                        <tr key={index}>
                                            {index == 0 && <td rowSpan={project.qualification.length} style={{ verticalAlign: 'top' }}><span style={{ fontSize: '0.875rem', color: '#474D5B' }}>지원자격</span></td>}
                                            <td style={{ verticalAlign: 'top' }}>-</td>
                                            <td>{line}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                }
                <div style={{ margin: '0px 0 0', height: '16px', minHeight: '16px', borderTop: '1px solid #D7D8DA', background: '#F4F4F4' }}></div>

                <div style={{ display: 'flex', borderBottom: '1px solid #D7D8DA' }}>
                    <span style={{ padding: '20px 0', fontSize: '1.125rem', flex: 1, textAlign: 'center', borderBottom: tab == TabMode.DETAIL ? '3px solid #0ABF53' : 'none', color: tab == TabMode.DETAIL ? '#0ABF53' : '#666666', fontWeight: 500 }} onClick={() => setTab(TabMode.DETAIL)}>사업개요</span>
                    <span style={{ padding: '20px 0', fontSize: '1.125rem', flex: 1, textAlign: 'center', borderBottom: tab == TabMode.LIST ? '3px solid #0ABF53' : 'none', color: tab == TabMode.LIST ? '#0ABF53' : '#666666', fontWeight: 500 }} onClick={() => setTab(TabMode.LIST)}>채용공고</span>
                </div>

                {
                    tab === TabMode.DETAIL ?
                    <div>
                        <p style={{ margin: '40px 0 10px 0', color: '#666', textAlign: 'center' }}>글자가 너무 작다면 크게 보세요!</p>
                        <div style={{ margin: '0 auto 40px', display: 'flex', alignItems: 'center', borderRadius: '30px', border: '1px solid black', justifyContent: 'center', width: 'fit-content', padding: '8px 24px' }} onClick={moveToZoom}>
                            <IcZoom width={15} height={15} style={{ marginRight: '5px' }} />
                            <span style={{ fontSize: '1.125rem', fontWeight: 500, marginLeft: '5px' }}>크게 보기</span>
                        </div>
                        {
                            !!(project?.images) &&
                            project.images.split(",").map((image: string, idx: number) => (
                                <FirebaseImageLoader path={`project/${image}`} key={idx}/>
                            ))
                        }
                        <div style={{ height: '60px' }}></div>
                    </div>
                    :
                    <div>
                        {
                            activities.length > 0 &&
                            <p style={{ margin: '40px 24px 16px', padding: '8px', textAlign: 'center', color: '#474D5B', background: '#F4F4F4', borderRadius: '5px' }}>
                                모든 지역의 공고가 노출됩니다
                            </p>
                        }
                        { activities && <ActivityList activities={activities} listRef={listRef} noContentView={noContentView}/> }
                    </div>
                }
            </>}
        />
    )
}

export default GovJobDetailView;