import { BackNavToolbar } from "@components/Toolbars";
import { useSelector } from "react-redux";
import { ForwardedRef, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import ActivityList from "@components/ActivityList";
import RegionCityButton from "@components/RegionCityButton";
import { AppInstallGuideBanner } from "@components/AppInstallGuideBanner";
import { DefaultPageRoot } from "@components/PageTemplate";
import { RootState } from "@reducer/store";
import { Activity } from "@model/Activity";
import activityPageLoader from "@src/data-layer/activityPageLoader";
import Filter from "@src/components/Filter";
import { useLocation, useNavigate } from "react-router-dom";
import NoContentView from "@components/NoContentView";
import useOnLog from "@src/components/useOnLog";
import { EVENT_ACTIVITY_LIST_PAGE_SHOW } from "@src/components/AppConstant";

const ActivityListView = () => {
    const navigate = useNavigate();
    const currentLocationState = useLocation().state
    const state = useMemo(() => currentLocationState, [null])
    const filterEnabled = state?.filterEnabled as boolean | undefined ?? true
    const title = state?.title as string | undefined ?? "상세 검색"
    const tagId = state?.tagId as string | undefined

    const regions = useSelector((state: RootState) => state.user.regions)
    const categoryIds = useSelector((state: RootState) => state.user.categoryIds)
    const workingTimeType = useSelector((state: RootState) => state.user.workingTimeType)
    const simpleApply = useSelector((state: RootState) => state.user.simpleApplyFilterOn)
    const [activities, setActivities] = useState<Activity[]>([])
    const [sessionId, setSessionId] = useState<string | null>(null)

    async function loadActivities() {
        try {
            let list: { activities: Activity[], sessionId: string }
            if (filterEnabled) {
                list = await activityPageLoader({ tagId: tagId, regions: regions, categoryIds: categoryIds, workingTimeType: workingTimeType, simpleApply: simpleApply })
            } else {
                list = await activityPageLoader({ tagId: tagId, regions: regions })
            }
            setActivities(list.activities)
            setSessionId(list.sessionId)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        loadActivities()
    }, [regions, categoryIds, workingTimeType, simpleApply]);

    useOnLog({
        pageNameKey: EVENT_ACTIVITY_LIST_PAGE_SHOW,
        amplitudeProp: filterEnabled ? {
            tagId: tagId,
            regions: regions,
            categoryIds: categoryIds,
            workingTimeType: workingTimeType,
            simpleApply: simpleApply,
        } : {
            tagId: tagId,
            regions: regions,
        },
        regStr: "\/activity_list$"
    })

    return (
        <DefaultPageRoot
            top={<>
                <AppInstallGuideBanner />
                <BackNavToolbar title={title} />
                <RegionCityButton location={"ActivityListView"} style={{ padding: '0px 16px 16px 16px' }} />
                {
                    filterEnabled &&
                    <Filter
                        filterCount={(categoryIds?.length ?? 0) + (!workingTimeType ? 0 : 1) + (simpleApply ? 1 : 0)}
                        margin="0px 16px 16px 16px"
                        onClick={() => navigate('filter')}
                    />
                }
                <div style={{ height: '1px', background: '#D9D9D9' }}></div>
            </>}
            middle={<>
                {activities && sessionId && <ActivityList activities={activities} location={"ActivityListView"} noContentView={<NoContentView filterEnabled={filterEnabled}/>} filterEnabled={filterEnabled} />}
            </>}
        />
    )
}

export default ActivityListView;