import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Description, HSelector, Indicator, TelField, TextArea, TextField, VSelector, YMDInput} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";
import React, {useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {clearEducation, EducationState, setEducation, setLastSelectedEducationOption} from "@reducer/userSlice";
import uuidv4 from "@util/uuidv4";
import useOnLog from "@components/useOnLog";
import {EVENT_CREATE_RESUME_HIGHEST_EDUCATION_PAGE_SHOW} from "@components/AppConstant";

const navigationMap: { [key: string]: string } = {
  "phd": 'create_resume_education_phd',
  "master": 'create_resume_education_master',
  "bachelor": 'create_resume_education_bachelor',
  "high_school": 'create_resume_education_high_school',
  "middle_under": "create_resume_check_career"
}

const options = [
  {key: "phd", label: "대학원 박사"},
  {key: "master", label: "대학원 석사"},
  {key: "bachelor", label: "대학교"},
  {key: "high_school", label: "고등학교"},
  {key: "middle_under", label: "중학교 이하"}
]

const HighestEducation = () => {
  const dispatch = useDispatch();
  const state = useLocation().state
  const step = useMemo(() => state?.step || 0, [null])
  const navigate = useNavigate();
  const education = useSelector((state: RootState) => state.user.resume.education)
  const lastSelectedEducationOption = useSelector((state: RootState) => state.user.resume.lastSelectedEducationOption)

  useOnLog({
    pageNameKey: EVENT_CREATE_RESUME_HIGHEST_EDUCATION_PAGE_SHOW,
    regStr: "\/create_resume_highest_education$"
  })

  const next = () => {
    if (lastSelectedEducationOption === "middle_under") {
      dispatch(setEducation({
        uuid: uuidv4(),
        name: "중학교 이하",
        major: "",
        status: "",
        startDate: "",
        endDate: "",
        degree: "middle_under"
      }))
    } else if (education.length > 0) {
      if (education[0].degree === lastSelectedEducationOption) {

      } else {
        dispatch(clearEducation())
      }
    }
    navigate('../' + navigationMap[lastSelectedEducationOption], {state: {step: step+1}})
  }
  const available = () => !!lastSelectedEducationOption

  return <DefaultPageRoot
    top={<BackNavToolbar title={""}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <Indicator label={"학력정보"} max={6} index={2} />
      <Description text={"최종 학력을 알려주세요"}/>
      <VSelector onChange={(option) => dispatch(setLastSelectedEducationOption(option))} selected={lastSelectedEducationOption} options={options}/>
    </div>}
    bottom={<div style={{padding: 16}}>
      <FillButton
        active={available()}
        text={"다음"}
        onClick={next}
      />
    </div>}
  />
}

export default HighestEducation;