import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {FinanceProductList} from "@src/features/finance/features/saving_installment_account/model/FinanceProductList";
import SavingInstallmentProductsPageTemplate from "@src/features/finance/features/saving_installment_account/component/SavingInstallmentProductsPageTemplate";

const InstallmentSavingAccountProductsPage = () => {
  const data = FinanceProductList.filter((product) => product.type === 'installment_saving_account');
  return <SavingInstallmentProductsPageTemplate title={"적금 상품"} products={data}/>
}

export default InstallmentSavingAccountProductsPage;