import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import {FillButton, IconFillButton, SecondaryFillButton} from "@components/Buttons";
import {Indicator, removeZeroPad, SalaryInput, YMInput} from "@components/form/ResumeComponent";
import IcLightBulb from "@assets/ic_small_lightbulb.webp";
import ICQuestionMark from "@assets/ic_small_question_mark.webp";
import IcReset from "@assets/ic_reset.svg";
import IcShare from "@assets/ic_share_white.webp";
import IcNotice from "@assets/ic_small_notice.webp";
import LoadingModal from "@src/features/job/component/LoadingModal";
import Table from "@components/Table";
import {DividerThin} from "@components/DivideBar";
import {StepTitleStyle} from "@src/features/pension_calculator/style/style";
import {useBlocker, useNavigate, useSearchParams} from "react-router-dom";
import calculatePension from "@src/features/pension_calculator/util/CalculatePension";
import {DialogDrawerTemplate} from "@components/DrawerTemplate";
import {ShareBottomSheet} from "@components/ShareComponent";
import trackEvent from "@util/trackEvent";
import {EVENT_PENSION_CALC_NEXT_CLICK, EVENT_PENSION_CALC_RESET_CLICK} from "@components/AppConstant";
import {AppInstallGuideBanner} from "@components/AppInstallGuideBanner";
import AppInstallGuideModal2 from "@components/AppInstallGuideModal2";
import {OdoMeter} from "@components/odometer/OdoMeter";
import {Caution, Notice, Question} from "@components/Notice";
import {calculateManAgeDate} from "@src/features/age_calculator/util/CalculateAge";
import {toDate, toYM} from "@util/DateHelper";
import NoticeBanner from "@features/job/component/NoticeBanner";



const Intro = () => {
  return <div style={{padding: 16}}>
    <NoticeBanner text={"고객님의 소중한 개인정보는 수집하지 않습니다."} title={"안심하세요!"} />
    <p style={{whiteSpace: 'pre-wrap', fontSize: '1.25rem', lineHeight: 1.5, margin: '16px 0', fontWeight: 700}}>{"총 3단계의 정보만\n입력하면 계산 완료!"}</p>
    <p style={{whiteSpace: 'pre-wrap', fontSize: '1.125rem', lineHeight: 1.5, margin: '16px 0',}}>{"국민연금 예상수령액을\n국민연금공단 계산 방식 그대로\n가장 정확하게 계산해드립니다"}</p>
  </div>
}

const Step1 = ({data, onChange}: { data: Pension, onChange: (data: object) => void }) => {
  return <div style={{padding: 16}}>
    <Indicator label={"출생정보"} max={3} index={1}/>
    <NoticeBanner text={"고객님의 소중한 개인정보는 수집하지 않습니다."} title={"안심하세요!"} style={{marginBottom: 16}} />
    <p style={StepTitleStyle}>출생연월을 알려주세요</p>
    <YMInput label={"출생연월"} init={data.birth} onChange={(text) => onChange({'birth': text})} placeHolder={"196302"}/>
    <div style={{background: '#F4F4F4', borderRadius: 5, padding: '8px 8px 8px', marginTop: 40}}>
      <Caution title={"출생연월이 왜 필요한가요?"}>
        <p style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 0px'}}>{"출생연월에 따라 개인별 연금 개시 연월이 다릅니다. 고객님의 보편적인 연금 개시 연월을 계산해드릴게요."}</p>
        <p style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 0px', whiteSpace: 'pre-wrap'}}>{"~ 1952년생: 만 60세\n1953 ~ 1956년생: 만 61세\n1957 ~ 1960년생: 만 62세\n1961 ~ 1964년생: 만 63세\n1965 ~ 1968년생: 만 64세\n1969년생 ~: 만 65세"}</p>
      </Caution>
    </div>
  </div>
}

const Step2 = ({data, onChange}: { data: Pension, onChange: (data: object) => void }) => {
  return <div style={{padding: 16}}>
    <Indicator label={"가입정보"} max={3} index={2}/>
    <NoticeBanner text={"고객님의 소중한 개인정보는 수집하지 않습니다."} title={"안심하세요!"} style={{marginBottom: 16}} />
    <p style={StepTitleStyle}>{"국민연금에 가입한 날짜와\n납입을 종료한 날짜를 알려주세요"}</p>
    <YMInput label={"가입연월"} init={data.start} onChange={(text) => onChange({'start': text})} placeHolder={"198902"}/>
    <YMInput label={"종료연월"} init={data.end} onChange={(text) => onChange({'end': text})} placeHolder={"202011"}/>
    <div style={{background: '#F4F4F4', borderRadius: 5, padding: 8, marginTop: 40}}>
      <Caution title={"가입/종료 날짜가 왜 필요한가요?"}>
        <p style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px'}}>{"국민연금 총 납부 개월수를 파악해야 \n예상수령금액을 계산할 수 있습니다."}</p>
      </Caution>
      <Question title={"가입/종료 날짜를 모르신다면?"}>
        <p style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 0px'}}>{"대략적인 첫 회사 입사연월과\n마지막 회사 퇴사연월을 알려주세요.\n위즈덤이 똑똑하게 계산해드릴게요!"}</p>
      </Question>
    </div>
  </div>
}

const Step3 = ({data, onChange}: { data: Pension, onChange: (data: object) => void }) => {

  const _onChange = (text: string) => {
    onChange({'salary': text})
  }

  return <div style={{padding: 16}}>
    <Indicator label={"급여정보"} max={3} index={3}/>
    <NoticeBanner text={"고객님의 소중한 개인정보는 수집하지 않습니다."} title={"안심하세요!"} style={{marginBottom: 16}} />
    <p style={StepTitleStyle}>{"앞서 입력한 기간의 \n평균 월 소득액을 알려주세요"}</p>
    <SalaryInput label={"평균월소득"} value={data.salary} onChange={_onChange}/>
    <div style={{background: '#F4F4F4', borderRadius: 5, padding: 8, marginTop: 40}}>
      <Caution title={"평균월소득액이 왜 필요한가요?\n30년 동안의 급여가 많이 다른데요?"}>
        <p style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px'}}>{"국민연금공단의 실제 계산 방식에 활용되며,\n가입전체기간의 평균값을 이용하여\n가입기간내 물가상승을 보정할 수 있습니다."}</p>
      </Caution>
      <Question title={"정확한 금액을 모르신다면?"}>
        <p style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 0px'}}>{"정확한 가입기간내 평균월소득은\n국민연금공단에 문의하여 알 수 있지만,\n대략적인 금액을 입력하셔도\n위즈덤이 똑똑하게 계산해드릴게요!"}</p>
      </Question>
    </div>
  </div>
}

const StepResult = ({data}: { data: Pension }) => {
  const [result, setResult] = useState<ExpectedPension>()

  useEffect(() => {
    const calculated = calculatePension(data)
    setResult(calculated)
  }, []);

  if ((result?.months || 0) < 120) {
    return <>
      <div style={{padding: 16}}>
        <p style={{fontSize: '1.75rem', fontWeight: 700, margin: 0}}>반환일시금</p>
        <p style={{fontSize: '1.125rem', fontWeight: 700, margin: '0px 0 20px', textAlign: 'center'}}>(총납입기간 10년 미만 가입자 해당)</p>
        <p style={{fontSize: '1.5rem', fontWeight: 700, margin: 0, textAlign: 'center'}}>총납부보험료 + 이자</p>
      </div>
      <DividerThin/>
      <div style={{padding: 16}}>
        <Table
          title={"국민연금 가입 정보"}
          data={[
            {
              key: '총납입월수',
              value : (result?.months || 0) + ' 개월',
            }
          ]}
        />
      </div>
      <div style={{background: '#EEF9F2', borderRadius: 5, padding: 8, margin: '0 16px'}}>
        <Caution title={"이렇게 해석하시면 돼요!"}>
          <ol style={{fontSize: '0.875rem', lineHeight: 1.5}}>
            <li><span>총납입기간이 10년(120개월) 미만일 경우 연금을 받을 수 없습니다.</span></li>
            <li><span>만 65세까지 추가 납입(임의계속가입)하여 총납입기간 10년을 채우면 연금을 받을 수 있습니다.</span></li>
            <li><span>임의계속가입이 불가능하거나 연금을 원하지 않으시면, 만 60세 이후 반환일시금을 택하여 그동안 납부한 보험료에 이자를 더해 받을 수 있습니다.</span></li>
            <li><span>반환일시금은 10년 내에 신청하여 받아야합니다.</span></li>
            <li><span>만 60세 미만의 경우 사망, 국적 상실, 국외 이주의 경우에만 반환일시금 신청이 가능하며 5년 내에 신청하여 받아야합니다.</span></li>
          </ol>
        </Caution>
      </div>
    </>
  }

  return <>
    <div style={{padding: 16}}>
      <p style={{fontSize: '1.75rem', fontWeight: 700, margin: 0}}>예상 수령금액<span style={{fontSize: '1.25rem'}}>(세전)</span></p>
      <div style={{display: 'flex', alignItems: 'center', fontSize: '1.5rem', fontWeight: 700, margin: '20px 0', textAlign: 'center', justifyContent: 'center'}}>
        <span>월&nbsp;</span>
        <OdoMeter value={Math.floor((result?.annualAmount || 0) / 12)} duration={1000} size="1.5rem" color="black"/>
        <span>원</span>
      </div>
      <p style={{fontSize: '1.125rem', fontWeight: 700, margin: 0, textAlign: 'center'}}>연 {Math.floor(result?.annualAmount || 0).toLocaleString("ko-KR")}원</p>
    </div>
    <DividerThin/>
    <div style={{padding: 16}}>
      <Table
        title={"국민연금 가입 정보"}
        data={[
          {
            key: '연금개시연월',
            value : `${result?.pensionStartDate.substring(0, 4)}년 ${removeZeroPad(result?.pensionStartDate.substring(4, 6) || "")}월`,
          },
          {
            key: '총납입월수',
            value: (result?.months || 0) + ' 개월'
          },
          {
            key: '평균월소득',
            value: data.salary + ' 만원'
          },
        ]}
      />
    </div>
    <div style={{background: '#EEF9F2', borderRadius: 5, padding: 8, margin: '0 16px'}}>
      <Caution title={"이렇게 해석하시면 돼요!"}>
        <ol style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 20px 0px'}}>
          <li><span>이 금액은 {result?.pensionStartDate.substring(0, 4)}년 {removeZeroPad(result?.pensionStartDate.substring(4, 6) || "")}월 물가 기준의 금액으로, 다음 해부터는 물가상승률이 반영되어 인상된 금액을 받습니다.</span></li>
          <li><span>평균월소득이 {parseInt(data.salary) > 30 && `${parseInt(data.salary) - 30}만원일 경우 월 ${Math.floor((result?.annualAmountMinus30 || 0) / 12).toLocaleString("ko-KR")}원, `}{parseInt(data.salary) + 30}만원일 경우 월 {Math.floor((result?.annualAmountPlus30 || 0) / 12).toLocaleString("ko-KR")}원 수령이 예상됩니다.</span></li>
          <li><span>국민연금제도는 1988년 1월에 시작하였고, 1988년 1월 이전 근무기간은 납입월수에서 제외했습니다.</span></li>
          <li><span>국민연금은 기본적으로 만 60세까지 납부할 수 있고 본 계산기는 추가납부를 반영하지 않았습니다.</span></li>
        </ol>
      </Caution>
      <Notice title={"기능 개선 중입니다! 기다려주세요!"}>
        <ul style={{fontSize: '0.875rem', lineHeight: 1.5, margin: '10px 30px 0 0px'}}>
          <li><span>국민연금 미납부 기간을 반영할 수 있어요.</span></li>
          <li><span>만 65세까지 가능한 추가납부를 반영할 수 있어요.</span></li>
          <li><span>연금개시연월을 조정하여 계산할 수 있어요.(조기수령, 연기연금)</span></li>
          <li><span>크레딧제도(출산, 군복무 및 실업에 대해 연금 가입기간 추가 인정)까지 계산에 반영할 수 있어요.</span></li>
          <li><span>부양가족연금액까지 계산할 수 있어요.</span></li>
        </ul>
      </Notice>
    </div>
  </>
}

let escape = false

const NextPath :{[key: string]: string} = {
  intro: "step1",
  step1: "step2",
  step2: "step3",
  step3: "result"
}

let timer: ReturnType<typeof setTimeout> | null = null;

const PensionCalculatorPage = () => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null)
  const [showExitModal, setShowExitModal] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [showInstallModal, setShowInstallModal] = useState(searchParams.get("show_install_guide") === 'true')
  const [path, setPath] = useState(["intro"])
  const [data, setData] = useState<Pension>({
    birth: "",
    start: "",
    end: "",
    salary: ""
  })

  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (escape) {
      escape = false
      return false
    }

    if (showExitModal) {
      setShowExitModal(false)
      return true
    }

    if (showShareModal) {
      setShowShareModal(false)
      return true
    }

    const last = path[path.length - 1]
    if (last === "intro") return false
    else if (last === "step3") {
      if (timer) {
        clearTimeout(timer);
        timer = null;
        setShowLoading(false)
        return true
      } else {
        path.pop()
        setPath([...path])
        return true
      }
    }
    else if (last === "result") {
      setShowExitModal(true)
      return true
    } else {
      path.pop()
      setPath([...path])
      return true
    }
  });

  const onChange = (newData: object) => {
    setData({...data, ...newData})
  }

  const onShare = () => {
    setShowShareModal(true)
  }

  const renderMiddle = () => {
    const current = path[path.length - 1]
    switch (current) {
      case "intro":
        return <Intro key={'intro'}/>
      case "step1":
        return <Step1 key={'step1'} data={data} onChange={onChange}/>
      case "step2":
        return <Step2 key={'step2'} data={data} onChange={onChange}/>
      case "step3":
        return <Step3 key={'step3'} data={data} onChange={onChange}/>
      case "result":
        return <StepResult key={'result'} data={data}/>
    }
  }

  const renderBottom = () => {
    switch (path[path.length - 1]) {
      case "intro":
        return <FillButton text={"시작하기"} onClick={onNext}/>
      case "step1":
        return <FillButton text={"다음"} onClick={onNext} active={data.birth !== ""}/>
      case "step2":
        const calculatedManAgeDate = toYM(calculateManAgeDate(data.birth, 65))
        console.log(calculatedManAgeDate)
        return <FillButton text={"다음"} onClick={onNext} active={data.start !== "" && data.end !== "" && data.start <= data.end && calculatedManAgeDate >= data.start }/>
      case "step3":
        return <FillButton text={"계산하기"} onClick={onNext} active={data.salary !== ""}/>
      case "result":
        return <div style={{display: 'flex', gap: 16}}>
          <IconFillButton icon={<IcReset style={{height: 30}}/>} text={"다시하기"} onClick={onReset} style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}}/>
          <IconFillButton icon={<img src={IcShare} alt={""} style={{height: 30}}/>} text={"추천하기"} onClick={onShare}/>
        </div>
    }
    return <FillButton text={"시작하기"} onClick={onNext}/>
  }

  const onReset = () => {
    const calculated = calculatePension(data)
    trackEvent(EVENT_PENSION_CALC_RESET_CLICK, {
      lump_sum: calculated.months < 120,
    })
    setData({
      birth: "",
      start: "",
      end: "",
      salary: ""
    })
    setPath(["intro", "step1"])
  }

  const onNext = () => {
    const current = path[path.length - 1]
    trackEvent(EVENT_PENSION_CALC_NEXT_CLICK, {current_step: current})
    const nextPath = NextPath[current]
    let newPath: string[] = [...path, nextPath]
    if (nextPath === "result") {
      setShowLoading(true)
      timer = setTimeout(() => {
        setShowLoading(false)
        setPath(newPath)
      }, 2000)
    } else {
      setPath(newPath)
    }
  }

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTo(0, 0)
    }
  }, [path]);

  return <>
    <DefaultPageRoot
      top={<>
        <AppInstallGuideBanner />
        <BackNavToolbar title={"국민연금 계산기"}/>
      </>}
      middleRef={ref}
      middle={renderMiddle()}
      bottom={<div style={{margin: 16}}>
        {renderBottom()}
      </div>}
    />
    <DialogDrawerTemplate show={showExitModal} onClose={() => setShowExitModal(false)}>
      <p style={{textAlign: 'center'}}>국민연금 계산기를 종료합니다.</p>
      <div style={{display: 'flex', gap: 16}}>
        <SecondaryFillButton text={"취소"} onClick={() => setShowExitModal(false)}/>
        <FillButton text={"종료하기"} onClick={() => {
          escape = true;
          navigate(-1)
        }}/>
      </div>
    </DialogDrawerTemplate>
    <ShareBottomSheet
      showModal={showShareModal}
      title={"국민연금계산기 추천하기"}
      content={"계산 결과는 공유되지 않으며, 국민연금계산기만 공유됩니다."}
      onClose={() => setShowShareModal(false)}
      props={{
        kakao_share_title: "카카오톡으로 공유하기",
        link_copy_title: "공유 내용 복사하기",
        amplitude_props: {location: "pension_calculator"},
        templateId: 109448,
        templateArgs: {
          "title": "(무료) 가장 정확한 국민연금 계산기를 공유합니다.",
          "description": "은퇴 후 필수 앱, 위즈덤",
          "thumbnail_url": "https://app.wizdom.co.kr/img/img_og_image.webp",
          "cta": "국민연금 계산하기",
          "url": import.meta.env.VITE_URL,
          "url_path": "/pension_calculator?show_install_banner=true&show_install_guide=true&back_to_home=true&referrer=utm_source%3Dpensioncalc%26utm_campaign%3Dsharing%26utm_content%3Dwizdomutil"
        },
        toastText: "복사되었습니다.\n붙여넣기 하여 공유해주세요.",
        url: "[위즈덤]\n국민연금 계산기\n\n국민연금 예상수령액을\n국민연금공단 계산 방식 그대로\n가장 정확하게 계산해드립니다.\n\n" + import.meta.env.VITE_URL + "/pension_calculator?show_install_banner=true&show_install_guide=true&back_to_home=true&referrer=utm_source%3Dpensioncalc%26utm_campaign%3Dsharing%26utm_content%3Dwizdomutil"
      }}
    />
    <AppInstallGuideModal2
      show={showInstallModal}
      onClose={() => setShowInstallModal(false)}
    />
    {
      showLoading &&
      <LoadingModal>
          <p style={{textAlign: 'center', fontWeight: 500, margin: 0, lineHeight: 1.5, whiteSpace: 'pre-wrap'}}>{"국민연금 예상수령금액을\n계산 중입니다.\n잠시만 기다려주세요."}</p>
      </LoadingModal>
    }

  </>
}

export default PensionCalculatorPage;