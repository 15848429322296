import React, {ChangeEvent, CSSProperties, useEffect, useMemo, useRef, useState} from "react";
import IcArrowDown from "@assets/arrow_down.svg";
import {BottomDrawerTemplate, DialogDrawerTemplate} from "@components/DrawerTemplate";
import {useNavigate} from "react-router-dom";
import RequiredMark from "@components/RequiredMark";
import {CareerState, CertificateState, EducationState, removeCareerTemp, removeCertificateTemp, removeEducationTemp, ResumeCardState, ResumeState} from "@reducer/userSlice";
import {primary} from "@components/Color";
import IcCheckMark from "@assets/ic_check_mark.svg";
import trackEvent from "@util/trackEvent";
import {EVENT_ACTIVITY_CARD_IMPRESSED, EVENT_RESUME_EDIT, EVENT_RESUME_PREVIEW, EVENT_SIMPLE_APPLY_CHANGE_RESUME_CLICK} from "@components/AppConstant";
import IcCheckboxOn from "@assets/ic_checkbox_on.svg";
import IcCheckboxOff from "@assets/ic_checkbox_off.svg";
import {FillButton} from "@components/Buttons";
import styles from '@src/features/job/features/apply/style/input.module.css'
import useAutoBlurObserver from "@components/useAutoBlurObserver";

const KeyboardTime = 600;
const AutoBlurTime = 2000;

const InputStyle: CSSProperties = {
  border: '1px solid #D9D9D9',
  borderRadius: 5,
  padding: 10,
  lineHeight: 1.5,
  fontSize: '1rem',
  boxSizing: 'border-box'
}


const ContainerStyle: CSSProperties = {
  padding: '10px 0',
  display: 'flex',
  alignItems: 'normal',
}


const LabelStyle: CSSProperties = {
  minWidth: 100,
  width: 100,
  paddingTop: 11,
  lineHeight: 1.5,
  fontWeight: 500
}

const SubLabelStyle: CSSProperties = {
  paddingTop: 11,
  lineHeight: 1.5,
}


const NumberInputStyle: CSSProperties = {
  border: '1px solid #D9D9D9',
  borderRadius: 5,
  padding: 10,
  lineHeight: 1.5,
  fontSize: '1rem',
  boxSizing: 'border-box',
  textAlign: 'right'
}


const TextAreaStyle: CSSProperties = {
  border: '1px solid #B2B2B2',
  borderRadius: 5,
  width: '100%',
  padding: 10,
  fontSize: '1rem',
  boxSizing: 'border-box',
  resize: 'none',
  lineHeight: 1.5,
  verticalAlign: 'top',
}

const Button = ({label, checked, onClick}: { label: string, checked: boolean, onClick: () => void }) => {
  let defaultStyle = {background: 'white', border: '1px solid #999', color: 'black'}
  if (checked) {
    defaultStyle = {background: '#EEF9F2', border: '1px solid #0ABF53', color: checked ? '#0ABF53' : 'white'}
  }

  return (
    <div style={{...ContainerStyle}}>
      <button style={{...defaultStyle, width: '100%', borderRadius: 5, padding: 16}} onClick={onClick}>
        <span style={{fontSize: '1rem'}}>{label}</span>
      </button>
    </div>
  )
}

const NavigateButton = ({label, to}: { label: string, to: string }) => {
  const navigate = useNavigate();

  const onClick = () => navigate(to);

  return (
    <div style={{...ContainerStyle}}>
      <button style={{background: 'white', border: '1px solid #999', color: 'black', width: '100%', borderRadius: 5, padding: 16}} onClick={onClick}>
        <span style={{fontSize: '1rem'}}>{label}</span>
      </button>
    </div>
  )
}

const TextField = ({label, placeholder, value, onChange, max, onFocus, highlight = false, showRequiredMark = false}: { label: string, placeholder: string, value: string, onFocus?: (top: number) => void, onChange: (e: string) => void, max?: number, highlight?: boolean, showRequiredMark?: boolean }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const observer = useAutoBlurObserver(inputRef);
  const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => inputRef.current?.scrollIntoView({behavior: "smooth"}), KeyboardTime)
    setTimeout(() => {if(inputRef.current) {observer.observe(inputRef.current)}}, AutoBlurTime)
  }

  const _onBlur = () => observer.disconnect()

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === " ") {
      onChange("")
    // } else if (/\s\s+/.test(e.target.value)) {
    } else if (max && e.target.value.length > max) {
    } else {
      onChange(e.target.value)
    }
    // } else if (/^[ㄱ-ㅎ가-힣a-zA-Z0-9\s]*$/.test(e.target.value)) {
    // }
  }

  return (
    <div style={ContainerStyle}>
      <span style={LabelStyle}>{label}{showRequiredMark && <RequiredMark/>}</span>
      <div style={{flex: 1}}>
        <input className={styles.input} ref={inputRef} type="text" placeholder={placeholder} value={value} onChange={_onChange} style={{width: '100%', ...InputStyle}} onFocus={_onFocus} onBlur={_onBlur}/>
        {highlight && <p style={{margin: '8px 0 0', padding: '5px 0 0', color: '#F15D47', fontSize: '0.875rem', borderTop: '1px solid #F15D47'}}>필수로 입력해주세요</p>}
      </div>
    </div>
  )
}

const Checkbox = ({checked, onChange}: { checked: boolean, onChange: (checked: boolean) => void }) => {
  return <div style={{width: 18, height: 18, marginRight: 8, borderRadius: 5, display: 'flex', alignItems: 'center'}} onClick={_ => onChange(!checked)}>
    {checked ? <IcCheckboxOn/> : <IcCheckboxOff/>}
  </div>
}

const RadioButtonGroup = ({options, value, onChange, children}: { options: any[], value: any, onChange: (option: any) => void, children: (option: any) => React.ReactNode }) => {
  const onClick = (option: any) => {
    onChange(option)
  }

  return options.map(option => <RadioButton checked={value === option} onClick={() => onClick(option)}>
    {children(option)}
  </RadioButton>)
}

const RadioButton = ({checked, onClick, children}: { checked: boolean, onClick: () => void, children: any }) => {
  return <div style={{display: 'flex', alignItems: 'center'}} onClick={onClick}>
    <div style={{position: 'relative', width: 16, height: 16, borderRadius: 10, background: checked ? '#0ABF53' : 'white', margin: '7px 10px 0 0', border: checked ? '1px solid transparent' : '1px solid #999'}}>
      <div style={{position: 'absolute', top: 4, left: 4, width: 8, height: 8, borderRadius: 15, background: 'white',}}/>
    </div>
    <div style={{flex: 1}}>{children}</div>
  </div>
}

const TextFieldCheckbox = ({label, placeholder, value, checkedText, onChange, highlight = false, showRequiredMark = false}: { label: string, placeholder: string, value: string, checkedText: string, onChange: (e: string) => void, highlight?: boolean, showRequiredMark?: boolean }) => {
  const [checked, setChecked] = useState(checkedText === value)

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (e.target.value === " ") {
    //   onChange("")
    // } else if (/\s\s+/.test(e.target.value)) {
    // } else if (/^[ㄱ-ㅎ가-힣a-zA-Z0-9\s]*$/.test(e.target.value)) {
    onChange(e.target.value)
    // }
  }

  function handle(newChecked: boolean) {
    onChange(newChecked ? checkedText : "")
    setChecked(newChecked)
  }

  return (
    <div style={ContainerStyle}>
      <span style={LabelStyle}>{label}{showRequiredMark && <RequiredMark/>}</span>
      <div style={{flex: 1}}>
        <input className={styles.input} readOnly={checked} type="text" placeholder={placeholder} value={value} onChange={_onChange} style={{width: '100%', ...InputStyle, background: checked ? '#F4F4F4' : 'white', color: checked ? "#666" : 'black'}}/>
        <div style={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
          <Checkbox checked={checked} onChange={handle}/>
          <span style={{color: '#474D5B'}}>{checkedText}</span>
        </div>
        {highlight && <p style={{margin: '8px 0 0', padding: '5px 0 0', color: '#F15D47', fontSize: '0.875rem', borderTop: '1px solid #F15D47'}}>필수로 입력해주세요</p>}
      </div>
    </div>
  )
}

const NumberField = ({value, onChange, digit, min, max, placeholder, unit, onFocus, onBlur, editable = true, inputRef}: { min?: number, max?: number, value: string, onChange: (text: string) => void, digit: number, placeholder: string, unit: string, onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void, onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void, editable?: boolean, inputRef?: React.Ref<HTMLInputElement> }) => {
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!/^\d*$/.test(e.target.value)) {
      return
    }
    if (e.target.value.length > digit) {
      return
    }
    if (max && parseInt(e.target.value) > max) {
      onChange(max.toString())
      return
    }
    if (min && parseInt(e.target.value) < min) {
      return
    }
    onChange(e.target.value)
  }
  return (<>
      <input className={styles.input} ref={inputRef} type="tel" disabled={!editable} placeholder={placeholder} value={value} onChange={_onChange} style={{width: `calc(22px + ${digit}ch)`, ...NumberInputStyle}} onFocus={onFocus} onBlur={onBlur}/>
      <span style={SubLabelStyle}>{unit}</span>
    </>
  )
}

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
const removeZeroPad = (str: string) => parseInt(str).toString()

const YMDInput = ({label, placeHolder = "19601231", init = "", onChange, onFocus, highlight = false, showRequiredMark = false}: { label: string, placeHolder?: string, init: string, onFocus?: (top:number) => void, onChange: (text: string) => void, highlight?: boolean, showRequiredMark?: boolean }) => {
  const [year, setYear] = useState(init ? parseInt(init.substring(0, 4)).toString() : "")
  const [month, setMonth] = useState(init ? parseInt(init.substring(4, 6)).toString() : "")
  const [day, setDay] = useState(init ? parseInt(init.substring(6, 8)).toString() : "")
  const [editableDay, setEditableDay] = useState(!!month)
  const inputRef = useRef<HTMLInputElement>(null);
  const observer = useAutoBlurObserver(inputRef);
  const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => inputRef.current?.scrollIntoView({behavior: "smooth"}), KeyboardTime)
    setTimeout(() => {if(inputRef.current) {observer.observe(inputRef.current)}}, AutoBlurTime)
  }

  const _onBlur = () => observer.disconnect()

  useEffect(() => {
    const date = new Date(parseInt(year), parseInt(month), 0);
    if (date.toString() === "Invalid Date") {
      setDay("")
      setMonth("")
      setEditableDay(false)
    } else {
      setEditableDay(true)
    }
    const maxDate = date.getDate()
    if (parseInt(day) > maxDate) {
      onChange("")
    } else {
      const birth = `${year}${zeroPad(parseInt(month), 2)}${zeroPad(parseInt(day), 2)}`
      onChange(birth.length === 8 ? birth : "")
    }
  }, [year, month, day])

  return (
    <div style={{...ContainerStyle}}>
      <span style={LabelStyle}>{label}{showRequiredMark && <RequiredMark/>}</span>
      <div>
        <div style={{flex: 1, display: 'flex', gap: 3}}>
          <NumberField onFocus={_onFocus} onBlur={_onBlur} inputRef={inputRef} unit={"년"} digit={4} placeholder={removeZeroPad(placeHolder.substring(0, 4) || "1960")} min={1} value={year} onChange={text => setYear(text)}/>
          <NumberField onFocus={_onFocus} onBlur={_onBlur} inputRef={inputRef} unit={"월"} digit={2} placeholder={removeZeroPad(placeHolder.substring(4, 6) || "12")} min={1} max={12} value={month} onChange={text => setMonth(text)}/>
          <NumberField onFocus={_onFocus} onBlur={_onBlur} inputRef={inputRef} editable={editableDay} unit={"일"} digit={2} placeholder={removeZeroPad(placeHolder.substring(6, 8) || "31")} min={1} value={day} onChange={text => setDay(text)}/>
        </div>
        {highlight && <p style={{margin: '8px 0 0', padding: '5px 0 0', color: '#F15D47', fontSize: '0.875rem', borderTop: '1px solid #F15D47'}}>필수로 입력해주세요</p>}
      </div>
    </div>
  )
}

const SalaryInput = ({label, placeHolder = "250", value, onChange, onFocus, highlight = false, showRequiredMark = false}: { label: string, placeHolder?: string, value: string, onFocus?: (top:number) => void, onChange: (text: string) => void, highlight?: boolean, showRequiredMark?: boolean }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const observer = useAutoBlurObserver(inputRef);
  const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => inputRef.current?.scrollIntoView({behavior: "smooth"}), KeyboardTime)
    setTimeout(() => {if(inputRef.current) {observer.observe(inputRef.current)}}, AutoBlurTime)
  }

  const _onBlur = () => observer.disconnect()

  return (
    <div style={{...ContainerStyle}}>
      <span style={LabelStyle}>{label}{showRequiredMark && <RequiredMark/>}</span>
      <div>
        <div style={{flex: 1, display: 'flex', gap: 3}}>
          <NumberField onFocus={_onFocus} onBlur={_onBlur} inputRef={inputRef} unit={"만원"} digit={4} placeholder={placeHolder} min={1} value={value} onChange={text => onChange(text)}/>
        </div>
        {highlight && <p style={{margin: '8px 0 0', padding: '5px 0 0', color: '#F15D47', fontSize: '0.875rem', borderTop: '1px solid #F15D47'}}>필수로 입력해주세요</p>}
      </div>
    </div>
  )
}


const YMDInput2 = ({label, placeHolder = "19601231", value = "", onChange, onFocus, highlight = false, showRequiredMark = false}: { label: string, placeHolder?: string, value: string, onFocus?: (top:number) => void, onChange: (text: string) => void, highlight?: boolean, showRequiredMark?: boolean }) => {
  const [year, setYear] = useState(value ? parseInt(value.substring(0, 4)).toString() : "")
  const [month, setMonth] = useState(value ? parseInt(value.substring(4, 6)).toString() : "")
  const [day, setDay] = useState(value ? parseInt(value.substring(6, 8)).toString() : "")

  const [editableDay, setEditableDay] = useState(!!month)
  const inputRef = useRef<HTMLInputElement>(null);
  const observer = useAutoBlurObserver(inputRef);
  const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => inputRef.current?.scrollIntoView({behavior: "smooth"}), KeyboardTime)
    setTimeout(() => {if(inputRef.current) {observer.observe(inputRef.current)}}, AutoBlurTime)
  }

  const _onBlur = () => observer.disconnect()

  useEffect(() => {
    if (value === "00000000") {
      setYear("")
      setMonth("")
      setDay("")
    }
  }, [value])


  useEffect(() => {
    if (value === "") {
      onChange("00000000")
      return
    }
    const a = parseInt(year) ? zeroPad(parseInt(year), 4) : "0000"
    const b = parseInt(month) ? zeroPad(parseInt(month), 2) : "00"

    if (parseInt(year) && parseInt(month)) {
      const date = new Date(parseInt(year), parseInt(month), 0);
      if (date.toString() === "Invalid Date") {
      } else {
        setEditableDay(true)
      }
    } else {
      setDay("")
      setEditableDay(false)
    }
    const c = parseInt(day) ? zeroPad(parseInt(day), 2) : "00"
    onChange(a + b + c)
  }, [year, month, day])

  const parseDate = (start: number, end: number) => {
    const parsed = parseInt(value.substring(start, end))
    if (parsed) {
      return parsed.toString()
    } else {
      return ""
    }
  }

  return (
    <div style={{...ContainerStyle}}>
      <span style={LabelStyle}>{label}{showRequiredMark && <RequiredMark/>}</span>
      <div>
        <div style={{flex: 1, display: 'flex', gap: 3}}>
          <NumberField onFocus={_onFocus} onBlur={_onBlur} inputRef={inputRef} unit={"년"} digit={4} placeholder={removeZeroPad(placeHolder.substring(0, 4) || "1960")} min={1} value={parseDate(0, 4)} onChange={text => setYear(text)}/>
          <NumberField onFocus={_onFocus} onBlur={_onBlur} inputRef={inputRef} unit={"월"} digit={2} placeholder={removeZeroPad(placeHolder.substring(4, 6) || "12")} min={1} max={12} value={parseDate(4, 6)} onChange={text => setMonth(text)}/>
          <NumberField onFocus={_onFocus} onBlur={_onBlur} inputRef={inputRef} editable={editableDay} unit={"일"} digit={2} placeholder={removeZeroPad(placeHolder.substring(6, 8) || "31")} min={1} value={parseDate(6, 8)} onChange={text => setDay(text)}/>
        </div>
        {highlight && <p style={{margin: '8px 0 0', padding: '5px 0 0', color: '#F15D47', fontSize: '0.875rem', borderTop: '1px solid #F15D47'}}>필수로 입력해주세요</p>}
      </div>
    </div>
  )
}

const YMInput = ({label, placeHolder = "196012", init = "", onChange, highlight = false, showRequiredMark = false}: { label: string, placeHolder?: string, init: string, onChange: (text: string) => void, highlight?: boolean, showRequiredMark?: boolean }) => {
  const [year, setYear] = useState(init ? parseInt(init.substring(0, 4)).toString() : "")
  const [month, setMonth] = useState(init ? parseInt(init.substring(4, 6)).toString() : "")

  useEffect(() => {
    if (year === "" || month === "") {
      onChange("")
    } else {
      const birth = `${year}${zeroPad(parseInt(month), 2)}`
      onChange(birth.length === 6 ? birth : "")
    }

  }, [year, month])

  return (
    <div style={{...ContainerStyle}}>
      <span style={LabelStyle}>{label}{showRequiredMark && <RequiredMark/>}</span>
      <div>
        <div style={{flex: 1, display: 'flex', gap: 3}}>
          <NumberField unit={"년"} digit={4} placeholder={removeZeroPad(placeHolder.substring(0, 4) || "1960")} min={1} value={year} onChange={text => setYear(text)}/>
          <NumberField unit={"월"} digit={2} placeholder={removeZeroPad(placeHolder.substring(4, 6) || "12")} min={1} max={12} value={month} onChange={text => setMonth(text)}/>
        </div>
        {highlight && <p style={{margin: '8px 0 0', padding: '5px 0 0', color: '#F15D47', fontSize: '0.875rem', borderTop: '1px solid #F15D47'}}>필수로 입력해주세요</p>}
      </div>
    </div>
  )
}

interface SelectorOption {
  key: string
  label: string
}

const HSelector = ({label, options = [], value, onChange, showRequiredMark = false}: { label: string, options: SelectorOption[], value: string, onChange: (key: string) => void, showRequiredMark?: boolean }) => {
  return (
    <div style={ContainerStyle}>
      <span style={{...LabelStyle, paddingTop: 6}}>{label}{showRequiredMark && <RequiredMark/>}</span>
      <div style={{flex: 1}}>
        {
          options.map(option => {
            const isSelected = value === option.key
            return <button key={option.key}
                           style={{
                             background: isSelected ? '#EEF9F2' : 'white',
                             padding: '5px 5px',
                             marginRight: 10,
                             borderRadius: 5,
                             border: isSelected ? '1px solid #0ABF53' : '1px solid #D9D9D9'
                           }}
                           onClick={() => {
                             onChange(option.key)
                           }}>
                                <span style={{
                                  whiteSpace: 'nowrap',
                                  fontSize: '1rem',
                                  color: isSelected ? '#0ABF53' : "#999",
                                  lineHeight: 1.5
                                }}>{option.label}</span>
            </button>
          })
        }
      </div>
    </div>
  )
}

const VSelector = ({options, selected, onChange}: { options: SelectorOption[], selected: string, onChange: (key: string) => void }) => {
  return <>
  {
    options.map(option => {
      return <Button
        label={option.label}
        checked={selected === option.key}
        onClick={() => onChange(option.key)}
      />
    })
  }
  </>
}

const DropDown = ({label, value, options, onChange, showModal, setShowModal, showRequiredMark = false, hideLabel = false}: { label: string, value: string, options: string[], onChange: (text: string) => void, showRequiredMark?: boolean, hideLabel?: boolean, showModal: boolean, setShowModal: (showModal: boolean) => void }) => {
  function onClick() {
    setShowModal(true);
  }

  const onClose = () => {
    setShowModal(false);
  }

  return (
    <React.Fragment>
      <div style={{...ContainerStyle}}>
        {!hideLabel && <span style={LabelStyle}>{label}{showRequiredMark && <RequiredMark/>}</span>}
        <div style={{borderRadius: 5, border: '1px solid #D9D9D9', display: 'flex', width: '100%'}} onClick={onClick}>
          <span style={{flex: 1, padding: 10, lineHeight: 1.5, color: value ? "black" : "#D9D9D9"}}>{value ? value : "선택하세요"}</span>
          <div style={{background: '#D9D9D9', display: 'flex', alignItems: 'center', padding: '0px 5px'}}>
            <IcArrowDown/>
          </div>
        </div>
      </div>
      <DropDownMenu title={label} selected={value} showDropDownMenu={showModal} onClose={onClose} onChange={onChange} options={options}/>
    </React.Fragment>
  )
}

const DropDownMenu = ({title, selected, showDropDownMenu, onClose, onChange, options}: { title: string, showDropDownMenu: boolean, onClose: () => void, options: string[], selected: string, onChange: (text: string) => void }) => {
  return <BottomDrawerTemplate
    show={showDropDownMenu}
    title={title}
    onClose={onClose}
    style={{maxHeight: 220, overflowY: 'scroll'}}
  >
    {
      options.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              onChange(item)
              onClose()
            }}
            style={{display: 'flex', justifyContent: 'space-between', padding: '12px 0'}}
          >
            <span style={{lineHeight: 1.5, color: item === selected ? primary : 'black'}}>{item}</span>
            <IcCheckMark style={{visibility: item === selected ? 'visible' : 'hidden'}} width={30} height={30}/>
          </div>
        )
      })
    }
  </BottomDrawerTemplate>
}

const TelField = ({init, label, placeholder, onChange, onFocus, highlight = false, showRequiredMark = false}: { init: string, label: string, placeholder: string, onChange: (text: string) => void, onFocus?: (top: number) => void, highlight?: boolean, showRequiredMark?: boolean }) => {
  const [text, setText] = useState(init || "")
  const inputRef = useRef<HTMLInputElement>(null);
  const observer = useAutoBlurObserver(inputRef);
  const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => inputRef.current?.scrollIntoView({behavior: "smooth"}), KeyboardTime)
    setTimeout(() => {if(inputRef.current) {observer.observe(inputRef.current)}}, AutoBlurTime)
  }
  const _onBlur = () => observer.disconnect()

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!/^\d*$/.test(e.target.value)) {
      return
    }
    if (e.target.value.length > 11) {
      return
    }
    setText(e.target.value ? e.target.value : "")
    onChange(e.target.value.length === 11 ? e.target.value : "")
  }

  return (
    <div style={ContainerStyle}>
      {label && <span style={LabelStyle}>{label}{showRequiredMark && <RequiredMark/>}</span>}
      <div style={{flex: 1}}>
        <input className={styles.input} ref={inputRef} type="tel" placeholder={placeholder} value={text} onChange={handleOnChange} style={{flex: 1, width: '100%', border: '1px solid #D9D9D9', borderRadius: 5, padding: 10, fontSize: '1rem', boxSizing: 'border-box', ...NumberInputStyle, textAlign: 'left'}} onFocus={_onFocus} onBlur={_onBlur}/>
        {highlight && <p style={{margin: '8px 0 0', padding: '5px 0 0', color: '#F15D47', fontSize: '0.875rem', borderTop: '1px solid #F15D47'}}>필수로 입력해주세요</p>}
      </div>
    </div>
  )
}

const DomainMap: { [key: string]: string } = {
  "네이버": "naver.com",
  "한메일": "hanmail.net",
  "지메일(구글)": "gmail.com",
  "다음": "daum.net",
  "네이트": "nate.com",
  "핫메일": "hotmail.com",
  "카카오": "kakao.com",
  "직접입력": ""
}

const EmailField = ({value, onChange, showModal, setShowModal, onFocus, onBlur, hideLabel = false}: { value: string, onChange: (text: string) => void, hideLabel?: boolean, onFocus?: (top: number) => void, onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void, showModal: boolean, setShowModal: (showModal: boolean) => void }) => {
  const parsed = /([a-zA-Z0-9._-]+)@([a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/.exec(value)
  const initUsername = parsed ? parsed[1] : ""
  const initDomain = parsed ? parsed[2] : ""
  const inputRef = useRef<HTMLInputElement>(null);
  const observer = useAutoBlurObserver(inputRef);
  const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => inputRef.current?.scrollIntoView({behavior: "smooth"}), KeyboardTime)
    setTimeout(() => {if(inputRef.current) {observer.observe(inputRef.current)}}, AutoBlurTime)
  }

  const _onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e)
    }
    observer.disconnect()
  }

  const t = Object.keys(DomainMap).find(key => DomainMap[key].includes(initDomain))

  const [username, setUsername] = useState(initUsername)
  const [directInputDomain, setDirectInputDomain] = useState(initDomain || "")
  const [selectedEmail, setSelectedEmail] = useState(t || "직접입력")

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setUsername("")
    } else if (/[a-zA-Z0-9._-]+/.test(e.target.value)) {
      setUsername(e.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣@]/g, ''))
    }
  }

  useEffect(() => {
    const domain = DomainMap[selectedEmail] || directInputDomain
    const data = username + "@" + domain

    if (!/[a-zA-Z0-9._-]+/.test(username)) {
      onChange("")
    } else if (!/[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+/.test(domain)) {
      onChange("")
    } else if (/([a-zA-Z0-9._-]+)(@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/.test(data)) {
      onChange(data)
    } else {
      onChange("")
    }
  }, [username, directInputDomain, selectedEmail]);

  return (<>
      <DropDown label={"이메일"} onChange={setSelectedEmail} options={Object.keys(DomainMap)} value={selectedEmail} hideLabel={hideLabel} showModal={showModal} setShowModal={setShowModal}/>
      <div style={{...ContainerStyle, padding: '0px 0 10px'}}>
        {!hideLabel && <span style={LabelStyle}></span>}
        <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
          <input className={styles.input} ref={inputRef} type="text" placeholder={"예) wizdom"} value={username} onChange={_onChange} style={{flex: 1, width: '100%', border: '1px solid #D9D9D9', borderRadius: 5, padding: 10, fontSize: '1rem', boxSizing: 'border-box', ...InputStyle}} onFocus={_onFocus} onBlur={onBlur}/>
          <span>@</span>
          {DomainMap[selectedEmail] ? <span style={{alignSelf: "center"}}> {DomainMap[selectedEmail]} </span> : <input className={styles.input} type="text" placeholder={"wizdom.co.kr"} value={directInputDomain} onChange={e => setDirectInputDomain(e.target.value)} style={{flex: 1, width: '100%', border: '1px solid #D9D9D9', borderRadius: 5, padding: 10, fontSize: '1rem', boxSizing: 'border-box', ...NumberInputStyle}} onFocus={_onFocus} onBlur={_onBlur}/>}
        </div>
      </div>
    </>
  )
}


const TextArea = ({placeholder, label, value, onChange, rows = 2, onFocus, highlight = false, showRequiredMark = false}: { placeholder: string, label: string, value: string, onChange: (text: string) => void, rows?: number, onFocus?: (top: number) => void, highlight?: boolean, showRequiredMark?: boolean }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const observer = useAutoBlurObserver(textAreaRef);

  const _onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setTimeout(() => textAreaRef.current?.scrollIntoView({behavior: "smooth"}), KeyboardTime)
    setTimeout(() => {if(textAreaRef.current) {observer.observe(textAreaRef.current)}}, AutoBlurTime)
  }

  const _onBlur = () => observer.disconnect()

  const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value === " ") {
      onChange("")
      // } else if (/\s\s+/.test(e.target.value)) {
      // } else if (/^[ㄱ-ㅎ가-힣a-zA-Z0-9\-(),.\n\s]*$/.test(e.target.value)) {
      //   onChange(e.target.value)
    } else {
      onChange(e.target.value)
    }
  }


  return (
    <div style={{...ContainerStyle}}>
      {label && <span style={{...LabelStyle, whiteSpace: 'pre-wrap'}}>{label}{showRequiredMark && <RequiredMark/>}</span>}
      <div style={{flex: 1}}>
        <textarea className={styles.textarea} ref={textAreaRef} rows={rows} placeholder={placeholder} value={value} onChange={_onChange} style={{...TextAreaStyle}} onFocus={_onFocus} onBlur={_onBlur}/>
        {highlight && <p style={{margin: '8px 0 0', padding: '5px 0 0', color: '#F15D47', fontSize: '0.875rem', borderTop: '1px solid #F15D47'}}>필수로 입력해주세요</p>}
      </div>
    </div>
  )
}

const Description = ({text}: { text: string }) => {
  return <p style={{margin: '16px 0px', lineHeight: 1.5, fontSize: '1.125rem', fontWeight: 700}}>{text}</p>
}

const formatDateISO = (date: string) => {
  const theDay = new Date(date)
  const year = theDay.getFullYear()
  const month = zeroPad(theDay.getMonth() + 1, 2)
  const day = zeroPad(theDay.getDate(), 2)
  return `${year}${month ? "." : ""}${month}${day ? "." : ""}${day}`
}

const formatDate = (date: string) => {
  if (date === undefined) {
    return ""
  }
  const year = date.substring(0, 4)
  const month = date.substring(4, 6)
  const day = date.substring(6, 8)
  return `${year}${month ? "." : ""}${month}${day ? "." : ""}${day}`
}

const CareerCard = ({career, onEdit, onDelete, highlight = false}: { career: CareerState, onEdit: () => void, onDelete: (id: string) => void, highlight?: boolean }) => {
  return (<>
    <div style={{border: highlight ? '1px solid #F15D47' : '1px solid #B2B2B2', borderRadius: 5, margin: '16px 0', padding: 16}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <p style={{flex: 1, margin: '0', lineHeight: 1.5, fontWeight: 700}}>{career.no_career ? "신입" : career.name}</p>
        {!career.no_career && <span style={{border: '1px solid #D7D8DA', color: '#6A6A6A', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={onEdit}>수정</span>}
        <span style={{width: 10}}/>
        <span style={{border: '1px solid #D7D8DA', color: '#6A6A6A', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={() => onDelete(career.uuid)}>삭제</span>
      </div>
      {career.startDate && <p style={{margin: '16px 0 0', lineHeight: 1.5, fontSize: '0.875rem', color: '#666'}}>{formatDate(career.startDate)} ~ {career.status == "1" ? "재직중" : formatDate(career.endDate) + "(퇴사)"}</p>}
      {career.position && <p style={{margin: '10px 0', lineHeight: 1.5, fontSize: '0.875rem', color: '#666'}}>{career.position}</p>}
      {career.description && <p style={{whiteSpace: 'pre-wrap', margin: 0, lineHeight: 1.5, fontSize: '0.875rem', color: '#666'}}>{career.description}</p>}
    </div>
    {highlight && <p style={{color: '#F15D47', margin: '0px 0 16px'}}>수정이 필요합니다.</p>}
  </>);
}

const DeleteCareerModal = ({id, onClose, onDelete}: {id: string, onClose: () => void, onDelete: () => void}) => {
  const removeCareer = () => {
    onDelete()
    onClose()
  }
  return <DialogDrawerTemplate show={id !== ""} onClose={onClose} title={"자격증 삭제"} closeButton={false}>
    <p>해당 경력 정보를 삭제할까요?</p>
    <div style={{display: 'flex', gap: 10}}>
      <FillButton style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}} text={"취소"} onClick={onClose}/>
      <FillButton style={{background: '#F15D47'}} text={"삭제"} onClick={removeCareer}/>
    </div>
  </DialogDrawerTemplate>
}


const educationTitle: { [key: string]: string } = {
  "phd": "대학원 박사",
  "master": "대학원 석사",
  "bachelor": "대학교",
  "high_school": "고등학교",
  "middle_under": "중학교 이하"
}

const EducationCard = ({education, highest, onEdit, onDelete}: { education: EducationState, highest: boolean, onEdit: () => void, onDelete: (id: string) => void }) => {
  return (
    <>
      <div style={{border: '1px solid #B2B2B2', borderRadius: 5, margin: '16px 0', padding: 16}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {highest && <span style={{background: '#F4F4F4', fontSize: '0.875rem', fontWeight: 500, padding: '4px 8px', borderRadius: 5, marginRight: 10}}>최종학력</span>}
          <p style={{flex: 1, margin: '0', lineHeight: 1.5, fontWeight: 700}}>{educationTitle[education.degree]}</p>
          {education.degree !== "middle_under" && <span style={{border: '1px solid #D7D8DA', color: '#6A6A6A', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={onEdit}>수정</span>}
          <span style={{width: 10}}/>
          <span style={{border: '1px solid #D7D8DA', color: '#6A6A6A', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={() => onDelete(education.uuid)}>삭제</span>
        </div>
        {education.degree !== "middle_under" && <p style={{flex: 1, margin: '16px 0', lineHeight: 1.5, fontSize: '0.875rem', color: "#666"}}>{education.name}</p>}
        {education.degree !== "middle_under" && education.startDate &&
            <p style={{margin: 0, lineHeight: 1.5, fontSize: '0.875rem', color: "#666"}}>
              {
                education.name === "검정고시" ? formatDate(education.startDate) : `${formatDate(education.startDate)} ~ ${education.status === "재학" ? "재학중" : formatDate(education.endDate) + "(" + education.status + ")"}`
              }
            </p>
        }
      </div>
    </>
  )
}

const DeleteEducationModal = ({id, onClose, onDelete}: {id: string, onClose: () => void, onDelete: () => void}) => {
  const removeEducation = () => {
    onDelete()
    onClose()
  }
  return <DialogDrawerTemplate show={id !== ""} onClose={onClose} title={"학력 삭제"} closeButton={false}>
    <p>해당 학력 정보를 삭제할까요?</p>
    <div style={{display: 'flex', gap: 10}}>
      <FillButton style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}} text={"취소"} onClick={onClose}/>
      <FillButton style={{background: '#F15D47'}} text={"삭제"} onClick={removeEducation}/>
    </div>
  </DialogDrawerTemplate>
}

const CertificateCard = ({certificate, onEdit, onDelete}: { certificate: CertificateState, onEdit: () => void, onDelete: (id: string) => void }) => {
  return (
    <>
      <div style={{border: '1px solid #B2B2B2', borderRadius: 5, margin: '16px 0', padding: 16}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={{flex: 1, margin: '0', lineHeight: 1.5, fontWeight: 700}}>{certificate.no_certificate ? "자격증 없음" : certificate.name}</p>
          {!certificate.no_certificate && <span style={{border: '1px solid #D7D8DA', color: '#6A6A6A', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={onEdit}>수정</span>}
          <span style={{width: 10}}/>
          <span style={{border: '1px solid #D7D8DA', color: '#6A6A6A', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={() => onDelete(certificate.uuid)}>삭제</span>
        </div>
        {certificate.publisher && <p style={{flex: 1, margin: '16px 0', lineHeight: 1.5, fontSize: '0.875rem', color: "#666"}}>{certificate.publisher}</p>}
        {certificate.acquired_at && <p style={{margin: 0, lineHeight: 1.5, fontSize: '0.875rem', color: "#666"}}>{formatDate(certificate.acquired_at)}</p>}
      </div>
    </>);
}

const DeleteCertificateModal = ({id, onClose, onDelete}: {id: string, onClose: () => void, onDelete: () => void}) => {
  const removeCertificate = () => {
    onDelete()
    onClose()
  }
  return <DialogDrawerTemplate show={id !== ""} onClose={onClose} title={"자격증 삭제"} closeButton={false}>
    <p>해당 자격증 정보를 삭제할까요?</p>
    <div style={{display: 'flex', gap: 10}}>
      <FillButton style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}} text={"취소"} onClick={onClose}/>
      <FillButton style={{background: '#F15D47'}} text={"삭제"} onClick={removeCertificate}/>
    </div>
  </DialogDrawerTemplate>
}


const ResumeItem = ({resume}: { resume: any }) => {
  const navigate = useNavigate();

  return <div style={{padding: '20px 0'}}>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div style={{flex: 1, marginLeft: 10}}>
        <span style={{display: 'block'}}>{resume.title}</span>
        <span style={{fontSize: '0.75rem', color: '#666'}}>최종수정일: {formatDateISO(resume.updated_at)}</span>
      </div>
      <span style={{border: '1px solid #D7D8DA', color: '#6A6A6A', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={() => navigate("resume_preview/" + resume.uuid)}>보기</span>
    </div>
  </div>
}


const EditableResumeItem = ({resume}: { resume: ResumeCardState }) => {
  const navigate = useNavigate();

  return <div style={{padding: '20px 0'}}>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div style={{flex: 1, marginLeft: 10}}>
        <span style={{display: 'block'}}>{resume.title}</span>
        <span style={{fontSize: '0.75rem', color: '#666'}}>최종수정일: {formatDateISO(resume.updated_at)}</span>
      </div>
      <span style={{border: '1px solid #D7D8DA', color: '#6A6A6A', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={() => navigate("resume_preview/" + resume.id)}>보기</span>
      <span style={{width: 10}}/>
      <span style={{border: '1px solid #D7D8DA', color: '#6A6A6A', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={() => navigate("edit_resume/" + resume.id)}>수정</span>
    </div>
  </div>
}


const RemovableResumeItem = ({resume, onDeleted}: { resume: ResumeCardState, onDeleted: (id: string) => void }) => {
  const navigate = useNavigate();

  const previewResume = () => {
    trackEvent(EVENT_RESUME_PREVIEW, {location: "JobResumeTab", resume_id: resume.id})
    navigate("resume_preview/" + resume.id)
  }

  const editResume = () => {
    trackEvent(EVENT_RESUME_EDIT, {location: "JobResumeTab", resume_id: resume.id})
    navigate("edit_resume/" + resume.id)
  }

  const removeResume = () => {
    onDeleted(resume.id)
  }

  return <>
    <div key={resume.id} style={{padding: '10px', border: '1px solid #999', borderRadius: 5, marginBottom: 10}}>
      <p style={{margin: 0, lineHeight: 1.5}}>{resume.title || ""}</p>
      <p style={{margin: 0, lineHeight: 1.5, fontSize: '0.75rem', color: '#666'}}>최종수정일: {formatDateISO(resume.updated_at)}</p>
      <div style={{display: 'flex', marginTop: 10, gap: 10}}>
        <span style={{flex: 1, textAlign: 'center', border: '1px solid #999', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={previewResume}>보기</span>
        <span style={{flex: 1, textAlign: 'center', border: '1px solid #999', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={editResume}>수정</span>
        <span style={{flex: 1, textAlign: 'center', border: '1px solid #999', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={removeResume}>삭제</span>
      </div>
    </div>
  </>
}


const SelectedResumeItem = ({resume}: { resume: ResumeCardState }) => {
  const navigate = useNavigate();

  const changeResume = () => {
    trackEvent(EVENT_SIMPLE_APPLY_CHANGE_RESUME_CLICK, {})
    navigate("select_resume")
  }

  return <div style={{padding: '20px 0'}}>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <span style={{flex: 1}}>{resume.title || ""}</span>
      <span style={{border: '1px solid #D7D8DA', color: '#6A6A6A', borderRadius: 5, padding: '2px 4px', fontSize: '0.75rem'}} onClick={changeResume}>이력서 변경</span>
    </div>
    <span style={{fontSize: '0.75rem', color: '#666'}}>최종수정일: {formatDateISO(resume.updated_at)}</span>
  </div>
}

const Indicator = ({label, max, index}: { label: string, max: number, index: number }) => {
  return <div style={{display: 'flex', gap: 8, alignItems: 'center', marginBottom: 20}}>
    {
      [...Array(max)].map((_, step) => {
        if (step + 1 === index) {
          return <div style={{background: '#0ABF53', borderRadius: 5, padding: '6px 10px', display: 'flex', gap: 4}}>
            <div style={{background: 'white', borderRadius: 10, width: 20, height: 20, display: 'flex', justifyContent: 'center'}}>
              <span style={{color: '#0ABF53', fontSize: '0.875rem', fontWeight: 500}}>{index}</span>
            </div>
            <span style={{color: 'white', fontSize: '0.875rem', fontWeight: 500}}>{label}</span>
          </div>
        }
        return <div style={{width: 10, height: 10, borderRadius: 5, background: '#D9D9D9'}}></div>
      })
    }
  </div>
}

export {removeZeroPad, SalaryInput, zeroPad, Button, NavigateButton, Description, TextField, TextFieldCheckbox, YMDInput, YMInput, HSelector, VSelector, TelField, EmailField, TextArea, DropDown, Checkbox, RadioButtonGroup, EducationCard, CareerCard, CertificateCard, ResumeItem, RemovableResumeItem, SelectedResumeItem, EditableResumeItem, Indicator, DeleteEducationModal, DeleteCareerModal, DeleteCertificateModal, YMDInput2};