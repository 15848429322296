import { CSSProperties, ChangeEventHandler, useEffect, useState } from "react"
import { DefaultPageRoot } from "@components/PageTemplate"
import { FillButton } from "@components/Buttons"
import { BackNavToolbar } from "@components/Toolbars"
import { useDispatch, useSelector } from "react-redux"
import { show } from "@reducer/snackbarSlice"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import * as amplitude from "@amplitude/analytics-browser"
import { EVENT_SURVEY_SUBMIT } from "@components/AppConstant"
import { appendAnswered } from "@reducer/surveySlice"
import request from "@util/request"
import { RootState } from "@reducer/store"
import styles from "@src/features/job/features/apply/style/input.module.css";

const selectedButtonStyle = {
    border: '1px solid #0ABF53',
    background: '#EEF9F2',
    color: '#0ABF53',
    borderRadius: 5,
    padding: 10,
    fontSize: '1.125rem',
    marginBottom: 10
}

const unselectedButtonStyle = {
    border: '1px solid #999',
    background: 'none',
    borderRadius: 5,
    color: 'black',
    fontSize: '1.125rem',
    padding: 10,
    marginBottom: 10
}

const OptionButton = ({ option, onClick, selected }: {
    option: { key: string, next: number },
    onClick: () => void,
    selected: boolean
}) => {
    return (
        <div key={option.key} onClick={(onClick)} style={selected ? selectedButtonStyle : unselectedButtonStyle}>
            <span>{option.key}</span>
        </div>
    )
}

const OptionInput = ({ option, init, onChange }: {
    option: { key: string, placeholder: string },
    init: string,
    onChange: (value: any) => void
}) => {
    const [text, setText] = useState(init || "")

    const handleTextChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setText(e.target.value)
    }

    useEffect(() => {
        if (text === "") {
            onChange(null)
        } else if (text.length > 500) {
            onChange(null)
        } else {
            onChange({
                key: option.key,
                value: text
            })
        }
    }, [text])

    return (
        <div>
            <textarea className={styles.textarea} rows={4} value={text} onChange={handleTextChange} placeholder={option.placeholder} style={{ width: '100%', padding: 10, borderRadius: 5, border: text.length < 501 ? '1px solid #999' : '1px solid #F15D47', boxSizing: 'border-box', lineHeight: 1.5, fontSize: '1rem', resize: 'none' }} />
            {text.length < 501 ? <p style={{ margin: 0, lineHeight: 1.5, textAlign: 'right', fontSize: '0.875rem', color: '#999' }}>{text.length}/500 자</p> : <p style={{ margin: 0, lineHeight: 1.5, textAlign: 'right', fontSize: '0.875rem',  color: "#F15D47" }}>최대 500자까지만 작성해주세요</p>}
        </div>
    )
} 


const selectedExtendButtonStyle = {
    border: '1px solid #0ABF53',
    background: '#EEF9F2',
    color: '#0ABF53',
    borderRadius: '5px 5px 0 0',
    padding: 10,
}

const unselectedExtendButtonStyle = {
    border: '1px solid #999',
    background: 'none',
    borderRadius: 5,
    color: 'black',
    padding: 10,
    marginBottom: 10
}

const ExtendButton = ({ option, init, onClick, onChange, selected }: {
    option: { key: string, description: string },
    init: { key: string, value: string },
    onClick: () => void,
    onChange: (value: any) => void,
    selected: boolean
}) => {
    const initText = option.key == init?.key ? init?.value : ""
    const [text, setText] = useState(initText)

    const handleTextChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setText(e.target.value)
    }

    useEffect(() => {
        if (option.key != init?.key) {
            return 
        }
        if (text === "") {
            onChange(null)
        } else if (text.length > 500) {
            onChange({
                key: option.key
            })
        } else {
            onChange({
                key: option.key,
                value: text
            })
        }
    }, [text])

    return (
        <div>
            <div key={option.key} onClick={(onClick)} style={selected ? selectedExtendButtonStyle : unselectedExtendButtonStyle}>
                <span style={{fontSize: '1.125rem'}}>{option.key}</span>
                {selected && <span>{option.description}</span>}
            </div>
            {selected && <textarea className={styles.textarea} value={text} onChange={handleTextChange} style={{ width: '100%', height: 100, padding: 10, borderRadius: '0 0 5px 5px', border: '1px solid #0ABF53', borderTop: 'none', boxSizing: 'border-box', lineHeight: 1.5, fontSize: '1rem', resize: 'none' }} />}
            {selected && (text.length < 501 ? <p style={{ margin: 0, lineHeight: 1.5, textAlign: 'right',fontSize: '0.875rem', color: '#999' }}>{text.length}/500 자</p> : <p style={{ margin: 0, lineHeight: 1.5, textAlign: 'right', color: "#F15D47", fontSize: '0.875rem' }}>최대 500자까지만 작성해주세요</p>)}
        </div>
    )
}

const TelInput = ({ option, onChange }: {
    option: { key: string },
    onChange: (value: any) => void
}) => {
    const [number, setNumber] = useState("")

    const handleChangeNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.value.length < 12) {
            setNumber(e.target.value)
        } 
    }
    useEffect(() => {
        if (number.length > 10) {
            onChange({
                key: option.key,
                value: number
            })
        } else {
            onChange(null)
        }
    }, [number])

    return (
        <div>
            <input
                className={styles.className}
                autoFocus={true}
                type='number'
                value={number}
                placeholder="- 없이 숫자만 입력"
                style={{ margin: 0, width: '100%', marginBottom: '1rem', lineHeight: 1.5, fontSize: '1rem', color: '#666666', padding: '10px', borderRadius: 4, border: '1px solid #66666687', boxSizing: 'border-box' }}
                onChange={handleChangeNumber}
            />
        </div>
    )
}

const ScoreButtonStyle: CSSProperties = {
    flex: 1,
    textAlign: 'center',
    padding: 10,
    fontSize: '1.125rem'
}
const ScoreButton = ({ option, init, onChange }: {
    option: { key: string, start: number, end: number, startDescription: string, endDescription: string },
    init: { key: string, value: number },
    onChange: (value: any) => void
}) => {
    const [score, setScore] = useState(init?.value || null)

    function handleScoreChange(i: number) {
        setScore(i)
        onChange({
            key: option.key,
            value: i
        })
    }

    return (
        <div>
            <div>
                <div style={{ display: 'flex' }}>
                    {
                        Array.from({ length: option.end - option.start + 1 }, (_, i) => option.start + i).map((i, index) => {
                            return (
                                <div key={i} onClick={() => handleScoreChange(i)} style={{ ...ScoreButtonStyle, background: score === i ? '#EEF9F2' : 'none', color: score === i ? '#0ABF53' : 'black', border: score === i ? '1px solid #0ABF53' : '1px solid #999', marginLeft: index !== 0 ? -1 : 0, zIndex: score === i ? 1 : 0 }}>{i}</div>
                            )
                        })
                    }
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10, fontSize: '0.875rem', lineHeight: 1.5 }}>
                <span style={{ whiteSpace: 'pre-wrap' }}>{option.startDescription}</span>
                <span style={{ whiteSpace: 'pre-wrap' }}>{option.endDescription}</span>
            </div>
        </div>
    )
}

const Info = ({ option }: { option: string }) => {
    return (
        <div>
            <p style={{ margin: '0px 0', width: '100%', borderRadius: 5, lineHeight: 1.5, fontSize: '1rem' }} >{option}</p>
        </div>
    )
} 


interface Question {
    key: string,
    title: string,
    description: string,
    options: any[],
    next: number,
    isEnd: boolean,
    index: number
}

const answers: { [key: string]: any }= {}
const answersList: string[] = []

const SurveyView = () => {
    const { surveyId, questionId } = useParams();
    const [answer, setAnswer] = useState<any>(null)
    const [requesting, setRequesting] = useState(false)
    var isRequesting = false
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation()
    const { survey } = state
    const [question, setQuestion] = useState<Question | null>(null)
    const uuid = useSelector((state: RootState) => state.user.uuid)
    const uuidHash = useSelector((state: RootState) => state.user.uuidHash);
    const next = () => {
        if (!!question && question.isEnd) {
            if (isRequesting) {
                return
            }
            answers[question.key] = answer
            const s = request({endPoint: "/survey_participate", body: {"survey_id": survey.id, "uid": uuid}})

            const format: { [key: string]: any } = {}
            for (const key in answers) {
                format[key] = answers[key].value
            }
            amplitude.track(EVENT_SURVEY_SUBMIT, {
                id: survey.id,
                user_group: uuidHash,
                ...format
            })

            isRequesting = true
            setRequesting(true)
            s.then(() => {
                    setRequesting(false)
                    dispatch(appendAnswered(survey.id))
                    dispatch(show({'message': "제출되었습니다."}))
                    navigate(-(answersList.length + 1))
                })
            return
        }
        if (!!question && !answersList.includes(question.key)) {
            answersList.push(question.key)
        }
        answers[question!.key] = answer
        let next = question!.next
        if (answer.next) {
            next = answer.next
        }
        const nextQuestion = survey.questions.find((q: any) => q.index === next)
        setQuestion(nextQuestion)
        setAnswer(null)
        navigate(`/survey/${surveyId}/${nextQuestion.index}`, {state: {survey: survey}})
    }

    useEffect(() => {
        let question = survey.questions.find((q: any) => q.index === parseInt(questionId!))   
        if (answersList.includes(question.key)) {
            const pos = answersList.indexOf(question.key)
            const target = answersList.splice(pos + 1)
            target.forEach((key) => {
                delete answers[key]
            })
        }
        setQuestion(question)
        setAnswer(answers[question.key])
    }, [questionId])

    return (
        <DefaultPageRoot
            top={<BackNavToolbar title="설문조사" />}
            middle={<div>
                {
                    question &&
                    <div style={{ padding: '30px 16px' }}>
                        <p style={{ fontSize: '1.25rem', fontWeight: 500, lineHeight: 1.5, margin: question.description ? '0 0 16px' : '0 0 30px' }}>{question.title}</p>
                        { question.description && <p style={{ lineHeight: 1.5, margin: '0 0 30px' }}>{question.description}</p>}

                        {question.options.map((option) => {
                            if (option.type === 'button') {
                                return (
                                    <OptionButton key={option.key} option={option} onClick={() => {setAnswer({key: option.key, value:option.key, next: option.next})}} selected={answer && answer.key === option.key} />
                                )
                            } else if (option.type === "input") {
                                return (
                                    <OptionInput key={option.key} option={option} init={answer?.value || ""} onChange={setAnswer} />
                                )
                            } else if (option.type === "extendButton") {
                                return (
                                    <ExtendButton key={option.key} option={option} init={answer} onClick={() => setAnswer({key: option.key})}  onChange={setAnswer} selected={answer && answer.key === option.key} />
                                )
                            } else if (option.type === "scoreButton") {
                                return (
                                    <ScoreButton key={option.key} option={option} init={answer} onChange={setAnswer} />
                                )
                            } else if (option.type === "telInput") {
                                return (
                                    <TelInput key={option.key} option={option} onChange={setAnswer} />
                                )
                            } else if (option.type === "info") {
                                return (
                                    <Info key={option.key} option={option.value} />
                                )
                            }
                        })}
                    </div>
                }
            </div>}
            bottom={<div style={{ padding: 16 }}>
                <FillButton
                    style={ (answer != null && answer.value != null) ? undefined : { background: '#D9D9D9' }}
                    onClick={ (answer != null && answer.value != null) ? next : undefined}
                    text={requesting ? "제출중..." : question && question.isEnd ? "제출하기" : "다음"}
                />
            </div>}
        />

    )
}
export default SurveyView;