import { BackNavToolbar } from "@components/Toolbars";
import PopularActivityList from "@components/PopularActivityList";
import RegionCityButton from "@components/RegionCityButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import popularActivityPageLoader from "../data-layer/popularActivityPageLoader";
import { updatePopularActivityPage } from "@reducer/activityCacheSlice";
import useOnLog from "@components/useOnLog";
import { EVENT_POPULAR_ACTIVITY_LIST_PAGE_SHOW } from "@components/AppConstant";
import { DefaultPageRoot } from "@components/PageTemplate";
import { RootState } from "@reducer/store";
import { Activity } from "@model/Activity";

const PopularActivityView = () => {
    const regions = useSelector((state: RootState) => state.user.regions)
    const popularActivityPage = useSelector((state: RootState) => state.activityCache.popularActivityPage)
    const [activities, setActivities] = useState<Activity[]>([])
    const [sessionId, setSessionId] = useState<string | null>(null)
    const dispatch = useDispatch()

    function loadActivities() {
        popularActivityPageLoader(regions).then((res) => {
            res.activities = res.activities.filter((a) => a.activityTagList != "노인일자리")
            setActivities(res.activities);
            setSessionId(res.sessionId);
            dispatch(updatePopularActivityPage({ sessionId: res.sessionId, activities: res.activities }));
        })
    }

    useOnLog({
        pageNameKey: EVENT_POPULAR_ACTIVITY_LIST_PAGE_SHOW,
        amplitudeProp: {},
        regStr: "^(.*?)\/popular_activity_more$"
    })

    useEffect(() => {
        if (!popularActivityPage) {
            setActivities([])
            setSessionId(null)
            loadActivities()
        } else {
            setSessionId(popularActivityPage.sessionId)
            setActivities(popularActivityPage.activities)
        }
    }, [regions]);

    const noContentView = () => {
        return (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p style={{ textAlign: 'center', lineHeight: '1.5' }}>
                    인기 공고가 없습니다.<br />
                    다른 지역을 확인해보세요.
                </p>
            </div>
        )
    }


    return (
        <DefaultPageRoot 
            top={<>
                <BackNavToolbar title={"실시간 인기 공고"} />
                <RegionCityButton location={"PopularActivityPage"} />
                <div style={{height: '1px', backgroundColor: '#D9D9D9', marginTop: '8px'}}></div>
            </>}
            middle={<>
                { activities && sessionId && <PopularActivityList activities={activities} location={"PopularActivityPage"} noContentView={noContentView} style={{paddingTop: '16px'}}/> }
            </>}
        />
    );
}

export default PopularActivityView;