import { EVENT_POPULAR_ACTIVITIES_LOADED } from "@components/AppConstant"
import server_domain from "@components/RemoteServer"
import convertActivities from "@util/convertActivities"
import uuidv4 from "@util/uuidv4"
import * as amplitude from "@amplitude/analytics-browser"
import { fetchWithRetry } from "@util/networks"
import { Activity } from "@model/Activity"

interface PopularActivityPageLoaderResponse {
    activities: Activity[],
    sessionId: string
}

const popularActivityPageLoader = async (regions: string[]): Promise<PopularActivityPageLoaderResponse> => {
    let serialized = JSON.stringify(regions)
    let response = await fetchWithRetry(server_domain() + '/popular_activity?regions=' + serialized, {
        headers: {
            "Content-Type": "application/json",
        },
    })
    let json = await response.json()
    let activities = convertActivities(json)
    let sessionId = uuidv4()
    activities = activities.filter((a) => a.activityTagList != "노인일자리")

    amplitude.track(EVENT_POPULAR_ACTIVITIES_LOADED, {
        location: "PopularActivityListPage",
        ids: activities.map((a) => a.id),
        regions: regions,
    })
    return { activities: activities, sessionId: sessionId }
}

export default popularActivityPageLoader;
