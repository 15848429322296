import Comment from "./Comment";

export interface PostImage {
    isUrl: boolean
    data: string
}
export default interface Post {
    id: number;
    user_id: string;
    nickname: string;
    title: string;
    content: string;
    category?: PostCategory;
    created_at: string;
    image_urls: string[] | null;
    like_count: number;
    comment_count: number;
}

export default interface PostDetail {
    id: number;
    user_id: string;
    nickname: string;
    title: string;
    content: string;
    category?: PostCategory;
    created_at: string;
    image_urls: string[] | null;
    likes: PostLike[];
    comments: Comment[];
    wizdom_value: number | null;
}

export interface PostLike {
    id: number;
    user_id: string;
    nickname: string;
}

export type PostCategory = '전체' | '일자리' | '자격증' | '재무' | '건강' | '자녀' | '부모 케어' | '취미' | '자유 주제';
export const postCategoryItems: PostCategory[] = ['전체', '일자리', '자격증', '재무', '건강', '자녀', '부모 케어', '취미', '자유 주제'];
