import React, { useState } from "react";
import { DefaultPageRoot } from "@components/PageTemplate"
import { BackNavToolbar } from "@components/Toolbars"
import { useDispatch, useSelector } from "react-redux";
import { updateUuidHash } from "@reducer/userSlice";
import { RootState } from "@reducer/store";

const DevConfigView = () => {
    const [ug, setUg] = useState<number>(-1);
    const uuidHash = useSelector((state: RootState) => state.user.uuidHash)
    const dispatch = useDispatch();

    function handleSetUserGroup() {
        if (0 <= ug && ug < 100) {
            dispatch(updateUuidHash(ug))
            setUg(-1)
        }
    }

    return (
        <DefaultPageRoot 
            top={<BackNavToolbar title={"설정(개발용)"} />}
            middle={<>
                <label>User Group({uuidHash})</label>
                <input type="number" value={ug} onChange={(e) => setUg(parseInt(e.target.value))} />
                <button onClick={handleSetUserGroup}>설정</button>
            </>}

        />
    )
}

export default DevConfigView