import server_domain from "@src/components/RemoteServer";
import { fetchWithRetry } from "@src/util/networks"

export const updateFCMToken = async ({deviceId, token}: {deviceId: string, token: string}) => {
    await fetchWithRetry(server_domain() + "/user/push-token", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "device_id": deviceId,
          "token": token
        })
    });
}
