import FilterResultFooter from "./FilterResultFooter"
interface Props {
    filterEnabled?: boolean
}
const NoContentView = ({filterEnabled = true}: Props) => {
    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{ display:'flex', flexDirection:'column', margin: 0, lineHeight:1.5, textAlign: 'center', gap: '40px', height: '100%', justifyContent: 'center'}}>
                <b>검색 결과가 없습니다.</b>
                <span>필터를 다르게 하면 좋은 일자리가<br/> 더 많을 수 있습니다.</span>
            </div>
            {filterEnabled && <FilterResultFooter style={{flex: '1', bottom: '0', width: '80%'}}/>}
        </div>
    )
}

export default NoContentView
