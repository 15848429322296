
import icFilter from '@assets/ic_filter.webp';
import { CSSProperties } from 'react';

interface FilterProps extends CSSProperties {
    filterCount: number;
    onClick?: () => void;
}

const Filter = (props: FilterProps) => {
    return (
        <div style={{
            ...props,
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
            }}
            onClick={props.onClick}
        >
            <div style={{
                borderRadius: '5px',
                border: '1px solid #999999',
                padding: '7px 10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
            }}>
                <img src={icFilter} style={{width: '15px', height: '15px'}}/>
                <span style={{fontSize: '0.875rem'}}>필터</span>
            </div>

            <div style={{fontSize: '0.875rem', color: '#474D5B'}}><span style={{color: props.filterCount !== 0 ? '#0ABF53' : undefined }}>{props.filterCount}개</span> 추가 필터 적용 중</div>
        </div>
    );
}

export default Filter;
