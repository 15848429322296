import IcClose from '@assets/close.svg';
import { Outlet } from 'react-router-dom';
import {isForPartners} from "@util/isForPartners";
import {useEffect, useState} from "react";
import {BackNavToolbar} from "@components/Toolbars";

interface DrawerTemplateProps {
    title?: string,
    subtitle?: string,
    children: React.ReactNode,
    handleOnClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void,
    closeButton: boolean,
    style?: React.CSSProperties,
}
export const DrawerTemplate = ({ title, subtitle, children, handleOnClick, closeButton, style}: DrawerTemplateProps) => {
    return (
        <>
            <div style={{ padding: '24px 0 0px'}}>
                {
                    title &&
                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 0 20px',  padding: '0 16px 0',}}>
                        <div style={{ flex: 1 }}>
                            <span style={{ fontWeight: 500, fontSize: '1.25rem', color: '#1D1D1D' }}>{title}</span>
                            {subtitle && <span style={{ fontSize: '0.875rem', color: '#999', marginLeft: '8px' }}>{subtitle}</span>}
                        </div>
                        {closeButton && <IcClose onClick={handleOnClick} /> }
                    </div>
                }
                <div style={{padding: '0px 16px 24px', ...style}}>
                    {children}
                </div>
            </div>
        </>
    )
}


export const TitleCenterDrawerTemplate = ({ title, children, handleOnClick, closeButton, style}: DrawerTemplateProps) => {
    return (
      <>
          <div style={{ padding: '24px 0 0px'}}>
              {
                title &&
                <div style={{display: 'flex', alignItems: 'center', margin: '0 0 20px', padding: '0 16px 0',}}>
                    <div style={{flex: 1}}/>
                    <span style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)', fontWeight: 500, fontSize: '1.25rem', color: '#1D1D1D'}}>{title}</span>
                    {closeButton && <IcClose onClick={handleOnClick}/>}
                </div>
              }
              <div style={{padding: '0px 16px 24px', ...style}}>
                  {children}
              </div>
          </div>
      </>
    )
}


interface DialogDrawerTemplateProps {
    show: boolean,
    onClose: () => void,
    title?: string,
    subtitle?: string,
    children: React.ReactNode,
    style?: React.CSSProperties,
    closeButton?: boolean,
}
const DialogDrawerTemplate = ({ show, onClose, title, subtitle, children, style, closeButton=true}: DialogDrawerTemplateProps) => {
    const handleClose: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        onClose();
    }

    if (!show) return null;
    return (
        <>
            <div style={{ position: 'absolute', top: 0, left: 0, height: '100vh', maxWidth: isForPartners ? 800 : 650, width: '100vw', background: '#000000B3', zIndex: 1 }} onClick={handleClose} >
                <div onClick={(e) => e.stopPropagation()} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', borderRadius: 5, width: "90%" }}>
                    <DrawerTemplate title={title} subtitle={subtitle} children={children} handleOnClick={onClose} closeButton={closeButton} style={style}/>
                </div>
            </div>
            <Outlet />
        </>
    )
}


const TitleCenterDialogDrawerTemplate = ({ show, onClose, title, children, style, closeButton=true}: DialogDrawerTemplateProps) => {
    const handleClose: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        onClose();
    }

    if (!show) return null;
    return (
      <>
          <div style={{ position: 'absolute', top: 0, left: 0, height: '100vh', maxWidth: isForPartners ? 800 : 650, width: '100vw', background: '#000000B3', zIndex: 1 }} onClick={handleClose} >
              <div onClick={(e) => e.stopPropagation()} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', borderRadius: 5, width: "90%" }}>
                  <TitleCenterDrawerTemplate title={title} children={children} handleOnClick={onClose} closeButton={closeButton} style={style}/>
              </div>
          </div>
          <Outlet />
      </>
    )
}

interface BottomDrawerTemplateProps {
    show: boolean,
    onClose: () => void,
    title: string,
    subtitle?: string,
    children: React.ReactNode,
    style?: React.CSSProperties,
    closeButton?: boolean,
}

const BottomDrawerTemplate = ({ show, onClose, title, subtitle, children, style, closeButton=true}: BottomDrawerTemplateProps) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      if (show) {
        setTimeout(() => {
          setShowModal(true);
        }, 10)
      }
      return () => {
        setShowModal(false)
      }
    }, [show]);

    const animationStyle = {
      transform: showModal ? 'translateY(-100vh)' : '',
      bottom: '-100vh',
      transition: 'all 400ms ease', // smooth transition
    }

    const handleClose: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        onClose();
    }

    if (!show) return null;
    return (
        <>
            <div style={{ position: 'absolute', height: '100vh', width: '100vw', top: 0, left: 0, maxWidth: isForPartners ? 800 : 650, background: '#000000B3', zIndex: 1 }} onClick={handleClose}>
                <div onClick={(e) => e.stopPropagation()} style={{ position: 'absolute', width: '100%', background: 'white', borderRadius: '10px 10px 0 0', ...animationStyle }}>
                    <DrawerTemplate title={title} subtitle={subtitle} children={children} handleOnClick={onClose} closeButton={closeButton} style={style}/>
                </div>
            </div>
            <Outlet />
        </>
    );
}

interface FullscreenDrawerTemplateProps {
    show: boolean,
    onClose: () => void,
    title?: string,
    children: React.ReactNode,
}

const FullscreenDrawerTemplate = ({ show, onClose, title, children}: FullscreenDrawerTemplateProps) => {
    const handleClose: React.MouseEventHandler = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onClose();
    }
    if (!show) return null;
    return (
      <>
          <div style={{ position: 'absolute', height: '100vh', maxWidth: 650, width: '100vw', background: 'white', zIndex: 1 }} onClick={handleClose} >
              <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                  <div style={{padding: 16}} onClick={handleClose}>
                      <IcClose />
                  </div>
                    <div style={{flex: 1}}>
                        {children}
                    </div>
              </div>
          </div>
          <Outlet />
      </>
    )
}


export { DialogDrawerTemplate, BottomDrawerTemplate, FullscreenDrawerTemplate, TitleCenterDialogDrawerTemplate };