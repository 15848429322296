import HistoryItem from "@src/features/job/features/history/component/HistoryItem";
import HistoryDot from "@src/features/job/features/history/component/HistoryDot";
import React, {useState} from "react";
import {BottomDrawerTemplate} from "@components/DrawerTemplate";
import {FillButton, SecondaryFillButton} from "@components/Buttons";
import ActivityHistoryLoader from "@src/features/job/features/history/component/ActivityHistoryLoader";
import ActivityHistoryViewTemplate from "@src/features/job/features/history/component/ActivityHistoryViewTemplate";
import {useBlocker, useParams} from "react-router-dom";
import trackEvent from "@util/trackEvent";
import {EVENT_APPLY_HISTORY_EVENT_INPUT_CLICK, EVENT_APPLY_HISTORY_EVENT_OK_CLICK, EVENT_JOB_HISTORY_DETAIL_PAGE_SHOW} from "@components/AppConstant";

const EventList = ["apply_method_checked", "applied", "interview", "final_passed"]
const EventDescription: {[key: string]: string[]} = {
  "apply_method_checked": ["본 공고에 지원하셨다면 알려주세요."],
  "applied": ["면접 일정이 잡혔다면 알려주세요."],
  "interview": ["면접을 잘 진행하신 후 합격하셨다면 알려주세요."],
  "final_passed": ["합격을 축하드립니다!\n합격 축하금 당첨시 연락드리겠습니다."],
}

const History = ({events, setShowAppliedForm, setShowInterviewForm, setShowFinalPassedForm, lastEvent}: { events: any, setShowAppliedForm: (show: boolean) => void, setShowInterviewForm: (show: boolean) => void, setShowFinalPassedForm: (show: boolean) => void, lastEvent: string }) => (
  <>
    <HistoryItem status={"apply_method_checked"} events={events} lastItem={lastEvent === "apply_method_checked"}/>
    <HistoryDot status={"apply_method_checked"} events={events}/>
    <HistoryItem status={"applied"} events={events} onClick={() => setShowAppliedForm(true)} lastItem={lastEvent === "applied"}/>
    <HistoryDot status={"applied"} events={events}/>
    <HistoryItem status={"interview"} prerequisites={["applied"]} events={events} onClick={() => setShowInterviewForm(true)} lastItem={lastEvent === "interview"}/>
    <HistoryDot status={"interview"} events={events}/>
    <HistoryItem status={"final_passed"} prerequisites={["interview"]} events={events} onClick={() => setShowFinalPassedForm(true)} lastItem={lastEvent === "final_passed"}/>
    {
      lastEvent in EventDescription && <div style={{background: '#F4F4F4', borderRadius: 5, padding: '10px 0', marginTop: 30, lineHeight: 1.5}}>
        {
          EventDescription[lastEvent].length === 1 ?
            <div style={{padding: '0px 20px 0px 20px'}}>
              <span style={{whiteSpace: 'pre-wrap'}}>{EventDescription[lastEvent][0]}</span>
            </div>
            :
            <ul style={{padding: '0px 20px 0px 40px'}}>
              {EventDescription[lastEvent].map((desc, idx) => <li key={idx}><span>{desc}</span></li>)}
            </ul>
        }
        </div>
    }
  </>
)

const DefaultActivityHistoryView = () => {
  const {historyId} = useParams();
  const [showAppliedForm, setShowAppliedForm] = useState(false)
  const [showInterviewForm, setShowInterviewForm] = useState(false)
  const [showFinalPassedForm, setShowFinalPassedForm] = useState(false)
  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (showAppliedForm) {
      setShowAppliedForm(false)
      return true
    }
    if (showInterviewForm) {
      setShowInterviewForm(false)
      return true
    }
    if (showFinalPassedForm) {
      setShowFinalPassedForm(false)
      return true
    }
    return false
  });

  const onShowAppliedForm = (show: boolean) => {
    trackEvent(EVENT_APPLY_HISTORY_EVENT_INPUT_CLICK, {event: "applied", history_id: historyId})
    setShowAppliedForm(show)
  }

  const onShowInterviewForm = (show: boolean) => {
    trackEvent(EVENT_APPLY_HISTORY_EVENT_INPUT_CLICK, {event: "interview", history_id: historyId})
    setShowInterviewForm(show)
  }

  const onShowFinalPassedForm = (show: boolean) => {
    trackEvent(EVENT_APPLY_HISTORY_EVENT_INPUT_CLICK, {event: "final_passed", history_id: historyId})
    setShowFinalPassedForm(show)
  }

  const submitApplied = (saveEvent: (status: string) => void) => {
    saveEvent("applied")
    setShowAppliedForm(false)
  }

  const submitInterview = (saveEvent: (status: string) => void) => {
    saveEvent("interview")
    setShowInterviewForm(false)
  }

  const submitFinalPassed = (saveEvent: (status: string) => void) => {
    saveEvent("final_passed")
    setShowFinalPassedForm(false)
  }

  return <ActivityHistoryLoader eventList={EventList}>
    {(history, lastEvent, saveEvent) => <>
      <ActivityHistoryViewTemplate history={history} pageShowRegStr={"\/activity_history\/[^/]+$"}>
        <History events={history.events} setShowAppliedForm={onShowAppliedForm} setShowInterviewForm={onShowInterviewForm} setShowFinalPassedForm={onShowFinalPassedForm} lastEvent={lastEvent}/>
      </ActivityHistoryViewTemplate>
      <BottomDrawerTemplate show={showAppliedForm} onClose={() => setShowAppliedForm(false)} title={"지원 완료 기입"}>
        <p style={{margin: 0, lineHeight: 1.5}}>본 공고에 지원하셨나요? 지원을 완료하셨다면 "네"를 눌러주세요.</p>
        <div style={{display: 'flex', gap: 16, marginTop: 16}}>
          <SecondaryFillButton text={"아니오"} onClick={() => setShowAppliedForm(false)}/>
          <FillButton text={"네"} onClick={() => {trackEvent(EVENT_APPLY_HISTORY_EVENT_OK_CLICK, {event: "applied", history_id: historyId}); submitApplied(saveEvent)}}/>
        </div>
      </BottomDrawerTemplate>
      <BottomDrawerTemplate show={showInterviewForm} onClose={() => setShowInterviewForm(false)} title={"면접 진행 기입"}>
        <p style={{margin: 0, lineHeight: 1.5}}>채용담당자로부터 면접 일정을 받으셨나요? 정확한 면접 일정을 받으셨다면 "네"를 눌러주세요.</p>
        <div style={{display: 'flex', gap: 16, marginTop: 16}}>
          <SecondaryFillButton text={"아니오"} onClick={() => setShowInterviewForm(false)}/>
          <FillButton text={"네"} onClick={() => {trackEvent(EVENT_APPLY_HISTORY_EVENT_OK_CLICK, {event: "interview", history_id: historyId}); submitInterview(saveEvent)}}/>
        </div>
      </BottomDrawerTemplate>
      <BottomDrawerTemplate show={showFinalPassedForm} onClose={() => setShowFinalPassedForm(false)} title={"최종 합격 기입"}>
        <p style={{margin: 0, lineHeight: 1.5}}>채용담당자로부터 합격 통보를 받으셨나요? 최종 합격하셨다면 "네"를 눌러주세요</p>
        <div style={{display: 'flex', gap: 16, marginTop: 16}}>
          <SecondaryFillButton text={"아니오"} onClick={() => setShowFinalPassedForm(false)}/>
          <FillButton text={"네"} onClick={() => {trackEvent(EVENT_APPLY_HISTORY_EVENT_OK_CLICK, {event: "final_passed", history_id: historyId}); submitFinalPassed(saveEvent)}}/>
        </div>
      </BottomDrawerTemplate>
    </>}
  </ActivityHistoryLoader>
}
export default DefaultActivityHistoryView;