import {DialogDrawerTemplate} from "@components/DrawerTemplate";
import {FillButton} from "@components/Buttons";
import {useDispatch, useSelector} from "react-redux";
import {setResumes} from "@reducer/userSlice";
import {fetchWithRetry} from "@util/networks";
import server_domain from "@components/RemoteServer";
import {RootState} from "@reducer/store";

const ConfirmDeleteResume = ({resumeId, showModal, setShowModal, onDeleted}: {resumeId: string, showModal: boolean, setShowModal: (showModal: boolean) => void, onDeleted: (title: string) => void}) => {
  const dispatch = useDispatch();
  const resume = useSelector((state: RootState) => state.user.resumes).find(resume => resume.id === resumeId)

  const uuid = useSelector((state: RootState) => state.user.uuid);

  const remove = () => {
    fetchWithRetry(server_domain() + "/resume", {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "uuid": uuid,
        "resume_id": resumeId
      })
    })
      .then(res => {
        if (res.ok) {

          return res.json()
        }
      })
      .then(data => {
        dispatch(setResumes(data))
        setShowModal(false)
        onDeleted(resume?.title || "")
      })
  }

  return <DialogDrawerTemplate show={showModal} onClose={() => setShowModal(false)}>
    <p style={{whiteSpace: 'pre-wrap', textAlign: 'center', fontWeight: 500, lineHeight: 1.5, margin: 0}}>
      {`${resume?.title} 이력서를\n정말로 삭제하시겠어요?\n한 번 삭제하면 복구는 불가합니다.`}
    </p>
    <div style={{display: 'flex', gap: 10, marginTop: 30}}>
      <FillButton text={"취소"} style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}} onClick={() => setShowModal(false)}/>
      <FillButton text={"삭제하기"} style={{background: '#F15D47'}} onClick={remove}/>
    </div>
  </DialogDrawerTemplate>
}

export default ConfirmDeleteResume;