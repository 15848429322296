import { Outlet, ScrollRestoration } from "react-router-dom"
import {isForPartners} from "@util/isForPartners";

const DefaultPageRoot = ({ top, middle, bottom, middleRef, modal, style, outletContext }: {
    top?: React.ReactNode,
    middle: React.ReactNode,
    bottom?: React.ReactNode,
    middleRef?: React.RefObject<HTMLDivElement>
    modal?: React.ReactPortal | React.ReactNode,
    style?: React.CSSProperties,
    outletContext?: any
}) => {
    return (
        <div id="modal-root" style={{ zIndex: 1, display: 'flex', flexDirection: 'column', width: '100vw', maxWidth: isForPartners ? 800 : 650, height: '100vh', position: 'absolute', background: 'white', ...style }} >
            {top}
            <div style={{ flex: 1, overflowY: 'scroll' }} ref={middleRef}>
                {middle}
            </div>
            {bottom}
            {modal}
            <Outlet context={outletContext}/>
        </div>
    )
}

const DefaultTabPageRoot = ({ middleRef, children ,style }: {
  children: React.ReactNode,
  middleRef?: React.RefObject<HTMLDivElement>
  style?: React.CSSProperties,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', maxWidth: isForPartners ? 800 : 650, height: '100vh', position: 'absolute', background: 'white', ...style }} >
      <div style={{flex: 1, overflowY: 'auto'}} ref={middleRef}>
        <Outlet/>
      </div>
      <div style={{width: '100%', background: 'white', display: 'flex', borderTop: "1px solid #f4f4f4", padding: '5px 0 5px'}}>
        {children}
      </div>
    </div>
  )
}

const DefaultTabTemplate = ({top, middle, middleRef, bottom, modal, style, outletContext }: {
  top?: React.ReactNode,
  middleRef?: React.RefObject<HTMLDivElement>
  middle: React.ReactNode,
  bottom?: React.ReactNode,
  modal?: React.ReactPortal | React.ReactNode,
  style?: React.CSSProperties,
  outletContext?: any
}) => {
  return (
    <div style={{overflowY: 'hidden', height: '100%', display: 'flex', flexDirection: 'column', width: '100vw', maxWidth: isForPartners ? 800 : 650, background: 'white', ...style }} >
      {top}
      <div style={{ flex: 1, overflowY: 'scroll' }} ref={middleRef}>
        {middle}
      </div>
      {bottom}
      {modal}
      <Outlet context={outletContext}/>
    </div>
  )
}

export { DefaultPageRoot, DefaultTabPageRoot, DefaultTabTemplate }