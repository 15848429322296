import React, {useEffect, useMemo} from "react";

const useAutoBlurObserver = (ref: React.RefObject<any>) => {
  let timer: ReturnType<typeof setTimeout> | null = null;
  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => {
      if (!entry.isIntersecting) {
        timer = setTimeout(() => {
          ref.current?.blur();
        }, 800);
      } else {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
      }
    }, {
      threshold: 0
    }), [ref])
  useEffect(() => {
    return () => {
      observer.disconnect()
    }
  }, [])
  return observer
}

export default useAutoBlurObserver;