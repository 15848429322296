import { Activity } from "@model/Activity";

export const isActivityClosed = (activity: Activity) => {
    if (activity.status == "outdated") {
        return true;
    }
    if (activity.activityRecruitmentEndDate != "") {
        var now = new Date(Date.now());
        var dueDate: Date
        if (!!activity.activityRecruitmentEndTime) {
            dueDate = new Date(activity.activityRecruitmentEndDate + " " + activity.activityRecruitmentEndTime)
        } else {
            now = new Date(now.setHours(0, 0, 0, 0))
            dueDate = new Date(activity.activityRecruitmentEndDate + " " + "00:00")
        }
        return (now > dueDate)
    }
    return false;
}

export const calculateRemainDate = (activity: Activity, onlyDate: boolean) => {
    var now = new Date(Date.now());
    var nowToStartTime = new Date(Date.now());
    var nowToEndTime = new Date(Date.now());

    if (!!activity.activityRecruitmentStartDate) {
      var startDate: Date;
      if (!!activity.activityRecruitmentStartTime) {
        startDate = new Date(activity.activityRecruitmentStartDate + " " + activity.activityRecruitmentStartTime)
      } else {
        nowToStartTime = new Date(now.setHours(0, 0, 0, 0))
        startDate = new Date(activity.activityRecruitmentStartDate + " " + "00:00")
      }

      var beforeDate = Math.floor((Number(nowToStartTime) - Number(startDate)) / (1000 * 60 * 60 * 24))
      if (beforeDate < 0) return beforeDate
    }

    var endDate: Date
    if (!!activity.activityRecruitmentEndTime && onlyDate != true) {
      endDate = new Date(activity.activityRecruitmentEndDate + " " + activity.activityRecruitmentEndTime)
    } else {
      nowToEndTime = new Date(now.setHours(0, 0, 0, 0))
      endDate = new Date(activity.activityRecruitmentEndDate + " " + "00:00")
    }

    return Math.floor((Number(endDate) - Number(nowToEndTime)) / (1000 * 60 * 60 * 24))
}

export const getSalaryInfo = (activity: Activity) => {
  if (activity.activityReward == null || activity.activityReward == "" || activity.activityReward == "0원") {
      return ""
  } else if (activity.activityReward == "협의") {
      return "급여 협의"
  }
  return activity.activityReward
}