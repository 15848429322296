

const lightMedium = {
    fontWeight: 300, 
    fontSize: '1.1rem',
}

const normalMedium = {
    fontWeight: 500, 
    fontSize: '1.25rem',
}

const lighterLarge = {
    fontWeight: 300, 
    fontSize: '1.25rem',
}

const normalLarge = {
    fontWeight: 400, 
    fontSize: '1.25rem',
}

const boldLarge = {
    fontWeight: 500, 
    fontSize: '1.25rem',
}

const primaryColor = "#0ABF53"
const secondaryColor = "#EEF9F2"
const secondaryBlackColor = "#1D1D1D"
const thirdColor = "#F4F4F4"
const inactiveColor = "#D9D9D9"
const redColor = "#F15D47"
const a0Color = "#A0A0A0"

export {
    lightMedium,
    normalMedium,
    lighterLarge,
    normalLarge,
    boldLarge,
    primaryColor,
    secondaryColor,
    secondaryBlackColor,
    thirdColor,
    inactiveColor,
    redColor,
    a0Color
}