import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {FillButton, IconFillButtonCenter} from "@components/Buttons";
import {YMDInput2} from "@components/form/ResumeComponent";
import {useState} from "react";
import NoticeBanner from "@src/features/job/component/NoticeBanner";
import IcReset from "@assets/ic_reset.svg";
import trackEvent from "@util/trackEvent";
import {EVENT_AGE_CALC_CLICK} from "@components/AppConstant";
import {calculateKoreaAge, calculateManAge} from "@src/features/age_calculator/util/CalculateAge";

const AgeCalculatorPage = () => {
  const today = new Date().toISOString().slice(0, 10).replace(/-/g, "")
  const [birth, setBirth] = useState<string>("")
  const [baseDate, setBaseDate] = useState<string>(today)

  const [manAge, setManAge] = useState<string>("")
  const [koreaAge, setKoreaAge] = useState<string>("")

  const available = () => {
    const birthYear = birth.slice(0, 4)
    const birthMonth = birth.slice(4, 6)
    const birthDay= birth.slice(6, 8)
    const maxBirthDay = new Date(parseInt(birthYear), parseInt(birthMonth), 0).getDate();

    const baseDateYear = baseDate.slice(0, 4)
    const baseDateMonth = baseDate.slice(4, 6)
    const baseDateDay = baseDate.slice(6, 8)
    const maxBaseDateDay = new Date(parseInt(baseDateYear), parseInt(baseDateMonth), 0).getDate();

    return birthYear !== "0000"
      && birthMonth !== "00"
      && birthDay !== "00"
      && parseInt(birthDay) <= maxBirthDay
      && baseDateYear !== "0000"
      && baseDateMonth !== "00"
      && baseDateDay !== "00"
      && parseInt(baseDateDay) <= maxBaseDateDay
      && parseInt(birthYear) <= parseInt(baseDateYear)
  }
  const onReset = () => {
    setManAge("")
    setKoreaAge("")
    setBirth("00000000")
    setBaseDate(today)
  }

  const ddd = (_: number) => {
    setManAge("")
    setKoreaAge("")
  }

  const onClick = () => {
    trackEvent(EVENT_AGE_CALC_CLICK, {})
    setManAge(calculateManAge(birth, baseDate))
    setKoreaAge(calculateKoreaAge(birth, baseDate))
  }

  return <DefaultPageRoot
    top={<BackNavToolbar title={"나이 계산기"}/>}
    middle={<>
      <div style={{padding: '0px 16px'}}>
        <NoticeBanner text={"만 나이, 세는 나이\n정확히 알면 도움됩니다!"} />
        <YMDInput2 label={"생년월일"} value={birth} onChange={setBirth} onFocus={ddd} placeHolder={"19600315"}/>
        <YMDInput2 label={"기준일"} value={baseDate} onChange={setBaseDate} onFocus={ddd} placeHolder={today}/>
        <div style={{display: 'flex', marginTop: 20}}>
          <div style={{flex: 1, margin: 'auto', padding: '5%'}}>
            <p style={{margin: 0, lineHeight: 1.5, fontSize: '1.25rem', fontWeight: 700}}>만 나이</p>
            <p style={{margin: 0, lineHeight: 1.5}}>법적 기준 나이</p>
            <p style={{margin: 0, lineHeight: 1.5, fontWeight: 700, textAlign: 'center'}}>
              <span style={{fontSize: '1.25rem', marginRight: 6}}>{manAge ? "만" : ""}</span>
              <span style={{fontSize: '2rem', marginRight: 6}}>{manAge ? `${manAge}세` : ""}</span>
            </p>
          </div>
          <div style={{flex: 1, margin: 'auto', padding: '5%'}}>
            <p style={{margin: 0, lineHeight: 1.5, fontSize: '1.25rem', fontWeight: 700}}>세는 나이</p>
            <p style={{margin: 0, lineHeight: 1.5}}>통상적 한국 나이</p>
            <p style={{margin: 0, lineHeight: 1.5, fontWeight: 700, textAlign: 'center'}}>
              <span style={{fontSize: '2rem'}}>{koreaAge ? `${koreaAge}세` : "" }&nbsp;</span>
            </p>
          </div>
        </div>
      </div>
    </>}
    bottom={<div style={{padding: 16}}>
      {
        manAge ?
          <IconFillButtonCenter
            text={"초기화"}
            onClick={onReset}
            style={{background: '#F4F4F4' , justifyContent: 'start'}}
            fontStyle={{color: 'black'}}
            icon={<IcReset />}
          />
          :
          <FillButton
            active={available()}
            text={"계산하기"}
            onClick={onClick}
          />
      }
    </div>}
  />
}

export default AgeCalculatorPage;