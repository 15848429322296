import {useNavigate} from "react-router-dom";
import ActivityCardTemplate from "@src/features/job/component/ActivityCardTemplate";
import {ButtonTextStyle} from "@src/features/job/features/history/style/HistoryCardFont";
import {ApplyHistory} from "@src/features/job/features/history/model/ApplyHistory";
import ApplyRemainDate from "@src/features/job/component/ApplyRemainDate";
import trackEvent from "@util/trackEvent";
import {EVENT_APPLY_HISTORY_CLICK, EVENT_FAQ_CLICK, EVENT_FAQ_ENTRY_CLICK} from "@components/AppConstant";

const DefaultActivityHistoryCard = ({history, location, last, regions = null, sessionId}: {
  history: ApplyHistory
  location: string,
  last?: boolean,
  regions: string[] | null,
  sessionId?: string
}) => {
  const navigate = useNavigate();

  const handleClick = () => navigate("activity/" + history.activity.id, {state: {previous_location: location}});

  return <ActivityCardTemplate
    Badges={<>
    {
        history.activity.reserved === 1 &&
          <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px', gap: "0.3rem"}}>
              <span style={{display: 'inline-block', fontWeight: '500', fontSize: '0.75rem', lineHeight: '1.2rem', verticalAlign: 'middle', backgroundColor: '#EEF9F2', borderRadius: '5px', padding: '0 16px', color: '#0ABF53'}}>5060 채용 약속</span>
          </div>
      }
    </>}
    Buttons={<>
      <ApplyRemainDate activity={history.activity} style={{marginTop: 4, fontWeight: 300}}/>
      <div style={{display: 'flex', gap: 12, marginTop: 8, fontSize: '1rem', textAlign: 'center',}}>
        <p style={ButtonTextStyle} onClick={e => {
          e.stopPropagation();
          trackEvent(EVENT_APPLY_HISTORY_CLICK, {location: 'DefaultApplyHistoryCard'})
          navigate("activity_history/" + history.id)
        }}>
          지원현황
        </p>
        <p style={ButtonTextStyle} onClick={e => {
          e.stopPropagation();
          trackEvent(EVENT_FAQ_ENTRY_CLICK, {location: 'DefaultApplyHistoryCard'})
          navigate("faq/" + history.activity.activityId)
        }}>
          지원 도움 받기
        </p>
      </div>
    </>
    }
    activity={history.activity}
    location={location}
    regions={regions}
    onClick={handleClick}
    useScrap={false}
  />
}

export default DefaultActivityHistoryCard;