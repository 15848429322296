import { useRef } from "react";
import IcPin from '@assets/ic_pin.svg';
import IcMoneyCircle from '@assets/ic_money_circle.webp';
import { secondaryBlackColor } from "@components/styles";
import { useSelector } from "react-redux";
import useOnScreenActivity from "@components/useOnScreenActivity";
import formatRegions from "@util/formatRegions";
import { RootState } from "@reducer/store";
import { Activity } from "@model/Activity";
import { getSalaryInfo } from "@util/activityHelper";

const ActivityCardContent = (prop: {
    activity: Activity,
    location: string,
    sessionId?: string
}) => {
    const { activity, location, sessionId } = prop;
    const ref = useRef(null);
    const userRegions = useSelector((state: RootState) => state.user.regions);
    useOnScreenActivity(ref, activity, location, sessionId);

    function convertRegion(activityRegionList: string) {
        try {
            return formatRegions(activityRegionList, userRegions)
        } catch (err) {
            return ""
        }
    }

    return (
      <div style={{margin: '0px 0px 0px'}} ref={ref}>
          <div style={{position: 'relative', wordBreak: 'break-all', textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical", padding: '0px 0px 5px',}}>
              <p style={{margin: 0, lineHeight: 1.5, textOverflow: "ellipsis", WebkitBoxOrient: "vertical", color: '#666', fontWeight: 300, fontSize: '1rem'}}>{activity.activityOrganization}</p>
              <p style={{margin: 0, fontSize: '1.125rem', color: secondaryBlackColor, lineHeight: 1.5, textOverflow: "ellipsis", WebkitLineClamp: "1", WebkitBoxOrient: "vertical", fontWeight: 500}}>{activity.activityTitle}</p>
          </div>
          <div style={{display: 'flex'}}>
              <div>
                  <div style={{display: 'flex', marginTop: '0.1rem', alignItems: 'flex-start'}}>
                      <IcPin style={{height: '1rem', width: '1rem', margin: '0px', marginLeft: '-0.05rem', marginTop: '0.3rem', marginRight: '0.35rem'}}/>
                      <p style={{margin: 0, fontSize: '1rem', fontWeight: 300, lineHeight: 1, paddingTop: '0.3rem', textOverflow: "ellipsis", WebkitLineClamp: "1", overflow: "hidden", display: "-webkit-box", WebkitBoxOrient: "vertical", height: '1.2rem', color: '#666666'}}> {convertRegion(activity.activityRegionList)} </p>
                  </div>
                  {
                    activity.activityReward && getSalaryInfo(activity) != "" &&
                    <div style={{display: 'flex', marginTop: '0.1rem', alignItems: 'flex-start'}}>
                        <img src={IcMoneyCircle} alt="" width={18} height={18} style={{alignSelf: 'center', margin: '0px', marginTop: '0.1rem', marginRight: '0.4rem'}}/>
                        <p style={{margin: 0, fontSize: '1rem', fontWeight: 300, lineHeight: 1, color: '#666666', paddingTop: '0.3rem', textOverflow: "ellipsis", overflow: "hidden", display: "-webkit-box", WebkitBoxOrient: "vertical", height: '1.2rem',}}> {getSalaryInfo(activity)} </p>
                    </div>
                  }
              </div>
          </div>
      </div>
    );
}

export default ActivityCardContent;