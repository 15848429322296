import { useParams } from "react-router-dom";
import server_domain from "@components/RemoteServer";
import { useState } from "react";
import { fetchWithRetry } from "@util/networks";

const RedirectView = () => {
    const { redirect_activity_id } = useParams()
    const [showError, setShowError] = useState(false);

    async function redirectActivityOriginDetail() {
        let response = await fetchWithRetry(server_domain() + '/redirect_info?activity_id=' + redirect_activity_id, {
            headers: {"Content-Type": "application/json"},
        })
        let json = await response.json()
        if (json.succeeded) {
            const form = document.createElement('form'); // form 태그 생성
            form.setAttribute('action', json.url); // "https://dobong.eminwon.seoul.kr/emwp/gov/mogaha/ntis/web/ofr/action/OfrAction.do"
            form.setAttribute('method', json.method); // post
            for (const [key, value] of Object.entries(json.params)) {
                const data = document.createElement('input');
                data.setAttribute('type', 'hidden');
                data.setAttribute('name', key);
                data.setAttribute('value', value as string);
                form.appendChild(data);
            }
            document.body.appendChild(form);
            form.submit();
        } else {
            setShowError(true);
        }
    }
    redirectActivityOriginDetail()
    return (
        <div>
            {showError && <div>에러가 발생했습니다.</div>}
        </div>
    )
}

export default RedirectView