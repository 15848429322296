import { FillButton } from "@src/components/Buttons";
import { fontSize } from "@src/components/font";
import { DefaultPageRoot } from "@src/components/PageTemplate";
import { MediumText_22 } from "@src/components/text";
import { BackNavToolbar } from "@src/components/Toolbars";
import { useState, useRef } from "react";
import CommunityService from "../infra/CommunityService";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { changeNickname } from "@src/reducer/userSlice";
import { show } from "@src/reducer/snackbarSlice";
import Modal from "@src/components/Modal";
import trackEvent from "@src/util/trackEvent";
import { EVENT_REGISTER_NICKNAME, EVENT_REGISTER_NICKNAME_PAGE_SHOW } from "@src/components/AppConstant";
import { RootState } from "@src/reducer/store";
import { useOnMountOnce } from "@src/util/useOnMountOnce";
import styles from "@src/features/job/features/apply/style/input.module.css";

const SetNicknamePage = () => {
    const ref = useRef<HTMLInputElement>(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [active, setActive] = useState(false)
    const [nickname, setNickname] = useState('')
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
    const deviceId = useSelector((state: RootState) => state.user.deviceId)
    const uuid = useSelector((state: RootState) => state.user.uuid)
    const duplicateNicknameList: string[] = []
    
    const onRegister = async () => {
        if (!uuid) { return }
        
        const result = await CommunityService.setNickname({ userId: uuid, nickname: nickname, deviceId: deviceId })

        if (result === "true") {
            trackEvent(EVENT_REGISTER_NICKNAME, undefined)
            dispatch(changeNickname(nickname))
            // @ts-ignore
            navigate(-1, { replace: true })
            dispatch(show({message: `'${nickname}'으로 별명이 등록되었습니다.`}))
        } else if (result === "used") {
            dispatch(show({message: '이미 사용중인 별명입니다.'}))
            duplicateNicknameList.push(nickname)
        } else {
            dispatch(show({message: '닉네임 설정에 실패했습니다. 다시 시도해주세요.'}))
        }
    }

    const onChange = (value: string) => {
        setNickname(value)

        if (value.length > 10 || value.length < 2) {
            setActive(false)
            return
        }
        setActive(true)
    }

    useOnMountOnce(() => {
        trackEvent(EVENT_REGISTER_NICKNAME_PAGE_SHOW, {});
    })

    return (
        <DefaultPageRoot
            top={BackNavToolbar({ title: '별명 등록' })}
            middle={
                <>
                    <div>
                        <div style={{ paddingTop: '40px', paddingLeft: '15px' }}>
                            <MediumText_22 style={{ display: 'block', lineHeight: '1.5' }}>{'위즈덤 커뮤니티에서 사용할'}</MediumText_22>
                            <MediumText_22>{'별명을 등록해주세요'}</MediumText_22>
                        </div>
                    </div>
                    <div style={{ paddingTop: '50px', overflow: 'hidden' }}>
                        <input
                            className={styles.input}
                            ref={ref}
                            value={nickname}
                            onChange={(e) => onChange(e.target.value)}
                            placeholder="2~10자까지 가능"
                            min={2}
                            max={10}
                            required={true}
                            style={{ 
                                fontSize: fontSize.m,
                                borderRadius: '5px',
                                width: 'calc(100% - 60px)',
                                margin: '0 15px 5px 15px',
                                padding: '15px 15px',
                            }}
                        />
                    </div>
                    { showConfirmModal && 
                        <Modal 
                        message={`'${nickname}'으로\n별명을 등록할까요?`}
                        confirmText="등록"
                        onCancel={() => {
                            setShowConfirmModal(false)
                         }}
                        onConfirm={() => {
                            setShowConfirmModal(false)
                            onRegister()
                        }}/>
                    }
                </>
            }
            bottom={
                <div style={{ padding: '15px' }}>
                    <FillButton
                        text="등록하기"
                        active={active}
                        onClick={() => setShowConfirmModal(true) }
                    />
                </div>
            }
        />
    )
}
export default SetNicknamePage;