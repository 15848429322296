import React, {useEffect, useMemo, useState} from "react";
import {BackNavToolbar} from "@components/Toolbars";
import {useBlocker, useLocation, useNavigate} from "react-router-dom";
import * as amplitude from '@amplitude/analytics-browser';
import {useDispatch, useSelector} from "react-redux";
import {EVENT_CREATE_RESUME_CLICK, EVENT_APPLY_PAGE_SHOW, EVENT_SIMPLE_APPLY_CLICK} from "@components/AppConstant";
import useOnLog from "@components/useOnLog";
import {primaryColor, secondaryColor} from "@components/styles";
import {DefaultPageRoot} from "@components/PageTemplate";
import {RootState} from "@reducer/store";
import {Divider} from "@components/DivideBar";
import {BorderButton, FillButton} from "@components/Buttons";
import {SelectedResumeItem} from "@components/form/ResumeComponent";
import IcBulb from "@assets/ic_bulb.webp";
import {updateLastSelectResumeId, updateLastUsedApplyMethod} from "@reducer/configSlice";
import {DialogDrawerTemplate, FullscreenDrawerTemplate} from "@components/DrawerTemplate";
import {fetchWithRetry} from "@util/networks";
import server_domain from "@components/RemoteServer";
import {setLastUsedResumeId} from "@reducer/userSlice";
import {show} from "@reducer/snackbarSlice";

const HeaderStyle: React.CSSProperties = {lineHeight: '1.5rem', padding: '12px 16px', whiteSpace: 'nowrap', verticalAlign: 'top', borderBottom: "none", width: 'fit-content', color: '#6A6A6A', fontSize: '1rem', fontWeight: 300}

const SimpleApplyView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activity = useMemo(() => location.state.activity, [null])
  const phoneNumber = useSelector((state: RootState) => state.user.phoneNumber)
  const resumes = useSelector((state: RootState) => state.user.resumes)
  const resume = useSelector((state: RootState) => state.user.resume)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showCompleted, setShowCompleted] = useState(false)

  const lastSelectResumeId = useSelector((state: RootState) => state.config.lastSelectResumeId)
  const lastUsedResumeId = useSelector((state: RootState) => state.user.lastUsedResumeId)

  const usedResume = resumes.find(resume => resume.id === lastSelectResumeId) || resumes.find(resume => resume.id === lastUsedResumeId) || resumes[0]
  const resumeId = usedResume?.id || null
  const selectedResume = resumes.find(resume => resume.id === resumeId) || resumes[0]
  const regions = useSelector((state: RootState) => state.user.regions);
  const deviceId = useSelector((state: RootState) => state.user.deviceId);
  let requesting = false
  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (showCompleted) {
      return false
    }

    if (showConfirm) {
      setShowConfirm(false)
      return true
    }
    return false
  });

  useOnLog({
    pageNameKey: EVENT_APPLY_PAGE_SHOW,
    amplitudeProp: {
      "type": "간편지원",
      "activity_id": activity.id,
    },
    regStr: "\/simple_apply$"
  })

  const apply = async () => {
    if (requesting) {
      return;
    }

    requesting = true
    if (activity && resumeId) {
      amplitude.track(EVENT_SIMPLE_APPLY_CLICK, {
        "activity_id": activity.id,
        "user_regions": regions,
        "title": activity.activityTitle,
        "resume_id": resumeId,
      });

      let res = await fetchWithRetry(server_domain() + "/apply_activity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "device_id": deviceId,
          "activity_id": activity.id,
          "resume_id": resumeId
        })
      })
      let data = await res.json();
      if (data.result == "success") {
        dispatch(updateLastUsedApplyMethod("간편지원"))
        dispatch(setLastUsedResumeId(resumeId))
        setShowConfirm(false)
        setShowCompleted(true)
      } else if (data.result == "duplicate_apply") {
        setShowConfirm(false)
        dispatch(show({message: "이미 지원한 공고입니다"}))
      } else {
        setShowConfirm(false)
      }
    }
    requesting = false;
  }

  function createResume() {
    amplitude.track(EVENT_CREATE_RESUME_CLICK, {
      "activity_id": activity.id,
      "phone_number": phoneNumber,
      "location": "SimpleApplyPage",
      "resume_status": resume.finished ? "done" : resume.name ? "editing" : "notyet"
    });
    navigate("create_resume_basic_info", {state: {step: 0}})
  }

  useEffect(() => {
    dispatch(updateLastSelectResumeId(""))
  }, []);

  return (
    <>
      <DefaultPageRoot
        top={<BackNavToolbar title={'간편 지원 (모바일 지원)'}/>}
        middle={<>
          <table>
            <tbody>
            <tr>
              <td colSpan={2}>
                <p style={{margin: '16px 15px 16px', fontSize: '1rem', lineHeight: '1.5rem', fontWeight: 700}}>지원회사 확인</p>
              </td>
            </tr>
            <tr>
              <td style={{verticalAlign: "top", padding: '12px 0',}}>
                <span style={HeaderStyle}>회사명</span>
              </td>
              <td style={{verticalAlign: "top", padding: '12px 0',}}>
                <p style={{margin: 0, lineHeight: 1.5, fontSize: '1rem',}}>{activity.activityOrganization}</p>
              </td>
            </tr>
            <tr>
              <td style={{verticalAlign: "top", padding: '12px 0',}}>
                <span style={HeaderStyle}>공고제목</span>
              </td>
              <td style={{verticalAlign: "top", padding: '12px 0',}}>
                <p style={{margin: 0, lineHeight: 1.5, fontSize: '1rem',}}>{activity.activityTitle}</p>
              </td>
            </tr>
            </tbody>
          </table>
          <Divider/>
          <div style={{padding: '0 16px'}}>
            <p style={{margin: '16px 0', fontSize: '1rem', lineHeight: '1.5rem', fontWeight: 700}}>제출 이력서</p>
            {usedResume !== undefined && <SelectedResumeItem resume={usedResume}/>}
          </div>


          {
            resumes.length === 0 &&
            (
              resume.finished || !resume.name ?
                <div style={{borderRadius: '5px', margin: '0 15px 30px', padding: '20px 30px 40px 30px', background: secondaryColor, textAlign: 'center'}} onClick={createResume}><p style={{color: primaryColor, margin: 0, lineHeight: 1.5}}>이력서 등록 후 지원 가능해요.<br/>한 번만 등록하면 됩니다!</p>
                  <div style={{background: primaryColor, margin: '10px auto 0', borderRadius: '5px', width: '70%', padding: '10px 20px'}}>
                    <p style={{margin: 0, lineHeight: 1.5, color: 'white'}}>이력서 등록하기</p>
                  </div>
                </div>
                :
                <>
                  <div style={{borderRadius: '5px', margin: '0 15px 30px', padding: '20px 30px 40px 30px', background: secondaryColor, textAlign: 'center'}} onClick={createResume}><p style={{color: primaryColor, margin: 0, lineHeight: 1.5}}>덜 작성된 항목이 있어요.<br/>끝까지 작성 후 지원해 주세요!</p>
                    <div style={{background: primaryColor, margin: '10px auto 0', borderRadius: '5px', width: '70%', padding: '10px 20px'}}>
                      <p style={{margin: 0, lineHeight: 1.5, color: 'white'}}>이력서 마저 작성하기</p>
                    </div>
                  </div>
                  <div style={{background: '#F4F4F4', margin: '20px 16px', padding: '1px'}}>
                    <div style={{display: 'flex', alignItems: 'center', margin: '0.875rem 0 0'}}>
                      <img src={IcBulb} alt={""} style={{margin: '0 10px', width: 18, height: 18}}/>
                      <p style={{whiteSpace: 'pre-wrap', fontWeight: 700, margin: 0}}>기존 이력서가 사라졌나요?</p>
                    </div>
                    <p style={{whiteSpace: 'pre-wrap', marginLeft: 10, lineHeight: 1.5, fontSize: '0.875rem'}}>{"6월 3일에 이력서가 업그레이드 되었어요!\n기존 이력서 등록자도 추가 작성이 필요해요."}</p>
                  </div>
                </>
            )
          }
        </>}
        bottom={<div style={{padding: 16}}>
          <FillButton
            active={!!usedResume}
            text={"지원하기"}
            onClick={() => setShowConfirm(true)}
          />
        </div>}
      />

      <DialogDrawerTemplate show={showConfirm} onClose={() => setShowConfirm(false)}>
        <p style={{margin: 0, fontWeight: '500', lineHeight: 1.5, textAlign: 'center'}}>
          <span style={{display: 'block'}}>[{selectedResume?.title || ""}]</span>
          <span style={{display: 'block'}}>이력서로 지원합니다.</span>
          <span style={{display: 'block'}}>중복 지원은 불가합니다.</span>
        </p>

        <div style={{display: 'flex', gap: '20px', margin: '30px 0px 0px'}}>
          <BorderButton text={"취소"} onClick={() => setShowConfirm(false)}/>
          <FillButton text={"지원하기"} onClick={apply}/>
        </div>
      </DialogDrawerTemplate>

      <FullscreenDrawerTemplate show={showCompleted} onClose={() => {navigate(-1);}}>
        <div style={{position: 'relative', height: '100%'}}>
          <div style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>
            <p style={{margin: 0, lineHeight: 1.5, textAlign: 'center', fontWeight: '500', fontSize: '1.125rem', color: 'black'}}>지원이 완료되었습니다!</p>
            <div style={{alignContent: 'center', margin: '40px'}}>
              <p style={{margin: '0 0 20px', lineHeight: 1.5, fontSize: '0.875rem', color: '#666666'}}>참고해주세요.</p>
              <div style={{display: 'flex', marginBottom: 20}}>
                <p style={{margin: 0, lineHeight: 1.5, fontSize: '0.875rem', color: '#666666', marginRight: '4px'}}>1.</p>
                <p style={{margin: 0, lineHeight: 1.5, fontSize: '0.875rem', color: '#666666'}}>하단의 [지원내역] 메뉴에서 간편지원 내역을 확인하실 수 있습니다.</p>
              </div>
              <div style={{display: 'flex'}}>
                <p style={{margin: 0, lineHeight: 1.5, fontSize: '0.875rem', color: '#666666', marginRight: '4px'}}>2.</p>
                <p style={{margin: 0, lineHeight: 1.5, fontSize: '0.875rem', color: '#666666'}}>채용담당자로부터 전화 혹은 문자가 예고없이 올 수 있습니다.</p>
              </div>
            </div>
            <div onClick={(e) => {e.stopPropagation(); navigate(-1);}} style={{margin: '30px 45px', textAlign: 'center', background: '#0ABF53', borderRadius: '5px', padding: '15px'}}>
              <p style={{margin: 0, lineHeight: 1.5, color: 'white', fontSize: '1.125rem', fontWeight: '500'}}>확인</p>
            </div>
          </div>
        </div>
      </FullscreenDrawerTemplate>
    </>
  )
}

export default SimpleApplyView;