import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RegionCityButton from "@components/RegionCityButton"
import IcHomeInactive from '@assets/ic_home_inactive.webp';
import { useDispatch, useSelector } from "react-redux";
import { updateMainPage } from "@reducer/activityCacheSlice";
import { EVENT_ACTIVITY_QUERY_MODE_DROPDOWN_CLICK, EVENT_CLICK_HOME, EVENT_JOB_MAIN_TAB_SHOW, EVENT_REQUEST_MORE_ACTIVITY} from "@components/AppConstant";
import useOnLog from "@components/useOnLog";
import ActivityCard from '@src/features/job/component/ActivityCard';
import SearchBox from "@components/SearchBox";
import server_domain from "@components/RemoteServer";
import { show } from "@reducer/snackbarSlice";
import * as amplitude from '@amplitude/analytics-browser';
import {changeRegions} from "@reducer/userSlice";
import DivideBar from "@components/DivideBar";
import IcArrowRightWhiteBold from '@assets/ic_arrow_right_white_bold.svg';
import Glide from "@glidejs/glide"
import { useVirtualizer } from '@tanstack/react-virtual';
import { useInfiniteQuery } from 'react-query'
import { fetchWithRetry } from '@util/networks';
import { RootState } from "@reducer/store";
import imgJobNewsCard from "/img/img_job_news_card.webp";
import imgPublicBuilding from "/img/img_public_building.webp";
import { Activity } from "@model/Activity";
import IcArrowDown from "@assets/arrow_down.svg";
import trackEvent from "@src/util/trackEvent";
import { primary } from "@src/components/Color";
import {DefaultTabTemplate} from "@src/components/PageTemplate";
import { BlackText_25, MediumText_25 } from "@src/components/text";
import { BackNavToolbar } from "@src/components/Toolbars";
import styles from "@src/features/job/features/apply/style/input.module.css";
import mainPageLoader from "@src/data-layer/mainPageLoader";
import MainPageMenu from "@src/features/job/component/MainPageJobMenu";


interface CarouselOptions {
  type: 'carousel' | 'slider';
  startAt: number;
  perView: number;
  gap: number;
}

const Carousel = forwardRef(({options, children}: { options: CarouselOptions, children: JSX.Element[] }, ref) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => sliderRef.current);

  useEffect(() => {
    if (children.length > 0 && sliderRef.current) {
      const slider = new Glide(sliderRef.current, options);
      slider.mount();
      return () => {
        slider.destroy()
      };
    }
  }, []);

  return (
    <div className="glide" ref={sliderRef} style={{padding: "0px 16px"}}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">{children}</ul>
      </div>
    </div>
  );
});

const Slide = forwardRef(({children}: { children: JSX.Element }, ref: ForwardedRef<HTMLLIElement>) => {
  return (
    <li className="glide__slide" ref={ref} style={{width: '80%'}}>
      {children}
    </li>
  );
});

const RequestForm = () => {
  const dispatch = useDispatch();
  const regions = useSelector((state: RootState) => state.user.regions)
  const [text, setText] = useState("");

  async function sendMoreActivity() {
    if (text != "") {
      amplitude.track(EVENT_REQUEST_MORE_ACTIVITY)
      let body = { "regions": regions, "text": text }
      let res = await fetchWithRetry(server_domain() + "/request_more_activity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      })
      if (res.ok) {
        setText("")
        dispatch(show({ message: "요청되었습니다." }))
      } else {
        dispatch(show({ message: "제출하는데 에러가 발생했습니다." }))
      }
    }
  }

  return (
    <div className="request-more">
      <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 500, fontSize: '1.125rem', textAlign: 'center' }}>채용정보가 부족한가요?</p>
      <p style={{ margin: 0, lineHeight: 1.5, fontWeight: 300, fontSize: '1rem', textAlign: 'center' }}>원하는 일자리를 말씀해주시면<br />적극 참고하여 찾아오겠습니다!</p>
      <textarea
        className={styles.textarea}
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="희망 일자리 입력란"
        style={{ background: 'white', fontSize: '1rem', width: '100%', resize: 'none', borderRadius: 4, color: "#000000DE", padding: '8.5px 14px', boxSizing: 'border-box', border: '1px solid #85858588' }}
      />

      <button style={{
        border: 'none',
        color: '#FFFFFF',
        fontSize: '1rem',
        width: 'fit-content',
        background: text === "" ? '#d9d9d9' : '#0ABF53',
        borderRadius: '5px',
        lineHeight: 1.75,
        padding: '4px 16px',
      }}
              onClick={text === "" ? undefined : sendMoreActivity}
      >
        요청하기
      </button>
    </div>
  )
}

const JobMainList = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const regions = useSelector((state: RootState) => state.user.regions)
  const signUpAt = useSelector((state: RootState) => state.user.signUpAt)
  const mainPageCache = useSelector((state: RootState) => state.activityCache.mainPage)
  const [activities, setActivities] = useState<Activity[]>([])
  const listRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const listQueryMode = useSelector((state: RootState) => state.config.listQueryMode)
  const resumes = useSelector((state: RootState) => state.user.resumes)

  // useJobNotice()

  const bannerItems :{
    src: string;
    title: string
    onClick: () => void;
  }[] = [
    { src: imgJobNewsCard, title: '5060 취업뉴스', onClick: () => navigate("job_news") },
    { src: imgPublicBuilding, title: '정부지원 일자리 사업', onClick: () => navigate("gov_job") },
  ]

  const {
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    'activities',
    ({ pageParam = 0 }) => loadActivities(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.activities.length === 0 || lastPage?.activities.length < 20 ? undefined : allPages.length
      },
      onSuccess: (data) => {
        setActivities(data.pages.flatMap((d) => d.activities))
      }
    },
  )
  const virtualizer = useVirtualizer({
    count: hasNextPage ? activities.length + 1 : activities.length,
    getScrollElement: () => listRef.current,
    estimateSize: () => 200,
    overscan: 5,
    scrollMargin: containerRef.current?.offsetTop ?? 0,
  })

  async function loadActivities(page: number) {
    if (regions.includes('인천 남구')) {
      dispatch(changeRegions(regions.splice(regions.indexOf('인천 남구'), 1, "인천 미추홀구")))
    }
    const result = await mainPageLoader(regions, signUpAt, listQueryMode, page)
    dispatch(updateMainPage(result));
    return result;
  }

  useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }

    if (
      lastItem.index >= activities.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage()
    }
  }, [
    hasNextPage,
    fetchNextPage,
    activities.length,
    isFetchingNextPage,
    virtualizer.getVirtualItems(),
    listQueryMode,
  ])

  useOnLog({
    pageNameKey: EVENT_JOB_MAIN_TAB_SHOW,
    amplitudeProp: {
      regions: regions,
    },
    regStr: "^\/job$"
  })

  return <DefaultTabTemplate
    top={
      <BackNavToolbar
        to={"/"}
        icon={<img src={IcHomeInactive} width='24px' height='24px'></img>}
        customBackHandler={() => {
          trackEvent(EVENT_CLICK_HOME, { 'source': 'job' })
          navigate('/', { replace: true })
        }}
        titleElement={
            <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', gap: '4px'}}>
                <BlackText_25 style={{color: primary}}>위즈덤</BlackText_25>
                <MediumText_25 style={{color: primary}}>일자리</MediumText_25>
            </div>
        }
      />
    }
    middleRef={listRef}
    middle={<>
      <p style={{fontSize: '1.25rem', fontWeight: 500, margin: '16px 20px', marginTop: '40px', color: '#1D1D1D'}}>5060 일자리 찾기</p>
      <MainPageMenu/>
      <div style={{margin: '16px 20px'}}>
        <SearchBox/>
      </div>
      <DivideBar/>
      <div>
        <p style={{fontSize: '1.25rem', fontWeight: 500, margin: '0px 16px 0px', color: '#1D1D1D'}}>정보 & 팁</p>
        <div style={{width: '100%', aspectRatio: '360 / 200'}}>
          <Carousel options={{type: 'slider', startAt: 0, perView: 1.2, gap: 20}}>
            {bannerItems.map((item, index) => {
              return (
                <Slide key={index}>
                  <div style={{borderRadius: '5px', margin: '16px 0px 0', position: 'relative'}} onClick={item.onClick}>
                    <img src={item.src} alt={item.title}
                         style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px', verticalAlign: 'middle'}}
                      // @ts-ignore
                         fetchPriority={index == 0 ? 'high' : 'auto'} // bug: fetchpriority is not recognized @see: https://github.com/facebook/react/issues/25682
                         loading={index === 0 ? 'eager' : 'lazy'}
                    />
                    <div style={{position: 'absolute', width: '100%', background: '#000000B3', borderRadius: '0 0 5px 5px', bottom: '0px'}}>
                      <div style={{padding: '20px 15px', display: "flex", alignItems: 'center'}}>
                        <span style={{display: 'block', color: 'white', fontSize: '1.125rem', flex: 1, fontWeight: 700}}>{item.title}</span>
                        <IcArrowRightWhiteBold width={20}/>
                      </div>
                    </div>
                  </div>
                </Slide>
              )
            })}
          </Carousel>
        </div>
        <DivideBar/>
      </div>
      <RegionCityButton location={"MainPage"}/>
      <div onClick={() => {
        trackEvent(EVENT_ACTIVITY_QUERY_MODE_DROPDOWN_CLICK, {currentMode: listQueryMode})
        navigate("list_query")
      }} style={{display: 'flex', alignItems: 'center', justifyContent: 'end', paddingRight: '8px', paddingTop: '20px', paddingBottom: '10px', color: '#474D5B', fontSize: '0.875rem'}}>
        {listQueryMode === 'RECOMMEND' ? '추천순' : '최신순'}
        <IcArrowDown height={24} width={24}/>
      </div>
      <div
        ref={containerRef}
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {virtualizer.getVirtualItems().map((item) =>
          <div
            key={item.key}
            ref={virtualizer.measureElement}
            data-index={item.index}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${item.start - virtualizer.options.scrollMargin}px)`,
            }}
          >
            {
              activities.length > item.index ? <ActivityCard activity={activities[item.index]} sessionId={mainPageCache == null ? "null" : mainPageCache.sessionId} location={"MainPage"} last={item.index === (activities.length - 1)} regions={regions}/> : <div/>
            }
          </div>
        )}
      </div>
      <RequestForm/>
    </>
    }
  />
}

export default JobMainList;