import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cleanAskedActivity } from "@reducer/askedActivitySlice";
import { cleanScrap } from "@reducer/scrapSlice";
import { cleanCacheActivities } from "@reducer/activityCacheSlice";
import { cleanUser } from "@reducer/userSlice";
import { show } from "@reducer/snackbarSlice";
import { DialogDrawerTemplate } from "@components/DrawerTemplate";
import { FillButton, SecondaryFillButton } from "@components/Buttons";

const SignOutDialog = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function onClose() {
        navigate(-1)
    }
    function onConfirm() {
        dispatch(cleanAskedActivity())
        dispatch(cleanUser())
        dispatch(cleanScrap())
        dispatch(cleanCacheActivities())
        dispatch(show({message: "회원탈퇴 되었습니다"}))
        // @ts-ignore
        navigate(-2, { replace: true })
    }
    return (
        <DialogDrawerTemplate 
            show={true} 
            title="정말 탈퇴하시겠습니까?"
            onClose={onClose}
            closeButton={false}
        >
            <div style={{ display: 'flex', width: '100%', gap: 10}} >
                <FillButton text={"탈퇴하기"} onClick={onConfirm}  />
                <SecondaryFillButton text={"취소"} onClick={onClose} />
            </div>
        </DialogDrawerTemplate>
    );
}

export default SignOutDialog