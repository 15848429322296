import {useNavigate} from "react-router-dom";
import IcFaq from "@assets/ic_faq.webp";
import trackEvent from "@util/trackEvent";
import {EVENT_FAQ_ENTRY_CLICK} from "@components/AppConstant";

const FaqFloatingButton = ({activityId}: {activityId: string}) => {
  const navigate = useNavigate();

  return <div style={{display: 'flex', alignItems: 'center', position: 'absolute', bottom: 20, right: 20, background: '#0ABF53', borderRadius: 20, color: 'white', padding: 10, boxShadow: '3px 3px 3px #00000044'}} onClick={() => {trackEvent(EVENT_FAQ_ENTRY_CLICK, {location: "FloatingButton"}); navigate('faq/' + activityId)}}>
    <img src={IcFaq} alt={""} style={{height: '20px', marginRight: 10}}/>
    <span style={{lineHeight: '20px'}}>지원 도움 받기</span>
  </div>
}

export default FaqFloatingButton;