import { DefaultPageRoot, DefaultTabTemplate } from "@src/components/PageTemplate";
import { useEffect, useRef, useState } from "react";
import Post from "../model/Post";
import CommunityService from "../infra/CommunityService";
import { BoldText_20, MediumText_17, MediumText_20, RegularText_17, RegularText_20 } from "@src/components/text";
import { grey999, greyF4, secondaryBlack } from "@src/components/Color";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from "react-redux";
import useOnLog from "@src/components/useOnLog";
import { EVENT_COMMUNITY_SEARCH_PAGE_SHOW, EVENT_COMMUNITY_SEARCH_POSTS } from "@src/components/AppConstant";
import IcSearchBlack from '@assets/ic_search_black.svg';
import IcArrowBack from '@assets/arrow_back.svg';
import { show } from "@src/reducer/snackbarSlice";
import { RootState } from "@src/reducer/store";
import { addSearchPostsKeyword, clearSearchPostsKeywords, removeSearchPostsKeyword } from "@src/reducer/communitySlice";
import IcClose from '@assets/close.svg';
import { PostItem } from "./component/PostItem";
import trackEvent from "@src/util/trackEvent";
import IcRemoveAll from '@assets/ic_remove_all.svg';
import { useOnMountOnce } from "@src/util/useOnMountOnce";

type ViewState = 'search' | 'view'

const PostSearchPage = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchText, setSearchText] = useState('')
    const dispatch = useDispatch()
    const cached = queryClient.getQueryData(`posts-전체`) as { pages: Post[][]} | undefined
    const [posts, setPosts] = useState<Post[]>(cached?.pages.flatMap(e => e) ?? [])
    const recentSearchKeywords = useSelector((state: RootState) => state.community.searchPostsKeywords?.toReversed() ?? [])
    const [mode, setMode] = useState('search' as ViewState)
    const [loading, setLoading] = useState(false)
    const [focused, setFocused] = useState(false)

    useOnLog({
        pageNameKey: EVENT_COMMUNITY_SEARCH_PAGE_SHOW,
        regStr: "^\/community/search$"
    })

    useEffect(() => {
        if (inputRef.current && !focused) {
            inputRef.current.focus()
            setFocused(true)
        }
    }, [inputRef.current])

    const searchPosts = async (text: string, useRecentSearchKeyword: boolean) => {
        if (text.length < 2) {
            dispatch(show({message: '검색어를 두 글자 이상 입력해주세요.'}))
            return
        }

        if (inputRef.current) {
            inputRef.current.blur()
        }
        trackEvent(EVENT_COMMUNITY_SEARCH_POSTS, { keyword: text, useRecentSearchKeyword: useRecentSearchKeyword})
        dispatch(addSearchPostsKeyword(text))

        setLoading(true)
        try {
            const posts = await CommunityService.searchPosts({search: text})
            setPosts(posts)
            setMode('view')
        } catch {
            dispatch(show({message: '검색 중 오류가 발생했습니다.'}))
        } finally {
            setLoading(false)
        }
    }

    const searchModeView = (
    <>
        <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '20px', paddingLeft: '15px', paddingRight: '15px'}}>
            <RegularText_17 style={{color: secondaryBlack}}>최근 검색어</RegularText_17>
            { recentSearchKeywords.length > 0 && <MediumText_17 style={{color: grey999}} onClick={() => dispatch(clearSearchPostsKeywords())}>전체 삭제</MediumText_17> }
        </div>
        {
            recentSearchKeywords.length == 0 
            ? <div>
                <div style={{padding: '70px 0px', textAlign: 'center'}}>
                    <MediumText_20 style={{color: grey999}}>최근 검색어 내역이 없습니다</MediumText_20>
                </div>
                <div style={{height: '1px', backgroundColor: '#D7D8DA', margin: '0px 15px'}}></div>
            </div>
            : <div style={{padding: '15px'}}>
                {
                    Array.from(recentSearchKeywords).map((keyword, index) => {
                        return <div key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '14px 0px'}}
                                onClick={() => { 
                                    searchPosts(keyword, true)
                                    setSearchText(keyword)
                                }}
                            >
                            <div style={{display: 'flex', gap: '12px'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '28px', height: '28px', borderRadius: '12px', backgroundColor: greyF4}}>
                                    <IcSearchBlack width="15px" height="15px"/>
                                </div>
                                <MediumText_20>{keyword}</MediumText_20>
                            </div>
                            <div style={{paddingRight: '5px'}} onClick={(e) => {
                                dispatch(removeSearchPostsKeyword(keyword))
                                e.stopPropagation()
                                inputRef.current?.focus()
                            }}>
                                <IcClose  width='13px' height='13px'/>
                            </div>
                            
                        </div>
                    })
                }
                <div style={{height: '1px', backgroundColor: '#D7D8DA', marginTop: '20px'}}></div>
            </div>
        }
    </>);
    

    const viewModeView = (
        <>
            { 
                posts.length == 0 
                ? <div style={{display: 'flex', flexDirection: 'column', gap: '36px', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '100%', height: '100%'}}>
                    <BoldText_20>검색 결과가 없습니다.</BoldText_20>
                    <RegularText_20>다른 검색어를 입력해주세요.</RegularText_20>
                </div>
                : posts.map((post, index) => {
                    return <PostItem idx={index} key={index} post={post} totalLength={posts.length} source="search"/>
                }) 
            }
        </>
    )

    return (
        <DefaultPageRoot
            top={
                <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', height: '75px' }}>
                    <button
                        onClick={() => navigate(-1)}
                        style={{
                            background: 'none',
                            border: 'none',
                            justifyContent: 'left',
                            color: '#1D1D1D',
                            margin: '0px 0px 0 16px',
                            width: 'auto',
                            padding: '1px 14px 1px 0px',
                            minWidth: 'initial',
                            display: 'flex',
                            alignItems: 'center',
                        }} >
                        <IcArrowBack />
                    </button>
                    <div style={{ position: 'relative', flex: 1, padding: '5px 0px 5px 0px', background: '#F4F4F4', borderRadius: '5px' }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <input
                                autoFocus={true}
                                ref={inputRef}
                                style={{ flex: 1, outline: 'none', border: 'none', background: 'none', fontSize: '1rem', padding: '5px 0px 5px 5px', lineHeight: 1.5 }}
                                placeholder="검색어 입력"
                                value={searchText}
                                onChange={(e) => {
                                    if (mode === 'view') {
                                        setMode('search')
                                    }
                                    setSearchText(e.target.value)
                                }}
                                onClick={() => {
                                    setMode('search')
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        searchPosts(searchText, false)
                                    }
                                }}
                            />
                        </div>
                        <IcRemoveAll 
                            style={{position: 'absolute', right: '10px', top: '15px', visibility: searchText.length > 0 ? 'visible' : 'collapse'}}
                            onClick={(e) => {
                                setSearchText('')
                                inputRef.current?.focus()
                                setMode('search')
                            }}
                        />
                    </div>
        
                    <button
                        style={{
                            background: 'none',
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: '2rem',
                            height: '100%',
                            padding: '6px 0px 6px 8px',
                            margin: '0 6px',
                        }}
                        onClick={() => searchPosts(searchText, false)}
                    >
                        <IcSearchBlack />
                    </button>
                </div>
            }
            middle={
                <>
                    <div style={{
                        height: '100%',
                        width: '100%',
                        overflowY: 'auto',
                        contain: 'strict',
                        scrollbarWidth: 'none',
                    }}>
                        { mode === 'search' ? searchModeView : viewModeView }
                    </div>
                    { loading && 
                    <div style={{position: 'absolute', display: 'flex', width: '100%', height: '100%', backgroundColor: '#00000070', alignItems: 'center', justifyContent: 'center', zIndex: 1, top: 0}}>
                        <div id="spinner"></div>
                    </div>
                    }
                </>
            }
        />
    );
};

export default PostSearchPage;
