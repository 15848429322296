
const formatRegions = (regionsList: string, userRegions: string[]) => {
    let activityRegion: string[] = []
    try {
        activityRegion = regionsList.split(",")
    } catch (err) {
    }
    let online = activityRegion.filter(val => ["비대면"].includes(val));
    let offline = activityRegion.filter(val => !["비대면"].includes(val));
    let dup = offline.filter(val => userRegions.includes(val));
    let uniq = offline.filter(val => !userRegions.includes(val));
    let sorted = online.concat(dup.concat(uniq))

    let len = sorted.length
    if (len < 2) {
        return sorted
    } else if (len < 3) {
        return sorted[0] + ", " + sorted[1].replace('서울 ', '')
    } else {
        return sorted[0] + ", " + sorted[1].replace('서울 ', '') + ` 외`
    }
}

export default formatRegions;