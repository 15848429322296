import server_domain from "@components/RemoteServer";
import { fetchWithRetry } from "./networks";

const headers = {
    "Content-Type": "application/json",
}

const request = async ({endPoint, params, body}: {
    endPoint: string,
    params?: string | Record<string, string> | URLSearchParams | string[][] | undefined,
    body?: { [key: string]: any }
}) => {
    if (body) {
        const res = await fetchWithRetry(server_domain() + endPoint, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body)
        })
        return await res.json()
    } else {
        if (params) {
            const queryStr = new URLSearchParams(params).toString()
            endPoint = endPoint + "?" + queryStr
        } else {
            endPoint = endPoint
        }
        const res = await fetchWithRetry(server_domain() + endPoint, {
            method: "GET",
            headers: headers
        })
        return await res.json()
    }
}

export default request;
