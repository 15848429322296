import React, {CSSProperties} from "react";

const HeaderStyle: React.CSSProperties = {lineHeight: '1.5rem', padding: '12px 0', whiteSpace: 'nowrap', verticalAlign: 'top', borderBottom: "none", width: 'fit-content', color: '#6A6A6A', fontSize: '1rem', fontWeight: 300}

interface TableProps {
  indent?: number,
  key: string,
  value: string
}

const Table = ({title, subTitle, data, tableStyle, keyStyle, valueStyle}: { title?: string, subTitle?: string, tableStyle?: CSSProperties, keyStyle?: CSSProperties, valueStyle?: CSSProperties, data: TableProps[] }) => {
  return <div>
    {title && <p style={{margin: '0', fontSize: '1rem', lineHeight: '1.5rem', fontWeight: 700}}>{title}</p>}
    {subTitle && <p style={{margin: '0', fontSize: '0.9rem', lineHeight: '1.5rem'}}>{subTitle}</p>}
    <div style={{margin: '16px 0 ', ...tableStyle}}>
      {
        data.map((props) => <div style={{display: 'flex'}}>
          <div style={{verticalAlign: "top", padding: '12px 0', minWidth: 120, ...keyStyle}}>
            <span style={{...HeaderStyle, marginLeft: `${(props.indent || 0) * 20}px`}}>{props.key}</span>
          </div>
          <div style={{verticalAlign: "top", padding: '12px 0', width: '100%', ...valueStyle}}>
            <p style={{margin: 0, lineHeight: 1.5, fontSize: '1rem', whiteSpace: 'pre-wrap'}}>{props.value}</p>
          </div>
        </div>)
      }
    </div>
  </div>
}

export default Table;