import {replayIntegration, browserProfilingIntegration, reactRouterV6BrowserTracingIntegration, makeFetchTransport, defaultStackParser, init, setTag, setUser, makeBrowserOfflineTransport} from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';

export function initSentry() {
    // https://docs.sentry.io/platforms/javascript/configuration/tree-shaking/#tree-shaking-default-integrations
    init({
        dsn: import.meta.env.VITE_SENTRY_DNS,
        transport: makeBrowserOfflineTransport(makeFetchTransport),
        stackParser: defaultStackParser,
        integrations: [
            reactRouterV6BrowserTracingIntegration({
                useEffect: useEffect,
                useLocation: useLocation,
                useNavigationType: useNavigationType,
                createRoutesFromChildren: createRoutesFromChildren,
                matchRoutes: matchRoutes,
            }),
            replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                networkDetailAllowUrls: [window.location.origin, "https://api.wizdom.co.kr"],
                networkRequestHeaders: ["User-Agent",],
            }),
            browserProfilingIntegration(),
        ],
        tracesSampleRate: 0.3,
        profilesSampleRate: 0.01,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1,
        enabled: import.meta.env.VITE_SENTRY_ENABLE === "true",
        release: 'wizdom@' + APP_VERSION
    });
}

export function setSentryTag(key: string, value: string) {
    setTag(key, value);
}