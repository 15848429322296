import {Activity} from "@model/Activity";
import {CSSProperties} from "react";

const ApplyRemainDate = ({activity, style}: { activity: Activity, style: CSSProperties }) => {
  if (activity.activityRecruitmentUntilComplete) {
    return <p style={{fontSize: '1rem', margin: 0, lineHeight: 1.5, color: '#666', ...style}}>상시모집</p>
  }
  if (activity.activityRecruitmentEndDate) {
    const now = new Date()
    const end = new Date(`${activity.activityRecruitmentEndDate} ${activity.activityRecruitmentEndTime}`)
    const diff = end.getTime() - now.getTime()
    if (diff < 0 || activity.status === "outdated") {
      return <p style={{fontSize: '1rem', margin: 0, lineHeight: 1.5, color: '#F15D47', ...style}}>접수 마감</p>
    } else if (diff < 2 * 60 * 60 * 1000) {
      return <p style={{fontSize: '1rem', margin: 0, lineHeight: 1.5, color: '#F15D47', ...style}}>마감 1시간전</p>
    } else if (diff < 24 * 60 * 60 * 1000) {
      return <p style={{fontSize: '1rem', margin: 0, lineHeight: 1.5, color: '#F15D47', ...style}}>마감 {Math.floor(diff/(60 * 60 * 1000))}시간전</p>
    } else {
      const remainDate = (diff / (60 * 60 * 24 * 1000)).toFixed(0)
      return <p style={{fontSize: '1rem', margin: 0, lineHeight: 1.5, color: '#666', ...style}}>마감 {remainDate}일전</p>
    }
  }
  return null
}

export default ApplyRemainDate;