import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Divider} from "@components/DivideBar";
import {useBlocker, useNavigate, useSearchParams} from "react-router-dom";
import IcHomeInactive from "@assets/ic_home_inactive.webp";
import {BlackText_25, MediumText_25} from "@components/text";
import {primary} from "@components/Color";
import {Button, ButtonText, FillButton} from "@components/Buttons";
import React, {useState} from "react";
import trackEvent from "@util/trackEvent";
import {EVENT_TAX_ITEM_CLICK} from "@components/AppConstant";
import Item from "@src/features/tax_saving/component/Item";
import {ShareBottomSheet} from "@components/ShareComponent";
import {AppInstallGuideBanner} from "@components/AppInstallGuideBanner";
import AppInstallGuideModal2 from "@components/AppInstallGuideModal2";
import IcTaxProperty from "@assets/ic_tax_property.webp";
import IcTaxCar from "@assets/ic_tax_car.webp";
import IcArrowRight from "@assets/ic_arrow_right.svg";
import {secondaryColor} from "@components/styles";

const TaxListPage = () => {
  const navigate = useNavigate();
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showInstallModal, setShowInstallModal] = useState(searchParams.get("show_install_guide") === 'true')
  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (showShareModal) {
      setShowShareModal(false)
      return true;
    }
    return false;
  })
  return <>
    <DefaultPageRoot
      top={
        <>
          <AppInstallGuideBanner/>
          <BackNavToolbar
            to={"/"}
            icon={<img alt="" src={IcHomeInactive} width='24px' height='24px'></img>}
            titleElement={
              <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', gap: '4px'}}>
                <BlackText_25 style={{color: primary}}>위즈덤</BlackText_25>
                <MediumText_25 style={{color: primary}}>절세</MediumText_25>
              </div>
            }
          />
        </>
      }
      middle={<>
        <div style={{padding: '16px 16px 16px 16px', textAlign: 'center', lineHeight: '1.5', fontWeight: 500, fontSize: "1rem"}}>
          <p style={{textAlign: 'left', whiteSpace: 'pre-wrap', fontWeight: 700, fontSize: "1.25rem", color: '#1D1D1D', margin: '0 0 20px'}}>{"소중한 자산을 지키는 첫걸음\n일상 생활의 세금과 절세를 다룹니다."}</p>
          {/*TODO amplitude*/}
          <FillButton text={"절세 팁 공유하기"} onClick={() => setShowShareModal(true)}/>
        </div>
        <Divider/>
        <p style={{marginLeft: 16, fontSize: '1.25rem', fontWeight: 700}}>절세 팁과 세금 계산기</p>
        <div style={{padding: '0 16px 16px', lineHeight: 1.5}}>
          <p style={{lineHeight: 1.5, margin: 0}}>(1) 일상에서 납부하는 세금과 절세 방안을 알려드립니다.</p>
          <p style={{lineHeight: 1.5, margin: 0}}>(2) 예상 세금 납부액을 정확하게 계산해보세요.</p>
        </div>
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', padding: '0 16px 16px', gap: 16}}>
          <Item icon={<img src={IcTaxProperty} alt={""} style={{height: '80%'}} />} title={"재산세\n(주택)"} onClick={() => {
            trackEvent(EVENT_TAX_ITEM_CLICK, {type: 'property_tax'});
            navigate('property')
          }}/>
          <Item icon={<img src={IcTaxCar} alt={""} style={{height: '80%'}} />}  title={"자동차세\n(보유)"} onClick={() => {
            trackEvent(EVENT_TAX_ITEM_CLICK, {type: 'car_tax'});
            navigate('car')
          }}/>
        </div>
        <div style={{padding: '0 16px 32px'}}>
          <Button style={{background: secondaryColor}} icon2={<IcArrowRight />} onClick={() => navigate('more')}>
            <ButtonText style={{color: 'black', fontWeight: 400}} text={"다른 세금 절세 방안 요청하기"}/>
          </Button>
        </div>
      </>}
    />
    <AppInstallGuideModal2
      show={showInstallModal}
      onClose={() => setShowInstallModal(false)}
    />
    <ShareBottomSheet
      showModal={showShareModal}
      title={"절세 팁 공유하기"}
      content={"생활속 세금을 줄일 수 있는 방법을 주변 분들에게 많이 전파하세요."}
      onClose={() => setShowShareModal(false)}
      props={{
        kakao_share_title: "카카오톡으로 공유하기",
        link_copy_title: "공유 내용 복사하기",
        amplitude_props: {location: "tax_saving"},
        templateId: 109448,
        templateArgs: {
          "title": "(무료)각종 절세 방안을 공유합니다.",
          "description": "은퇴 후 필수 앱, 위즈덤",
          "thumbnail_url": "https://app.wizdom.co.kr/img/img_og_image.webp",
          "cta": "절세 팁 보기",
          "url": import.meta.env.VITE_URL,
          "url_path": "/save_tax?show_install_banner=true&show_install_guide=true&back_to_home=true&referrer=utm_source%3Dtaxsaving%26utm_campaign%3Dsharing%26utm_content%3Dwizdomutil"
        },
        toastText: "복사되었습니다.\n붙여넣기 하여 공유해주세요.",
        url: "[위즈덤]\n절세 팁\n\n재산세, 자동차세 등\n계산 방식 그대로 정확하게 계산하고 절세 방안까지 알려드립니다.\n\n" + import.meta.env.VITE_URL + "/save_tax?show_install_banner=true&show_install_guide=true&back_to_home=true&referrer=utm_source%3Dtaxsaving%26utm_campaign%3Dsharing%26utm_content%3Dwizdomutil"
      }}
    />

  </>

}

export default TaxListPage;