export const getFCMToken = async () => {
    try {
        if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            const token: string | null = await window.flutter_inappwebview.callHandler('getFCMToken')
            return token;   
        }
        return null
    } catch {
        return null
    }
}

export const subscribeToTopic = async (topic: string) => {
    try {
        if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            await window.flutter_inappwebview.callHandler('subscribeToTopic', topic)
        }
    } catch {
        return null
    }
}
