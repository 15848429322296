import { CSSProperties, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@src/reducer/store";

const ApplyMethodSelector = (
    {onSelectIsSimpleApply}: {onSelectIsSimpleApply: (isSimpleApply: boolean) => void}
) => {
    const userFilterIsSimpleApply = useSelector((state: RootState) => state.user.simpleApplyFilterOn)
    const [isSimpleApply, setIsSimpleApply] = useState<boolean>(userFilterIsSimpleApply)
    const style: CSSProperties = {
        borderRadius: 5,
        height: '60px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    }
    
    return (
        <div
            style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
        >
            <div 
                onClick={() => { 
                    setIsSimpleApply(false)
                    onSelectIsSimpleApply(false) 
                }} 
                style={{
                    ...style,
                    border: `1px solid ${!isSimpleApply ? '#0ABF53' : '#999'}`,
                    background: !isSimpleApply ? '#EEF9F2' : 'white',
                    color: !isSimpleApply ? '#0ABF53' : 'black',
                }}
            >
                전체
            </div>
            <div 
                onClick={() => {
                    setIsSimpleApply(true)
                    onSelectIsSimpleApply(true)
                }}
                style={{
                    ...style,
                    border: `1px solid ${isSimpleApply ? '#0ABF53' : '#999'}`,
                    background: isSimpleApply ? '#EEF9F2' : 'white',
                    color: isSimpleApply ? '#0ABF53' : 'black',
                }}
            >
                간편지원 공고만 보기
            </div>
        </div>
    );
}
export default ApplyMethodSelector;
