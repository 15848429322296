import IModal from "@src/components/IModal";
import { useLocation, useNavigate } from "react-router-dom";

const SetNicknameGuideModal = () => {
    const navigate = useNavigate()
    const source = useLocation().state?.source

    return (
        <IModal
            message="커뮤니티를 이용하려면\n별명 등록이 필요해요"
            confirmText="별명 등록"
            onCancel={() => navigate('..', { replace: true, state: { source: source } })}
            onConfirm={() => {
                navigate('../nickname', { replace: true, state: { source: source } })
            }}
        />
    );
};
export default SetNicknameGuideModal;
