import CommunityRootPage from '@features/community/page/CommunityRootPage';
import PostListPage from '@features/community/page/PostListPage';
import HotPostListPage from '@features/community/page/HotPostListPage';
import ProfilePage from '@features/community/page/ProfilePage';
import PostDetailPage from '@features/community/page/PostDetailPage';
import PostWritingPage from '@features/community/page/PostWritingPage';
import SetNicknamePage from '@features/community/page/SetNicknamePage';
import CommunityMemberLevelGuide from '@features/community/page/CommunityMemberLevelGuide';
import MemberLevelUpModal from '@features/community/page/MemberLevelUpModal';
import { RouteObject } from 'react-router-dom';
import SetNicknameGuideModal from '@features/community/page/SetNicknameGuideModal';
import OnboardingPage from "@features/community/feature/onboarding/OnboardingPage";
import EditNicknamePage from '@features/community/page/EditNicknamePage';
import OnboardingCompleteCongraturation from '@features/community/feature/onboarding/OnboardingCompleteCongraturation';
import CommunityService from '@features/community/infra/CommunityService';
import AppInstallGuideModal from '@components/AppInstallGuideModal';
import { ShareDrawer } from '@src/view/ShareDrawer';
import PostSearchPage from './page/PostSearchPage';
import { Children } from 'react';

const setNicknamePageRouter: RouteObject = { path: 'nickname', element: <SetNicknamePage /> }
const SetNicknameGuideModalRouter: RouteObject = { 
    path: 'set_nickname_guide_modal',
    element: <SetNicknameGuideModal />, 
}
const levelUp = {
    path: 'member-level-up',
    element: <MemberLevelUpModal />,
    children: [
      {
        path: 'congraturation',
        element: <OnboardingCompleteCongraturation />,
      },
    ]
}
  
export const PostDetailRouter = {
    path: 'post/:postId',
    element: <PostDetailPage />,
    children: [
        SetNicknameGuideModalRouter,
        setNicknamePageRouter,
        levelUp,
        { 
            path: "share",
            element: <ShareDrawer /> 
        },
        {
            path: 'edit',
            element: <PostWritingPage edit={true}/>,
        },
        {
            path: 'congraturation',
            element: <OnboardingCompleteCongraturation />,
        },
        {
            path: 'app_install_guide',
            element: <AppInstallGuideModal />
        },
    ]
}

const editNicknameRouter: RouteObject = {
    path: 'edit-nickname',
    element: <EditNicknamePage />,
}

const communityOnboardingRouter: RouteObject[] = [
{ 
    path: 'onboarding',
    element: <OnboardingPage />,
    loader: async ({ params}) => {
        return CommunityService.getAutoGeneratedNickname()
    },
    children: [
            editNicknameRouter,
        ],
    },
]

const CommunityRouter = {
    path: 'community',
    element: <CommunityRootPage />,
    children: [
      {
        path: '',
        element: <PostListPage />,
        children: [
          PostDetailRouter,
          ...communityOnboardingRouter,
        ]
      },
      {
        path: 'hot-post',
        element: <HotPostListPage />,
        children: [
          PostDetailRouter,
        ]
      },
      {
        path: 'search',
        element: <PostSearchPage />,
        children: [
          PostDetailRouter,
        ]
      },
      {
        path: 'profile',
        element: <ProfilePage />,
        children: [
          PostDetailRouter,
          editNicknameRouter,
          {
            path: 'member-guide',
            element: <CommunityMemberLevelGuide />,
          }
        ]
      },
      {
        path: 'post/write',
        element: <PostWritingPage />,
      },
    ]
}

export default CommunityRouter;