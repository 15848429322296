import { DefaultPageRoot } from "@src/components/PageTemplate";
import icPhoto from "@assets/ic_photo.webp";
import { grey999, primary, secondaryBlack } from "@src/components/Color";
import { BackNavToolbar } from "@src/components/Toolbars";
import { fontSize } from "@src/components/font";
import { FillButton } from "@src/components/Buttons";
import { useCallback, useEffect, useRef, useState } from "react";
import CommunityService from "../infra/CommunityService";
import { useBlocker, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/reducer/store";
import icClose from "@assets/ic_close.webp";
import { RegularText_20 } from "@src/components/text";
import openGallery from "@src/util/openGarllery";
import uuidv4 from "@src/util/uuidv4";
import { useOnMountOnce } from "@src/util/useOnMountOnce";
import trackEvent from "@src/util/trackEvent";
import { EVENT_EDIT_POST, EVENT_POST_WRITING_BACK_CLICK, EVENT_POST_WRITING_CATEGORY_CLOSE, EVENT_POST_WRITING_CATEGORY_SELECT, EVENT_POST_WRITING_CATEGORY_SHOW, EVENT_POST_WRITING_CLICK, EVENT_POST_WRITING_CONTENT_CLICK, EVENT_POST_WRITING_CONTENT_INPUT, EVENT_POST_WRITING_IMAGE_ADD_CLICK, EVENT_POST_WRITING_TITLE_CLICK, EVENT_POST_WRITING_TITLE_INPUT, EVENT_WRITE_POST } from "@src/components/AppConstant";
import { addBase64Image, addUrlImage, clearImages, removeImage } from "@src/reducer/postImageSlice";
import IModal from "@src/components/IModal";
import Post, { PostCategory, postCategoryItems } from "../model/Post";
import ArrowDown from '@assets/arrow_down.svg';
import { DrawerTemplate } from "@src/components/DrawerTemplate";
import { isForPartners } from "@src/util/isForPartners";
import IcCheckMark from "@assets/ic_check_mark.svg";
import { show } from "@src/reducer/snackbarSlice";
import { setWritePostMoreThanOnce } from "@src/reducer/communitySlice";
import { useQueryClient } from "react-query";

type CategoryModalSource = 'auto' | 'selector';
type CategoryModalState = false | CategoryModalSource

const PostWritingPage = ({edit = false}: {edit?: boolean}) => {
    const navigate = useNavigate()
    const context = useOutletContext() as { onAddPost?: () => void } | undefined;
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const previousPost = useLocation().state?.post as Post | undefined
    const userId = useSelector((state: RootState) => state.user.uuid)
    const nickname = useSelector((state: RootState) => state.user.nickname)
    const [category, setCategory] = useState<PostCategory | null>(previousPost?.category ?? null)
    const [title, setTitle] = useState(previousPost?.title ?? '')
    const [content, setContent] = useState(previousPost?.content ?? '')
    const images = useSelector((state: RootState) => state.postImages.images)
    const imageId = uuidv4().toString()
    const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined)
    const titleRef = useRef<HTMLTextAreaElement>(null);
    const textRef = useRef<HTMLTextAreaElement>(null);
    const writePostMoreThanOnce = useSelector((state: RootState) => state.community.writePostMoreThanOnce)

    const handleResizeTitleHeight = useCallback(() => {
        if (titleRef?.current == null) { return }
        const previousHeight = titleRef.current.style.height;
        titleRef.current.style.height = "auto";
        titleRef.current.style.height = titleRef.current.scrollHeight + "px";
        if (previousHeight < titleRef.current.style.height) {
            titleRef.current.style.lineHeight = '1.5'
        }
    }, []);
    
    const [loading, setLoading] = useState(false)
    const handleResizeContentHeight = useCallback(() => {
        if (textRef?.current == null) { return }
        textRef.current.style.height = "auto";
        textRef.current.style.height = textRef.current.scrollHeight + "px";
    }, []);
    const [showBackConfirmModal, setShowBackConfirmModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showCategoryModal, setShowCategoryModal] = useState<CategoryModalState>(!edit ? 'auto' : false)
    const categoryModalRef = useRef<HTMLDivElement | null>(null)
    const [categoryModalBlocker, setCategoryModalBlocker] = useState(false)
    const [confirmModalBlocker, setConfirmModalBlocker] = useState(false)
    const [backConfirmModalBlocker, setBackConfirmModalBlocker] = useState(false)
    const [isTrackedTitleInput, setIsTrackedTitleInput] = useState(false)
    const [isTrackedContentInput, setIsTrackedContentInput] = useState(false)
    const [backToolbarClicked, setBackToolbarClicked] = useState(false)
    const onClickAddPhoto = () => {
        openGallery(imageId, true)
    }

    const submit = async () => {
        if (!userId || !nickname || !category || (edit && !previousPost)) {
            return
        }

        try {
            setLoading(true)
            if (edit) {
                const result = await CommunityService.updatePost({
                    postId: previousPost!.id,
                    title: title,
                    content: content,
                    images: images,
                    category: category
                })
                if (result) {
                    trackEvent(EVENT_EDIT_POST, { 'post_id': previousPost!.id })
                    queryClient.removeQueries(`posts-전체`)
                    queryClient.removeQueries(`posts-${category}`)
                    navigate(-1)
                } else {
                    dispatch(show({message: '글 수정에 실패했습니다.'}))
                }
                
            } else {
                const response = await CommunityService.addPost({
                    userId: userId,
                    title: title,
                    nickname: nickname,
                    content: content,
                    images: images,
                    category: category
                })
                const postId = response.id

                context?.onAddPost?.()
                trackEvent(EVENT_WRITE_POST, { 'post_id': postId })
                queryClient.removeQueries(`posts-전체`)
                queryClient.removeQueries(`posts-${category}`)
                
                let _writePostMoreThanOnce = writePostMoreThanOnce
                if (!writePostMoreThanOnce) {
                    dispatch(setWritePostMoreThanOnce(true))
                }

                navigate(`../post/${postId}`, { replace: true })
                if (response.level_up) {
                    navigate('member-level-up', { state: { level: response.level_up, congraturation: !_writePostMoreThanOnce } })
                    return
                }

                if (!_writePostMoreThanOnce) {
                    if (!response.level_up) {
                        navigate('congraturation')
                    }
                }
            }
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    const onUploadDesktopImages = (value: FileList | null) => {
        if (!value) {
            return
        }
        for (let i = 0; i < value.length; i++) {
            const file = value[i]
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (e) => {
                dispatch(addBase64Image(e.target?.result as string))
            }
        }
    };


    const handleMobileImages = (e: any) => {
        const mobileUploadImages: string[] = (e.detail.images as string[]).map((img) => `data:image/webp;base64,${img}`)
        mobileUploadImages.forEach((img) => {
            dispatch(addBase64Image(img))
        })
    }

    const handleOnTitleChange = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            titleRef.current?.blur()
            textRef.current?.focus()
        }
    }

    useOnMountOnce(() => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobile(isMobile)
        dispatch(clearImages())

        if (edit) {
            previousPost?.image_urls?.forEach(e => dispatch(addUrlImage(e)))
        }
    })

    useEffect(() => {
        window.addEventListener("uploadImage", handleMobileImages);
        return () => {
            window.removeEventListener("uploadImage", handleMobileImages);
        }
    }, [])

    useEffect(() => {
        if (title.length > 0 && !isTrackedTitleInput) {
            trackEvent(EVENT_POST_WRITING_TITLE_INPUT, undefined)
            setIsTrackedTitleInput(true)
        }
    }, [title, isTrackedTitleInput])
    
    useEffect(() => {
        if (content.length > 0 && !isTrackedContentInput) {
            trackEvent(EVENT_POST_WRITING_CONTENT_INPUT, undefined)
            setIsTrackedContentInput(true)
        }
    }, [content, isTrackedContentInput])

    useEffect(() => {
        if (showCategoryModal) {
            trackEvent(EVENT_POST_WRITING_CATEGORY_SHOW, { source: showCategoryModal })
        }
    }, [showCategoryModal])

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => {
            if (showBackConfirmModal) {
                setBackConfirmModalBlocker(true)
                return true
            }

            if (showConfirmModal) {
                setConfirmModalBlocker(true)
                return true
            }

            if (showCategoryModal) {
                trackEvent(EVENT_POST_WRITING_CATEGORY_CLOSE, { trigger: 'system_back', source: showCategoryModal })
                setCategoryModalBlocker(true)
                return true
            }
            
            const changed = (!edit && (title !== "" || content !== "" || images.length > 0) && nextLocation.pathname === "/community") || 
            (edit && (previousPost?.title !== title || previousPost?.content !== content || (previousPost?.image_urls?.length ?? 0) !== images.length) && !loading)

            if (changed) {
                setShowBackConfirmModal(true)
            } else {
                trackEvent(EVENT_POST_WRITING_BACK_CLICK, { source: backToolbarClicked ? 'toolbar' : 'system_back', changed: false })
            }
            return changed
        }
    );

    useEffect(() => {
        if (!categoryModalBlocker) { return }
        setShowCategoryModal(false)
        setCategoryModalBlocker(false)
        if (blocker.state == 'blocked') {
            blocker.reset()
        }
    }, [categoryModalBlocker])

    useEffect(() => {
        if (!confirmModalBlocker) { return }

        setShowConfirmModal(false)
        setConfirmModalBlocker(false)
        if (blocker.state == 'blocked') {
            blocker.reset()
        }
    }, [confirmModalBlocker])

    useEffect(() => {
        if (!backConfirmModalBlocker) { return }
        setShowBackConfirmModal(false)
        setBackConfirmModalBlocker(false)
        if (blocker.state == 'blocked') {
            blocker.reset()
        }
    }, [backConfirmModalBlocker])

    return <DefaultPageRoot
        top={
            <BackNavToolbar 
                title={'글쓰기'}
                onBeforeBack={() => {
                    setBackToolbarClicked(true)
                }}
                trailing={
                    <FillButton text="등록" style={{width: '80px', height: '40px'}} 
                        onClick={() => {
                            trackEvent(EVENT_POST_WRITING_CLICK, { active: true })
                            setShowConfirmModal(true)
                        }} 
                        onInactiveClick={() => {
                            trackEvent(EVENT_POST_WRITING_CLICK, { active: false })
                        }}
                        active={content.length > 0 && title.length > 0 && category != null}
                    />
                }
            /> 
        }
        middle={
            <>
                <div style={{display: 'flex', flexDirection: 'column', padding: '15px', width: 'calc(100% - 30px)', height: 'auto'}}>
                    <div style={{borderBottom: 'solid 1px #D7D8DA', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '10px'}}
                          onClick={() => setShowCategoryModal('selector')}
                        >
                        <RegularText_20>{category ? category : '게시글의 주제를 선택해주세요'}</RegularText_20>
                        <ArrowDown></ArrowDown>
                    </div>
                    
                    <textarea
                        ref={titleRef}
                        value={title}
                        rows={1}
                        onClick={() => {
                            if (content.length === 0) {
                                trackEvent(EVENT_POST_WRITING_TITLE_CLICK, undefined)
                            }
                        }}
                        onChange={(e) => {
                            setTitle(e.target.value)
                            handleResizeTitleHeight()
                        }}
                        onKeyDown={handleOnTitleChange}
                        placeholder="제목을 입력해주세요(필수)"
                        style={{height: '30px', fontWeight: 700, fontSize: fontSize.l, color: title.length === 0 ? grey999 : "black", overflow: 'hidden', resize: 'none', lineHeight: '1.5', marginTop: '30px', border: 'none', outline: 'none' }} 
                    />
                    <div style={{height: '1px', backgroundColor: '#D7D8DA', margin: '14px 0px'}}/>
                    <textarea
                        ref={textRef}
                        value={content}
                        onClick={() =>  {
                            if (content.length === 0) {
                                trackEvent(EVENT_POST_WRITING_CONTENT_CLICK, undefined)
                            }
                        }}
                        onChange={(e) => {
                            setContent(e.target.value)
                            handleResizeContentHeight()
                        }}
                        placeholder="내용을 입력해주세요(필수)"
                        style={{fontSize: fontSize.m, minHeight: '200px', color: content.length === 0 ? grey999 : "black", overflow: 'hidden', resize: 'none', lineHeight: '1.5', border: 'none', outline: 'none'}} 
                    />
                    <div style={{display: 'flex', gap: '15px', width: '100%', flexWrap: 'wrap', marginTop: '15px'}}>
                        {
                            images.map((image, idx) => {
                                return (
                                    <div key={idx} style={{position: 'relative'}}>
                                        <img 
                                            src={icClose}
                                            onClick={() => dispatch(removeImage(idx))}
                                            width={'18px'} height={'18px'}
                                            style={{position: 'absolute', top: '-8px', right: '-8px'}}/>
                                        <img src={image.data} width='66px' height='66px' style={{borderRadius: '5px', objectFit: 'cover'}}></img>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    loading && 
                    <div style={{position: 'absolute', display: 'flex', width: '100%', height: '100%', backgroundColor: '#00000070', alignItems: 'center', justifyContent: 'center', zIndex: 1, top: 0}}>
                        <div id="spinner"></div>
                    </div>
                }
            </>
        }
        bottom={
            <div style={{borderTop: 'solid 1px #D7D8DA'}} onClick={() => trackEvent(EVENT_POST_WRITING_IMAGE_ADD_CLICK, undefined)}>
                { isMobile ? <div 
                    style={{display: 'inline-flex', gap: '4px', justifyContent: 'center', alignItems: 'center', padding: '16px 12px'}} 
                    onClick={onClickAddPhoto}
                >
                    <img src={icPhoto} width='36px' height='36px'></img>
                    <RegularText_20 style={{color: secondaryBlack}}>사진 추가</RegularText_20>
                </div> : <div 
                    style={{display: 'inline-flex', gap: '4px', justifyContent: 'center', alignItems: 'center', padding: '9px 12px'}} 
                >
                    <img src={icPhoto} width='36px' height='36px'></img>
                    <label htmlFor="post_image_file">
                        <RegularText_20 style={{color: secondaryBlack}}>사진 추가</RegularText_20>
                    </label>
                    <input type="file" id="post_image_file" accept="image/*" style={{display: 'none'}} onChange={(e) => onUploadDesktopImages(e.target.files)}></input>
                </div>}
            </div>
        }
        modal={
            <>
                {showConfirmModal &&
                <IModal
                    message="글을 등록할까요?"
                    confirmText="등록"
                    cancelText="취소"
                    onCancel={() => {
                        setShowConfirmModal(false)
                    }}
                    onConfirm={() => {
                        setShowConfirmModal(false)
                        submit()
                    }}
                    onBackgroundClick={() => {
                        setShowConfirmModal(false)
                    }}
                />
                }
                {showBackConfirmModal && !showConfirmModal && !showCategoryModal &&
                <IModal
                    message={edit ? "글 수정을 취소하시겠습니까?\\n취소시 수정한 내용이 사라집니다." : "글쓰기를 취소하시겠습니까?\\n글쓰기 취소시 작성한\\n내용이 사라집니다."}
                    confirmText={edit ? '계속 수정' : "계속 작성"}
                    cancelText={edit ? '수정 취소' : "글쓰기 취소"}
                    onCancel={() => {
                        if (blocker.state == 'blocked') {
                            trackEvent(EVENT_POST_WRITING_BACK_CLICK, { source: backToolbarClicked ? 'toolbar' : 'system_back', changed: title.length > 0 || content.length > 0 || images.length > 0 })
                            blocker.proceed()
                        }
                    }}
                    onConfirm={() => {
                        setBackToolbarClicked(false)
                        setShowBackConfirmModal(false)
                    }}
                    onBackgroundClick={() => {
                        setBackToolbarClicked(false)
                        setShowBackConfirmModal(false)
                    }}
                />
                }
                {showCategoryModal &&
                <div ref={categoryModalRef} 
                     style={{ position: 'absolute', height: '100vh', width: '100vw', top: 0, left: 0, maxWidth: isForPartners ? 800 : 650, background: '#000000B3', zIndex: 1 }} 
                     onClick={() => {
                        trackEvent(EVENT_POST_WRITING_CATEGORY_CLOSE, { trigger: 'modal_background', source: showCategoryModal})
                        setShowCategoryModal(false)
                     }}
                >
                    <div onClick={(e) => e.stopPropagation()} style={{ position: 'absolute', bottom: '0', width: '100%', background: 'white', borderRadius: '10px 10px 0 0' }}>
                        <DrawerTemplate 
                            title={'게시글의 주제를 선택해주세요'}
                            subtitle={''}
                            handleOnClick={() => {
                                trackEvent(EVENT_POST_WRITING_CATEGORY_CLOSE, { trigger: 'modal_close_button', source: showCategoryModal })
                                setShowCategoryModal(false)
                            }} 
                            style={{}} 
                            closeButton={true}
                        >
                            { postCategoryItems.filter(e => e != '전체').map((e, idx) => {
                                return <div key={idx} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '15px 0px'}} 
                                    onClick={() => {
                                        setCategory(e)
                                        trackEvent(EVENT_POST_WRITING_CATEGORY_SELECT, { category: e, source: showCategoryModal })
                                        setShowCategoryModal(false)
                                    }}>
                                    <RegularText_20 style={{color: category == e ? primary : 'black'}}>{e}</RegularText_20>
                                    { category == e ? <IcCheckMark /> : null }
                                </div>
                            })}
                        </DrawerTemplate>
                    </div>
                </div>
                }
            </>
        }
    />
};
export default PostWritingPage;