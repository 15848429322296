import React, {ReactNode} from "react"

interface ButtonProps {
    icon1?: ReactNode
    icon2?: ReactNode
    children: ReactNode,
    active?: boolean,
    onClick: () => void,
    style?: React.CSSProperties,
}

export const Button = ({children, active = true, onClick, style, icon1, icon2}: ButtonProps) => {
    return <button style={{background: active ? '#0ABF53' : '#D9D9D9', border: 'none', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: 16, gap: 10, ...style}} onClick={active ? onClick : () => {}}>
        {icon1 ?? <div/>}
        {children}
        {icon2 ?? <div/>}
    </button>
}

interface ButtonTextProps {
    text: string,
    style?: React.CSSProperties
}

export const ButtonText = ({style, text}: ButtonTextProps) =>  <span style={{fontSize: '1.125rem', fontWeight: 500, color: 'white', ...style}}>{text}</span>

interface FillButtonProps {
    active?: boolean,
    text: string,
    style?: React.CSSProperties,
    onClick?: (e: React.MouseEvent) => void,
    onInactiveClick?: (e: React.MouseEvent) => void,
    fontStyle?: React.CSSProperties
}
const FillButton = ({ active = true, text, style, onClick, fontStyle, onInactiveClick = undefined }: FillButtonProps) => {
    let ButtonBackground = { background: '#0ABF53' }
    if(!active) {
        ButtonBackground = { background: '#D9D9D9'}
    }
    return <button style={{...ButtonBackground, border: 'none', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '16px', gap: 10, ...style}} onClick ={active ? onClick : onInactiveClick}>
        <span style={{fontSize: '1.125rem', fontWeight: 500, color: 'white', ...fontStyle}}>{text}</span>
    </button>
}


interface SecondaryFillButtonProps {
    text: string,
    style?: React.CSSProperties,
    onClick: () => void,
    fontStyle?: React.CSSProperties
}
const SecondaryFillButton = ({text, style, onClick, fontStyle}: SecondaryFillButtonProps) => (
    <button style={{background: '#F4F4F4', border: 'none', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: 16, gap: 10, ...style}} onClick={onClick}>
        <span style={{fontSize: '1.125rem', fontWeight: 500, color: 'black', ...fontStyle}}>{text}</span>
    </button>
)

interface IconFillButtonProps {
    icon: React.ReactNode,
    text: string,
    style?: React.CSSProperties,
    onClick: () => void,
    fontStyle?: React.CSSProperties
}
const IconFillButton = ({icon, text, style, onClick, fontStyle}: IconFillButtonProps) => (
    <button style={{ background: '#0ABF53', border: 'none', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: 16, gap: 10, ...style}} onClick={onClick}>
        {icon}
        <span style={{fontSize: '1.125rem', fontWeight: 500, color: 'white', ...fontStyle}}>{text}</span>
    </button>
)

const IconFillButtonCenter = ({icon, text, style, onClick, fontStyle}: IconFillButtonProps) => (
  <button style={{ position: 'relative', background: '#0ABF53', border: 'none', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: 16, gap: 10, ...style}} onClick={onClick}>
      {icon}
      <span style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)', fontSize: '1.125rem', fontWeight: 500, color: 'white', ...fontStyle}}>{text}</span>
  </button>
)


interface BorderButtonProps {
    icon?: React.ReactNode,
    text: string,
    style?: React.CSSProperties,
    onClick: () => void,
    fontStyle?: React.CSSProperties
}
const BorderButton = ({icon, text, style, onClick, fontStyle}: BorderButtonProps) => (
    <button style={{ background: 'white', border: '1px solid #999', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: 16, gap: 10, ...style}} onClick={(e) => {e.stopPropagation(); onClick()}}>
        {icon}
        <span style={{fontSize: '1.125rem', fontWeight: 500, color: 'black', ...fontStyle}}>{text}</span>
    </button>
)

interface PlainTextButtonProps {
    icon?: React.ReactNode,
    rightIcon?: React.ReactNode,
    text: string,
    style?: React.CSSProperties,
    onClick: () => void,
    fontStyle?: React.CSSProperties
}
const PlainTextButton = ({icon, rightIcon, text, style, onClick, fontStyle}: PlainTextButtonProps) => (
    <button style={{ background: 'white', border: 'none', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: 16, gap: 10, ...style}} onClick={onClick}>
        {icon}
        <span style={{fontSize: '1.125rem', fontWeight: 500, color: 'black', ...fontStyle}}>{text}</span>
        {rightIcon}
    </button>
)

export { IconFillButtonCenter, IconFillButton, BorderButton, FillButton, SecondaryFillButton, PlainTextButton };