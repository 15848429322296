import IModal from "@src/components/IModal";
import server_domain from "@src/components/RemoteServer";
import { subscribeToTopic } from "@src/util/firebaseCloudMessaging";
import { fetchWithRetry } from "@src/util/networks";
import { useNavigate } from "react-router-dom";
import { discountTopics } from "../model/discount_topic";
import { RootState } from "@src/reducer/store";
import { useDispatch, useSelector } from "react-redux";
import { show } from "@src/reducer/snackbarSlice";
import trackEvent from "@src/util/trackEvent";
import { EVENT_DISCOUNT_NOTIFICATION_SUBSCRIBE_COMPLETE } from "@src/components/AppConstant";

const DiscountNotificationConfirmModal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const uuid = useSelector((state: RootState) => state.user.uuid)

    const postUser = async () => {
        trackEvent(EVENT_DISCOUNT_NOTIFICATION_SUBSCRIBE_COMPLETE, undefined)
        await fetchWithRetry(server_domain() + '/event/discount/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                topic: discountTopics.first,
                uuid: uuid,
            }),
        })
    }

    return (
        <IModal
            message="할인 정보 오픈 알림을 받으시겠습니까?"
            confirmText="알림 받기"
            onCancel={() => navigate(-1)}
            onConfirm={async () => {
                await subscribeToTopic(discountTopics.first)
                await postUser();
                navigate('../', { replace: true, state: { subscribed: true }})
                dispatch(show({message: '할인 정보 오픈 알림을 신청하였습니다.'}))
            }}
            onBackgroundClick={() => {
                navigate(-1)
            }}
        />
    );
};
export default DiscountNotificationConfirmModal;