import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {FillButton, IconFillButton} from "@components/Buttons";
import IcShare from "@assets/ic_share_white.webp";
import React, {useState} from "react";
import {ShareBottomSheet} from "@components/ShareComponent";
import trackEvent from "@util/trackEvent";
import {EVENT_TAX_CALC_CLICK, EVENT_TAX_TIPS_CLICK} from "@components/AppConstant";
import {useBlocker, useNavigate} from "react-router-dom";
import {AppInstallGuideBanner} from "@components/AppInstallGuideBanner";
import ExpandableItem from "@components/expandable/ExpandableItem";
import {secondaryColor} from "@components/styles";
import IcCalcBlack from "@assets/ic_calc_black.webp";
import ExpandableList, {ExpandableListSection} from "@components/expandable/ExpandableList";
import ExpandableListHeader from "@components/expandable/ExpandableSection";

const CarTaxTipsPage = () => {
  const navigate = useNavigate()
  const [showShareModal, setShowShareModal] = useState(false)

  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (showShareModal) {
      setShowShareModal(false)
      return true;
    }
    return false;
  });

  const onShare = () => {
    setShowShareModal(true)
  }

  return <>
    <DefaultPageRoot
      top={<>
        <AppInstallGuideBanner/>
        <BackNavToolbar title={"자동차세(보유)"}/>
      </>}
      middle={<>
        <div style={{background: secondaryColor, padding: 16, display: 'flex', alignItems: 'center'}}>
          <img src={IcCalcBlack} alt={""} style={{height: 80, marginRight: 19}}/>
          <div style={{flex: 1}}>
            <p style={{margin: '0 0 10px', lineHeight: 1.5}}>나의 자동차세(보유)는?</p>
            <FillButton text={"세금 계산하기"} onClick={() => {trackEvent(EVENT_TAX_CALC_CLICK, {location: "car_tax"}); navigate('calc')}}/>
          </div>
        </div>
        <ExpandableList>
          <ExpandableListSection title={"절세 팁"}>
            <ExpandableItem title={"1년치 자동차세를 미리 내요."} onExpand={() => trackEvent(EVENT_TAX_TIPS_CLICK, {type: "property_tax", title: "1년치 자동차세를 미리 내요."})}>
              <span style={{whiteSpace: 'pre-wrap'}}>{"정부는 세금 징수율을 높이기 위해 '연납할인제도'를 운영합니다. 납부할 1년치 세금을 1, 3, 6, 9월에 미리 납부하는 방식으로 세금을 미리 낸 기간만큼 감액을 해주는 개념입니다.\n자동차세 연납은 1, 3, 6, 9월중 신청 가능하며 1월에는 2~12월 11개월분의 5%, 3월에는 4~12월 9개월분의 5%, 6월에는 7~12월 6개월분의 5%, 9월에는 10~12월 3개월분의 5% 할인이 적용됩니다. (2024년 5%, 2025년 3% 예정)"}</span>
            </ExpandableItem>
            <ExpandableItem title={"자동차세 연납 시 월의 마지막 날에 납부해요."} onExpand={() => trackEvent(EVENT_TAX_TIPS_CLICK, {type: "property_tax", title: "자동차세 연납 시 월의 마지막 날에 납부해요."})}>
              <span style={{whiteSpace: 'pre-wrap'}}>{"자동차세 연납 납부 기간은 1, 3, 6, 9월의 16일~30일 혹은 31일입니다. 납부 기간 중에는 언제 납부하더라도 할인율이 동일하기 때문에 조금이라도 이자를 더 쌓을 수 있도록 마지막 날짜에 납부하는 것이 유리합니다."}</span>
            </ExpandableItem>
          </ExpandableListSection>
        </ExpandableList>
      </>}
      bottom={<div style={{padding: 16}}>
        <IconFillButton icon={<img src={IcShare} alt={""} style={{height: 30}}/>} text={"절세 팁 공유하기"} onClick={onShare}/>
      </div>}
    />
    <ShareBottomSheet
      showModal={showShareModal}
      title={"절세 팁 공유하기"}
      content={"자동차세(보유)를 줄일 수 있는 방법을 주변 분들에게 많이 전파하세요."}
      onClose={() => setShowShareModal(false)}
      props={{
        kakao_share_title: "카카오톡으로 공유하기",
        link_copy_title: "공유 내용 복사하기",
        amplitude_props: {location: "car_tax"},
        templateId: 109448,
        templateArgs: {
          "title": "(무료)자동차세 절세 방안을 공유합니다.",
          "description": "은퇴 후 필수 앱, 위즈덤",
          "thumbnail_url": "https://app.wizdom.co.kr/img/img_og_image.webp",
          "cta": "자동차세 절세 팁 보기",
          "url": import.meta.env.VITE_URL,
          "url_path": "/save_tax/car?show_install_banner=true&show_install_guide=true&back_to_home=true&referrer=utm_source%3Dcartaxsaving%26utm_campaign%3Dsharing%26utm_content%3Dwizdomutil"
        },
        toastText: "복사되었습니다.\n붙여넣기 하여 공유해주세요.",
        url: "[위즈덤]\n자동차세 절세 팁\n\n6월, 12월 납부하는 자동차세\n지자체 계산 방식 그대로 정확하게 계산하고 절세 방안까지 알려드립니다.\n\n" + import.meta.env.VITE_URL + "/save_tax/car?show_install_banner=true&show_install_guide=true&back_to_home=true&referrer=utm_source%3Dcartaxsaving%26utm_campaign%3Dsharing%26utm_content%3Dwizdomutil"
      }}
    />
  </>
}

export default CarTaxTipsPage;