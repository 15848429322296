import {IconFillButton} from "@components/Buttons";
import {BottomDrawerTemplate} from "@components/DrawerTemplate"
import openCamera from "@util/openCamera";
import IcCamera from "@assets/ic_camera.webp"
import IcImage from "@assets/ic_image.webp"
import openGallery from "@util/openGarllery";

const ImageSourceSelector = ({id, showModal, setShowModal, multipleImage = false}: { id: string, showModal: boolean, setShowModal: (showModal: boolean) => void, multipleImage?: boolean }) => {

  return (
    <BottomDrawerTemplate
      show={showModal}
      title={"사진 등록하기"}
      onClose={() => setShowModal(false)}
    >
      <IconFillButton
        icon={<img src={IcCamera} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '8px' }} />}
        style={{ background: 'white', justifyContent: 'start', marginBottom: 15, border: '1px solid #999', }}
        fontStyle={{ color: 'black' }}
        text={"카메라로 사진찍기"}
        onClick={() => {
          setShowModal(false)
          openCamera(id)
        }}
      />
      <IconFillButton
        icon={<img src={IcImage} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '8px' }} />}
        fontStyle={{color: 'black'}}
        style={{ background: 'white', justifyContent: 'start', marginBottom: 15, border: '1px solid #999', }}
        text={"앨범에서 가져오기"}
        onClick={() => {
          setShowModal(false)
          openGallery(id, multipleImage)
        }}
      />
    </BottomDrawerTemplate>
  )
}

export default ImageSourceSelector;