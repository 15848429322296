import { useEffect, useState } from 'react';
import categoryData from '../data/category_data.json'
import { RootState } from '@src/reducer/store';
import { useSelector } from 'react-redux';

interface CategoryGridItemProps {
    name: string
    selected: boolean
    onClick: () => void
}

const CategoryGridItem = ({ name, selected, onClick }: CategoryGridItemProps) => {
    return (
        <div style={{
            borderRadius: 5,
            height: '60px',
            border: `1px solid ${selected ? '#0ABF53' : '#999999'}`,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            background: selected ? '#EEF9F2' : 'white',
        }}
            onClick={onClick}
        >
        {
            name && name.split('\n').map((line, index) => {
                return (
                    <p key={index} 
                    style={{ 
                        margin: 0,
                        lineHeight: 1.5,
                        width: '100%',
                        color: selected ? '#0ABF53' : 'black',
                        fontSize: '1rem',
                        fontWeight: 400
                     }}>
                        {line}
                    </p>
                )
            })
        }
        </div>
    )
}

interface CategoryContainerProps {
    onUpdate: (categoryIds: number[]) => void,
    style?: React.CSSProperties
}
const CategoryContainer = ({ onUpdate, style }: CategoryContainerProps) => {
    const categoryIds = [1, 2, 3, 10, 4, 5, 11, 8, 9, 7, 6, 12];
    const userCategoryList = useSelector((state: RootState) => state.user.categoryIds)
    const [categoryList, setCategoryList] = useState<number[]>(userCategoryList || [])
    const onClick = (id: number) => {
        setCategoryList((prev) => {
            if (prev.includes(id)) {
                return prev.filter((item) => item !== id)
            } else {
                return [...prev, id]
            }
        })
    }

    useEffect(() => {
        onUpdate(categoryList)
    }, [categoryList])

    return (
        <div style={{ display: "grid", flexWrap: 'wrap', gap: 13, gridTemplateColumns: '1fr 1fr', ...style }}>
            {categoryIds && categoryIds.map((categoryId) => (
               <CategoryGridItem key={categoryId} name={categoryData[categoryId]} onClick={() => onClick(categoryId)} selected={categoryList.includes(categoryId)}/>
            ))}
        </div>
    )
}

export default CategoryContainer;