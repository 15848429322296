import JobMenuItem, {JobMenuItemType} from "@src/features/job/component/JobMenuItem";

const MainPageMenu = () => {
    const topMenuItems: JobMenuItemType[] = ["Location", "Time", "JobType", "Government", "Best"]
    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '10px', padding: '0px 16px' }}>
            {topMenuItems.map((type, idx) => <JobMenuItem key={type} type={type} style={{ gridColumn: (topMenuItems.length % 2 == 1) && idx < 2 ? 'span 3' : 'span 2' }} />)}
        </div>
    )
};

export default MainPageMenu;