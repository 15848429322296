import icArrowRight from '@assets/ic_arrow_right.webp';
import icMap from "@assets/ic_map.webp";
import icRecruitment from "@assets/ic_recruitment.webp";
import icClock from "@assets/ic_clock.webp";
import icBest from "@assets/ic_best.webp";
import icGovernment from "@assets/ic_government.webp";
import { useNavigate } from 'react-router-dom';
import { EVENT_BEST_JOB_CLICK, EVENT_GOVERNMENT_JOB_CLICK, EVENT_JOB_CATEGORY_CLICK, EVENT_JOB_TIME_TYPE_CLICK, EVENT_LOCATION_FILTER_CLICK } from '@components/AppConstant';
import { useSelector } from 'react-redux';
import { RootState } from '@src/reducer/store';
import trackEvent from '@src/util/trackEvent';
import { CSSProperties } from 'react';

export type JobMenuItemType = "Location" | "Time" | "JobType" | "Government" | "Best";

export type TagId = "a9beb77a-9096-4720-8379-26b8cd15d2d2";

export const TagItem: { [key: string]: TagId } = {
    Government: "a9beb77a-9096-4720-8379-26b8cd15d2d2",
}

interface JobMenuItemProps {
    style?: CSSProperties,
    type: JobMenuItemType;
}

export default function JobMenuItem({ style, type }: JobMenuItemProps) {
    const navigate = useNavigate();
    let src: React.ImgHTMLAttributes<HTMLImageElement>["src"];
    let title: string;
    let height: string = '60%';
    let onClick: () => void = () => { };

    const regions = useSelector((state: RootState) => state.user.regions)
    const categoryIds = useSelector((state: RootState) => state.user.categoryIds)
    const workingTimeType = useSelector((state: RootState) => state.user.workingTimeType)
    const simpleApplay = useSelector((state: RootState) => state.user.simpleApplyFilterOn)

    let eventName: string;
    const eventProperties = {
        regions: regions,
        categoryIds: categoryIds,
        workingTimeType: workingTimeType,
        simpleApply: simpleApplay
    }
    switch (type) {
        case "Location":
            src = icMap;
            title = "지역";
            onClick = () => { 
                if (!regions || regions.length == 0) {
                    navigate('region_select', { state: { directSearch: true } }) 
                    return
                }
                navigate('activity_list')
            };
            height = '60%';
            eventName = EVENT_LOCATION_FILTER_CLICK
            break;
        case "Time":
            src = icClock;
            title = "전일제/\n시간제";
            onClick = () => {
                if (!workingTimeType) {
                    navigate('job_time_type') 
                    return
                }
                navigate('activity_list')
            };
            height = '50%';
            eventName = EVENT_JOB_TIME_TYPE_CLICK
            break;
        case "JobType":
            src = icRecruitment;
            title = "직종";
            onClick = () => {
                if (!categoryIds || categoryIds.length == 0) {
                    navigate('job_category')
                    return
                }
                navigate('activity_list')
            };
            height = '55%';
            eventName = EVENT_JOB_CATEGORY_CLICK
            break;
        case "Government":
            src = icGovernment;
            title = "공공\n일자리";
            onClick = () => navigate(`activity_list`, { state: { tagId: TagItem.Government, title: '공공 일자리', filterEnabled: false } });
            eventName = EVENT_GOVERNMENT_JOB_CLICK
            height = '50%';
            break;
        case "Best":
            src = icBest;
            title = "인기\n공고";
            onClick = () => navigate('popular_activity_more', { state: { title: '실시간 인기 공고' } });
            eventName = EVENT_BEST_JOB_CLICK
            height = '55%';
            break;
    }

    return (
        <div style={{ ...style, borderRadius: '5px', border: '1px solid #999', overflow: 'hidden' }}
            onClick={() => {
                onClick();
                trackEvent(eventName, eventProperties);
            }}
        >
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80px",
            }}>
                <img src={src} style={{ height: height, aspectRatio: '1' }}></img>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80px",
                background: "#EEF9F2",
            }}>
                <p style={{ fontWeight: 500, marginRight: '8px', fontSize: '1.125rem', whiteSpace: 'pre', textAlign: 'center' }}>{title}</p>
                <img src={icArrowRight} style={{ width: '6px', height: '12px' }} />
            </div>
        </div>
    )
}