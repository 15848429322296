import { AppInstallGuideBanner } from "@src/components/AppInstallGuideBanner";
import { DefaultPageRoot } from "@src/components/PageTemplate";
import { BackNavToolbar } from "@src/components/Toolbars";
import { JobTimeSelector } from "./WorkingTimeTypeDrawer";
import { WorkingTimeType } from "@src/model/WorkingTimeType";
import { CSSProperties, useState } from "react";
import CategoryContainer from "@src/components/CategoryContainer";
import { FillButton, IconFillButton } from "@src/components/Buttons";
import IcReset from "@assets/ic_reset.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeCategoryIds, changeWorkingTimeType, changeSimpleApplyFilterOn, clearFilter } from "@src/reducer/userSlice";
import ApplyMethodSelector from "./ApplyMethodSelector";
import Modal from "@src/components/Modal";
import trackEvent from "@src/util/trackEvent";
import useOnLog from "@src/components/useOnLog";
import { EVENT_JOB_FILTER_APPLY, EVENT_JOB_FILTER_PAGE_SHOW } from "@src/components/AppConstant";

const subTitleStyle: CSSProperties = { fontSize: '0.875rem', color: '#999', marginLeft: '8px' }

const ActivityFilterPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [workingTimeType, setWorkingTimeType] = useState<WorkingTimeType | undefined>()
    const [categoryIds, setCategoryIds] = useState<number[]>([])
    const [isSimpleApply, setIsSimpleApply] = useState<boolean>(false)
    const [filterResetModal, setFilterResetModal] = useState<boolean>(false)
    const [filterChildrenKey, setFilterChildrenKey] = useState<number>(0)

    const onWorkingTimeTypeSelect = (value: WorkingTimeType | undefined) => {
        setWorkingTimeType(value)
    }
    const onUpdateCategory = (categoryIds: number[]) => {
        setCategoryIds(categoryIds)
    }

    const onSelectIsSimpleApplay = (isSimpleApply: boolean) => {
        setIsSimpleApply(isSimpleApply)
    }

    const resetFilterChildren = () => {
        setFilterChildrenKey(filterChildrenKey + 1)
    }

    useOnLog({
        pageNameKey: EVENT_JOB_FILTER_PAGE_SHOW,
        regStr: "/filter$"
    })

    return (
        <DefaultPageRoot
            top={<>
                <AppInstallGuideBanner />
                <BackNavToolbar title={"필터"} />
            </>}
            middle={<>
                <div style={{ marginTop: '40px' }}>
                    <p style={{ fontSize: '1.25rem', fontWeight: 500, margin: '0 16px 20px', color: '#1D1D1D' }}>근로시간</p>
                    <div style={{padding: '0 16px'}}>
                        <JobTimeSelector key={filterChildrenKey} onSelect={onWorkingTimeTypeSelect} />
                    </div>
                    
                </div>
                <div style={{ marginTop: '60px' }}>
                    <p style={{ fontSize: '1.25rem', fontWeight: 500, margin: '0 16px 20px', color: '#1D1D1D' }}>직종<span style={subTitleStyle}>복수 선택 가능</span></p>
                    <div style={{padding: '0 16px'}}>
                        <CategoryContainer key={filterChildrenKey} onUpdate={onUpdateCategory} />
                    </div>
                </div>
                <div style={{ marginTop: '60px', paddingBottom: '60px' }}>
                    <p style={{ fontSize: '1.25rem', fontWeight: 500, margin: '0 16px 20px', color: '#1D1D1D' }}>지원방식</p>
                    <div style={{padding: '0 16px'}}>
                        <ApplyMethodSelector key={filterChildrenKey} onSelectIsSimpleApply={onSelectIsSimpleApplay} />
                    </div>
                </div>
            </>}
            bottom={
                <div style={{ display: 'flex', gap: '10px', margin: '8px 3%' }}>
                    <IconFillButton
                        icon={<IcReset></IcReset>}
                        text="초기화"
                        onClick={() => setFilterResetModal(true)}
                        style={{
                            background: 'white',
                            border: '1px solid #999',
                        }}
                        fontStyle={{
                            color: 'black'
                        }}
                    />
                    <FillButton
                        text={"공고 보기"}
                        onClick={() => {
                            trackEvent(EVENT_JOB_FILTER_APPLY, {
                                workingTimeType: workingTimeType,
                                categoryIds: categoryIds,
                                simpleApply: isSimpleApply,
                            })
                            dispatch(changeWorkingTimeType(workingTimeType))
                            dispatch(changeCategoryIds(categoryIds))
                            dispatch(changeSimpleApplyFilterOn(isSimpleApply))
                            navigate(-1)
                        }}
                    />
                </div>
            }
            modal={
                (filterResetModal && (<Modal
                    message="설정된 필터를 초기화하시겠습니까?"
                    onCancel={() => {
                        setFilterResetModal(false)
                    }}
                    onConfirm={() => {
                        dispatch(clearFilter())
                        resetFilterChildren()
                        setFilterResetModal(false)
                    }}
                />)) as React.ReactPortal
            }
        />
    )
}

export default ActivityFilterPage;