import { useLocation, useNavigate } from "react-router-dom";
import { DialogDrawerTemplate } from "@components/DrawerTemplate";
import openCall from "@util/openCall";
import { BorderButton } from "@components/Buttons";
import IcPhoneBlack from '@assets/ic_phone_black.svg';

const TelDrawer = () => {
    const state = useLocation().state;
    const navigate = useNavigate();
    const { title, number } = state;

    return (
        <DialogDrawerTemplate
            show={true}
            onClose={() => navigate(-1)}
            title={title}
        >
            {number.split(',').map((tel: string) => (
                <BorderButton
                    icon={<IcPhoneBlack />}
                    text={tel}
                    style={{ justifyContent: 'start', marginBottom: 10 }}
                    onClick={() => openCall(tel)}
                />
            ))}
        </DialogDrawerTemplate>
    )
}

export default TelDrawer;