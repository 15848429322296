import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store, { persistor } from './reducer/store';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query'
import { initSentry } from './util/sentry';
import React from 'react';

declare global {
  interface Window {
    Kakao: any;
    flutter_inappwebview: any;
    AndroidInterface: AndroidInterface
  }
}

interface AndroidInterface {
  goBack: (canGoBack: boolean, url: string) => boolean;
}


initSentry();

export const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);