import { useEffect } from "react"
import { CUSTOM_EVENT_APP_RESUMED } from "./AppConstant";
import * as amplitude from "@amplitude/analytics-browser";
import { useLocation } from "react-router-dom";

const useOnLog = ({ pageNameKey, amplitudeProp, regStr }: {
    pageNameKey: string,
    amplitudeProp?: Record<string, any>,
    regStr?: string | RegExp
}) => {
    const location = useLocation();
    const regex: RegExp | null = regStr ? new RegExp(regStr) : null;
    useEffect(() => {
        if (regex?.test(location.pathname)) {
            logPageShow()
        }
    }, [location.pathname]);

    function logPageShow() {
        amplitude.track(pageNameKey, amplitudeProp);
    }

    function onResumed() {
        // console.log(`${window.location.pathname}`)
        const isMatch = regex?.test(window.location.pathname)
        if (isMatch) {
            logPageShow()
        }
    }

    useEffect(() => {
        window.addEventListener(CUSTOM_EVENT_APP_RESUMED, onResumed);
        return () => {
            window.removeEventListener(CUSTOM_EVENT_APP_RESUMED, onResumed);
        }
    }, []);
}
export default useOnLog;