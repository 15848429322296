import { useDispatch, useSelector } from "react-redux"
import { FillButton } from "./Buttons"
import { clearFilter } from "@src/reducer/userSlice"
import { useNavigate } from "react-router-dom"
import { RootState } from "@src/reducer/store"
import categoryData from '../data/category_data.json'
import { primary, secondary } from "./Color"
import { CSSProperties, useState } from "react"
import Modal from "./Modal"

const FilterResultFooter = (
    { style }: { style?: CSSProperties },
) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const workingTimeType = useSelector((state: RootState) => state.user.workingTimeType)
    const categoryIds = useSelector((state: RootState) => state.user.categoryIds)
    const simpleApply = useSelector((state: RootState) => state.user.simpleApplyFilterOn)

    const headerStyle: CSSProperties = {
        fontSize: '1rem',
        fontWeight: 500,
        marginBottom: '10px',
        color: '#1D1D1D',
        minWidth: '100px'
    }

    interface Filter {
        name: string;
        value: string;
    }

    const filters: Filter[] = [
        !!workingTimeType && {
            name: '채용 유형',
            value: workingTimeType
        },
        categoryIds && categoryIds.length !== 0 && {
            name: '직종',
            value: categoryIds.map((e) => categoryData[e]).join(", ")
        },
        simpleApply && {
            name: '지원방식',
            value: '간편지원 공고만 보기'
        }
    ].filter(Boolean) as Filter[]

    return (
        <>
        {filters.length !== 0 && <div style={{ display: 'flex', flexDirection: 'column', margin: '16px 16px', padding: "0px 16px 16px 16px", background: secondary, borderRadius: '8px', ...style }}>
            <p style={{ fontWeight: '700', color: primary, fontSize: '1.125rem', lineHeight: '1.5' }}>회원님이 설정한 필터에요</p>
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: '14px'}}>
                {filters.map((filter, index) => {
                    return (
                        <div key={index} style={{ display: 'flex', gap: '16px' }}>
                            <span style={headerStyle}>{filter.name}</span>
                            <span style={{color: '#474D5B', lineHeight: '1.5'}}>{filter.value}</span>
                        </div>
                    )
                })}
            </div>
            <div style={{ display: 'flex', gap: '11px', marginTop: '30px' }}>
                <FillButton
                    text="필터 초기화"
                    onClick={() => setShowModal(true)}
                    style={{
                        background: 'white',
                        border: '1px solid #999',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}
                    fontStyle={{
                        color: 'black',
                        fontSize: '1rem'
                    }}
                />
                <FillButton
                    text={"필터 변경"}
                    onClick={() => {
                        navigate('filter')
                    }}
                    fontStyle={{
                        fontSize: '1rem',
                    }}
                    style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}
                />
            </div>
            {showModal && <Modal
                message="설정된 필터를 초기화하시겠습니까?"
                onCancel={() => {
                    setShowModal(false)
                }}
                onConfirm={() => {
                    dispatch(clearFilter())
                    setShowModal(false)
                }}
            />
            }
        </div>
        }
        </>
    )
}

export default FilterResultFooter;
