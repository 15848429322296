import IcCheckCircle from "@assets/ic_check_circle.webp";
import React, {CSSProperties} from "react";
import {firstItemRightMargin, secondItemWidth} from "@src/features/job/features/history/style/HistoryItemLayout";
import {zeroPad} from "@components/form/ResumeComponent";

const formatDate = (date: string) => {
  const dateObj = new Date(date);
  dateObj.setHours(dateObj.getHours() + 9);
  console.log(dateObj.getMinutes(), zeroPad(dateObj.getMinutes(), 2))
  return dateObj.getFullYear() + '.' + (dateObj.getMonth() + 1) + '.' + dateObj.getDate() + ' ' + zeroPad(dateObj.getHours(), 2) + ':' + zeroPad(dateObj.getMinutes(), 2);
}

const HistoryEvent: { [key: string]: string } = {
  "apply_method_checked": "지원방법확인",
  "applied": "지원 완료",
  "application_form_opened": "지원서 열람",
  "interview": "면접 진행",
  "final_passed": "최종 합격"
}

const HistoryActionButton: { [key: string]: string } = {
  "applied": "지원 기입",
  "interview": "면접 기입",
  "final_passed": "합격 기입"
}

const IcCircle = ({style = {}}: { style?: CSSProperties }) => <div style={{borderRadius: '100%', background: '#D9D9D9', ...style}}/>

const HistoryItem = ({status, lastItem, prerequisites = [], events, onClick}: { status: string, lastItem: boolean, prerequisites?: string[], events: any, onClick?: () => void }) => {
  const event = events.find((event: any) => event.status === status);
  const isPrerequisite = prerequisites.every(prerequisite => events.find((event: any) => event.status === prerequisite));

  return <div style={{display: 'flex', alignItems: 'center', margin: '10px 0'}}>

    {event ?
      lastItem ?
        <img src={IcCheckCircle} alt={""} style={{height: 30, margin: `0 ${firstItemRightMargin}px 0 0`}}/>
        :
        <div style={{width: (30 + firstItemRightMargin), padding: '5px 0px'}}>
          <IcCircle style={{width: 20, height: 20, marginLeft: 5, background: '#0ABF53'}}/>
        </div>
      :
      <div style={{width: (30 + firstItemRightMargin), padding: '5px 0px'}}>
        <IcCircle style={{width: 20, height: 20, marginLeft: 5}}/>
      </div>
    }
    <span style={{width: secondItemWidth, fontWeight: 500}}>{HistoryEvent[status]}</span>

    {
      event ?
        event.date ?
          <span style={{flex: 1, textAlign: 'center', fontSize: '0.875rem', fontWeight: 300, color: '#999'}}>{formatDate(event.date)}</span>
          :
          <></>
        :
        isPrerequisite ?
          <span style={{flex: 1, border: '1px solid #999', borderRadius: 5, textAlign: 'center'}} onClick={onClick}>{HistoryActionButton[status]}</span>
          :
          null
    }
  </div>
}

export default HistoryItem;