import { BackNavToolbar } from "@components/Toolbars";
import { useNavigate } from "react-router-dom";
import Padding5perListItem from "@components/NoPaddingListItem";
import useOnLog from "@components/useOnLog";
import { EVENT_SCRAP_PAGE_SHOW } from "@components/AppConstant";
import { DefaultPageRoot } from '@components/PageTemplate';


const SettingView = () => {
    const navigate = useNavigate();

    useOnLog({
        pageNameKey: EVENT_SCRAP_PAGE_SHOW,
        regStr: "^\/setting$"
    })

    return (
        <DefaultPageRoot 
            top={<BackNavToolbar title={'설정'}/>}
            middle={
                <div style={{ padding: '0px 5%' }}>
                    <Padding5perListItem text={'로그아웃'} onClick={() => navigate('logout')} />
                    <Padding5perListItem text={'회원탈퇴'} onClick={() => navigate('signout')} />
                </div>
            }
        />
    );
}

export default SettingView;