import { DefaultPageRoot } from "@src/components/PageTemplate";
import icMenu from "@assets/ic_menu.webp";
import icBriefCase from "@assets/ic_briefcase.webp"
import icSeniorDiscount from "@assets/ic_senior_discount.webp"
import icChat from "@assets/ic_chat.webp"
import icCalc from "@assets/ic_calc.webp"
import icFinance from "@assets/ic_finance.webp"
import icPensionCalc from "@assets/ic_pension_calc.webp"
import icTax from "@assets/ic_tax.webp"
import icReferralBanner from "@assets/ic_referral_banner.webp"
import icArrowRight from "@assets/ic_arrow_right.webp"
import { BoldText_22, MediumText_15, MediumText_17, MediumText_20, MediumText_25, RegularText_12, RegularText_20 } from "@src/components/text";
import { greyF4, greyF8, inactive, primary, secondaryBlack } from "@src/components/Color";
import { ScrollRestoration, useNavigate, useSearchParams } from "react-router-dom";
import trackEvent from "@src/util/trackEvent";
import { EVENT_ASK_WIZDOM_CLICK, EVENT_CLICK_MORE_COMMUNITY_POST, EVENT_CLICK_MORE_JOB_ACTIVITY, EVENT_HOME_ITEM_CLIK, EVENT_HOME_PAGE_SHOW, EVENT_IMPRESSION_JOB_IN_HOME, EVENT_SHARE_VIEW_CLICK, KEY_ACTIVITY_CLICK_COUNT } from "@src/components/AppConstant";
import useOnLog from "@src/components/useOnLog";
import { PlainTextButton } from "@src/components/Buttons";
import Post from "@src/features/community/model/Post";
import { useState } from "react";
import { PostItem } from "@src/features/community/page/component/PostItem";
import { Activity } from "@src/model/Activity";
import { useOnMountOnce } from "@src/util/useOnMountOnce";
import loadHomeData from "@src/data-layer/HomeDataLoader";
import { useDispatch, useSelector } from "react-redux";
import { increment } from "@src/reducer/eventSlice";
import { fontSize } from "@src/components/font";
import { ImpressionTracker } from "@src/components/useOnScreenActivity";
import { RootState } from "@src/reducer/store";
import ActivityCardContent from "@src/features/job/component/ActivityCardContent";
import { AppInstallGuideBanner } from "@src/components/AppInstallGuideBanner";

const HomePage = () => {
    const navigate = useNavigate()
    const [posts, setPosts] = useState<Post[]>([])
    const [activities, setActivities] = useState<Activity[]>([])
    const dispatch = useDispatch()
    const nickname = useSelector((state: RootState) => state.user.nickname)
    const onboarded = useSelector((state: RootState) => state.user.onboarded);
    const [searchParams, setSearchParams] = useSearchParams()
    const showInstallBannser = searchParams.get("show_install_banner") === "true"

    const shareKakao = () => {
        trackEvent(EVENT_SHARE_VIEW_CLICK, { location: 'HomePageBanner' })
        navigate({
            pathname: "share"
        }, {
            state: {
                amplitude_props: {
                    location: 'HomePage',
                },
                title: "지인 초대하기",
                templateId: 108511,
                kakao_share_title: "카카오톡으로 앱 링크 보내기",
                link_copy_title: "앱 설치 링크 복사하기",
                toastText: "앱 설치 주소가 복사되었습니다.\n붙여넣기 하여 공유해주세요.",
                url: `은퇴 후 필수 앱, 위즈덤을 소개합니다.\n\n50세 이상 분들이 실제로 지원할 수 있는 채용 정보부터\n은퇴 후 필요한 각종 정보까지!\n\n위즈덤에서 은퇴 후 도움이 될 정보를 무료로 얻어가세요.\nhttps://play.google.com/store/apps/details?id=com.modex.wizdom&referrer=utm_source%3Dappshare%26utm_campaign%3Dbanner%26utm_content%3Dsimple`,
                templateArgs: {
                    "title": "은퇴 후 필수 앱, 위즈덤을 소개합니다",
                    "description": "무료로 은퇴 후 도움이 될 정보를 얻어가세요!",
                    "thumbnail_url": "https://app.wizdom.co.kr/img/img_og_image.webp",
                    "cta": "앱 설치하기",
                    "url": 'https://play.google.com',
                    "url_path": 'store/apps/details?id=com.modex.wizdom&referrer=utm_source%3Dappshare%26utm_campaign%3Dbanner%26utm_content%3Dsimple'
                }
            }
        })
    }

    const navigateToCommunity = () => {
        if (nickname == null && !onboarded) {
            navigate('community')
            navigate('community/onboarding')
        } else {
            navigate('community')
        }
    }
    
    const items: {icon: string, title: string, onClick: () => void}[] = [
        {
            icon: icChat,
            title: '커뮤니티',
            onClick: () => {
                trackEvent(EVENT_HOME_ITEM_CLIK, { item: 'community' })
                navigateToCommunity()
            }
        },
        {
            icon: icBriefCase,
            title: '일자리',
            onClick: () => {
                trackEvent(EVENT_HOME_ITEM_CLIK, { item: 'job' })
                navigate('job')
            }
        },
        {
            icon: icSeniorDiscount,
            title: '할인정보',
            onClick: () => {
                trackEvent(EVENT_HOME_ITEM_CLIK, { item: 'discount' })
                navigate('discount')
            }
        },
        {
            icon: icPensionCalc,
            title: '국민연금\n계산기',
            onClick: () => {
                trackEvent(EVENT_HOME_ITEM_CLIK, { item: 'calc_pension' })
                navigate('pension_calculator')
            }
        },
        {
            icon: icTax,
            title: '절세',
            onClick: () => {
                trackEvent(EVENT_HOME_ITEM_CLIK, { item: 'tax_saving' })
                navigate('save_tax')
            }
        },
        {
            icon: icFinance,
            title: '금융',
            onClick: () => {
                trackEvent(EVENT_HOME_ITEM_CLIK, { item: 'finance' })
                navigate('finance')
            }
        },
        {
            icon: icCalc,
            title: '나이계산기',
            onClick: () => {
                trackEvent(EVENT_HOME_ITEM_CLIK, { item: 'calc_age' })
                navigate('age_calculator')
            }
        },
    ]

    useOnLog({
        pageNameKey: EVENT_HOME_PAGE_SHOW,
        regStr: "^\/$"
    })
    
    useOnMountOnce(() => {
        loadHomeData().then((data) => {
            setPosts(data.posts)
            setActivities(data.activities)
        })
    })

    function handleKakao() {
        trackEvent(EVENT_ASK_WIZDOM_CLICK, {
            "location": "Home",
            "type": "kakao"
        });

        if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            window.flutter_inappwebview.callHandler('fromJSKakao');
        }
    }

    function handleActivityClick(id: string) {
        dispatch(increment({key: KEY_ACTIVITY_CLICK_COUNT}))
        navigate("/activity/" + id, {state: {previous_location: "HomePage"}});
    }

    return (
        <DefaultPageRoot
            top = {
                showInstallBannser && <AppInstallGuideBanner />
            }
            middle = { <div style={{display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center', overflow: 'scroll', scrollbarWidth: 'none'}}>
                <div style={{display: 'flex', gap: '10px', alignSelf: 'start', alignItems: 'center', justifyContent: 'center', marginTop: '15px'}}>
                    <div style={{display: 'flex', paddingLeft: '20px', height: '50px', width: '30px', alignItems: 'center'}} onClick={() => navigate('mypage')}>
                        <img src={icMenu} width='25px' height='20px'></img>
                    </div>
                    <MediumText_17 
                        style={{display: 'flex', borderRadius: '5px', padding: '9px 21px', backgroundColor: 'white', border: `solid 1px ${secondaryBlack}`, textAlign: 'center', justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}
                        onClick={handleKakao}
                    > 문의하기
                    </MediumText_17>
                </div>
                <p style={{fontWeight: 900, fontSize: '2.25rem', color: primary, margin: '0px', marginBottom: '10px', marginTop: '60px'}}>위즈덤</p>
                <MediumText_25 style={{color: primary}}>은퇴 후 필수 정보</MediumText_25>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'baseline', gap: '30px', marginTop: '60px', flexWrap: 'wrap'}}>
                    { items.map((item, index) => 
                        <div
                            key={index}
                            onClick={item.onClick} 
                            style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '9px'}}>
                            <img src={item.icon} width='50px' height='50px' style={{borderRadius: '5px', backgroundColor: greyF4, padding: '17.5px'}}></img>
                            <MediumText_20 style={{whiteSpace: 'pre-wrap', textAlign: 'center'}}>{item.title}</MediumText_20>
                            </div>
                        )
                    }
                </div>
                <img src={icReferralBanner} style={{width: 'calc(100% - 30px)', aspectRatio: '360 / 130', marginTop: '75px', borderRadius: '5px' }} onClick={shareKakao}></img>
                <div style={{marginTop: '40px', width: '100%', backgroundColor: greyF8}}>
                    <div style={{padding: '40px 15px'}}>
                        <BoldText_22>커뮤니티</BoldText_22>
                        <div style={{height: '15px'}}></div>
                        <RegularText_20 style={{lineHeight: '1.5'}}>은퇴를 맞이한 사람들의 모임.<br/>고민도 나누고 정보도 교류하세요.</RegularText_20>
                        <div style={{border: 'solid 1px #D9D9D9', borderRadius: '5px', backgroundColor: 'white', marginTop: '20px', padding: '5px 0px', boxShadow: "0px 2px 2px 0px rgba(0,0,0,0.1)"}}>
                            {posts.map((post, index) => 
                            <PostItem key={index} post={post} idx={index} totalLength={posts.length} newMarkEnabeld={false} source="home"/>
                            )}
                        </div>
                        <PlainTextButton text='커뮤니티 더보기' 
                            onClick={() => {
                                trackEvent(EVENT_CLICK_MORE_COMMUNITY_POST, undefined)
                                navigateToCommunity()
                            }} 
                            style={{marginTop: '24px', border: `solid 1px ${inactive}`, boxShadow: "0px 2px 2px 0px rgba(0,0,0,0.1)"}}
                            rightIcon={<img src={icArrowRight} width='9px' height='16px' style={{borderRadius: '5px'}}></img>}
                            fontStyle={{fontSize: fontSize.m}}
                        />
                    </div>
                </div>
                <div style={{marginTop: '30px', width: '100%', paddingBottom: '30px'}}>
                    <div style={{padding: '10px 15px'}}>
                        <BoldText_22>일자리</BoldText_22>
                        <div style={{height: '15px'}}></div>
                        <RegularText_20 style={{lineHeight: '1.5'}}>5060 일자리 찾을땐, 위즈덤 일자리.<br/>채용 공고를 탐색해보세요.</RegularText_20>
                        <div style={{border: 'solid 1px #D9D9D9', borderRadius: '5px', marginTop: '20px', boxShadow: "0px 2px 2px 0px rgba(0,0,0,0.1)" }}>
                            {activities.map((activity, index) => 
                            <ImpressionTracker key={index} eventName={EVENT_IMPRESSION_JOB_IN_HOME} props={{activity_id: activity.id}}>
                                <div style={{padding: '0px 15px'}} onClick={() => handleActivityClick(activity.id)}>
                                    <div style={{borderBottom: (index  < activities.length -1) || activities.length == 1 ? 'solid 1px #F4F4F4': undefined, padding: '20px 0px'}}>
                                        <ActivityCardContent activity={activity} location='HomePage'/>
                                    </div>
                                </div>
                            </ImpressionTracker>
                            )}
                        </div>
                        <PlainTextButton text='일자리 더보기' 
                            onClick={() => {
                                navigate('job')
                                trackEvent(EVENT_CLICK_MORE_JOB_ACTIVITY, undefined)
                            }} 
                            style={{marginTop: '24px', border: `solid 1px ${inactive}`, boxShadow: "0px 2px 2px 0px rgba(0,0,0,0.1)"}}
                            rightIcon={<img src={icArrowRight} width='9px' height='16px' style={{borderRadius: '5px'}}></img>}
                            fontStyle={{fontSize: fontSize.m}}
                        />
                    </div>
                </div>
                <div style={{backgroundColor: 'white', width: '100%', flexGrow: 1, background: '#F9F9F9'}}>
                    <div style={{padding: '40px 15px', display: 'flex', flexDirection: 'column'}}>
                        <MediumText_15 style={{color: secondaryBlack}}>위즈덤</MediumText_15>
                        <RegularText_12 style={{color: secondaryBlack, marginTop: '15px'}}>회사명: 주식회사 모드엑스</RegularText_12>
                        <RegularText_12 style={{color: secondaryBlack}}>대표자: 이선민</RegularText_12>
                        <RegularText_12 style={{color: secondaryBlack, marginTop: '20px'}}>전화번호: 1660-2985</RegularText_12>
                        <RegularText_12 style={{color: secondaryBlack}}>이메일: help@modex.kr</RegularText_12>
                        <RegularText_12 style={{color: secondaryBlack}}>주소: 서울시 송파구 올림픽로 240, 롯데월드 웰빙센터 2층, 222호 (스파크플러스 잠실점)</RegularText_12>
                        <RegularText_12 style={{color: secondaryBlack, marginTop: '20px'}}>사업자 등록번호: 109-86-56595</RegularText_12>
                        <RegularText_12 style={{color: secondaryBlack}}>직업정보제공사업 신고번호 : 서울동부 제2023-30호</RegularText_12>
                        <RegularText_12 style={{color: secondaryBlack}}>통신판매업 신고번호 : 2021-서울송파-4156호</RegularText_12>
                    </div>
                </div>
                <ScrollRestoration />
            </div>}
        />
    )
}
export default HomePage;