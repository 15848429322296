import { useSearchParams } from "react-router-dom";
import icWizdom from "@assets/ic_wizdom.webp"
import { useDispatch, useSelector } from "react-redux";
import { updateShowInstallGuideBanner } from "@reducer/configSlice";
import { EVENT_INSTALL_BUTTON_CLICK } from "./AppConstant";
import { updateReferrer } from "@reducer/userSlice";
import { FillButton } from "./Buttons";
import { RootState } from "@reducer/store";
import { MediumText_17 } from "./text";
import trackEvent from "@src/util/trackEvent";

export const AppInstallGuideBanner = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const showInstallGuideBanner = useSelector((state: RootState) => state.config.showInstallGuideBanner)
    const showInstallBanner = (searchParams.get("show_install_banner") === "true")
    const referrer = searchParams.get("referrer")
    const userReferrer = useSelector((state: RootState) => state.user.referrer)

    if (showInstallBanner) {
        dispatch(updateShowInstallGuideBanner(true))
    }

    if (referrer) {
        dispatch(updateReferrer(referrer))
    }

    function openStore() {
        trackEvent(EVENT_INSTALL_BUTTON_CLICK, {
            location: 'banner',
            referrer: userReferrer
        })
        if (userReferrer) {
            window.location.href = `https://play.google.com/store/apps/details?id=com.modex.wizdom&referrer=${encodeURIComponent(userReferrer)}`;
        } else {
            window.location.href = "https://play.google.com/store/apps/details?id=com.modex.wizdom&referrer=utm_source%3Dappshare%26utm_campaign%3Ddetailpage%26utm_content%3Dactivityshare";
        }
    }
 
    if (showInstallBanner || showInstallGuideBanner) {
        return (
            <div style={{ height: '100px', background: 'black', display: "flex", alignItems: 'center' }} onClick={openStore} >
                <img src={icWizdom} alt="" style={{ alignSelf: "center", height: '40px', marginLeft: '15px' }} />
                <MediumText_17 style={{ color: 'white', lineHeight: 1.5, marginLeft: '13px' }}>
                    은퇴 후 일자리, 재무 등 필수<br/>정보를 매일 받아보세요.
                </MediumText_17>
                <div style={{position: 'absolute', right: '15px', display: 'flex', gap: '6px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <FillButton 
                        style={{height: '33px', width: 'none', padding: '16px 8px'}}
                        fontStyle={{fontSize: '1rem'}}
                        text={"앱 설치"}
                        onClick={() => {}}
                    />
                </div>
                
            </div>
        )
    }
    return null
}