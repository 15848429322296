import {useCallback, useEffect, useRef, useState} from "react";

const PinchZoomWrapper = ({children}: {
  children: React.ReactNode
}) => {
  const screenRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const [viewState, setViewState] = useState({x: 0, y: 0, scale: 1});
  let scale2 = 1;
  let x2 = 0;
  let y2 = 0;

  let screenTop = 0;
  let screenBottom = 0;
  let screenLeft = 0;
  let screenRight = 0;
  let childRefOffsetHeight = 0;

  let prevDistance = -1;
  let prevPointX = -1;
  let prevPointY = -1;
  const evHistory: Touch[] = [];

  const touchStartHandler = useCallback((event: any) => {
    const touches = event.changedTouches;
    prevDistance = -1;
    if (evHistory.length + touches.length <= 2) {
      if (evHistory.length == 0) {
        prevPointX = touches[0].clientX
        prevPointY = touches[0].clientY
      }
      for (let i = 0; i < touches.length; i++) {
        const touch = touches[i];
        evHistory.push(touch);
      }
    }
  }, []);

  const touchEndHandler = useCallback((event: any) => {
    const touches = event.changedTouches;
    for (let i = 0; i < touches.length; i++) {
      const touch = touches[i];
      const index = evHistory.findIndex((ev) => ev.identifier === touch.identifier);
      const left = evHistory.find((ev) => ev.identifier !== touch.identifier);
      if (left) {
        prevPointX = left.clientX
        prevPointY = left.clientY
      }
      if (index !== -1) {
        evHistory.splice(index, 1);
      }
    }
  }, []);

  const touchMoveHandler = useCallback((event: any, onPinch: any, onMove: any) => {
    if (event.currentTarget === null) return;
    const touches = event.changedTouches;
    for (let i = 0; i < touches.length; i++) {
      const touch = touches[i];
      const index = evHistory.findIndex((ev) => ev.identifier === touch.identifier);
      if (index !== -1) {
        evHistory[index] = touch; // update
        if (evHistory.length === 2) {
          const xDiff = evHistory[0].clientX - evHistory[1].clientX;
          const yDiff = evHistory[0].clientY - evHistory[1].clientY;
          const distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
          if (prevDistance > 0) {
            const x = (evHistory[0].clientX + evHistory[1].clientX) / 2;
            const y = (evHistory[0].clientY + evHistory[1].clientY) / 2;
            const width = screenRight - screenLeft;
            const height = screenBottom - screenTop;
            const zoom = distance - prevDistance;
            onPinch({zoom, x: x - screenLeft, y: y - screenTop, width, height});
          }
          prevDistance = distance;
        } else {
          const x = evHistory[0].clientX;
          const y = evHistory[0].clientY;
          const width = screenRight - screenLeft;
          const height = screenBottom - screenTop;
          onMove({x, y, width, height})
          prevPointX = x;
          prevPointY = y;
        }
      }
    }
  }, []);

  const handlePinch = useCallback(({zoom, x: centerX, y: centerY, width, height}: { zoom: any, x: any, y: any, width: any, height: any }) => {
    if (zoom === 0) return;

    const zoomWeight = Math.abs(zoom) * 0.003;

    let nextScale = scale2 + (zoom > 0 ? zoomWeight : -zoomWeight);
    if (nextScale < 1) {
      nextScale = 1
    }

    if (nextScale > 3) {
      nextScale = 3
    }

    const biasX = ((centerX - x2) * (nextScale / scale2)) - (centerX - x2);
    const biasY = ((centerY - y2) * (nextScale / scale2)) - (centerY - y2);

    let nextX = x2 - biasX;
    if (nextX > 0) {
      nextX = 0
    }
    const rightMax = width - width * scale2
    if (nextX < rightMax) {
      nextX = rightMax
    }

    let nextY = y2 - biasY;
    const currentHeight = childRefOffsetHeight * scale2
    const bottomMax = height - currentHeight
    if (nextY < bottomMax) {
      nextY = bottomMax
    }
    if (nextY > 0) {
      nextY = 0
    }

    scale2 = nextScale;
    x2 = nextX;
    y2 = nextY;

    requestAnimationFrame(() => {
      setViewState({x: x2, y: y2, scale: scale2})
    });
  }, []);

  const handleMove = useCallback(({x, y, width, height}: { x: any, y: any, width: any, height: any }) => {
    const biasX = (prevPointX - x);
    const biasY = (prevPointY - y);

    let nextX = x2 - biasX;
    if (nextX > 0) {
      nextX = 0
    }
    const rightMax = width - width * scale2
    if (nextX < rightMax) {
      nextX = rightMax
    }

    let nextY = y2 - biasY;
    const currentHeight = childRefOffsetHeight * scale2
    const bottomMax = height - currentHeight
    if (nextY < bottomMax) {
      nextY = bottomMax
    }
    if (nextY > 0) {
      nextY = 0
    }
    x2 = nextX;
    y2 = nextY;

    requestAnimationFrame(() => {
      setViewState({x: x2, y: y2, scale: scale2})
    });
  }, []);

  useEffect(() => {
    const touchStart = (event: TouchEvent) => touchStartHandler(event);
    const touchMove = (event: TouchEvent) => touchMoveHandler(event, handlePinch, handleMove);
    const touchEnd = (event: TouchEvent) => touchEndHandler(event);

    if (screenRef && screenRef.current && childRef && childRef.current) {
      const {top, left, bottom, right} = screenRef.current.getBoundingClientRect();
      screenTop = top;
      screenLeft = left;
      screenBottom = bottom;
      screenRight = right;
      childRefOffsetHeight = childRef.current.offsetHeight;
      screenRef.current.addEventListener("touchstart", touchStart);
      screenRef.current.addEventListener("touchmove", touchMove);
      screenRef.current.addEventListener("touchend", touchEnd);
    }

    return () => {
      if (screenRef && screenRef.current) {
        screenRef.current.removeEventListener("touchstart", touchStart);
        screenRef.current.removeEventListener("touchmove", touchMove);
        screenRef.current.removeEventListener("touchend", touchEnd);
      }
    };
  }, [screenRef, childRef]);

  return (
    <div ref={screenRef} style={{overflow: 'hidden', height: '100%'}}>
      <div ref={ref} style={{transformOrigin: 'top left', transform: `translateX(${viewState.x}px) translateY(${viewState.y}px) scale(${viewState.scale})`}}>
        <div ref={childRef} style={{padding: "5%"}}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PinchZoomWrapper;