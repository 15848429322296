import { isForPartners } from "@src/util/isForPartners";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MemberLevelTag from "./component/MemberLevelTag";
import { BoldText_25, MediumText_20 } from "@src/components/text";
import { greyF4, primary } from "@src/components/Color";
import { useEffect, useRef, useState } from "react";
import { useOnMountOnce } from "@src/util/useOnMountOnce";
import { MemberLevelValue, getMemberTitleFromLevel } from "../model/MemberLevel";
import { FillButton } from "@src/components/Buttons";

const MemberLevelUpModal = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const state = location.state
    const [level, setLevel] = useState((state?.level ?? 1) - 1)
    const ref = useRef<HTMLDivElement>(null)
    const [progressDone, setProgressDone] = useState(false)
    const [startMoveDone, setStartMoveDone] = useState(false)
    const confirmButtonRef = useRef<HTMLDivElement>(null)
    const textRef = useRef<HTMLDivElement>(null)
    const tagRef = useRef<HTMLDivElement>(null)
    const progressRef = useRef<HTMLDivElement>(null)

    function inProgress() {
        var width = 1;
        var id = setInterval(frame, 10);
        function frame() {
            if (ref.current === null) { return }
            if (width >= 100) {
                clearInterval(id);
                setLevel(level + 1)
                setProgressDone(true)
            } else {
                width += 0.5;
                ref.current.style.width = width + '%';
            }
        }
    }

    useOnMountOnce(() => {
        if (state?.congraturation) {
            navigate('congraturation')
            return
        }
        
        if (state?.level) {
            inProgress()
        }
    }, [])

    useEffect(() => {
        if (!progressDone) { return }
        textRef.current!.style.opacity = '1';
        progressRef.current!.style.visibility = 'collapse';
        tagRef.current!.style.marginTop = '45px';
        
        setTimeout(() => {
            setStartMoveDone(true)
        }, 1500)        
    }, [progressDone])

    useEffect(() => {
        if (!startMoveDone) return;
        confirmButtonRef.current!.style.opacity = '1';
    }, [startMoveDone])

    return <div
            style={{ 
                display: 'flex',
                position: 'absolute',
                height: '100vh',
                width: '100vw',
                background: '#000000B3',
                zIndex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: isForPartners ? 800 : 650
            }}
        >
            <div onClick={(e) => e.stopPropagation()} style={{ width: '80%', background: 'white', borderRadius: '10px', padding: '16px 16px' }}>
                <div style={{ display: 'flex', position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '320px' }}>
                    <div ref={textRef} style={{position: 'absolute', top: '16px', transitionDuration: '1.5s', opacity: 0, textAlign: 'center'}}>
                        <MediumText_20 style={{textAlign: 'center', marginTop: '10px', marginBottom: '45px', lineHeight: '1.5'}}>축하해요!<br/>{`지혜도가 올라 ${getMemberTitleFromLevel(level as MemberLevelValue)}이 되었어요!`}</MediumText_20>
                    </div>
                    <div ref={tagRef} style={{display: 'flex', flexDirection: 'column', transitionDuration: '1.5s', alignItems: 'center', marginTop: '0px'}}>
                        <MemberLevelTag level={level as MemberLevelValue} fontSize="30px" size="60px" borderRadius="5px" />
                        <BoldText_25 style={{marginTop: '10px'}}>{getMemberTitleFromLevel(level as MemberLevelValue)}</BoldText_25>
                    </div>
                    <div ref={progressRef} style={{width: '40%', height: '20px', backgroundColor: greyF4, borderRadius: '10px', marginTop: '12px', visibility: startMoveDone ? 'collapse' : 'visible' }}>
                        <div ref={ref} style={{borderRadius: '10px', width: '2%', height: '20px', backgroundColor: primary}}/>
                    </div>
                    <div ref={confirmButtonRef} style={{position: 'absolute', bottom: '16px', opacity: '0', width: '140px'}}>
                        <FillButton text="확인" onClick={() => navigate(-1)} style={{width: '100%'}} fontStyle={{fontSize: '1rem'}}/>
                    </div>
                </div>
            </div>
            <Outlet context={{onPopState: inProgress}}/>
        </div>
};

export default MemberLevelUpModal;