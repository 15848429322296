import React from "react";

interface DivideBarProps {
  margin?: string
}

const DivideBar = ({margin}: DivideBarProps) => {
  return (
    <div style={{margin: margin ? margin : '40px 0 40px 0', height: '16px', minHeight: '16px', borderTop: '1px solid #D7D8DA', background: '#F4F4F4'}}></div>
  )
}
const DividerThin = () => {
  return (<div style={{margin: '8px 0px', background: '#F4F4F4', height: 8}}/>)
}


const Divider = () => {
  return (<div style={{margin: '8px 0px', background: '#F4F4F4', height: 16}}/>)
}

export default DivideBar;
export {Divider, DividerThin};