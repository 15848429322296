import { EVENT_ACTIVITIES_LOADED } from "@components/AppConstant"
import server_domain from "@components/RemoteServer"
import convertActivities from "@util/convertActivities"
import * as amplitude from "@amplitude/analytics-browser"
import { fetchWithRetry } from "@util/networks"
import { Activity } from "@model/Activity"

const govJobDetailPageLoader = async (id: string): Promise<Activity[]> => {
    let response = await fetchWithRetry(server_domain() + '/gov_job?id=' + id, {
        headers: {
            "Content-Type": "application/json",
        },
    })
    let json = await response.json()
    let activities = convertActivities(json.activities)
    amplitude.track(EVENT_ACTIVITIES_LOADED, {
        location: "GovJobDetailPage",
        size: activities.length,
    })
    return activities
}

export default govJobDetailPageLoader;
