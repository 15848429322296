const copyText = async (text: string, toastText: string) => {
    if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
        const appVersion = await window.flutter_inappwebview.callHandler('appVersion')
        if (appVersion == null || appVersion <= 9) {
            window.flutter_inappwebview.callHandler('copyText', text);
        } else {
            window.flutter_inappwebview.callHandler('copyText', [text, toastText]);
        }
    } else if (window &&  window.navigator && window.navigator.clipboard) {
        window.navigator.clipboard.writeText(text);
        // dispatch(show({ message: toastText}))
    }
}

export default copyText;