import { DefaultPageRoot } from "@components/PageTemplate";
import { BackNavToolbar } from "@components/Toolbars";
import useOnLog from "@components/useOnLog";
import { IconFillButton } from "@components/Buttons";
import icKakao from "@assets/ic_kakao.webp";
import icShareWhite from "@assets/ic_share_white.webp";
import * as amplitude from "@amplitude/analytics-browser";
import { EVENT_SHARE_BUTTON_CLICK, EVENT_SHARE_GUIDE_PAGE_SHOW } from "@components/AppConstant";
import { show } from "@reducer/snackbarSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import server_domain from "@components/RemoteServer";
import IcClose from "@assets/close.svg";
import { shareViaKakao } from "@util/kakao"; 

const ShareGuideView = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    useOnLog({
        pageNameKey: EVENT_SHARE_GUIDE_PAGE_SHOW,
        regStr: "/share_guide$"
    })

    function shareKakao() {
        amplitude.track(EVENT_SHARE_BUTTON_CLICK, {
            source: 'kakao',
            location: 'ShareGuidePage',
        })
        shareViaKakao(
            100786,
            {
                "url": server_domain(),
                "url_path": "redirect_store?source=KakaoShare&type=o"
            }
        );
    }

    const handleLater = () => {
        // @ts-ignore
        navigate(-1, { replace: true })
    }

    async function copyLink() {
        amplitude.track(EVENT_SHARE_BUTTON_CLICK, {
            location: 'ShareGuidePage',
            source: 'direct_link'
        })
        const txt = `위즈덤 앱을 소개합니다.\\n50세 이상 분들이 실제로 지원할 수 있는 채용정보만 매일 알려주는 곳입니다.(공공채용정보, 사무행정, 학교보안관, 도서관사서, 시설관리 등)\\n저의 초대를 클릭해서 들어오시면 가입 절차 없이 무료로 사용 가능해요!\\n${server_domain()}/redirect_store?source=DirectLink&type=o`
        if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            const appVersion = await window.flutter_inappwebview.callHandler('appVersion')
            if (appVersion == null || appVersion <= 9) {
                window.flutter_inappwebview.callHandler('copyText', txt);
            } else {
                window.flutter_inappwebview.callHandler('copyText', [txt, "앱 설치 주소가 복사되었습니다.\n붙여넣기 하여 공유해주세요."]);
            }
        } else if (window && window.navigator && window.navigator.clipboard) {
            await window.navigator.clipboard.writeText(txt);
            dispatch(show({ message: "내용이 복사되었습니다. 붙여넣기 하여 공유해주세요." }))
        }
    }

    return <DefaultPageRoot
        top={<BackNavToolbar icon={<IcClose />} />}
        middle={<div style={{ padding: 16 }}>
            <p style={{ margin: '20px 0 40px', fontSize: '1.25rem', lineHeight: 1.5, fontWeight: 700, whiteSpace: 'pre-wrap' }}>{"고객님! ✋\n위즈덤 서비스에 만족하셨다면,\n지인들을 초대해주세요!"}</p>
            <IconFillButton
                icon={<img src={icKakao} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '8px' }} />}
                style={{ background: '#FEE500', justifyContent: 'center', marginBottom: 15, gap: 5 }}
                fontStyle={{ color: 'black' }}
                text={"카카오톡으로 친구 초대하기"}
                onClick={shareKakao}
            />
            <IconFillButton
                icon={<img src={icShareWhite} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '8px' }} />}
                style={{ background: '#474D5B', justifyContent: 'center', marginBottom: 30, gap: 5 }}
                text={"초대 내용 복사하기"}
                onClick={copyLink}
            />
            <p style={{ margin: 0, textAlign: 'center', textDecoration: 'underline' }} onClick={handleLater}>다음에 초대할게요</p>
        </div>}
    />
}

export default ShareGuideView;