import { useNavigate } from "react-router-dom";
import * as amplitude from '@amplitude/analytics-browser';
import ActivityCardContent from "../features/job/component/ActivityCardContent";
import { useDispatch, useSelector } from "react-redux";
import { append, remove } from "@reducer/scrapSlice";
import IcScrapActiveGreen from '@assets/ic_scrap_active_green.svg';
import IcScrapInactive from '@assets/ic_scrap_inactive.svg';
import {EVENT_SCRAP_CLICK, KEY_ACTIVITY_CLICK_COUNT} from "./AppConstant";
import { show } from "@reducer/snackbarSlice";
import isSimpleApply from "@util/isSimpleApply";
import {increment} from "@reducer/eventSlice";
import { RootState } from "@reducer/store";
import { Activity } from "@model/Activity";

const PopularActivityCard = ({ activity, location, idx, last, regions } : {
    activity: Activity,
    location: string,
    idx: number,
    last?: boolean,
    regions: string[]
}) => {
    const navigate = useNavigate();
    const scrap = useSelector((state: RootState) => state.scrap.value)
    const dispatch = useDispatch()
    
    function handleClick() {
        // if (window && window.fbq) {
        //     window.fbq('track', 'ActivityClick');
        // }
        dispatch(increment({key: KEY_ACTIVITY_CLICK_COUNT}))
        if (isSimpleApply(activity)) {
            navigate("simple_apply_activity/" + activity.id, {state: {previous_location: location}});
        } else {
            navigate("activity/" + activity.id, {state: {previous_location: location}});
        }
    }

    function isScraped() {
        if (scrap[activity.id]) {
            return true
        }
        return false
    }

    function buildStartIcon() {
        const startIconStyle = {
            width: 'auto',
            maxWidth: 'none'
        }
        if (isScraped()) {
            return <IcScrapActiveGreen style={startIconStyle} />
        } else {
            return <IcScrapInactive style={startIconStyle} />
        }
    }

    function handleScrap() {
        const prop = {
            "activity_id": activity.id,
            "user_regions": regions,
            "title": activity.activityTitle,
            "url": activity.url,
            "location": location,
            "toggle": isScraped() ? "off" : "on"
        }
        if (isScraped()) {
            dispatch(show({ message: "스크랩에서 제거되었습니다" }))
            dispatch(remove(activity))
        } else {
            dispatch(show({ message: "스크랩 되었습니다" }))
            dispatch(append(activity))
        }
        amplitude.track(EVENT_SCRAP_CLICK, prop)
    }

    let borderBottom = {}
    if (last) {
        borderBottom = { borderBottom: '1px solid #F4F4F4', }
    }

    return (
        <div style={{ borderRadius: '5px', border: '1px solid #999999', margin: '0px 16px 16px' }}>
            {
                idx != undefined &&
                <div style={{ background: '#EEF9F2', borderRadius: '5px' }}>
                    <span style={{ fontSize: '1.15rem', display:'block', textAlign: 'center', width: '100%', paddingTop: '0.1rem' }}>{idx + 1}위</span>
                </div>
            }
            <div style={{ position: 'relative', margin: '0px', borderRadius: '5px' }} >
                <button
                    onClick={handleClick}
                    style={{
                        background: 'transparent',
                        border: 'none',
                        display: 'block',
                        borderRadius: '0px',
                        lineHeight: '1rem',
                        padding: '20px 16px',
                        textAlign: 'start',
                        width: '100%',
                    }}>
                    <div>
                        {
                            activity.reserved === 1 &&
                            <span style={{ display:'inline-block', fontWeight: '500', fontSize: '0.75rem', lineHeight: '1.2rem',  verticalAlign: 'middle', backgroundColor: '#EEF9F2', borderRadius: '5px', padding: '0 16px', marginBottom: '8px', marginRight: '0.3rem', color: '#0ABF53'}}>5060 채용 약속</span>
                        }
                        {
                            isSimpleApply(activity) &&
                            <span style={{  display:'inline-block', fontSize: '0.75rem', lineHeight: '1.2rem', verticalAlign: 'middle', backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '0 0.8rem', marginBottom: '8px', marginRight: '0.3rem', fontWeight: 500, color: '#474D5B'}}>간편 지원</span>
                        }
                        <ActivityCardContent activity={activity} location={location} />
                    </div>
                </button>
                <div style={{ position: "absolute", top: '5px', right: '5px', borderRadius: '5px'}}>
                    <button onClick={handleScrap} style={{background: 'transparent', border: 'none', padding: 8}}>
                        {buildStartIcon()}
                    </button>
                </div>
            </div>
        </div>

    )
}

export default PopularActivityCard;