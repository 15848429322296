import icArrowRight from "@assets/ic_arrow_right.webp";
import React, {ReactNode} from "react";

interface ItemProps {
  title: string;
  icon: ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const Item = ({title, icon, onClick}: ItemProps) => {
  return (
    <div style={{ borderRadius: '5px', border: '1px solid #999', overflow: 'hidden' }}
         onClick={onClick}
    >
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80px",
      }}>
        {icon}
        {/*<Icon style={{ height: '10px', aspectRatio: '1' }}/>*/}
        {/*<img src={src} ></img>*/}
      </div>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80px",
        background: "#EEF9F2",
      }}>
        <p style={{ fontWeight: 500, marginRight: '8px', fontSize: '1.125rem', whiteSpace: 'pre', textAlign: 'center' }}>{title}</p>
        <img src={icArrowRight} style={{ width: '6px', height: '12px' }} alt={""}/>
      </div>
    </div>
  )
}

export default Item;