export const toYMD = (date: Date): string => {
  return date.toISOString().slice(0, 10).replace(/-/g, "")
}

export const toYM = (date: Date): string => {
  return date.toISOString().slice(0, 7).replace(/-/g, "")
}

export const toDate = (date: string): Date | null => {
  if (date.length === 7) {
    return new Date(date + "-01");
  } else if (date.length === 10) {
    return new Date(date);
  } else {
    return null;
  }
}