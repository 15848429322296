import { DefaultPageRoot } from "@src/components/PageTemplate";
import { BackNavToolbar } from "@src/components/Toolbars";
import { useRef } from "react";
import { BoldText_20, BoldText_22, RegularText_20 } from "@src/components/text";
import {  greyF4, secondaryBlack } from "@src/components/Color";
import { useNavigate } from "react-router-dom";
import useOnLog from "@src/components/useOnLog";
import { EVENT_DISCOUNT_FOR_CEO_PAGE_SHOW, EVENT_DISCOUNT_PROMOTION_REQUEST_CLICK } from "@src/components/AppConstant";
import { FillButton } from "@src/components/Buttons";
import trackEvent from "@src/util/trackEvent";

const DiscountPromoteForCEOPage = () => {
    const navigate = useNavigate()
    const parentRef = useRef<HTMLDivElement>(null)
    
    useOnLog({
        pageNameKey: EVENT_DISCOUNT_FOR_CEO_PAGE_SHOW,
        regStr: "^\/for-ceo$"
    })

    const onClickCTA = () => {
        trackEvent(EVENT_DISCOUNT_PROMOTION_REQUEST_CLICK, undefined)
        navigate('call')
    }

    return (
        <DefaultPageRoot
            top={<BackNavToolbar title='' />}
            middle={
                <div ref={parentRef} style={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    contain: 'strict',
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '15px', marginTop: '40px', lineHeight: '1.5'}}>
                        <BoldText_22 style={{textAlign: 'center'}}>수많은 위즈덤 회원님들에게 특전을 제공하고, 무료로 홍보하세요.</BoldText_22>
                    </div>
                    <div style={{margin: '15px', height: '1px', backgroundColor: 'black'}}></div>
                    <div style={{padding: '15px', lineHeight: '1.5'}}>
                        <BoldText_20>평일 낮 손님이 적어 걱정인 사장님, </BoldText_20>
                        <br></br>
                        <BoldText_20>중장년 대상 상품을 판매중인 사장님</BoldText_20><RegularText_20>이라면</RegularText_20>
                        <br></br>
                        <RegularText_20>주목해주세요!</RegularText_20>
                        <br></br>
                        <br></br>
                        <RegularText_20>평일 낮 시간을 잘 쓰려는 은퇴자 분들에게</RegularText_20>
                        <br></br>
                        <RegularText_20>무료로 가게, 상품을 홍보하고</RegularText_20>
                        <br></br>
                        <BoldText_20>매출을 늘려보세요!</BoldText_20>
                    </div>
                    <div style={{display: 'inline-flex', flexDirection: 'column', padding: '40px 15px', backgroundColor: greyF4, lineHeight: '1.5', marginTop: '20px'}}>
                        <BoldText_20 style={{display: 'flex', height: '47px', width: '256px', borderRadius: '25px', border: `solid 1px ${secondaryBlack}`, backgroundColor: 'white', textAlign: 'center', justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>사장님 혜택</BoldText_20>
                        <table style={{marginTop: '30px'}}>
                            <tbody>
                                <tr>
                                    <td style={{display: 'flex', flexDirection: 'column', width: '15px'}}><span>∙</span></td>
                                    <td><BoldText_20>5만명 이상</BoldText_20><RegularText_20>의 위즈덤 회원님들에게 사장님의 가게, 상품이 소개됩니다.</RegularText_20></td>
                                </tr>
                                <tr>
                                <td style={{display: 'flex', flexDirection: 'column', width: '15px'}}><span>∙</span></td>
                                    <td><RegularText_20>위즈덤이 받는 수수료는 없습니다. </RegularText_20><BoldText_20>무료로 홍보할 수 있습니다.</BoldText_20></td>
                                </tr>
                                <tr>
                                    <td style={{width: '15px'}}></td>
                                    <td><RegularText_20>(대신, 위즈덤 회원님들에게 홍보 혜택을 나눠주세요.)</RegularText_20></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{display: 'inline-flex', flexDirection: 'column', padding: '40px 15px', lineHeight: '1.5'}}>
                    <BoldText_20 style={{display: 'flex', height: '47px', width: '256px', borderRadius: '25px', border: `solid 1px ${secondaryBlack}`, textAlign: 'center', justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>특전 예시</BoldText_20>
                        <table style={{marginTop: '30px'}}>
                            <tbody>
                                <tr>
                                    <td style={{width: '15px'}}>∙</td>
                                    <td><RegularText_20 style={{marginTop: '30px'}}>평일 낮 몇시~몇시 방문한 위즈덤 회원님에게 20% 할인</RegularText_20></td>
                                </tr>
                                <tr>
                                    <td style={{width: '15px'}}>∙</td>
                                    <td><RegularText_20>15% 항시 할인</RegularText_20></td>
                                </tr>
                                <tr>
                                    <td style={{width: '15px'}}>∙</td>
                                    <td><RegularText_20>음료수 1인 1캔 무료 증정</RegularText_20></td>
                                </tr>
                                <tr>
                                    <td style={{width: '15px'}}>∙</td>
                                    <td><RegularText_20>...</RegularText_20></td>
                                </tr>
                            </tbody>
                        </table>
                        <RegularText_20 style={{marginTop: '8px'}}>특전 내용은 사장님이 설정 가능합니다. 거창한 특전이 아니어도 좋으니, 홍보 혜택을 위즈덤 회원님들에게 나눠주세요!</RegularText_20>
                    </div>
                    <div style={{display: 'inline-flex', flexDirection: 'column', padding: '40px 15px', backgroundColor: greyF4, lineHeight: '1.5'}}>
                        <BoldText_20 style={{display: 'flex', height: '47px', width: '256px', borderRadius: '25px', backgroundColor: 'white', border: `solid 1px ${secondaryBlack}`, textAlign: 'center', justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>참고 사항</BoldText_20>
                        <RegularText_20 style={{marginTop: '30px'}}>홍보 가능 업종과 불가능 업종이 존재하며, 다음과 같습니다.</RegularText_20>
                        <table>
                            <tbody style={{padding: '0px 15px'}}>
                                <tr>
                                    <td style={{display: 'flex', flexDirection: 'column', width: '15px'}}><span>∙</span></td>
                                    <td><RegularText_20>가능 업종 : 요식업, 농수산업, 소매업(의류, 식품, 수공예품 등), 서비스업, 체육시설업, 여행업, 그 외 불가능 업종에 명시되지 않은 업종</RegularText_20></td>
                                </tr>
                                <tr>
                                    <td style={{display: 'flex', flexDirection: 'column', width: '15px'}}><span>∙</span></td>
                                    <td><RegularText_20>불가능 업종 : 운수업, 인력소개업, 통신기기소매업, 사채업, 다단계판매업, 방문판매업</RegularText_20></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            bottom={
                <div style={{padding: '17px 15px'}}>
                    <FillButton text="가게/상품 홍보 신청하기" onClick={onClickCTA}></FillButton>
                </div>
            }
        />
    );
};

export default DiscountPromoteForCEOPage;