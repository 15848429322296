import CarTax from "@src/features/tax_saving/features/car_tax/model/CarTax";

const calculate = (data: CarTax, currentYear: number = new Date().getFullYear()) => {

  const displacementType = parseInt(data.displacement) <= 1000 ? "1,000cc 이하"
    : parseInt(data.displacement) <= 1600 ? "1,600cc 이하"
      : "1,600cc 초과"
  // console.log("배기량구분: ", displacementType)

  const taxPerDisplacement = parseInt(data.displacement) <= 1000 ? 80
    : parseInt(data.displacement) <= 1600 ? 140
      : 200
  // console.log("배기량세액: ", taxPerDisplacement)

  const standardTax = parseInt(data.displacement) * taxPerDisplacement
  // console.log("기본세액: ", standardTax)

  const halfStandardTax = standardTax / 2

  let diff = (currentYear - parseInt(data.registeredAt) + 1) * 2
  if (parseInt(data.registeredAtHalf)) {
    diff -= 1
  }

  const firstHalfCarAge= Math.floor(diff / 2)
  // console.log("상반기차령: ", firstHalfCarAge)

  const firstHalfCarAgeRatio = firstHalfCarAge < 3 ? 100
    : firstHalfCarAge < 12 ? (100 - (firstHalfCarAge - 2) * 5)
      : 50
  // console.log("상반기차령적용률: ", firstHalfCarAgeRatio, "%")

  const firstHalfCarTax = Math.floor(halfStandardTax * firstHalfCarAgeRatio * 0.01 / 10) * 10
  // console.log("상반기차동차세액: ", firstHalfCarTax)

  const firstHalfLocalEducationTax =  Math.floor(firstHalfCarTax * 30 * 0.01 / 10) * 10
  // console.log("상반기지방교육세: ", firstHalfLocalEducationTax)

  const firstHalfTotal = firstHalfCarTax + firstHalfLocalEducationTax
  // console.log("상반기세액: ", firstHalfTotal)

  const secondHalfCarAge = Math.floor((diff + 1) / 2)
  // console.log("하반기차령: ", secondHalfCarAge)

  const secondHalfCarAgeRatio = secondHalfCarAge < 3 ? 100
    : secondHalfCarAge < 12 ? (100 - (secondHalfCarAge - 2) * 5)
      : 50
  // console.log("하반기차령적용률: ", secondHalfCarAgeRatio, "%")

  const secondHalfCarTax = Math.floor(halfStandardTax * secondHalfCarAgeRatio * 0.01 / 10) * 10
  // console.log("하반기차동차세액: ", secondHalfCarTax)

  const secondHalfLocalEducationTax = Math.floor(secondHalfCarTax * 30 * 0.01 / 10) * 10
  // console.log("하반기지방교육세: ", secondHalfLocalEducationTax)

  const secondHalfTotal = secondHalfCarTax + secondHalfLocalEducationTax
  // console.log("하반기세액: ", secondHalfTotal)

  const total = firstHalfTotal + secondHalfTotal
  // console.log("총세액: ", total)

  return {
    currentYear: currentYear,
    standardTax: standardTax,
    displacementType: displacementType,
    taxPerDisplacement: taxPerDisplacement,
    firstHalfTotal: firstHalfTotal,
    halfStandardTax: halfStandardTax,
    firstHalfCarTax: firstHalfCarTax,
    firstHalfCarAgeRatio: firstHalfCarAgeRatio,
    firstHalfLocalEducationTax: firstHalfLocalEducationTax,
    secondHalfTotal: secondHalfTotal,
    secondHalfCarTax: secondHalfCarTax,
    secondHalfCarAgeRatio: secondHalfCarAgeRatio,
    secondHalfLocalEducationTax: secondHalfLocalEducationTax,
    total: total,
    nextYearTotal: total + standardTax,
    nextNextYearTotal: total + standardTax
  }
}

export const calculateCarTax = (data: CarTax) => {
  // console.log("배기량: ", data.displacement)
  // console.log("차량최초등록: ", data.registeredAt)
  // console.log("반기 ", parseInt(data.registeredAtHalf) ? "하반기" : "상반기")

  const currentYear = new Date().getFullYear()
  const currentYearTax = calculate(data, currentYear)
  const nextYearTax = calculate(data, currentYear + 1)
  const nextNextYearTax = calculate(data, currentYear + 2)

  return {
    ...currentYearTax,
    nextYearTotal: nextYearTax.total,
    nextNextYearTotal: nextNextYearTax.total
  }
}