import { Outlet, useNavigate } from "react-router-dom";
import Padding5perListItem from "@components/NoPaddingListItem";
import { EVENT_ASK_WIZDOM_CLICK, EVENT_MY_PAGE_SHOW } from "@components/AppConstant";
import { useDispatch, useSelector } from "react-redux";
import useOnLog from "@components/useOnLog";
import { FillButton } from "@components/Buttons";
import { AppInstallGuideBanner } from "@components/AppInstallGuideBanner";
import * as amplitude from "@amplitude/analytics-browser"
import openCall from "@util/openCall";
import { show } from '@reducer/snackbarSlice';
import {isForPartners} from "@util/isForPartners";
import PartnersInquiryFloatingButton from "@components/PartnersInquiryFloatingButton";
import { RootState } from '@reducer/store';
import { TouchEventHandler } from "react";
import Close from '@assets/close.svg';

const MyPageView = () => {
    const phoneNumber = useSelector((state: RootState) => state.user.phoneNumber)
    const resumes = useSelector((state: RootState) => state.user.resumes)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function handleCall() {
        amplitude.track(EVENT_ASK_WIZDOM_CLICK, {
            "location": "MyPage",
            "type": "mobile-phone"
        });
        openCall('1660-2958')
    }

    function handleKakao() {
        amplitude.track(EVENT_ASK_WIZDOM_CLICK, {
            "location": "MyPage",
            "type": "kakao"
        });

        if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            window.flutter_inappwebview.callHandler('fromJSKakao');
        } else {
        }
    }

    useOnLog({
        pageNameKey: EVENT_MY_PAGE_SHOW,
        regStr: "^\/mypage$"
    })

    const uuidHash = useSelector((state: RootState) => state.user.uuidHash)
    let touchStartX: number | null = null

    const touchStart: TouchEventHandler<HTMLDivElement> = (event) => {
        touchStartX = event.changedTouches[0].clientX
    }

    const touchEnd: TouchEventHandler<HTMLDivElement> = (event) => {
        if (event.changedTouches[0].clientX - (touchStartX ?? 0) > 200) {
            dispatch(show({message: `${uuidHash}`}))
        }
        touchStartX = null
    }

    const onClickBackground: React.MouseEventHandler = (e) => {
        e.stopPropagation()
        navigate(-1)
    }

    return (
        <div style={{ zIndex: 1, display: 'flex', flexDirection: 'column', width: '100vw', maxWidth: isForPartners ? 800 : 650, height: '100vh', backgroundColor: '#00000030' }}>
            <div style={{height: '100%', width: '100%', background: 'rgba(0, 0, 0, 0.7)', overflowY: 'hidden'}} onClick={onClickBackground}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '85%', background: 'white', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', zIndex: 1}}
                    onClick={(e) => e.stopPropagation()} 
                >
                    <button
                        onClick={() => navigate(-1)}
                        style={{
                            display: 'flex',
                            height: '75px',
                            border: 'none',
                            background: 'none',
                            color: '#1D1D1D',
                            minWidth: 'initial',
                            alignItems: 'center',
                            justifyContent: 'right',
                            marginRight: '25px'
                        }} >
                        <Close style={{right: '15px'}}/>
                    </button>
                    <AppInstallGuideBanner />
                    <div style={{ flex: 1, overflowY: 'auto' }} onTouchStart={touchStart} onTouchEnd={touchEnd}>
                        {!phoneNumber &&
                            <div style={{ padding: '5% 5% 2rem 5%' }}>
                                <p style={{ textAlign: 'center', margin: '0px 0px 1rem', lineHeight: '1.5', fontSize: '1rem', fontWeight: 500 }}>
                                    위즈덤에 가입하여,<br />
                                    은퇴에 필요한 정보를 교류하세요
                                </p>
                                <FillButton text={'로그인/회원가입'} onClick={() => navigate("login")} />
                            </div>
                        }
                        {
                            phoneNumber &&
                            <div style={{ padding: '5% 5% 2rem 5%', textAlign: 'center', lineHeight: '1.5', fontWeight: 500, fontSize: "1rem" }}>
                                <p style={{fontWeight: 500, fontSize: "1rem", color: '#1D1D1D', margin: 0}} >{phoneNumber.substr(phoneNumber.length - 4)}님</p>
                                <p style={{fontWeight: 500, fontSize: "1rem", color: '#1D1D1D', margin: 0}} >위즈덤에 오신 걸 환영합니다</p>
                            </div>
                        }
                        <div style={{height: 8, background: '#F4F4F4', width: "100%",}} />
                        <div style={{ padding: '0.5rem 5% 0px 5%' }}>
                            {
                                !isForPartners && <>
                                    <Padding5perListItem text={'전화 문의'} onClick={handleCall} />
                                    <Padding5perListItem text={'카카오톡 1:1 문의'} onClick={handleKakao} />
                                </>
                            }
                            {phoneNumber && <Padding5perListItem text={'설정'} onClick={() => navigate('setting')} /> }
                        </div>
                    </div>
                    {
                        isForPartners && <div style={{position: 'relative'}}>
                            <div style={{position: 'absolute', bottom: 30, right: 20}}>
                                <PartnersInquiryFloatingButton onClick={() => navigate("partners_inquiry")}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default MyPageView;