import { useLocation, useNavigate } from "react-router-dom";
import {BorderButton, FillButton, IconFillButtonCenter} from "@components/Buttons";
import { DefaultPageRoot } from "@components/PageTemplate";
import { BackNavToolbar } from "@components/Toolbars";
import copyText from "@util/copyText";
import openCall from "@util/openCall";
import server_domain from "@components/RemoteServer";
import {useEffect, useMemo} from "react";
import { EVENT_APPLY_PAGE_SHOW, EVENT_CALL_CLICK, EVENT_COPY_EMAIL_CLICK, EVENT_COPY_FAX_CLICK, EVENT_COPY_URL_LINK_CLICK } from "@components/AppConstant";
import * as amplitude from "@amplitude/analytics-browser";
import {useDispatch, useSelector} from "react-redux";
import uuidv4 from "@util/uuidv4";
import { RootState } from "@reducer/store";
import {fetchWithRetry} from "@util/networks";
import {updateLastUsedApplyMethod} from "@reducer/configSlice";
import FaqFloatingButton from "@src/features/job/features/apply/component/FaqFloatingButton";


const GuideText: { [key: string] : string } = {
    "전화지원": "채용담당자에게 전화하여 지원하세요",
    "이메일지원": "제출 서류를 첨부하여 메일로 보내세요",
    "방문": "제출 서류를 구비하여 방문 지원하세요",
    "팩스지원": "제출 서류를 팩스로 보내 지원하세요",
}

const OtherApplyView = () => {
    const location = useLocation();
    const state = useMemo(() => location.state, [null])
    const regions = useSelector((state: RootState) => state.user.regions);
    const uuid = useSelector((state: RootState) => state.user.uuid)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitCheckApplyMethod = () => {
        dispatch(updateLastUsedApplyMethod("간편지원 외"))
        fetchWithRetry(server_domain() + "/apply_history", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                activity_id: state.id,
                user_uuid: uuid,
                status: "apply_method_checked",
                date: new Date().toISOString()
            })
        })
    }

    function goInputEmailPage() {
        navigate("input_email", {
            state: state
        })
    }

    useEffect(() => {
        amplitude.track(EVENT_APPLY_PAGE_SHOW, {
            "type": state.title,
            "activity_id": state.id
        })
    }, [])

    function getTextUrl() {
        if (state.activityApplicationRequestMethod === "get") {
            return server_domain() + "/redirect_info?activity_id=" + state.id
        } else {
            return "https://wizdom-73ae1.web.app/redirect/" + state.id;
        }
    }

    function goRequestGuideKakao() {
        const id = uuidv4()
        navigate("request_guide_kakao", {
            state: {
                ...state,
                selectedApplyMethod: state.selectedApplyMethod,
                amplitude_props: {
                    location: 'ApplyPage',
                    activity_title: state.activityTitle,
                    activity_id: state.id,
                    id: id
                },
                templateId: 101041,
                templateArgs: {
                    title: `${state.activityOrganization} ${state.activityTitle}`,
                    url: `${import.meta.env.VITE_URL}/application_description/${state.id}?kakao_share_id=${id}`,
                },
            }
        })
    }

    return (
        <DefaultPageRoot
            top={<BackNavToolbar title={state.title ? state.title : ""} />}
            middle={
                <div style={{paddingBottom: 20}}>
                    <div style={{margin: "0px 16px"}}>
                        {
                          state.title != "컴퓨터지원" && <span style={{lineHeight: 1.5, whiteSpace: 'pre-wrap', display: 'block', background: '#EEF9F2', color: '#0ABF53', borderRadius: 50, padding: '20px 10px', margin: '20px 0 40px', fontWeight: 500, textAlign: 'center', fontSize: '1.1rem'}}>{GuideText[state.title]}</span>
                        }

                        {
                          state.title != "컴퓨터지원" &&
                          <>
                              <p style={{fontWeight: 700, margin: 0}}>제출 서류</p>
                              <p style={{whiteSpace: 'pre-wrap', wordBreak: "break-word", lineHeight: 1.5}}>
                                  {state.selectedApplyMethod.document || "공고문을 참고해주세요"}
                              </p>
                          </>
                        }

                        {
                          state.selectedApplyMethod.original_document && state.title != "컴퓨터지원" &&
                          <div style={{background: '#F4F4F4', padding: 16, marginBottom: 20}}>
                              <p style={{whiteSpace: 'pre-wrap', color: '#474D5B', margin: 0, lineHeight: 1.5, marginBottom: 20}}>{"[필수] 자사 이력서 양식 사용\n공고 주소를 복사하여 컴퓨터에서 열고 양식을 다운로드 해주세요"}</p>
                              <BorderButton
                                text={"공고 주소 복사하기"}
                                onClick={async () => {
                                    amplitude.track(EVENT_COPY_URL_LINK_CLICK, {
                                        "activity_id": state.id,
                                        "user_regions": regions,
                                        location: "OriginalDocument",
                                    })
                                    await copyText(getTextUrl(), "공고 주소를 복사했습니다.")
                                }}
                              />
                          </div>
                        }
                    </div>

                    {
                      (state.title != "컴퓨터지원") && <div style={{height: '10px'}}></div>
                    }

                    {
                      state.title == "전화지원" && state.selectedApplyMethod.tel &&
                      <div style={{margin: "0px 5%"}}>
                          <p style={{fontWeight: 700}}>채용담당자 전화번호</p>
                          <p style={{whiteSpace: 'pre-wrap', lineHeight: 1.5}}>
                              {state.selectedApplyMethod.tel.replace(',', ' 또는 \n') + (state.selectedApplyMethod.manager_name ? "\n" + `(${state.selectedApplyMethod.manager_name})` : "")}
                          </p>
                          <FillButton
                            text={'전화하기'}
                            onClick={() => {
                                submitCheckApplyMethod()
                                amplitude.track(EVENT_CALL_CLICK, {
                                    location: "CallApplyPage",
                                    "activity_id": state.id
                                })
                                if (state.selectedApplyMethod.tel.split(',').length > 1) {
                                    navigate("tel", {state: {...state, title: "채용담당자 전화번호", number: state.selectedApplyMethod.tel}})
                                } else {
                                    openCall(state.selectedApplyMethod.tel)
                                }
                            }}
                            style={{margin: '20px 0'}}
                          />
                      </div>
                    }

                    {
                      state.title == "이메일지원" && state.selectedApplyMethod.email &&
                      <div style={{margin: "0px 5%"}}>
                          <p style={{fontWeight: 700}}>이메일 주소</p>
                          <p style={{}}>
                              {state.selectedApplyMethod.email}
                          </p>
                          <FillButton
                            text={'이메일 주소 복사하기'}
                            onClick={() => {
                                submitCheckApplyMethod()
                                amplitude.track(EVENT_COPY_EMAIL_CLICK, {
                                    "activity_id": state.id,
                                    "user_regions": regions,
                                })
                                copyText(state.selectedApplyMethod.email, "이메일 주소를 복사했습니다")
                            }}
                            style={{margin: '20px 0'}}
                          />
                      </div>
                    }

                    {
                      state.title == "방문" && state.selectedApplyMethod.address &&
                      <div style={{margin: "0px 5%"}}>
                          <p style={{fontWeight: 700}}>방문 주소</p>
                          <p style={{lineHeight: 1.5}}>
                              {state.selectedApplyMethod.address}
                          </p>
                      </div>
                    }

                    {
                      state.title == "팩스지원" && state.selectedApplyMethod.fax &&
                      <div style={{margin: "0px 5%"}}>
                          <p style={{fontWeight: 700}}>팩스 번호</p>
                          <p style={{}}>
                              {state.selectedApplyMethod.fax}
                          </p>
                          <FillButton
                            text={'팩스 번호 복사하기'}
                            onClick={() => {
                                submitCheckApplyMethod()
                                amplitude.track(EVENT_COPY_FAX_CLICK, {
                                    "activity_id": state.id,
                                    "user_regions": regions,
                                })
                                copyText(state.selectedApplyMethod.fax, "팩스 번호를 복사했습니다")
                            }}
                            style={{margin: '20px 0'}}
                          />
                      </div>
                    }

                    {
                      state.title == "방문" && state.selectedApplyMethod.tel &&
                      <div style={{background: '#F4F4F4', padding: 16, margin: "0px 5% 20px"}}>
                          <p style={{whiteSpace: 'pre-wrap', color: "#474D5B", margin: 0, lineHeight: 1.5}}>{"방문 전 채용담당자에게 전화하여\n제출 서류와 주소를 꼭 재확인하세요"}</p>
                          <p style={{color: "#474D5B", margin: '20px 0 0', whiteSpace: 'pre-wrap', lineHeight: 1.5}}>{state.selectedApplyMethod.tel.replace(',', ' 또는 \n')}</p>
                          {state.selectedApplyMethod.manager_name && <span style={{color: "#474D5B",}}>({state.selectedApplyMethod.manager_name})</span>}
                          <div style={{height: '20px'}}></div>
                          <FillButton
                            text={"전화하기"}
                            onClick={() => {
                                submitCheckApplyMethod()
                                amplitude.track(EVENT_CALL_CLICK, {
                                    location: "VisitApplyPage",
                                    "activity_id": state.id
                                })
                                if (state.selectedApplyMethod.tel.split(',').length > 1) {
                                    navigate("tel", {state: {...state, title: "채용담당자 전화번호", number: state.selectedApplyMethod.tel}})
                                } else {
                                    openCall(state.selectedApplyMethod.tel)
                                }
                            }}
                          />
                      </div>
                    }

                    {
                      state.title == "컴퓨터지원" &&
                      <div style={{margin: "5%"}}>
                          <p style={{fontWeight: 700}}>위즈덤에서 문서 받기</p>
                          <p style={{whiteSpace: 'pre-wrap', lineHeight: 1.5}}>{"지원방법과 서류양식을 위즈덤이 보내드릴게요. 한 가지 방법을 선택하여 받으세요."}</p>
                          <IconFillButtonCenter
                            icon={<span style={{color: '#0ABF53', background: 'white', padding: '5px 7px', fontSize: '0.875rem', borderRadius: 5, fontWeight: 500}}>추천</span>}
                            text={"이메일로 받기"}
                            style={{justifyContent: 'start',}}
                            fontStyle={{fontSize: '1rem', flex: 1}}
                            onClick={goInputEmailPage}
                          />
                          <FillButton
                            text={"카카오톡으로 받기"}
                            onClick={goRequestGuideKakao}
                            style={{margin: '16px 0', background: '#FEE500'}}
                            fontStyle={{color: 'black', fontSize: '1rem'}}
                          />

                          <p style={{fontWeight: 700, marginTop: '30px'}}>공고사이트에서 문서 받기</p>
                          <p style={{whiteSpace: 'pre-wrap', lineHeight: 1.5}}>{"이메일과 카카오톡이 없으시다면\n공고사이트에서 직접 문서를 받으세요."}</p>
                          <FillButton
                            text={"공고 주소 복사하기"}
                            fontStyle={{color: 'white', fontSize: '1rem'}}
                            onClick={() => {
                                submitCheckApplyMethod()
                                amplitude.track(EVENT_COPY_URL_LINK_CLICK, {
                                    "activity_id": state.id,
                                    "user_regions": regions,
                                    location: "ComputerApplyPage",
                                })
                                copyText(getTextUrl(), "공고 주소를 복사했습니다")
                            }}
                            style={{background: '#474D5B'}}
                          />

                      </div>
                    }
                    <FaqFloatingButton activityId={state.id} />
                </div>

            }
        />
    )
}

export default OtherApplyView;