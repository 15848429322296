import { grey666, grey999, greyF4, primary, secondaryBlack } from "@src/components/Color";
import { DefaultTabTemplate } from "@src/components/PageTemplate";
import { BoldText_20, BoldText_25, MediumText_20, MediumText_25, RegularText_15, RegularText_17, RegularText_20 } from "@src/components/text";
import { useVirtualizer, VirtualItem } from "@tanstack/react-virtual";
import { useEffect, useRef, useState } from "react";
import Post from "../model/Post";
import {PostItem} from "./component/PostItem";
import MyComment from "../model/MyComment";
import CommunityService from "../infra/CommunityService";
import { RootState } from "@src/reducer/store";
import { useSelector } from "react-redux";
import { useBlocker, useNavigate } from "react-router-dom";
import { FillButton } from "@src/components/Buttons";
import { convertToKSTString } from "@src/util/convertDatetime";
import { EVENT_CLICK_EDIT_NICKNAME, EVENT_CLICK_POST_ITEM, EVENT_COMMUNITY_PROFILE_PAGE_SHOW } from "@src/components/AppConstant";
import trackEvent from "@src/util/trackEvent";
import useOnLog from "@src/components/useOnLog";
import { CommunityProfile } from "../model/CommunityProfile";
import { getLevelFromPoint, getLevelValueFromPoint, getMemberTitleFromPoint, getNextLevelTitle } from "../model/MemberLevel";
import IcArrowRight from "@assets/ic_arrow_right.svg";
import MemberLevelTag from "./component/MemberLevelTag";

const ProfilePage = () => {
    const parentRef = useRef<HTMLDivElement>(null)
    const uuid = useSelector((state: RootState) => state.user.uuid)!
    const nickname = useSelector((state: RootState) => state.user.nickname)
    const [tab, setTab] = useState(0)
    const [posts, setPosts] = useState<Post[] | null>(null)
    const [comments, setComment] = useState<MyComment[]>([])
    const [profile, setProfile] = useState<CommunityProfile | null>(null)
    const navigate = useNavigate();
    const postVirtualizer = useVirtualizer({
        count: posts?.length ?? 0,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 10,
        overscan: 10
    })
    const postItems: VirtualItem[] = postVirtualizer.getVirtualItems()
    const [showDeleteNotifyModal, setShowDeleteNotifyModal] = useState(false)

    const commentVirtualizer = useVirtualizer({
        count: comments.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 100,
        overscan: 10
    })
    const commentItems: VirtualItem[] = commentVirtualizer.getVirtualItems()

    const progress = ((profile?.wizdom_value ?? 0) - getLevelFromPoint(profile?.wizdom_value ?? 0).range[0]) * 100 / getLevelFromPoint(profile?.wizdom_value ?? 10).range[1] 

    useOnLog({
        pageNameKey: EVENT_COMMUNITY_PROFILE_PAGE_SHOW,
        regStr: "^\/community/profile$"
    })

    useEffect(() => {
        Promise.all([
            CommunityService.getPostsByUserId({user_id: uuid}),
            CommunityService.getCommentsByUserId({user_id: uuid}),
            CommunityService.getProfile({userId: uuid})    
        ]).then(([posts, comments, profile]) => {
            setPosts(posts)
            setComment(comments)
            setProfile(profile)
        })
    }, [])

    const MyCommentItem = ({comment}: {comment: MyComment}) => {
        const navigate = useNavigate()
        
        const onClick = () => {
            trackEvent(EVENT_CLICK_POST_ITEM, { 
                'post_id': comment.post_id ,
                'source': 'profile/comment'
            })
    
            if (comment.post_deleted_at != null) {
                setShowDeleteNotifyModal(true)
                return;
            }
            
            navigate(`post/${comment.post_id}`);
        }
    
        return (
            <div style={{display: 'flex', flexDirection: 'column', gap: '8px', padding: '20px 0px', margin: '0px 15px', borderBottom: `1px solid ${greyF4}` }} onClick={onClick}>
                <RegularText_20 style={{lineHeight: '1.5', display: '-webkit-box', whiteSpace: 'pre-wrap', wordBreak: 'break-all', overflow: 'hidden', overflowWrap: 'break-word', textOverflow: 'ellipsis', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}}>{comment.content}</RegularText_20>
                <RegularText_17 style={{color: grey999}}>{convertToKSTString(comment.created_at)}</RegularText_17>
                <RegularText_17 style={{ display: 'inline-block', color: '#474D5B', overflow: 'hidden', textOverflow: 'ellipsis', flexBasis: '100%', whiteSpace: 'nowrap', lineHeight: '1' }}>{comment.post_title}</RegularText_17>
            </div>
        )
    }

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => {
            if (blocker.state == 'blocked') {
                return false
            }

            if (showDeleteNotifyModal) {
                setShowDeleteNotifyModal(false)
                return true
            }

            return false
        }
    );

    const onDeletePost = (postId: number) => {
        setPosts(posts?.filter(post => post.id !== postId) ?? [])
    }

    const onAddPost = () => {
        CommunityService.getPostsByUserId({user_id: uuid}).then((posts) => {
            setPosts(posts)
        })
    }
    

    return (
        <DefaultTabTemplate
            outletContext={{onDeletePost: onDeletePost, onAddPost: onAddPost}}
            top={
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '75px'}}>
                    <MediumText_25>커뮤니티 프로필</MediumText_25>
                </div>
            }
            middle={
                <div 
                    ref={parentRef}
                    style={{
                        height: '100%',
                        width: '100%',
                        overflowY: 'auto',
                        contain: 'strict',
                        scrollbarWidth: 'none',
                    }}>
                    <div style={{display: 'flex', paddingTop: '40px', paddingLeft: '20px', gap: '12px'}}>
                        {profile && <MemberLevelTag size="60px" fontSize="1.5rem" borderRadius="5px" level={getLevelValueFromPoint(profile?.wizdom_value)} />}
                        <div style={{display: 'flex', flexDirection: 'column', gap: '9px'}}>
                            <BoldText_25>{nickname ?? ''}</BoldText_25>
                            {profile && <RegularText_17 style={{color: secondaryBlack}}>{getMemberTitleFromPoint(profile.wizdom_value)}</RegularText_17>}
                        </div>
                        
                        <div style={{display: 'inline-flex', textAlign: 'center', padding: '0px 12px', border: `solid 1px ${grey999}`, borderRadius: '5px', justifyContent: 'center', alignItems: 'center', height: '30px'}}
                            onClick={() => {
                                trackEvent(EVENT_CLICK_EDIT_NICKNAME, { source: 'profile' })
                                navigate('edit-nickname', { state: { autoGenerated: false, source: 'profile' } })
                            }}
                        >
                            <RegularText_15 style={{color: secondaryBlack}}>
                                별명 변경
                            </RegularText_15>
                        </div>
                    </div>
                    <div id="post_comment_like" style={{display: 'flex', backgroundColor: greyF4, borderRadius: '5px', padding: '20px 0px', margin: '30px 20px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '4px', flex: 1, borderRight: '1.2px solid #D7D8DA'}}>
                            <BoldText_20 style={{color: secondaryBlack}}>{profile?.post_count}</BoldText_20>
                            <RegularText_15 style={{color: secondaryBlack}}>게시글</RegularText_15>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '4px', flex: 1, borderRight: '1.2px solid #D7D8DA'}}>
                            <BoldText_20 style={{color: secondaryBlack}}>{profile?.comment_count}</BoldText_20>
                            <RegularText_15 style={{color: secondaryBlack}}>댓글</RegularText_15>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '4px', flex: 1}}>
                            <BoldText_20 style={{color: secondaryBlack}}>{profile?.give_like_count}</BoldText_20>
                            <RegularText_15 style={{color: secondaryBlack}}>좋아요</RegularText_15>
                        </div>
                    </div>
                    <div id="wizdom_value" style={{display: 'flex', flexDirection: 'column', borderRadius: '5px', padding: '30px 25px', border: '1px solid #D7D8DA', margin: '0px 20px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <BoldText_20>지혜도</BoldText_20>
                            <BoldText_25>{profile?.wizdom_value}</BoldText_25>
                        </div>
                        <div style={{height: '20px', backgroundColor: greyF4, borderRadius: '10px', marginTop: '30px'}}>
                            <div style={{borderRadius: '10px', width: progress != 0 ? progress + '%' : '10%', height: '20px', backgroundColor: primary}}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', color: secondaryBlack, marginTop: '8px'}}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                                <RegularText_17>{getLevelFromPoint(profile?.wizdom_value ?? 0).range[0]}</RegularText_17>
                                <RegularText_15>({getLevelFromPoint(profile?.wizdom_value ?? 0).name})</RegularText_15>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end', gap: '4px'}}>
                                <RegularText_17>{getLevelFromPoint(profile?.wizdom_value ?? 0).range[1]}</RegularText_17>
                                <RegularText_15>({getNextLevelTitle(profile?.wizdom_value ?? 0)})</RegularText_15>
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: greyF4, padding: '15px 0px', gap: '10px', marginTop: '30px', borderRadius: '5px'}}
                            onClick={() => navigate('member-guide')}
                        >
                            <RegularText_20 style={{color: secondaryBlack}}>지혜도 얻는 법 보기</RegularText_20>
                            <IcArrowRight color={secondaryBlack} height='15px'/>
                        </div>
                    </div>
                    <div style={{marginTop: '40px', display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                        <div style={{width: '100%', height: '40px', textAlign: 'center', borderBottom: tab === 0 ? `solid 3px ${primary}` : 'solid 1px #D7D8DA'}} onClick={() => setTab(0)}>
                            <MediumText_20 style={{color: tab === 0 ? primary : grey666}}>내가 쓴 글</MediumText_20>
                        </div>
                        <div style={{width: '100%', height: '40px', textAlign: 'center', borderBottom: tab === 1 ? `solid 3px ${primary}` : 'solid 1px #D7D8DA'}} onClick={() => setTab(1)}>
                            <MediumText_20 style={{color: tab === 1 ? primary : grey666}}>내가 쓴 댓글</MediumText_20>
                        </div>
                    </div >
                    {
                        tab === 0 
                        ?   posts ? <div>
                                {
                                postItems.length > 0 
                                    ? postItems.map((item) => <PostItem idx={item.index} post={posts[item.index]} key={posts[item.index].id} newMarkEnabeld={false} dateFormat="string" nicknameEnabled={false} totalLength={posts.length} source="profile/post"/>)
                                    : 
                                    <div style={{position: 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50%', width: '100%', textAlign: 'center', gap: '16px'}}>
                                        작성한 글이 없습니다.
                                        {<FillButton text="글쓰기" onClick={() => navigate('../post/write')} style={{width: '200px'}}/>}
                                    </div>
                                }
                            </div> : null
                        :   <div>
                                {
                                    commentItems.length > 0 
                                    ? commentItems.map((item) => <MyCommentItem key={comments[item.index].id} comment={comments[item.index]}/>)
                                    : <div style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%', width: '100%', textAlign: 'center'}}>작성한 댓글이 없습니다.</div>
                                }
                            </div>
                    }
                </div> 
            }
            modal={
                showDeleteNotifyModal && <div
                style={{ 
                    display: 'flex',
                    position: 'absolute',
                    height: '100vh',
                    width: '100vw',
                    background: '#000000B3',
                    zIndex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: 650
                }}
                onClick={() => setShowDeleteNotifyModal(false)}
            >
                <div
                    onClick={(e) => e.stopPropagation()}
                    style={{ display: 'flex', flexDirection: 'column', width: '80%', background: 'white', borderRadius: '10px', padding: '16px 16px', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{display: 'block', textAlign: 'center', fontWeight: 500, lineHeight: '1.5'}}>글이 삭제되었습니다.</p>
                    <div style={{display: 'flex', gap: '10px', width: '100%', justifyContent: 'center'}}>
                        <FillButton 
                            text='확인'
                            onClick={() => setShowDeleteNotifyModal(false)}
                            fontStyle={{
                                fontSize: '1rem'
                            }}
                            style={{
                                width: '40%'
                            }}
                        />
                    </div>
                </div>
            </div>
    
            }
        />
    )
};
export default ProfilePage;
