import {useDispatch} from "react-redux";
import {EVENT_SHARE_BUTTON_CLICK} from "@components/AppConstant";
import {shareViaKakao} from "@util/kakao";
import {BottomDrawerTemplate} from "@components/DrawerTemplate";
import {IconFillButton} from "@components/Buttons";
import icKakao from "@assets/ic_kakao.webp";
import icShareWhite from "@assets/ic_share_white.webp";
import {show} from "@reducer/snackbarSlice";
import trackEvent from "@util/trackEvent";

interface ShareBottomSheetProps {
  kakao_share_title: string
  link_copy_title: string
  amplitude_props: object,
  templateId: number,
  templateArgs: {[key: string]: string} ,
  toastText: string,
  url: string
}

export const ShareBottomSheet = ({showModal, onClose, title, content, props}:{ showModal:boolean, onClose: () => void, title: string, content: string, props: ShareBottomSheetProps}) => {
  const dispatch = useDispatch();

  function shareKakao() {
    trackEvent(EVENT_SHARE_BUTTON_CLICK, {
      source: 'kakao',
      ...props.amplitude_props
    })
    shareViaKakao(
      props.templateId,
      props.templateArgs
    );
  }

  async function copyLink() {
    trackEvent(EVENT_SHARE_BUTTON_CLICK, {
      source: 'direct_link',
      ...props.amplitude_props,
    })
    if (props.url) {
      if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
        const appVersion = await window.flutter_inappwebview.callHandler('appVersion')
        if (appVersion == null || appVersion <= 9) {
          window.flutter_inappwebview.callHandler('copyText', props.url);
        } else {
          window.flutter_inappwebview.callHandler('copyText', [props.url, props.toastText]);
        }
      } else if (window && window.navigator && window.navigator.clipboard) {
        window.navigator.clipboard.writeText(props.url);
        dispatch(show({ message: "내용이 복사되었습니다. 붙여넣기 하여 공유해주세요." }))
      }
    }
  }

  return (
    <BottomDrawerTemplate title={title} show={showModal} onClose={onClose} >
      <p style={{lineHeight: 1.5, fontSize: '0.875rem'}}>{content}</p>
      <IconFillButton
        icon={<img src={icKakao} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '8px' }} />}
        style={{ background: '#FEE500', justifyContent: 'start', marginBottom: 15 }}
        fontStyle={{ color: 'black' }}
        text={props.kakao_share_title}
        onClick={shareKakao}
      />
      <IconFillButton
        icon={<img src={icShareWhite} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '8px' }} />}
        style={{ background: '#474D5B', justifyContent: 'start', marginBottom: 15 }}
        text={props.link_copy_title}
        onClick={copyLink}
      />
    </BottomDrawerTemplate>
  );
}