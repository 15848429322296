import { createSlice } from "@reduxjs/toolkit"

interface ScrollState {
    scroll: { [key: string]: {key: string, value: number} }
}

const scrollSlice = createSlice({
    name: 'scroll',
    initialState: {
        scroll: {}
    } as ScrollState,
    reducers: {
        cleanScrolls: (state) => {
            state.scroll = {}
        },
        saveScroll: (state, action) => {
            state.scroll[action.payload.key] = action.payload.value;
        },
        resetScroll: (state, action) => {
            delete state.scroll[action.payload.key]
        },
    }
})

export const { cleanScrolls, saveScroll, resetScroll } = scrollSlice.actions
export default scrollSlice.reducer

