import BuildingPropertyTaxCalculatorProps from "@src/features/tax_saving/features/property_tax/model/BuildingPropertyTaxCalculatorProps";
import HousePropertyTaxCalculatorProps from "@src/features/tax_saving/features/property_tax/model/HousePropertyTaxCalculatorProps";
import LandPropertyTaxCalculatorProps from "@src/features/tax_saving/features/property_tax/model/LandPropertyTaxCalculatorProps";

export const calculateHousePropertyTax = (props: HousePropertyTaxCalculatorProps) => {
  // console.log('시가표준액: ', props.standardValue)

  // 공정시장가액비율
  const fairMarketValueType =
  props.standardValue <= 300000000 ? "3억원 이하"
      : props.standardValue <= 600000000 ? "6억원 이하"
        : "6억원 초과"
  // console.log('공정시장가액비율: ', fairMarketValueType)

  // 공정시장가액비율
  const fairMarketValue = props.isSingleHousehold ?
    props.standardValue <= 300000000 ? 0.43
      : props.standardValue <= 600000000 ? 0.44
        : 0.45
    : 0.6
  // console.log('공정시장가액비율: ', fairMarketValue)

  // 과세표준
  const taxableStandard = props.standardValue * fairMarketValue
  // console.log('과세표준: ', taxableStandard)

  // 재산세
  const propertyTaxRatioType =
    taxableStandard <= 60000000 ? "6천만원 이하 세율"
      : taxableStandard <= 150000000 ? "1억 5천만원 이하 세율"
        : taxableStandard <= 300000000 ? "3억원 이하 세율"
          : "3억원 초과 세율"
  // console.log("재산세율타입: ", propertyTaxRatioType)

  // 재산세
  const propertyTax =
    taxableStandard <= 60000000 ? taxableStandard * 0.1 * 0.01
      : taxableStandard <= 150000000 ? 60000 + (taxableStandard - 60000000) * 0.15 * 0.01
        : taxableStandard <= 300000000 ? 195000 + (taxableStandard - 150000000) * 0.25 * 0.01
          : 570000 + (taxableStandard - 300000000) * 0.4 * 0.01
  // console.log("재산세: ", propertyTax)

  // 도시지역분
  const cityArea = Math.floor(taxableStandard * 0.14 * 0.01)
  // console.log("도시지역분: ", cityArea)

  // console.log('지역자원시설세시가표준액: ', props.localResourceFacilityTaxStandard)

  // 지역자원시설세과세표준
  const localResourceFacilityTaxableStandard = props.localResourceFacilityTaxStandard * fairMarketValue
  // console.log("지역자원시설세과세표준: ", localResourceFacilityTaxableStandard)

  // 지역자원시설세
  const localResourceFacilityTax = 5000
  // const localResourceFacilityTax =
  //   props.localResourceFacilityTaxStandard <= 6000000 ? localResourceFacilityTaxableStandard * 0.04 * 0.01
  //     : props.localResourceFacilityTaxStandard <= 13000000 ? 2400 + (localResourceFacilityTaxableStandard - 6000000) * 0.05 * 0.01
  //       : props.localResourceFacilityTaxStandard <= 26000000 ? 5900 + (localResourceFacilityTaxableStandard - 13000000) * 0.06 * 0.01
  //         : props.localResourceFacilityTaxStandard <= 39000000 ? 13700 + (localResourceFacilityTaxableStandard - 26000000) * 0.08 * 0.01
  //           : props.localResourceFacilityTaxStandard <= 64000000 ? 24100 + (localResourceFacilityTaxableStandard - 39000000) * 0.1 * 0.01
  //             : 49100 + (localResourceFacilityTaxableStandard - 64000000) * 0.12 * 0.01
  // console.log("지역자원시설세: ", localResourceFacilityTax)

  // 지방교육세
  const localEducationTax = propertyTax * 20 * 0.01
  // console.log("지방교육세: ", localEducationTax)

  const total = propertyTax + cityArea + localResourceFacilityTax + localEducationTax
  // console.log("total: ", total)

  return {
    propertyTax: propertyTax,
    standardValue: props.standardValue,
    fairMarketValue: fairMarketValue,
    singleHousehold: props.isSingleHousehold,
    fairMarketValueType: fairMarketValueType,
    cityArea: cityArea,
    localResourceFacilityTax: localResourceFacilityTax,
    propertyTaxRatioType: propertyTaxRatioType,
    localEducationTax: localEducationTax,
    total: total,
  }
}

// export const calculateBuildingPropertyTax = (props: BuildingPropertyTaxCalculatorProps) => {
//   console.log("과세대상구분: ", props.target)
//   console.log('시가표준액: ', props.standardValue)
//
//   // 공정시장가액비율
//   const fairMarketValue = props.fairMarketValue || 0.7
//   console.log('공정시장가액비율: ', fairMarketValue)
//
//   // 과세표준
//   const taxableStandard = props.standardValue * fairMarketValue
//   console.log('과세표준: ', taxableStandard)
//
//   // 재산세
//   const propertyTax =
//     props.target === "골프장, 고급오락장" ? taxableStandard * 4 * 0.01
//       : props.target === "주거지역 및 지정지역 내 공장용 건축물" ? taxableStandard * 0.5 * 0.01
//         : taxableStandard * 0.25 * 0.01
//   console.log("재산세: ", propertyTax)
//
//   // 도시지역분
//   const cityArea = taxableStandard * 0.14 * 0.01
//   console.log("도시지역분: ", cityArea)
//
//   // 지역자원시설세과세표준
//   const localResourceFacilityTaxableStandard = taxableStandard
//   console.log("지역자원시설세과세표준: ", localResourceFacilityTaxableStandard)
//
//   // 지역자원시설세
//   const localResourceFacilityTax =
//     localResourceFacilityTaxableStandard <= 6000000 ? localResourceFacilityTaxableStandard * 0.04 * 0.01
//       : localResourceFacilityTaxableStandard <= 13000000 ? 2400 + (localResourceFacilityTaxableStandard - 6000000) * 0.05 * 0.01
//         : localResourceFacilityTaxableStandard <= 26000000 ? 5900 + (localResourceFacilityTaxableStandard - 13000000) * 0.06 * 0.01
//           : localResourceFacilityTaxableStandard <= 39000000 ? 13700 + (localResourceFacilityTaxableStandard - 26000000) * 0.08 * 0.01
//             : localResourceFacilityTaxableStandard <= 64000000 ? 24100 + (localResourceFacilityTaxableStandard - 39000000) * 0.1 * 0.01
//               : 49100 + (localResourceFacilityTaxableStandard - 64000000) * 0.12 * 0.01
//   console.log("지역자원시설세: ", localResourceFacilityTax)
//
//   // 지방교육세
//   const localEducationTax = propertyTax * 20 * 0.01
//   console.log("지방교육세: ", localEducationTax)
//
//   const total = propertyTax + cityArea + localResourceFacilityTax + localEducationTax
//   console.log("total: ", total)
//
//   return total
// }
//
// export const calculateLandPropertyTax = (props: LandPropertyTaxCalculatorProps) => {
//   console.log("과세대상구분: ", props.target)
//   console.log('시가표준액: ', props.standardValue)
//
//   // 공정시장가액비율
//   const fairMarketValue = props.fairMarketValue || 0.7
//   console.log('공정시장가액비율: ', fairMarketValue)
//
//   // 과세표준
//   const taxableStandard = props.standardValue * fairMarketValue
//   console.log('과세표준: ', taxableStandard)
//
//   // 재산세
//   const propertyTax =
//     props.target === "나대지등 (종합합산과세)" ?
//       taxableStandard <= 50000000 ? taxableStandard * 0.2 * 0.01
//         : taxableStandard <= 100000000 ? 100000 + (taxableStandard - 50000000) * 0.3 * 0.01
//           : 250000 + (taxableStandard - 100000000) * 0.5 * 0.01
//       : taxableStandard * 0.4 * 0.01
//     props.target === "사업용토지(별도합산과세)" ?
//       taxableStandard <= 200000000 ? taxableStandard * 0.2 * 0.01
//         : taxableStandard <= 1000000000 ? 40000000 + (taxableStandard - 200000000) * 0.3 * 0.01
//           : 2800000 + (taxableStandard - 1000000000) * 0.4 * 0.01
//       : 0
//   console.log("재산세: ", propertyTax)
//
//   // 도시지역분
//   const cityArea = taxableStandard * 0.14 * 0.01
//   console.log("도시지역분: ", cityArea)
//
//   // 지방교육세
//   const localEducationTax = propertyTax * 20 * 0.01
//   console.log("지방교육세: ", localEducationTax)
//
//   const total = propertyTax + cityArea + localEducationTax
//   console.log("total: ", total)
//
//   return total
// }