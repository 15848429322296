import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Description, DropDown, Indicator, TextField, VSelector, YMInput} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";
import React, {useMemo, useState} from "react";
import {useBlocker, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {setEducation} from "@reducer/userSlice";
import uuidv4 from "@util/uuidv4";

const EducationBachelor = () => {
  const degree = "bachelor";
  const state = useLocation().state
  const step = useMemo(() => state?.step || 0, [null])
  const dispatch = useDispatch();
  const education = useSelector((state: RootState) => state.user.resume.education).find((e) => e.degree === degree)
  const isEdit = !!education?.name || false;

  const navigate = useNavigate();
  const [name, setName] = useState(education?.name || "")
  const [major, setMajor] = useState(education?.major || "")
  const [status, setStatus] = useState(education?.status || "졸업")
  const [startDate, setStartDate] = useState(education?.startDate || "")
  const [endDate, setEndDate] = useState(education?.endDate || "")
  const [showStatusModal, setShowStatusModal] = useState(false)
  const _ = useBlocker(({ currentLocation, nextLocation, historyAction }) => {
    if (showStatusModal) {
      setShowStatusModal(false)
      return true
    }
    return false
  });

  const next = () => {
    dispatch(setEducation({
      uuid: uuidv4(),
      name: name,
      major: major,
      status: status,
      startDate: startDate,
      endDate: status === "재학" ? "" : endDate,
      degree: degree
    }))
    navigate("../create_resume_check_career", {state: {step: step+1}});
  }

  const available = () => name !== "" && major !== "" && status !== "" && startDate !== "" && (status !== "재학" && endDate !== "" && endDate >= startDate || status === "재학")

  return <DefaultPageRoot
    top={<BackNavToolbar title={""}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <Description text={"대학교 정보"}/>
      <TextField label={"학교명"} placeholder={"예) OO대학교"} value={name} onChange={setName} highlight={isEdit && name === ""}/>
      <TextField label={"전공"} placeholder={"예) OO과"} value={major} onChange={setMajor} highlight={isEdit && major === ""}/>
      <DropDown label={"졸업여부"} onChange={setStatus} value={status} options={["졸업", "재학", "수료", "중퇴"]} showModal={showStatusModal} setShowModal={setShowStatusModal}/>
      <YMInput label={"입학일"} placeHolder={"198403"} init={startDate} onChange={setStartDate} highlight={isEdit && startDate === ""}/>
      {["졸업", "수료", "중퇴"].includes(status) && <YMInput label={status + "일"} placeHolder={"198712"} init={endDate} onChange={setEndDate} highlight={isEdit && endDate === ""}/>}

    </div>}
    bottom={<div style={{padding: 16}}>
      <FillButton
        active={available()}
        text={"다음"}
        onClick={next}
      />
    </div>}
  />
}

export default EducationBachelor;