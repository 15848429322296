import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Description, DropDown, Indicator, TextField, VSelector, YMInput} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";
import React, {useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {clearCertificate, setLastSelectedCertificateOption, setNoCertificate} from "@reducer/userSlice";
import uuidv4 from "@util/uuidv4";
import useOnLog from "@components/useOnLog";
import {EVENT_CREATE_RESUME_CHECK_CAREER_PAGE_SHOW, EVENT_CREATE_RESUME_CHECK_CERTIFICATE_PAGE_SHOW} from "@components/AppConstant";

const navigationMap: { [key: string]: string } = {
  "yes": 'create_resume_certificate',
  "no": 'create_resume_introduction',
}

const options = [
  {key: "yes", label: "자격증 있어요"},
  {key: "no", label: "자격증이 하나도 없어요"},
]

const CheckCertificate = () => {
  const dispatch = useDispatch();
  const state = useLocation().state
  const step = useMemo(() => state?.step || 0, [null])
  const navigate = useNavigate()
  const lastSelectedCertificateOption = useSelector((state: RootState) => state.user.resume.lastSelectedCertificateOption)

  const next = () => {
    if (lastSelectedCertificateOption === "no") {
      dispatch(setNoCertificate({
        uuid: uuidv4(),
        name: "",
        publisher: "",
        acquired_at: "",
        no_certificate: true,
      }))
    } else {
      dispatch(clearCertificate())
    }
    navigate('../' + navigationMap[lastSelectedCertificateOption], {state: {step: step+1}})
  }

  const available = () => !!lastSelectedCertificateOption

  useOnLog({
    pageNameKey: EVENT_CREATE_RESUME_CHECK_CERTIFICATE_PAGE_SHOW,
    regStr: "\/create_resume_check_certificate$"
  })

  return <DefaultPageRoot
    top={<BackNavToolbar title={""}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <Indicator label={"자격증정보"} max={6} index={4} />
      <Description text={"자격증이 있나요?"}/>
      <VSelector onChange={(option) => dispatch(setLastSelectedCertificateOption(option))} selected={lastSelectedCertificateOption} options={options}/>
    </div>}
    bottom={<div style={{padding: 16}}>
      <FillButton
        active={available()}
        text={"다음"}
        onClick={next}
      />
    </div>}
  />
}
export default CheckCertificate;