import { DefaultPageRoot } from "@src/components/PageTemplate";
import { HomeNavToolbar } from "@src/components/Toolbars";
import { useEffect, useRef, useState } from "react";
import { BoldText_20, BoldText_22, BoldText_25, RegularText_17, RegularText_20 } from "@src/components/text";
import {  grey999, greyF4, primary } from "@src/components/Color";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "@src/reducer/store";
import { useSelector } from "react-redux";
import useOnLog from "@src/components/useOnLog";
import { EVENT_DISCOUNT_NOTIFICATION_CTA_CLICK, EVENT_DISCOUNT_PAGE_SHOW } from "@src/components/AppConstant";
import trackEvent from "@src/util/trackEvent";
import { BorderButton, FillButton } from "@src/components/Buttons";
import icBell from '@assets/ic_bell.webp';
import { fetchWithRetry } from "@src/util/networks";
import server_domain from "@src/components/RemoteServer";
import { discountTopics } from "../model/discount_topic";
import { fontSize } from "@src/components/font";

const DiscountHomePage = () => {
    const navigate = useNavigate()
    const parentRef = useRef<HTMLDivElement>(null)
    const uuid = useSelector((state: RootState) => state.user.uuid)
    const [subscribed, setSubscribed] = useState<boolean | null>(null)
    const [subscribedCount, setSubscribedCount] = useState<number>(0)
    const location = useLocation()
    const subscribeResult = useLocation().state?.subscribed

    useOnLog({
        pageNameKey: EVENT_DISCOUNT_PAGE_SHOW,
        regStr: "^\/discount$"
    })

    useEffect(() => {
        fetchWithRetry(server_domain() + `/event/discount/subscribe/${uuid}?topic=${discountTopics.first}`)
            .then((response: Response) => {
                return response.json()
            })
            .then((data) => {
                const subscribed: boolean = data.subscribed
                const subscribedCount: number = data.subscribed_count
                setSubscribed(subscribed)
                setSubscribedCount(subscribedCount > 783 ? subscribedCount : (783 + subscribedCount))
            })
    }, [])

    useEffect(() => {
        if (subscribeResult) {
            setSubscribed(true)
            setSubscribedCount(subscribedCount + 1)
        }
    }, [location])

    const onClickNotification = () => {
        trackEvent(EVENT_DISCOUNT_NOTIFICATION_CTA_CLICK, undefined)
        navigate('confirm-notification')
    }

    return (
        <DefaultPageRoot
            top={<HomeNavToolbar location="discount" title="할인정보" />}
            middle={
                <div ref={parentRef} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    lineHeight: '1.5',
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '15px', marginTop: '50px'}}>
                        <BoldText_22>위즈덤 회원님들을 위한</BoldText_22>
                        <BoldText_22>할인정보를</BoldText_22>
                        <BoldText_22>준비하고 있습니다!</BoldText_22>
                    </div>
                    <div style={{margin: '15px', marginTop: '20px', padding: '20px', border: `solid 1px ${grey999}`, display: 'flex', flexDirection: 'column', borderRadius: '5px'}}>
                        <RegularText_20>제일 먼저 할인정보를 받으려면</RegularText_20>
                        <RegularText_20>오픈 알림을 신청하세요.</RegularText_20>
                        <FillButton text={subscribed == null ? "로딩 중" : subscribed ? "알림 신청 완료" : "오픈 알림 받기"} style={{marginTop: '20px'}} onClick={onClickNotification} active={subscribed == null ? false : subscribed ? false : true}></FillButton>
                        {
                            !!subscribedCount ?
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', gap: '8px'}}>
                                <img src={icBell} width={'18px'} height={'18px'}></img><RegularText_17>{`${subscribedCount}명 신청 중`}</RegularText_17>
                            </div>
                            : <div style={{height: '25px'}}></div>
                        }
                    </div>
                    <div style={{backgroundColor: greyF4, padding: '40px 15px', display: 'flex', marginTop: '40px', flexDirection: 'column', flexGrow: '1'}}>
                        <BoldText_20>혹시 사장님이세요?</BoldText_20>
                        <RegularText_20 style={{marginTop: '14px'}}>수많은 위즈덤 회원님들에게 특전을</RegularText_20>
                        <RegularText_20>제공하고, 무료로 홍보하세요</RegularText_20>
                        <BorderButton text="자세히 보기" onClick={() => navigate('for-ceo')} style={{marginTop: '20px', width: '150px', padding: '10px'}} fontStyle={{fontSize: fontSize.m}}></BorderButton>
                    </div>
                </div>
            }
        />
    );
};

export default DiscountHomePage;