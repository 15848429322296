import { FillButton } from "@src/components/Buttons";
import server_domain from "@src/components/RemoteServer";
import { fontSize } from "@src/components/font";
import { show } from "@src/reducer/snackbarSlice";
import { isForPartners } from "@src/util/isForPartners";
import { fetchWithRetry } from "@src/util/networks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import IcClose from "@assets/close.svg";
import trackEvent from "@src/util/trackEvent";
import { EVENT_DISCOUNT_PROMOTION_REQUEST_COMPLETE } from "@src/components/AppConstant";
import styles from "@src/features/job/features/apply/style/input.module.css";

const DiscountPromotePhoneNumberModal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [phone, setPhone] = useState('')
    
    const onCancel = () => {
        navigate(-1)
    }

    const onConfirm = async () => {
        if (!phone) {
            return
        }
        trackEvent(EVENT_DISCOUNT_PROMOTION_REQUEST_COMPLETE, undefined)
        await fetchWithRetry(server_domain() + '/event/discount/promote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                phone: phone,
            }),
        
        })
        navigate(-1)
        dispatch(show({message: '제출이 완료되었습니다.'}))
    }

    return (
        <div
            style={{ 
                display: 'flex',
                position: 'absolute',
                height: '100vh',
                width: '100vw',
                background: '#000000B3',
                zIndex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: isForPartners ? 800 : 650
            }}
            onClick={onCancel}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                style={{ display: 'flex', flexDirection: 'column', width: '80%', background: 'white', borderRadius: '10px', padding: '16px 16px', justifyContent: 'center', alignItems: 'center' }}
            >
                <IcClose style={{alignSelf: 'end', paddingTop: '10px'}} onClick={onCancel}></IcClose>
                <p style={{display: 'block', textAlign: 'center', fontWeight: 500, lineHeight: '1.5'}}>전화번호를 남겨주시면 1일 이내에<br></br> 담당자가 연락드릴 예정입니다.</p>
                <input 
                    className={styles.input}
                    value={phone}
                    type="tel"
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="예) 01012345678"
                    style={{fontSize: fontSize.m, marginTop: '8px', alignSelf: 'start', borderRadius: '5px', padding: '15px 20px', width: 'calc(100% - 40px)'}}
                />

                <div style={{display: 'flex', gap: '10px', width: '100%', marginTop: '30px'}}>
                    <FillButton 
                        text='제출하기'
                        onClick={onConfirm}
                        active={phone.length >= 11}
                        fontStyle={{
                            fontSize: '1rem'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default DiscountPromotePhoneNumberModal;