import React from "react";
import { useNavigate } from "react-router-dom";
import { DefaultPageRoot } from "@components/PageTemplate";
import { BackNavToolbar } from "@components/Toolbars";
import { useEffect, useState } from "react";
import server_domain from "@components/RemoteServer";
import { useSelector } from "react-redux";
import useOnLog from "@components/useOnLog";
import { EVENT_SURVEY_INTRO_PAGE_SHOW } from "@components/AppConstant";
import { FillButton } from "@components/Buttons";
import { fetchWithRetry } from "@util/networks";
import { RootState } from "@reducer/store";

const SurveyState = {
    CHECKING: "확인중",
    NOT_PARTICIPATED: "미참여",
    PARTICIPATED: "참여"
}

let survey: any = null

const SurveyIntroView = () => {
    const navigate = useNavigate();
    const uuid = useSelector((state: RootState) => state.user.uuid);

    const uuidHash = useSelector((state: RootState) => state.user.uuidHash);
    const answered = useSelector((state: RootState) => state.survey.answered);
    const [surveyState, setSurveyState] = useState(SurveyState.CHECKING);

    useEffect(() => {
        if (answered.includes("c181cb4f-c522-402c-86bb-b0bfe0e3987b")) {
            setSurveyState(SurveyState.PARTICIPATED);
        }
    }, [answered]);

    useEffect(() => {
        if (answered.includes("c181cb4f-c522-402c-86bb-b0bfe0e3987b")) {
            setSurveyState(SurveyState.PARTICIPATED);
            return
        }
        fetchWithRetry(server_domain() + "/check_survey", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                survey_id: "c181cb4f-c522-402c-86bb-b0bfe0e3987b",
                uid: uuid
            })
        })
            .then(response => response.json())
            .then(data => {
                if(data.result === "participated") {
                    setSurveyState(SurveyState.PARTICIPATED);
                } else {
                    setSurveyState(SurveyState.NOT_PARTICIPATED);
                    survey = data.survey;
                }
            })
    }, []);


    useOnLog({
        pageNameKey: EVENT_SURVEY_INTRO_PAGE_SHOW,
        amplitudeProp: {
            user_group: uuidHash,
            survey_id: "c181cb4f-c522-402c-86bb-b0bfe0e3987b"
        },
        regStr: "\/survey$"
    })

    const moveSurvey = () => {
        if (surveyState === SurveyState.NOT_PARTICIPATED) {
            navigate("c181cb4f-c522-402c-86bb-b0bfe0e3987b/1", { state: { survey: survey } })
        }
    }

    const buttonText = () => {
        if (surveyState === SurveyState.CHECKING) {
            return "확인중...";
        } else if (surveyState === SurveyState.NOT_PARTICIPATED) {
            return "전화 인터뷰 참여하기";
        } else if (surveyState === SurveyState.PARTICIPATED) {
            return "참여 신청 완료";
        }

        throw new Error("Invalid survey state");
    }

    const buttonStyle = () => {
        if (surveyState === SurveyState.NOT_PARTICIPATED) {
            return { };
            // return { width: "70%", margin: "50px auto 10px" };
        } else {
            return { background: '#D9D9D9' };
            // return { width: "70%", margin: "50px auto 10px", background: '#D9D9D9' };
        }
    }

    return (
        <DefaultPageRoot
            top={<BackNavToolbar />}
            middle={
                <div style={{ textAlign: 'center', lineHeight: 1.5, paddingBottom: 30 }}>
                    {/* <p style={{ fontSize: '1.25rem', fontWeight: 700, marginBottom: 40 }}>위즈덤 설문조사</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.125rem', fontWeight: 500 }}>{"여러분의 소중한\n의견을 들려주세요"}</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.125rem', fontWeight: 500 }}>{"참여해주신 10분께\n5만원 신세계 상품권을 드려요!"}</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '0.875rem', color: '#999' }}>{"당첨자는 추첨으로 결정되며\n3/19 오후 6시에 발표됩니다"}</p> */}

                    <p style={{ fontSize: '1.125rem', fontWeight: 700, marginBottom: '22.5px' }}>안녕하세요 고객님!</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.125rem', fontWeight: 700, marginBottom: '22.5px' }}>{"고객님은 위즈덤을 오랜기간\n사용해주신 '단골 고객님'이십니다!"}</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.125rem', fontWeight: 400 }}>{"위즈덤을 오랜 기간\n사용해주셔서 감사합니다."}</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.125rem', fontWeight: 400 }}>{"위즈덤이 더 좋은 서비스가 되고자\n단골 고객님들의 목소리에\n귀기울이려고 합니다."}</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.125rem', fontWeight: 400 }}>{"위즈덤 사용경험에 대해\n"}<span style={{ textDecoration: 'underline' }} >5 ~ 10분 가량 전화통화로</span>{"\n이야기 나눠주실 수 있을까요?"}</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.125rem', fontWeight: 400 }}>{"위즈덤에 대한 생각들을\n가감없이 하실수 있는 기회입니다!"}</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.125rem', fontWeight: 400 }}>{"도움을 주실 수 있다면\n'전화 인터뷰 참여하기'를 눌러주세요!"}</p>

                    {/* <p style={{ margin: 0, display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', fontSize: '1.125rem' }}><img src={IcClock} alt="" style={{ width: 20, marginRight: 10 }} />3분 소요</p> */}
                </div>
            }
            bottom={<div style={{ padding: 16 }}>
                <FillButton
                    text={buttonText()}
                    onClick={moveSurvey}
                    style={buttonStyle()}
                />
            </div>}
        />
    )
}

export default SurveyIntroView;