import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {KEY_ACTIVITY_CLICK_COUNT} from "@components/AppConstant";
import isSimpleApply from "@util/isSimpleApply";
import Badge from "@components/Badge";
import {increment} from "@reducer/eventSlice";
import { Activity } from '@model/Activity';
import ActivityCardTemplate from "@src/features/job/component/ActivityCardTemplate";

const ActivityCard = ({ activity, location, last, regions = null, sessionId }: {
    activity: Activity,
    location: string,
    last?: boolean,
    regions: string[] | null,
    sessionId?: string
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    function handleClick() {
        // if (window && window.fbq) {
        //     window.fbq('track', 'ActivityClick');
        // }
        dispatch(increment({key: KEY_ACTIVITY_CLICK_COUNT}))
        if (isSimpleApply(activity)) {
            navigate("simple_apply_activity/" + activity.id, {state: {previous_location: location}});
        } else {
            navigate("activity/" + activity.id, {state: {previous_location: location}});
        }
    }

    function displayNewBadge(updatedAt: string | null) {
        if (updatedAt == null) return false

        const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
        const databaseUpdatedAt = new Date(updatedAt)
        const updatedAtDate = new Date(databaseUpdatedAt.getTime() + KR_TIME_DIFF);
        const now = new Date();
        if (Number(now) - Number(updatedAtDate) <= 1000 * 60 * 60 * 24) {
            return true
        } else {
            return false
        }
    }

    return <ActivityCardTemplate
      Badges={<>
          {
            ((activity.updatedAt && displayNewBadge(activity.updatedAt)) || activity.reserved === 1 || isSimpleApply(activity)) &&
            <div style={{display: 'flex', alignItems: 'center',  marginBottom: '8px', gap: "0.3rem" }}>
                {
                  activity.updatedAt &&
                  displayNewBadge(activity.updatedAt) &&
                  <Badge text="N" style={{borderRadius: '5px', height: '1.2rem', width: '1.2rem', lineHeight: 2 }} />
                }
                {
                  activity.reserved === 1 &&
                  <span style={{ display:'inline-block', fontWeight: '500', fontSize: '0.75rem', lineHeight: '1.2rem',  verticalAlign: 'middle',  backgroundColor: '#EEF9F2', borderRadius: '5px', padding: '0 16px', color: '#0ABF53'}}>5060 채용 약속</span>
                }
                {
                  isSimpleApply(activity) &&
                  <span style={{ display:'inline-block', fontSize: '0.75rem', lineHeight: '1.2rem', verticalAlign: 'middle', backgroundColor: '#F4F4F4', borderRadius: '5px', padding: '0 0.8rem', fontWeight: 500, color: '#474D5B' }}>간편 지원</span>
                }
            </div>
          }
      </>}
      Buttons={<div style={{position: "absolute", right: '1rem', bottom: '0.6rem'}}>
          <div style={{backgroundColor: '#0ABF53', borderRadius: '3px', color: 'white', marginLeft: 'auto', fontSize: '1rem', fontWeight: '400', padding: '8px 10px'}}>
              자세히 보기
          </div>
      </div>}
      activity={activity}
      location={location}
      regions={regions}
      onClick={handleClick}
    />
}

export default ActivityCard;