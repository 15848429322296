import {DefaultTabTemplate} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {useBlocker, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {RemovableResumeItem} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";
import IcBulb from "@assets/ic_bulb.webp"
import useOnLog from "@components/useOnLog";
import {EVENT_CREATE_RESUME_CLICK, EVENT_JOB_RESUME_TAB_SHOW, EVENT_JOB_SCRAP_TAB_SHOW} from "@components/AppConstant";
import trackEvent from "@util/trackEvent";
import React, {useState} from "react";
import {DialogDrawerTemplate} from "@components/DrawerTemplate";
import ConfirmDeleteResume from "@components/ConfirmDeleteResume";


const JobResumeView = () => {
  const navigate = useNavigate();
  const resumes = useSelector((state: RootState) => state.user.resumes)
  const resume = useSelector((state: RootState) => state.user.resume)
  const [deleteResumeId, setDeleteResumeId] = useState("");
  const [showDeletedResume, setShowDeletedResume] = useState<string>("")
  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (deleteResumeId !== "") {
      setDeleteResumeId("")
      return true
    }

    if(showDeletedResume !== "") {
      setShowDeletedResume("")
      return true
    }

    return false
  });

  useOnLog({
    pageNameKey: EVENT_JOB_RESUME_TAB_SHOW,
    regStr: "^\/job\/resume$"
  })

  const createResume = (status: string) => {
    trackEvent(EVENT_CREATE_RESUME_CLICK, {
      resume_status: status,
      location: "JobResumeTab"
    })
    navigate("create_resume_basic_info", {state: {step: 0}});
  }

  const selectOption = () => {
    trackEvent(EVENT_CREATE_RESUME_CLICK, {
      resume_status: "done",
      location: "JobResumeTab"
    })
    navigate("select_add_option")
  }

  return <>
    <DefaultTabTemplate
      top={<BackNavToolbar title={"이력서 관리"} icon={<></>}/>}
      middle={<div style={{padding: '0 16px', height: '100%'}}>
        {
          resumes && resumes.length === 0 ?
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
              {
                resume.finished || !resume.name ?
                  <>
                    <p style={{whiteSpace: 'pre-wrap', textAlign: 'center', lineHeight: 1.5}}>{"작성된 이력서가 없습니다.\n자신을 표현할 이력서를 만들어보세요."}</p>
                    <FillButton text={"이력서 추가하기"} onClick={() => createResume('notyet')}/>
                  </>
                  :
                  <>
                    <p style={{whiteSpace: 'pre-wrap', textAlign: 'center', lineHeight: 1.5}}>{"덜 작성된 항목이 있어요.\n이력서를 끝까지 작성해주세요."}</p>
                    <FillButton text={"이력서 마저 작성하기"} onClick={() => createResume('editing')}/>
                    <div style={{background: '#F4F4F4', width: '100%', marginTop: 20}}>
                      <div style={{display: 'flex', alignItems: 'center', margin: '0.875rem 0 0'}}>
                        <img src={IcBulb} alt={""} style={{margin: '0 10px', width: 18, height: 18}}/>
                        <p style={{whiteSpace: 'pre-wrap', fontWeight: 700, margin: 0}}>기존 이력서가 사라졌나요?</p>
                      </div>
                      <p style={{whiteSpace: 'pre-wrap', marginLeft: 10, lineHeight: 1.5, fontSize: '0.875rem'}}>{"6월 3일에 이력서가 업그레이드 되었어요!\n기존 이력서 등록자도 추가 작성이 필요해요."}</p>
                    </div>
                  </>
              }
            </div>
            :
            <>
              {
                resumes.map(resume => <RemovableResumeItem key={resume.id} resume={resume} onDeleted={setDeleteResumeId}/>)
              }
              <FillButton text={"이력서 추가하기"} onClick={selectOption}/>
            </>
        }
      </div>}
    />
    <ConfirmDeleteResume resumeId={deleteResumeId} showModal={deleteResumeId !== ""} setShowModal={() => setDeleteResumeId("")} onDeleted={(title) => setShowDeletedResume(title)}/>
    <DialogDrawerTemplate show={showDeletedResume !== ""} onClose={() => setShowDeletedResume("")}>
      <p style={{whiteSpace: 'pre-wrap', textAlign: 'center', fontWeight: 500, lineHeight: 1.5, margin: 0}}>
        {`${showDeletedResume} 이력서를\n삭제하였습니다.`}
      </p>
      {
        <div style={{display: 'flex', gap: 10, marginTop: 30}}>
          <FillButton text={"확인"} style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}} onClick={() => setShowDeletedResume("")}/>
        </div>
      }
    </DialogDrawerTemplate>
  </>
}

export default JobResumeView;