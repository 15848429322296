import {BackNavToolbar} from "@components/Toolbars";
import icGuide1 from "@assets/ic_guide_self_share_1.webp";
import icGuide2 from "@assets/ic_guide_self_share_2.webp";
import icGuide3 from "@assets/ic_guide_self_share_3.webp";
import * as amplitude from "@amplitude/analytics-browser"
import {useLocation, useNavigate} from "react-router-dom";
import {CUSTOM_EVENT_APP_RESUMED, EVENT_REQUEST_GUIDE_KAKAO_BUTTON_CLICK, EVENT_REQUEST_KAKAO_GUIDE_PAGE_SHOW} from "@components/AppConstant";
import useOnLog from "@components/useOnLog";
import {useDispatch, useSelector} from "react-redux"
import {DefaultPageRoot} from "@components/PageTemplate";
import {shareViaKakao} from '@util/kakao';
import {fetchWithRetry} from "@util/networks";
import server_domain from "@components/RemoteServer";
import {RootState} from "@reducer/store";
import {updateLastUsedApplyMethod} from "@reducer/configSlice";
import FaqFloatingButton from "@src/features/job/features/apply/component/FaqFloatingButton";
import React, {useEffect, useMemo} from "react";
import GuideComplete from "@src/features/job/features/apply/component/GuideComplete";
import IcKakaoSend from "@assets/ic_kakao_send.webp";
import {BorderButton} from "@components/Buttons";

let escape = false;

const RequestKakaoGuideView = () => {
  const location = useLocation();
  const state = useMemo(() => location.state, [null])
  const uuid = useSelector((state: RootState) => state.user.uuid)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showComplete, setShowComplete] = React.useState(false)

  useOnLog({
    pageNameKey: EVENT_REQUEST_KAKAO_GUIDE_PAGE_SHOW,
    amplitudeProp: {
      "activity_id": state.amplitude_props.activity_id,
    }
  })

  function shareKakao() {
    amplitude.track(EVENT_REQUEST_GUIDE_KAKAO_BUTTON_CLICK, {
      ...state.amplitude_props
    })
    dispatch(updateLastUsedApplyMethod("간편지원 외"))
    fetchWithRetry(server_domain() + "/apply_history", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        activity_id: state.amplitude_props.activity_id,
        user_uuid: uuid,
        status: "apply_method_checked",
        date: new Date().toISOString()
      })
    })
    shareViaKakao(
      state.templateId,
      state.templateArgs
    );
  }

  const onResume = () => {
    setShowComplete(true)
  }
  useEffect(() => {
    window.addEventListener(CUSTOM_EVENT_APP_RESUMED, onResume);
    return () => {
      window.removeEventListener(CUSTOM_EVENT_APP_RESUMED, onResume);
    }
  }, []);

  return (
    <>
      <DefaultPageRoot
        top={<BackNavToolbar title={"카카오톡으로 받기"}/>}
        middle={<>
          <p style={{textAlign: 'left', margin: '30px 15px 32px', lineHeight: '1.5rem', fontWeight: 400}}>
            지원 방법∙서류가 담긴 문서를 카카오톡으로 공유해드릴게요. 내 채팅방으로 문서 공유 후, <span style={{fontWeight: 700}}>PC 카카오톡</span>에서 열어보세요.
          </p>
          <div style={{textAlign: 'center'}}>
            <button onClick={shareKakao} style={{width: '92%', background: '#FEE500', borderRadius: '5px', border: 'none', padding: '15px', fontSize: '1rem', fontWeight: 400}}>
              <span style={{color: 'black'}}>카카오톡으로 문서 받기</span>
            </button>
          </div>
          <div style={{background: '#F4F4F4', borderRadius: '5px', margin: '48px 15px', padding: '16px 24px'}}>
            <div style={{lineHeight: '1.2rem', color: '#666666', fontSize: '0.875rem', margin: '0'}}>
              <p style={{margin: "0px"}}>
                <span style={{display: 'block'}}>참고 : 카카오톡으로 문서 받는 방법</span>
              </p>

              <p>
                <span style={{display: 'block'}}>1. 친구 탭을 클릭합니다</span>
                <img src={icGuide1} alt="" style={{width: '100%'}}/>
              </p>

              <p>
                <span style={{display: 'block'}}>2. 내 프로필을 선택합니다</span>
                <img src={icGuide2} alt="" style={{width: '100%'}}/>
              </p>

              <p>
                <span style={{display: 'block'}}>3. 우측 상단의 확인을 누릅니다</span>
                <img src={icGuide3} alt="" style={{width: '100%'}}/>
              </p>
            </div>
          </div>
          <FaqFloatingButton activityId={state.amplitude_props.activity_id}/>
        </>}
      />
      <GuideComplete activityId={state.amplitude_props.activity_id} show={showComplete} onClose={() => {escape = true;navigate(-3)}}>
        <img src={IcKakaoSend} alt="" style={{width: '4rem'}}/>
        <p style={{fontSize: '1rem', marginTop: '10px', fontWeight: 500}}>카카오톡 문서는 잘 받으셨나요?</p>
        <pre style={{fontSize: '0.875rem', color: '#666666', margin: '24px 0px 24px', lineHeight: '1.1rem'}}>{"컴퓨터에서 카카오톡을 확인하고\n최종 지원까지 진행해주세요"}</pre>
        <BorderButton style={{width: 200, display: 'inline-block'}} text={"확인"} onClick={() => {escape = true;navigate(-3)}} />
      </GuideComplete>
    </>

  )
}

export default RequestKakaoGuideView;