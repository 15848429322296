import React, {CSSProperties} from "react";
import {firstItemRightMargin} from "@src/features/job/features/history/style/HistoryItemLayout";

const DotIcon = ({style = {}, dotBackground = '#D9D9D9'}: { style?: CSSProperties, dotBackground?: string }) => <div style={style}>
  <div style={{width: 3, height: 3, borderRadius: '100%', background: dotBackground}}/>
  <div style={{width: 3, height: 3, borderRadius: '100%', background: dotBackground, margin: '3px 0'}}/>
  <div style={{width: 3, height: 3, borderRadius: '100%', background: dotBackground}}/>
</div>

const HistoryDot = ({status, events}: { status: string, events: any }) => {
  const event = events.find((event: any) => event.status === status);
  return <DotIcon style={{marginLeft:(firstItemRightMargin + 7) / 2, width: 'fit-content'}} dotBackground={event ? 'black' : '#D9D9D9'}/>
}

export default HistoryDot;