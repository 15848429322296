import React from "react";

interface BadgeProps {
    text: string,
    style?: React.CSSProperties
}

const Badge = ({text, style}: BadgeProps) => {
    let badgeStyle: React.CSSProperties = {
        lineHeight: 1.7,
        display: 'block',
        borderRadius: '50%',
        background: "#F15D47",
        color: 'white',
        width: '20px',
        height: '20px',
        fontSize: '0.6rem',
        textAlign: 'center',
        ...style
    }
    return (<span style={badgeStyle}>{text}</span> )
}

export default Badge;
