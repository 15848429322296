export const primary = '#0ABF53';
export const secondary = '#EEF9F2';
export const inactive = '#D9D9D9';
export const secondaryBlack = '#474D5B';
export const grey999 = '#999999';
export const grey666 = '#666666';
export const greyD7 = '#D7D8DA';
export const greyF8 = '#F8F8F8';
export const greyF4 = '#F4F4F4';
export const red = '#F15D47';


// https://codepen.io/sosuke/pen/Pjoqqp
// figma svg가 image로 렌더링 되는 문제로 인해 svg filter를 적용하기 위한 변수
// 참고: image의 경우 fill/currentColor가 적용되지 않아서 svg filter를 적용해야함
export const primaryFilterForSVG = "invert(70%) sepia(52%) saturate(6043%) hue-rotate(106deg) brightness(96%) contrast(92%)";
