import {useBlocker, useLocation, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import * as amplitude from "@amplitude/analytics-browser"
import {EVENT_INPUT_EMAIL_PAGE_SHOW, EVENT_REQUEST_EMAIL_CLICK, EVENT_EMAIL_REQUESTED} from "@components/AppConstant"
import {BackNavToolbar} from "@components/Toolbars"
import server_domain from "@components/RemoteServer"
import React, {useMemo, useState} from "react"
import styles from "@src/features/job/features/apply/style/input.module.css";
import {show} from "@reducer/snackbarSlice"
import {changeEmail} from "@reducer/userSlice"
import useOnLog from "@components/useOnLog";
import emailChecker from "@util/emailChecker";
import {DefaultPageRoot} from "@components/PageTemplate"
import uuidv4 from "@util/uuidv4"
import {fetchWithRetry} from "@util/networks";
import {RootState} from "@reducer/store";
import {EmailField} from "@components/form/ResumeComponent";
import {BorderButton, FillButton} from "@components/Buttons";
import {updateLastUsedApplyMethod} from "@reducer/configSlice";
import FaqFloatingButton from "@src/features/job/features/apply/component/FaqFloatingButton";
import ImgSentEmail from "@assets/img_sent_email.webp";
import {DialogDrawerTemplate, FullscreenDrawerTemplate} from "@components/DrawerTemplate";
import GuideComplete from "@src/features/job/features/apply/component/GuideComplete";

let escape = false;

const InputEmail = () => {
  const location = useLocation();
  const state = useMemo(() => location.state, [null])
  const [showComplete, setShowComplete] = useState(false)
  var isRequesting = false
  const [requesting, setReqeusting] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userEmail = useSelector((state: RootState) => state.user.email)
  const [email, setEmail] = useState<string>(userEmail || "");
  const uuid = useSelector((state: RootState) => state.user.uuid)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const currentPath = useMemo(() => location.pathname, [null])
  const [emailFocused, setEmailFocused] = useState(false)
  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (escape) {
      escape = false
      return false
    }

    if (showComplete && currentPath === currentLocation.pathname && historyAction === "POP") {
      navigate(-2)
      escape = true
      return false
    }

    if (showEmailModal) {
      setShowEmailModal(false)
      return true
    }

    return false
  });

  const re = /.*\s+$/
  if (!!email && re.test(email)) {
    setEmail(email.trim())
  }

  useOnLog({
    pageNameKey: EVENT_INPUT_EMAIL_PAGE_SHOW,
    amplitudeProp: {
      "activity_id": state.id,
      "title": state.activityTitle,
    }
  })

  async function requestEmail() {
    if (isRequesting) {
      return
    }
    dispatch(changeEmail(email))

    if (!!email && !emailChecker(email)) {
      setShowErrorText(true)
      return
    }
    try {
      let body = {
        "activity_id": state.id,
        "email": email,
        "id": uuidv4()
      }
      isRequesting = true
      setReqeusting(true);
      amplitude.track(
        EVENT_REQUEST_EMAIL_CLICK, body
      );

      let res = await fetchWithRetry(server_domain() + "/request_email", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      })
      if (res.ok) {
        setEmail("");
        setShowComplete(true)
        amplitude.track(EVENT_EMAIL_REQUESTED, {"succeed": true})
        dispatch(updateLastUsedApplyMethod("간편지원 외"))
        fetchWithRetry(server_domain() + "/apply_history", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({
            activity_id: state.id,
            user_uuid: uuid,
            status: "apply_method_checked",
            date: new Date().toISOString()
          })
        })
      } else {
        amplitude.track(EVENT_EMAIL_REQUESTED, {"succeed": false})
      }
    } catch {
      amplitude.track(EVENT_EMAIL_REQUESTED, {"succeed": false})
      dispatch(show({message: "전송에 실패하였습니다. 잠시 후 다시 시도해 주세요"}))
    } finally {
      isRequesting = false
      setReqeusting(false);
    }
  }

  return (<>
      <DefaultPageRoot
        top={<BackNavToolbar title={"이메일로 받기"}/>}
        middle={<>
          <pre style={{textAlign: 'left', margin: '30px 15px 32px', lineHeight: '1.5rem', fontWeight: 400}}>{"지원 방법∙서류를 받을 이메일 주소 입력 후\n'이메일 받기' 버튼을 눌러주세요."}</pre>
          <p style={{margin: '0px 16px 0px', fontSize: '0.75rem'}}>이메일 주소</p>
          <div style={{padding: '0 16px'}}>
            <EmailField onChange={setEmail} value={email} hideLabel showModal={showEmailModal} setShowModal={setShowEmailModal} onFocus={() => setEmailFocused(true)} onBlur={() => setEmailFocused(false)}/>
            {showErrorText && <p style={{margin: '5px 15px', color: "#F15D47", fontSize: '0.875rem'}}>메일을 보내지 못했습니다. 주소를 확인해주세요.</p>}
            <FillButton
              active={email !== "" && !requesting}
              text={requesting ? "보내는 중..." : "보내기"}
              style={{marginTop: 20}}
              onClick={requestEmail}
            />
          </div>
          {
            !emailFocused && <FaqFloatingButton activityId={state.id}/>
          }
        </>}
      />
      <GuideComplete activityId={state.id} show={showComplete} onClose={() => {escape = true;navigate(-3)}}>
        <img src={ImgSentEmail} alt="" style={{width: '4rem'}}/>
        <p style={{fontSize: '1rem', marginTop: '10px', fontWeight: 500}}>이메일을 보냈습니다</p>
        <pre style={{fontSize: '0.875rem', color: '#666666', margin: '24px 0px 24px', lineHeight: '1.1rem'}}>{"컴퓨터에서 이메일을 확인하고\n최종 지원까지 진행해주세요"}</pre>
        <BorderButton style={{width: 200, display: 'inline-block'}} text={"확인"} onClick={() => {escape = true;navigate(-3)}} />
      </GuideComplete>
    </>
  )
}

export default InputEmail