import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {useBlocker, useNavigate, useParams} from "react-router-dom";
import {Divider} from "@components/DivideBar";
import {BorderButton, FillButton, SecondaryFillButton} from "@components/Buttons";
import IcPlus from '@assets/ic_plus.svg';
import IcPerson from '@assets/ic_person.svg';
import RequiredMark from "@components/RequiredMark";
import {CareerCard, CertificateCard, DeleteCareerModal, DeleteCertificateModal, DeleteEducationModal, EducationCard, EmailField, HSelector, TelField, TextArea, TextField, YMDInput} from "@components/form/ResumeComponent";
import {fetchWithRetry} from "@util/networks";
import server_domain from "@components/RemoteServer";
import {RootState} from "@reducer/store";
import {removeCareerTemp, removeCertificateTemp, removeEducationTemp, ResumeState, setResumes, setResumeTemp, updateResumeTempField} from "@reducer/userSlice";
import {DialogDrawerTemplate} from "@components/DrawerTemplate";
import "@components/LoadingCircle.css"
import {Action} from "@remix-run/router";
import {show} from "@reducer/snackbarSlice";
import ImageSourceSelector from "@components/ImageSourceSelector";

const SectionStyle = {
  padding: '20px 16px'
}

const HeaderStyle = {
  fontWeight: 500,
  fontSize: '1.125rem',
  color: '#1D1D1D'
}

const EducationNavigation: { [key: string]: string } = {
  "phd": "education_phd",
  "master": "education_master",
  "bachelor": "education_bachelor",
  "high_school": "education_high_school",
  "middle_under": "중학교 이하"
}

let originalResume: ResumeState | null = null;

const EditResumeView = ({previousPage}: { previousPage: string }) => {
  const navigate = useNavigate();
  let saved = false;
  const dispatch = useDispatch();
  const {resumeId} = useParams()
  const middleRef = useRef<HTMLDivElement>(null);
  const uuid = useSelector((state: RootState) => state.user.uuid)
  const deviceId = useSelector((state: RootState) => state.user.deviceId)
  const number = useSelector((state: RootState) => state.user.phoneNumber)
  const resume = useSelector((state: RootState) => state.user.resumeTemp)
  const [loaded, setLoaded] = useState(false)
  let waiting = false
  const currentPath = useMemo(() => window.location.pathname, [null]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showNotSavedModal, setNotSavedModal] = useState(false);
  const [showChangedPhoneNumber, setShowChangedPhoneNumber] = useState(false);

  const [deleteEducationId, setDeleteEducationId] = useState("");
  const [deleteCareerId, setDeleteCareerId] = useState("");
  const [deleteCertificateId, setDeleteCertificateId] = useState("");

  const [showConfirmDeletePhotoModal, setShowConfirmDeletePhotoModal] = useState(false);
  const [showImageSelectorModal, setShowImageSelectorModal] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation, historyAction }) => {
    if (showEmailModal) {
      setShowEmailModal(false)
      return true
    }

    if (deleteEducationId !== "") {
      setDeleteEducationId("")
      return true
    }

    if (deleteCareerId !== "") {
      setDeleteCareerId("")
      return true
    }

    if (deleteCertificateId !== "") {
      setDeleteCertificateId("")
      return true
    }

    if (showConfirmDeletePhotoModal) {
      setShowConfirmDeletePhotoModal(false)
      return true
    }

    if (showImageSelectorModal) {
      setShowImageSelectorModal(false)
      return true
    }

    if (showNotSavedModal) {
      setNotSavedModal(false)
      return true
    }

    if (!showNotSavedModal && !saved && isChanged() && currentLocation.pathname === currentPath && historyAction === Action.Pop) {
      setNotSavedModal(true)
      return true
    }
    return false
  });

  useEffect(() => {
    fetchWithRetry(server_domain() + "/resume?uuid=" + uuid + "&resume_id=" + resumeId)
      .then(res => res.json())
      .then((data) => {
        originalResume = data
        dispatch(setResumeTemp(data))
        setTimeout(() => setLoaded(true), 2000)
      })
  }, []);

  const available = () => {
    return resume.title !== ""
      && resume.name !== ""
      && resume.birthday !== ""
      && resume.phone_number !== ""
      && resume.address !== ""
      && resume.introduction !== ""
      && resume
      && resume.career
      && resume.career.length > 0
      && resume.education
      && resume.education.length > 0
  }

  function save() {
    if (!isChanged()) {
      navigate(-1)
      return
    }

    if (!showChangedPhoneNumber && resume.phone_number !== number) {
      setShowChangedPhoneNumber(true)
      return
    }

    if (waiting) {
      return
    }

    waiting = true;
    fetchWithRetry(server_domain() + "/resume", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: deviceId,
        resume_id: resumeId,
        title: resume.title,
        name: resume.name,
        birthday: resume.birthday,
        gender: resume.gender,
        phone_number: resume.phone_number,
        email: resume.email,
        address: resume.address,
        introduction: resume.introduction,
        education: resume.education,
        career: resume.career,
        certificate: resume.certificate,
        photo: resume.photo,
        photo_res: resume.photo_res || "",
      })
    })
      .then(res => {
          if (res.ok) {
            return res.json()
          }
        }
      )
      .then((data) => {
        if (data) {
          saved = true
          dispatch(setResumes(data.resumes))
          dispatch(show({message: "이력서를 수정하였습니다."}))
          navigate(-1)
        }
      })
      .finally(() => {
        waiting = false
      })
  }

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const newImg = document.createElement('img')
  newImg.onload = () => {
    const imgWidth = 500
    const imgHeight = imgWidth * newImg.height / newImg.width
    canvas.width = imgWidth
    canvas.height = imgHeight
    if (ctx !== null) {
      ctx.filter = "blur(0.5px) brightness(105%)";
      ctx.drawImage(newImg, 0, 0, imgWidth, imgHeight)
    }
    const img = canvas.toDataURL('image/webp')
    dispatch(updateResumeTempField({
      photo: "new",
      photo_res: img.split(',')[1]
    }))
  }

  const uploadImage = (e: any) => {
    if (e.detail.id === 'id1') {
      newImg.setAttribute('src', "data:image/webp;base64, " + e.detail.images[0])
    }
  }

  const isChanged = () => {
    if (originalResume !== null) {
      return originalResume.title !== resume.title
        || originalResume.name !== resume.name
        || originalResume.birthday !== resume.birthday
        || originalResume.gender !== resume.gender
        || originalResume.email !== resume.email
        || originalResume.phone_number !== resume.phone_number
        || originalResume.address !== resume.address
        || originalResume.introduction !== resume.introduction
        || originalResume.career !== resume.career
        || originalResume.education !== resume.education
        || originalResume.certificate !== resume.certificate
        || originalResume.photo !== resume.photo
        || originalResume.photo_res !== resume.photo_res
    } else {
      return false;
    }
  }

  useEffect(() => {
    window.addEventListener("uploadImage", uploadImage);
    return () => {
      window.removeEventListener("uploadImage", uploadImage);
    }
  }, [null]);

  if (!loaded) {
    return <DialogDrawerTemplate show={true} onClose={() => {}}>
      <div id="spinner"></div>
      <p style={{textAlign: 'center', whiteSpace: 'pre-wrap', lineHeight: 1.5}}>{"이력서 수정 페이지로 이동합니다.\n잠시만 기다려주세요."}</p>
      <p style={{textAlign: 'center', whiteSpace: 'pre-wrap', lineHeight: 1.5, fontSize: '0.9rem'}}>{"TIP. 이력서 수정 후에\n'보기'를 눌러 재확인하세요"}</p>
    </DialogDrawerTemplate>
  }

  const onFocus = (top: number) => setTimeout(() => middleRef.current?.scrollTo({top: middleRef.current.scrollTop + top - 80, behavior: 'smooth'}), 400)

  return (
    <>
    <DefaultPageRoot
        top={<BackNavToolbar title={'이력서'}/>}
        middleRef={middleRef}
        middle={
          <>
            <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', margin: 'auto', width: 'fit-content', marginBottom: 10}}>
              <div style={{width: 150, display: "flex", flexDirection: 'column', alignItems: 'center'}}>
                {
                  resume.photo_res
                    ? <img src={"data:image/webp;base64, " + resume.photo_res} alt="" style={{width: '100%', border: '1px solid black', borderRadius: 5}}/>
                    : <div style={{background: '#F4F4F4', borderRadius: 5, width: 'fit-content', padding: 10, marginBottom: 10}} onClick={() => setShowImageSelectorModal(true)}>
                      <IcPerson/>
                    </div>
                }
              </div>
              {
                resume.photo_res
                  ? <div style={{display: 'flex', gap: 5, marginTop: 10}}>
                    <BorderButton
                      text={"변경"}
                      style={{margin: 'auto', width: 'fit-content', padding: 5}}
                      onClick={() => setShowImageSelectorModal(true)}
                    />
                    <BorderButton
                      text={"삭제"}
                      style={{margin: 'auto', width: 'fit-content', padding: 5}}
                      onClick={() => setShowConfirmDeletePhotoModal(true)}
                    />
                  </div>
                  : <BorderButton
                    text={"사진 등록"}
                    style={{margin: 'auto', width: '100px', padding: 5}}
                    onClick={() => setShowImageSelectorModal(true)}
                  />
              }
            </div>
            <div style={SectionStyle}>
              <div style={{display: 'flex',}}>
                <span style={{...HeaderStyle, flex: 1, marginRight: '0px', paddingRight: '0px'}}>이력서 정보</span>
                <RequiredMark/>
                <span style={{fontSize: '0.875rem', color: '#F15D47'}}>&nbsp;필수입력항목</span>
              </div>
              <TextField onFocus={onFocus} label={"별칭"} placeholder={"예) 시설직 전용"} value={resume.title} onChange={(text) => dispatch(updateResumeTempField({title: text}))} max={10} highlight={resume.title === ""} showRequiredMark/>
            </div>
            <div style={SectionStyle}>
              <span style={{...HeaderStyle, marginRight: '0px', paddingRight: '0px'}}>인적사항</span>
              <TextField onFocus={onFocus} label={"이름"} placeholder={"예) 홍길동"} value={resume.name} onChange={(text) => dispatch(updateResumeTempField({name: text}))} highlight={resume.name === ""} showRequiredMark/>
              <YMDInput onFocus={onFocus} label={"생년월일"} init={resume.birthday} onChange={(text) => dispatch(updateResumeTempField({birthday: text}))} highlight={resume.birthday === ""} showRequiredMark/>
              <HSelector label={"성별"} options={[{key: "0", label: '남성'}, {key: "1", label: '여성'}]} value={resume.gender.toString() || "0"} onChange={(text) => dispatch(updateResumeTempField({gender: text}))}/>
              <TelField onFocus={onFocus} label={"휴대폰"} placeholder={"예) 01012345678"} onChange={(text) => dispatch(updateResumeTempField({phone_number: text}))} init={resume.phone_number} highlight={resume.phone_number === ""} showRequiredMark/>
              <EmailField onFocus={onFocus} value={resume.email} onChange={(text) => dispatch(updateResumeTempField({email: text}))} showModal={showEmailModal} setShowModal={setShowEmailModal} />
              <TextArea onFocus={onFocus} value={resume.address} label={"주소"} placeholder={"예) 서울특별시 중구 세종대로 110 "} onChange={(text) => dispatch(updateResumeTempField({address: text}))} highlight={resume.address === ""} showRequiredMark/>
            </div>
            <Divider/>
            <div style={SectionStyle}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{...HeaderStyle, flex: 1, marginRight: '0px', paddingRight: '0px'}}>학력사항<RequiredMark/></span>
                <div style={{display: 'flex', alignItems: 'center'}} onClick={() => navigate("education_status")}>
                  <span style={{color: 'black'}}>추가</span>
                  <IcPlus fill="black" stroke="black" width={20}/>
                </div>
              </div>
              {resume && resume.education && resume.education.map((education, idx) => <EducationCard key={education.uuid} education={education} highest={idx === 0} onEdit={() => navigate(EducationNavigation[education.degree])} onDelete={setDeleteEducationId} />)}
              {resume && resume.education && resume.education.length === 0 && <p style={{margin: '1rem 0 8px', padding: '5px 0 0', color: '#F15D47', fontSize: '0.875rem'}}>필수로 입력해주세요</p>}
              {resume && resume.education && resume.education.length === 0 && <p style={{margin: 0, lineHeight: 1.5, color: '#B2B2B2'}}>입력된 학력이 없어요. “추가+”를 눌러 최종 학력부터 추가해보세요</p>}
            </div>
            <Divider/>
            <div style={SectionStyle}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{...HeaderStyle, flex: 1, marginRight: '0px', paddingRight: '0px'}}>경력사항<RequiredMark/></span>
                <div style={{display: 'flex', alignItems: 'center'}} onClick={() => navigate(resume.career.length === 0 ? "check_career" : "career")}>
                  <span style={{color: 'black'}}>추가</span>
                  <IcPlus fill="black" stroke="black" width={20}/>
                </div>
              </div>
              {resume && resume.career && resume.career.map((item, idx) => <CareerCard key={item.uuid} career={item} onEdit={() => navigate("career/" + item.uuid)} onDelete={setDeleteCareerId} />)}
              {resume && resume.career && resume.career.length === 0 && <p style={{margin: '1rem 0 8px', padding: '5px 0 0', color: '#F15D47', fontSize: '0.875rem'}}>필수로 입력해주세요</p>}
              {resume && resume.career && resume.career.length === 0 && <p style={{margin: 0, lineHeight: 1.5, color: '#B2B2B2'}}>입력된 경력이 없어요. “추가+”를 눌러 최근 경력부터 추가해주세요</p>}
            </div>
            <Divider/>
            <div style={SectionStyle}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{...HeaderStyle, flex: 1, marginRight: '0px', paddingRight: '0px'}}>자격증</span>
                <div style={{display: 'flex', alignItems: 'center'}} onClick={() => navigate(resume.certificate.length === 0 ? "check_certificate" : "certificate")}>
                  <span style={{color: 'black'}}>추가</span>
                  <IcPlus fill="black" stroke="black" width={20}/>
                </div>
              </div>
              {resume && resume.certificate && resume.certificate.map((item, idx) => <CertificateCard key={item.uuid} certificate={item} onEdit={() => navigate("certificate/" + item.uuid)} onDelete={setDeleteCertificateId} />)}
              {resume && resume.certificate && resume.certificate.length === 0 && <p style={{margin: '1rem 0 0', lineHeight: 1.5, color: '#B2B2B2'}}>입력된 자격증이 없어요. “추가+”를 눌러 최근 취득한 자격증부터 추가해주세요</p>}
            </div>
            <Divider/>
            <div style={SectionStyle}>
              <span style={{...HeaderStyle, flex: 1, marginRight: '0px', paddingRight: '0px'}}>나의 강점<RequiredMark/></span>
              <TextArea onFocus={onFocus} rows={8} placeholder={"지원하려는 분야에서 내가 어떤 강점을 가지고 있는지 설명해주세요. 어린 시절의 구구절절한 스토리보다, 내가 이 일을 왜 잘 할 수 있는지 설명하는 게 좋아요"} label={""} value={resume.introduction} highlight={resume.introduction === ""} onChange={(text) => dispatch(updateResumeTempField({introduction: text}))}/>
            </div>
          </>
        }
        bottom={<div style={{padding: 16}}>
          <FillButton
            active={available()}
            text="저장"
            onClick={save}
          />
        </div>}
      />
      <DeleteEducationModal id={deleteEducationId} onClose={() => setDeleteEducationId("")} onDelete={() => dispatch(removeEducationTemp(deleteEducationId))} />
      <DeleteCareerModal id={deleteCareerId} onClose={() => setDeleteCareerId("")} onDelete={() => dispatch(removeCareerTemp(deleteCareerId))} />
      <DeleteCertificateModal id={deleteCertificateId} onClose={() => setDeleteCertificateId("")} onDelete={() => dispatch(removeCertificateTemp(deleteCertificateId))} />
      <DialogDrawerTemplate show={showNotSavedModal} onClose={() => {setNotSavedModal(false)}}>
        <p style={{lineHeight: 1.5, whiteSpace: 'pre-wrap', fontWeight: 500, textAlign: 'center'}}>{"저장하지 않은 내용은\n전부 삭제됩니다."}</p>
        <FillButton text={"계속 작성하기"} style={{marginBottom: 10}} onClick={() => {setNotSavedModal(false)}}/>
        <SecondaryFillButton text={"저장하지 않고 나가기"} onClick={() => {blocker?.proceed?.()}}/>
      </DialogDrawerTemplate>
      <DialogDrawerTemplate show={showChangedPhoneNumber} onClose={() => setShowChangedPhoneNumber(false)}>
        <p style={{lineHeight: 1.5, wordBreak: 'break-all'}}>입력하신 휴대전화번호({resume.phone_number})와 로그인 한 휴대전화번호({number})가 일치하지 않습니다.</p>
        <FillButton text={"다시 입력하기"} style={{marginBottom: 10}} onClick={() => setShowChangedPhoneNumber(false)}/>
        <SecondaryFillButton text={"입력한 내용대로 저장하기"} onClick={save}/>
      </DialogDrawerTemplate>
      <DialogDrawerTemplate show={showConfirmDeletePhotoModal} onClose={() => setShowConfirmDeletePhotoModal(false)} title={"사진 삭제"}>
        <p>등록한 사진을 삭제합니다.</p>
        <div style={{display: 'flex', gap: 10}}>
          <FillButton style={{background: '#F4F4F4'}} fontStyle={{color: 'black'}} text={"취소"} onClick={() => setShowConfirmDeletePhotoModal(false)} />
          <FillButton style={{background: '#F15D47'}} text={"삭제"} onClick={() => {
            dispatch(updateResumeTempField({photo: "", photo_res: ""}))
            setShowConfirmDeletePhotoModal(false);
          }} />
        </div>
      </DialogDrawerTemplate>
      <ImageSourceSelector id={'id1'} showModal={showImageSelectorModal} setShowModal={setShowImageSelectorModal} />
    </>
  );
}

export default EditResumeView;