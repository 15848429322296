import server_domain from "@src/components/RemoteServer";
import convertActivities from "@src/util/convertActivities";
import { fetchWithRetry } from "@src/util/networks";

async function loadHomeData() {
    const result = await fetchWithRetry(server_domain() + '/home', {
        headers: { "Content-Type": "application/json" },
    })
    const body = await result.json()
    
    return {
        ...body,
        posts: body.posts.map((post: any) => {
            return {
                ...post,
                image_urls: post.image_urls ? post.image_urls.split(',') : null
            }
        }),
        activities: convertActivities(body.activities),
    }
}

export default loadHomeData;
