import ReactDOM from 'react-dom';
import { useOnMountOnce } from "@src/util/useOnMountOnce";
import IModal from "./IModal";

const Modal = ({ message, onCancel, onConfirm, confirmText = '확인', cancelText = '취소', onBackgroundClick}: 
    {
        message: string;
        confirmText?: string;
        cancelText?: string;
        onCancel: () => void;
        onConfirm: () => void;
        onBackgroundClick?: () => void;
    }
) => {
    const handleMobileBackButton = (_: any) => {
        onCancel();
    }

    const handleManualCancel = () => {
        window.history.back();
        onCancel();
    }

    const handleConfirm = () => {
        window.history.back();
        onConfirm();
    }

    useOnMountOnce(() => {
        window.history.pushState(null, '', window.location.href);
        window.addEventListener('popstate', handleMobileBackButton, { once: true });
    })
    
    return (
        ReactDOM.createPortal(
            IModal({ message, onCancel: handleManualCancel, onConfirm: handleConfirm, confirmText, cancelText, onBackgroundClick }),
            document.getElementById('modal-root') as HTMLElement
        )
    );
}

export default Modal;