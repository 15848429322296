import icKakao from "@assets/ic_kakao.webp";
import icShareWhite from "@assets/ic_share_white.webp";
import { useLocation, useNavigate } from "react-router-dom";
import { EVENT_SHARE_BUTTON_CLICK } from "@components/AppConstant";
import * as amplitude from "@amplitude/analytics-browser"
import { useDispatch } from "react-redux";
import { show } from "@reducer/snackbarSlice";
import { BottomDrawerTemplate } from "@components/DrawerTemplate";
import { IconFillButton } from "@components/Buttons";
import { shareViaKakao } from '@util/kakao';

export const ShareDrawer = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const dispatch = useDispatch();
    const state = location.state;

    function shareKakao() {
        amplitude.track(EVENT_SHARE_BUTTON_CLICK, {
            source: 'kakao',
            ...state.amplitude_props
        })
        shareViaKakao(
            state.templateId,
            state.templateArgs
        );
    }

    async function copyLink() {
        amplitude.track(EVENT_SHARE_BUTTON_CLICK, {
            ...state.amplitude_props,
            source: 'direct_link'
        })
        if (state.url) {
            if (window && window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
                const appVersion = await window.flutter_inappwebview.callHandler('appVersion')
                if (appVersion == null || appVersion <= 9) {
                    window.flutter_inappwebview.callHandler('copyText', state.url);
                } else {
                    window.flutter_inappwebview.callHandler('copyText', [state.url, state.toastText]);
                }
            } else if (window && window.navigator && window.navigator.clipboard) {
                window.navigator.clipboard.writeText(state.url);
                dispatch(show({ message: "내용이 복사되었습니다. 붙여넣기 하여 공유해주세요." }))
            }
        }
    }

    return (
        <BottomDrawerTemplate title={state.title} show={true} onClose={() => navigate(-1)} >
            <IconFillButton
                icon={<img src={icKakao} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '8px' }} />}
                style={{ background: '#FEE500', justifyContent: 'start', marginBottom: 15 }}
                fontStyle={{ color: 'black' }}
                text={state.kakao_share_title}
                onClick={shareKakao}
            />
            <IconFillButton
                icon={<img src={icShareWhite} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '8px' }} />}
                style={{ background: '#474D5B', justifyContent: 'start', marginBottom: 15 }}
                text={state.link_copy_title}
                onClick={copyLink}
            />
        </BottomDrawerTemplate>
    );
}