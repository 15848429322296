import PopularActivityCard from './PopularActivityCard';
import { useSelector } from 'react-redux';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef } from 'react';
import { RootState } from '@reducer/store';


const PopularActivityList = ({ activities, location, noContentView, style }: {
    activities: any[],
    location: string,
    noContentView: () => JSX.Element
    style?: React.CSSProperties
}) => {
    const parentRef = useRef<HTMLDivElement>(null)
    const regions = useSelector((state: RootState) => state.user.regions);
    const virtualizer = useVirtualizer({
        count: activities.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 200,
        overscan: 10
    })
    const items = virtualizer.getVirtualItems()

    if (activities.length === 0) {
        return noContentView()
    }
    return (
        <>
            <div
                ref={parentRef}
                style={{
                    ...style,
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    contain: 'strict',
                }}
            >
                <div
                    style={{
                        height: virtualizer.getTotalSize(),
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${items[0]?.start ?? 0}px)`,
                        }}
                    >
                        {items.map((virtualRow) => (
                            <div
                                key={virtualRow.key}
                                data-index={virtualRow.index}
                                ref={virtualizer.measureElement}
                            >
                                <PopularActivityCard key={virtualRow.index} idx={virtualRow.index} activity={activities[virtualRow.index]} regions={regions} location={location} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopularActivityList;