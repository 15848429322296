import { createSlice } from "@reduxjs/toolkit";
import { identify } from '@amplitude/analytics-browser';
import * as amplitude from '@amplitude/analytics-browser';
import { setSentryTag } from "@src/util/sentry";

const configSlice = createSlice({
    name: 'config',
    initialState: {
        showInstallGuideBanner: false,
        appVersion: 0,
        listQueryMode: "RECOMMEND",
        lastSelectResumeId: "",
        lastUsedApplyMethod: "",
    },
    reducers: {
        updateShowInstallGuideBanner: (state, action) => {
            state.showInstallGuideBanner = action.payload
        },
        updateAppVersion: (state, action) => {
            const identifyEvent = new amplitude.Identify();
            identifyEvent.set('app_version', action.payload);
            identify(identifyEvent);
            setSentryTag('app_version', action.payload);
            state.appVersion = action.payload
        },
        updateListQueryMode: (state, action: {payload: string, type: string}) => {
            state.listQueryMode = action.payload
        },
        updateLastSelectResumeId: (state, action: {payload: string, type: string}) => {
            state.lastSelectResumeId = action.payload
        },
        updateLastUsedApplyMethod: (state, action: {payload: string, type: string}) => {
            state.lastUsedApplyMethod = action.payload
        }
    }
})

export const { 
    updateShowInstallGuideBanner,
    updateAppVersion,
    updateListQueryMode,
    updateLastSelectResumeId,
    updateLastUsedApplyMethod
} = configSlice.actions
export default configSlice.reducer