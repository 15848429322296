import { Activity } from "@model/Activity";

const convertActivity = (activity: any): Activity => {
    return {
        activityRecruitmentUntilComplete: activity.activity_recruitment_until_complete,
        activityRecruitmentStartDate: activity.activity_recruitment_start_date,
        id: activity.id,
        activityId: activity.id,
        activityDescription: activity.activity_description,
        activityRecruitmentEndDate: activity.activity_recruitment_end_date,
        activityRecruitmentEndTime: activity.activity_recruitment_end_time,
        activityRecruitmentStartTime: activity.activity_recruitment_start_time,
        url: activity.url,
        updatedAt: activity.updated_at,
        activityReward: activity.activity_reward,
        status: activity.status,
        activityTitle: activity.activity_title,
        activityRegionList: activity.activity_region_list,
        activityTagList: activity.activity_tag_list,
        activityImages: activity.images?.split(','), 
        activityTagKey: activity.activity_tag_key,
        activityOrganization: activity.activity_organization,
        activityBasicInsurance: activity.activity_basic_insurance,
        originTitle: activity.origin_title,
        activityApplicationRequestMethod:activity.activity_application_request_method,
        activityAppliedAt: activity.activity_applied_at,
        reserved: activity.reserved,
        applyMethod: activity.method, 
        applyMethodTel: activity.apply_method_tel, 
        applyMethodManagerName: activity.apply_method_manager_name,
        applyPreparationDocument: activity.apply_preparation_document,
        applyDecisionProcess: activity.apply_decision_process,
        applyOriginalDocument: activity.apply_original_document,
        historyId: activity.history_id,
    }
}

export default convertActivity;