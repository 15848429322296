import SavingInstallmentProduct from "@src/features/finance/features/saving_installment_account/model/SavingInstallmentProduct";
import {secondaryBlackColor} from "@components/styles";
import IcMoneyCircle from '@assets/ic_money_circle.webp';
import IcCalendar from '@assets/ic_calendar.webp';

const SavingInstallmentProductCardContent = ({product}: { product: SavingInstallmentProduct}) => {
  const rate = "기본 " + product.basicRate + "%" + (product.maxRate ? ` (최대 ${product.maxRate}%)` : "");
  return (
    <div style={{margin: '0px 0px 0px'}}>
      <div style={{position: 'relative', wordBreak: 'break-all', textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical", padding: '0px 0px 5px',}}>
        <p style={{margin: 0, lineHeight: 1.5, textOverflow: "ellipsis", WebkitBoxOrient: "vertical", color: '#666', fontWeight: 300, fontSize: '1rem'}}>{product.organization}</p>
        <p style={{margin: 0, fontSize: '1.125rem', color: secondaryBlackColor, lineHeight: 1.5, textOverflow: "ellipsis", WebkitLineClamp: "1", WebkitBoxOrient: "vertical", fontWeight: 500}}>{product.title}</p>
      </div>
      <div style={{display: 'flex', marginTop: '0.1rem', alignItems: 'flex-start'}}>
        <img src={IcMoneyCircle} alt="" width={18} height={18} style={{alignSelf: 'center', margin: '0px', marginTop: '0.1rem', marginRight: '0.4rem'}}/>
        <p style={{margin: 0, fontSize: '1rem', fontWeight: 300, lineHeight: 1, color: '#666666', paddingTop: '0.3rem', textOverflow: "ellipsis", overflow: "hidden", display: "-webkit-box", WebkitBoxOrient: "vertical", height: '1.2rem',}}> {rate} </p>
      </div>
      <div style={{display: 'flex', marginTop: '0.1rem', alignItems: 'flex-start'}}>
        <img src={IcCalendar} alt="" width={18} height={18} style={{alignSelf: 'center', margin: '0px', marginTop: '0.1rem', marginRight: '0.4rem'}}/>
        <p style={{margin: 0, fontSize: '1rem', fontWeight: 300, lineHeight: 1, color: '#666666', paddingTop: '0.3rem', textOverflow: "ellipsis", overflow: "hidden", display: "-webkit-box", WebkitBoxOrient: "vertical", height: '1.2rem',}}> 기간 {product.period} </p>
      </div>
    </div>
  )
}

export default SavingInstallmentProductCardContent;