import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Divider} from "@components/DivideBar";
import React, {ReactElement} from "react";
import IcLightBulb from "@assets/ic_lightbulb.webp";
import IcPersonCall from "@assets/ic_person_call.svg";
import {useNavigate, useParams} from "react-router-dom";
import trackEvent from "@util/trackEvent";
import {EVENT_ASK_JOB_CLICK, EVENT_JOB_HISTORY_DETAIL_PAGE_SHOW} from "@components/AppConstant";
import {useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import useOnLog from "@components/useOnLog";


const HeaderStyle: React.CSSProperties = {lineHeight: '1.5rem', padding: '12px 12px 12px 0', whiteSpace: 'nowrap', verticalAlign: 'top', borderBottom: "none", width: 'fit-content', color: '#6A6A6A', fontSize: '1rem', fontWeight: 300}


const Banner = () => {
  return <div style={{background: '#EEF9F2', display: 'flex', padding: 16, borderRadius: 5, alignItems: 'center'}}>
    <img src={IcLightBulb} alt={""} style={{height: 30, marginRight: 16}}/>
    <p style={{margin: 0, lineHeight: 1.5, whiteSpace: 'pre-wrap'}}>{"지원 현황을 기록하고\n합격축하금 10만원 받으세요!"}</p>
  </div>
}

const formatDate = (history: any) => {
  if (history.activity_recruitment_until_complete) {
    return "상시모집"
  } else if (history.activity_recruitment_end_date) {
    return history.activity_recruitment_end_date.split("-").join(". ") + '.'
  }
}

const ActivityHistoryViewTemplate = ({history, pageShowRegStr, children}: { history: any, pageShowRegStr: string, children: ReactElement }) => {
  const navigate = useNavigate();
  const {historyId} = useParams();
  const regions = useSelector((state: RootState) => state.user.regions)
  const requestHelp = () => {
    const prop = {
      "location": "ActivityHistory",
      "activity_id": history.activity_id,
      "user_regions": regions,
    }
    trackEvent(EVENT_ASK_JOB_CLICK, { ...prop });
    navigate("inquiry", { state: { activity_id: history.activity_id, number: history.activity_apply_tel, managerName: history.activity_apply_manager_name } })
  }

  useOnLog({
    pageNameKey: EVENT_JOB_HISTORY_DETAIL_PAGE_SHOW,
    amplitudeProp: {history_id: historyId, status: history.events.map((event: any) => event.status).join(",")},
    regStr: pageShowRegStr
  })

  return <>
    <DefaultPageRoot
      top={<BackNavToolbar title={"지원 현황"}/>}
      middle={<>
        <table style={{width: '100%', padding: '16px'}}>
          <tbody>
          <tr>
            <td colSpan={2}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={{flex: 1, fontSize: '1rem', margin: 0, lineHeight: '1.5rem', fontWeight: 700}}>지원 회사 및 공고</p>
                <IcPersonCall onClick={requestHelp}/>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{verticalAlign: "top", padding: '8px 0',}}>
              <span style={HeaderStyle}>회사명</span>
            </td>
            <td style={{verticalAlign: "top", padding: '8px 0',}}>
              <p style={{margin: 0, lineHeight: 1.5, fontSize: '1rem',}}>{history.activity_organization}</p>
            </td>
          </tr>
          <tr>
            <td style={{verticalAlign: "top", padding: '8px 0',}}>
              <span style={HeaderStyle}>공고제목</span>
            </td>
            <td style={{verticalAlign: "top", padding: '8px 0',}}>
              <p style={{margin: 0, lineHeight: 1.5, fontSize: '1rem'}}>{history.activity_title}</p>
            </td>
          </tr>
          <tr>
            <td style={{verticalAlign: "top", padding: '8px 0',}}>
              <span style={HeaderStyle}>마감일자</span>
            </td>
            <td style={{verticalAlign: "top", padding: '8px 0',}}>
              <p style={{margin: 0, lineHeight: 1.5, fontSize: '1rem',}}>{formatDate(history)}</p>
            </td>
          </tr>
          </tbody>
        </table>
        <Divider/>
        <div style={{padding: 16}}>
          <Banner/>
          <p style={{margin: '16px 0', fontSize: '1rem', lineHeight: '1.5rem', fontWeight: 700}}>지원 현황</p>
          {children}
        </div>
      </>}
    />
  </>
}

export default ActivityHistoryViewTemplate;