import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import Table from "@components/Table";
import SavingInstallmentProductCard from "@src/features/finance/features/saving_installment_account/component/SavingInstallmentProductCard";
import SavingInstallmentProduct from "@src/features/finance/features/saving_installment_account/model/SavingInstallmentProduct";

const SavingInstallmentProductsPageTemplate = ({title, products}: {title: string, products: SavingInstallmentProduct[] }) => {
  return <DefaultPageRoot
    top={<BackNavToolbar title={title}/>}
    middle={<>
      <div>
        <div style={{padding: '0 16px'}}>
          <Table
            title={"2024년 5월 지표"}
            data={[
              {
                'key': '기준금리',
                'value': '3.5%'
              },
              {
                'key': '물가등락',
                'value': '전년 동월 대비 +3.2%'
              },
            ]}
          />
        </div>
        <p style={{background: '#F4F4F4', borderRadius: 5, padding: 16, margin: 16, lineHeight: 1.5}}>현재 한국은행 기준금리와 물가상승지수를 확인하고 금융상품을 확인하세요</p>
        {
          products.map((product, index) => {
            return <SavingInstallmentProductCard key={index} product={product} last={index === products.length - 1}/>
          })
        }
      </div>
    </>}
  />
}

export default SavingInstallmentProductsPageTemplate;