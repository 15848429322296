import { Activity } from "@model/Activity";
import { createSlice } from "@reduxjs/toolkit";

interface ScrapState {
    value: { [id: string]: Activity }
}

const scrapSlice = createSlice({
    name: 'scrap',
    initialState: {
        value: {}
    } as ScrapState,
    reducers: {
        append: (state, action) => {
            let activity = action.payload
            state.value[activity.id] = activity
        },
        remove: (state, action) => {
            let activity = action.payload
            delete state.value[activity.id]
        },
        cleanScrap: (state) => {
            state.value = {}
        },
        setScrap: (state, action) => {
            state.value = action.payload
        },
        removeUnavailableScrapedActivity: (state, action) => {
            for (let activityId of action.payload) {
                delete state.value[activityId]
            }
        }
    }
})

export const {append, remove, cleanScrap, setScrap, removeUnavailableScrapedActivity} = scrapSlice.actions
export default scrapSlice.reducer
