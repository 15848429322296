const defaultDelay = 2000;
const defaultTries = 2;

export function fetchWithRetry(
    input: string | URL | Request,
    init: RequestInit = {},
    tries = defaultTries,
    delay = defaultDelay,
) {
    const onError: any = async (error: any)=> {
        if (tries === 0) {
            throw error
        }
        await new Promise((res) => setTimeout(res, delay));
        return await fetchWithRetry(input, init, tries - 1, delay);
    }
    return fetch(input, init)
      .then(res => {
          if (!res.ok) {
              throw Error(res.status.toString());
          }
          return res;
      })
      .catch(onError)
}