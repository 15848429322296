import {useLocation, useNavigate} from "react-router-dom";
import {DefaultTabPageRoot} from "@src/components/PageTemplate";
import {EVENT_CLICK_HOT_POST_TAB, EVENT_CLICK_POST_TAB, EVENT_CLICK_PROFILE_TAB, EVENT_CLICK_SEARCH_TAB, EVENT_JOB_PAGE_SHOW} from "@components/AppConstant";
import trackEvent from "@src/util/trackEvent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/reducer/store";
import icPostFilled from '@assets/ic_post_filled.webp';
import icPostOutlined from '@assets/ic_post_outlined.webp';
import icFrameFilled from '@assets/ic_frame_filled.webp';
import icFrameOutlined from '@assets/ic_frame_outlined.webp';
import icProfileFilled from '@assets/ic_profile_filled.webp';
import icProfileOutlined from '@assets/ic_profile_outlined.webp';
import icSearchLong from '@assets/ic_search_long.webp';
import { useOnMountOnce } from "@src/util/useOnMountOnce";
import CommunityService from "../infra/CommunityService";
import { FunctionComponent, SVGAttributes, useState } from "react";
import { setAllTimeFavoritePostIds } from "@src/reducer/communitySlice";
import { red } from "@src/components/Color";

const Tab = ({label, pathname, onClick, activeIcon, inactiveIcon, highlight = false}: { label: string, pathname: string, onClick: () => void, activeIcon?: any, inactiveIcon: any, iconSize?: number, highlight?: boolean }) => {
    const NavIconStyle = {padding: "5px",}
  return <div style={{flex: 1, textAlign: 'center'}} onClick={onClick}>
    <div style={{display: 'inline-flex'}}>
      {highlight && <div style={{position: 'absolute', width: '14px', height: '14px', borderRadius: '7px', backgroundColor: red, marginLeft: '30px', marginTop: '2px'}}/>}
      <img src={location.pathname === pathname ? activeIcon : inactiveIcon} alt="" width={'30px'} height={'30px'} style={NavIconStyle}/>
    </div>
    <p style={{margin: 0, color: '#1D1D1D', fontSize: '0.75rem'}}>{label}</p>
  </div>
}

const CommunityRootPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userId = useSelector((state: RootState) => state.user.uuid)
    const nickname = useSelector((state: RootState) => state.user.nickname)
    const [allTimeFavoritePostHighlight, setAllTimeFavoritePostHighlight] = useState(false)
    const allTimeFavoritePostIds = useSelector((state: RootState) => state.community.allTimeFavoritePostIds)
    const [updatedAllTimeFavoritePostIds, setUpdatedAllTimeFavoritePostIds] = useState<number[]>(allTimeFavoritePostIds)
    
    const onClickPost = () => {
      const nextPath = '/community'
      if (location.pathname === nextPath) { return }
      trackEvent(EVENT_CLICK_POST_TAB, undefined)
      navigate("/community")
    }

    const onClickHotPost = () => {
      const nextPath = '/community/hot-post'
      if (location.pathname === nextPath) { return }
      dispatch(setAllTimeFavoritePostIds(updatedAllTimeFavoritePostIds))
      setAllTimeFavoritePostHighlight(false)
      trackEvent(EVENT_CLICK_HOT_POST_TAB, undefined)
      navigate(nextPath)
    }

    const onClickSearch = () => {
      const nextPath = '/community/search'
      if (location.pathname === nextPath) { return }
      trackEvent(EVENT_CLICK_SEARCH_TAB, undefined)
      navigate(nextPath)
    }

    const onClickProfile = () => {
      const nextPath = '/community/profile'
      if (location.pathname === nextPath) { return }
      trackEvent(EVENT_CLICK_PROFILE_TAB, {
          'nickname_exists': nickname ? true : false
      })
      if (!nickname) {
          navigate('onboarding')
          return
      }
      navigate(nextPath)
    }

    useOnMountOnce(() => {
      CommunityService.getBestPosts().then(value => {
        const fetchedPostIds = value.all_time_favorites.map(e => e.id)
        if (JSON.stringify(fetchedPostIds) !== JSON.stringify(allTimeFavoritePostIds)) {
          setUpdatedAllTimeFavoritePostIds(fetchedPostIds)
          setAllTimeFavoritePostHighlight(true)
        }
      })

      if (nickname && userId) {
        CommunityService.syncProfile({userId: userId})
      }
    })

  return <DefaultTabPageRoot>
    <Tab label={"전체글"} pathname={"/community"} onClick={onClickPost} activeIcon={icPostFilled} inactiveIcon={icPostOutlined}/>
    <Tab label={"인기글"} pathname={"/community/hot-post"} onClick={onClickHotPost} activeIcon={icFrameFilled} inactiveIcon={icFrameOutlined} highlight={allTimeFavoritePostHighlight}/>
    <Tab label={"검색"} pathname={"/community/search"} onClick={onClickSearch} activeIcon={icSearchLong} inactiveIcon={icSearchLong}/>
    <Tab label={"프로필"} pathname={"/community/profile"} onClick={onClickProfile} activeIcon={icProfileFilled} inactiveIcon={icProfileOutlined}/>
  </DefaultTabPageRoot>

}
export default CommunityRootPage;
