const FETCH_INTERVAL = 30 * 60 * 1000;

const EVENT_INTRO_PAGE_SHOW = 'IntroPageShow'
const EVENT_LOGIN_PAGE_SHOW = 'LoginPageShow'
const EVENT_MAIN_PAGE_SHOW = "MainPageShow"
const EVENT_JOB_PAGE_SHOW = "JobPageShow"
const EVENT_REGION_SELECT_CLICK = "RegionSelectClick"
const EVENT_REGION_SELECT_DONE = "RegionSelectDone"
const EVENT_SCRAP_PAGE_SHOW = "ScrapPageShow"
const EVENT_JOB_MAIN_TAB_SHOW = "JobMainTabShow"
const EVENT_JOB_SCRAP_TAB_SHOW = "JobScrapTabShow"
const EVENT_JOB_RESUME_TAB_SHOW = "JobResumeTabShow"
const EVENT_APPLICATION_RESULT_PAGE_SHOW = "ApplicationResultPageShow"
const EVENT_MY_PAGE_SHOW = 'MyPageShow'
const EVENT_ACTIVITY_CARD_IMPRESSED = "ActivityCardImpressed"
const EVENT_INTRO_HALL_ITEM_IMPRESSED = "IntroHallItemImpressed"
const EVENT_ACTIVITY_LIST_PAGE_SHOW = "ActivityListPageShow"
const EVENT_ARTICLE_PAGE_SHOW = "ActivityPageShow"
const EVENT_ARTICLE_PAGE_SCROLL = "ActivityPageScroll"
const EVENT_SENIOR_JOB_PAGE_SHOW = "SeniorJobPageShow"
const EVENT_SENIOR_JOB_REGION_CLICK = "SeniorJobRegionClick"
const EVENT_SENIOR_JOB_DETAIL_PAGE_SHOW = "SeniorJobDetailPageShow"
const EVENT_ZOOM_IN_IMAGE_CLICK = 'ZoomInImageClick'
const EVENT_SCRAP_CLICK = "ScrapClick"
const EVENT_TAG_LIST_PAGE_SHOW = 'TagListPageShow'
const EVENT_TAG_MIDDLE_PAGE_SHOW = 'TagMiddlePageShow'
const EVENT_CATEGORY_PAGE_SHOW = 'CategoryPageShow'
const EVENT_ASK_WIZDOM_CLICK = "AskWizdomClick"
const EVENT_ASK_JOB_CLICK = "AskJobClick"
const EVENT_INQURIY_WIZDOM_CLICK = "InquiryWizdomClick"
const EVENT_APPLY_GUIDE_CLICK = "ApplyGuideClick"
const EVENT_URL_LINK_CLICK = "UrlLinkClick"
const EVENT_COPY_URL_LINK_CLICK = "CopyUrlLinkClick"
const EVENT_COPY_EMAIL_CLICK = "CopyEmailClick"
const EVENT_COPY_FAX_CLICK = "CopyFaxClick"
const EVENT_INPUT_EMAIL_PAGE_SHOW = "InputEmailPageShow"
const EVENT_INTROHALL_LIST_PAGE_SHOW = 'IntroHallListPageShow'
const EVENT_INTROHALL_NOTI_CLICK = 'IntroHallNotiClick'
const EVENT_INTROHALL_NOTI_REQUESTED = 'IntroHallNotiRequested'
const EVENT_INTROHALL_ITEM_PAGE_SHOW = 'IntroHallItemPageShow'
const EVENT_INTROHALL_ITEM_PAGE_TAP_CLICK = 'IntroHallItemPageTapClick'
const EVENT_INTROHALL_ITEM_PAGE_NOTI_CLICK = 'IntroHallItemPageNotiClick'
const EVENT_INTROHALL_ITEM_PAGE_NOTI_REQUESTED = 'IntroHallItemPageNotiRequested'
const EVENT_INTROHALL_ITEM_REVIEW_PAGE_SHOW = 'IntroHallItemReviewPageShow'
const EVENT_INTROHALL_ITEM_DESC_LINK_CLICK = 'IntroHallItemDescLinkClick'
const EVENT_SEARCHBAR_CLICK = "SearchbarClick"
const EVENT_KEYWORD_SEARCH = "KeywordSearch"
const EVENT_PUSH_KEYWORD_SEARCH = "PushKeywordSearch"
const EVENT_UPDATE_DIALOG_SHOW = "UpdateDialogShow"
const EVENT_APPLICATION_GUIDE_COPY = "ApplicationGuideCopy"
const EVENT_HELP_REQUEST_PAGE_SHOW = "HelpRequestPageShow"
const EVENT_HELP_REQUEST_FORM_PAGE_SHOW = "HelpRequestFormPageShow"
const EVENT_HELP_REQUEST_CONFIRM_PAGE_SHOW = "HelpRequestConfirmPageShow"
const EVENT_POPULAR_ACTIVITY_LIST_PAGE_SHOW = "PopularActivityListPageShow"
const EVENT_ACTIVITIES_LOADED = "ActivitiesLoaded"
const EVENT_POPULAR_ACTIVITIES_LOADED = "PopularActivitiesLoaded"
const EVENT_APPLICATION_ACTIVITIES_LOADED = "ApplicationActivitiesLoaded"
const EVENT_ANSWER_APPLICATION_QUESTION = "AnswerApplicationQuestion"
const EVENT_ANSWER_APPLICATION_PAGE_SHOW = "AnswerApplicationPageShow"
const EVENT_ANSWER_EMPLOYMENT_SUBMIT = "AnswerEmploymentSubmit"
const EVENT_ANSWER_EMPLOYMENT_PAGE_SHOW = "AnswerEmploymentPageShow"
const EVENT_HELP_REQUEST_PAGE_SCROLL = "HelpRequestPageScroll"
const EVENT_REQUEST_MORE_ACTIVITY = "RequestMoreActivity"
const EVENT_SIMPLE_APPLY_BANNER_CLICK = "SimpleApplyBannerClick"
const EVENT_SIMPLE_APPLY_ACTIVITY_LIST_CTA_CLICK = "SimpleApplyActivityListCtaClick"
const EVENT_GOV_JOB_PAGE_SHOW = "GovJobPageShow"
const EVENT_GOV_JOB_DETAIL_PAGE_SHOW = "GovJobDetailPageShow"
const EVENT_RESERVED_ACTIVITIES_LOADED = "ReservedActivitiesLoaded"
const EVENT_EMPLOYMENT_SUCCESS_PAGE_SHOW = "EmploymentSuccessPageShow"
const EVENT_EMPLOYMENT_SUCCESS_FORM_PAGE_SHOW = "EmploymentSuccessFormPageShow"
const EVENT_EMPLOYMENT_SUCCESS_SUBMIT = "EmploymentSuccessSubmit"
const EVENT_SHARE_VIEW_CLICK = "ShareViewClick" // 메인 배너, 디테일 페이지
const EVENT_SHARE_BUTTON_CLICK = "ShareButtonClick" // 카톡, 링크복사
const EVENT_INSTALL_BUTTON_CLICK = "InstallButtonClick" // 모달, 상단 고정 배너
const EVENT_INQURIY_SELECT_PAGE_SHOW = "InquirySelectPageShow"
const EVENT_APPLICATION_GUIDE_PAGE_SHOW = "ApplicationGuidePageShow"
const EVENT_REQUEST_GUIDE_KAKAO_BUTTON_CLICK = "RequestGuideKakaoButtonClick"
const EVENT_REQUEST_KAKAO_GUIDE_PAGE_SHOW = "RequestKakaoGuidePageShow"
const EVENT_REQUEST_RECRUIT_DESCRIPTION_PAGE_SHOW = "RequestRecruitDescriptionPageShow"
const EVENT_REQUEST_RECRUIT_INQUIRY_PAGE_SHOW = "RequestRecruitInquiryPageShow"
const EVENT_REQUEST_RECRUIT_SUBMIT = "RequestRecruitSubmit"
const EVENT_CALL_CLICK = "CallClick"
const EVENT_CALL_MANAGER_CLICK = "CallManagerClick"
const EVENT_LOGIN_INFORM_CONFIRM_CLICK = "LoginInformConfirmClick"
const EVENT_JOB_NEWS_PAGE_SHOW = "JobNewsPageShow"
const EVENT_JOB_NEWS_DETAIL_PAGE_SHOW = "JobNewsDetailPageShow"
const EVENT_SURVEY_INTRO_PAGE_SHOW = "SurveyIntroPageShow"
const EVENT_SURVEY_SUBMIT = "SurveySubmit"
const EVENT_SHARE_GUIDE_PAGE_SHOW = "ShareGuidePageShow"

const EVENT_PHOTO_SIGN_SUBMIT_CLICK = "PhotoSignSubmitClick"
const CUSTOM_EVENT_APP_RESUMED = "appResumed"
const CUSTOM_EVENT_APP_PAUSED = "appPaused"
const CUSTOM_EVENT_SLIDE_CHANGE = "slidechange"
const CUSTOM_EVENT_SHOWN_UPDATE_DIALOG = "shownUpdateDialog"

const CUSTOM_EVENT_MOVE_KAKAO_CHANNEL = "fromJSKakao"
const CUSTOM_EVENT_MOVE_TEL = "fromJS"

const CUSTOM_EVENT_UPDATE_INSTALL_REFERRER = "UpdateInstallReferrer"

const EVENT_REQUEST_EMAIL_CLICK = 'RequestEmailClick'
const EVENT_EMAIL_REQUESTED = 'EmailRequested'

const EVENT_GO_TO_SIMPLE_APPLY_CLICK = "GoToSimpleApplyClick"
const EVENT_GO_TO_NORMAL_APPLY_CLICK = "GoToNormalApplyClick"
const EVENT_COMPLETE_LOGIN_TO_APPLY = "CompleteLoginToApply"
const EVENT_CHANGE_PHONE_NUMBER_CLICK = "ChangePhoneNumberClick"
const EVENT_SIMPLE_APPLY_PAGE_SHOW = "SimpleApplyPageShow"
const EVENT_APPLY_PAGE_SHOW = "ApplyPageShow"
const EVENT_CREATE_RESUME_CLICK = "CreateResumeClick"
const EVENT_SAVE_TEMP_RESUME_CLICK = "saveTempResumeClick"
const EVENT_SAVE_COMPLETE_RESUME_CLICK = "saveCompleteResumeClick"
const EVENT_SIMPLE_APPLY_CLICK = "simpleApplyClick"
const EVENT_SIMPLE_APPLY_CHANGE_RESUME_CLICK = "SimpleApplyChangeResumeClick"

const EVENT_CREATE_RESUME_INTRO_DONE_CLICK = "CreateResumeIntroDoneClick"
const EVENT_CREATE_RESUME_BASIC_INFO_DONE_CLICK = "CreateResumeBasicInfoDoneClick"
const EVENT_CREATE_RESUME_EDUCATION_DONE_CLICK = "CreateResumeEducationDoneClick"
const EVENT_CREATE_RESUME_CAREER_DONE_CLICK = "CreateResumeCareerDoneClick"
const EVENT_CREATE_RESUME_HIGHEST_EDUCATION_PAGE_SHOW = "CreateResumeHighestEducationPageShow"
const EVENT_CREATE_RESUME_CHECK_CAREER_PAGE_SHOW = "CreateResumeCheckCareerPageShow"
const EVENT_CREATE_RESUME_CHECK_CERTIFICATE_PAGE_SHOW = "CreateResumeCheckCertificatePageShow"
const EVENT_CREATE_RESUME_INTRODUCTION_PAGE_SHOW = "CreateResumeIntroductionPageShow"
const EVENT_CREATE_RESUME_REGISTER_PHOTO_PAGE_SHOW = "CreateResumeRegisterPhotoPageShow"
const EVENT_CREATE_RESUME_REGISTER_PHOTO_GUIDE_PAGE_SHOW = "CreateResumeRegisterPhotoGuidePageShow"
const EVENT_CREATE_RESUME_SAVED = "CreateResumeSaved"

// 이력서 추가 이벤트
const EVENT_CREATE_RESUME_COPY_SELECT = "CreateResumeCopySelect"
const EVENT_CREATE_RESUME_NEW_CREATE_SELECTS = "CreateResumeNewCreateSelects"

// 이력서 이벤트
const EVENT_RESUME_PREVIEW = "ResumePreview"
const EVENT_RESUME_EDIT = "ResumeEdit"

const EVENT_JOB_NOTICE_SHOW = "JobNoticeShow"
const EVENT_JOB_NOTICE_SCROLL = "JobNoticeScroll"
const EVENT_JOB_NOTICE_STAY = "JobNoticeStay"

/// 2024.05.20 일자리 고도화 데이터

// 메인페이지에서 지역, 시간, 직종, 공공, 인기 공고 클릭
const EVENT_LOCATION_FILTER_CLICK = "RegionFilterClick"
const EVENT_JOB_TIME_TYPE_CLICK = "WorkingTimeTypeClick"
const EVENT_JOB_CATEGORY_CLICK = "JobCategoryClick"
const EVENT_GOVERNMENT_JOB_CLICK = "GovernmentJobClick"
const EVENT_BEST_JOB_CLICK = "BestJobClick"
const EVENT_JOB_LIST_QUERY_CHANGE = "JobListQueryChange"

// 메인 페이지에서 상세 검색을 어느 타이밍에 클릭하는지
const EVENT_SEARCH_DETAIL_SEARCH_BUTTON_CLICK = "JobDetailSearchButtonClick"

// 필터 실제 사용량
const EVENT_JOB_FILTER_PAGE_SHOW = "JobFilterPageShow"
const EVENT_JOB_FILTER_APPLY = "JobFilterApply"

// 메인 페이지 최신순/추천순 사용 빈도 확인
const EVENT_ACTIVITY_QUERY_MODE_DROPDOWN_CLICK = "ActivityQueryModeDropDownClick"
const EVENT_ACTIVITY_QUERY_MODE_CLICK = "ActivityQueryModeClick"

const KEY_ACTIVITY_CLICK_COUNT = "95d4049f-432e-4e6f-a29a-e52b4e894b3f"

// 2024.05.28 커뮤니티
export const EVENT_COMMUNITY_PAGE_SHOW = "CommunityPageShow"
export const EVENT_CLICK_HOME = "ClickHomeButton"
export const EVENT_CLICK_WRITE_POST = "ClickWritePost"
export const EVENT_WRITE_POST = "WritePost"
export const EVENT_CLICK_POST_ITEM = "ClickPostItem"
export const EVENT_CLICK_COMMENT_CONTAINER = "ClickCommentContainer"
export const EVENT_WRITE_COMMENT = "WriteComment"
export const EVENT_CLICK_EDIT_COMMENT_CTA = "EditCommentCtaClick"
export const EVENT_EDIT_COMMENT = "EditComment"
export const EVENT_CLICK_DELETE_COMMENT_CTA = "ClickDeleteCommentCta"
export const EVENT_DELETE_COMMENT = "DeleteComment"
export const EVENT_CLICK_EDIT_POST_CTA = "ClickEditPostCta"
export const EVENT_EDIT_POST = "EditPost"
export const EVENT_CLICK_DELETE_POST_CTA = "ClickDeletePostCta"
export const EVENT_DELETE_POST = "DeletePost"

export const EVENT_CLICK_POST_TAB = "ClickPostTab"
export const EVENT_CLICK_PROFILE_TAB = "ClickProfileTab"
export const EVENT_CLICK_SEARCH_TAB = "ClickSearchTab"
export const EVENT_CLICK_HOT_POST_TAB = "ClickHotPostTab"
export const EVENT_REGISTER_NICKNAME = "RegisterNickname"
export const EVENT_HOME_PAGE_SHOW = "HomePageShow"
export const EVENT_REGISTER_NICKNAME_PAGE_SHOW = "RegisterNicknamePageShow"
export const EVENT_DISCOUNT_PAGE_SHOW = "DiscountPageShow"
export const EVENT_DISCOUNT_FOR_CEO_PAGE_SHOW = "DiscountForCEOPageShow"
export const EVENT_DISCOUNT_NOTIFICATION_CTA_CLICK = "DiscountNotificationCtaClick"
export const EVENT_DISCOUNT_NOTIFICATION_SUBSCRIBE_COMPLETE = "DiscountNotificationSubscribeComplete"
export const EVENT_DISCOUNT_PROMOTION_REQUEST_CLICK = "DiscountPromotionRequestCtaClick"
export const EVENT_DISCOUNT_PROMOTION_REQUEST_COMPLETE = "DiscountPromotionRequestComplete"
export const EVENT_CLICK_COMMUNITY_CATEGORY = "ClickCommunityCategory"
export const EVENT_CLICK_POST_LIKE = "ClickPostLike"

export const EVENT_JOB_HISTORY_PAGE_SHOW = "JobHistoryPageShow"
export const EVENT_JOB_HISTORY_DETAIL_PAGE_SHOW = "JobHistoryDetailPageShow"
export const EVENT_APPLY_HISTORY_CLICK = "ApplyHistoryClick"
export const EVENT_APPLY_HISTORY_EVENT_INPUT_CLICK = "ApplyHistoryEventInputClick"
export const EVENT_APPLY_HISTORY_EVENT_OK_CLICK = "ApplyHistoryEventOkClick"

export const EVENT_FAQ_ENTRY_CLICK = "FaqEntryClick"
export const EVENT_FAQ_CTA_CLICK = "FaqCtaClick"
export const EVENT_FAQ_SUBMIT = "FaqSubmit"
export const EVENT_FAQ_CLICK = "FaqClick"

export const EVENT_HOME_ITEM_CLIK = "HomeItemClick"
export const EVENT_IMPRESSION_JOB_IN_HOME = "ImpressionJobInHome"
export const EVENT_CLICK_MORE_COMMUNITY_POST = "ClickMoreCommunityPost"
export const EVENT_CLICK_MORE_JOB_ACTIVITY = "ClickMoreJobActivity"
export const EVENT_LOAD_MORE_RECOMMEND_POST_CLICK = "LoadMoreRecommendPostClick"
export const EVENT_POST_DETAIL_PAGE_SHOW = "PostDetailPageShow"
export const EVENT_CLICK_CHANGE_NICKNAME = "ClickChangeNickname"
export const EVENT_CHANGE_NICKNAME_COMPLETE = "ChangeNicknameComplete"
export const EVENT_ONBOARDING_START_CTA_CLICK = "OnboardingStartCtaClick"
export const EVNET_POST_ITEM_IMPRESSED = "PostItemImpressed"
export const EVENT_APP_INSTLL_GUIDE_MODAL_SHOW = "AppInstallGuideModalShow"

// 글쓰기 개선
export const EVENT_POST_WRITING_CATEGORY_MODAL_CLOSE = "PostWritingCategoryModalClose"
export const EVENT_POST_WRITING_BACK_CLICK = "PostWritingBackClick"
export const EVENT_POST_WRITING_CATEGORY_SHOW = "PostWritingCategoryShow"
export const EVENT_POST_WRITING_CATEGORY_SELECT = "PostWritingCategorySelect"
export const EVENT_POST_WRITING_CATEGORY_CLOSE = "PostWritingCategoryClose"
export const EVENT_POST_WRITING_TITLE_CLICK = "PostWritingTitleClick"
export const EVENT_POST_WRITING_TITLE_INPUT = "PostWritingTitleInput"
export const EVENT_POST_WRITING_CONTENT_CLICK = "PostWritingContentClick"
export const EVENT_POST_WRITING_CONTENT_INPUT = "PostWritingContentInput"
export const EVENT_POST_WRITING_IMAGE_ADD_CLICK = "PostWritingImageAddClick"
export const EVENT_POST_WRITING_CLICK = "PostWritingSubmission"

export const EVENT_ONBOARDING_PAGE_V2_SHOW = "OnboardingPageV2Show"
export const EVENT_CLICK_EDIT_NICKNAME = "ClickEditNickname"
export const EVENT_EDIT_NICKNAME_PAGE_SHOW = "EditNicknamePageShow"
export const EVENT_EDIT_NICKNAME_COMPLETE = "EditNicknameComplete"
export const EVENT_COMMUNITY_MEMBER_GUIDE_PAGE_SHOW = "CommunityMemberGuidePageShow"
export const EVENT_SEARCH_POST_CLICK = "SearchPostClick"

export const EVENT_UPGRADE_RESUME_BANNER_IMPRESSED = "UpgradeResumeBannerImpressed"
export const EVENT_UPGRADE_RESUME_BANNER_CLICK = "UpgradeResumeBannerClick"
export const EVENT_UPGRADE_RESUME_APPLY_CLICK = "UpgradeResumeApplyClick"
export const EVENT_UPGRADE_RESUME_SUBMIT = "UpgradeResumeBannerSubmit"

export const EVENT_AGE_CALC_CLICK = "AgeCalcClick"
export const EVENT_COMMUNITY_SEARCH_PAGE_SHOW = "CommunitySearchPageShow"
export const EVENT_COMMUNITY_HOT_POST_PAGE_SHOW = "CommunityHotPostPageShow"
export const EVENT_COMMUNITY_PROFILE_PAGE_SHOW = "CommunityProfilePageShow"
export const EVENT_COMMUNITY_SEARCH_POSTS = "SearchPosts"

export const EVENT_PENSION_CALC_NEXT_CLICK = "PensionCalcNextClick"
export const EVENT_PENSION_CALC_RESET_CLICK = "PensionCalcResetClick"

export const EVENT_TAX_ITEM_CLICK = "TaxItemClick"
export const EVENT_TAX_TIPS_CLICK = "TaxTipsClick"
export const EVENT_TAX_CALC_NEXT_CLICK = "TaxCalcNextClick"
export const EVENT_TAX_CALC_RESET_CLICK = "TaxCalcResetClick"
export const EVENT_TAX_MORE_CLICK = "TaxMoreClick"
export const EVENT_TAX_CALC_CLICK = "TaxCalcClick"
export const EVENT_OTHER_TAX_MORE_CLICK = "TaxMoreClick"

export const EVENT_FINANCE_ITEM_CLICK = "FinanceItemClick"
export const EVENT_FINANCE_MORE_CLICK = "FinanceMoreClick"
export const EVENT_FINANCE_NEXT_CLICK = "FinanceNextClick"
export const EVENT_FINANCE_PRODUCT_DETAIL_PAGE_SHOW = "FinanceProductDetailPageShow"
export const EVENT_FINANCE_INTEREST_CHECK_CLICK = "FinanceInterestCheckClick"
export const EVENT_FINANCE_INTEREST_CALC_CLICK = "FinanceInterestCalcClick"
export const EVENT_ASK_FINANCE_CLICK = "AskFinanceClick"

export {
    FETCH_INTERVAL,
    EVENT_ACTIVITY_LIST_PAGE_SHOW,
    EVENT_LOCATION_FILTER_CLICK,
    EVENT_JOB_TIME_TYPE_CLICK,
    EVENT_JOB_CATEGORY_CLICK,
    EVENT_GOVERNMENT_JOB_CLICK,
    EVENT_BEST_JOB_CLICK,
    EVENT_SEARCH_DETAIL_SEARCH_BUTTON_CLICK,
    EVENT_JOB_FILTER_PAGE_SHOW,
    EVENT_JOB_FILTER_APPLY,
    EVENT_ACTIVITY_QUERY_MODE_DROPDOWN_CLICK,
    EVENT_ACTIVITY_QUERY_MODE_CLICK,
    EVENT_ACTIVITY_CARD_IMPRESSED,
    EVENT_MAIN_PAGE_SHOW,
    EVENT_ARTICLE_PAGE_SHOW,
    EVENT_ARTICLE_PAGE_SCROLL,
    EVENT_REGION_SELECT_CLICK,
    EVENT_REGION_SELECT_DONE,
    EVENT_SCRAP_CLICK,
    EVENT_SCRAP_PAGE_SHOW,
    EVENT_APPLICATION_RESULT_PAGE_SHOW,
    EVENT_ANSWER_APPLICATION_QUESTION,
    EVENT_ANSWER_APPLICATION_PAGE_SHOW,
    EVENT_ANSWER_EMPLOYMENT_SUBMIT,
    EVENT_ANSWER_EMPLOYMENT_PAGE_SHOW,
    EVENT_INTRO_PAGE_SHOW,
    EVENT_LOGIN_PAGE_SHOW,
    EVENT_MY_PAGE_SHOW,
    EVENT_ZOOM_IN_IMAGE_CLICK,
    CUSTOM_EVENT_APP_RESUMED,
    CUSTOM_EVENT_APP_PAUSED,
    CUSTOM_EVENT_SLIDE_CHANGE,
    CUSTOM_EVENT_MOVE_KAKAO_CHANNEL,
    CUSTOM_EVENT_MOVE_TEL,
    EVENT_TAG_LIST_PAGE_SHOW,
    EVENT_TAG_MIDDLE_PAGE_SHOW,
    EVENT_CATEGORY_PAGE_SHOW,
    EVENT_APPLY_GUIDE_CLICK,
    EVENT_ASK_WIZDOM_CLICK,
    EVENT_URL_LINK_CLICK,
    EVENT_COPY_URL_LINK_CLICK,
    EVENT_COPY_EMAIL_CLICK,
    EVENT_COPY_FAX_CLICK,
    EVENT_INPUT_EMAIL_PAGE_SHOW,
    EVENT_INTROHALL_LIST_PAGE_SHOW,
    EVENT_INTROHALL_NOTI_CLICK,
    EVENT_INTROHALL_NOTI_REQUESTED,
    EVENT_INTROHALL_ITEM_PAGE_SHOW,
    EVENT_INTROHALL_ITEM_PAGE_TAP_CLICK,
    EVENT_INTROHALL_ITEM_PAGE_NOTI_CLICK,
    EVENT_INTROHALL_ITEM_PAGE_NOTI_REQUESTED,
    EVENT_INTROHALL_ITEM_REVIEW_PAGE_SHOW,
    EVENT_INTRO_HALL_ITEM_IMPRESSED,
    EVENT_INTROHALL_ITEM_DESC_LINK_CLICK,
    EVENT_SEARCHBAR_CLICK,
    EVENT_KEYWORD_SEARCH,
    EVENT_UPDATE_DIALOG_SHOW,
    EVENT_APPLICATION_GUIDE_COPY,
    CUSTOM_EVENT_SHOWN_UPDATE_DIALOG,
    EVENT_ACTIVITIES_LOADED,
    EVENT_HELP_REQUEST_PAGE_SHOW,
    EVENT_HELP_REQUEST_FORM_PAGE_SHOW,
    EVENT_HELP_REQUEST_CONFIRM_PAGE_SHOW,
    EVENT_HELP_REQUEST_PAGE_SCROLL,
    EVENT_REQUEST_MORE_ACTIVITY,
    EVENT_SHARE_VIEW_CLICK,
    EVENT_SHARE_BUTTON_CLICK,
    EVENT_INSTALL_BUTTON_CLICK,
    CUSTOM_EVENT_UPDATE_INSTALL_REFERRER,
    EVENT_POPULAR_ACTIVITIES_LOADED,
    EVENT_POPULAR_ACTIVITY_LIST_PAGE_SHOW,
    EVENT_APPLICATION_ACTIVITIES_LOADED,
    EVENT_REQUEST_EMAIL_CLICK,
    EVENT_EMAIL_REQUESTED,
    EVENT_APPLICATION_GUIDE_PAGE_SHOW,
    EVENT_REQUEST_GUIDE_KAKAO_BUTTON_CLICK,
    EVENT_REQUEST_KAKAO_GUIDE_PAGE_SHOW,
    EVENT_INQURIY_SELECT_PAGE_SHOW,
    EVENT_SENIOR_JOB_PAGE_SHOW,
    EVENT_SENIOR_JOB_REGION_CLICK,
    EVENT_SENIOR_JOB_DETAIL_PAGE_SHOW,
    EVENT_GO_TO_SIMPLE_APPLY_CLICK,
    EVENT_GO_TO_NORMAL_APPLY_CLICK,
    EVENT_COMPLETE_LOGIN_TO_APPLY,
    EVENT_SIMPLE_APPLY_PAGE_SHOW,
    EVENT_CREATE_RESUME_CLICK,
    EVENT_CHANGE_PHONE_NUMBER_CLICK,
    EVENT_SAVE_TEMP_RESUME_CLICK,
    EVENT_SAVE_COMPLETE_RESUME_CLICK,
    EVENT_SIMPLE_APPLY_CLICK,
    EVENT_SIMPLE_APPLY_BANNER_CLICK,
    EVENT_SIMPLE_APPLY_ACTIVITY_LIST_CTA_CLICK,
    EVENT_GOV_JOB_PAGE_SHOW,
    EVENT_GOV_JOB_DETAIL_PAGE_SHOW,
    EVENT_REQUEST_RECRUIT_DESCRIPTION_PAGE_SHOW,
    EVENT_REQUEST_RECRUIT_INQUIRY_PAGE_SHOW,
    EVENT_REQUEST_RECRUIT_SUBMIT,
    EVENT_RESERVED_ACTIVITIES_LOADED,
    EVENT_EMPLOYMENT_SUCCESS_PAGE_SHOW,
    EVENT_EMPLOYMENT_SUCCESS_FORM_PAGE_SHOW,
    EVENT_EMPLOYMENT_SUCCESS_SUBMIT,
    EVENT_CALL_CLICK,
    EVENT_CALL_MANAGER_CLICK,
    EVENT_APPLY_PAGE_SHOW,
    EVENT_LOGIN_INFORM_CONFIRM_CLICK,
    EVENT_PUSH_KEYWORD_SEARCH,
    EVENT_JOB_NEWS_PAGE_SHOW,
    EVENT_JOB_NEWS_DETAIL_PAGE_SHOW,
    EVENT_SURVEY_INTRO_PAGE_SHOW,
    EVENT_SURVEY_SUBMIT,
    EVENT_INQURIY_WIZDOM_CLICK,
    EVENT_PHOTO_SIGN_SUBMIT_CLICK,
    EVENT_CREATE_RESUME_INTRO_DONE_CLICK,
    EVENT_CREATE_RESUME_BASIC_INFO_DONE_CLICK,
    EVENT_CREATE_RESUME_EDUCATION_DONE_CLICK,
    EVENT_CREATE_RESUME_CAREER_DONE_CLICK,
    KEY_ACTIVITY_CLICK_COUNT,
    EVENT_SHARE_GUIDE_PAGE_SHOW,
    EVENT_JOB_PAGE_SHOW,
    EVENT_JOB_MAIN_TAB_SHOW,
    EVENT_JOB_SCRAP_TAB_SHOW,
    EVENT_JOB_RESUME_TAB_SHOW,
    EVENT_JOB_LIST_QUERY_CHANGE,
    EVENT_ASK_JOB_CLICK,
    EVENT_CREATE_RESUME_HIGHEST_EDUCATION_PAGE_SHOW,
    EVENT_CREATE_RESUME_CHECK_CAREER_PAGE_SHOW,
    EVENT_CREATE_RESUME_CHECK_CERTIFICATE_PAGE_SHOW,
    EVENT_CREATE_RESUME_INTRODUCTION_PAGE_SHOW,
    EVENT_CREATE_RESUME_REGISTER_PHOTO_PAGE_SHOW,
    EVENT_CREATE_RESUME_REGISTER_PHOTO_GUIDE_PAGE_SHOW,
    EVENT_CREATE_RESUME_SAVED,
    EVENT_SIMPLE_APPLY_CHANGE_RESUME_CLICK,
    EVENT_RESUME_PREVIEW,
    EVENT_RESUME_EDIT,
    EVENT_CREATE_RESUME_COPY_SELECT,
    EVENT_CREATE_RESUME_NEW_CREATE_SELECTS,
    EVENT_JOB_NOTICE_SCROLL,
    EVENT_JOB_NOTICE_STAY,
    EVENT_JOB_NOTICE_SHOW,
};