import { createSlice } from "@reduxjs/toolkit";

interface EventState {
    [key: string]: number
}

const eventSlice = createSlice({
    name: 'event',
    initialState: {} as EventState,
    reducers: {
        increment: (state, action) => {
            if (state[action.payload.key]) {
                state[action.payload.key] += 1
            } else {
                state[action.payload.key] = 1
            }
        },
    }
})

export const {
    increment
} = eventSlice.actions
export default eventSlice.reducer