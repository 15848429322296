import { BackNavToolbar } from "@components/Toolbars";
import React, {useEffect, useState} from "react"
import { useSelector } from "react-redux";
import server_domain from "@components/RemoteServer"
import PinchZoomWrapper from "@components/PinchZoomWrapper";
import { DefaultPageRoot } from "@components/PageTemplate";
import { fetchWithRetry } from "@util/networks";
import { RootState } from "@reducer/store";
import {useParams} from "react-router-dom";
import {DialogDrawerTemplate} from "@components/DrawerTemplate";

const ResumePreview = () => {
    const {resumeId} = useParams();
    const deviceId = useSelector((state: RootState) => state.user.deviceId)
    const [title, setTitle] = useState<any>("이력서")
    const [resume, setResume] = useState<any>(null)
    const [loaded, setLoaded] = useState<any>(false)

    async function getResume() {
        let response = await fetchWithRetry(server_domain() + '/resume_image?device_id=' + deviceId + "&resume_id=" + resumeId, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        let content = await response.json()
        setTitle(content['title'])
        setResume(content['content'])
        setTimeout(() => setLoaded(true), 2000)
    }

    useEffect(() => {
        getResume()
    }, []);

    if (!loaded) {
        return <DialogDrawerTemplate show={true} onClose={() => {}}>
            <div id="spinner"></div>
            <p style={{textAlign: 'center', whiteSpace: 'pre-wrap', lineHeight: 1.5}}>{"이력서를 불러오는 중입니다.\n잠시만 기다려주세요."}</p>
            <p style={{textAlign: 'center', whiteSpace: 'pre-wrap', lineHeight: 1.5, fontSize: '0.9rem'}}>{"TIP. 이력서가 보기 좋게\n작성되었는지 확인하세요"}</p>
        </DialogDrawerTemplate>
    }

    return (
        <DefaultPageRoot
            top={<BackNavToolbar title={title}/>}
            middle={<>
                <p style={{ margin:0, background: '#F4F4F4', textAlign: 'center', padding: '10px 10px', fontSize: '1rem', lineHeight: 1.5}}>{"기업에게 전달될 이력서 모습이에요"}</p>
                <PinchZoomWrapper>
                    <div dangerouslySetInnerHTML={{ __html: resume }} style={{ transformOrigin: 'left top', margin: '0', width: '100%' }}></div>
                </PinchZoomWrapper>
            </>}
        />
    )
}

export default ResumePreview;