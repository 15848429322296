import {useBlocker, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import React, {useState} from "react";
import {EducationState, saveEducationTemp, setEducation} from "@reducer/userSlice";
import uuidv4 from "@util/uuidv4";
import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {Description, DropDown, TextField, TextFieldCheckbox, YMInput} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";

const EditResumeEducationHighSchool = () => {
  const degree = "high_school"
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const education = useSelector((state: RootState) => state.user.resumeTemp.education).find(edu => edu.degree === degree)
  const isEdit = !!education

  const [name, setName] = useState(education?.name || "")
  const [status, setStatus] = useState(education?.status || "졸업")
  const [startDate, setStartDate] = useState(education?.startDate || "")
  const [endDate, setEndDate] = useState(education?.endDate || "")
  const [showStatusModal, setShowStatusModal] = useState(false)
  const _ = useBlocker(({ currentLocation, nextLocation, historyAction }) => {
    if (showStatusModal) {
      setShowStatusModal(false)
      return true
    }
    return false
  });

  const next = () => {
    dispatch(saveEducationTemp({
        uuid: education?.uuid || uuidv4(),
        name: name.trim(),
        major: "",
        status: name === "검정고시" ? "" : status,
        startDate: startDate.trim(),
        endDate: status === "재학" || name === "검정고시" ? "" : endDate.trim(),
        degree: degree
      }
    ))
    navigate(-1);
  }

  const available = () => name !== "" && status !== "" && startDate !== "" && (status !== "재학" && endDate !== "" && endDate >= startDate || status === "재학" || name === "검정고시")

  return <DefaultPageRoot
    top={<BackNavToolbar title={""}/>}
    middle={<div style={{padding: '20px 16px 0px'}}>
      <Description text={"고등학교 정보"}/>
      <TextFieldCheckbox label={"학교명"} placeholder={"예) OO고등학교"} value={name} onChange={setName} checkedText={"검정고시"} highlight={isEdit && name === ""} showRequiredMark/>
      {name !== "검정고시" && <DropDown label={"졸업여부"} onChange={setStatus} value={status} options={["졸업", "재학", "수료", "중퇴"]} showRequiredMark showModal={showStatusModal} setShowModal={setShowStatusModal}/>}
      {name === "검정고시" && <YMInput label={"합격년월"} placeHolder={"198403"} init={startDate} onChange={setStartDate} highlight={isEdit && startDate === ""} showRequiredMark/>}
      {name !== "검정고시" && <YMInput label={"입학일"} placeHolder={"198403"} init={startDate} onChange={setStartDate} highlight={isEdit && startDate === ""} showRequiredMark/>}
      {name !== "검정고시" && ["졸업", "수료", "중퇴"].includes(status) && <YMInput label={status + "일"}  placeHolder={"198712"} init={endDate} onChange={setEndDate} highlight={isEdit && endDate === ""} showRequiredMark/>}
    </div>}
    bottom={<div style={{padding: 16}}>
      <FillButton
        active={available()}
        text={"저장"}
        onClick={next}
      />
    </div>}
  />
}

export default EditResumeEducationHighSchool;