import icWizdom from "@assets/ic_wizdom.webp";
import * as amplitude from "@amplitude/analytics-browser"
import { EVENT_INSTALL_BUTTON_CLICK } from "@components/AppConstant";
import { FillButton, PlainTextButton } from "@components/Buttons";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateReferrer } from "@reducer/userSlice";
import {isForPartners} from "@util/isForPartners";
import { RootState } from "@reducer/store";

export const GuideInstallAppModal = ({show, close}: {
    show : boolean,
    close: () => void
}) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const referrer = searchParams.get("referrer")
    const userReferrer = useSelector((state: RootState) => state.user.referrer)

    if (referrer) {
        dispatch(updateReferrer(referrer))
    }

    function openStore() {
        amplitude.track(EVENT_INSTALL_BUTTON_CLICK, { location: 'modal', referrer: referrer })
        if (userReferrer) {
            window.location.href = `https://play.google.com/store/apps/details?id=com.modex.wizdom&referrer=${encodeURIComponent(userReferrer)}`;
        } else {
            window.location.href = "https://play.google.com/store/apps/details?id=com.modex.wizdom&referrer=utm_source%3Dappshare%26utm_campaign%3Ddetailpage%26utm_content%3Dactivityshare";
        }
    }

    if (!show) return null;

    return (
        <div style={{ display: 'flex', position: 'absolute', zIndex: 2, height: '100vh', width: "100vw", maxWidth: isForPartners ? 800 : 650, background: "#1D1D1D88", alignItems: 'center', justifyContent: 'center' }} onClick={close}>
            <div style={{ borderRadius: '10px', background: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '32px 24px', margin: '16px', gap: '10px' }}>
                <img src={icWizdom} alt="" style={{ width: '50px', marginBottom: '16px' }} />
                <p style={{ margin: 0, lineHeight: 1.5, textAlign: 'center', fontSize: '1.125rem', fontWeight: 500 }}>
                    위즈덤에는<br />
                    50세이상 채용정보만 있습니다!<br />
                </p>
                <p style={{ margin: 0, lineHeight: 1.5, fontSize: '0.875rem', color: '#6B7684', textAlign: 'center' }}>
                    매일 신규 공고 500개 이상!
                </p>
                <FillButton 
                    text={"앱 설치해서 보기"} 
                    onClick={openStore} 
                    style={{borderRadius: '15px', marginTop: '24px', padding: '6px 8px'}}
                    fontStyle={{fontSize: '1rem', lineHeight: '1.5', fontWeight: 400}}
                />
                <PlainTextButton 
                    text={"설치하지 않고 보기"}
                    onClick={close}
                    fontStyle={{ fontSize: '0.875rem', color: '#999999', fontWeight: 400}}
                />
            </div>
        </div>
        
    );
}