import React, {CSSProperties, useRef} from "react";
import useAutoBlurObserver from "@components/useAutoBlurObserver";
import styles from "@src/features/job/features/apply/style/input.module.css";

const ContainerStyle: CSSProperties = {
  padding: '10px 0',
  display: 'flex',
  alignItems: 'normal',

}


const LabelStyle: CSSProperties = {
  minWidth: 100,
  width: 100,
  paddingTop: 11,
  lineHeight: 1.5,
  fontWeight: 500
}

const UnitStyle: CSSProperties = {
  paddingTop: 11,
  lineHeight: 1.5,
  fontWeight: 500,
  marginLeft: 10
}

const InputStyle: CSSProperties = {
  border: '1px solid #D9D9D9',
  borderRadius: 5,
  padding: 10,
  lineHeight: 1.5,
  fontSize: '1rem',
  boxSizing: 'border-box'
}


export type MoneyUnit = "백만원" | "원"
const KoreanMoneyUnit: {[key: string]: string[]} = {
  "백만원": ["백", "천", "억"],
  "원": ["", "만", "억",]
}
const KoreanMoneyEndUnit: {[key: string]: string[]} = {
  "백만원": ["백만원", "천만원", "억원"],
  "원": ["", "만", "억",]
}

export const Inputs = ({label, placeholder, unit, value, onChange, onFocus } : {label: string, placeholder: string, unit?: MoneyUnit, value: string, onChange: (e: string) => void, onFocus?: (top: number) => void}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const observer = useAutoBlurObserver(inputRef);
  const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(e.target.getBoundingClientRect().top);
    setTimeout(() => {if(inputRef.current) {observer.observe(inputRef.current)}}, 2000)
  }

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === " ") {
      onChange("")
    } else if (parseInt(e.target.value) === 0) {
      onChange("")
    // } else if (/^[.,]*$/.test(e.target.value)) {
    } else {
      onChange(e.target.value)
    }
  }

  const convert = () => {
    let result = []
    let temp = parseInt(value) || 0
    let lastUnitCnt = 1
    let cnt = 0
    if (unit === "백만원") {
      while (temp > 0) {
        const remain = temp % 10
        if (cnt === KoreanMoneyUnit[unit].length - 1) {
          if (lastUnitCnt) {
            result.push(KoreanMoneyEndUnit[unit][cnt])
            lastUnitCnt = 0
          } else {
            result.push(KoreanMoneyUnit[unit][cnt])
          }
          result.push(temp)
          break
        }
        if (remain !== 0) {
          if (lastUnitCnt) {
            result.push(KoreanMoneyEndUnit[unit][cnt])
            lastUnitCnt = 0
          } else {
            result.push(KoreanMoneyUnit[unit][cnt])
          }
          result.push(remain)
        } else {
        }
        temp = Math.floor(temp / 10)
        cnt += 1
      }
      if (result.length < 1) {
        result.push(KoreanMoneyEndUnit[unit][cnt])
        result.push(0)
      }
      result.reverse()
    } else if (unit === "원") {
      result.push("원")
      while (temp > 10000) {
        result.push(KoreanMoneyUnit[unit][cnt])
        result.push(temp % 10000)
        temp = Math.floor(temp / 10000)
        cnt += 1
      }
      result.push(KoreanMoneyUnit[unit][cnt])
      result.push(temp)
      result.reverse()
    } else {
      // TODO
    }
    return result
  }

  return (
    <div style={ContainerStyle}>
      <span style={LabelStyle}>{label}</span>
      <div style={{flex: 1}}>
        <div style={{display: 'flex'}}>
          <input className={styles.input} ref={inputRef} type="number" placeholder={placeholder} value={value} onChange={_onChange} style={{width: '100%', ...InputStyle, textAlign: 'right'}} onFocus={_onFocus}/>
          <span style={{whiteSpace: 'nowrap', ...UnitStyle}}>{unit || "원"}</span>
        </div>
        <p style={{margin: '4px 0 0', textAlign: 'right', lineHeight: 1.5}}>{convert().length < 1 ? "a" : convert()}</p>
      </div>
    </div>
  )
}


export const NumInput = ({label, placeholder, unit, value, onChange, onFocus, maxValue } : {label: string, placeholder: string, unit?: string, value: string, onChange: (e: string) => void, onFocus?: (top: number) => void, maxValue?: number}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const observer = useAutoBlurObserver(inputRef);
  const _onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(e.target.getBoundingClientRect().top);
    setTimeout(() => {if(inputRef.current) {observer.observe(inputRef.current)}}, 2000)
  }

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "" || e.target.value === " ") {
      onChange("")
    } else if (parseInt(e.target.value) === 0) {
      onChange("")
      // } else if (/^[.,]*$/.test(e.target.value)) {
    } else if (maxValue && parseInt(e.target.value) > maxValue) {
      onChange(maxValue.toString())
    } else {
      onChange(e.target.value)
    }
  }

  return <div style={ContainerStyle}>
    <span style={LabelStyle}>{label}</span>
    <div style={{flex: 1}}>
      <div style={{display: 'flex'}}>
        <input className={styles.input} ref={inputRef} type="number" placeholder={placeholder} value={value} onChange={_onChange} style={{width: '100%', ...InputStyle, textAlign: 'right'}} onFocus={_onFocus}/>
        <span style={{whiteSpace: 'nowrap', ...UnitStyle}}>{unit || "원"}</span>
      </div>
    </div>
  </div>
}