import SavingInstallmentProduct from "@src/features/finance/features/saving_installment_account/model/SavingInstallmentProduct";
import PrincipalInterest from "@src/features/finance/features/saving_installment_account/model/PrincipalInterest";

export const calculatePrincipalInterest = (product: SavingInstallmentProduct, amount: number, months: number): PrincipalInterest => {
  // console.log(product, amount, months)
  const result = {
    basicAfterTax: 0,
    basicInterest: 0,
    basicRate: "",
    basicTax: 0,
    maxAfterTax: 0,
    maxInterest: 0,
    maxRate: "",
    maxTax: 0,
    principalTotal: 0,
    basicInterestAfterTax: 0,
    maxInterestAfterTax: 0
  }

  if (product.type === 'saving_account') {
    result.principalTotal = amount
    if (product.interestType === 'simple') {
      result.basicInterest = amount * product.basicRate * 0.01 * months / 12;
      result.maxInterest = amount * product.maxRate * 0.01 * months / 12;
    } else {
      result.basicInterest = amount * (1 + product.basicRate * 0.01 / 12) ** months - result.principalTotal;
      result.maxInterest = amount * (1 + product.maxRate * 0.01 / 12) ** months - result.principalTotal;
    }
  } else {
    result.principalTotal = amount * months
    if (product.interestType === 'simple') {
      result.basicInterest = amount * (1 + (months * (months - 1) / 2) / 12) * product.basicRate * 0.01
      result.maxInterest = amount * (1 + (months * (months - 1) / 2) / 12) * product.maxRate * 0.01
    } else {
      result.basicInterest = [...Array(months).keys()].map((i) => i + 1).reduce((acc, month) => acc + amount * (1 + product.basicRate * 0.01 / 12) ** month, 0) - result.principalTotal
      result.maxInterest = [...Array(months).keys()].map((i) => i + 1).reduce((acc, month) => acc + amount * (1 + product.maxRate * 0.01 / 12) ** month, 0) - result.principalTotal
    }
  }
  result.basicInterest = Math.round(result.basicInterest);
  result.maxInterest = Math.round(result.maxInterest);
  result.basicTax = Math.round(result.basicInterest * 15.4 * 0.01);
  result.maxTax = Math.round(result.maxInterest * 15.4 * 0.01);
  result.basicInterestAfterTax = result.basicInterest - result.basicTax;
  result.maxInterestAfterTax = result.maxInterest - result.maxTax;
  result.basicAfterTax = result.principalTotal + result.basicInterestAfterTax;
  result.maxAfterTax = result.principalTotal + result.maxInterestAfterTax;
  result.basicRate = ((result.basicAfterTax - result.principalTotal) / result.principalTotal * 100).toFixed(2).toString();
  result.maxRate = ((result.maxAfterTax - result.principalTotal) / result.principalTotal * 100).toFixed(2).toString();
  // console.log(result)
  return result
}