import { createSlice } from "@reduxjs/toolkit";
import { PostImage } from "@src/features/community/model/Post";

const postImageSlice = createSlice({
    name: 'postImages',
    initialState: {
        images: []
    } as { images: PostImage[] 
    },
    reducers: {
        addBase64Image: (state, action) => {
            state.images.push({
                isUrl: false,
                data: action.payload
            })
        },
        addUrlImage: (state, action) => {
            state.images.push({
                isUrl: true,
                data: action.payload
            })
        },
        removeImage: (state, action) => {
            state.images = state.images.filter((item, idx) => idx !== action.payload)
        },
        clearImages: (state) => {
            state.images = []
        }
    }
})

export const {
    addBase64Image, addUrlImage, removeImage, clearImages
} = postImageSlice.actions
export default postImageSlice.reducer