import { DefaultPageRoot } from "@components/PageTemplate";
import { BackNavToolbar } from "@components/Toolbars";


const SurveyResultView = () => {
    const phoneList = ["2934", "4432", "2313", "9482", "8110", "0923", "0239", "6210", "8672", "9102"];

    return (
        <DefaultPageRoot
            top={<BackNavToolbar />}
            middle={
                <div style={{ textAlign: 'center', lineHeight: 1.5 }}>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.25rem', fontWeight: 700, marginBottom: 40 }}>{"위즈덤 설문조사\n이벤트 당첨자 발표"}</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.125rem', fontWeight: 500 }}>{"설문 응답자 중\n경품 당첨자를 안내드립니다."}</p>
                    <p style={{ whiteSpace: 'pre-wrap', fontSize: '1.125rem', fontWeight: 500, borderBottom: '1px solid black', paddingBottom: 30, margin: '0 10px 30px' }}>{"설문에 참여해주신 모든 분들에게\n다시 한번 진심으로 감사드립니다."}</p>
                    <p style={{fontSize: '1.125rem', fontWeight: 500}}>{"당첨자 목록 (10명)"}</p>                                        
                    <p style={{fontSize: '0.875rem', fontWeight: 500, color: '#999'}}>{"휴대폰 뒷자리(4자리)"}</p>                                        
                    {phoneList.map((phone, index) => (
                        <p key={index} style={{fontSize: '1.125rem', fontWeight: 500, lineHeight: 0.5}}>{phone}</p>
                    ))}
                    <p style={{fontSize: '0.875rem', fontWeight: 500, marginTop: 50, marginBottom: 50}}>{"*당첨자 개별 연락 후 상품 발송 예정"}</p>                                        
                    
                </div>
            }
        />
    )
}

export default SurveyResultView;