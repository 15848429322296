import { useNavigate } from "react-router-dom";
import { BackNavToolbar } from "@components/Toolbars";
import icGovJob from '@assets/ic_gov.webp'
import IcCarbonShareBlack from "@assets/ic_carbon_share_black.svg"
import IcContactMore from "@assets/ic_contact_more.svg"
import { useEffect, useState } from "react";
import server_domain from "@components/RemoteServer";
import useOnLog from "@components/useOnLog";
import { EVENT_GOV_JOB_PAGE_SHOW, EVENT_SHARE_VIEW_CLICK } from "@components/AppConstant";
import * as amplitude from "@amplitude/analytics-browser"
import { AppInstallGuideBanner } from "@components/AppInstallGuideBanner";
import { DefaultPageRoot } from "@components/PageTemplate";
import { fetchWithRetry } from "@util/networks";

const GovJobView = () => {
    const navigate = useNavigate();
    const [projects, setProjects] = useState<GovProjectResponse>()

    function loadProjects() {
        fetchWithRetry(server_domain() + "/projects", {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then((json: GovProjectResponse) => {
                json.projects.sort((a, b) => {
                    const k1 = json.recruiting_projects.includes(a.id) ? 0 : 1
                    const k2 = json.recruiting_projects.includes(b.id) ? 0 : 2
                    return k1 - k2
                })
                setProjects(json)
            })
    }

    useEffect(() => {
        loadProjects()
    }, [])

    useOnLog({
        pageNameKey: EVENT_GOV_JOB_PAGE_SHOW,
        regStr: "\/gov_job$"
    })

    async function askWidzom() {
        navigate({
            pathname: "inquiry_select",
        }, {
            state: {
                amplitudePageShowProp: {},
                amplitudeButtonEvenetProp: {
                    "location": "GovJobPage",
                },
                description: "궁금한 내용 모두\n위즈덤 상담사가 알려드릴게요",
                title: "문의하기"
            }
        })
    }

    function share() {
        amplitude.track(EVENT_SHARE_VIEW_CLICK, {
            location: 'GovJobPage'
        })

        navigate({
            pathname: "share",
        }, {
            state: {
                amplitude_props: {
                    location: 'GovJobPage',
                },
                title: "공유하기",
                templateId: 102468,
                kakao_share_title: "카카오톡으로 공유하기",
                link_copy_title: "링크 복사하기",
                toastText: "링크가 복사되었습니다.",
                url: `https://wizdom-73ae1.web.app/job/gov_job`,
                templateArgs: {
                }
            }
        })
    }

    function moveToGovJobPage(id: string) {
        navigate(`${id}`)
    }

    return (
        <DefaultPageRoot
            top={<>
                <AppInstallGuideBanner />
                <BackNavToolbar title={""} />
            </>}
            middle={<>
                <p style={{ margin: 0, fontSize: '1.25rem', fontWeight: 500, textAlign: 'center' }}>2024년 정부지원 일자리사업</p>

                <div style={{ display: 'flex', alignItems: 'center', margin: '24px 16px' }}>
                    <p style={{ margin: 0, flex: 1, lineHeight: 1.5, fontSize: '1.125rem', paddingLeft: '10px' }}>
                        <span style={{ display: 'block' }}>모집기간을 확인하고</span>
                        <span style={{ display: 'block' }}>미리 준비하세요!</span>
                    </p>
                    <img src={icGovJob} alt="" width={"25%"} style={{ marginRight: '15px' }} />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px 16px 0px', padding: '20px 0px 0', borderTop: '1px solid #D7D8DA' }}>
                    <div style={{ textAlign: 'center', justifyContent: 'center', margin: '8px auto' }} onClick={share}>
                        <IcCarbonShareBlack />
                        <span style={{ display: 'block', margin: '5px 0 0 0', fontSize: '1rem', fontWeight: 400, }}>공유하기</span>
                    </div>
                    <div style={{ height: '60px', width: '1px', background: '#D7D8DA' }}></div>
                    <div style={{ textAlign: 'center', justifyContent: 'center', margin: '8px auto' }} onClick={askWidzom}>
                        <IcContactMore />
                        <p style={{ lineHeight: 1.2, margin: '5px 0 0 0' }}>
                            <span style={{ display: 'block', fontSize: '1rem', fontWeight: 400, }}>문의하기</span>
                        </p>
                    </div>
                </div>
                <div style={{ margin: '20px 0 ', height: '16px', minHeight: '16px', borderTop: '1px solid #D7D8DA', background: '#F4F4F4' }}></div>
                <p style={{ whiteSpace: 'pre', margin: '20px 0', color: '#474D5B', textAlign: 'center', lineHeight: 1.5 }}>{"결원시, 모집기간 후에도\n 공고가 올라옵니다"}</p>

                {projects?.projects && projects.projects.map((item, idx) => (
                    <div key={idx} style={{ border: '1px solid #999999', borderRadius: '5px', margin: '20px 16px', padding: '16px' }} onClick={() => moveToGovJobPage(item.id)}>
                        {projects.recruiting_projects.includes(item.id) && <span style={{ display: 'inline-block', borderRadius: '5px', background: "#EEF9F2", padding: '8px 14px', color: '#0ABF53', fontSize: '0.875rem', margin: '0 0 10px 0', fontWeight: 600 }}>채용중</span>}
                        <p style={{ margin: projects.recruiting_projects.includes(item.id) ? '0 0 10px 0' : '10px 0 10px 0', fontSize: '1.125rem', fontWeight: 500 }}>{item.title}</p>
                        <table style={{ borderSpacing: '0px 10px' }}>
                            <colgroup>
                                <col width={85} />
                                <col width={15} />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: 'top', marginBottom: '10px' }}><span style={{ fontSize: '0.875rem', color: '#999' }}>모집지역</span></td>
                                    <td colSpan={2} style={{ verticalAlign: 'top' }}>{item.region}</td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: 'top' }}><span style={{ fontSize: '0.875rem', color: '#999' }}>모집기간</span></td>
                                    <td colSpan={2} style={{ verticalAlign: 'top' }}>{item.period}</td>
                                </tr>
                                {
                                    item.qualification.split("\n").map((line, index) => (
                                        <tr key={index}>
                                            {index == 0 && <td rowSpan={item.qualification.length} style={{ verticalAlign: 'top' }}><span style={{ fontSize: '0.875rem', color: '#999' }}>지원자격</span></td>}
                                            <td style={{ verticalAlign: 'top' }}>-</td>
                                            <td>{line}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div style={{ background: projects.recruiting_projects.includes(item.id) ? '#0ABF53' : "#474D5B", textAlign: 'center', padding: '10px 0', marginTop: '10px', borderRadius: '3px' }}><span style={{ color: 'white', borderRadius: '3px' }}>자세히 보기</span></div>
                    </div>
                ))}
            </>}
        />
    )
}

export default GovJobView;