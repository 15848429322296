import {ReactElement, useEffect, useState} from "react";
import IcArrowDown from "@assets/arrow_down.svg";

const ExpandableItem = ({title, children, expanded, onClick, onExpand}: {title: string, children: ReactElement | ReactElement[], expanded?: boolean, onClick?: (e: React.MouseEvent<HTMLDivElement>) => void, onExpand?: () => void}) => {
  const style = {
    transform: expanded ? 'rotate(180deg)' : '',
    transition: 'transform 150ms ease', // smooth transition
  }

  useEffect(() => {
    if (expanded && onExpand) {
      onExpand();
    }
  }, []);


  const contentStyle = {
    background: '#F4F4F4',
    borderRadius: 5,
    lineHeight: 1.5,
    marginTop: 10,
    maxHeight: expanded ? 800 : 0,
    overflow: 'hidden', // hide content when collapsed
    transition: 'max-height 0.3s', // smooth transition
  }

  return <div style={{padding: '8px 16px'}}>
    <div onClick={onClick} style={{display: 'flex'}}>
      <div style={{flex: 1}}>{title}</div>
      <IcArrowDown style={style}/>
    </div>
    <div style={contentStyle}>
      <div style={{padding: 16}}>
        {children}
      </div>
    </div>
  </div>
}

export default ExpandableItem;