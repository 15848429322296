import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {CareerCard, DeleteCareerModal, Description, DropDown, Indicator, NavigateButton, TextFieldCheckbox, YMInput} from "@components/form/ResumeComponent";
import {FillButton} from "@components/Buttons";
import React, {useMemo, useState} from "react";
import {useBlocker, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@reducer/store";
import {CareerState, removeCareer} from "@reducer/userSlice";
import {Action} from "@remix-run/router";

const checkCareer = (career: CareerState): boolean => {
  return career.name !== ""
    && career.name !== null
    && career.name !== undefined
    && career.startDate !== ""
    && career.startDate !== null
    && career.startDate !== undefined
    && (career.status !== "1" && career.endDate !== "" || career.status === "1")
    && (career.status !== "1" && career.endDate !== null || career.status === "1")
    && (career.status !== "1" && career.endDate !== undefined || career.status === "1")
    && career.status !== ""
    && career.status !== null
    && career.status !== undefined
    && career.position !== ""
    && career.position !== null
    && career.position !== undefined
    && career.description !== ""
    && career.description !== null
    && career.description !== undefined
    && career.no_career !== null
    && career.no_career !== undefined
}

const Career = () => {
  const careers = useSelector((state: RootState) => state.user.resume.career)
  const state = useLocation().state
  const step = useMemo(() => state?.step || 0, [null])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteCareerId, setDeleteCareerId] = useState("");
  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (deleteCareerId !== "") {
      setDeleteCareerId("")
      return true
    }
    return false
  });

  const available = () => {
    if (careers.length > 0) {
      for (let career of careers) {
        if (checkCareer(career)) {
        } else {
          return false
        }
      }
      return true
    } else {
      return false
    }
  }

  const next = () => navigate("../create_resume_check_certificate", {state: {step: step + 1}});

  return <>
    <DefaultPageRoot
      top={<BackNavToolbar title={""}/>}
      middle={<div style={{padding: '20px 16px 0px'}}>
        <Indicator label={"경력정보"} max={6} index={3}/>
        <Description text={"어떤 일을 하셨나요?"}/>
        <NavigateButton label={"경력 추가하기"} to={"add"}/>
        {careers.map(career => <CareerCard career={career} onEdit={() => navigate("add/" + career.uuid)} onDelete={setDeleteCareerId}/>)}
      </div>}
      bottom={<div style={{padding: 16}}>
        <FillButton
          active={available()}
          text={"다음"}
          onClick={next}
        />
      </div>}
    />
    <DeleteCareerModal id={deleteCareerId} onClose={() => setDeleteCareerId("")} onDelete={() => dispatch(removeCareer(deleteCareerId))} />
  </>
}

export default Career;