import { useNavigate } from "react-router-dom";
import {isForPartners} from "@util/isForPartners";

const SurveyModal = () => {
    const navigate = useNavigate();

    return (<div style={{ position: 'absolute', height: '100vh', width: '100vw', maxWidth: isForPartners ? 800 : 650, background: '#000000B3', zIndex: 1 }} onClick={() => navigate(-1)} >
        <div onClick={(e) => e.stopPropagation()} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', borderRadius: 5, width: "90%" }} >
            <img src="https://wizdom-73ae1.web.app/img/img_usability.webp" alt="survey" style={{verticalAlign: 'middle', width: "100%", borderRadius: '5px 5px 0 0' }} onClick={() => navigate("/survey", {replace: true})}/>
            <p style={{margin: 0, padding: 20, textAlign: 'center', fontSize: '1.25rem', fontWeight: 500, borderTop: '1px solid black'}} onClick={() => navigate("/survey", {replace: true})}>
                자세히 보기
            </p>
        </div>
    </div>
    )
}

export default SurveyModal;