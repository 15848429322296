import { CSSProperties } from "react"
import { fontSize } from "./font"
import { primary } from "./Color"

/**
 * rem: 1.25 / px 25
 * color: black
 * font-weight: Regular(400)
 */
export const RegularText_25 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <span style={{fontSize: fontSize.xl, ...style}}>{children}</span>
}

/**
 * rem: 1.125 / px 22.5
 * color: black
 * font-weight: Regular(400) 
 */
export const RegularText_22 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <span style={{fontSize: fontSize.l, ...style}}>{children}</span>
}

/**
 * rem: 1.000 / px 20
 * color: black
 * font-weight: Regular(400)
 */
export const RegularText_20 = ({onClick, style, children}: {onClick?: () => void, style?: CSSProperties, children: string | React.ReactNode}) => {
    return <span onClick={onClick} style={{fontSize: fontSize.m, ...style}}>{children}</span>
}

/**
 * rem: 0.875 / px 17.5
 * color: black
 * font-weight: Regular(400)
 */
export const RegularText_17 = ({onClick, style, children}: {onClick?: () => void, style?: CSSProperties, children: string | React.ReactNode}) => {
    return <span onClick={onClick} style={{fontSize: fontSize.s, ...style}}>{children}</span>
}

/**
 * rem: 0.750 / px 15
 * color: black
 * font-weight: Regular(400)
 */
export const RegularText_15 = ({onClick, style, children}: {onClick?: () => void, style?: CSSProperties, children: string | React.ReactNode}) => {
    return <span onClick={onClick} style={{fontSize: fontSize.xs, ...style}}>{children}</span>
}

/**
 * rem: 0.625 / px 13
 * color: black
 * font-weight: Regular(400)
 */
export const RegularText_13 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <span style={{fontSize: fontSize.xxs, ...style}}>{children}</span>
}

/**
 * rem: 0.625 / px 12.5
 * color: black
 * font-weight: Regular(400)
 */
export const RegularText_12 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <span style={{fontSize: fontSize.xxs, ...style}}>{children}</span>
}


/**
 * rem: 1.25 / px 25
 * color: black
 * font-weight: 300
 */
export const LightText_25 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_25 style={{fontWeight: 300, ...style}}>{children}</RegularText_25>
}

/**
 * rem: 1.125 / px 22.5
 * color: black
 * font-weight: 300
 */
export const LightText_22 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_22 style={{fontWeight: 300, ...style}}>{children}</RegularText_22>
}

/**
 * rem: 1.000 / px 20
 * color: black
 * font-weight: 300
 */
export const LightText_20 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_20 style={{fontWeight: 300, ...style}}>{children}</RegularText_20>
}

/**
 * rem: 0.875 / px 17.5
 * color: black
 * font-weight: 300
 */
export const LightText_17 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_17 style={{fontWeight: 300, ...style}}>{children}</RegularText_17>
}

/**
 * rem: 0.75 / px 15
 * color: black
 * font-weight: 300
 */
export const LightText_15 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_15 style={{fontWeight: 300, ...style}}>{children}</RegularText_15>
}

/**
 * rem: 0.625 / px 13
 * color: black
 * font-weight: 300
 */
export const LightText_13 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_13 style={{fontWeight: 300, ...style}}>{children}</RegularText_13>
}


/**
 * rem: 1.25 / px 25
 * color: black
 * font-weight: Medium(500)
 */
export const MediumText_25 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_25 style={{fontWeight: 500, ...style}}>{children}</RegularText_25>
}

/**
 * rem: 1.125 / px 22.5
 * color: black
 * font-weight: Medium(500)
 */
export const MediumText_22 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_22 style={{fontWeight: 500, ...style}}>{children}</RegularText_22>
}

/**
 * rem: 1.000 / px 20
 * color: black
 * font-weight: Medium(500)
 */
export const MediumText_20 = ({onClick, style, children}: {onClick?: () => void, style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_20 onClick={onClick} style={{fontWeight: 500, ...style}}>{children}</RegularText_20>
}

/**
 * rem: 0.875 / px 17.5
 * color: black
 * font-weight: Medium(500)
 */
export const MediumText_17 = ({onClick, style, children}: {onClick?: () => void, style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_17 onClick={onClick}  style={{fontWeight: 500, ...style}}>{children}</RegularText_17>
}

/**
 * rem: 0.7000 / px 15
 * color: black
 * font-weight: Medium(500)
 */
export const MediumText_15 = ({onClick, style, children}: {onClick?: () => void, style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_15 onClick={onClick} style={{fontWeight: 500, ...style}}>{children}</RegularText_15>
}


/**
 * rem: 1.25 / px 25
 * color: black
 * font-weight: Bold(600)
 */
export const BoldText_25 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_25 style={{fontWeight: 700, ...style}}>{children}</RegularText_25>
}

/**
 * rem: 1.125 / px 22.5
 * color: black
 * font-weight: Bold(600)
 */
export const BoldText_22 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_22 style={{fontWeight: 700, ...style}}>{children}</RegularText_22>
}

/**
 * rem: 1.000 / px 20
 * color: black
 * font-weight: Bold(600)
 */
export const BoldText_20 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_20 style={{fontWeight: 700, ...style}}>{children}</RegularText_20>
}

/**
 * rem: 0.875 / px 17.5
 * color: black
 * font-weight: Bold(600)
 */
export const BoldText_17 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_17 style={{fontWeight: 700, ...style}}>{children}</RegularText_17>
}

export const BoldText_15 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_15 style={{fontWeight: 700, ...style}}>{children}</RegularText_15>
}


/// 색상 적용된 폰트
/**
 * rem: 1 / px 20
 * color: green(main)
 * font-weight: Regular(400)
 */
export const RegularText_20_MainGreen = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_20 style={{color: primary, ...style}}>{children}</RegularText_20>
}
/// 색상 적용된 폰트
/**
 * rem: 1 / px 20
 * color: white
 * font-weight: Regular(400)
 */
export const RegularText_20_White = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_20 style={{color: 'white', ...style}}>{children}</RegularText_20>
}

/**
 * rem: 1.25 / px 25
 * color: black
 * font-weight: Bold(800)
 */
export const BlackText_25 = ({style, children}: {style?: CSSProperties, children: string | React.ReactNode}) => {
    return <RegularText_25 style={{fontWeight: 800, ...style}}>{children}</RegularText_25>
}
