import IcLightBulb from "@assets/ic_lightbulb.webp";
import React, {CSSProperties} from "react";

const NoticeBanner = ({title, text, style}: {title?: string, text: string, style? :CSSProperties}) => (
  <div style={{background: '#EEF9F2', display: 'flex', padding: 16, borderRadius: 5, alignItems: 'center', ...style}}>
    <img src={IcLightBulb} alt={""} style={{height: 50, marginRight: 16}}/>
    <div>
      <p style={{margin: 0, lineHeight: 1.5, whiteSpace: 'pre-wrap', fontWeight: 700}}>{title}</p>
      <p style={{margin: 0, lineHeight: 1.5, whiteSpace: 'pre-wrap'}}>{text}</p>
    </div>
  </div>
)

export default NoticeBanner;