import {DefaultPageRoot} from "@components/PageTemplate";
import {BackNavToolbar} from "@components/Toolbars";
import {FillButton, IconFillButton, SecondaryFillButton} from "@components/Buttons";
import IcShare from "@assets/ic_share_white.webp";
import React, {useState} from "react";
import {ShareBottomSheet} from "@components/ShareComponent";
import trackEvent from "@util/trackEvent";
import {EVENT_TAX_CALC_CLICK, EVENT_TAX_TIPS_CLICK} from "@components/AppConstant";
import {useBlocker, useNavigate} from "react-router-dom";
import {AppInstallGuideBanner} from "@components/AppInstallGuideBanner";
import ExpandableItem from "@components/expandable/ExpandableItem";
import ExpandableList, {ExpandableListSection} from "@components/expandable/ExpandableList";
import {secondaryColor} from "@components/styles";
import IcCalcBlack from "@assets/ic_calc_black.webp";

const PropertyTaxTips = () => {
  const navigate = useNavigate()
  const [showShareModal, setShowShareModal] = useState(false)
  const _ = useBlocker(({currentLocation, nextLocation, historyAction}) => {
    if (showShareModal) {
      setShowShareModal(false)
      return true;
    }
    return false;
  });

  const onShare = () => {
    setShowShareModal(true)
  }

  return <>
    <DefaultPageRoot
      top={<>
        <AppInstallGuideBanner/>
        <BackNavToolbar title={"재산세(주택)"}/>
      </>}
      middle={<>
        <div style={{background: secondaryColor, padding: 16, display: 'flex', alignItems: 'center'}}>
          <img src={IcCalcBlack} alt={""} style={{height: 80, marginRight: 19}}/>
          <div style={{flex: 1}}>
            <p style={{margin: '0 0 10px', lineHeight: 1.5}}>나의 재산세(주택)는?</p>
            <FillButton text={"세금 계산하기"} onClick={() => {trackEvent(EVENT_TAX_CALC_CLICK, {location: "car_property"}); navigate('calc')}}/>
          </div>
        </div>

        <ExpandableList>
          <ExpandableListSection title={"절세 팁"}>
            <ExpandableItem title={"6월1일 이후에 주택을 사거나 6월 1일 이전에 주택을 팔아요."} onExpand={() => trackEvent(EVENT_TAX_TIPS_CLICK, {type: "property_tax", title: "6월1일 이후에 주택을 사거나 6월 1일 이전에 주택을 팔아요."})}>
              <span style={{whiteSpace: 'pre-wrap'}}>{"재산세 과세기준일은 6월 1일로, 6월 1일 현재 보유한 재산에 대해 세금을 계산합니다. 따라서 주택을 살 때는 6월 2일 이후에 사고, 팔 때는 5월 31일 이전에 파는게 좋습니다.\n날짜는 잔금지급일과 등기접수일 중 빠른 날짜를 기준으로 정합니다.\n분양주택의 경우는 사용승인일 또는 잔금일 기준으로 정합니다."}</span>
            </ExpandableItem>
            <ExpandableItem title={"업무용 오피스텔에서 거주하고 있다면 주거용으로 등록해요."} onExpand={() => trackEvent(EVENT_TAX_TIPS_CLICK, {type: "property_tax", title: "업무용 오피스텔에서 거주하고 있다면 주거용으로 등록해요."})}>
              <span style={{whiteSpace: 'pre-wrap'}}>{"주거용 오피스텔로 구매했으나 업무용으로 등록되어 있을 수 있습니다. 업무용 오피스텔은 주택이 아닌 건축물로 분류되어 공시가격의 70%를 과세표준으로 계산하고 재산세율이 0.25%로 높습니다.\n재산세 고지서를 받은 후 90일 이내에 재산세변동신고서와 주거용 오피스텔 증빙서류를 구청에 제출하면 주택 재산세로 변경할 수 있습니다. 주의할 점도 있습니다.\n업무용을 주거용으로 등록하는 것은 절세가 되지만 주거용 오피스텔은 주택 1채로 간주하므로 다른 비용이 늘어나는 것(종부세와 양도세 중과, 환급부가세 반납)까지 함께 고려하여야합니다."}</span>
            </ExpandableItem>
            <ExpandableItem title={"임대주택에 등록해요."} onExpand={() => trackEvent(EVENT_TAX_TIPS_CLICK, {type: "property_tax", title: "임대주택에 등록해요."})}>
              <span>지방자치단체에 임대등록을 하면 재산세를 25~100% 감면 받습니다. 재산세는 지방세이므로 세무서에 사업자등록을 하지 않아도 됩니다. 만일 장기임대로 2채 이상 등록한 경우, 지방세특례제한법에 따라 전용면적 40㎡ 이하는 100%, 60㎡ 이하는 75%, 85㎡ 이하는 50% 재산세 감면을 받습니다.</span>
            </ExpandableItem>
            <ExpandableItem title={"주택연금에 가입해요."} onExpand={() => trackEvent(EVENT_TAX_TIPS_CLICK, {type: "property_tax", title: "주택연금에 가입해요."})}>
              <span>보유하신 주택을 주택연금에 가입하면 매년 재산세의 25%를 감면 받습니다. 주택공시가격 5억원 이하는 25% 감면, 5억원을 초과하면 5억원에 해당하는 재산세의 25%인 142,500원을 감면 받습니다.</span>
            </ExpandableItem>
            <ExpandableItem title={"내진성능을 갖추어 최대 100% 감면 받아요."} onExpand={() => trackEvent(EVENT_TAX_TIPS_CLICK, {type: "property_tax", title: "내진성능을 갖추어 최대 100% 감면 받아요."})}>
              <span>내진성능을 갖춘 주택은 재산세를 감면 받습니다. 주택의 규모와 준공 시기에 따라 다르며, 건축물 신∙증축, 개축 또는 이전의 경우 5년간 재산세 50%를 감면받고, 건축물 대수선시 5년간 재산세 100%를 면제 받습니다.</span>
            </ExpandableItem>
            <ExpandableItem title={"재산세 고지서를 철저히 확인해요."} onExpand={() => trackEvent(EVENT_TAX_TIPS_CLICK, {type: "property_tax", title: "재산세 고지서를 철저히 확인해요."})}>
              <span>많은 분들이 정부와 지자체의 세금 계산 내역을 따지지 않고 그대로 납세합니다. 하지만 경정청구라는 제도가 있듯이 정부와 지자체의 세금 계산도 잘못될 수 있습니다. 아래의 내용은 조금만 신경을 쓴다면 누구라도 확인할 수 있으니 꼭 확인하시기 바랍니다.</span>
              <ol>
                <li><span>과세표준(공시가격 x 공정시장가액비율) 산출이 정확한가?</span></li>
                <li><span>양도∙증여∙상속 재산이 있는 경우 과세기준일 6월 1일이 제대로 반영되었나?</span></li>
                <li><span>공동지분 재산의 경우 지분이 제대로 반영되었나?</span></li>
                <li><span>재산세 감면 대상(임대주택∙주택연금∙내진성능 등)이 제대로 반영되었나?</span></li>
              </ol>
            </ExpandableItem>
          </ExpandableListSection>
        </ExpandableList>
      </>}
      bottom={<div style={{padding: 16}}>
        <IconFillButton icon={<img src={IcShare} alt={""} style={{height: 30}}/>} text={"절세 팁 공유하기"} onClick={onShare}/>
      </div>}
    />
    <ShareBottomSheet
      showModal={showShareModal}
      title={"절세 팁 공유하기"}
      content={"재산세(주택)를 줄일 수 있는 방법을 주변 분들에게 많이 전파하세요."}
      onClose={() => setShowShareModal(false)}
      props={{
        kakao_share_title: "카카오톡으로 공유하기",
        link_copy_title: "공유 내용 복사하기",
        amplitude_props: {location: "property_tax"},
        templateId: 109448,
        templateArgs: {
          "title": "(무료)재산세 절세 방안을 공유합니다.",
          "description": "은퇴 후 필수 앱, 위즈덤",
          "thumbnail_url": "https://app.wizdom.co.kr/img/img_og_image.webp",
          "cta": "재산세 절세 팁 보기",
          "url": import.meta.env.VITE_URL,
          "url_path": "/save_tax/property?show_install_banner=true&show_install_guide=true&back_to_home=true&referrer=utm_source%3Dproptaxhousesaving%26utm_campaign%3Dsharing%26utm_content%3Dwizdomutil"
        },
        toastText: "복사되었습니다.\n붙여넣기 하여 공유해주세요.",
        url: "[위즈덤]\n재산세(주택) 절세 팁\n\n다가오는 7, 9월 재산세\n지자체 계산 방식 그대로 정확하게 계산하고 절세 방안까지 알려드립니다.\n\n" + import.meta.env.VITE_URL + "/save_tax/property?show_install_banner=true&show_install_guide=true&back_to_home=true&referrer=utm_source%3Dproptaxhousesaving%26utm_campaign%3Dsharing%26utm_content%3Dwizdomutil"
      }}
    />
  </>
}

export default PropertyTaxTips;