import Post, { PostCategory, PostImage } from "../model/Post";
import Comment from "../model/Comment";
import { fetchWithRetry } from "@src/util/networks";
import server_domain from "@src/components/RemoteServer";
import MyComment from "../model/MyComment";
import PostDetail from '../model/Post';
import CommunityBestPagePosts from "../model/CommunityBestPagePosts";
import { CommunityProfile, initialProfile } from "../model/CommunityProfile";
import { getLevelFromPoint } from "../model/MemberLevel";
import * as amplitude from '@amplitude/analytics-browser';
import { AddCommentResponse, AddPostResponse, LikeCommentResponse, LikePostResponse } from "./dto";

export type SetNicknameResult = "true" | "error" | "used";

class CommunityService {
    static recommendPosts: Post[] = []
    static bestPosts: CommunityBestPagePosts | null = null
    static bestPostsFetchedAt: Date | null = null
    static profile: CommunityProfile = initialProfile

    static async syncProfile({userId}: {userId: string}): Promise<void> {
        const result = await fetch(server_domain() + '/community/profile/' + userId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        this.profile = await result.json() as CommunityProfile
        const identifyEvent = new amplitude.Identify();
        identifyEvent.set('grade', getLevelFromPoint(this.profile.wizdom_value).value);
        amplitude.identify(identifyEvent);
    }

    static async setNickname({userId, nickname, deviceId}: {userId: string, nickname: string, deviceId: string | null}): Promise<SetNicknameResult> {
        try {
            const result = await fetch(server_domain() + '/user/nickname', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uuid: userId,
                    nickname: nickname,
                    device_id: deviceId,
                })
            })
            if (result.status === 409) {
                return "used"
            }
            return "true"
        } catch (e) {
            return "error"
        }
    }

    static async getRandomHotPosts(): Promise<Post[]> {
        if (this.bestPosts) {
            return this.bestPosts?.hot;    
        }
        
        return (await this.getBestPosts()).hot
    }

    static async getPosts({page, category}: {page: number, category: PostCategory}): Promise<Post[]> {
        let categoryQuery = (category && category != '전체') ? `&category=${category}` : ''
        const result = await fetchWithRetry(server_domain() + '/community/posts?page=' + page + categoryQuery, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const postDto = await result.json()
        return postDto.map((post: any) => {
            return {
                ...post,
                image_urls: post.image_urls ? post.image_urls.split(',') : null
            }
        })
    }

    static async searchPosts({search}: {search: string}): Promise<Post[]> {
        const result = await fetchWithRetry(server_domain() + '/community/posts?search=' + search, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const postDto = await result.json()
        return postDto.map((post: any) => {
            return {
                ...post,
                image_urls: post.image_urls ? post.image_urls.split(',') : null
            }
        })
    }

    static async getBestPosts(): Promise<CommunityBestPagePosts> {
        if (this.bestPostsFetchedAt != null && (new Date().getTime() - this.bestPostsFetchedAt.getTime() < 1000 * 60) && this.bestPosts) {
            return {
                ...this.bestPosts,
                hot: this.bestPosts.hot
                    .sort((a, b) => Math.random() - 0.5)
                    .slice(0, 2)
            } 
        }
        this.bestPostsFetchedAt = new Date()
        this.bestPosts = null

        const result = await fetchWithRetry(server_domain() + '/community/best-posts', {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const postDto = await result.json()
        const hotPosts = 
            ((postDto.hot as any[]) ?? [])
            .map((post: any) => {
                return {
                    ...post,
                    image_urls: post.image_urls ? post.image_urls.split(',') : null
                }
            })
        
        const allTimeFavoritesPosts = 
            ((postDto.all_time_favorites as any[]) ?? [])
            .map((post: any) => {
                return {
                    ...post,
                    image_urls: post.image_urls ? post.image_urls.split(',') : null
                }
            })
        
        this.bestPosts = {
            hot: hotPosts,
            all_time_favorites: allTimeFavoritesPosts
        }
        
        return {
            hot: hotPosts
                .sort((a, b) => Math.random() - 0.5)
                .slice(0, 2),
            all_time_favorites: allTimeFavoritesPosts
        }
    }

    static async getRecommendPosts({depth}: {depth: number}): Promise<Post[]> {
        if (this.recommendPosts.length == 0) {
            const result = await fetchWithRetry(server_domain() + '/community/recommend-posts', {
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            const postDto = await result.json()
            const posts = postDto.map((post: any) => {
                return {
                    ...post,
                    image_urls: post.image_urls ? post.image_urls.split(',') : null
                }
            })
            this.recommendPosts = posts
        }
        if (depth === 0) {
            this.recommendPosts = this.recommendPosts.sort((a, b) => Math.random() - 0.5)
        }
        depth = depth % (this.recommendPosts.length / 4)
        return this.recommendPosts.slice(4 * depth, 4 * (depth + 1))
    }

    static async getPostsByUserId({user_id}: {user_id: string}): Promise<Post[]> {
        const result = await fetchWithRetry(server_domain() + '/community/posts/user/' + user_id, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const posts = await result.json()
        return posts.map((post: any) => {
            return {
                ...post,
                image_urls: post.image_urls ? post.image_urls.split(',') : null
            }
        })
    }

    static async getPostById(id: number): Promise<PostDetail> {
        const result = await fetchWithRetry(server_domain() + `/community/post/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const post = await result.json()
        return {
            ...post,
            image_urls: post.image_urls ? post.image_urls.split(',') : null
        }
    }

    static async getCommentsByPostId(postId: number): Promise<Comment[]> {
        const result = await fetchWithRetry(server_domain() + `/community/post/${postId}/comments`, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        return await result.json()
    }

    static async getCommentsByUserId({user_id}: {user_id: string}): Promise<MyComment[]> {
        const result = await fetchWithRetry(server_domain() + '/community/comments/user/' + user_id, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        return await result.json()
    }

    static async addComment(
        {userId, postTitle, writerUserId, nickname, content, postId}: 
        {userId: string, postTitle: string, writerUserId: string, nickname: string, content: string, postId: number}
    ): Promise<{comment: Comment, levelUp: number | null}> {
        const result = await fetchWithRetry(server_domain() + `/community/post/${postId}/comment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: userId,
                post_title: postTitle,
                writer_user_id: writerUserId,
                nickname: nickname,
                content: content,
                created_at: null, // for: admin
            })
        })
        const response = (await result.json() as AddCommentResponse)
        if (response.wizdom_value) {
            this.profile.wizdom_value = response.wizdom_value
        }
        return {
            comment: {
                id: response.id,
                user_id: userId,
                nickname: nickname,
                post_id: postId,
                content: content,
                created_at: new Date().toISOString(),
                likes: [],
                wizdom_value: this.profile.wizdom_value,
            },
            levelUp: response.level_up
        }
    }

    static async addPost({userId, title, nickname, content, images, category}: {userId: string, nickname: string, title: string, content: string, images?: PostImage[], category: PostCategory}): Promise<AddPostResponse> {
        const result = await fetchWithRetry(server_domain() + '/community/post', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: userId,
                nickname: nickname,
                title: title,
                content: content,
                images: images?.map(e => e.data),
                category: category,
                created_at: null, // for: admin
            })
        })
        const response = await result.json() 
        if (response.wizdom_value) {
            this.profile.wizdom_value = response.wizdom_value
        }
        return response
    }

    static async updatePost({postId, title, content, images, category}: {postId: number, title: string, content: string, images?: PostImage[], category: PostCategory}): Promise<boolean> {
        const result = await fetchWithRetry(server_domain() + '/community/post/' + postId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title: title,
                content: content,
                images: images?.map((e) => {
                    return { is_url: e.isUrl, data: e.data}
                }),
                category: category,
            })
        })
        return result.ok
    }

    static async updateComment({commentId, content}: {commentId: number, content: string}): Promise<boolean> {
        const result = await fetchWithRetry(server_domain() + '/community/comment/' + commentId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                content: content
            })
        })
        return result.ok
    }

    static async deletePostById(id: number): Promise<boolean> {
        const result = await fetchWithRetry(server_domain() + '/community/post/' + id , {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        return result.ok
    }

    static async deleteCommentById({id, postId}:{id: number, postId: number}): Promise<boolean> {
        const result = await fetchWithRetry(server_domain() + '/community/post/'+ postId + '/comment/' + id , {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        return result.ok
    }

    static async getLikeUsersByPostId(postId: number): Promise<string[]> {
        const result = await fetchWithRetry(server_domain() + '/community/post/' + postId + '/like-users', {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        return (await result.json() as any[]).map((e: any) => e.user_id)
    }

    static async likePostById({id, userId, writerUserId, postTitle, likeUserNickname} :{id: number, userId: string, writerUserId: string, postTitle: string, likeUserNickname: string}): Promise<LikePostResponse> {
        const result = await fetchWithRetry(server_domain() + '/community/post/' + id  + '/like', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: userId,
                post_title: postTitle,
                writer_user_id: writerUserId,
                like_user_nickname: likeUserNickname
            })
        })
        const response =  await result.json()
        if (response.wizdom_value) {
            this.profile.wizdom_value = response.wizdom_value
        }
        return response
    }

    static async deleteLikePostById({id, userId} :{id: number, userId: string}): Promise<boolean> {
        const result = await fetchWithRetry(server_domain() + '/community/post/' + id  + '/like', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: userId
            })
        })
        return result.ok
    }

    static async getAutoGeneratedNickname(): Promise<string> {
        const result = await fetchWithRetry(server_domain() + '/community/user/generated-nickname', {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        return await result.json()
    }

    static async likeCommentById({id, userId, content, nickname, postId, writerUserId } :{id: number, userId: string, content: string, nickname: string, postId: string, writerUserId: string}): Promise<LikeCommentResponse> {
        const result = await fetchWithRetry(server_domain() + `/community/post/${postId}/comment/${id}/like`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: userId,
                content: content,
                nickname: nickname,
                comment_writer_id: writerUserId
            })
        })
        const response = await result.json()
        if (response.wizdom_value) {
            this.profile.wizdom_value = response.wizdom_value
        }
        return response
    }

    static async cancelLikeCommentById({id, userId, postId } :{id: number, userId: string, postId: string }): Promise<boolean> {
        const result = await fetchWithRetry(server_domain() + `/community/post/${postId}/comment/${id}/like`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: userId,
            })
        })
        return result.ok
    }

    static async getProfile({userId}: {userId: string}): Promise<CommunityProfile> {
        const result = await fetchWithRetry(server_domain() + '/community/profile/' + userId, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        return await result.json()
    }
}

export default CommunityService;
