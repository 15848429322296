import { BackNavToolbar } from "@src/components/Toolbars";
import { DefaultPageRoot } from "@src/components/PageTemplate";
import { BoldText_15, BoldText_17, BoldText_20, BoldText_22, LightText_17, MediumText_15, MediumText_17, MediumText_20, RegularText_15, RegularText_17, RegularText_20 } from "@src/components/text";
import Post from "../model/Post";
import Comment from "../model/Comment";
import { grey999, greyD7, greyF4, inactive, primary, secondaryBlack } from "@src/components/Color";
import { useBlocker, useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import icComment from "@assets/ic_comment.webp";
import IcHeart from "@assets/ic_heart.svg";
import IcHeartActive from "@assets/ic_heart_active.svg";
import icShareLight from "@assets/ic_share_light.webp";
import { FillButton, PlainTextButton } from "@src/components/Buttons";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { fontSize } from "@src/components/font";
import CommunityService from "../infra/CommunityService";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "@src/reducer/store";
import { convertToHangul } from "@src/util/convertDatetime";
import trackEvent from "@src/util/trackEvent";
import { EVENT_CLICK_COMMENT_CONTAINER, EVENT_CLICK_DELETE_COMMENT_CTA, EVENT_CLICK_DELETE_POST_CTA, EVENT_CLICK_EDIT_COMMENT_CTA, EVENT_CLICK_EDIT_POST_CTA, EVENT_CLICK_POST_LIKE, EVENT_DELETE_COMMENT, EVENT_DELETE_POST, EVENT_EDIT_COMMENT, EVENT_LOAD_MORE_RECOMMEND_POST_CLICK, EVENT_POST_DETAIL_PAGE_SHOW, EVENT_SHARE_VIEW_CLICK, EVENT_WRITE_COMMENT } from "@src/components/AppConstant";
import { PostCateogryTag } from "./component/PostCateogryTag";
import IModal from "@src/components/IModal";
import { show } from "@src/reducer/snackbarSlice";
import { useOnMountOnce } from "@src/util/useOnMountOnce";
import IcReset from "@src/assets/ic_reset.svg";
import PostDetail from '../model/Post';
import { RecommendPostItem } from "./component/PostItem";
import useOnLog from "@src/components/useOnLog";
import { AppInstallGuideBanner } from "@src/components/AppInstallGuideBanner";
import isMobile from "@src/util/isMobile";
import linkify from "@src/util/linkify";
import icHeartFilled from "@src/assets/ic_heart_filled.webp"
import { useQueryClient } from "react-query";
import MemberLevelTag from "./component/MemberLevelTag";
import { getLevelFromPoint, getLevelValueFromPoint } from "../model/MemberLevel";

const NoCommentView = () => {
    return (
        <div style={{display: 'flex', flexDirection:'column', height: '200px', justifyContent: 'center', alignItems: 'center', lineHeight: '1.5'}}>
            <RegularText_20 style={{color: grey999}}>등록된 댓글이 없습니다</RegularText_20>
            <RegularText_20 style={{color: grey999}}>첫 댓글을 남겨주세요</RegularText_20>
        </div>
    )
}

const CommentItem = (
    {idx, comment, isWriter, viewerId, isOwner, totalLength, onClickLike, onCancelLike, onClickEdit, onClickDelete}:
    {idx: number, comment: Comment, viewerId: string | null, isWriter: boolean, isOwner: boolean, totalLength: number, onClickLike: () => void, onCancelLike: () => void, onClickEdit: () => void, onClickDelete: () => void}
) => {
    const isLikeUser = comment.likes.filter(e => e.user_id == viewerId).length > 0

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', gap: '4px', padding: '20px 15px'}}>
                <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                    <BoldText_17>{comment.nickname}</BoldText_17>
                    <MemberLevelTag level={getLevelValueFromPoint(comment.wizdom_value)} size="17px" fontSize="10px" borderRadius="2px"/>
                    { isWriter && <BoldText_15 style={{color: secondaryBlack, backgroundColor: greyF4, borderRadius: '5px', padding: '2px 6px', marginLeft: '2px'}}>글쓴이</BoldText_15>}
                </div>
                <RegularText_20 style={{display: 'block', whiteSpace: 'pre-wrap', wordBreak: 'break-all', lineHeight: '1.5'}}>{comment.content}</RegularText_20>
                <div style={{display: 'flex', gap: '24px', alignItems: 'center'}}>
                    <MediumText_15 style={{color: grey999, marginRight: '2px'}}>{convertToHangul(comment.created_at)}</MediumText_15>
                    <div
                        onClick={isLikeUser ? onCancelLike : onClickLike} 
                        style={{display: 'flex', 'gap': '4px', alignItems: 'center'}}>
                        {isLikeUser ? <IcHeartActive width='20px' height='20px' /> : <IcHeart width='20px' height='20px' color={grey999}/> }
                        <MediumText_15 style={{color: isLikeUser ? primary : grey999}}>좋아요</MediumText_15>
                        {comment.likes.length != 0 ? <MediumText_15 style={{color: isLikeUser ? primary : grey999}}>{comment.likes.length.toString()}</MediumText_15> : null }
                    </div>
                    {isOwner ? <MediumText_15 onClick={onClickEdit} style={{color: grey999}}>수정</MediumText_15> : null}
                    {isOwner ? <MediumText_15 onClick={onClickDelete} style={{color: grey999}}>삭제</MediumText_15>: null}
                </div>
            </div>
            {(idx < totalLength-1 ) && <div style={{height: '1px', backgroundColor: greyF4}} />}
        </>
    )
}

const PhotoItem = ({photoUrl}: {photoUrl: string}) => {
    return (
        <img src={photoUrl} width='100%' style={{borderRadius: '5px'}}></img>
    )
}

const PostDetailPage = () => {
    const context = useOutletContext() as { onDeletePost?: (postId: number) => void } | undefined;
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const commentContainerRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const uuid = useSelector((state: RootState) => state.user.uuid)
    const nickname = useSelector((state: RootState) => state.user.nickname)
    const [post, setPost] = useState<PostDetail | null>(null)
    const [recommendPosts, setRecommendPost] = useState<Post[]>([])
    const { postId } = useParams() as { postId: string };
    const [addCommentNotifier, setAddCommentNotifier] = useState<number>(0)
    const [commentInput, setCommentInput] = useState('')
    const dispatch = useDispatch()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showCommentDeleteModal, setShowCommentDeleteModal] = useState(false)
    const [deleteCommentId, setDeleteCommentId] = useState<number | null>(null)
    const [updateTargetComment, setUpdateTargetComment] = useState<Comment | null>(null)
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const event = searchParams.get("event")
    const [loadMoreRecommendPostsDepth, setLoadMoreRecommendPostsDepth] = useState(0)
    const scrollRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLSpanElement>(null)
    const showInstallBanner = searchParams.get("show_install_banner") === "true"

    const commentRefs = post?.comments.reduce((acc, value) => {
        acc[value.id] = createRef();
        return acc;
    }, {} as Record<number, React.RefObject<HTMLDivElement>>);

    const handleResizeHeight = useCallback(() => {
        if (inputRef.current == null) { return }
        if (inputRef.current.value === '') {
            inputRef.current.style.height = 'auto';
            return
        }
        const previousHeight = inputRef.current.style.height;
        const nextHeight = inputRef.current.scrollHeight + 'px';
        if (Math.abs(parseInt(previousHeight) - parseInt(nextHeight)) < 5) { return }
        if (parseInt(nextHeight) > 100) { 
            if (updateTargetComment != null && updateTargetComment.content == commentInput) {
                inputRef.current.style.height = 'auto';
                inputRef.current.style.height = "100px";
                inputRef.current?.scrollTo(0, inputRef.current.scrollHeight)
            }
            return 
        }
        inputRef.current.style.height = 'auto';
        inputRef.current.style.height = nextHeight;
    }, [updateTargetComment, commentInput]);
    const onCommentSubmit = () => {
        if (commentInput.length === 0 || !uuid) {
            return
        }
        
        if (!nickname) {
            navigate('set_nickname_guide_modal')
            return
        }
        
        const comment = commentInput
        setCommentInput('')
        inputRef.current?.blur()
        if (inputRef.current) {
            inputRef.current.style.height = 'auto'
        }
        CommunityService.addComment({userId: uuid, postTitle: post!.title, writerUserId: post!.user_id, nickname: nickname, content: comment, postId: Number(postId)}).then((response) => {
            setPost({...post!, comments: [...post!.comments, response.comment]})
            setAddCommentNotifier(post!.comments.length + 1)
            if (response.levelUp) {
                showLevelUp(response.levelUp)
            }
        })
        updateCachedCommentCount(1)
        trackEvent(EVENT_WRITE_COMMENT, { 'post_id': postId })
    }

    const onCommentUpdate = async () => {
        if (commentInput.length === 0 || !uuid || updateTargetComment == null || !post) {
            return
        }
        
        const comment = commentInput
        setCommentInput('')
        inputRef.current?.blur()
        if (inputRef.current) {
            inputRef.current.style.height = 'auto'
        }
        const result = await CommunityService.updateComment({commentId: updateTargetComment.id, content: comment})
        if (result) {
            setPost({
                ...post,
                comments: [...post.comments.map((e) => {
                    if (e.id == updateTargetComment.id) {
                        return {
                            ...e,
                            content: comment
                        }
                    } else {
                        return e
                    }
                })]
            })
            trackEvent(EVENT_EDIT_COMMENT, { 'post_id': postId, 'comment_id': updateTargetComment.id })
            setUpdateTargetComment(null)
        } else {
            dispatch(show({message: '댓글 수정에 실패했습니다.'}))
        }
        
    }

    useEffect(() => {
        if (!location.pathname.endsWith(`/post/${postId}`)) return

        CommunityService.getPostById(Number(postId)).then((post) => {
            setPost(post)
            setTimeout(() => {
                scrollRef.current?.scrollTo({top: 0, behavior: 'auto'})
            }, 100)
        })
        loadRecommendedPosts(0)
    }, [location.pathname])

    useEffect(() => {
        if (contentRef.current == null) return
        if (post == null) return

        contentRef.current.innerHTML = linkify(post.content)
    }, [post, contentRef])

    const loadRecommendedPosts = (depth: number) => {
        CommunityService.getRecommendPosts({depth: depth}).then((posts) => {
            setRecommendPost(posts)
        })
    }

    useOnLog({
        pageNameKey: EVENT_POST_DETAIL_PAGE_SHOW,
        amplitudeProp: {
            post_id: postId
        },
        regStr: "\/post\/[^/]+$"
      })

    useEffect(() => {
        setTimeout(() => {
            if (addCommentNotifier == 0) return
            const comment = post?.comments[post.comments.length - 1]
            if (comment == null) return
            const commentRef = commentRefs?.[comment.id]
            if (commentRef?.current == null) return
            commentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 400)
    }, [addCommentNotifier])

    useEffect(() => {
        handleResizeHeight()
    }, [commentInput])

    useOnMountOnce(() => {
        if (event) {
            trackEvent(event, { 'post_id': postId })
        }

        if (!window.flutter_inappwebview && isMobile() && showInstallBanner) {
            navigate('app_install_guide')
        }
    })
    
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => {
            if (blocker.state == 'blocked') {
                return false
            }

            if (showDeleteModal) {
                setShowDeleteModal(false)
                return true
            }

            if (showCommentDeleteModal) {
                setShowCommentDeleteModal(false)
                return true
            }
            return false
        }
    );

    const onClickCommentContainer = () => {
        trackEvent(EVENT_CLICK_COMMENT_CONTAINER, { 'post_id': postId, 'nickname_exists': nickname ? true : false })
        if (!nickname) {
            navigate('set_nickname_guide_modal')
            return
        }
    }

    const onClickShare = () => {
        if (!post) { return; }
        const postContent = post.content.length > 30 ? post.content.slice(0, 30).replaceAll('\n', ' ') : post.content.replaceAll('\n', ' ')
        trackEvent(EVENT_SHARE_VIEW_CLICK, {
            location: 'PostDetailPage',
            postId: postId
        })
        navigate({
            pathname: "share"
        }, {
            state: {
                amplitude_props: {
                    location: 'PostDetailPage',
                    post_id: postId
                },
                title: '게시글 공유하기',
                templateId: 108511,
                kakao_share_title: '카카오톡으로 공유하기',
                link_copy_title: "링크 복사하기",
                toastText: "게시글이 복사되었습니다.\n붙여넣기 하여 공유해주세요.",
                url: `위즈덤에 올라온 글이에요\n\n${post.title}\n${postContent + (postContent.length > 30 ? '...' : '')}\n\n${import.meta.env.VITE_URL}/community/post/${postId}?back_to=/community&show_install_banner=true&referrer=utm_source%3Dappshare%26utm_campaign%3Dpostdetailpage%26utm_content%3D${postId}`,
                templateArgs: {
                    "title": post.title,
                    "description": postContent.slice(0, 20) + (postContent.length > 20 ? '...' : ''),
                    "thumbnail_url": post.image_urls && post.image_urls?.length > 0 ? post.image_urls[0] : "https://app.wizdom.co.kr/img/img_og_image.webp",
                    "cta": "자세히 보기",
                    "url": import.meta.env.VITE_URL,
                    "url_path": `community/post/${postId}?back_to=/community&show_install_banner=true&referrer=utm_source%3Dappshare%26utm_campaign%3Dpostdetailpage%26utm_content%3D${postId}`
                }
            }
        })
    }

    const onClickEdit = () => {
        trackEvent(EVENT_CLICK_EDIT_POST_CTA, { 'post_id': postId })
        navigate('edit', {
            state: {
                post: post
            },
        })
    }

    const onClickDelete = () => {
        trackEvent(EVENT_CLICK_DELETE_POST_CTA, { 'post_id': postId })
        setShowDeleteModal(true)
    }

    const deletePost = async () => {
        const result = await CommunityService.deletePostById(Number(postId))
        if (result) {
            context?.onDeletePost?.(Number(postId))
            queryClient.removeQueries(`posts-전체`)
            queryClient.removeQueries(`posts-${post!.category}`)
            trackEvent(EVENT_DELETE_POST, { 'post_id': postId })
            navigate(-1)
        } else {
            dispatch(show({message: '글 삭제에 실패했습니다.'}))
        }
    }

    const deleteComment = async () => {
        if (!post) { return }
        const result = await CommunityService.deleteCommentById({id: Number(deleteCommentId), postId: Number(postId)})
        if (result) {
            trackEvent(EVENT_DELETE_COMMENT, { 'post_id': postId, 'comment_id': deleteCommentId })
            setPost({
                ...post,
                comments: post.comments.filter(e => e.id != deleteCommentId)
            })
            setDeleteCommentId(null)
            updateCachedCommentCount(-1)
        } else {
            dispatch(show({message: '댓글 삭제에 실패했습니다.'}))
        }
    }

    const onClickLike = () => {
        if (!uuid || !post) return;

        const likeUsers = post!.likes.map(e => e.user_id)
        trackEvent(EVENT_CLICK_POST_LIKE, { "value": likeUsers.includes(uuid) ? 'false' : 'true', "post_id": postId, 'nickname_exists': nickname ? true : false})
        if (!nickname) {
            navigate('set_nickname_guide_modal')
            return
        }
        if (likeUsers.includes(uuid)) {
            CommunityService.deleteLikePostById({userId: uuid, id: Number(postId)})    
            setPost({
                ...post,
                likes: post.likes.filter(e => e.user_id != uuid)
            })
        } else {
            CommunityService.likePostById({userId: uuid, id: Number(postId), writerUserId: post!.user_id, postTitle: post!.title, likeUserNickname: nickname})
                .then((res) => {
                    if (res.level_up) {
                        showLevelUp(res.level_up)
                    }
                })
            setPost({
                ...post,
                likes: [...post.likes, {id: 0, user_id: uuid, nickname: nickname}]
            })
        }
        updateCachedLikeCount()
    }

    const updateCachedLikeCount = () => {
        if (!post || !uuid) return
        const likeUsers = post.likes.map(e => e.user_id)
        queryClient.setQueryData(['posts-전체'], (oldData: any) => {
            const pages = oldData.pages.map((e: any) => {
                return e.map((post: Post) => {
                    if (post.id == Number(postId)) {
                        return {
                            ...post,
                            like_count: post.like_count + (likeUsers.includes(uuid) ? -1 : 1)
                        }
                    }
                    return post
                })
            })
            return {
                pages: pages,
                pageParams: oldData.pageParams
            }
        })
    }

    const updateCachedCommentCount = (value: number) => {
        if (!post || !uuid) return
        queryClient.setQueryData(['posts-전체'], (oldData: any) => {
            const pages = oldData.pages.map((e: any) => {
                return e.map((post: Post) => {
                    if (post.id == Number(postId)) {
                        return {
                            ...post,
                            comment_count: post.comment_count + (value)
                        }
                    }
                    return post
                })
            })
            return {
                pages: pages,
                pageParams: oldData.pageParams
            }
        })
    }

    const showLevelUp = (level: number) => {
        navigate('member-level-up', { state: { level: level } })
    }

    return (
        <DefaultPageRoot
            top={
                <>
                    {showInstallBanner && <AppInstallGuideBanner />}
                    <BackNavToolbar
                        title={''}
                        trailing={ uuid === post?.user_id ?
                        <div style={{display: 'flex', gap: '24px'}}>
                            <MediumText_20 onClick={onClickEdit} style={{color: secondaryBlack}}>수정</MediumText_20>
                            <MediumText_20 onClick={onClickDelete} style={{color: secondaryBlack}}>삭제</MediumText_20>
                        </div>
                        : undefined
                        }/> 
                </>

            }
            middleRef={scrollRef}
            middle={
                post &&
                <div>
                    <div style={{display: 'flex', flexDirection: 'column', padding: '20px 15px', width: 'calc(100% - 30px)'}}>
                        {/* title은 component 사용하면 개행이 어색해짐 */}
                        {
                            post.category ? <PostCateogryTag category={post.category}/> : null
                        }
                        <BoldText_22 style={{overflowWrap: 'break-word', lineHeight: '1.5', display: 'block', whiteSpace: 'pre-wrap', marginTop: '12px'}}>{post.title}</BoldText_22>
                        <div style={{ paddingTop: '15px', display: 'inline-flex', gap: '4px', alignItems: 'center'}}>
                            <RegularText_17 style={{color: secondaryBlack}}>{post.nickname}</RegularText_17>
                            <MemberLevelTag level={getLevelValueFromPoint(post.wizdom_value)} size="17px" fontSize="10px" borderRadius="2px" style={{marginLeft: '1px'}}/>
                            <RegularText_17 style={{color: grey999}}>∙</RegularText_17>
                            <RegularText_17 style={{color: grey999}}>{convertToHangul(post.created_at)}</RegularText_17>
                        </div>
                    </div>
                    <div style={{height: '1px', backgroundColor: '#D7D8DA', margin: '0px 15px'}}></div>
                    <div style={{padding: '20px 15px 60px 15px'}}>
                        <span ref={contentRef} style={{lineHeight: '1.5', display: 'block', whiteSpace: 'pre-wrap'}}></span>
                    </div>
                    {
                        post.image_urls && post.image_urls.length > 0 &&
                        <div style={{paddingLeft: '15px', paddingRight: '15px', paddingBottom: '45px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '15px', width: 'calc(100% - 30px)'}}>
                            {
                                post.image_urls.map((image) => {
                                    return <PhotoItem key={image} photoUrl={image}/>
                                })
                            }
                        </div>
                    }
                    {
                        post.likes.length != 0 && <div style={{display: 'inline-flex', alignItems: 'center', marginLeft: '13px', marginBottom: '10px', color: secondaryBlack}}>
                            <img src={icHeartFilled} width='24px' height='24px'/>
                            <MediumText_17 style={{marginLeft: '3px'}}>{post.likes[post.likes.length -1].nickname}님</MediumText_17>
                            { post.likes.length > 1 &&
                                <>
                                    <LightText_17 style={{marginLeft: '3px'}}>외</LightText_17>
                                    <MediumText_17 style={{marginLeft: '3px'}}>{post.likes.length -1}명</MediumText_17>
                                </>
                            }
                            <LightText_17>이 좋아합니다</LightText_17>
                        </div>
                    }
                    <div style={{display:'flex', borderTop: 'solid 1px #D7D8DA', borderBottom: 'solid 1px #D7D8DA', width: '100%', gap: '5px', paddingTop: '12px', paddingBottom: '13px', alignItems: 'center', justifyContent: 'space-around'}}>
                        <div
                            onClick={onClickLike} 
                            style={{display: 'flex', 'gap': '5px', paddingLeft: '14px', alignItems: 'center'}}>
                            {uuid && post.likes.map(e => e.user_id).includes(uuid) ? <IcHeartActive width='24px' height='24px' /> : <IcHeart width='24px' height='24px' /> }
                            <RegularText_17 style={{color: uuid && post.likes.map(e => e.user_id).includes(uuid) ? primary : 'black'}}>좋아요</RegularText_17>
                            {post.likes.length != 0 ? <RegularText_17 style={{color: uuid && post.likes.map(e => e.user_id).includes(uuid) ? primary : 'black'}}>{post.likes.length.toString()}</RegularText_17> : null }
                        </div>

                        <div style={{display: 'flex', 'gap': '5px'}} onClick={() => { inputRef.current?.focus() }}>
                            <img src={icComment} width='24px' height='24px'></img>
                            <RegularText_17>댓글</RegularText_17>
                            <RegularText_17>{post.comments.length.toString()}</RegularText_17>
                        </div>

                        <div style={{display: 'flex', 'gap': '5px', paddingRight: '14px'}} onClick={onClickShare}>
                            <img src={icShareLight} width='20px' height='20px'></img>                            
                            <RegularText_17>공유하기</RegularText_17>
                        </div>
                    </div>
                    <div style={{padding: '15px 0px'}}>
                        {
                            post.comments.length === 0 
                            ? <NoCommentView /> 
                            : post.comments.map((comment, idx) => {
                                return <div key={comment.id} ref={commentRefs?.[comment.id]}>
                                    <CommentItem
                                        idx={idx}
                                        totalLength={post.comments.length}
                                        comment={comment} 
                                        isWriter={post.user_id === comment.user_id}
                                        isOwner={uuid == comment.user_id}
                                        viewerId={uuid}
                                        onClickLike={() => {
                                            if (!nickname) {
                                                navigate('set_nickname_guide_modal')
                                                return
                                            }

                                            setPost({
                                                ...post,
                                                comments: [...post.comments].map(e => {
                                                    if (e.id == comment.id) {
                                                        const value = [...comment.likes]
                                                        value.push({user_id: uuid!})
                                                        return {
                                                            ...comment,
                                                            likes: value
                                                        }
                                                    }
                                                    return e
                                                })
                                            })

                                            trackEvent('LikeComment', { 'post_id': postId, 'comment_id': comment.id, value: true })
                                            CommunityService.likeCommentById({
                                                id: comment.id,
                                                userId: uuid!,
                                                content: comment.content,
                                                nickname: nickname,
                                                writerUserId: comment.user_id,
                                                postId: postId
                                            }).then((response) => {
                                                if (response.level_up) {
                                                    showLevelUp(response.level_up)
                                                }
                                            })
                                        }}
                                        onCancelLike={() => {
                                            setPost({
                                                ...post,
                                                comments: [...post.comments].map(e => {
                                                    if (e.id == comment.id) {
                                                        return {
                                                            ...e,
                                                            likes: [...e.likes].filter(e => e.user_id != uuid)
                                                        }
                                                    }
                                                    return e
                                                })
                                            })

                                            trackEvent('LikeComment', { 'post_id': postId, 'comment_id': comment.id, value: false })
                                            CommunityService.cancelLikeCommentById({
                                                id: comment.id,
                                                userId: uuid!,
                                                postId: postId
                                            })
                                        }}
                                        onClickDelete={() => {
                                            trackEvent(EVENT_CLICK_DELETE_COMMENT_CTA, { 'post_id': postId, 'comment_id': comment.id })
                                            setShowCommentDeleteModal(true)
                                            setDeleteCommentId(comment.id)
                                        }}
                                        onClickEdit={() => {
                                            trackEvent(EVENT_CLICK_EDIT_COMMENT_CTA, { 'post_id': postId, 'comment_id': comment.id })
                                            setUpdateTargetComment(comment)
                                            setCommentInput(comment.content)
                                            inputRef.current?.focus()
                                            handleResizeHeight()
                                        }}
                                    />
                                </div>
                            })
                        }
                    </div>
                    {
                        recommendPosts.length !== 0 &&
                        <>
                            <div style={{height: '16px', width: '100%', backgroundColor: greyF4, borderTop: `solid 1px ${greyD7}`}}/>
                            <div style={{marginTop: '30px', padding: '0px 15px'}}>
                                <BoldText_20>커뮤니티</BoldText_20>
                                <BoldText_20 style={{color: primary}}> 추천글</BoldText_20>
                                <div style={{marginTop: '15px'}}>
                                    {recommendPosts.map((post, idx) => {
                                        return <RecommendPostItem key={idx} idx={idx} post={post} totalLength={recommendPosts.length} sourcePostId={postId}/>
                                    })}
                                </div>
                                <PlainTextButton 
                                    text="다음글 보기"
                                    style={{border: `1px solid ${grey999}`, marginBottom: '50px', marginTop: '10px'}}
                                    fontStyle={{fontSize: fontSize.m}}
                                    icon={<IcReset width='17px' height='17px'/>}
                                    onClick={() => {
                                        const nextDepth = loadMoreRecommendPostsDepth + 1
                                        setLoadMoreRecommendPostsDepth(nextDepth)
                                        loadRecommendedPosts(nextDepth)
                                        trackEvent(EVENT_LOAD_MORE_RECOMMEND_POST_CLICK, { source_post_id: postId, depth: loadMoreRecommendPostsDepth})
                                    }}
                                />
                            </div>
                        </>
                    }
                </div>
            }
            bottom={
                <div style={{padding: '15px', display: 'flex', width: 'calc(100% - 30px)', borderTop: 'solid 1px #D7D8DA'}}>
                    <div ref={commentContainerRef} style={{display: 'flex', width: 'calc(100% - 30px)', height: 'fit-content', padding: '10px 15px', border: `solid 1px ${grey999}`, borderRadius: '5px', fontSize: '1rem', alignItems: 'center', justifyContent: 'space-between', gap: '8px'}}>
                        <textarea
                            ref={inputRef}
                            value={commentInput}
                            onClick={onClickCommentContainer}
                            onChange={(e) => {
                                setCommentInput(e.target.value)
                            }}
                            placeholder="댓글을 입력해주세요"
                            onFocus={(e) => {
                                commentContainerRef.current?.style.setProperty('border', `solid 1px ${primary}`)
                            }}
                            onBlur={(e) => {
                                commentContainerRef.current?.style.setProperty('border', `solid 1px ${grey999}`)
                            }}
                            rows={1}
                            spellCheck={false}
                            style={{fontSize:fontSize.m, outline: 'none', border: 'none', flexGrow: 1, WebkitAppearance: 'none', overflow: 'scroll', scrollbarWidth: 'none', resize: 'none', lineHeight: '1.5'}}
                        />
                        <div style={{display: 'flex', alignItems: 'end', justifyContent: 'end', height: '100%', alignSelf: 'end'}}>
                            <FillButton
                                text="등록"
                                onClick={() => {
                                    if (updateTargetComment) {
                                        onCommentUpdate()
                                    } else {
                                        onCommentSubmit()
                                    }
                                }}
                                style={{backgroundColor: commentInput.length === 0 ? inactive : primary, width: '60px', height: '30px', padding: '0px 5px' }}
                                fontStyle={{fontSize: fontSize.s}}
                            />
                        </div>
                        
                    </div>
                </div>
            }
            modal={
            <>
                {showDeleteModal && <IModal 
                    message="글을 삭제하시겠습니까?"
                    confirmText="확인"
                    cancelText="취소"
                    onCancel={() => {
                        setShowDeleteModal(false)
                    }}
                    onConfirm={() => {
                        setShowDeleteModal(false)
                        deletePost()
                    }}
                    onBackgroundClick={() => {
                        setShowDeleteModal(false)
                    }}
                />}
                {showCommentDeleteModal && <IModal 
                    message="댓글을 삭제하시겠습니까?"
                    confirmText="확인"
                    cancelText="취소"
                    onCancel={() => {
                        setShowCommentDeleteModal(false)
                    }}
                    onConfirm={() => {
                        setShowCommentDeleteModal(false)
                        deleteComment()
                    }}
                    onBackgroundClick={() => {
                        setShowCommentDeleteModal(false)
                    }}
                />}
            </>
            }
        />
    );
};

export default PostDetailPage;
