import { CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeWorkingTimeType } from "@reducer/userSlice";
import { FillButton } from "@components/Buttons";
import { BottomDrawerTemplate } from "@components/DrawerTemplate";
import { RootState } from "@reducer/store";
import { WorkingTimeType } from "@src/model/WorkingTimeType";

export const JobTimeSelector = (
    { onSelect }: { onSelect: (value: WorkingTimeType | undefined) => void }
) => {
    const style: CSSProperties = {
        borderRadius: 5,
        height: '60px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    }

    const userWorkingTimeType = useSelector((state: RootState) => state.user.workingTimeType)
    const [workingTimeType, setWorkingTimeType] = useState<WorkingTimeType | undefined>(userWorkingTimeType)

    useEffect(() => {
        onSelect(workingTimeType)
    }, [workingTimeType])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div
                onClick={() => setWorkingTimeType(undefined)}
                style={{
                    ...style,
                    border: `1px solid ${!workingTimeType ? '#0ABF53' : '#999'}`,
                    background: !workingTimeType ? '#EEF9F2' : 'white',
                    color: !workingTimeType ? '#0ABF53' : 'black',
                }}
            >
                전체
            </div>
            <div
                onClick={() => setWorkingTimeType("전일제")}
                style={{
                    ...style,
                    border: `1px solid ${workingTimeType === "전일제" ? '#0ABF53' : '#999'}`,
                    background: workingTimeType === "전일제" ? '#EEF9F2' : 'white',
                    color: workingTimeType === "전일제" ? '#0ABF53' : 'black',
                }}
            >
                전일제
            </div>
            <div
                onClick={() => setWorkingTimeType("시간제")}
                style={{
                    ...style,
                    border: `1px solid ${workingTimeType === "시간제" ? '#0ABF53' : '#999'}`,
                    background: workingTimeType === "시간제" ? '#EEF9F2' : 'white',
                    color: workingTimeType === "시간제" ? '#0ABF53' : 'black',
                }}
            >
                시간제
            </div>
        </div>
    )
}

const WorkingTimeTypeDrawer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [workingTimeType, setWorkingTimeType] = useState<WorkingTimeType | undefined>()

    const onSelect = (value: WorkingTimeType | undefined) => {
        setWorkingTimeType(value)
    }

    const Cta = () => {
        function handle() {
            dispatch(changeWorkingTimeType(workingTimeType))
            navigate('/job/activity_list', { replace: true })
        }

        return (
            <FillButton
                text={!!workingTimeType ? `${workingTimeType} 공고 보기` : '전체 공고 보기'}
                onClick={handle}
            />
        )
    }

    return (
        <BottomDrawerTemplate
            title={'근로시간'}
            show={true}
            style={{padding: '0px'}}
            onClose={() => navigate(-1)}
        >
            <div style={{ height: '1px', background: '#D9D9D9' }}></div>
            <div style={{padding: '20px 8px 150px 8px'}}>
                <JobTimeSelector onSelect={onSelect} />
            </div>
            <div style={{padding: '5px 10px'}}>
                <Cta />
            </div>
        </BottomDrawerTemplate>
    )
}

export default WorkingTimeTypeDrawer;
